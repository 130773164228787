import {useMemo} from "react";
import { sumObjs } from ".";
import { AnalyticsGlobalData } from "./global";
import { usePairChartV3 } from "./pair-chart-v3";
import { useGetPairsV3Data } from "./pairs-v3";

import { useTokenDataV3 } from "./token-data-v3";
import { useGetTransactionsFilteredV2Data } from "./transactions-filtered-v2";
import { useGetTransactionsFilteredV3Data } from "./transactions-filtered-v3";

export const usePairDataV3 = (globalData?: AnalyticsGlobalData, address?: string) => {
  const tokenPairs = useGetPairsV3Data(
    !!address,
    globalData,
    false,
    `where: {id: "${address}"}`,
    undefined,
    `
      totalValueLockedToken0
      totalValueLockedToken1
      a: token0 {
        address: id
        symbol
        name
        derivedETH
      }
      b: token1 {
        address: id
        symbol
        name
        derivedETH
      }
    `
  )

  const tokenTxs = useGetTransactionsFilteredV3Data(!!address, [address!])
  const pairChart = usePairChartV3(globalData, address)
  
  return {
    ready: !!tokenPairs.pairs?.length,
    pair: tokenPairs.pairs?.[0] ||  {},
    pairs: [...tokenPairs.pairs] || [],
    txs: {
      isLoading: tokenTxs.isLoading,
      txs: tokenTxs.txs,
    },
    chart: {
      isLoading: pairChart.isLoading,
      ...pairChart.data,
    }
  };
};
