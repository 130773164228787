const month_arr = ["Jan", "Feb", "Mar", "April", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];

export function formatDate(milliseconds: number) {
  const date = new Date(milliseconds);
  const dayOfMonth = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  return `${dayOfMonth} ${month_arr[month]} ${year}`;
}

export const dateFormat = new Intl.DateTimeFormat("en-GB", {
  year: "2-digit",
  month: "2-digit",
  day: "2-digit",
});

export const timeFormat = new Intl.DateTimeFormat("en-GB", {
  hour: "numeric",
  minute: "2-digit",
  hour12: false,
});

export const shortDate = (date: number | string) =>
  `${timeFormat.format(new Date(date))} / ${dateFormat.format(new Date(date)).replace(/\//g, ".")}`;
