import {useMemo, useState} from "react";

import {Flex, Tabs, Divider, Skeleton, Loading} from "../../../../../../ui/components";
import {abbrNum} from "../../../../../../utils";
import {AnalyticsData} from "../../../../data";
import {useAnalytics} from "../../../../provider";

import * as SC from "./index.styles";

interface AnalyticsChartVolumeProps {}

type ZoomType = "day" | "week" | "month";

const {format: formatDay} = new Intl.DateTimeFormat("en-us", {month: "short", day: "numeric"});
const {format: formatDayLong} = new Intl.DateTimeFormat("en-us", {month: "short", day: "numeric", year: "numeric"});
const {format: formatMonth} = new Intl.DateTimeFormat("en-us", {month: "short", year: "numeric"});

type Point = AnalyticsData["chart"]["data"][number];

const config = {
  day: {sub: "Last 30 days", format: formatDay, date: (_) => formatDayLong(_.date)},
  week: {
    sub: "Last 53 weeks",
    format: formatDayLong,
    date: (_) => `${formatDayLong(_.date)} - ${formatDayLong(_.date + 7 * 24 * 60 * 60 * 1000)}`,
  },
  month: {sub: "Montly data", format: formatMonth, date: (_) => formatMonth(_.date)},
};

export const AnalyticsChartVolume = (props: AnalyticsChartVolumeProps) => {
  const {
    data: {chart},
  } = useAnalytics();
  const [zoom, setZoom] = useState<ZoomType>("day");
  const [hover, setHover] = useState<number>();

  const {data, min, max} = useMemo(() => {
    let values: Point[] = [];
    if (zoom === "day") {
      values = chart.data.slice(-30);
    } else {
      const isWeek = zoom === "week";
      const g: Record<string, Point> = {};
      const getKeyDate = (d: number) => {
        const date = new Date(d);
        if (isWeek) {
          return new Date(+date - (date.getDay() - 1) * 24 * 60 * 60 * 1000);
        } else {
          const [month] = date.toISOString().match(/\d+-\d+/) || [];
          return new Date(month + "-01T00:00:00");
        }
      };
      chart.data.slice(isWeek ? -380 : 0).forEach((_) => {
        const dayDate = getKeyDate(_.date);
        const day = dayDate.toISOString().substring(0, 10);
        if (!g[day]) {
          g[day] = JSON.parse(JSON.stringify(_));
          g[day].date = +dayDate;
        } else {
          g[day].volume += _.volume;
          g[day].v2.volume += _.v2.volume;
          if (!g[day].v3 && _.v3) {
            g[day].v3 = JSON.parse(JSON.stringify(_.v3));
          }
          if (g[day].v3) {
            g[day].v3!.volume += _.v3?.volume || 0;
          }
        }
      });
      values = Object.values(g)
        .sort(({date: a}, {date: b}) => a - b)
        .slice(isWeek ? -53 : 0);
    }

    let {min, max} = values.reduce(
      (acc, _) => ({
        max: Math.max(acc.max, _.volume),
        min: Math.min(acc.min, _.v2.volume),
      }),
      {max: -Infinity, min: Infinity},
    );

    min = Math.max(0, min - (max - min) * 0.2);
    const gap = max - min;

    const simple = values.map((_) => {
      const value = 1 - (max - _.volume) / gap;
      const inner = 1 - (max - _.v2.volume) / gap;
      return {
        date: _.date,
        value,
        inner: inner / value,
        v: _.volume,
        v2: _.v2?.volume,
        v3: _.v3?.volume,
      };
    });

    return {data: simple, min, max};
  }, [zoom, chart.data]);

  const {xAxis, yAxis} = useMemo(() => {
    const nX = 5;
    const nY = 3;
    const x = Array.from({length: nX})
      .map((_, i) => i / (nX - 1))
      .map((_) => data[Math.round((data.length - 1) * _)]?.date)
      .map(config[zoom].format);
    const y = Array.from({length: nY})
      .map((_, i) => i / (nY - 1))
      .map((_) => min + (max - min) * _)
      .map((_) => abbrNum(_, 3));
    return {
      xAxis: x,
      yAxis: y,
    };
  }, [zoom, data, min, max]);

  const agg = useMemo(() => {
    return data.reduce(
      (acc, _) => ({
        v: acc.v + _.v,
        v2: acc.v2 + _.v2,
        v3: acc.v3 + (_.v3 || 0),
      }),
      {v: 0, v2: 0, v3: 0},
    );
  }, [data]);

  const isHover = hover !== undefined;
  const isLoading = chart.isLoading || !data.length;

  return (
    <>
      <SC.Wrapper>
        {isLoading && <Loading size="xl" />}
        <Flex justify="space-between" align="flex-start">
          <div>
            <SC.Title>KOI Volume</SC.Title>
            <SC.TitleValue>
              <Skeleton hideChildren active={isLoading} width={140}>
                <span>${abbrNum(!isHover ? agg.v : data[hover!]?.v, 4)}</span>
              </Skeleton>
            </SC.TitleValue>
            <Divider size={0.5} />
            <SC.TitleValueSubtitle>
              <Skeleton hideChildren active={isLoading} width={100}>
                <span>{isHover ? config[zoom].date(data[hover!]) : config[zoom].sub}</span>
              </Skeleton>
            </SC.TitleValueSubtitle>
          </div>
          <div>
            <Skeleton hideChildren active={isLoading}>
              <Tabs
                micro
                selected={zoom}
                onSelect={setZoom}
                tabs={[
                  {value: "day", label: "D"},
                  {value: "week", label: "W"},
                  {value: "month", label: "M"},
                ]}
              />
            </Skeleton>
            <Divider size={2} />
            <Skeleton hideChildren active={isLoading}>
              <SC.LegendRow>
                <SC.LegendColor />
                v2
                <span>${abbrNum(!isHover ? agg.v2 : data[hover!]?.v2)}</span>
              </SC.LegendRow>
            </Skeleton>
            <Divider size={1} />
            <Skeleton hideChildren active={isLoading}>
              <SC.LegendRow>
                <SC.LegendColor alt />
                v3
                <span>${abbrNum(!isHover ? agg.v3 : data[hover!]?.v3 || 0)}</span>
              </SC.LegendRow>
            </Skeleton>
          </div>
        </Flex>
        <SC.Chart onMouseLeave={() => setHover(undefined)}>
          {!isLoading && (
            <>
              <SC.ChartBarWrapper hover={isHover}>
                {data.map((_, i) => (
                  <SC.ChartBar
                    key={i}
                    active={i === hover}
                    style={{height: _.value * 100 + "%"}}
                    onMouseEnter={() => setHover(i)}
                    onMouseLeave={() => setHover(undefined)}
                  >
                    <SC.ChartBar inner style={{height: _.inner * 100 + "%"}} />
                  </SC.ChartBar>
                ))}
              </SC.ChartBarWrapper>
              <SC.XAxis>
                {xAxis.map((_) => (
                  <span>{_}</span>
                ))}
              </SC.XAxis>
              <SC.YAxis>
                {yAxis.map((_) => (
                  <span>{_}</span>
                ))}
              </SC.YAxis>
            </>
          )}
        </SC.Chart>
      </SC.Wrapper>
    </>
  );
};
