import {BrowserProvider} from "zksync-ethers";
import {FallbackProvider} from "ethers";
import {useMemo} from "react";
import type {Account, Chain, Client, Transport} from "viem";
import {type Config, useConnectorClient} from "wagmi";

/*
export async function clientToSigner(client: Client<Transport, Chain, Account>) {
  const { account, chain, transport } = client
  const provider = new BrowserProvider(transport)
  const signer = await provider.getSigner()
  return signer
}

export function useZkSyncSigner({ chainId }: { chainId?: number } = {}) {
  const { data: client } = useConnectorClient<Config>({ chainId })
  const [val, setVal] = useState<Signer | undefined>(undefined)

  //return useMemo(async () => (client ? await clientToSigner(client) : undefined), [client])
  useEffect(() => {
    if(client && client.account && client.chain && client.transport){
      //@ts-ignore
      clientToSigner(client).then(res => {
        setVal(res)
      })
    }
    return () => {}

  }, [client])
  
  return val
}
*/

export function clientToProvider(client: Client<Transport, Chain, Account>) {
  const {account, chain, transport} = client;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };

  if (transport.type === "fallback") {
    const providers = (transport.transports as ReturnType<Transport>[]).map(
      ({value}) => new BrowserProvider(value?.url, network),
    );
    if (providers.length === 1) return providers[0];
    return new FallbackProvider(providers);
  }

  return new BrowserProvider(transport, network);
}

/** Hook to convert a viem Wallet Client to an ethers.js Signer. */
export function useZkSyncProvider({chainId}: {chainId?: number} = {}) {
  const {data: client} = useConnectorClient<Config>({chainId});
  return useMemo(() => (client ? clientToProvider(client) : undefined), [client]);
}
