export const txFragment = (type?: string) => `
  t: transaction {
    t: timestamp
    h: id
  }
  p: pair {
    x: token0 {
      s: symbol
    }
    y: token1 {
      s: symbol
    }
  }
  u: amountUSD
  ${
    type === "swap" ? `
      ai: amount0In
      ao: amount0Out
      bi: amount1In
      bo: amount1Out
    ` : `
      a: amount0
      b: amount1
    `
  }
  ${
    type === "swap"
      ? "f: from"
      : "f: sender"
  }
`;

export const mapTxType = <T extends string>(type: T, list: any[]) => list.map(mapTx).map((_) => ({type, ..._}));

export const mapTx = ({f, p: {x, y}, t, u, a, ai, ao, b, bi, bo}: any) => ({
  hash: t.h as string,
  from: f as string,
  date: t.t * 1000,
  token0: x.s as string,
  token1: y.s as string,
  amountUsd: +u,
  amount0: +a || +ai || +ao,
  amount1: +b || +bi || +bo,
});


export const txFragmentV3 = (type?: string) => `
  t: transaction {
    t: timestamp
    h: id
  }
  p: pool {
    x: token0 {
      s: symbol
    }
    y: token1 {
      s: symbol
    }
  }
  u: amountUSD
  a: amount0
  b: amount1
  ${
    type === "burn"
      ? "f: owner"
      : "f: sender"
  }
`;