import styled from "styled-components";

import {card, flex, mq, size, text} from "../../styles";
import {layoutMq} from "../box";
import {CardContentContentProps, CardContentProps} from ".";

export const Wrapper = styled.div<CardContentProps>`
  ${card("xl", true)}
  ${size.padding(5, 6)}

  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${(_) =>
    _.disabled &&
    `
    & > *{
      opacity: 0.66;
      pointer-events: none;
    }
  `}

  ${(_) =>
    _.extend &&
    `
    ${_.extend === 2 ? "min-height" : "height"}: 100%;
  `}

  ${(_) =>
    _.forceContract &&
    `
    overflow: hidden;
  `}

  ${(_) =>
    _.mobileMaxHeight &&
    layoutMq(`
    max-height: ${+_.mobileMaxHeight}px;
  `)}

  ${(_) =>
    _.extendHorizontal && `
      width: 100%;
    `}

  ${mq(
    480,
    `
    ${size.padding(5, 3, 3)}
  `,
  )}
`;

export const TitleWrapper = styled.div<{center?: boolean}>`
  ${(_) => flex(_.center ? "center" : "flex-end")}

  padding-bottom: ${size(4)};
`;

export const Title = styled.div`
  ${text.h6}

  margin-right: auto;
`;

export const TitleContent = styled.div``;

export const ContentWrapper = styled.div<CardContentContentProps>`
  min-height: 100%;
  // max-height: 100%;
  margin-right: ${size(-3.5)};
  padding-right: ${size(2.5)};
  padding-bottom: ${size(6)};
  margin-bottom: ${size(-6)};
  padding-top: ${size(2)};
  margin-top: ${size(-2)};
  overflow: auto;
  scrollbar-gutter: stable;

  -webkit-mask: linear-gradient(transparent 0, #fff ${size(2)}, #fff calc(100% - ${size(6)}), transparent 100%),
    linear-gradient(270deg, #fff 0, #fff ${size(2)}, transparent ${size(2)});

  ${mq(
    480,
    `
    margin-right: ${size(-2)};
    padding-right: ${size(1)};
    padding-bottom: ${size(3)};
    margin-bottom: ${size(-3)};
    -webkit-mask:
      linear-gradient(transparent 0, #fff ${size(2)}, #fff calc(100% - ${size(3)}), transparent 100%),
      linear-gradient(270deg, #fff 0, #fff ${size(2)}, transparent ${size(2)});
  `,
  )}
`;
