var COINGECKO_LIST: any = [
  {
    chainId: 324,
    id: 'archly-finance',
    symbol: 'ARC',
    name: 'Archly Finance',
    decimals: 18,
    address: '0xfb4c64c144c2bd0e7f2a06da7d6aac32d8cb2514',
    logoURI: 'https://coin-images.coingecko.com/coins/images/37308/large/arc-logo-circle-200.png?1713952763'
  },
  {
    chainId: 324,
    id: 'autoair-ai',
    symbol: 'AAI',
    name: 'AutoAir AI',
    decimals: 18,
    address: '0x144b83555d8a3119b0a69a7bc2f0a0388308fee3',
    logoURI: 'https://coin-images.coingecko.com/coins/images/36165/large/AutoAir_AI_-_200x200.png?1712108994'
  },
  {
    chainId: 324,
    id: 'baby-zeek',
    symbol: 'KITTEN',
    name: 'Baby Zeek',
    decimals: 18,
    address: '0x9d86578f4b935bed8398353c89bf0fca987dcef2',
    logoURI: 'https://coin-images.coingecko.com/coins/images/36414/large/Asset_31.png?1711420517'
  },
  {
    chainId: 324,
    id: 'basmati',
    symbol: 'BSMTI',
    name: 'Basmati',
    decimals: 18,
    address: '0x3beff5cc75f04e3717676dae3eeac8f57a8ca493',
    logoURI: 'https://coin-images.coingecko.com/coins/images/37412/large/1000010075.jpg?1714373910'
  },
  {
    chainId: 324,
    id: 'beany',
    symbol: 'BEANY',
    name: 'Beany',
    decimals: 18,
    address: '0xc65364c35d8e2aaba17d00e468c29576f3ec105c',
    logoURI: 'https://coin-images.coingecko.com/coins/images/35823/large/Design_sans_titre_%2843%29.png?1709886949'
  },
  {
    chainId: 324,
    id: 'bolt-token-023ba86e-eb38-41a1-8d32-8b48ecfcb2c7',
    symbol: '$BOLT',
    name: 'Bolt Token',
    decimals: 18,
    address: '0x61b41e0244133b9c9c47a57e51a5ef70be2c5dd4',
    logoURI: 'https://coin-images.coingecko.com/coins/images/30082/large/cropped-Group-75-300x258.png?1696529006'
  },
  {
    chainId: 324,
    id: 'bridged-usdt',
    symbol: 'USDT',
    name: 'Bridged USDT',
    decimals: 18,
    address: '0x493257fd37edb34451f62edf8d2a0c418852ba4c',
    logoURI: 'https://coin-images.coingecko.com/coins/images/35001/large/logo.png?1706959346'
  },
  {
    chainId: 324,
    id: 'bridged-weth',
    symbol: 'WETH',
    name: 'Bridged WETH',
    decimals: 18,
    address: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
    logoURI: 'https://coin-images.coingecko.com/coins/images/39723/large/WETH.PNG?1723733967'
  },
  {
    chainId: 324,
    id: 'bullets',
    symbol: 'BLT',
    name: 'Bullets',
    decimals: 18,
    address: '0x71fd7dfa7db7094e0f857ad3040f1afef76fef85',
    logoURI: 'https://coin-images.coingecko.com/coins/images/30475/large/Logo_BLT_Token_%28200x200%29.png?1696529362'
  },
  {
    chainId: 324,
    id: 'celer-bridged-busd-zksync',
    symbol: 'BUSD',
    name: 'Celer Bridged BUSD (zkSync)',
    decimals: 18,
    address: '0x2039bb4116b4efc145ec4f0e2ea75012d6c0f181',
    logoURI: 'https://assets.coingecko.com/coins/images/31273/standard/new_binance-peg-busd.png?1696530096'
  },
  {
    chainId: 324,
    id: 'cheems-token',
    symbol: 'CHEEMS',
    name: 'Cheems Token',
    decimals: 18,
    address: '0x9db6bf8b0215a02bea9bef28a92d829fd008d480',
    logoURI: 'https://coin-images.coingecko.com/coins/images/30376/large/Hg4_Lhbg_400x400.jpg?1696529270'
  },
  {
    chainId: 324,
    id: 'dappad',
    symbol: 'APPA',
    name: 'Dappad',
    decimals: 18,
    address: '0xe87269fa38180a13e9bb3c487537f5282ef3e5d7',
    logoURI: 'https://coin-images.coingecko.com/coins/images/36977/large/5XUKTuSc_400x400.jpg?1712916349'
  },
  {
    chainId: 324,
    id: 'derp',
    symbol: 'DERP',
    name: 'Derp',
    decimals: 18,
    address: '0x0bf4cb727b3f8092534d793893b2cc3348963dbf',
    logoURI: 'https://coin-images.coingecko.com/coins/images/33069/large/derpdex_%281%29.png?1700793428'
  },
  {
    chainId: 324,
    id: 'dextf',
    symbol: 'DEXTF',
    name: 'Domani Protocol',
    decimals: 18,
    address: '0x9929bcac4417a21d7e6fc86f6dae1cc7f27a2e41',
    logoURI: 'https://coin-images.coingecko.com/coins/images/12634/large/0qgT0aMu_400x400.jpg?1696512442'
  },
  {
    chainId: 324,
    id: 'dracula-fi',
    symbol: 'FANG',
    name: 'Dracula Fi',
    decimals: 18,
    address: '0x160e07e42adbc1fce92d505b579bcd8a3fbea77d',
    logoURI: 'https://coin-images.coingecko.com/coins/images/31028/large/fang.png?1696529864'
  },
  {
    chainId: 324,
    id: 'dudiez-meme-token',
    symbol: 'DUDIEZ',
    name: 'Dudiez Meme Token',
    decimals: 18,
    address: '0x3b925184c17b4648da212229a2fde6a8031462ee',
    logoURI: 'https://coin-images.coingecko.com/coins/images/36310/large/dudProfile.jpg?1711090369'
  },
  {
    chainId: 324,
    id: 'era-name-service',
    symbol: 'ERA',
    name: 'Era Name Service',
    decimals: 18,
    address: '0xecd9f240ed3895c77db676004328dd1d246f33c9',
    logoURI: 'https://coin-images.coingecko.com/coins/images/30536/large/era.jpg?1696529409'
  },
  {
    chainId: 324,
    id: 'ezkalibur',
    symbol: 'SWORD',
    name: 'eZKalibur',
    decimals: 18,
    address: '0x240f765af2273b0cab6caff2880d6d8f8b285fa4',
    logoURI: 'https://coin-images.coingecko.com/coins/images/30722/large/leaf.png?1696529593'
  },
  {
    chainId: 324,
    id: 'flashdash',
    symbol: 'FLASHDASH',
    name: 'Flashdash',
    decimals: 18,
    address: '0x06662147240414cc0dc25e6414100750d346bc44',
    logoURI: 'https://coin-images.coingecko.com/coins/images/33274/large/logo200.png?1701329106'
  },
  {
    chainId: 324,
    id: 'fulcrom',
    symbol: 'FUL',
    name: 'Fulcrom',
    decimals: 18,
    address: '0xe593853b4d603d5b8f21036bb4ad0d1880097a6e',
    logoURI: 'https://coin-images.coingecko.com/coins/images/29684/large/FUL_Token_%281%29.png?1696528618'
  },
  {
    chainId: 324,
    id: 'gemswap-2',
    symbol: 'ZGEM',
    name: 'GemSwap',
    decimals: 18,
    address: '0x2b64237277c605d07f17b96f9627712340c32981',
    logoURI: 'https://coin-images.coingecko.com/coins/images/29630/large/zgem.png?1696528568'
  },
  {
    chainId: 324,
    id: 'goal3',
    symbol: 'ZKUSD',
    name: 'zkUSD',
    decimals: 18,
    address: '0xfc7e56298657b002b3e656400e746b7212912757',
    logoURI: 'https://coin-images.coingecko.com/coins/images/31553/large/goal3.jpeg?1696530365'
  },
  {
    chainId: 324,
    id: 'govi',
    symbol: 'GOVI',
    name: 'CVI',
    decimals: 18,
    address: '0xd63ef5e9c628c8a0e8984cdfb7444aee44b09044',
    logoURI: 'https://coin-images.coingecko.com/coins/images/13875/large/GOVI.png?1696513619'
  },
  {
    chainId: 324,
    id: 'grai',
    symbol: 'GRAI',
    name: 'Grai',
    decimals: 18,
    address: '0x5fc44e95eaa48f9eb84be17bd3ac66b6a82af709',
    logoURI: 'https://coin-images.coingecko.com/coins/images/30427/large/GRAI_Token.png?1696529315'
  },
  {
    chainId: 324,
    id: 'heartx-utility-token',
    symbol: 'HNX',
    name: 'HeartX Utility Token',
    decimals: 18,
    address: '0xc76997c863bac0f6e4b6d3c2404b06013a0b29e2',
    logoURI: 'https://coin-images.coingecko.com/coins/images/30531/large/HNX_icon.png?1696529404'
  },
  {
    chainId: 324,
    id: 'holdstation',
    symbol: 'HOLD',
    name: 'Holdstation',
    decimals: 18,
    address: '0xed4040fd47629e7c8fbb7da76bb50b3e7695f0f2',
    logoURI: 'https://coin-images.coingecko.com/coins/images/32726/large/HOLD.png?1699018663'
  },
  {
    chainId: 324,
    id: 'holdstation-usd-coin',
    symbol: 'HSUSDC',
    name: 'Holdstation USDC',
    decimals: 18,
    address: '0xaf08a9d918f16332f22cf8dc9abe9d9e14ddcbc2',
    logoURI: 'https://coin-images.coingecko.com/coins/images/32646/large/hsUSDC.png?1698893340'
  },
  {
    chainId: 324,
    id: 'holdstation-utility-gold',
    symbol: 'UGOLD',
    name: 'Holdstation Utility GOLD',
    decimals: 18,
    address: '0x10d967f46b06580c4a87b05c78f04e4df25c0db0',
    logoURI: 'https://coin-images.coingecko.com/coins/images/32647/large/uGOLD.png?1698893439'
  },
  {
    chainId: 324,
    id: 'hypercomic',
    symbol: 'HYCO',
    name: 'HYPERCOMIC',
    decimals: 18,
    address: '0x45656c02aae856443717c34159870b90d1288203',
    logoURI: 'https://coin-images.coingecko.com/coins/images/29407/large/coingecko_HYPERCOMIC_LOGO.png?1696528357'
  },
  {
    chainId: 324,
    id: 'idexo-token',
    symbol: 'IDO',
    name: 'Idexo',
    decimals: 18,
    address: '0xdea6d5161978d36b5c0fa6a491faa754f4bc809c',
    logoURI: 'https://coin-images.coingecko.com/coins/images/18523/large/qOiqm7T8_400x400.jpg?1696518004'
  },
  {
    chainId: 324,
    id: 'impermax-2',
    symbol: 'IBEX',
    name: 'Impermax',
    decimals: 18,
    address: '0xbe9f8c0d6f0fd7e46cdacca340747ea2f247991d',
    logoURI: 'https://coin-images.coingecko.com/coins/images/27606/large/IqwOmX-c_400x400.jpeg?1696526637'
  },
  {
    chainId: 324,
    id: 'increment',
    symbol: 'INCR',
    name: 'Increment',
    decimals: 18,
    address: '0xb2c5a37a4c37c16ddd21181f6ddbc989c3d36cdc',
    logoURI: 'https://coin-images.coingecko.com/coins/images/38166/large/logo2.png?1716692752'
  },
  {
    chainId: 324,
    id: 'interport-token',
    symbol: 'ITP',
    name: 'Interport Token',
    decimals: 18,
    address: '0xd03465338226ea0178337f4abb16fdd6df529f57',
    logoURI: 'https://coin-images.coingecko.com/coins/images/28338/large/ITP_Logo_200.png?1696527344'
  },
  {
    chainId: 324,
    id: 'izumi-finance',
    symbol: 'IZI',
    name: 'iZUMi Finance',
    decimals: 18,
    address: '0x16a9494e257703797d747540f01683952547ee5b',
    logoURI: 'https://coin-images.coingecko.com/coins/images/21791/large/izumi-logo-symbol.png?1696521144'
  },
  {
    chainId: 324,
    id: 'karat',
    symbol: 'KAT',
    name: 'Karat',
    decimals: 18,
    address: '0xcdb7d260c107499c80b4b748e8331c64595972a1',
    logoURI: 'https://coin-images.coingecko.com/coins/images/31834/large/coin1.png?1696530647'
  },
  {
    chainId: 324,
    id: 'koi-3',
    symbol: 'KOI',
    name: 'Koi',
    decimals: 18,
    address: '0xa995ad25ce5eb76972ab356168f5e1d9257e4d05',
    logoURI: 'https://coin-images.coingecko.com/coins/images/35766/large/Koi_logo.png?1709782399'
  },
  {
    chainId: 324,
    id: 'kyber-network-crystal',
    symbol: 'KNC',
    name: 'Kyber Network Crystal',
    decimals: 18,
    address: '0x6ee46cb7cd2f15ee1ec9534cf29a5b51c83283e6',
    logoURI: 'https://coin-images.coingecko.com/coins/images/14899/large/RwdVsGcw_400x400.jpg?1696514562'
  },
  {
    chainId: 324,
    id: 'kz-token',
    symbol: 'KZ',
    name: 'KZ Token',
    decimals: 18,
    address: '0x5fd37f12029511f17c1898477f7fb685cf6c0c0f',
    logoURI: 'https://coin-images.coingecko.com/coins/images/38802/large/KZ_Token_200x200.png?1718941524'
  },
  {
    chainId: 324,
    id: 'liquity-usd',
    symbol: 'LUSD',
    name: 'Liquity USD',
    decimals: 18,
    address: '0x503234f203fc7eb888eec8513210612a43cf6115',
    logoURI: 'https://coin-images.coingecko.com/coins/images/14666/large/Group_3.png?1696514341'
  },
  {
    chainId: 324,
    id: 'long-3',
    symbol: 'LONG',
    name: 'Long',
    decimals: 18,
    address: '0x5165ec33b491d7b67260b3143f96bb4ac4736398',
    logoURI: 'https://coin-images.coingecko.com/coins/images/36497/large/Logo_Long_square.png?1717541650'
  },
  {
    chainId: 324,
    id: 'long-mao',
    symbol: 'LMAO',
    name: 'Long Mao',
    decimals: 18,
    address: '0xb0c2bdc425fd01c33d8514f8be016070212bdc6a',
    logoURI: 'https://coin-images.coingecko.com/coins/images/35213/large/rsz_longmaopfpp.png?1707898201'
  },
  {
    chainId: 324,
    id: 'macaronswap',
    symbol: 'MCRN',
    name: 'MacaronSwap',
    decimals: 18,
    address: '0xafe4ca0bbe6215cbda12857e723134bc3809f766',
    logoURI: 'https://coin-images.coingecko.com/coins/images/14633/large/macaron.png?1696514311'
  },
  {
    chainId: 324,
    id: 'maverick-protocol',
    symbol: 'MAV',
    name: 'Maverick Protocol',
    decimals: 18,
    address: '0x787c09494ec8bcb24dcaf8659e7d5d69979ee508',
    logoURI: 'https://coin-images.coingecko.com/coins/images/30850/large/MAV_Logo.png?1696529701'
  },
  {
    chainId: 324,
    id: 'metaelfland',
    symbol: 'MELD',
    name: 'MetaElfLand',
    decimals: 18,
    address: '0xcd2cfa60f04f3421656d6eebee122b3973b3f60c',
    logoURI: 'https://coin-images.coingecko.com/coins/images/31469/large/MELD_LOGO.png?1696530282'
  },
  {
    chainId: 324,
    id: 'metavault-trade',
    symbol: 'MVX',
    name: 'Metavault Trade',
    decimals: 18,
    address: '0xc8ac6191cdc9c7bf846ad6b52aaaa7a0757ee305',
    logoURI: 'https://coin-images.coingecko.com/coins/images/25402/large/mvx.png?1696524534'
  },
  {
    chainId: 324,
    id: 'mute',
    symbol: 'MUTE',
    name: 'Mute',
    decimals: 18,
    address: '0x0e97c7a0f8b2c9885c8ac9fc6136e829cbc21d42',
    logoURI: 'https://coin-images.coingecko.com/coins/images/14331/large/MUTE.png?1696514019'
  },
  {
    chainId: 324,
    id: 'nodle-network',
    symbol: 'NODL',
    name: 'Nodle Network',
    decimals: 18,
    address: '0xbd4372e44c5ee654dd838304006e1f0f69983154',
    logoURI: 'https://coin-images.coingecko.com/coins/images/27019/large/nodle.png?1696526071'
  },
  {
    chainId: 324,
    id: 'nop-app',
    symbol: 'NOP',
    name: 'Nop App',
    decimals: 18,
    address: '0x941fc398d9faebdd9f311011541045a1d66c748e',
    logoURI: 'https://coin-images.coingecko.com/coins/images/36376/large/191.png?1711342125'
  },
  {
    chainId: 324,
    id: 'omnisea',
    symbol: 'OSEA',
    name: 'Omnisea',
    decimals: 18,
    address: '0xd6c008fb375648972206518fb0b1a1b65f371d05',
    logoURI: 'https://coin-images.coingecko.com/coins/images/26475/large/293837892_407994084681555_3167689470652146992_n.png?1696525547'
  },
  {
    chainId: 324,
    id: 'onez',
    symbol: 'ONEZ',
    name: 'ONEZ',
    decimals: 18,
    address: '0x90059c32eeeb1a2aa1351a58860d98855f3655ad',
    logoURI: 'https://coin-images.coingecko.com/coins/images/32970/large/onez.jpg?1700056810'
  },
  {
    chainId: 324,
    id: 'orbitpad',
    symbol: 'OPAD',
    name: 'Orbitpad',
    decimals: 18,
    address: '0x3a4fea0cf14c18c21975ff08efe11c08cccefe10',
    logoURI: 'https://coin-images.coingecko.com/coins/images/33113/large/logo200px.png?1700731139'
  },
  {
    chainId: 324,
    id: 'pancakeswap-token',
    symbol: 'CAKE',
    name: 'PancakeSwap',
    decimals: 18,
    address: '0x3a287a06c66f9e95a56327185ca2bdf5f031cecd',
    logoURI: 'https://coin-images.coingecko.com/coins/images/12632/large/pancakeswap-cake-logo_%281%29.png?1696512440'
  },
  {
    chainId: 324,
    id: 'pepera',
    symbol: 'PEPERA',
    name: 'Pepera',
    decimals: 18,
    address: '0x60143f39b54a731ac790708c8719211878e638c3',
    logoURI: 'https://coin-images.coingecko.com/coins/images/30199/large/PEPERA.png?1696529114'
  },
  {
    chainId: 324,
    id: 'reactorfusion',
    symbol: 'RF',
    name: 'ReactorFusion',
    decimals: 18,
    address: '0x5f7cbcb391d33988dad74d6fd683aadda1123e4d',
    logoURI: 'https://coin-images.coingecko.com/coins/images/29975/large/RF_Token_logo.png?1696528900'
  },
  {
    chainId: 324,
    id: 'rogue-mav',
    symbol: 'RMAV',
    name: 'Rogue MAV',
    decimals: 18,
    address: '0xb7f5b452b381a90ba10bdde6d75c6a4fd65a4239',
    logoURI: 'https://coin-images.coingecko.com/coins/images/32384/large/rMAV.png?1698045143'
  },
  {
    chainId: 324,
    id: 'snark-launch',
    symbol: '$SNRK',
    name: 'Snark Launch',
    decimals: 18,
    address: '0x533b5f887383196c6bc642f83338a69596465307',
    logoURI: 'https://coin-images.coingecko.com/coins/images/29957/large/IMG_20230422_160407_790.jpg?1696528883'
  },
  {
    chainId: 324,
    id: 'solarcoin',
    symbol: 'SLR',
    name: 'Solarcoin',
    decimals: 18,
    address: '0xe027d939f7de6f521675907cf086f59e4d75b876',
    logoURI: 'https://coin-images.coingecko.com/coins/images/152/large/solarcoin.png?1696501524'
  },
  {
    chainId: 324,
    id: 'spacefi-zksync',
    symbol: 'SPACE',
    name: 'SpaceFi',
    decimals: 18,
    address: '0x47260090ce5e83454d5f05a0abbb2c953835f777',
    logoURI: 'https://coin-images.coingecko.com/coins/images/30822/large/aOwhiUUo_400x400.png?1696529676'
  },
  {
    chainId: 324,
    id: 'superlauncher-dao',
    symbol: 'LAUNCH',
    name: 'Superlauncher',
    decimals: 18,
    address: '0xf6d9a093a1c69a152d87e269a7d909e9d76b1815',
    logoURI: 'https://coin-images.coingecko.com/coins/images/14817/large/SL-V3-1024.png?1696514485'
  },
  {
    chainId: 324,
    id: 'symbiosis-finance',
    symbol: 'SIS',
    name: 'Symbiosis',
    decimals: 18,
    address: '0xdd9f72afed3631a6c85b5369d84875e6c42f1827',
    logoURI: 'https://coin-images.coingecko.com/coins/images/20805/large/SymbiosisFinance_logo-150x150.jpeg?1696520198'
  },
  {
    chainId: 324,
    id: 'synapse-network-2',
    symbol: 'ZKSNP',
    name: 'Synapse Network',
    decimals: 18,
    address: '0x971b79ef5e76088af5e52c6d6aa3276c2190e45c',
    logoURI: 'https://coin-images.coingecko.com/coins/images/34990/large/blobid0_%281%29.png?1707451143'
  },
  {
    chainId: 324,
    id: 'syncdex',
    symbol: 'SYDX',
    name: 'SyncDex',
    decimals: 18,
    address: '0x3a34fa9a1288597ad6c1da709f001d37fef8b19e',
    logoURI: 'https://coin-images.coingecko.com/coins/images/29746/large/Logo200x200.png?1696528678'
  },
  {
    chainId: 324,
    id: 'syncus',
    symbol: 'SYNC',
    name: 'Syncus',
    decimals: 18,
    address: '0x2d20b8891f2f9ed0ebf1b179b2279f936dec9282',
    logoURI: 'https://coin-images.coingecko.com/coins/images/33573/large/Syncus.png?1702450708'
  },
  {
    chainId: 324,
    id: 'tarot-2',
    symbol: 'TAROT',
    name: 'Tarot',
    decimals: 18,
    address: '0x7f2fd959013eec5144269ac6edd0015cb10968fc',
    logoURI: 'https://coin-images.coingecko.com/coins/images/31800/large/TAROT.jpg?1696530615'
  },
  {
    chainId: 324,
    id: 'tiny-era-shard',
    symbol: 'TES',
    name: 'Tiny Era Shard',
    decimals: 18,
    address: '0xcab3f741fa54e79e34753b95717b23018332b8ac',
    logoURI: 'https://coin-images.coingecko.com/coins/images/31204/large/TES250.png?1696530031'
  },
  {
    chainId: 324,
    id: 'tiusd',
    symbol: 'TIUSD',
    name: 'TiUSD',
    decimals: 18,
    address: '0xc059767cb62f003e863f9e7bd1fc813beff9693c',
    logoURI: 'https://coin-images.coingecko.com/coins/images/30697/large/TIUDS.png?1696529566'
  },
  {
    chainId: 324,
    id: 'usd',
    symbol: 'USD+',
    name: 'Overnight.fi USD+',
    decimals: 18,
    address: '0x8e86e46278518efc1c5ced245cba2c7e3ef11557',
    logoURI: 'https://coin-images.coingecko.com/coins/images/25757/large/USD__logo.png?1696524843'
  },
  {
    chainId: 324,
    id: 'usd-coin',
    symbol: 'USDC',
    name: 'USDC',
    decimals: 18,
    address: '0x1d17cbcf0d6d143135ae902365d2e5e2a16538d4',
    logoURI: 'https://coin-images.coingecko.com/coins/images/6319/large/usdc.png?1696506694'
  },
  {
    chainId: 324,
    id: 'velocore',
    symbol: 'VC',
    name: 'Velocore',
    decimals: 18,
    address: '0x99bbe51be7cce6c8b84883148fd3d12ace5787f2',
    logoURI: 'https://coin-images.coingecko.com/coins/images/29702/large/VELOCORE-CIRCLE-LOGO.png?1696528634'
  },
  {
    chainId: 324,
    id: 'veno-finance',
    symbol: 'VNO',
    name: 'Veno Finance',
    decimals: 18,
    address: '0xe75a17b4f5c4f844688d5670b684515d7c785e63',
    logoURI: 'https://coin-images.coingecko.com/coins/images/28799/large/Veno_Token_dark.png?1696527777'
  },
  {
    chainId: 324,
    id: 'veno-finance-staked-eth',
    symbol: 'LETH',
    name: 'Veno Finance Staked ETH',
    decimals: 18,
    address: '0xe7895ed01a1a6aacf1c2e955af14e7cf612e7f9d',
    logoURI: 'https://coin-images.coingecko.com/coins/images/34945/large/LETH_%281%29.png?1708020787'
  },
  {
    chainId: 324,
    id: 'vesync',
    symbol: 'VS',
    name: 'veSync',
    decimals: 18,
    address: '0x5756a28e2aae01f600fc2c01358395f5c1f8ad3a',
    logoURI: 'https://coin-images.coingecko.com/coins/images/30611/large/veSync-Logo-PNG-200X200.png?1696529480'
  },
  {
    chainId: 324,
    id: 'wagmi-2',
    symbol: 'WAGMI',
    name: 'Wagmi',
    decimals: 18,
    address: '0x3613ad277df1d5935d41400a181aa9ec1dc2dc9e',
    logoURI: 'https://coin-images.coingecko.com/coins/images/31887/large/wagmi_token_logo.png?1696530698'
  },
  {
    chainId: 324,
    id: 'walkmining-governance',
    symbol: 'WKG',
    name: 'WalkMining Governance',
    decimals: 18,
    address: '0xbde632e042bd6e7439856461b87b89d60bc07417',
    logoURI: 'https://coin-images.coingecko.com/coins/images/38633/large/symbol_wkm_200.png?1718172340'
  },
  {
    chainId: 324,
    id: 'wefi-finance',
    symbol: 'WEFI',
    name: 'Wefi',
    decimals: 18,
    address: '0x81e7186947fb59aaaaeb476a47daac60680cbbaf',
    logoURI: 'https://coin-images.coingecko.com/coins/images/30540/large/wefi.png?1696529412'
  },
  {
    chainId: 324,
    id: 'whisper',
    symbol: 'WISP',
    name: 'Whisper',
    decimals: 18,
    address: '0xc8ec5b0627c794de0e4ea5d97ad9a556b361d243',
    logoURI: 'https://coin-images.coingecko.com/coins/images/29650/large/wisp_200.png?1696528586'
  },
  {
    chainId: 324,
    id: 'woo-network',
    symbol: 'WOO',
    name: 'WOO',
    decimals: 18,
    address: '0x9e22d758629761fc5708c171d06c2fabb60b5159',
    logoURI: 'https://coin-images.coingecko.com/coins/images/12921/large/WOO_Logos_2023_Profile_Pic_WOO.png?1696512709'
  },
  {
    chainId: 324,
    id: 'wrapped-libertas-omnibus',
    symbol: 'LIBERTAS',
    name: 'Wrapped LIBERTAS OMNIBUS',
    decimals: 18,
    address: '0xc6dac3a53d5d6de9d1d05aa6e28b8e9e41722601',
    logoURI: 'https://coin-images.coingecko.com/coins/images/35181/large/libertas.png?1707796268'
  },
  {
    chainId: 324,
    id: 'wrapped-rseth',
    symbol: 'WRSETH',
    name: 'Wrapped rsETH',
    decimals: 18,
    address: '0xd4169e045bcf9a86cc00101225d9ed61d2f51af2',
    logoURI: 'https://coin-images.coingecko.com/coins/images/37919/large/rseth.png?1715936438'
  },
  {
    chainId: 324,
    id: 'wrapped-steth',
    symbol: 'WSTETH',
    name: 'Wrapped stETH',
    decimals: 18,
    address: '0x703b52f2b28febcb60e1372858af5b18849fe867',
    logoURI: 'https://coin-images.coingecko.com/coins/images/18834/large/wstETH.png?1696518295'
  },
  {
    chainId: 324,
    id: 'zeek-coin',
    symbol: 'MEOW',
    name: 'Zeek Coin',
    decimals: 18,
    address: '0x79db8c67d0c33203da4efb58f7d325e1e0d4d692',
    logoURI: 'https://coin-images.coingecko.com/coins/images/34101/large/Zeek_200_by_200.png?1714928523'
  },
  {
    chainId: 324,
    id: 'zeekwifhat',
    symbol: 'ZWIF',
    name: 'Zeekwifhat',
    decimals: 18,
    address: '0x60e7fe7ae4461b535bb9eb40c20424c7c61063d0',
    logoURI: 'https://coin-images.coingecko.com/coins/images/36371/large/zeekCG.png?1711338998'
  },
  {
    chainId: 324,
    id: 'zkapes-token',
    symbol: 'ZAT',
    name: 'zkApes Token',
    decimals: 18,
    address: '0x47ef4a5641992a72cfd57b9406c9d9cefee8e0c4',
    logoURI: 'https://coin-images.coingecko.com/coins/images/30316/large/zkApes.png?1696529217'
  },
  {
    chainId: 324,
    id: 'zkdoge',
    symbol: 'ZKDOGE',
    name: 'zkDoge',
    decimals: 18,
    address: '0xbfb4b5616044eded03e5b1ad75141f0d9cb1499b',
    logoURI: 'https://coin-images.coingecko.com/coins/images/29619/large/zkdoge.png?1696528555'
  },
  {
    chainId: 324,
    id: 'zkdx',
    symbol: 'ZKDX',
    name: 'ZKDX',
    decimals: 18,
    address: '0xc3a3aa99b80845d22cc41dea3bdb9b2a9ebe199a',
    logoURI: 'https://coin-images.coingecko.com/coins/images/34802/large/logo-png.png?1706092934'
  },
  {
    chainId: 324,
    id: 'zkera-finance',
    symbol: 'ZKE',
    name: 'zkEra Finance',
    decimals: 18,
    address: '0x7b3e1236c39ddd2e61cf6da6ac6d11193238ccb0',
    logoURI: 'https://coin-images.coingecko.com/coins/images/33928/large/token_png.png?1715327053'
  },
  {
    chainId: 324,
    id: 'zkgrok',
    symbol: 'ZKGROK',
    name: 'ZKGROK',
    decimals: 18,
    address: '0x75c568a970e54a075b2a5475264cfcff672d2b89',
    logoURI: 'https://coin-images.coingecko.com/coins/images/33806/large/zkgrok_200_200.png?1703117664'
  },
  {
    chainId: 324,
    id: 'zklaunchpad',
    symbol: 'ZKPAD',
    name: 'zkLaunchpad',
    decimals: 18,
    address: '0x959ab3394246669914bddeaeb50f8ac85648615e',
    logoURI: 'https://coin-images.coingecko.com/coins/images/29720/large/zkpad.jpeg?1696528650'
  },
  {
    chainId: 324,
    id: 'zkpepe',
    symbol: 'ZKPEPE',
    name: 'ZKPepe',
    decimals: 18,
    address: '0x90eea899185105d583d04b7bdbfb672fce902a53',
    logoURI: 'https://coin-images.coingecko.com/coins/images/29870/large/241.png?1696528795'
  },
  {
    chainId: 324,
    id: 'zkpepe-2',
    symbol: 'ZKPEPE',
    name: 'ZKPEPE',
    decimals: 18,
    address: '0x7d54a311d56957fa3c9a3e397ca9dc6061113ab3',
    logoURI: 'https://coin-images.coingecko.com/coins/images/33196/large/logo.jpg?1700972509'
  },
  {
    chainId: 324,
    id: 'zkswap-finance',
    symbol: 'ZF',
    name: 'zkSwap Finance',
    decimals: 18,
    address: '0x31c2c031fdc9d33e974f327ab0d9883eae06ca4a',
    logoURI: 'https://coin-images.coingecko.com/coins/images/31633/large/zf.png?1696530449'
  },
  {
    chainId: 324,
    id: 'zksync',
    symbol: 'ZK',
    name: 'ZKsync',
    decimals: 18,
    address: '0x5a7d6b2f92c77fad6ccabd7ee0624e64907eaf3e',
    logoURI: 'https://coin-images.coingecko.com/coins/images/38043/large/ZKTokenBlack.png?1718614502'
  },
  {
    chainId: 324,
    id: 'zksync-bridged-usdc-zksync',
    symbol: 'USDC',
    name: 'zkSync Bridged USDC (zkSync)',
    decimals: 18,
    address: '0x3355df6d4c9c3035724fd0e3914de96a5a83aaf4',
    logoURI: 'https://coin-images.coingecko.com/coins/images/35262/large/USDC_Icon.png?1708008941'
  },
  {
    chainId: 324,
    id: 'zksync-bridged-wbtc-zksync',
    symbol: 'WBTC',
    name: 'zkSync Bridged WBTC (zkSync)',
    decimals: 18,
    address: '0xbbeb516fb02a01611cbbe0453fe3c580d7281011',
    logoURI: 'https://coin-images.coingecko.com/coins/images/39592/large/wbtc.png?1723016108'
  },
  {
    chainId: 324,
    id: 'zksync-erc20-bridged-dai-zksync',
    symbol: 'DAI',
    name: 'ZKsync ERC20 Bridged DAI (zkSync)',
    decimals: 18,
    address: '0x4b9eb6c0b6ea15176bbf62841c6b2a8a398cb656',
    logoURI: 'https://coin-images.coingecko.com/coins/images/39805/large/dai.png?1724126260'
  },
  {
    chainId: 324,
    id: 'zksync-id',
    symbol: 'ZKID',
    name: 'zkSync id',
    decimals: 18,
    address: '0x2141d7fe06a1d69c016fc638ba75b6ef92fa1435',
    logoURI: 'https://coin-images.coingecko.com/coins/images/30115/large/logo.png?1696529037'
  },
  {
    chainId: 324,
    id: 'zorro',
    symbol: 'ZORRO',
    name: 'Zorro',
    decimals: 18,
    address: '0x244c238325fc1bdf6eded726ee1b47d55895d944',
    logoURI: 'https://coin-images.coingecko.com/coins/images/34381/large/ZORRO_TP.png?1704777748'
  }
]

export default COINGECKO_LIST;
