import React, {useState} from "react";
import styled from "styled-components";
import BigNumber from "bignumber.js";

import {formatDate} from "../../utils/formatDate";
import {AddressSection} from "../../common/section/AddressSection";
import {isAddress} from "ethers";

import EditIcon from "@mui/icons-material/Edit";

import {reduceString} from "../../utils/reduceString";
import {formatNumber} from "../../utils/formatNumber";

import {Modal, Card, Text, CardContent, Icon, Button} from "../../ui/components";

export const VELockInfoKoi = ({info, onRedeem, onChangeDelegate, onConvert, price, calculating: cA}) => {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [delegateInfo, setDelegateInfo] = useState(info.delegate);
  const [originalDelegate, setOriginalDelegate] = useState(info.delegate);

  const [cB, setCalculating] = useState(false);
  const calculating = cA || cB;
  const [showEdit, setShowEdit] = useState(false);

  const shouldDisableRedeem = () => {
    var curDate = new BigNumber(Date.now()).div(1000);

    if (curDate.lt(info.time)) {
      return true;
    }

    return false;
  };

  const shouldDisable = () => {
    if (delegateInfo.toLowerCase() == originalDelegate.toLowerCase() || isAddress(delegateInfo) == false) return true;

    return false;
  };

  const getConfirmationText = () => {
    if (delegateInfo.toLowerCase() == originalDelegate.toLowerCase()) return "Same address";
    if (isAddress(delegateInfo) == false) return "Invalid address";

    return "Delegate";
  };

  const changeDel = async () => {
    setCalculating(true);
    await onChangeDelegate(delegateInfo);
    setCalculating(false);
  };

  React.useEffect(() => {
    return () => {};
  }, []);

  return (
    <StyledDropdown
      onClick={() => {
        setShowInfoModal(true);
      }}
    >
      <Modal
        title={"Delegate veKOI Token #" + info.index}
        isVisible={showEdit}
        onClose={() => {
          setDelegateInfo(info.delegate);
          setShowEdit(false);
        }}
      >
        <AddressSection address={delegateInfo} onChange={setDelegateInfo} label={"Delegate Address"} />

        <Button loading={calculating} full onClick={() => changeDel()} disabled={shouldDisable()}>
          {getConfirmationText()}
        </Button>
      </Modal>

      <Modal
        title={"veKOI Token #" + info.index}
        isVisible={showInfoModal}
        onClose={() => {
          setShowInfoModal(false);
        }}
      >
        <Content>
          <Icon icon="coinKoi" size="m" />
          <Text h4>{formatNumber(info.amount, 2)} KOI</Text>
          <Text bodyRegular>${new BigNumber(price ? price : 0).times(info.amount).toFixed(2)}</Text>
        </Content>
        <StatRow>
          <StatText bodyRegular color="gray400">
            Vote Shares
          </StatText>
          <StatTextHighlight bodyMedium>
            {formatNumber(new BigNumber(info.vote_weight).toFixed(4), 2) + " shares"}
          </StatTextHighlight>
        </StatRow>
        <StatRow>
          <StatText bodyRegular color="gray400">
            Underlying KOI Amount
          </StatText>
          <StatTextHighlight bodyMedium>
            {formatNumber(new BigNumber(info.amount).toFixed(4), 2) + " KOI"}
          </StatTextHighlight>
        </StatRow>
        <StatRow>
          <StatText bodyRegular color="gray400">
            Expiry Date
          </StatText>
          <StatTextHighlight bodyMedium>
            {formatDate(new BigNumber(info.time).times(1000).toNumber())}
          </StatTextHighlight>
        </StatRow>
        <StatRow>
          <StatText bodyRegular color="gray400">
            Delegate{" "}
            <EditIcon
              onClick={() => {
                setShowEdit(true);
                setShowInfoModal(false);
              }}
              fill="rgb(var(--color-gray400))"
              style={{color: "rgb(var(--color-gray400))", padding: "4px", cursor: "pointer"}}
            />
          </StatText>
          <StatTextHighlightLink href={"https://era.zksync.network/address/" + info.delegate} target="_blank">
            {reduceString(info.delegate, 7, 4)}
          </StatTextHighlightLink>
        </StatRow>

        <Button
          full={true}
          onClick={async () => {
            await onRedeem();
            setShowInfoModal(false);
          }}
          loading={calculating}
          disabled={shouldDisableRedeem() || calculating}
        >
          {shouldDisableRedeem() == true ? "Locked" : "Unlock"}
        </Button>
      </Modal>

      <LI>
        <Icon size="m" success icon="coinKoi" />

        <Amount style={{paddingLeft: "10px"}} h6>
          {formatNumber(new BigNumber(info.amount).toFixed(4), 2) + " KOI"}
        </Amount>
        <Amount h6 style={{marginLeft: "auto", marginRight: "10px"}}>
          {formatNumber(new BigNumber(info.vote_weight).toFixed(4), 2) + " shares"}
        </Amount>
        <Icon size="m" button icon="arrow" color="overgrown" rotate={3} />
      </LI>

      <DateText bodyRegular color="gray400">
        Expiration date: &nbsp;{" "}
        <Amount bodyRegular color="black">
          {" "}
          {formatDate(new BigNumber(info.time).times(1000).toNumber())}{" "}
        </Amount>
      </DateText>
      <DateText bodyRegular color="gray400">
        Delegate: &nbsp;{" "}
        <Amount bodyRegular color="black">
          {" "}
          {reduceString(info.delegate, 7, 4)}{" "}
        </Amount>
      </DateText>
    </StyledDropdown>
  );
};

const StyledDropdown = styled(Card)`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  flex-direction: row;

  :hover {
    opacity: 0.5;
  }

  & + & {
    margin-top: 6px;
  }
`;

const LI = styled.div`
  display: flex;

  margin-top: 0.5px;
  margin-bottom: 10px;
`;

const DateText = styled(Text)`
  text-align: left;
  align-items: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

const Amount = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 900px) {
    font-size: 12px;
  }
`;

const StatRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 12px;
`;

const StatText = styled(Text)`
  display: flex;
  align-items: center;
  text-align: right;
`;

const StatTextHighlightLink = styled.a`
  width: 50%;
  text-align: right;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  :hover {
    color: var(--color-overgrown);
  }
`;

const StatTextHighlight = styled(Text)`
  width: 50%;
  text-align: right;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Content = styled(CardContent)`
  align-items: center;
  justify-content: space-between;
  height: 150px;
  margin-bottom: 50px;
`;
