import {createContext, CSSProperties, useContext, useEffect, useMemo, useState, type PropsWithChildren} from "react";
import {setGaUserProperties} from "../../../utils";
/*
import { getGPUTier } from 'detect-gpu';
*/

import {MicroPalette, microPalette} from "../../styles";

export const getWrapperStyle = (color: MicroPalette, skipContents = false) => ({
  display: skipContents ? undefined : "contents",
  "--color-mp-c100": microPalette[color]?.c100?.var,
  "--color-mp-c200": microPalette[color]?.c200?.var,
  "--color-mp-c300": microPalette[color]?.c300?.var,
  "--color-mp-c400": microPalette[color]?.c400?.var,
  "--color-mp-c500": microPalette[color]?.c500?.var,
});

const useUiContext = () => {
  const [color, setColor] = useState<MicroPalette>(Object.keys(microPalette)[0] as MicroPalette);
  const [performance, setPerformance] = useState<number>(-1);
  const [lavaFollowerId, setLavaFollowerId] = useState<string>();

  const wrapperStyle = useMemo((): CSSProperties => {
    return getWrapperStyle(color);
  }, [color]);

  useEffect(() => {
    //getGPUTier().then(_ => setPerformance(Math.min(2, _.tier)));
    if (localStorage.getItem("easterEgg") === "true") {
      setPerformance(2);
      setGaUserProperties({
        easter_egg: true,
      });
    } else {
      localStorage.setItem("easterEgg", "false");
    }
  }, []);

  return {
    color,
    setColor,
    wrapperStyle,
    performance,
    lavaFollowerId,
    setLavaFollowerId,
  };
};

type UiContextProps = ReturnType<typeof useUiContext>;
const UiContext = createContext<UiContextProps | null>(null);
export const UiProvider = ({children}: PropsWithChildren<{}>) => {
  const ui = useUiContext();
  return (
    <UiContext.Provider value={ui}>
      <div style={ui.wrapperStyle}>{children}</div>
    </UiContext.Provider>
  );
};

export const useUi = () => {
  const context = useContext(UiContext);
  if (context === null) {
    throw new Error("useUi must be used within a UiProvider");
  }
  return context;
};
