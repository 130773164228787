import styled from "styled-components";

import {mq, palette, typo, size, flex, card} from "../../styles";

export const noBalanceMq = mq(640);

export const Wrapper = styled.div`
  ${flex("center")}
  ${size.gap(3)}
`;

export const Button = styled.div<{
  wallet?: boolean;
  connect?: boolean;
  balance?: boolean;
  forceVisible?: boolean;
  gap?: number;
}>`
  ${typo.primary.medium}
  ${card("s")}
  ${flex("center")}
  ${(_) => size.gap(_.gap || 2)}
  ${size.padding(2)}

  cursor: pointer;

  ${(_) =>
    _.wallet &&
    `
    color: ${palette.overgrown};
  `}

  ${(_) =>
    _.balance &&
    !_.forceVisible &&
    `
    ${noBalanceMq(`
      display: none;
    `)}
  `}
`;

export const GasLessWrapper = styled.div<{balance?: boolean; forceVisible?: boolean}>`
  display: contents;

  ${(_) =>
    _.forceVisible &&
    noBalanceMq(`
      display: none;
    `)}
`;


export const WrongNetworkButton = styled(Button)`
  ${card("s", false, palette.errorMedium)}

  padding-right: ${size(3)};
`