import {memo, ComponentProps, useMemo, ReactElement, cloneElement, ComponentPropsWithoutRef, ReactNode} from "react";

import {withColor} from "../-with-color";

import greenL1 from "../../../assets/images/lava/green-L-1.png";
import green2 from "../../../assets/images/lava/green-bg-2.png";
import green3 from "../../../assets/images/lava/green-bg-3.png";

import purpleL1 from "../../../assets/images/lava/purple-L-1.png";
import purple2 from "../../../assets/images/lava/purple-bg-2.png";
import purple3 from "../../../assets/images/lava/purple-bg-3.png";

import {LavaEffect} from "../lava-effect";
import {Chip} from "../chip";
import {Asset} from "../../../model/Asset";
import {Flex} from "../flex";
import {Icon, IconAsset} from "../icon";
import {Text} from "../text";
import {Skeleton} from "../skeleton";

import * as SC from "./index.styles";
import {Divider} from "../divider";

interface LavaChipCommonProps extends ComponentPropsWithoutRef<typeof LavaEffect> {
  alt?: number;
  disabled?: boolean;
}

interface LavaChipGenericProps extends LavaChipCommonProps {
  heading: ReactNode;
  value: ReactNode;
  loading?: boolean;
  arrow?: boolean;
  micro?: boolean;
}

interface LavaChipGenericMicroProps extends Omit<LavaChipGenericProps, "value" | "micro"> {
  micro?: true;
  value?: undefined;
}

interface LavaChipTokensProps extends LavaChipCommonProps {
  tokenA: Asset;
  tokenB: Asset;
  subtitle?: string;
  payout?: Asset;
  chip?: ReactElement<ComponentProps<typeof Chip>, typeof Chip>;
}

export const LavaChip = memo(
  withColor(function LavaChipComponent(
    props: LavaChipGenericProps | LavaChipTokensProps | LavaChipGenericMicroProps,
    color,
    {performance},
  ) {
    const {alt, ...rest} = props;

    const bg = {
      green: alt ? (alt == 1 ? green2 : green3) : greenL1,
      purple: alt ? (alt == 1 ? purple2 : purple3) : purpleL1,
      aqua: "",
    }[color];

    const background = useMemo(() => {
      return performance === -1 ? (
        <SC.Img src={bg} />
      ) : (
        <SC.Effect>
          <LavaEffect {...rest} palette={color} performance={performance} />
        </SC.Effect>
      );
    }, [bg, color, props.leader, props.follower, performance]);

    if ("tokenA" in rest || !("heading" in rest)) {
      const {tokenA, tokenB, subtitle, chip, payout} = rest;
      return (
        <SC.Wrapper {...props} tokens>
          {background}
          <SC.TokenContent>
            <Flex grow justify="space-between">
              <Flex>
                <IconAsset stack asset={tokenA} button size="s" />
                <IconAsset stack asset={tokenB} button size="s" />
              </Flex>

              {payout ? (
                <Flex>
                  <IconAsset asset={payout} button size="s" />
                </Flex>
              ) : (
                <></>
              )}
            </Flex>

            <Flex gap={1} column>
              <Text h3 color="white">
                <Flex gap={3}>
                  {tokenA.symbol}
                  <Text color="gray400" inline>
                    —
                  </Text>
                  {tokenB.symbol}
                </Flex>
              </Text>
              <Text bodyRegular color="gray200">
                {subtitle}
              </Text>
            </Flex>
            {chip && <Flex justify="flex-end">{cloneElement(chip, {variant: "color"})}</Flex>}
          </SC.TokenContent>
        </SC.Wrapper>
      );
    }
    const {heading, loading, value, children, micro} = rest;
    return (
      <SC.Wrapper {...props}>
        {background}
        {rest.arrow && (
          <SC.ArrowWrapper>
            <Icon icon="arrow" color="c100" rotate={3} size={micro ? "l" : "xl"} />
          </SC.ArrowWrapper>
        )}

        <SC.Title hasChildren={!!children}>{heading}</SC.Title>

        {!micro && (
          <Skeleton width={200} active={loading} variant="color">
            <SC.Value>{value}</SC.Value>
          </Skeleton>
        )}

        {children && <Divider size={4} />}

        {children}
      </SC.Wrapper>
    );
  }),
);
