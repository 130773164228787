import styled, {css} from "styled-components";
import {transition} from "../../styles";

const buttonLargeStyles = css`
  width: auto;
  height: 47px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding-left: 24px;
  padding-right: 24px;
`;

const buttonMediumStyles = css`
  padding: 7px 16px;
  font-size: 16px;
  line-height: 24px;
`;

const buttonPrimaryStyles = css`
  background: var(--color-blue);
  border: 1px solid var(--color-blue);
  color: white;

  &:hover {
    opacity: 0.8;
    &:before {
      transform: scaleX(1);
    }
  }
  &:active,
  &:focus {
    opacity: 0.8;
  }

  &:before {
    border-radius: 14px;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.15);
    position: absolute;
    z-index: -1;
    transform: scaleX(0);
    transform-origin: 50%;
    transition: transform ease-in-out 0.5s;
  }
`;

const buttonSecondaryStyles = css`
  border: 1px solid var(--color-blue);
  color: var(--color-blue);
  background: var(--color-button-bg-dark);
  &:hover {
    border: 1px solid #f6f8fe;
  }
  &:active,
  &:focus {
    color: var(--color-blue);
    border: 1px solid #35415a;
  }
`;

const BaseButton = styled.button`
  ${transition.fast}

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: 600;
  color: #ffffff;
  border-radius: 14px;
  cursor: pointer;
  outline: none;

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
`;

export const ButtonPrimaryLarge = styled(BaseButton)`
  ${buttonPrimaryStyles}
  ${buttonLargeStyles}
`;

export const ButtonPrimaryMedium = styled(BaseButton)`
  ${buttonPrimaryStyles}
  ${buttonMediumStyles}
`;

export const ButtonSecondaryLarge = styled(BaseButton)`
  ${buttonSecondaryStyles}
  ${buttonLargeStyles}
`;

export const ButtonSecondaryMedium = styled(BaseButton)`
  ${buttonSecondaryStyles}
  ${buttonMediumStyles}
`;
