import {CloneMuteSwitchContractDetails} from "../factories/pair/models/clone-muteswitch-contract-details";
import {KoiContractContext} from "./koi-contract-context";

export const koiContracts = {
  getQuoterAddress: (cloneMuteSwitchContractDetails: CloneMuteSwitchContractDetails | undefined) => {
    if (cloneMuteSwitchContractDetails && cloneMuteSwitchContractDetails.v3Override) {
      return cloneMuteSwitchContractDetails.v3Override.quoterAddress;
    }

    return KoiContractContext.quoterAddress;
  },
  getPermit2Address: (cloneMuteSwitchContractDetails: CloneMuteSwitchContractDetails | undefined) => {
    if (cloneMuteSwitchContractDetails && cloneMuteSwitchContractDetails.v2Override) {
      return cloneMuteSwitchContractDetails.v2Override.permit2Address;
    }

    return KoiContractContext.permit2Address;
  },
  getFactoryAddress: (cloneMuteSwitchContractDetails: CloneMuteSwitchContractDetails | undefined) => {
    if (cloneMuteSwitchContractDetails && cloneMuteSwitchContractDetails.v2Override) {
      return cloneMuteSwitchContractDetails.v2Override.factoryAddress;
    }

    return KoiContractContext.factoryAddress;
  },

  getInitHash: (cloneMuteSwitchContractDetails: CloneMuteSwitchContractDetails | undefined) => {
    if (cloneMuteSwitchContractDetails && cloneMuteSwitchContractDetails.v3Override) {
      return cloneMuteSwitchContractDetails.v3Override.initHash;
    }

    return KoiContractContext.INIT_CODE_HASH;
  },
};
