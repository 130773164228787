const quickHash = (value: string) => {
  let hash = 0;
  if (value.length !== 0) {
    for (let i = 0; i < value.length; i++) {
      let chr = value.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0;
    }
  }
  return "Hx" + hash.toString(16).replace("-", "1").padEnd(12, "c01f1a1ce");
};

export const gaHash = quickHash;

export const sentGaEvent = (
  name: string,
  data?: Partial<Record<"value" | "label" | "category" | string, string | number>> | string | number,
  hashed = false,
) => {
  let {value, label, category, ...rest} = typeof data === "object" ? data : ({} as never);
  if (typeof data === "string" || typeof data === "number") {
    value = data;
  }
  if (hashed) {
    value = quickHash(String(value ?? ""));
  }
  try {
    (window as any).gtag("event", name, {
      custom_name: name,
      event_category: category,
      event_label: label,
      value: value,
      ...rest,
    });
  } catch {}
};

interface ExecutorResponse {
  success: boolean;
  tx?: string;
  gasless?: boolean | undefined;
}

export const sendTransactionGaEvent = async (
  name: `${string}_transaction`,
  category: string | undefined,
  executor: () => Promise<any>,
): Promise<any> => {
  sentGaEvent(name, {category, label: "started"});
  let res: ExecutorResponse = {success: false, gasless: undefined};
  try {
    res = await executor();
  } catch {}
  sentGaEvent(
    name,
    {
      category,
      label: res.success ? "success" : "error",
      value: res.success ? res.tx : undefined,
      gasless: JSON.stringify(res.gasless),
    },
    res.success,
  );
  return res;
};

export const setGaUserProperties = (props: Record<string, string | number | boolean | undefined>) =>
  (window as any).gtag("set", "user_properties", props);
