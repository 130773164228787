import styled from "styled-components";

import bannerImage from "../../assets/images/lava/green-bg-1.png";
import bannerMobileImage from "../../assets/images/lava/green-bg-1-m.png";
import {layoutMq} from "../../ui/components";

import {card, size, text} from "../../ui/styles";

export const Wrapper = styled.div`
  ${card("xl")}
  ${size.padding(8)}
  ${text.bodyRegular}
  
  background-image: url("${bannerImage}");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: 100% 0;

  ${layoutMq(`
    background-image: url("${bannerMobileImage}");
    background-size: 100% auto;
    background-position: 0 100%;
    padding-bottom: 54%;
  `)}
`;

export const Content = styled.div`
  max-width: 420px;
  margin-right: auto;
`;
