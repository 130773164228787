import {useQuery} from "@tanstack/react-query";
import {useMemo} from "react";
import {fetchGraphQL} from "../../../utils/gql-utils";
import {getGraphQLUrl} from "../../../web3/apollo/client";
import {AnalyticsGlobalData} from "./global";
import {transformTokens} from "./top-tokens.utils";

const tokenProps = `
  a: id
  s: symbol
  n: name
  l: totalValueLocked
  v: volumeUSD
  t: txCount
  p: derivedETH
`;

export const useTopTokensV3Data = (enabled?: boolean, globalData?: AnalyticsGlobalData, full?: boolean) => {
  const query = useQuery({
    queryKey: ["top-tokens-v3", full],
    queryFn: async () => {
      try {
        if (!globalData) throw "No global data";

        const url = getGraphQLUrl(true);
        const {
          data: {tokens},
        } = await fetchGraphQL(
          url,
          `
          query {
            tokens(first: ${full ? 100 : 20}, orderBy: volumeUSD, orderDirection: desc) {
              ${tokenProps}
            }
          }
        `,
        );
        const {
          data: {lastDayTokens},
        } = await fetchGraphQL(
          url,
          `
          query {
            lastDayTokens: tokens(block: {number: ${globalData.blocks.day}}, where: {id_in: ${JSON.stringify(tokens.map((_) => _.a))}}) {
              ${tokenProps}
            }
          }
        `,
        );
        const refs = {};
        tokens.forEach((_) => (refs[_.a] = _));
        lastDayTokens.forEach((_) => (refs[_.a].oneDayBefore = _));
        return tokens;
      } catch (e) {
        console.warn(e);
        throw e;
      }
    },
    enabled: !!globalData?.blocks?.day && enabled,
    refetchOnWindowFocus: false,
  });

  const data = useMemo(() => transformTokens(globalData, query.data) || [], [globalData, query.data]);

  return {
    ...query,
    tokens: data,
  };
};
