import styled from "styled-components";
import {flex, palette, size, text, typo} from "../../../../ui/styles";

const nCols = 6;
const sizeContent = (cols: number) => `calc(${100 / cols}% - ${(size.raw(3) * (cols - 1)) / cols}px)`;

export const getTableSize = (full?: boolean) => full ? "calc(100vh - 272px)" : "40vh";

export const TableCol = styled.div<{right?: boolean}>`
  ${text.bodyLarge}
  ${flex("center")}

  min-width: ${sizeContent(nCols)};
  max-width: ${sizeContent(nCols)};
  cursor: default;

  ${(_) =>
    _.right &&
    `
    ${typo.primary.normal}

    justify-content: flex-end;
  `}

  & > strong {
    color: ${palette.gray400};
  }
`;

export const NameWrapper = styled.div`
  max-width: calc(100% - ${size(10)});
`;

export const TitleColWrapper = styled.div`
  ${flex("center", "space-between")}

  width: calc((min(calc(100vw - ${size(12)}), 1080px) - 50px - ${size(3)}) / ${nCols} * ${nCols - 1});
  padding-right: ${size(3)};

  ${TableCol} {
    ${text.h7}

    min-width: ${sizeContent(nCols - 1)};
    max-width: ${sizeContent(nCols - 1)};
  }
`;

export const TooltipRow = styled.div`
  ${flex("center", "space-between")}
  ${size.gap(3)}
`;
