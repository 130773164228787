import {toIcon} from "./icon.shared";

export const coinUsdt = toIcon(
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.29583 0.651709L0.0137983 7.35744C0.00130927 7.38238 -0.00264844 7.41055 0.00250399 7.43784C0.00765643 7.46512 0.0216487 7.49009 0.0424346 7.50909L8.90641 15.7711C8.93159 15.7945 8.96513 15.8077 9.00001 15.8077C9.0349 15.8077 9.06843 15.7945 9.09361 15.7711L17.9576 7.5096C17.9784 7.49061 17.9924 7.46564 17.9975 7.43835C18.0027 7.41107 17.9987 7.38289 17.9862 7.35795L14.7042 0.652225C14.6936 0.629704 14.6766 0.610626 14.6551 0.597271C14.6337 0.583916 14.6087 0.576849 14.5833 0.576914H3.4178C3.39222 0.576605 3.36709 0.583502 3.34545 0.596773C3.32381 0.610045 3.30657 0.629124 3.29583 0.651709Z"
      fill="#50AF95"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.1386 8.04621C10.075 8.05085 9.74618 8.06993 9.01277 8.06993C8.42944 8.06993 8.01527 8.05291 7.86997 8.04621C5.61566 7.94975 3.93302 7.56804 3.93302 7.11101C3.93302 6.65399 5.61566 6.2728 7.86997 6.17479V7.66604C8.0174 7.67636 8.43951 7.7006 9.02285 7.7006C9.72284 7.7006 10.0734 7.67223 10.1365 7.66656V6.17582C12.386 6.27331 14.0649 6.65503 14.0649 7.11101C14.0649 7.567 12.3865 7.94871 10.1365 8.04569L10.1386 8.04621ZM10.1386 6.02159V4.68715H13.278V2.65222H4.73059V4.68715H7.86944V6.02108C5.31816 6.13507 3.39954 6.62665 3.39954 7.21573C3.39954 7.8048 5.31816 8.29587 7.86944 8.41038V12.6866H10.1381V8.40883C12.6835 8.29483 14.599 7.80377 14.599 7.21521C14.599 6.62665 12.6851 6.13559 10.1381 6.02108L10.1386 6.02159Z"
      fill="white"
    />
  </svg>,
  true,
);
