import styled from "styled-components";
import {br, flex, palette, size, text, typo} from "../../../ui/styles";

export const Chip = styled.span<{microHover?: boolean}>`
  ${br.xs}
  ${size.padding(0, 2, 0, 1)}
  ${size.gap(2)}
  ${text.h6}

  height: 32px;
  display: inline-flex;
  align-items: center;
  background: ${palette.gray50};
  position: relative;
  cursor: default;

  ${(_) =>
    _.microHover &&
    `
    ${size.padding(0, 1, 0, 1)}
    & > span {
      display: none;
    }
    &:hover {
      ${size.padding(0, 2, 0, 1)}

      & > span {
        display: inline;
      }
    }
  `}
`;

export const Row = styled.div`
  display: flex;
  height: 32px;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
`;

export const Svg = styled.svg`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
`;

export const Path = styled.path`
  fill: none;
  stroke: ${palette.gray200};
  stroke-width: 1px;
`;

export const WrapperTab = styled.div`
  ${br.s}
  ${text.bodyMedium}
  ${size.padding(1, 2)}
  ${size.margin(0, 0, 0, -4)}


  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: ${palette.azul};
  background: color-mix(in srgb, ${palette.azul} 20%, ${palette.white});
  display: inline-flex;
  box-shadow: 0 16px 0 color-mix(in srgb, ${palette.azul} 20%, ${palette.white});
`;
export const Wrapper = styled.div`
  ${flex("center")}
  ${br.s}
  ${size.padding(4)}
  ${size.margin(0, -4)}

  background: ${palette.white};
  border: 1px solid ${palette.gray100};
  position: relative;
  z-index: 2;
`;

export const ChipWrapper = styled.div`
  position: relative;
  z-index: 2;
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  color: ${palette.gray200};
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 0;
  display: flex;

  svg {
    min-width: 6px;
  }
`;

export const Border = styled.div`
  ${br.s}

  display: flex;
  flex-direction: column;

  &:hover ${Path} {
    animation-play-state: initial;
  }
`;

export const Tag = styled.span<{initial?: boolean}>`
  ${size.padding(0, 1.5)};
  ${typo.secondary.normal}
  ${br.m}

  background: ${palette.gray200};
  color: ${palette.black};
  font-size: 11px;
  line-height: 18px;
  height: 18px;

  ${(_) =>
    _.initial &&
    `
    ${typo.secondary.medium}

    justify-self: flex-start;
    color: ${palette.azul};
    background: color-mix(in srgb, ${palette.azul} 20%, ${palette.white});
  `}
`;
