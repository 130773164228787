import styled from "styled-components";
import {Transaction} from "../model/Transaction";
import {formatPrice, numberWithCommas} from "./formatNumber";

const BlockContainer = styled.span`
  overflow-wrap: anywhere;
`;
const InlineBlock = styled.span`
  display: inine-block;
  white-space: nowrap;
`;

export const transactionToText = (transaction: Transaction) => {
  switch (transaction.operation) {
    case "Remove LP":
    case "Add LP":
      return `$${numberWithCommas(transaction.amountUSD, 2)} ${transaction.fromPair}/${transaction.toPair}`;
    case "Swap":
      return (
        <BlockContainer>
          <InlineBlock>{`${formatPrice(transaction.amountIn, true)} ${transaction.fromPair}`}</InlineBlock>
          <InlineBlock>{` to ${formatPrice(transaction.amountOut, true)} ${transaction.toPair}`}</InlineBlock>
        </BlockContainer>
      );
  }

  return "---";
};
