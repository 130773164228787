import {toIcon} from "./icon.shared";

export const coinZk = toIcon(
  <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.9998 5.22122L12.8944 0.130936V3.85792L7.82568 7.58924L12.8944 7.59355V10.3094L17.9998 5.22122Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 5.21907L5.10543 10.3072V6.61049L10.1741 2.84893L5.10543 2.84461V0.130936L0 5.21907Z"
      fill="black"
    />
  </svg>,
  true,
);
