import COINGECKO_LIST from "./cg_zksync";
import MUTE_TOKEN_LIST from "./custom_zksync";
import { sophonsepolia } from "../../providers/Providers";
import { zkSync } from "viem/zksync";
import SOPHON_SEPHOLIA_TOKEN from "./custom_sophon_sepolia";

export default function getTokenList(id) {
    switch (id) {
        case zkSync.id:
            return [
                ...MUTE_TOKEN_LIST,
                ...COINGECKO_LIST
            ]
        case sophonsepolia.id:
            return [...SOPHON_SEPHOLIA_TOKEN]
        default:
            return [
                ...MUTE_TOKEN_LIST,
                ...COINGECKO_LIST,
            ]
    }
}