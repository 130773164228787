import {toIcon} from "./icon.shared";

export const menu = toIcon(
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 5.33325H28V7.99992H4V5.33325ZM4 14.6666H28V17.3333H4V14.6666ZM4 23.9999H28V26.6666H4V23.9999Z"
      fill="#191919"
    />
  </svg>,
);
