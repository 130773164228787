import React, {useEffect, useState, useCallback, useMemo} from "react";
import {useAccount, useSwitchChain, useBalance, useChainId} from "wagmi";
import {zkSync} from "wagmi/chains";
import { watchAccount, watchChainId } from '@wagmi/core'

import {useEthersProvider} from "../providers/ethers";
import {useZkSyncProvider} from "../providers/zksync";
import { sophonsepolia } from "../providers/Providers";

import {AllowanceProvider, MaxAllowanceTransferAmount, AllowanceTransfer, PermitSingle} from "./permit2";

import axios from "axios";
import {Contract, utils, Provider, BrowserProvider} from "zksync-ethers";
import BigNumber from "bignumber.js";

// import {multicall} from "@argent/era-multicall";
//import { multicallMute, isSupported, getDeployedAA, handleDeployAA } from './multicall/index';
import UnknownToken from "../assets/images/icons/unknown_token.png";

import getContracts from "./contracts/contracts";
import {getSwapData, getWrapData, getUnwrapData} from "./router";

import {Token} from "@uniswap/sdk-core";
import { v3PoolAddress} from "./v3/liqPositions";
import {getFullPool} from "./v3/liquidityHelper";

import {
  FACTORY,
  ROUTER,
  PAIR,
  WETH,
  BOND,
  ERC20,
  DMUTE,
  AMPLIFIER,
  MULTICALL,
  VEMUTE,
  MUTE_ORACLE,
  AMPLIFIER_REDUX,
  AMPLIFIER_TREASURY,
  KOI_CONVERSION,
  VEKOI,
  UNIVERSAL_ROUTER,
  IUniswapV3PoolABI,
  IUniswapV3Factory,
  NonfungiblePositionManager,
  VE_REWARDS,
} from "./ABI/index";

//import CACHED_POOLS from './pools/cachedPools';

import {GLOBAL_INFO, SWAP_HISTORY, TOKEN_PRICES, TOP_PAIRS, LIQ_POSITIONS} from "./apollo/queries";
import { GLOBAL_INFO_V3 } from "./apollo/queriesV3";
import {getClient, getClientV3} from "./apollo/client";

//import { MuteSwitchPair, TradeContext, MuteSwitchPairSettings, TradeDirection } from 'simple-muteswitch-sdk'
import {MuteSwitchPair} from "../sdk/factories/pair/muteswitch-pair";
import {TradeContext, TradeContextSmart} from "../sdk/factories/pair/models/trade-context";
import {MuteSwitchPairSettings} from "../sdk/factories/pair/models/muteswitch-pair-settings";
import {TradeDirection} from "../sdk/factories/pair/models/trade-direction";
import {Multicall} from "./multicall/multicall";
import {Utils} from "./multicall/utils";
import {ContractCallResults, ContractCallContext} from "./multicall/models";
import * as ethers from "ethers";
import {store} from "../state";
import {useWalletDispatch} from "../state/hooks";
import {initialState, walletSlice} from "../state/reducer";
import {zeroAddress} from "viem";
import { wagmiConfig } from "../providers/Providers";

import {Address, Asset, FeeAsset} from "./types";
import {MulticallExecutor} from "./walletHookUtils/multicall";
import {Pool, TickMath} from "@uniswap/v3-sdk";
import {selectFarms} from "../state/selectors";
import {sendSponsoredPaymaster} from "./sponsored-paymaster";
import getTokenList from "./tokens";

const abiDecoder = require("abi-decoder");
abiDecoder.addABI(ROUTER);

const approve_amount = "0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF";
const min_approve_amount = new BigNumber(approve_amount).div(2);

BigNumber.config({ROUNDING_MODE: BigNumber.ROUND_DOWN});

export const walletHookContext = React.createContext<any>(undefined);

export const useWalletHookFn = () => {
  const dispatch = useWalletDispatch();

  const {address, chain} = useAccount();
  const chainId = useChainId()

  const account = address;

  const provider = useEthersProvider({chainId});
  const zk_signer = useZkSyncProvider({chainId});

  const wagmiBal = useBalance({
    address: account,
    query: {enabled: true, refetchInterval: 1_000 * 5},
  });

  // 0 not loaded, 1 loading, 2 loaded
  const calculatingTrade = React.useRef<any>([]);

  const [eth_bal, setETHBal] = useState<any>(null);
  const [initialized, setInitialized] = useState(false);

  const [loadedInitialList, setLoadedInitialList] = useState(false);

  const TOKEN_STORAGE = useMemo(() => "TOKEN_STORAGE_" + String(chainId), [chainId]); 
  const LIQ_STORAGE = useMemo(() => "LIQUIDITY_STORAGE_" + String(chainId), [chainId]); 
  const SAVE_STORAGE = useMemo(() => "SAVEDATA_" + String(chainId), [chainId]); 

  const client = useMemo(() => getClient(chainId), [chainId]);
  const clientV3 = useMemo(() => getClientV3(chainId), [chainId]);
  const CONTRACTS = useMemo(() => getContracts(chainId), [chainId]);

  var saveStorage: any =
    localStorage.getItem(SAVE_STORAGE) == null ? {} : JSON.parse(localStorage.getItem(SAVE_STORAGE)!);

  if (saveStorage.addedTokens == null) {
    saveStorage.addedTokens = ["ETH"];
    localStorage.setItem(SAVE_STORAGE, JSON.stringify(saveStorage));
  }

  if (saveStorage.addedLiquidity == null) {
    saveStorage.addedLiquidity = [];
    localStorage.setItem(SAVE_STORAGE, JSON.stringify(saveStorage));
  }

  const [tokenList, setTokenList] = useState<any[]>([]);

  useEffect(() => {
    addNewTokens();
    const fetchData = async () => {
      var start = Date.now();
      await Promise.all([/*getTokenPrices(), getTopPairs(),*/ getTXHistory()]);
      console.log("fetchData(): " + (Date.now() - start) / 1000);
      await Promise.all([getPairAPYs(), getMerkleCampaigns()]);
      synchronizeMerkl()
      await Promise.all([getAmplifierV2Info()]);
      synchronizeFarmsV2();
    };

    fetchData();
  }, []);

  useEffect(() => {
    // should be called once on dapp load once accounts and signers are configured
    if (wagmiBal.data && provider && account && zk_signer) {
      setETHBal(wagmiBal.data?.value);
      let decimals = 18;
      let balance = new BigNumber(wagmiBal.data?.value.toString()).div(Math.pow(10, 18)).toFixed();
      let address = CONTRACTS.gas.address.toLowerCase()
      let symbol = CONTRACTS.gas.symbol
      let name = CONTRACTS.gas.name
      let logo = CONTRACTS.gas.logo;
      let active = true;
      dispatch(walletSlice.actions.updateToken({balance, symbol, address, decimals, name, logo, active}));
    }
  }, [wagmiBal, provider, account, zk_signer]);

  const chainSafetyCheck = async () => {
    if (chain && (chainId === zkSync.id || chainId === sophonsepolia.id)) return;
    throw "Wrong network";
  };

  const fetchData = async (apy = false) => {
      //getTokenPrices()
      var timeer = Date.now();

      console.log("Get Balance: " + (Date.now() - timeer) / 1000);
      timeer = Date.now();
      await Promise.all([onGetBalance(), getTXHistory()]);
      console.log("getTXHistory & onGetBalance: " + (Date.now() - timeer) / 1000);

      if(apy)
        await getPairAPYs()

      timeer = Date.now();
      await Promise.all([
        getLiquidityBalance(),
        getUserMerkleRewards(),
        /*getBondInfo(),*/ getDAOInfo(),
        getAmplifierV2Info(),
        getAmplifierPools(),
      ]);

      synchronizeMerkl()

      console.log("getLiquidityBalance & getBondInfo: " + (Date.now() - timeer) / 1000);
      timeer = Date.now();
      //await Promise.all([getAmplifierV2Info(), getAmplifierPools()]);
      synchronizeFarmsV2();
      console.log("getFarms: " + (Date.now() - timeer) / 1000);
  };

  useEffect(() => {
    if (provider && account && eth_bal != null && initialized == false && loadedInitialList == false) {
      setInitialized(true);
      fetchData().then(() => {});
    }
  }, [eth_bal]);

  //wipe data and update if chain change
  useEffect(() => {
    if (provider && account && eth_bal != null && initialized && loadedInitialList) {
      console.log('reset')
      dispatch(walletSlice.actions.reset());
      fetchData(true).then(() => {});
    }
  }, [chainId, account]);

  const handleTransaction = async (...args: Parameters<typeof sendSponsoredPaymaster>) => {
    const [method, signer, params = [], extraParams = {}] = args;

    const paymasterParams = await getPaymasterParams();
    const additionalParams = paymasterParams.customData
      ? Utils.deepClone(paymasterParams)
      : {gasLimit: paymasterParams.gasLimit, customData: null};

    try {
      let gasEst = await method.estimateGas(...params, {...extraParams, gasLimit: null});
      additionalParams.gasLimit = BigInt(gasEst.toString());
      paymasterParams.gasLimit = BigInt(gasEst.toString());
    } catch (e) {
      console.log(e);
      throw e
    }

    estimateGasRefund(paymasterParams.gasPrice.toString(), paymasterParams.gasLimit.toString());
    const {isSponsored, tx} = await sendSponsoredPaymaster(method, signer, params, {
      ...additionalParams,
      ...extraParams,
    });
    const gasless = await isSponsored;
    estimateGasRefund(0, 0, gasless);

    const txResponse = await tx;
    const extra = {
      startTime: Date.now(),
      paymasterParams,
      additionalParams,
      gasless,
      waitAndResult: async () => {
        const receipt = await txResponse.wait();
        return getTransactionResults(extra.paymasterParams, receipt, extra.startTime);
      },
    };
    return Object.assign(txResponse, extra);
  };

  const getNodeUrl = () => {
    if (chain!.rpcUrls.default.http[0] && process.env.NODE_ENV == "development") {
      //return InfraApi
      return chain!.rpcUrls.default.http[0];
    }

    return chain!.rpcUrls.default.http[0];
    //return InfraApi
  };
  const setFeeAsset = useCallback(
    (address) => {
      dispatch(walletSlice.actions.updateFeeAsset(address.toLowerCase()));
    },
    [dispatch, walletSlice],
  );

  const getFeeAssetData = useCallback(
    (address: Address): FeeAsset => {
      return store.getState().tokens[address];
    },
    [store],
  );

  const getFeeAsset = useCallback((): FeeAsset => {
    return getFeeAssetData(store.getState().feeAsset);
  }, [getFeeAssetData]);

  const getFeeAssets = useCallback(() => {
    return [
      {asset: getFeeAssetData(CONTRACTS.gas.address.toLowerCase()), discount: 0},
      /*
      {asset: getFeeAssetData(CONTRACTS.KOI.toLowerCase()), discount: 30},
      {asset: getFeeAssetData(CONTRACTS.ZKSYNC.toLowerCase()), discount: 15},
      {asset: getFeeAssetData(CONTRACTS.USDC.toLowerCase()), discount: 15},
      {asset: getFeeAssetData(CONTRACTS.USDT.toLowerCase()), discount: 15},
      {asset: getFeeAssetData(CONTRACTS.WBTC.toLowerCase()), discount: 15},
      {asset: getFeeAssetData(CONTRACTS.ZORRO.toLowerCase()), discount: 15},
      {asset: getFeeAssetData(CONTRACTS.HOLD.toLowerCase()), discount: 0},
      */
    ];
  }, [getFeeAssetData]);

  const updateAmp = async () => {
    let _amplifiers: any = [];
    let _ampPools = await getAmplifierPools();
    for (let i in _ampPools) {
      let _amp = _ampPools[i];

      /*
      if(!(new BigNumber(_amp.endTime).lt(Date.now() / 1000) && new BigNumber(_amp.totalUserStake).lte(0)))
        _amplifiers.push(_amp)     
      */
    }
    dispatch(walletSlice.actions.updateAmplifiers(_amplifiers));
  };

  const addNewTokens = () => {
    var tokenStorage: any = null; // localStorage.getItem(TOKEN_STORAGE)
    let _tokens_temp: any[] = [];
    var directory = getTokenList(chainId)

    directory = directory.filter((value, index) => {
      const _value = JSON.stringify(value.address.toLowerCase());
      return (
        index ===
        directory.findIndex((obj) => {
          return JSON.stringify(obj.address.toLowerCase()) === _value;
        })
      );
    });

    let res = directory;
    for (let i in res) {
        let name = res[i].name;
        let symbol = res[i].symbol;
        let address = res[i].address.toLowerCase();
        let logo = res[i].logoURI;
        let decimals = res[i].decimals;
        let balance = "0";
        let active = true;

        if (symbol == "DMUTE") active = false;

        _tokens_temp.push({
          name,
          symbol,
          address,
          logo,
          decimals,
          balance,
          active,
        });
    }
    

    if (tokenStorage == null) {
      localStorage.setItem(TOKEN_STORAGE, JSON.stringify(_tokens_temp));
    } else {
      _tokens_temp = _tokens_temp.concat(JSON.parse(tokenStorage));
    }

    _tokens_temp = _tokens_temp.filter((value, index) => {
      const _value = JSON.stringify(value.address.toLowerCase());
      return (
        index ===
        _tokens_temp.findIndex((obj) => {
          return JSON.stringify(obj.address.toLowerCase()) === _value;
        })
      );
    });

    setTokenList(_tokens_temp);

    var allTokens: any[] = [];
    for (let item in _tokens_temp) {
      if (_tokens_temp[item].active == true) {
        if (_tokens_temp[item].address.toLowerCase() == CONTRACTS.gas.address.toLowerCase()) {
          let decimals = _tokens_temp[item].decimals;
          let balance = Object.assign(new BigNumber(0).toFixed(), {unknown: true});
          let address = _tokens_temp[item].address;
          let symbol = _tokens_temp[item].symbol;
          let name = _tokens_temp[item].name;
          let logo = _tokens_temp[item].logo;
          let active = true;

          allTokens.push({balance, symbol, address, decimals, name, logo, active});
        } else {
          var decimals = _tokens_temp[item].decimals;
          var address = _tokens_temp[item].address;
          let symbol = _tokens_temp[item].symbol;
          let name = _tokens_temp[item].name;
          let logo = _tokens_temp[item].logo;
          let val = new BigNumber(0).toFixed();
          let active = true;
          allTokens.push({balance: val, symbol, address, decimals, name, logo, active});
        }
      }
    }

    dispatch(walletSlice.actions.updateTokens(allTokens));
  };

  const onGetBalance = async () => {
    try {
      var contractCallContext: ContractCallContext[] = [];
      for (let item in tokenList) {
        if (tokenList[item].active == true) {
          if (tokenList[item].address.toLowerCase() == CONTRACTS.gas.address.toLowerCase()) {
            //@ts-ignore
            //const eth_bal_local = await provider!.getSigner().getBalance();
            let decimals = tokenList[item].decimals;
            let balance = new BigNumber(eth_bal!.toString()).div(Math.pow(10, 18)).toFixed();
            let address = tokenList[item].address;
            let symbol = tokenList[item].symbol;
            let name = tokenList[item].name;
            let logo = tokenList[item].logo;
            let active = true;

            dispatch(walletSlice.actions.updateToken({balance, symbol, address, decimals, name, logo, active}));
          } else {
            contractCallContext.push({
              reference: item,
              contractAddress: tokenList[item].address,
              abi: ERC20,
              calls: [
                {reference: item, methodName: "balanceOf", methodParameters: [account]},
                {reference: item, methodName: "decimals", methodParameters: []},
              ],
            });
          }
        }
      }

      const multicall = new Multicall({ethersProvider: provider, tryAggregate: true, network: {chainId, name: chain!.name}});

      contractCallContext.push({
        reference: "time",
        contractAddress: CONTRACTS.multicall,
        abi: MULTICALL,
        calls: [{reference: "time", methodName: "getCurrentBlockTimestamp", methodParameters: []}],
      });

      var results: ContractCallResults = await multicall.call(contractCallContext);
      var allTokens: any[] = [];

      for (let i in results.results) {
        if (results.results[i].callsReturnContext[0].success == false) continue;
        if (i == "time") {
          dispatch(
            walletSlice.actions.updateChainDelayed(
              new BigNumber(results.results[i].callsReturnContext[0].returnValues[0]).lte(Date.now() / 1000 - 60 * 5),
            ),
          );
        } else {
          var decimals = new BigNumber(results.results[i].callsReturnContext[1].returnValues[0]).toNumber();
          var address = tokenList[i].address;
          let symbol = tokenList[i].symbol;
          let name = tokenList[i].name;
          let logo = tokenList[i].logo;
          let val = new BigNumber(results.results[i].callsReturnContext[0].returnValues[0])
            .div(Math.pow(10, decimals))
            .toFixed();
          let active = true;

          allTokens.push({balance: val, symbol, address, decimals, name, logo, active});
          //dispatch(walletSlice.actions.updateToken({balance: val, symbol, address, decimals, name, logo, active}))
        }
      }

      dispatch(walletSlice.actions.updateTokens(allTokens));
      setLoadedInitialList(true);
    } catch (e) {
      console.log("error finding balances! ! ");
      console.log(e);
    }
  };

  const addTokenToStorage = (new_token) => {
    let _tokens = tokenList;
    // @ts-ignore
    let dupe = _tokens.filter((e) => e.address.toLowerCase() == new_token.address.toLowerCase()).length > 0;
    if (dupe == false) {
      // @ts-ignore
      new_token.active = true;
      // @ts-ignore
      _tokens.push(new_token);
    } else {
      for (let i in _tokens) {
        // @ts-ignore
        if (new_token.address.toLowerCase() == _tokens[i].address.toLowerCase()) {
          // @ts-ignore
          _tokens[i].active = true;
          break;
        }
      }
    }

    // @ts-ignore
    localStorage.setItem(TOKEN_STORAGE, JSON.stringify(_tokens));
    setTokenList(_tokens);
  };

  const addLiquidityToStorage = (pair) => {
    try {
      let _addedLiquidity = Utils.deepClone(store.getState().addedLiquidity);
      _addedLiquidity.push(pair);
      _addedLiquidity = _addedLiquidity.map((x) => x.toLowerCase());
      _addedLiquidity = Array.from(new Set(_addedLiquidity));
      var saveStorage: any =
        localStorage.getItem(SAVE_STORAGE) == null ? {} : JSON.parse(localStorage.getItem(SAVE_STORAGE)!);
      saveStorage.addedLiquidity = _addedLiquidity;

      localStorage.setItem(SAVE_STORAGE, JSON.stringify(saveStorage));

      dispatch(walletSlice.actions.updateAddedLiquidity(_addedLiquidity));
    } catch (e) {
      console.log(e);
    }
  };

  const addTokenToWallet = async (token) => {
    if (provider) {
      try {
        if (token.address.toLowerCase() != CONTRACTS.gas.address.toLowerCase()) {
          // wasAdded is a boolean. Like any RPC method, an error may be thrown.
          const wasAdded = await (provider as ethers.ethers.JsonRpcProvider).send("wallet_watchAsset", {
            type: "ERC20", // Initially only supports ERC20, but eventually more!
            options: {
              address: token.address, // The address that the token is at.
              symbol: token.symbol, // A ticker symbol or shorthand, up to 5 chars.
              decimals: token.decimals, // The number of decimals in the token
              image: token.logo, // A string url of the token logo
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const isETH = (token) => {
    return token.toLowerCase() == CONTRACTS.gas.wrapped_address.toLowerCase() || token.toLowerCase() == CONTRACTS.gas.address.toLowerCase();
  };

  const wethOrToken = (token) => {
    if (token.toLowerCase() == CONTRACTS.gas.wrapped_address.toLowerCase() || token.toLowerCase() == CONTRACTS.gas.address.toLowerCase())
      return CONTRACTS.gas.wrapped_address;

    return token;
  };

  const isETHSolo = (token) => {
    if (token.toLowerCase() == CONTRACTS.gas.address.toLowerCase()) return true;
    else return false;
  };

  const isStable = (token) => {
    return CONTRACTS.stables.some(e => e.toLowerCase() == token.toLowerCase())

    /*
      return (
      token == CONTRACTS.USDC.toLowerCase() ||
      token == CONTRACTS.USDT.toLowerCase() ||
      token == CONTRACTS.USDC_NATIVE.toLowerCase()
    );
    */
  };

  const getETH = () => {
    return store.getState().tokens[CONTRACTS.gas.address];
  };


  const getKoiToken = () => {
    return store.getState().tokens[CONTRACTS.KOI.toLowerCase()];
  };

  const getKoiTokenPrice = () => {
    if (store.getState().tokens[CONTRACTS.KOI.toLowerCase()])
      return store.getState().tokens[CONTRACTS.KOI.toLowerCase()].price;

    return "0.00";
  };

  const getVeRewards = () => {
    return CONTRACTS.ve_rewards.map((x, index) => (getFeeAssetData(x.toLowerCase())));
  };

  const getVeRewardsRaw = () => {
    return CONTRACTS.ve_rewards
  };

  const isUSDC = (token) => {
    if (
      token.toLowerCase() == CONTRACTS.USDC.toLowerCase() ||
      token.toLowerCase() == CONTRACTS.USDC_NATIVE.toLowerCase()
    )
      return true;
    else return false;
  };

  const currentSwapCost = () => {
    return new BigNumber(store.getState().ethPrice)
      .times(store.getState().swapCostPerGas)
      .times(store.getState().gasFee)
      .toFixed(2);
  };

  const networkChanged = (networkId) => {
    if (!new BigNumber(networkId).eq("0x144")) {
      dispatch(walletSlice.actions.updateWrongChain(true));
    } else {
      dispatch(walletSlice.actions.updateWrongChain(false));
    }
  };

  const getSignerAddress = () => {
    return account;
  };

  const getZkSigner = async () => {
    return await (zk_signer as BrowserProvider)?.getSigner();
  };

  const searchForAllLiquidity = async (to, _addedLiquidity) => {
    try {
      // @ts-ignore
      const multicall = new Multicall({ethersProvider: provider, tryAggregate: true, network: {chainId, name: chain!.name}});
      var contractCallContext: ContractCallContext[] = [];
      var resultArray: any = {};
      for (let item = 0; item < _addedLiquidity.length; item++) {
        // @ts-ignore
        let pair = _addedLiquidity[item];

        if (pair == "0x000000000000000000000000000000000") continue;

        contractCallContext.push({
          reference: pair.toLowerCase() + "_" + String(item),
          contractAddress: pair,
          abi: PAIR,
          calls: [
            {reference: "balance", methodName: "balanceOf", methodParameters: [to]},
            {reference: "reserves", methodName: "getReserves", methodParameters: []},
            {reference: "totalSupply", methodName: "totalSupply", methodParameters: []},
            {reference: "token0", methodName: "token0", methodParameters: []},
            {reference: "token1", methodName: "token1", methodParameters: []},
            {reference: "symbol", methodName: "symbol", methodParameters: []},
            {reference: "token1", methodName: "token1", methodParameters: []},
            {reference: "pairFee", methodName: "pairFee", methodParameters: []},
            {reference: "delegates", methodName: "delegates", methodParameters: [to]},
            {reference: "approveLP", methodName: "allowance", methodParameters: [to, CONTRACTS.router]},
            {reference: "stable", methodName: "stable", methodParameters: []},
          ],
        });
      }

      const multiRes: ContractCallResults = await multicall.call(contractCallContext);
      const contractCallContextTokens: ContractCallContext[] = [];

      for (let i in multiRes.results) {
        if (multiRes.results[i].callsReturnContext[0].success == false) continue;
        var pair = i.split("_")[0].toLowerCase();
        var index = i.split("_")[1];
        var bal = multiRes.results[i].callsReturnContext[0].returnValues[0];
        var reserves = [
          multiRes.results[i].callsReturnContext[1].returnValues[0],
          multiRes.results[i].callsReturnContext[1].returnValues[1],
        ];
        var supply = multiRes.results[i].callsReturnContext[2].returnValues[0];
        var token0 = multiRes.results[i].callsReturnContext[3].returnValues[0].toLowerCase();
        var token1 = multiRes.results[i].callsReturnContext[4].returnValues[0].toLowerCase();

        var reserveANormalized = new BigNumber(reserves[0]); //.div(Math.pow(10, pair1.decimals))
        var reserveBNormalized = new BigNumber(reserves[1]); //.div(Math.pow(10, pair2.decimals))
        var pairFeePercent = new BigNumber(multiRes.results[i].callsReturnContext[7].returnValues[0])
          .div(100)
          .toFixed(2);

        resultArray[pair.toLowerCase()] = {
          hasLiq: new BigNumber(bal).gt(0),
          available: true,
          assetAAmount: new BigNumber(bal).div(supply).times(reserveANormalized).toFixed(),
          assetBAmount: new BigNumber(bal).div(supply).times(reserveBNormalized).toFixed(),
          assetATotal: reserveANormalized.toFixed(),
          assetBTotal: reserveBNormalized.toFixed(),
          address: pair,
          pairFeePercent,
          symbol: multiRes.results[i].callsReturnContext[5].returnValues[0],
          approveAssetLP: new BigNumber(multiRes.results[i].callsReturnContext[9].returnValues[0]).lte(
            min_approve_amount,
          ),
          delegate: multiRes.results[i].callsReturnContext[8].returnValues[0],
          balance: new BigNumber(bal).div(Math.pow(10, 18)).toFixed(),
          share: new BigNumber(bal).div(supply).times(100).toFixed(2),
          index: index,
          stable: multiRes.results[i].callsReturnContext[10].returnValues[0],
        };

        contractCallContextTokens.push(
          {
            reference: pair.toLowerCase() + "_0_" + token0.toLowerCase(),
            contractAddress: token0,
            abi: ERC20,
            calls: [
              {reference: "approve", methodName: "allowance", methodParameters: [to, CONTRACTS.router]},
              {reference: "decimals", methodName: "decimals", methodParameters: []},
              {reference: "symbol", methodName: "symbol", methodParameters: []},
            ],
          },
          {
            reference: pair.toLowerCase() + "_1_" + token1.toLowerCase(),
            contractAddress: token1,
            abi: ERC20,
            calls: [
              {reference: "approve", methodName: "allowance", methodParameters: [to, CONTRACTS.router]},
              {reference: "decimals", methodName: "decimals", methodParameters: []},
              {reference: "symbol", methodName: "symbol", methodParameters: []},
            ],
          },
        );
      }

      const multiResTokens: ContractCallResults = await multicall.call(contractCallContextTokens);

      for (let i in multiResTokens.results) {
        if (multiResTokens.results[i].callsReturnContext[0].success == false) continue;

        var pair = i.split("_")[0].toLowerCase();
        var id = i.split("_")[1];
        var token = i.split("_")[2];
        let _decimals = multiResTokens.results[i].callsReturnContext[1].returnValues[0];
        var symbol = multiResTokens.results[i].callsReturnContext[2].returnValues[0];
        if (id == "0") {
          resultArray[pair.toLowerCase()].assetASymbol = symbol;
          resultArray[pair.toLowerCase()].assetA = token;
          resultArray[pair.toLowerCase()].approveAssetA =
            token.toLowerCase() == CONTRACTS.gas.address.toLowerCase()
              ? false
              : new BigNumber(multiResTokens.results[i].callsReturnContext[0].returnValues[0]).lte(min_approve_amount);
          resultArray[pair.toLowerCase()].assetAAmount = new BigNumber(resultArray[pair.toLowerCase()].assetAAmount)
            .div(Math.pow(10, _decimals))
            .toFixed();
          resultArray[pair.toLowerCase()].assetATotal = new BigNumber(resultArray[pair.toLowerCase()].assetATotal)
            .div(Math.pow(10, _decimals))
            .toFixed();
        } else {
          resultArray[pair.toLowerCase()].assetB = token;
          resultArray[pair.toLowerCase()].assetBSymbol = symbol;
          resultArray[pair.toLowerCase()].approveAssetB =
            token.toLowerCase() == CONTRACTS.gas.address.toLowerCase()
              ? false
              : new BigNumber(multiResTokens.results[i].callsReturnContext[0].returnValues[0]).lte(min_approve_amount);
          resultArray[pair.toLowerCase()].assetBAmount = new BigNumber(resultArray[pair.toLowerCase()].assetBAmount)
            .div(Math.pow(10, _decimals))
            .toFixed();
          resultArray[pair.toLowerCase()].assetBTotal = new BigNumber(resultArray[pair.toLowerCase()].assetBTotal)
            .div(Math.pow(10, _decimals))
            .toFixed();
        }
      }

      return resultArray;
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  const searchForAllLiquidityV3 = async () => {
    try {
      const to = getSignerAddress();
      const nftManager = new Contract(
        CONTRACTS.nonfungibleTokenPositionManagerAddress,
        NonfungiblePositionManager.abi,
        provider,
      );

      const numPositions = await nftManager.balanceOf(to);

      if (numPositions == 0) return [];

      const _calls: any[] = [];
      for (let i = 0; i < numPositions; i++) {
        _calls.push({
          reference: "tokenOfOwnerByIndex_" + String(i),
          methodName: "tokenOfOwnerByIndex",
          methodParameters: [to, i],
        });
      }

      // @ts-ignore
      const multicall = new Multicall({ethersProvider: provider, tryAggregate: true, network: {chainId, name: chain!.name}});
      const executor = new MulticallExecutor(multicall);

      const idsRes = await executor.execute({
        reference: "tokenOfOwnerByIndex",
        contractAddress: CONTRACTS.nonfungibleTokenPositionManagerAddress,
        abi: NonfungiblePositionManager.abi,
        calls: _calls,
      });

      const nftIds: string[] = idsRes.tokenOfOwnerByIndex.callsReturnContext
        .filter((_) => _.success)
        .map((_) => _.returnValues[0]);

      const MAX_UINT128 = new BigNumber(2).pow(128).minus(1).toFixed();

      const positionsRes = await executor.execute({
        reference: "positions",
        contractAddress: CONTRACTS.nonfungibleTokenPositionManagerAddress,
        abi: NonfungiblePositionManager.abi,
        calls: nftIds
          .map((id) => [
            {reference: "positions_" + id, methodName: "positions", methodParameters: [id]},
            {
              reference: "collect_" + id,
              methodName: "collect",
              methodParameters: [{tokenId: id, recipient: to, amount0Max: MAX_UINT128, amount1Max: MAX_UINT128}],
            },
          ])
          .flat(),
      });

      const positions = positionsRes.positions.callsReturnContext
        .filter((_) => _.success)
        .map((context) => ({
          id: context.methodParameters[0],
          nonce: context.returnValues[0],
          operator: context.returnValues[1],
          token0: context.returnValues[2],
          token1: context.returnValues[3],
          fee: context.returnValues[4],
          tickLower: context.returnValues[5],
          tickUpper: context.returnValues[6],
          liquidity: context.returnValues[7],
          feeGrowthInside0LastX128: context.returnValues[8],
          feeGrowthInside1LastX128: context.returnValues[9],
          tokensOwed0: context.returnValues[10],
          tokensOwed1: context.returnValues[11],
          address: v3PoolAddress(
            CONTRACTS.v3CoreFactoryAddress,
            CONTRACTS.INIT_CODE_HASH_v3,
            context.returnValues[2],
            context.returnValues[3],
            context.returnValues[4],
          ),
          asset0: {} as Asset,
          asset1: {} as Asset,
          pool: {} as Pool,
          v3: true as const,
        }));

      const tokensInfo: Record<string, any> = {};
      const owner = getSignerAddress();
      const spender = CONTRACTS.router;

      positions
        .map((_) => [_.token0, _.token1])
        .flat()
        .filter((_, i, list) => list.indexOf(_) === i)
        .forEach((address) => {
          executor.listen("erc20", [address, owner, spender], (data) => {
            tokensInfo[address?.toLowerCase()] = data;
          });
        });

      await executor.execute();

      const poolRes = await executor.execute(
        ...positions.map((id) => ({
          reference: id.id,
          contractAddress: v3PoolAddress(
            CONTRACTS.v3CoreFactoryAddress,
            CONTRACTS.INIT_CODE_HASH_v3,
            id.token0,
            id.token1,
            id.fee,
          ),
          abi: IUniswapV3PoolABI.abi,
          calls: [
            {reference: "slot0", methodName: "slot0", methodParameters: []},
            {reference: "liquidity", methodName: "liquidity", methodParameters: []}
          ],
        })),
        ...positions.map((id) => ({
          reference: id.id + '_0',
          contractAddress: id.token0,
          abi: ERC20,
          calls: [
            {reference: "balanceOf", methodName: "balanceOf", methodParameters: [
              v3PoolAddress(
                CONTRACTS.v3CoreFactoryAddress,
                CONTRACTS.INIT_CODE_HASH_v3,
                id.token0,
                id.token1,
                id.fee,
              )
            ]},
          ],
        })),
        ...positions.map((id) => ({
          reference: id.id + '_1',
          contractAddress: id.token1,
          abi: ERC20,
          calls: [
            {reference: "balanceOf", methodName: "balanceOf", methodParameters: [
              v3PoolAddress(
                CONTRACTS.v3CoreFactoryAddress,
                CONTRACTS.INIT_CODE_HASH_v3,
                id.token0,
                id.token1,
                id.fee,
              )
            ]},
          ],
        })),
      );


      const PoolCreation = Object.values(poolRes)
        .filter((entry) => entry.callsReturnContext[0].success)
        .map((context) => {
          if(context.callsReturnContext[0].methodName == 'balanceOf'){
            let token = context.originalContractCallContext.reference.split("_")[1]
            if(token == '0')
              return {
                id: context.originalContractCallContext.reference.split("_")[0],
                balance0: context.callsReturnContext[0].returnValues[0]
              }
            else
              return {
                id: context.originalContractCallContext.reference.split("_")[0],
                balance1: context.callsReturnContext[0].returnValues[0]
              }
          }
          return {
            slot0: context.callsReturnContext[0].returnValues,
            liquidity: context.callsReturnContext[1].returnValues[0],
            address: context.originalContractCallContext.contractAddress,
            id: context.originalContractCallContext.reference,
          }
        })
        .reduce((acc, cur) => {
            Object.assign(acc[cur.id] ??= {}, cur);
            return acc;
        }, {});

      positions.forEach((position) => {
        Object.assign(position, {
          asset0: {...getTokenFromContract(position.token0), ...tokensInfo[position.token0?.toLowerCase()]},
          asset1: {...getTokenFromContract(position.token1), ...tokensInfo[position.token1?.toLowerCase()]},
        });
      });

      positions.forEach((position) => {
        const tokenA = position.asset0;
        const tokenB = position.asset1;

        var _tokenA = new Token(
          chainId,
          isETH(tokenA.address) ? CONTRACTS.gas.wrapped_address : tokenA.address,
          +tokenA.decimals,
          tokenA.symbol,
          tokenA.name,
        );
        var _tokenB = new Token(
          chainId,
          isETH(tokenB.address) ? CONTRACTS.gas.wrapped_address : tokenB.address,
          +tokenB.decimals,
          tokenB.symbol,
          tokenB.name,
        );

        const fullPool = new Pool(
          _tokenA,
          _tokenB,
          Number(position.fee),
          PoolCreation[position.id].slot0[0].toString(),
          PoolCreation[position.id].liquidity.toString(),
          new BigNumber(PoolCreation[position.id].slot0[1]).toNumber(),
        );

        Object.assign(position, {
          pool: fullPool,
          balance0: new BigNumber(PoolCreation[position.id].balance0).div(Math.pow(10, _tokenA.decimals)).toFixed(),
          balance1: new BigNumber(PoolCreation[position.id].balance1).div(Math.pow(10, _tokenB.decimals)).toFixed(),
        });
      });

      return positions;
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  const getAllZkSyncTokens = async () => {
    let response = await fetch("https://api.coingecko.com/api/v3/coins/list?include_platform=true");
    const data = await response.json();
    let tokens = {};
    for (let i in data) {
      let token = data[i];
      if (token.platforms["zksync"])
        tokens[token.platforms["zksync"]] = {
          name: token.name,
          symbol: token.symbol,
          id: token.id,
        };
    }

    return tokens;
  };

  const getLiquidityBalance = async () => {
    var _addedLiquidity = store.getState().addedLiquidity;
    _addedLiquidity = Array.from(new Set(_addedLiquidity));

    var liq_res = await Promise.all([
      searchForAllLiquidity(getSignerAddress(), _addedLiquidity),
      searchForAllLiquidityV3(),
    ]);
    var all_liq = liq_res[0];
    var all_liq_v3 = liq_res[1];

    dispatch(walletSlice.actions.updateOwnedLiquidityV3(all_liq_v3));

    try {
      var _ownedPairs: any = [];
      for (let i in all_liq) {
        if (new BigNumber(all_liq[i].assetAAmount).lte(0) || new BigNumber(all_liq[i].assetBAmount).lte(0)) {
          _addedLiquidity.splice(all_liq[i].index, 1);
          continue;
        }

        _ownedPairs.push(all_liq[i]);
      }

      // sory liquidity by value
      let arrayForSort = [..._ownedPairs];

      const orderedPairs = arrayForSort.sort((a: any, b: any) => {
        let _aa = getTokenFromContract(a.assetA);
        let _ab = getTokenFromContract(a.assetB);
        let _ba = getTokenFromContract(b.assetA);
        let _bb = getTokenFromContract(b.assetB);
        let _aPrice = new BigNumber(a.assetAAmount)
          .times(_aa.price ? _aa.price : 0)
          .plus(new BigNumber(a.assetBAmount).times(_ab.price ? _ab.price : 0))
          .toNumber();
        let _bPrice = new BigNumber(b.assetAAmount)
          .times(_ba.price ? _ba.price : 0)
          .plus(new BigNumber(b.assetBAmount).times(_bb.price ? _bb.price : 0))
          .toNumber();

        return _bPrice - _aPrice;
      });

      dispatch(walletSlice.actions.updateAddedLiquidity(_addedLiquidity));
      dispatch(walletSlice.actions.updateOwnedPairs(orderedPairs));
    } catch (e) {
      console.log(e);
    }
  };

  const getCurrentAsset = (asset) => {
    let _tokens = store.getState();
    for (let i in _tokens) {
      if (asset.symbol == _tokens[i].symbol) return _tokens[i];
    }
  };

  const getTokenPrice = (symbol) => {
    let _tokens = store.getState();

    for (let i in _tokens) {
      if (_tokens[i].symbol == symbol) return _tokens[i].price;
    }

    return 0;
  };

  const getTokenFromContract = (contract) => {
    let _tokens = store.getState().tokens;
    contract =
      contract.toLowerCase() == CONTRACTS.gas.wrapped_address.toLowerCase() ? CONTRACTS.gas.address.toLowerCase() : contract.toLowerCase();
    for (let i in _tokens) {
      if (i.toLowerCase() == contract.toLowerCase()) {
        return _tokens[i] as Asset;
      }
    }
    return {address: contract, decimals: 18, logo: UnknownToken} as Asset;
  };

  const getTokenFromContractPure = (contract) => {
    let _tokens = store.getState().tokens;
    contract = contract.toLowerCase();
    for (let i in _tokens) {
      if (i.toLowerCase() == contract) {
        return _tokens[i] as Asset;
      }
    }

    return {address: contract, decimals: 18, logo: UnknownToken} as Asset;
  };

  const getTokenFromSymbol = (symbol) => {
    let _tokens = store.getState();

    for (let i in _tokens) {
      if (_tokens[i] && _tokens[i].symbol && _tokens[i].symbol.toLowerCase() == symbol.toLowerCase()) return _tokens[i];
    }

    return 0;
  };

  const getLiqPositions = async () => {
    try {
      // dont need to continually fetch
      //if(store.getState().history.length > 0)
      //return

      var start = Date.now();
      let result = await client.query({
        query: LIQ_POSITIONS,
        variables: {
          user: getSignerAddress()?.toLowerCase(),
        },
        fetchPolicy: "no-cache",
      });

      console.log("liqPositions " + (Date.now() - start) / 1000);

      let _addedLiquidity = store.getState().addedLiquidity;
      if (_addedLiquidity.length == 0) {
        dispatch(walletSlice.actions.updateAddedLiquidity(Utils.deepClone(CONTRACTS.LP)));
        _addedLiquidity = Utils.deepClone(CONTRACTS.LP);
      }

      if (result?.data && result.data.user && result.data.user.liquidityPositions) {
        for (let swap_index in result.data.user.liquidityPositions) {
          var swap = result.data.user.liquidityPositions[swap_index];
          var pairID = swap.id.split("-")[0];

          _addedLiquidity = [..._addedLiquidity, pairID];
        }

        _addedLiquidity = _addedLiquidity.map((x) => x.toLowerCase());
        _addedLiquidity = Array.from(new Set(_addedLiquidity));

        dispatch(walletSlice.actions.updateAddedLiquidity(Utils.deepClone(_addedLiquidity)));
      }
    } catch (e) {
      console.log(e);
    }
  };
  const getTXHistory = async () => {
    try {
      var start = Date.now();
      let result = await client.query({
        query: GLOBAL_INFO,
        variables: {
          user: getSignerAddress() ? getSignerAddress()?.toLowerCase() : "0x00",
          hourStartUnix: new BigNumber(Math.floor(Date.now() / (1000 * 60 * 60 * 24)) - 1)
            .times(60 * 60 * 24)
            .toNumber(),
        },
        fetchPolicy: "no-cache",
      });

      let resultv3 = await clientV3.query({
        query: GLOBAL_INFO_V3,
        variables: {
          user: getSignerAddress() ? getSignerAddress()?.toLowerCase() : "0x00"
        },
        fetchPolicy: "no-cache",
      });

      let _addedLiquidity: any[] = []; // store.getState().addedLiquidity
      if (result?.data) {
        let _history: any = [];

        if (result.data.user && result.data.user.liquidityPositions) {
          for (let swap_index in result.data.user.liquidityPositions) {
            var swap = result.data.user.liquidityPositions[swap_index];
            var pairID = swap.id.split("-")[0];

            if (!_addedLiquidity.includes(pairID)) _addedLiquidity = [..._addedLiquidity, pairID];
          }
        }

        for (let swap_index in result.data.swaps) {
          var swap = result.data.swaps[swap_index];

          let fromToken = swap.amount0In != "0" ? swap.pair.token0.symbol : swap.pair.token1.symbol;
          let toToken = swap.amount0In != "0" ? swap.pair.token1.symbol : swap.pair.token0.symbol;

          let amountIn = swap.amount0In != "0" ? swap.amount0In : swap.amount1In;
          let amountOut = swap.amount0Out != "0" ? swap.amount0Out : swap.amount1Out;

          let trade = {
            operation: "Swap",
            status: "Confirmed",
            timestamp: Number(swap.timestamp) * 1000,
            amount: amountOut,
            amountIn: amountIn,
            amountOut: amountOut,
            amountUSD: swap.amountUSD,
            fromPair: fromToken,
            toPair: toToken,
            hash: swap.id.split("-")[0],
          };

          _history = [..._history, trade];
        }

        for (let swap_index in result.data.mints) {
          var swap = result.data.mints[swap_index];

          let fromToken = swap.pair.token0.symbol;
          let toToken = swap.pair.token1.symbol;
          let pairAddress = swap.pair.id;

          let amountIn = swap.amount0;
          let amountOut = swap.amount1;

          let trade = {
            operation: "Add LP",
            status: "Confirmed",
            timestamp: Number(swap.timestamp) * 1000,
            amount: amountOut,
            amountIn: amountIn,
            amountOut: amountOut,
            amountUSD: swap.amountUSD,
            lp: new BigNumber(swap.liquidity).toFixed(4),
            fromPair: fromToken,
            toPair: toToken,
            hash: swap.id.split("-")[0],
          };

          _history = [..._history, trade];

          //if(!_addedLiquidity.includes(pairAddress))
          //_addedLiquidity = [..._addedLiquidity, pairAddress]
        }

        for (let swap_index in result.data.burns) {
          var swap = result.data.burns[swap_index];

          let fromToken = swap.pair.token0.symbol;
          let toToken = swap.pair.token1.symbol;

          let amountIn = swap.amount0;
          let amountOut = swap.amount1;

          let trade = {
            operation: "Remove LP",
            status: "Confirmed",
            timestamp: Number(swap.timestamp) * 1000,
            amount: amountOut,
            amountIn: amountIn,
            amountOut: amountOut,
            amountUSD: swap.amountUSD,
            lp: new BigNumber(swap.liquidity).toFixed(4),
            fromPair: fromToken,
            toPair: toToken,
            hash: swap.id.split("-")[0],
          };

          _history = [..._history, trade];
        }

        let tokenUpdates: any[] = [];

        for (let i in result.data.tokens) {
          let token0 = result.data.tokens[i];

          let _tokens = store.getState().tokens;
          let _token0 = _tokens[token0.id.toLowerCase()];

          const eth_price = result.data.bundle.ethPrice;

          let decimals = token0.decimals;
          let address = token0.id.toLowerCase();
          let symbol = token0.symbol;
          let name = token0.name;
          let price = new BigNumber(token0.derivedETH).times(eth_price).toFixed();

          //cull 0 value tokens
          if (new BigNumber(price).eq(0)) continue;

          let logo = _token0 && _token0.logo ? _token0.logo : UnknownToken;
          let balance = _token0 && _token0.balance ? _token0.balance : "0.00";
          let active = _token0 && _token0.active ? _token0.active : false;

          if (active == false) continue;
          //update eth using weth data
          if (address.toLowerCase() == CONTRACTS.gas.wrapped_address.toLowerCase())
            tokenUpdates.push({
              decimals: decimals,
              address: CONTRACTS.gas.address,
              symbol: getETH().symbol,
              name: getETH().name,
              logo: getETH().logo,
              balance: getETH().balance,
              active: true,
              price: eth_price,
            });

          if (address.toLowerCase() == CONTRACTS.USDC.toLowerCase()) {
            tokenUpdates.push({
              decimals,
              address,
              symbol: "USDC.e",
              name: "Bridged USDC",
              logo,
              balance,
              active,
              price,
            });
          } else tokenUpdates.push({decimals, address, symbol, name, logo, balance, active, price});
        }

        let _allPairs = Utils.deepClone(store.getState().allPairs);
        let _newPairs = Utils.deepClone(result.data.pairs.concat(resultv3.data.pools));

        for (let k in _newPairs) {
          for (let id in _allPairs) {
            if (_allPairs[id].id.toLowerCase() == _newPairs[k].id.toLowerCase()) {
              _newPairs[k].apy = _allPairs[id].apy;
            }
          }
        }

        dispatch(walletSlice.actions.updateAllPairs(_newPairs));
        dispatch(walletSlice.actions.updateTokens(tokenUpdates));
        dispatch(walletSlice.actions.updateTrendingPairs(Utils.deepClone(result.data.pairDayDatas)));
        dispatch(walletSlice.actions.updateETHPrice(Utils.deepClone(result.data.bundle.ethPrice)));
        _history.sort((a, b) => b.timestamp - a.timestamp);
        dispatch(walletSlice.actions.updateHistory(Utils.deepClone(_history)));
        _addedLiquidity = _addedLiquidity.map((x) => x.toLowerCase());
        _addedLiquidity = Array.from(new Set(_addedLiquidity));

        dispatch(walletSlice.actions.updateAddedLiquidity(Utils.deepClone(_addedLiquidity)));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getTopPairs = async () => {
    try {
      var start = Date.now();

      let result = await client.query({
        query: TOP_PAIRS,
        variables: {
          user: "0x0",
          extra: String("0xd5a69D2bD59ae04EA931B4e2bD30aED6A412Ce10").toLowerCase(),
        },
        fetchPolicy: "cache-first",
      });

      //console.log("getTopPairs(): " + (Date.now() - start) / 1000);
      if (result?.data) {
        dispatch(walletSlice.actions.updateAllPairs(Utils.deepClone(result.data.pairs.concat(result.data.extra))));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getTokenPrices = async () => {
    try {
      var start = Date.now();
      let result = await client.query({
        query: TOKEN_PRICES,
        variables: {
          user: "0x0",
        },
        fetchPolicy: "cache-first",
      });

      //console.log("getTokenPrices(): " + (Date.now() - start) / 1000);

      var addresses = "0x244c238325fc1bdf6eded726ee1b47d55895d944";
      var addy_index = 0;

      if (result?.data) {
        let tokenUpdates: any[] = [];

        for (let i in result.data.tokens) {
          let token0 = result.data.tokens[i];

          let _tokens = store.getState().tokens;
          let _token0 = _tokens[token0.id.toLowerCase()];

          //if(addy_index < 10)
          //addresses += token0.id.toLowerCase() + ","

          //addy_index++

          const eth_price = result.data.bundle.ethPrice;

          let decimals = token0.decimals;
          let address = token0.id.toLowerCase();
          let symbol = token0.symbol;
          let name = token0.name;
          let price = new BigNumber(token0.derivedETH).times(eth_price).toFixed();

          //cull 0 value tokens
          if (new BigNumber(price).eq(0)) continue;

          let logo = _token0 && _token0.logo ? _token0.logo : UnknownToken;
          let balance = _token0 && _token0.balance ? _token0.balance : "0.00";
          let active = _token0 && _token0.active ? _token0.active : false;

          if (active == false) continue;
          //update eth using weth data
          if (address.toLowerCase() == CONTRACTS.gas.wrapped_address.toLowerCase())
            tokenUpdates.push({
              decimals: decimals,
              address: CONTRACTS.gas.address,
              symbol: getETH().symbol,
              name: getETH().name,
              logo: getETH().logo,
              balance: getETH().balance,
              active: true,
              price: eth_price,
            });

          tokenUpdates.push({decimals, address, symbol, name, logo, balance, active, price});
        }
        /*
        var prices = await axios.create().get(`https://api.coingecko.com/api/v3/simple/token_price/zksync?contract_addresses=${addresses}&vs_currencies=usd`)
        //console.log(prices.data)
        for(let i in prices.data){
          var addy = i
          var obj = prices.data[i]
          var foundIndex = tokenUpdates.findIndex(x => x.address.toLowerCase() == addy.toLowerCase());
          tokenUpdates[foundIndex].price = obj.usd
          console.log('updating')
        }
        */

        dispatch(walletSlice.actions.updateTokens(tokenUpdates));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getSwapHistory = async () => {
    try {
      return;
      let weekID = new BigNumber(Math.floor(Date.now() / 1000)).div(604800).toFixed(0);
      let dateTimestamp = new BigNumber(weekID).times(604800).toNumber();
      let result = await client.query({
        query: SWAP_HISTORY,
        variables: {
          user: getSignerAddress(),
          timestampStartUnix: dateTimestamp,
          id: weekID + "-" + getSignerAddress()?.toLowerCase(),
        },
        fetchPolicy: "no-cache",
      });

      if (result?.data) {
        let userInfo = {totalVolume: "0.00", totalFees: "0.00", rank: "unranked", globalFees: "0.00"};
        let globalFees = new BigNumber(0);
        for (let user_rank in result.data.global) {
          var user_info = result.data.global[user_rank];
          if (user_info["id"].includes(getSignerAddress()?.toLowerCase())) {
            userInfo.rank = String(Number(user_rank) + 1);
            userInfo.totalVolume = new BigNumber(user_info.totalVolumeUSD).toFixed(2);
            userInfo.totalFees = new BigNumber(user_info.feesPaidUSD).toFixed(2);
          }

          globalFees = globalFees.plus(user_info.feesPaidUSD);
        }

        if (userInfo.rank == "unranked" && result.data.user[0])
          userInfo.totalVolume = new BigNumber(result.data.user[0].totalVolumeUSD).toFixed(2);

        userInfo.globalFees = globalFees.toFixed(2);
        dispatch(walletSlice.actions.updateUserRanking(userInfo));
      }
    } catch (e) {
      console.log(e);
    }
  };
  const getPairAPYs = async () => {
    try {
      let _allPairs = Utils.deepClone(store.getState().allPairs);

      let pair_text = "";
      let items = 0
      let pair_groups: any = []
      for (let i in _allPairs) {
        if(items > 20){
          pair_groups.push(pair_text)
          pair_text = ""
          items = 0
        }
        pair_text += _allPairs[i].id + ",";
        items++
      }

      pair_groups.push(pair_text)

      console.log(pair_groups)

      var dex_screener_pairs: any = []
      for(let i in pair_groups){
        let pair_info: any = await fetch(`https://api.dexscreener.com/latest/dex/pairs/zksync/${pair_groups[i]}`, {
          method: "GET",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
          },
        });
        dex_screener_pairs.push(...(await pair_info.json()).pairs);
      }

      var token_prices: any = [];

      for (let k in _allPairs) {
        let pair = _allPairs[k];
        //@ts-ignore
        for (let id in dex_screener_pairs) {
          //@ts-ignore
          token_prices.push({
            //@ts-ignore
            price: dex_screener_pairs[id].priceUsd,
            //@ts-ignore
            address: dex_screener_pairs[id].baseToken.address.toLowerCase(),
          });
          
          //@ts-ignore
          if (
            dex_screener_pairs[id].pairAddress.toLowerCase() == pair.id.toLowerCase() &&
            new BigNumber(pair.reserveUSD).gt(0)
          ) {
            //20% protocol fees
            //@ts-ignore
            let apy = new BigNumber(dex_screener_pairs[id].volume.h24)
              .times(pair.pairFee)
              .div(10000)
              .times(365)
              .div(pair.reserveUSD)
              .times(80)
              .toFixed(2);
            _allPairs[k].apy = apy;
          }

          if (
            dex_screener_pairs[id].pairAddress.toLowerCase() == pair.id.toLowerCase() &&
            new BigNumber(pair.totalValueLockedUSD).gt(0)
          ) {
            //20% protocol fees
            //@ts-ignore
            let apy = new BigNumber(dex_screener_pairs[id].volume.h24)
              .times(pair.feeTier)
              .div(1000000)
              .times(365)
              .div(pair.totalValueLockedUSD)
              .times(80)
              .toFixed(2);
            _allPairs[k].apy = apy;
          }
        }
      }

      /*
      for (let k in _ownedPairs) {
        for (let id in _allPairs) {
          if (_allPairs[id].id.toLowerCase() == _ownedPairs[k].address.toLowerCase()) {
            _ownedPairs[k].apy = _allPairs[id].apy;
          }
        }
      }
      */

      dispatch(walletSlice.actions.updateAllPairs(_allPairs));
      dispatch(walletSlice.actions.updateTokenPrices(token_prices));
      return;
    } catch (e) {
      console.log(e);
    }
  };

  const getPairDayData = async (pair, pairFee = 30) => {
    try {
      let pair_info: any = await fetch(`https://api.dexscreener.com/latest/dex/pairs/zksync/${pair}`, {
        method: "GET",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
      });
      pair_info = await pair_info.json();
      try {
        //@ts-ignore
        var apy = new BigNumber(pair_info.pair.volume.h24)
          .times(pairFee)
          .div(10000)
          .times(0.8)
          .times(365)
          .div(pair_info.pair.liquidity.usd)
          .times(100)
          .toFixed(2);
      } catch (e) {
        apy = "0";
      }
      //@ts-ignore
      return {apy, tvl: pair_info.pair.liquidity.usd};
    } catch (e) {
      console.log(e);
      return {apy: "0", tvl: "0"};
    }
  };

  const getPairTradeData = async (pair, token = "base") => {
    try {
      var res = await axios
        .create()
        .get(
          `https://api.geckoterminal.com/api/v2/networks/zksync/pools/${pair}/ohlcv/hour?aggregate=1&limit=24&token=${token}`,
        );
      var dat_list = res.data.data.attributes.ohlcv_list;
      var vals: any = [];
      var totalVol = new BigNumber(0);
      for (let i in dat_list) {
        vals.push(dat_list[i][4]);
        totalVol.plus(dat_list[i][5]);
      }
      return vals;
    } catch (e) {}
  };

  const getCurrentGasFees = async () => {
    let res: any = await axios.create().get("https://ethgasstation.info/api/ethgasAPI.json?");
    res = res.data.average / 10;
    return res;
  };

  const getCurrentSwapCost = () => {
    return new BigNumber(store.getState().ethPrice)
      .times(store.getState().swapCostPerGas)
      .times(store.getState().gasFee)
      .toFixed(2);
  };

  const getTokenPair = async (pair1, pair2, stable) => {
    try {
      var path: any = [ethers.getAddress(pair1), ethers.getAddress(pair2)];

      if (path[0].toLowerCase() == CONTRACTS.gas.address.toLowerCase()) path[0] = CONTRACTS.gas.wrapped_address;

      if (path[1].toLowerCase() == CONTRACTS.gas.address.toLowerCase()) path[1] = CONTRACTS.gas.wrapped_address;

      var zksigner = await getZkSigner(); // provider!.getSigner();

      const factory = new Contract(CONTRACTS.factory, FACTORY, zksigner);
      var res = await factory.getPair(path[0], path[1], stable);
      return res;
    } catch (e) {
      console.log(e);
    }
  };

  const prepareTradeSmart = async (pair1, pair2, amountIn, slippage) => {
    try {
      if (amountIn == "" || new BigNumber(amountIn).eq(0) || pair1.address == pair2.address) throw "bad amounts";

      //if user calculates too fast, push amount to stack and return latest value
      calculatingTrade.current.push(amountIn);
      await chainSafetyCheck();
      var path: any = [ethers.getAddress(pair1.address), ethers.getAddress(pair2.address)];

      var wrap = "Unwrap wETH";
      if (path[0].toLowerCase() == CONTRACTS.gas.address.toLowerCase()) wrap = "Wrap ETH";

      if (path[0].toLowerCase() == CONTRACTS.gas.address.toLowerCase()) path[0] = CONTRACTS.gas.wrapped_address;

      if (path[1].toLowerCase() == CONTRACTS.gas.address.toLowerCase()) path[1] = CONTRACTS.gas.wrapped_address;

      if (path[1] == CONTRACTS.gas.wrapped_address && path[0] == CONTRACTS.gas.wrapped_address) {
        const wrap_route = {
          "100": {
            amOut: amountIn,
            amIn: amountIn,
            fee: 0,
            route: [
              {
                from: path[0],
                to: path[1],
                stable: false,
                fee: "0",
              },
            ],
          },
        };

        dispatch(
          walletSlice.actions.updateCachedPairSmart({
            baseConvertRequest: amountIn,
            expectedConvertQuote: amountIn,
            minAmountConvertQuote: amountIn,
            liquidityProviderFee: [0, 0],
            stable: false,
            routes: wrap_route,
            routeText: wrap,
            fromAllowance: new BigNumber(approve_amount).toFixed(),
            fromBalance:
              wrap == "Wrap ETH" ? new BigNumber(getETHBalance()).toFixed() : new BigNumber(getWETHBalance()).toFixed(),

            fromToken: {contractAddress: pair1},
            toToken: {contractAddress: pair2},
            priceImpact: "0.00",
            hasETH: getHasEnoughTokensForGas(),
          }),
        );

        return {
          trade: {
            baseConvertRequest: amountIn,
            expectedConvertQuote: amountIn,
            minAmountConvertQuote: amountIn,
            liquidityProviderFee: [0, 0],
            stable: false,
            routes: wrap_route,
            routeText: wrap,
            fromAllowance: new BigNumber(approve_amount).toFixed(),
            fromBalance:
              wrap == "Wrap ETH" ? new BigNumber(getETHBalance()).toFixed() : new BigNumber(getWETHBalance()).toFixed(),

            fromToken: {contractAddress: pair1},
            toToken: {contractAddress: pair2},
            priceImpact: "0.00",
            hasETH: getHasEnoughTokensForGas(),
          },
          priceImpact: "0.00",
          success: true,
          hasETH: getHasEnoughTokensForGas(),
          fee: 0,
        };
      }

      if (
        path[0].toLowerCase() == CONTRACTS.USDC.toLowerCase() &&
        path[1].toLowerCase() == CONTRACTS.USDC_NATIVE.toLowerCase() && false
      ) {
        var allowance = await getAllowance(CONTRACTS.USDC, CONTRACTS.USDC_CONVERSION);

        const wrap_route = {
          "100": {
            amOut: amountIn,
            amIn: amountIn,
            fee: 0,
            route: [
              {
                from: path[0],
                to: path[1],
                stable: false,
                fee: "0",
              },
            ],
          },
        };

        dispatch(
          walletSlice.actions.updateCachedPairSmart({
            baseConvertRequest: amountIn,
            expectedConvertQuote: new BigNumber(amountIn).times(1).toFixed(),
            minAmountConvertQuote: new BigNumber(amountIn).times(1).toFixed(),
            liquidityProviderFee: [0, 0],
            stable: false,
            routes: wrap_route,
            routeText: "Convert to USDC",
            fromAllowance: allowance,
            fromBalance: new BigNumber(getUSDCEBalance()).toFixed(),
            hasEnoughAllowance: new BigNumber(amountIn).lte(allowance),
            fromToken: {contractAddress: pair1},
            toToken: {contractAddress: pair2},
            priceImpact: "0.00",
            hasETH: getHasEnoughTokensForGas(),
          }),
        );

        return {
          trade: {
            baseConvertRequest: amountIn,
            expectedConvertQuote: new BigNumber(amountIn).times(1).toFixed(),
            minAmountConvertQuote: new BigNumber(amountIn).times(1).toFixed(),
            liquidityProviderFee: [0, 0],
            stable: false,
            routes: wrap_route,
            routeText: "Convert to USDC",
            fromAllowance: allowance,
            fromBalance: new BigNumber(getUSDCEBalance()).toFixed(),
            hasEnoughAllowance: new BigNumber(amountIn).lte(allowance),
            fromToken: {contractAddress: pair1},
            toToken: {contractAddress: pair2},
            priceImpact: "0.00",
            hasETH: getHasEnoughTokensForGas(),
          },
          priceImpact: "0.00",
          success: true,
          hasETH: getHasEnoughTokensForGas(),
          fee: 0,
        };
      }

      /*
      if (
        path[0].toLowerCase() == CONTRACTS.MUTE.toLowerCase() &&
        path[1].toLowerCase() == CONTRACTS.KOI.toLowerCase()
      ) {
        const wrap_route = {
          "100": {
            amOut: new BigNumber(amountIn).times(12.5).toFixed(), 
            amIn: amountIn,
            fee: 0,
            route: [
              {
                  "from": path[0],
                  "to": path[1],
                  "stable": false,
                  "fee": "0"
              }
            ],
          }
        }

        var allowance = await getAllowance(CONTRACTS.MUTE, CONTRACTS.KOI_CONVERSION);

        dispatch(
          walletSlice.actions.updateCachedPairSmart({
            baseConvertRequest: amountIn,
            expectedConvertQuote: new BigNumber(amountIn).times(12.5).toFixed(),
            minAmountConvertQuote: new BigNumber(amountIn).times(12.5).toFixed(),
            liquidityProviderFee: [0, 0],
            stable: false,
            routeText: "Convert to KOI",
            routes: wrap_route,
            fromAllowance: allowance,
            fromBalance: new BigNumber(getMUTEBalance()).toFixed(),
            hasEnoughAllowance: new BigNumber(amountIn).lte(allowance),
            fromToken: {contractAddress: pair1},
            toToken: {contractAddress: pair2},
            priceImpact: "0.00",
            hasETH: getHasEnoughTokensForGas(),
          }),
        );

        return {
          trade: {
            baseConvertRequest: amountIn,
            expectedConvertQuote: new BigNumber(amountIn).times(12.5).toFixed(),
            minAmountConvertQuote: new BigNumber(amountIn).times(12.5).toFixed(),
            liquidityProviderFee: [0, 0],
            stable: false,
            routeText: "Convert to KOI",
            routes: wrap_route,
            fromAllowance: allowance,
            fromBalance: new BigNumber(getMUTEBalance()).toFixed(),
            hasEnoughAllowance: new BigNumber(amountIn).lte(allowance),
            fromToken: {contractAddress: pair1},
            toToken: {contractAddress: pair2},
            priceImpact: "0.00",
            hasETH: getHasEnoughTokensForGas(),
          },
          priceImpact: "0.00",
          success: true,
          hasETH: getHasEnoughTokensForGas(),
          fee: 0,
        };
      }
      */

      const to = ethers.getAddress(getSignerAddress() ? (getSignerAddress() as string) : zeroAddress);

      var muteswitchPairFactory = store.getState().factoryTrade;
      let one = new BigNumber(1);

      if (
        muteswitchPairFactory == null ||
        //if tokens have been changed reset
        muteswitchPairFactory.fromToken.contractAddress.toLowerCase() != path[0].toLowerCase() ||
        muteswitchPairFactory.toToken.contractAddress.toLowerCase() != path[1].toLowerCase() ||
        //if slippage has been changed, reset
        new BigNumber(muteswitchPairFactory._muteswitchPairFactoryContext.settings.slippage).eq(
          new BigNumber(slippage).div(100).toNumber(),
        ) == false
      ) {
        if (muteswitchPairFactory != null) destroyCurrentTrade();

        const muteswitchPair = new MuteSwitchPair({
          fromTokenContractAddress: path[0],
          toTokenContractAddress: path[1],
          ethereumAddress: to,
          ethereumProvider: new ethers.JsonRpcProvider(getNodeUrl()),
          settings: new MuteSwitchPairSettings({
            slippage: new BigNumber(slippage).div(100).toNumber(),
            customNetwork: {
              multicallContractAddress: CONTRACTS.multicall,
              nodeUrl: getNodeUrl(),
              chainId,
              nameNetwork: chain!.name,
              client: getClient(chainId, true),
              clientV3: getClientV3(chainId, true),
              nativeCurrency: {
                name: CONTRACTS.gas.name,
                symbol: CONTRACTS.gas.symbol
              },
              nativeWrappedTokenInfo: {
                name: CONTRACTS.gas.wrapped_name,
                symbol: CONTRACTS.gas.wrapped_symbol,
                chainId,
                contractAddress: CONTRACTS.gas.wrapped_address,
                decimals: 18
              }
            },
          }),
        });
        // now to create the factory you just do
        muteswitchPairFactory = await muteswitchPair.createFactory();
        dispatch(walletSlice.actions.updateFactoryTrade(muteswitchPairFactory));
      }

      var trade = await muteswitchPairFactory!.tradeSmart(amountIn, TradeDirection.input);

      //[wbtc - > eth - > dai]
      let priceImpact = trade.bestRouteQuote.impact!;

      if (calculatingTrade.current[calculatingTrade.current.length - 1] != amountIn) {
        return;
      }

      calculatingTrade.current = [];

      dispatch(
        walletSlice.actions.updateCachedPairSmart({
          expectedConvertQuote: trade.expectedConvertQuote,
          minAmountConvertQuote: new BigNumber(trade.expectedConvertQuote)
            .minus(new BigNumber(trade.expectedConvertQuote).times(slippage).div(100))
            .toFixed(),
          baseConvertRequest: trade.baseConvertRequest,
          fromAllowance: trade.bestRouteQuote.fromAllowance,
          hasEnoughAllowance:
            pair1.address.toLowerCase() == CONTRACTS.gas.address.toLowerCase() ? true : trade.bestRouteQuote.hasEnoughAllowance,
          routes: trade.bestRouteQuote.routes,
          fromBalance:
            pair1.address.toLowerCase() == CONTRACTS.gas.address.toLowerCase()
              ? {hasEnough: new BigNumber(getETHBalance()).gte(amountIn)}
              : trade.bestRouteQuote.fromBalance,
          priceImpact: new BigNumber(priceImpact).lte(0) ? "0.00" : priceImpact,
          hasETH: getHasEnoughTokensForGas(),
        }),
      );

      trade.quoteChanged$.subscribe((value: TradeContextSmart) => {
        // value will hold the same info as below but obviously with
        // the new trade info.
        dispatch(
          walletSlice.actions.updateCachedPairSmart({
            expectedConvertQuote: value.expectedConvertQuote,
            minAmountConvertQuote: new BigNumber(value.expectedConvertQuote)
              .minus(new BigNumber(value.expectedConvertQuote).times(slippage).div(100))
              .toFixed(),
            baseConvertRequest: value.baseConvertRequest,
            fromAllowance: value.bestRouteQuote.fromAllowance,
            hasEnoughAllowance:
              pair1.address.toLowerCase() == CONTRACTS.gas.address.toLowerCase()
                ? true
                : value.bestRouteQuote.hasEnoughAllowance,
            routes: value.bestRouteQuote.routes,
            fromBalance:
              pair1.address.toLowerCase() == CONTRACTS.gas.address.toLowerCase()
                ? {hasEnough: new BigNumber(getETHBalance()).gte(amountIn)}
                : value.bestRouteQuote.fromBalance,
            priceImpact: new BigNumber(priceImpact).lte(0) ? "0.00" : priceImpact,
            hasETH: getHasEnoughTokensForGas(),
          }),
        );
      });

      return {
        trade: {
          expectedConvertQuote: trade.expectedConvertQuote,
          minAmountConvertQuote: new BigNumber(trade.expectedConvertQuote)
            .minus(new BigNumber(trade.expectedConvertQuote).times(slippage).div(100))
            .toFixed(),
          baseConvertRequest: trade.baseConvertRequest,
          hasEnoughAllowance:
            pair1.address.toLowerCase() == CONTRACTS.gas.address.toLowerCase() ? true : trade.bestRouteQuote.hasEnoughAllowance,
          fromAllowance: trade.bestRouteQuote.fromAllowance,
          routes: trade.bestRouteQuote.routes,
          fromBalance:
            pair1.address.toLowerCase() == CONTRACTS.gas.address.toLowerCase()
              ? {hasEnough: new BigNumber(getETHBalance()).gte(amountIn)}
              : trade.bestRouteQuote.fromBalance,
          priceImpact: new BigNumber(priceImpact).lte(0) ? "0.00" : priceImpact,
          hasETH: getHasEnoughTokensForGas(),
        },
        priceImpact: new BigNumber(priceImpact).lte(0) ? "0.00" : priceImpact,
        success: true,
        hasETH: getHasEnoughTokensForGas(), //new BigNumber(getTokenFromContract('0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE').amount).gt(0)
        fee: 0,
      };
    } catch (e) {
      console.log(e);
      return {
        trade: null,
        priceImpact: 0,
        success: false,
        hasETH: false,
        fee: 0,
      };
    }
  };

  const getETHBalance = () => {
    let eth = store.getState().tokens[CONTRACTS.gas.address];

    if (eth) return eth.balance;

    return "0";
  };

  const getMUTEBalance = () => {
    let eth = store.getState().tokens[CONTRACTS.MUTE.toLowerCase()];

    if (eth) return eth.balance;

    return "0";
  };

  const getUSDCEBalance = () => {
    let eth = store.getState().tokens[CONTRACTS.USDC.toLowerCase()];

    if (eth) return eth.balance;

    return "0";
  };

  const getWETHBalance = () => {
    let eth = store.getState().tokens[CONTRACTS.gas.wrapped_address] || store.getState().tokens[CONTRACTS.gas.wrapped_address.toLowerCase()];

    if (eth) return eth.balance;

    return "0";
  };

  const destroyCurrentTrade = () => {
    if (store.getState().factoryTrade) store.getState().factoryTrade.destroy();

    dispatch(walletSlice.actions.updateFactoryTrade(null));
    dispatch(walletSlice.actions.updateCachedPair(null));
    dispatch(walletSlice.actions.updateCachedPairSmart(null));
  };

  const getDeadline = async () => {
    var latest_block = await provider!.getBlock("latest");
    return latest_block!;
  };

  const estimateGasRefund = (gasFee: number | string, gasLimit: number | string, free?: boolean) => {
    if (free !== undefined) {
      return dispatch(walletSlice.actions.updateGasFree(free));
    }
    let gFee = new BigNumber(gasFee).div(Math.pow(10, 9));
    let gLimit = new BigNumber(gasLimit).div(Math.pow(10, 9));
    let ePrice = store.getState().ethPrice;
    let cost = gFee.times(gLimit).times(ePrice);
    dispatch(
      walletSlice.actions.updateGasEstimate({
        total: cost.toFixed(2),
        refund: cost.times(0.7).toFixed(2),
        actual: cost.times(0.3).toFixed(2),
      }),
    );

    return {total: cost.toFixed(2), refund: cost.times(0.7).toFixed(2), actual: cost.times(0.3).toFixed(2)};
  };

  const getAllowanceInfo = async (token) => {
    var _provider = new Provider(getNodeUrl());
    const to = ethers.getAddress(getSignerAddress() as string);

    const allowanceProvider = new AllowanceProvider(_provider, CONTRACTS.PERMIT2ADDRESS);

    const {
      amount: permitAmount,
      expiration,
      nonce,
    } = await allowanceProvider.getAllowanceData(token, to, CONTRACTS.UNIVERSALROUTER);

    console.log(permitAmount);
    console.log(expiration);
    console.log(nonce);

    return {permitAmount, expiration, nonce};
  };

  const permitSingleToken = async (token, approval_amount = MaxAllowanceTransferAmount) => {
    var _provider = new Provider(getNodeUrl());
    const to = ethers.getAddress(getSignerAddress() as string);
    var zksigner = await getZkSigner(); // provider!.getSigner();

    const allowanceProvider = new AllowanceProvider(_provider, CONTRACTS.PERMIT2ADDRESS);

    const {
      amount: permitAmount,
      expiration,
      nonce,
    } = await allowanceProvider.getAllowanceData(token, to, CONTRACTS.UNIVERSALROUTER);

    function toDeadline(expiration: number): number {
      return Math.floor((Date.now() + expiration) / 1000);
    }

    const permitSingle: PermitSingle = {
      details: {
        token: token,
        amount: approval_amount,
        // You may set your own deadline - we use 30 days.
        expiration: toDeadline(/* 30 days= */ 1000 * 60 * 60 * 24 * 30),
        nonce,
      },
      spender: CONTRACTS.UNIVERSALROUTER, //to
      // You may set your own deadline - we use 30 minutes.
      sigDeadline: toDeadline(/* 30 minutes= */ 1000 * 60 * 60 * 30),
    };

    const {domain, types, values} = AllowanceTransfer.getPermitData(permitSingle, CONTRACTS.PERMIT2ADDRESS, chainId);

    // We use an ethers signer to sign this data:
    const signature = await zksigner.signTypedData(domain, types, values);

    dispatch(walletSlice.actions.updatePermit(signature));

    return {permitSingle, signature};
  };

  /*
  routes: {
    [key: string]: [{from, to, stable, fee}]
  };
  */
  const executeTradeSmart = async (pair1, pair2, inAm, outAm, routes, allowance, expectedAmount, slippage) => {
    try {
      //slippage could be 100%
      if (inAm == "" || new BigNumber(inAm).eq(0) || outAm == "" /*|| new BigNumber(outAm).eq(0)*/) throw "e";

      await chainSafetyCheck();

      // in any event we receive an execution that is not equal, make sure we reset the minOutAmount
      if (
        new BigNumber(store.getState().cachedPairSmart.baseConvertRequest).eq(inAm) &&
        !new BigNumber(store.getState().cachedPairSmart.minAmountConvertQuote).eq(outAm)
      ) {
        if (new BigNumber(expectedAmount).lt(store.getState().cachedPairSmart.minAmountConvertQuote))
          throw "Large price change. Increase slippage or try again";
      } else if (!new BigNumber(store.getState().cachedPairSmart.baseConvertRequest).eq(inAm)) {
        throw (
          "Invalid input amount " + String(inAm) + " " + String(store.getState().cachedPairSmart.baseConvertRequest)
        );
      }

      var zksigner = await getZkSigner(); // provider!.getSigner();
      const to = ethers.getAddress(getSignerAddress() as string);
      var path_weth: any = [ethers.getAddress(pair1.address), ethers.getAddress(pair2.address)];

      if (path_weth[0].toLowerCase() == CONTRACTS.gas.address.toLowerCase()) path_weth[0] = CONTRACTS.gas.wrapped_address;

      if (path_weth[1].toLowerCase() == CONTRACTS.gas.address.toLowerCase()) path_weth[1] = CONTRACTS.gas.wrapped_address;

      var inAmount = new BigNumber(inAm).times(Math.pow(10, pair1.decimals)).toFixed(0);
      var outAmount = new BigNumber(outAm).times(Math.pow(10, pair2.decimals)).toFixed(0);

      let receipts: Awaited<ReturnType<typeof handleTransaction>>;

      if (path_weth[1] == CONTRACTS.gas.wrapped_address && path_weth[0] == CONTRACTS.gas.wrapped_address) {
        dispatch(walletSlice.actions.updaetTransactionModalStatus("Confirm transaction in your wallet"));

        var wethContract = new Contract(CONTRACTS.gas.wrapped_address, WETH, zksigner!);

        if (ethers.getAddress(pair1.address).toLowerCase() == CONTRACTS.gas.address.toLowerCase()) {
          receipts = await handleTransaction(wethContract.deposit, zksigner, [], {value: inAmount});
          // receipts = await wethContract.deposit({...additionalParams, value: inAmount});
        } else {
          receipts = await handleTransaction(wethContract.withdraw, zksigner, [inAmount]);
          // receipts = await wethContract.withdraw(inAmount, {...additionalParams});
        }
      } else if (path_weth[0].toLowerCase() == CONTRACTS.USDC.toLowerCase() && path_weth[1].toLowerCase() == CONTRACTS.USDC_NATIVE.toLowerCase()
      ) {
        // check for mute to koi swap
        if (new BigNumber(allowance).lt(inAmount)) {
          dispatch(walletSlice.actions.updaetTransactionModalStatus("Approve token for spending"));
          //multi_calls.push(await approveTokenAmount(pair1.address, CONTRACTS.router))
          await approveTokenAmount(pair1.address, CONTRACTS.USDC_CONVERSION);
        }

        dispatch(walletSlice.actions.updaetTransactionModalStatus("Confirm transaction in your wallet"));

        const conversion = new Contract(CONTRACTS.USDC_CONVERSION, KOI_CONVERSION, zksigner!);

        receipts = await handleTransaction(conversion.convert, zksigner, [inAmount]);
        // receipts = await conversion.convert(inAmount, {...additionalParams});
      } else {
        const universalrouter = new Contract(CONTRACTS.UNIVERSALROUTER, UNIVERSAL_ROUTER, zksigner!);
        const sendETH = pair1.address.toLowerCase() == CONTRACTS.gas.address.toLowerCase();
        const receiveETH = pair2.address.toLowerCase() == CONTRACTS.gas.address.toLowerCase();
        const wrap = path_weth[1] == CONTRACTS.gas.wrapped_address && path_weth[0] == CONTRACTS.gas.wrapped_address;
        var wrap_data;
        var swap_data;
        var getPermit: any = null;

        if (wrap) {
          if (pair1.address.toLowerCase() == CONTRACTS.gas.address.toLowerCase()) wrap_data = getWrapData(inAmount);
          else wrap_data = getUnwrapData(inAmount);
        }
        //sending tokens
        else if (!sendETH) {
          var permit_token_allowance = await getAllowance(pair1.address.toLowerCase(), CONTRACTS.PERMIT2ADDRESS);
          // if we did not approve permit2 for token spend, approve it
          if (new BigNumber(permit_token_allowance).lt(inAmount)) {
            await approveTokenAmount(pair1.address, CONTRACTS.PERMIT2ADDRESS);
          }

          const allowance_info = await getAllowanceInfo(pair1.address.toLowerCase());
          //if we have not approved router for token spend from permit2, create 30 day approval
          if (new BigNumber(allowance_info.permitAmount.toString()).lt(inAmount)) {
            getPermit = await permitSingleToken(pair1.address.toLowerCase(), BigInt(new BigNumber(inAmount).toFixed()));
          }
        }

        dispatch(walletSlice.actions.updaetTransactionModalStatus("Confirm transaction in your wallet"));
        var clean_routes: any = [];

        if (!wrap_data) {
          for (let i in routes) {
            if (routes[i].v3) {
              clean_routes.push({
                amountIn: new BigNumber(routes[i].amIn).times(Math.pow(10, pair1.decimals)).toFixed(0),
                amountOutMin: new BigNumber(routes[i].amOut)
                  .minus(new BigNumber(routes[i].amOut).times(slippage).div(100))
                  .times(Math.pow(10, pair2.decimals))
                  .toFixed(0),
                routeV3: routes[i].route,
                v3: true,
              });
            } else {
              var _route: any = [];
              for (let j in routes[i].route) {
                _route.push({
                  from: routes[i].route[j].from,
                  to: routes[i].route[j].to,
                  stable: routes[i].route[j].stable,
                });
              }

              clean_routes.push({
                amountIn: new BigNumber(routes[i].amIn).times(Math.pow(10, pair1.decimals)).toFixed(0),
                amountOutMin: new BigNumber(routes[i].amOut)
                  .minus(new BigNumber(routes[i].amOut).times(slippage).div(100))
                  .times(Math.pow(10, pair2.decimals))
                  .toFixed(0),
                route: _route,
              });
            }
          }

          const outMin = new BigNumber(outAmount).minus(new BigNumber(outAmount).times(slippage).div(100)).toFixed(0);

          swap_data = getSwapData(clean_routes, sendETH, receiveETH, inAmount, outMin, getPermit);
        }

        const expiry = (3000 + Date.now() / 1000).toFixed(0);

        const execute_data = wrap_data ? wrap_data : swap_data;

        var options = {};
        if (sendETH) options["value"] = inAmount;

        receipts = await handleTransaction(
          universalrouter.execute,
          zksigner,
          [execute_data.commands, execute_data.inputs, expiry],
          {
            ...options,
          },
        );
        // receipts = await universalrouter.execute(execute_data.commands, execute_data.inputs, expiry, {
        //   ...additionalParams,
        //   ...options,
        // })
      }

      const txResults = await receipts.waitAndResult();

      addTokenToStorage(pair1);
      addTokenToStorage(pair2);

      await onGetBalance();

      return {
        success: true,
        error: "",
        ...txResults,
        gasless: receipts.gasless,
      };
    } catch (e) {
      console.log(e);
      const txResults = getTransactionResults();

      return {success: false, error: JSON.stringify(e), ...txResults};
    }
  };

  const searchForLiquidityAndUpdate = async (pair1, pair2, index = 0, stable = false) => {
    const to = ethers.getAddress(getSignerAddress() as string);

    var lp_res = await searchForLiquidity(pair1, pair2, index, stable);

    const updateAll = async () => {
      try {
        var _addedLiquidity = store.getState().addedLiquidity;
        _addedLiquidity = Array.from(new Set(_addedLiquidity));
        _addedLiquidity.push(lp_res.address);
        _addedLiquidity = Array.from(new Set(_addedLiquidity));

        var all_liq = await searchForAllLiquidity(to, _addedLiquidity);

        try {
          var _ownedPairs: any = [];
          for (let i in all_liq) {
            if (new BigNumber(all_liq[i].assetAAmount).lte(0) || new BigNumber(all_liq[i].assetBAmount).lte(0)) {
              _addedLiquidity.splice(all_liq[i].index, 1);
              continue;
            }

            _ownedPairs.push(all_liq[i]);
          }

          // sory liquidity by value
          let arrayForSort = [..._ownedPairs];

          const orderedPairs = arrayForSort.sort((a: any, b: any) => {
            let _aa = getTokenFromContract(a.assetA);
            let _ab = getTokenFromContract(a.assetB);
            let _ba = getTokenFromContract(b.assetA);
            let _bb = getTokenFromContract(b.assetB);
            let _aPrice = new BigNumber(a.assetAAmount)
              .times(_aa.price ? _aa.price : 0)
              .plus(new BigNumber(a.assetBAmount).times(_ab.price ? _ab.price : 0))
              .toNumber();
            let _bPrice = new BigNumber(b.assetAAmount)
              .times(_ba.price ? _ba.price : 0)
              .plus(new BigNumber(b.assetBAmount).times(_bb.price ? _bb.price : 0))
              .toNumber();

            return _bPrice - _aPrice;
          });

          dispatch(walletSlice.actions.updateAddedLiquidity(_addedLiquidity));
          dispatch(walletSlice.actions.updateOwnedPairs(orderedPairs));
        } catch (e) {
          console.log(e);
        }
      } catch (e) {
        console.log(e);
      }
    };

    updateAll();

    return lp_res;
  };
  const searchForLiquidity = async (pair1, pair2, index = 0, stable = false) => {
    var multiResTokens: ContractCallResults;

    try {
      var pair1Address = pair1.address;
      var pair2Address = pair2.address;
      const multicall = new Multicall({ethersProvider: provider, tryAggregate: true, network: {chainId, name: chain!.name}});

      if (pair1.address.toLowerCase() == CONTRACTS.gas.address.toLowerCase()) pair1Address = CONTRACTS.gas.wrapped_address;

      if (pair2.address.toLowerCase() == CONTRACTS.gas.address.toLowerCase()) pair2Address = CONTRACTS.gas.wrapped_address;

      const to = ethers.getAddress(getSignerAddress() as string);

      const contractCallContextTokens: ContractCallContext[] = [
        {
          reference: "approveA",
          contractAddress: pair1Address,
          abi: ERC20,
          calls: [{reference: "approveA", methodName: "allowance", methodParameters: [to, CONTRACTS.router]}],
        },
        {
          reference: "approveB",
          contractAddress: pair2Address,
          abi: ERC20,
          calls: [{reference: "approveB", methodName: "allowance", methodParameters: [to, CONTRACTS.router]}],
        },
      ];

      multiResTokens = await multicall.call(contractCallContextTokens);

      const pair = await getTokenPair(pair1Address, pair2Address, stable);

      if (pair == "0x0000000000000000000000000000000000000000") throw "0 pair address";

      const contractCallContext: ContractCallContext[] = [
        {
          reference: "balance",
          contractAddress: pair,
          abi: PAIR,
          calls: [{reference: "balance", methodName: "balanceOf", methodParameters: [to]}],
        },
        {
          reference: "reserves",
          contractAddress: pair,
          abi: PAIR,
          calls: [{reference: "reserves", methodName: "getReserves", methodParameters: []}],
        },
        {
          reference: "totalSupply",
          contractAddress: pair,
          abi: PAIR,
          calls: [{reference: "totalSupply", methodName: "totalSupply", methodParameters: []}],
        },
        {
          reference: "token0",
          contractAddress: pair,
          abi: PAIR,
          calls: [{reference: "token0", methodName: "token0", methodParameters: []}],
        },
        {
          reference: "symbol",
          contractAddress: pair,
          abi: PAIR,
          calls: [{reference: "symbol", methodName: "symbol", methodParameters: []}],
        },
        {
          reference: "token1",
          contractAddress: pair,
          abi: PAIR,
          calls: [{reference: "token1", methodName: "token1", methodParameters: []}],
        },
        {
          reference: "pairFee",
          contractAddress: pair,
          abi: PAIR,
          calls: [{reference: "pairFee", methodName: "pairFee", methodParameters: []}],
        },
        {
          reference: "delegates",
          contractAddress: pair,
          abi: PAIR,
          calls: [{reference: "delegates", methodName: "delegates", methodParameters: [to]}],
        },
        {
          reference: "approveA",
          contractAddress: pair1Address,
          abi: ERC20,
          calls: [{reference: "approveA", methodName: "allowance", methodParameters: [to, CONTRACTS.router]}],
        },
        {
          reference: "approveB",
          contractAddress: pair2Address,
          abi: ERC20,
          calls: [{reference: "approveB", methodName: "allowance", methodParameters: [to, CONTRACTS.router]}],
        },
        {
          reference: "approveLP",
          contractAddress: pair,
          abi: PAIR,
          calls: [{reference: "approveLP", methodName: "allowance", methodParameters: [to, CONTRACTS.router]}],
        },

        // calculate fees
        {
          reference: "supplyIndex0",
          contractAddress: pair,
          abi: PAIR,
          calls: [{reference: "supplyIndex0", methodName: "supplyIndex0", methodParameters: [to]}],
        },
        {
          reference: "supplyIndex1",
          contractAddress: pair,
          abi: PAIR,
          calls: [{reference: "supplyIndex1", methodName: "supplyIndex1", methodParameters: [to]}],
        },
        {
          reference: "claimable0",
          contractAddress: pair,
          abi: PAIR,
          calls: [{reference: "claimable0", methodName: "claimable0", methodParameters: [to]}],
        },
        {
          reference: "claimable1",
          contractAddress: pair,
          abi: PAIR,
          calls: [{reference: "claimable1", methodName: "claimable1", methodParameters: [to]}],
        },
        {
          reference: "index0",
          contractAddress: pair,
          abi: PAIR,
          calls: [{reference: "index0", methodName: "index0", methodParameters: []}],
        },
        {
          reference: "index1",
          contractAddress: pair,
          abi: PAIR,
          calls: [{reference: "index1", methodName: "index1", methodParameters: []}],
        },
        {
          reference: "stable",
          contractAddress: pair,
          abi: PAIR,
          calls: [{reference: "stable", methodName: "stable", methodParameters: []}],
        },
        {
          reference: "getCurrentVotes",
          contractAddress: pair,
          abi: PAIR,
          calls: [{reference: "getCurrentVotes", methodName: "getCurrentVotes", methodParameters: [to]}],
        },
      ];

      const multiRes: ContractCallResults = await multicall.call(contractCallContext);
      var bal = multiRes.results["balance"].callsReturnContext[0].returnValues[0];
      var reserves = [
        multiRes.results["reserves"].callsReturnContext[0].returnValues[0],
        multiRes.results["reserves"].callsReturnContext[0].returnValues[1],
      ];
      var supply = multiRes.results["totalSupply"].callsReturnContext[0].returnValues[0];
      var tot_votes = multiRes.results["getCurrentVotes"].callsReturnContext[0].returnValues[0];

      const token0 = multiRes.results["token0"].callsReturnContext[0].returnValues[0].toLowerCase();
      const token1 = multiRes.results["token1"].callsReturnContext[0].returnValues[0].toLowerCase();

      var reserveANormalized =
        pair1Address.toLowerCase() == token0
          ? new BigNumber(reserves[0]).div(Math.pow(10, pair1.decimals))
          : new BigNumber(reserves[1]).div(Math.pow(10, pair1.decimals));
      var reserveBNormalized =
        pair1Address.toLowerCase() == token0
          ? new BigNumber(reserves[1]).div(Math.pow(10, pair2.decimals))
          : new BigNumber(reserves[0]).div(Math.pow(10, pair2.decimals));
      var pairFeePercent = new BigNumber(multiRes.results["pairFee"].callsReturnContext[0].returnValues[0])
        .div(100)
        .toFixed(2);

      var lp_price = new BigNumber(0);

      if (!new BigNumber(pair1.price).eq(0)) {
        let res = reserveANormalized;
        lp_price = new BigNumber(
          new BigNumber(res)
            .times(pair1.price)
            .times(2)
            .div(new BigNumber(supply).div(Math.pow(10, 18))),
        );
      } else if (!new BigNumber(pair2.price).eq(0)) {
        let res = reserveBNormalized;
        lp_price = new BigNumber(
          new BigNumber(res)
            .times(pair2.price)
            .times(2)
            .div(new BigNumber(supply).div(Math.pow(10, 18))),
        );
      }

      var claimable0 = new BigNumber(multiRes.results["claimable0"].callsReturnContext[0].returnValues[0]);
      var claimable1 = new BigNumber(multiRes.results["claimable1"].callsReturnContext[0].returnValues[0]);

      var claimable0_f = claimable0
        .div(Math.pow(10, pair1Address.toLowerCase() == token0 ? pair1.decimals : pair2.decimals))
        .toFixed();
      var claimable1_f = claimable1
        .div(Math.pow(10, pair1Address.toLowerCase() == token0 ? pair2.decimals : pair1.decimals))
        .toFixed();
      var delta0 = new BigNumber(multiRes.results["index0"].callsReturnContext[0].returnValues[0]).minus(
        multiRes.results["supplyIndex0"].callsReturnContext[0].returnValues[0],
      );
      var delta1 = new BigNumber(multiRes.results["index1"].callsReturnContext[0].returnValues[0]).minus(
        multiRes.results["supplyIndex1"].callsReturnContext[0].returnValues[0],
      );

      if (delta0.gt(0)) {
        claimable0 = claimable0.plus(delta0.times(bal).div(Math.pow(10, 18)));
        claimable0_f = claimable0
          .div(Math.pow(10, pair1Address.toLowerCase() == token0 ? pair1.decimals : pair2.decimals))
          .toFixed();
      }

      if (delta1.gt(0)) {
        claimable1 = claimable1.plus(delta1.times(bal).div(Math.pow(10, 18)));
        claimable1_f = claimable1
          .div(Math.pow(10, pair1Address.toLowerCase() == token0 ? pair2.decimals : pair1.decimals))
          .toFixed();
      }

      const lp_res = {
        hasLiq: new BigNumber(bal).gt(0),
        available: true,
        assetA: pair1,
        assetB: pair2,
        assetAAmount: new BigNumber(bal).div(supply).times(reserveANormalized).toFixed(),
        assetBAmount: new BigNumber(bal).div(supply).times(reserveBNormalized).toFixed(),
        assetATotal: reserveANormalized.toFixed(),
        assetBTotal: reserveBNormalized.toFixed(),
        address: pair,
        lpPrice: lp_price.toFixed(),
        claimable0: pair1Address.toLowerCase() == token0 ? claimable0_f : claimable1_f,
        claimable1: pair1Address.toLowerCase() == token0 ? claimable1_f : claimable0_f,
        stable: multiRes.results["stable"].callsReturnContext[0].returnValues[0],
        pairFeePercent,
        symbol: multiRes.results["symbol"].callsReturnContext[0].returnValues[0],
        approveAssetA:
          pair1.address.toLowerCase() == CONTRACTS.gas.address.toLowerCase()
            ? false
            : new BigNumber(multiResTokens.results["approveA"].callsReturnContext[0].returnValues[0]).lte(
                min_approve_amount,
              ),
        approveAssetB:
          pair2.address.toLowerCase() == CONTRACTS.gas.address.toLowerCase()
            ? false
            : new BigNumber(multiResTokens.results["approveB"].callsReturnContext[0].returnValues[0]).lte(
                min_approve_amount,
              ),
        approveAssetAAmount: new BigNumber(multiResTokens.results["approveA"].callsReturnContext[0].returnValues[0])
          .div(Math.pow(10, pair1Address.toLowerCase() == token0 ? pair1.decimals : pair2.decimals))
          .toFixed(),
        approveAssetBAmount: new BigNumber(multiResTokens.results["approveB"].callsReturnContext[0].returnValues[0])
          .div(Math.pow(10, pair1Address.toLowerCase() == token0 ? pair2.decimals : pair1.decimals))
          .toFixed(),
        approveAssetLP: new BigNumber(multiRes.results["approveLP"].callsReturnContext[0].returnValues[0]).lte(0),
        approveLPValue: new BigNumber(multiRes.results["approveLP"].callsReturnContext[0].returnValues[0])
          .div(Math.pow(10, 18))
          .toFixed(),
        delegate: multiRes.results["delegates"].callsReturnContext[0].returnValues[0],
        balance: new BigNumber(bal).div(Math.pow(10, 18)).toFixed(),
        share: new BigNumber(bal).div(supply).times(100).toFixed(2),
        vote_share: new BigNumber(tot_votes).div(supply).times(100).toFixed(2),
        supply: new BigNumber(supply).div(Math.pow(10, 18)).toFixed(),
        index: index,
      };

      return lp_res;
    } catch (e) {
      console.log(e);
      return {
        hasLiq: false,
        available: false,
        assetA: pair1,
        assetB: pair2,
        assetAAmount: "0.00",
        assetBAmount: "0.00",
        assetATotal: "0.00",
        assetBTotal: "0.00",
        claimable0: "0.00",
        claimable1: "0.00",
        stable: false,
        lpPrice: "0",
        address: "0x000000000000000000000000000000000",
        pairFeePercent: "0.30",
        symbol: "",
        approveAssetA:
          pair1.address.toLowerCase() == CONTRACTS.gas.address.toLowerCase()
            ? false
            : new BigNumber(multiResTokens!.results["approveA"].callsReturnContext[0].returnValues[0]).lte(
                min_approve_amount,
              ),
        approveAssetB:
          pair2.address.toLowerCase() == CONTRACTS.gas.address.toLowerCase()
            ? false
            : new BigNumber(multiResTokens!.results["approveB"].callsReturnContext[0].returnValues[0]).lte(
                min_approve_amount,
              ),
        approveLPValue: "0",
        delegate: "0x000000000000000000000000000000000",
        balance: "0.00",
        share: "0.00",
        vote_share: "0.00",
        supply: "0.00",
        index: index,
      };
    }
  };

  const searchForLiquidityWithAddressAndUpdate = async (pair, index = 0, stable = false) => {
    const to = ethers.getAddress(getSignerAddress() as string);
    const lp_res = await searchForLiquidityWithAddress(pair, index, stable);

    var _addedLiquidity = store.getState().addedLiquidity;
    _addedLiquidity = Array.from(new Set(_addedLiquidity));
    _addedLiquidity.push(lp_res.address);
    _addedLiquidity = Array.from(new Set(_addedLiquidity));

    var all_liq = await searchForAllLiquidity(to, _addedLiquidity);

    try {
      var _ownedPairs: any = [];
      for (let i in all_liq) {
        if (new BigNumber(all_liq[i].assetAAmount).lte(0) || new BigNumber(all_liq[i].assetBAmount).lte(0)) {
          _addedLiquidity.splice(all_liq[i].index, 1);
          continue;
        }

        _ownedPairs.push(all_liq[i]);
      }

      // sory liquidity by value
      let arrayForSort = [..._ownedPairs];

      const orderedPairs = arrayForSort.sort((a: any, b: any) => {
        let _aa = getTokenFromContract(a.assetA);
        let _ab = getTokenFromContract(a.assetB);
        let _ba = getTokenFromContract(b.assetA);
        let _bb = getTokenFromContract(b.assetB);
        let _aPrice = new BigNumber(a.assetAAmount)
          .times(_aa.price ? _aa.price : 0)
          .plus(new BigNumber(a.assetBAmount).times(_ab.price ? _ab.price : 0))
          .toNumber();
        let _bPrice = new BigNumber(b.assetAAmount)
          .times(_ba.price ? _ba.price : 0)
          .plus(new BigNumber(b.assetBAmount).times(_bb.price ? _bb.price : 0))
          .toNumber();

        return _bPrice - _aPrice;
      });

      dispatch(walletSlice.actions.updateAddedLiquidity(_addedLiquidity));
      dispatch(walletSlice.actions.updateOwnedPairs(orderedPairs));
    } catch (e) {
      console.log(e);
    }
  };

  const searchForLiquidityWithAddress = async (pair, index = 0, stable = false) => {
    try {
      if (pair == "0x000000000000000000000000000000000") throw "0 pair address";

      const to = ethers.getAddress(getSignerAddress() as string);

      const multicall = new Multicall({ethersProvider: provider, tryAggregate: true, network: {chainId, name: chain!.name}});
      const contractCallContext: ContractCallContext[] = [
        {
          reference: "balance",
          contractAddress: pair,
          abi: PAIR,
          calls: [{reference: "balance", methodName: "balanceOf", methodParameters: [to]}],
        },
        {
          reference: "reserves",
          contractAddress: pair,
          abi: PAIR,
          calls: [{reference: "reserves", methodName: "getReserves", methodParameters: []}],
        },
        {
          reference: "totalSupply",
          contractAddress: pair,
          abi: PAIR,
          calls: [{reference: "totalSupply", methodName: "totalSupply", methodParameters: []}],
        },
        {
          reference: "token0",
          contractAddress: pair,
          abi: PAIR,
          calls: [{reference: "token0", methodName: "token0", methodParameters: []}],
        },
        {
          reference: "symbol",
          contractAddress: pair,
          abi: PAIR,
          calls: [{reference: "symbol", methodName: "symbol", methodParameters: []}],
        },
        {
          reference: "token1",
          contractAddress: pair,
          abi: PAIR,
          calls: [{reference: "token1", methodName: "token1", methodParameters: []}],
        },
        {
          reference: "pairFee",
          contractAddress: pair,
          abi: PAIR,
          calls: [{reference: "pairFee", methodName: "pairFee", methodParameters: []}],
        },
        {
          reference: "delegates",
          contractAddress: pair,
          abi: PAIR,
          calls: [{reference: "delegates", methodName: "delegates", methodParameters: [to]}],
        },
        {
          reference: "approveLP",
          contractAddress: pair,
          abi: PAIR,
          calls: [{reference: "approveLP", methodName: "allowance", methodParameters: [to, CONTRACTS.router]}],
        },
        {
          reference: "stable",
          contractAddress: pair,
          abi: PAIR,
          calls: [{reference: "stable", methodName: "stable", methodParameters: []}],
        },
      ];

      const multiRes: ContractCallResults = await multicall.call(contractCallContext);

      var bal = multiRes.results["balance"].callsReturnContext[0].returnValues[0];
      var reserves = [
        multiRes.results["reserves"].callsReturnContext[0].returnValues[0],
        multiRes.results["reserves"].callsReturnContext[0].returnValues[1],
      ];
      var supply = multiRes.results["totalSupply"].callsReturnContext[0].returnValues[0];
      var token0 = multiRes.results["token0"].callsReturnContext[0].returnValues[0].toLowerCase();
      var token1 = multiRes.results["token1"].callsReturnContext[0].returnValues[0].toLowerCase();

      const pair1 = getTokenFromContract(token0);
      const pair2 = getTokenFromContract(token1);

      //@ts-ignore
      var reserveANormalized = new BigNumber(reserves[0]).div(Math.pow(10, pair1!.decimals));
      //@ts-ignore
      var reserveBNormalized = new BigNumber(reserves[1]).div(Math.pow(10, pair2!.decimals));
      var pairFeePercent = new BigNumber(multiRes.results["pairFee"].callsReturnContext[0].returnValues[0])
        .div(100)
        .toFixed(2);

      const contractCallContextTokens: ContractCallContext[] = [
        {
          reference: "approveA",
          contractAddress: token0,
          abi: ERC20,
          calls: [{reference: "approveA", methodName: "allowance", methodParameters: [to, CONTRACTS.router]}],
        },
        {
          reference: "approveB",
          contractAddress: token1,
          abi: ERC20,
          calls: [{reference: "approveB", methodName: "allowance", methodParameters: [to, CONTRACTS.router]}],
        },
      ];
      const multiResTokens: ContractCallResults = await multicall.call(contractCallContextTokens);

      var lp_res = {
        hasLiq: new BigNumber(bal).gt(0),
        available: true,
        assetA: pair1,
        assetB: pair2,
        assetAAmount: new BigNumber(bal).div(supply).times(reserveANormalized).toFixed(),
        assetBAmount: new BigNumber(bal).div(supply).times(reserveBNormalized).toFixed(),
        assetATotal: reserveANormalized.toFixed(),
        assetBTotal: reserveBNormalized.toFixed(),
        address: pair,
        pairFeePercent,
        symbol: multiRes.results["symbol"].callsReturnContext[0].returnValues[0],
        //@ts-ignore
        approveAssetA:
          pair1!.address.toLowerCase() == CONTRACTS.gas.address.toLowerCase()
            ? false
            : new BigNumber(multiResTokens.results["approveA"].callsReturnContext[0].returnValues[0]).lte(
                min_approve_amount,
              ),
        //@ts-ignore
        approveAssetB:
          pair2!.address.toLowerCase() == CONTRACTS.gas.address.toLowerCase()
            ? false
            : new BigNumber(multiResTokens.results["approveB"].callsReturnContext[0].returnValues[0]).lte(
                min_approve_amount,
              ),
        approveAssetLP: new BigNumber(multiRes.results["approveLP"].callsReturnContext[0].returnValues[0]).lte(
          min_approve_amount,
        ),
        delegate: multiRes.results["delegates"].callsReturnContext[0].returnValues[0],
        balance: new BigNumber(bal).div(Math.pow(10, 18)).toFixed(),
        share: new BigNumber(bal).div(supply).times(100).toFixed(2),
        index: index,
        stable: multiRes.results["stable"].callsReturnContext[0].returnValues[0],
      };

      return lp_res;
    } catch (e) {
      return {
        hasLiq: false,
        available: false,
        assetA: {},
        assetB: {},
        assetAAmount: "0.00",
        assetBAmount: "0.00",
        assetATotal: "0.00",
        assetBTotal: "0.00",
        address: pair,
        pairFeePercent: stable ? "0.05" : "0.30",
        symbol: "",
        approveAssetA: true,
        approveAssetB: true,
        delegate: "0x000000000000000000000000000000000",
        balance: "0.00",
        share: "0.00",
        index: index,
        stable: false,
      };
    }
  };

  const approveTokenAmount = async (token, address) => {
    const zksigner = await getZkSigner();
    var contract = new Contract(token, ERC20, zksigner!);

    dispatch(walletSlice.actions.updaetTransactionModalStatus("Approve token for spend"));

    var txParams = await getPaymasterParams();

    try {
      var gasLimitEstimate = await contract.approve.estimateGas(address, approve_amount, txParams);
      txParams.gasLimit = BigInt(gasLimitEstimate.toString());
    } catch (e) {
      console.log("error");
      console.log(e);
    }

    const {tx} = await sendSponsoredPaymaster(contract.approve, zksigner, [address, approve_amount], txParams);

    await (await tx).wait();
  };

  const approveTokenAmountBase = async (token, address) => {
    const zksigner = await getZkSigner();

    var contract = new Contract(token, ERC20, zksigner!);
    dispatch(walletSlice.actions.updaetTransactionModalStatus("Approve fee token for spend"));

    const gasLimitEstimate = await contract.approve.estimateGas(address, approve_amount);

    var txParams = {
      gasLimit: BigInt(gasLimitEstimate.toString()),
    };

    const txHandle = await contract.approve(address, approve_amount, txParams);
    await txHandle.wait();
  };

  const approveTokenAmountRaw = async (token, address) => {
    const zksigner = await getZkSigner();

    var contract = new Contract(token, ERC20, zksigner!);
    dispatch(walletSlice.actions.updaetTransactionModalStatus("Approve token for spend"));

    var txParams = await getPaymasterParams();

    try {
      var gasLimitEstimate = await contract.approve.estimateGas(address, approve_amount, txParams);
      txParams.gasLimit = BigInt(gasLimitEstimate.toString());
    } catch (e) {
      console.log("error");
      console.log(e);
    }

    return await contract.approve.populateTransaction(address, approve_amount, txParams);
  };

  const checkAllowance = async (token, address, amount = min_approve_amount.toFixed()) => {
    if (ethers.getAddress(token) == CONTRACTS.gas.address) return true;

    const zksigner = await getZkSigner();

    const web3contract = new Contract(token, ERC20, zksigner);
    var res = await web3contract.allowance(getSignerAddress(), address);

    return new BigNumber(res.toString()).gte(amount);
  };

  const getAllowance = async (token, address) => {
    if (ethers.getAddress(token) == CONTRACTS.gas.address) return new BigNumber(approve_amount).toFixed();

    const zksigner = await getZkSigner();

    const web3contract = new Contract(token, ERC20, zksigner);

    var res = await web3contract.allowance(getSignerAddress(), address);
    return new BigNumber(res.toString()).toFixed();
  };

  const createLiquidityPool = async (
    pair1,
    pair2,
    amountA,
    amountB,
    _fee = 30,
    stable = false,
    slippage = 0.5,
    pair = null,
  ) => {
    await chainSafetyCheck();

    if (pair1.address.toLowerCase() == CONTRACTS.gas.address.toLowerCase()) {
      return createLiquidityPoolETH(pair2, amountB, amountA, pair1, _fee, stable, slippage);
    } else if (pair2.address.toLowerCase() == CONTRACTS.gas.address.toLowerCase()) {
      return createLiquidityPoolETH(pair1, amountA, amountB, pair2, _fee, stable, slippage);
    }

    const token0 = pair1.address < pair2.address ? pair1.address : pair2.address;
    const token1 = pair1.address < pair2.address ? pair2.address : pair1.address;

    const pair1Final = pair1.address < pair2.address ? pair1 : pair2;
    const pair2Final = pair1.address < pair2.address ? pair2 : pair1;

    var amountAFinal = pair1.address < pair2.address ? amountA : amountB;
    var amountBFinal = pair1.address < pair2.address ? amountB : amountA;

    amountAFinal = new BigNumber(amountAFinal).times(Math.pow(10, pair1Final.decimals)).toFixed(0);
    amountBFinal = new BigNumber(amountBFinal).times(Math.pow(10, pair2Final.decimals)).toFixed(0);

    var paymasterParams = await getPaymasterParams();
    var additionalParams = paymasterParams.customData
      ? Utils.deepClone(paymasterParams)
      : {gasLimit: paymasterParams.gasLimit, customData: null};

    try {
      const zksigner = await getZkSigner();
      const to = ethers.getAddress(getSignerAddress() as string);
      var deadline = (await getDeadline()).timestamp + 60 * 60;

      if ((await checkAllowance(token0, CONTRACTS.router, amountAFinal)) == false)
        await approveTokenAmount(token0, CONTRACTS.router);

      if ((await checkAllowance(token1, CONTRACTS.router, amountBFinal)) == false)
        await approveTokenAmount(token1, CONTRACTS.router);

      var routerNew = new Contract(CONTRACTS.router, ROUTER, zksigner!);

      const receipts = await handleTransaction(routerNew.addLiquidity, zksigner, [
        token0,
        token1,
        amountAFinal,
        amountBFinal,
        new BigNumber(100).minus(slippage).times(amountAFinal).div(100).toFixed(0),
        new BigNumber(100).minus(slippage).times(amountBFinal).div(100).toFixed(0),
        to,
        deadline,
        _fee,
        stable,
      ]);

      const txResults = await receipts.waitAndResult();

      if (pair) addLiquidityToStorage(pair);

      var res = {
        amountA: 1,
        amountB: 1,
        liquidity: 5,
        share: 100,
      };

      return {
        amountA: new BigNumber(amountAFinal).div(Math.pow(10, 18)).toFixed(),
        amountB: new BigNumber(amountBFinal).div(Math.pow(10, 18)).toFixed(),
        liquidity: new BigNumber(res.liquidity).div(Math.pow(10, 18)).toFixed(),
        share: res.share,
        success: true,
        ...txResults,
        gasless: receipts.gasless,
        error: "",
      };
    } catch (e) {
      console.log(e);
      let txResults = getTransactionResults();

      return {
        amountA: null,
        amountB: null,
        liquidity: null,
        error: JSON.stringify(e),
        share: null,
        success: false,
        ...txResults,
      };
    }
  };

  const createLiquidityPoolETH = async (
    pair1,
    amount1,
    ethAmount,
    ethPair,
    _fee = 30,
    stable = false,
    slippage,
    pair = null,
  ) => {
    var amountAFinal = new BigNumber(amount1).times(Math.pow(10, pair1.decimals)).toFixed(0);
    var amountETH = new BigNumber(ethAmount).times(Math.pow(10, 18)).toFixed(0);

    try {
      const to = ethers.getAddress(getSignerAddress() as string);
      var deadline = (await getDeadline()).timestamp + 60 * 60;

      if ((await checkAllowance(pair1.address, CONTRACTS.router, amountAFinal)) == false)
        await approveTokenAmount(pair1.address, CONTRACTS.router);

      const zksigner = await getZkSigner();

      var routerNew = new Contract(CONTRACTS.router, ROUTER, zksigner!);

      const receipts = await handleTransaction(
        routerNew.addLiquidityETH,
        zksigner,
        [
          pair1.address,
          amountAFinal,
          new BigNumber(100).minus(slippage).times(amountAFinal).div(100).toFixed(0),
          new BigNumber(100).minus(slippage).times(amountETH).div(100).toFixed(0),
          to,
          deadline,
          _fee,
          stable,
        ],
        {value: amountETH},
      );

      const txResults = await receipts.waitAndResult();

      const pair1Final = pair1.address < CONTRACTS.gas.wrapped_address ? pair1.address : CONTRACTS.gas.wrapped_address;
      const pair2Final = pair1.address < CONTRACTS.gas.wrapped_address ? CONTRACTS.gas.wrapped_address : pair1.address;

      if (pair) addLiquidityToStorage(pair);

      var res = {
        amountA: 1,
        amountB: 1,
        liquidity: 5,
        share: 100,
      };

      return {
        amountA: new BigNumber(amount1).toFixed(),
        amountB: new BigNumber(ethAmount).toFixed(),
        liquidity: new BigNumber(res.liquidity).div(Math.pow(10, 18)).toFixed(),
        share: res.share,
        success: true,
        error: "",
        ...txResults,
      };
    } catch (e) {
      console.log(e);
      let txResults = getTransactionResults();
      return {
        amountA: null,
        amountB: null,
        liquidity: null,
        share: null,
        success: false,
        error: JSON.stringify(e),
        ...txResults,
      };
    }
  };

  const removeLiquidityV3 = async (id, token1, token2, liquidity, amount1, amount2, slippage) => {
    if (isETHSolo(token1.address)) {
      token1 = store.getState().tokens[CONTRACTS.gas.wrapped_address.toLowerCase()];
    } else if (isETHSolo(token2.address)) {
      token2 = store.getState().tokens[CONTRACTS.gas.wrapped_address.toLowerCase()];
    }

    // sort tokens
    if (new BigNumber(token1.address, 16).gt(token2.address, 16)) {
      let _temp = Utils.deepClone(token1);
      token1 = Utils.deepClone(token2);
      token2 = Utils.deepClone(_temp);

      _temp = amount1;
      amount1 = amount2;
      amount2 = _temp;
    }

    var amountA = new BigNumber(amount1).times(Math.pow(10, token1.decimals)).toFixed(0);
    var amountB = new BigNumber(amount2).times(Math.pow(10, token2.decimals)).toFixed(0);

    try {
      const to = ethers.getAddress(getSignerAddress() as string);
      var deadline = (await getDeadline()).timestamp + 60 * 60;

      const zksigner = await getZkSigner();

      const NonFungContract = new Contract(
        CONTRACTS.nonfungibleTokenPositionManagerAddress,
        NonfungiblePositionManager.abi,
        zksigner,
      );

      const RemoveParams = {
        tokenId: id,
        liquidity,
        amount0Min: new BigNumber(100).minus(slippage).times(amountA).div(100).toFixed(0),
        amount1Min: new BigNumber(100).minus(slippage).times(amountB).div(100).toFixed(0),
        deadline,
      };

      const burn_ABI = await NonFungContract.decreaseLiquidity.populateTransaction(RemoveParams);

      const MAX_UINT128 = new BigNumber(2).pow(128).minus(1).toFixed();

      // we claim to the positon manager so we can sweep balances and unwrap weth
      const CollectParams = {
        tokenId: id,
        recipient: CONTRACTS.nonfungibleTokenPositionManagerAddress,
        amount0Max: MAX_UINT128,
        amount1Max: MAX_UINT128,
      };

      const collect_ABI = await NonFungContract.collect.populateTransaction(CollectParams);

      const unwrap_ETH_ABI = await NonFungContract.unwrapWETH9.populateTransaction(0, to);
      const sweep_token1_ABI = await NonFungContract.sweepToken.populateTransaction(wethOrToken(token1.address), 0, to);
      const sweep_token2_ABI = await NonFungContract.sweepToken.populateTransaction(wethOrToken(token2.address), 0, to);

      const calls = [
        burn_ABI.data,
        collect_ABI.data,
        unwrap_ETH_ABI.data,
        sweep_token1_ABI.data,
        sweep_token2_ABI.data,
      ];

      const receipts = await handleTransaction(NonFungContract.multicall, zksigner, [calls]);

      const txResults = await receipts.waitAndResult();

      return {
        ...txResults,
        success: true,
        gasless: receipts.gasless,
      };
    } catch (e) {
      console.log(e);
      let txResults = getTransactionResults();
      return {
        amountA: null,
        amountB: null,
        liquidity: null,
        share: null,
        success: false,
        error: JSON.stringify(e),
        ...txResults,
      };
    }
  };

  const increaseLiquidityV3 = async (id, token1, token2, amount1, amount2, slippage) => {
    var hasETH = false;

    if (isETHSolo(token1.address)) {
      hasETH = true;
      token1 = store.getState().tokens[CONTRACTS.gas.wrapped_address.toLowerCase()];
    } else if (isETHSolo(token2.address)) {
      hasETH = true;
      token2 = store.getState().tokens[CONTRACTS.gas.wrapped_address.toLowerCase()];
    }

    // sort tokens
    if (new BigNumber(token1.address, 16).gt(token2.address, 16)) {
      let _temp = Utils.deepClone(token1);
      token1 = Utils.deepClone(token2);
      token2 = Utils.deepClone(_temp);

      _temp = amount1;
      amount1 = amount2;
      amount2 = _temp;
    }

    var amountA = new BigNumber(amount1).times(Math.pow(10, token1.decimals)).toFixed(0);
    var amountB = new BigNumber(amount2).times(Math.pow(10, token2.decimals)).toFixed(0);

    try {
      var deadline = (await getDeadline()).timestamp + 60 * 60;

      if (
        !(isETH(token1.address) && hasETH) &&
        (await checkAllowance(token1.address, CONTRACTS.nonfungibleTokenPositionManagerAddress, amountA)) == false
      )
        await approveTokenAmount(token1.address, CONTRACTS.nonfungibleTokenPositionManagerAddress);

      if (
        !(isETH(token2.address) && hasETH) &&
        (await checkAllowance(token2.address, CONTRACTS.nonfungibleTokenPositionManagerAddress, amountB)) == false
      )
        await approveTokenAmount(token2.address, CONTRACTS.nonfungibleTokenPositionManagerAddress);

      const zksigner = await getZkSigner();

      const NonFungContract = new Contract(
        CONTRACTS.nonfungibleTokenPositionManagerAddress,
        NonfungiblePositionManager.abi,
        zksigner,
      );

      const IncreaseParams = {
        tokenId: id,
        amount0Desired: amountA,
        amount1Desired: amountB,
        amount0Min: new BigNumber(100).minus(slippage).times(amountA).div(100).toFixed(0),
        amount1Min: new BigNumber(100).minus(slippage).times(amountB).div(100).toFixed(0),
        deadline,
      };

      const mint_ABI = await NonFungContract.increaseLiquidity.populateTransaction(IncreaseParams);
      const calls = [mint_ABI.data];

      const receipts = await handleTransaction(NonFungContract.multicall, zksigner, [calls], {
        value: hasETH ? (isETH(token1.address) ? amountA : amountB) : 0,
      });

      const txResults = await receipts.waitAndResult();

      return {
        ...txResults,
        success: true,
        gasless: receipts.gasless,
      };
    } catch (e) {
      console.log(e);
      let txResults = getTransactionResults();
      return {
        amountA: null,
        amountB: null,
        liquidity: null,
        share: null,
        success: false,
        error: JSON.stringify(e),
        ...txResults,
      };
    }
  };

  const addLiquidityV3 = async (
    token1,
    token2,
    amount1,
    amount2,
    tickLower,
    tickUpper,
    _fee = 5000,
    slippage,
    currentTick,
  ) => {
    var hasETH = false;

    if (isETHSolo(token1.address)) {
      hasETH = true;
      token1 = store.getState().tokens[CONTRACTS.gas.wrapped_address.toLowerCase()];
    } else if (isETHSolo(token2.address)) {
      hasETH = true;
      token2 = store.getState().tokens[CONTRACTS.gas.wrapped_address.toLowerCase()];
    }

    // sort tokens
    if (new BigNumber(token1.address, 16).gt(token2.address, 16)) {
      let _temp = Utils.deepClone(token1);
      token1 = Utils.deepClone(token2);
      token2 = Utils.deepClone(_temp);

      _temp = amount1;
      amount1 = amount2;
      amount2 = _temp;
    }

    var amountA = new BigNumber(amount1).times(Math.pow(10, token1.decimals)).toFixed(0);
    var amountB = new BigNumber(amount2).times(Math.pow(10, token2.decimals)).toFixed(0);

    try {
      const to = ethers.getAddress(getSignerAddress() as string);
      var deadline = (await getDeadline()).timestamp + 60 * 60;

      if (
        !(isETH(token1.address) && hasETH) &&
        (await checkAllowance(token1.address, CONTRACTS.nonfungibleTokenPositionManagerAddress, amountA)) == false
      )
        await approveTokenAmount(token1.address, CONTRACTS.nonfungibleTokenPositionManagerAddress);

      if (
        !(isETH(token2.address) && hasETH) &&
        (await checkAllowance(token2.address, CONTRACTS.nonfungibleTokenPositionManagerAddress, amountB)) == false
      )
        await approveTokenAmount(token2.address, CONTRACTS.nonfungibleTokenPositionManagerAddress);

      const zksigner = await getZkSigner();

      const NonFungContract = new Contract(
        CONTRACTS.nonfungibleTokenPositionManagerAddress,
        NonfungiblePositionManager.abi,
        zksigner,
      );

      const sqrtPriceX96 = TickMath.getSqrtRatioAtTick(currentTick);

      const init_ABI = await NonFungContract.createAndInitializePoolIfNecessary.populateTransaction(
        token1.address,
        token2.address,
        _fee,
        sqrtPriceX96.toString(),
      );

      const MintParams = {
        token0: token1.address,
        token1: token2.address,
        fee: _fee,
        tickLower,
        tickUpper,
        amount0Desired: amountA,
        amount1Desired: amountB,
        amount0Min: new BigNumber(100).minus(slippage).times(amountA).div(100).toFixed(0),
        amount1Min: new BigNumber(100).minus(slippage).times(amountB).div(100).toFixed(0),
        recipient: to,
        deadline,
      };

      const mint_ABI = await NonFungContract.mint.populateTransaction(MintParams);
      const calls = [init_ABI.data, mint_ABI.data];

      const receipts = await handleTransaction(NonFungContract.multicall, zksigner, [calls], {
        value: hasETH ? (isETH(token1.address) ? amountA : amountB) : 0,
      });

      const txResults = await receipts.waitAndResult();

      return {
        ...txResults,
        success: true,
        gasless: receipts.gasless,
      };
    } catch (e) {
      console.log(e);
      let txResults = getTransactionResults();
      return {
        amountA: null,
        amountB: null,
        liquidity: null,
        share: null,
        success: false,
        error: JSON.stringify(e),
        ...txResults,
      };
    }
  };

  const removeLiquidityPermit = async (pair1, pair2, pairAddress = null, stable = false) => {
    if (pairAddress == null) {
      if (pair1.toLowerCase() == CONTRACTS.gas.address.toLowerCase()) {
        pairAddress = await getTokenPair(CONTRACTS.gas.wrapped_address, pair2, stable);
      } else if (pair2.toLowerCase() == CONTRACTS.gas.address.toLowerCase()) {
        pairAddress = await getTokenPair(pair1, CONTRACTS.gas.wrapped_address, stable);
      } else {
        pairAddress = await getTokenPair(pair1, pair2, stable);
      }
    }

    const zksigner = await getZkSigner();

    if ((await checkAllowance(pairAddress!, CONTRACTS.router)) == false) {
      var contract = new Contract(pairAddress!, PAIR, zksigner!);
      const txHandle = await contract.approve(CONTRACTS.router, approve_amount, {});

      await txHandle.wait();
    }

    return true;
  };

  const removeLiquidityWithPermit = async (assetA, assetB, lpAmount, sig, stable, minAmountA, minAmountB) => {
    try {
      await chainSafetyCheck();
      const to = ethers.getAddress(getSignerAddress() as string);
      const zksigner = await getZkSigner();

      var deadline = (await getDeadline()).timestamp + 60 * 60;

      var routerNew = new Contract(CONTRACTS.router, ROUTER, zksigner!);

      let receipts: Awaited<ReturnType<typeof handleTransaction>>;

      if (assetA.address.toLowerCase() == CONTRACTS.gas.address.toLowerCase()) {
        receipts = await handleTransaction(routerNew.removeLiquidityETH, zksigner, [
          assetB.address,
          new BigNumber(lpAmount).times(Math.pow(10, 18)).toFixed(0),
          new BigNumber(minAmountB).times(Math.pow(10, assetB.decimals)).toFixed(0),
          new BigNumber(minAmountA).times(Math.pow(10, 18)).toFixed(0),
          to,
          deadline,
          stable,
        ]);
      } else if (assetB.address.toLowerCase() == CONTRACTS.gas.address.toLowerCase()) {
        receipts = await handleTransaction(routerNew.removeLiquidityETH, zksigner, [
          assetA.address,
          new BigNumber(lpAmount).times(Math.pow(10, 18)).toFixed(0),
          new BigNumber(minAmountA).times(Math.pow(10, assetA.decimals)).toFixed(0),
          new BigNumber(minAmountB).times(Math.pow(10, 18)).toFixed(0),
          to,
          deadline,
          stable,
        ]);
      } else {
        receipts = await handleTransaction(routerNew.removeLiquidity, zksigner, [
          assetA.address,
          assetB.address,
          new BigNumber(lpAmount).times(Math.pow(10, 18)).toFixed(0),
          new BigNumber(minAmountA).times(Math.pow(10, assetA.decimals)).toFixed(0),
          new BigNumber(minAmountB).times(Math.pow(10, assetB.decimals)).toFixed(0),
          to,
          deadline,
          stable,
        ]);
      }

      const txResults = await receipts.waitAndResult();

      return {
        amountA: minAmountA,
        amountB: minAmountB,
        liquidity: new BigNumber(0).toFixed(),
        share: 100,
        success: true,
        error: "",
        ...txResults,
        gasless: receipts.gasless,
      };
    } catch (e) {
      console.log(e);
      let txResults = getTransactionResults();

      return {
        amountA: null,
        amountB: null,
        liquidity: null,
        share: null,
        success: false,
        error: JSON.stringify(e),
        ...txResults,
      };
    }
  };

  const getHasEnoughTokensForGas = () => {
    let fee = getFeeAsset();
    const reqAmount = new BigNumber(1).div(fee.price).toFixed();

    if (new BigNumber(fee.balance).gte(reqAmount)) return true;

    return true;
  };

  const getPaymasterParams = async () => {
    let fee = getFeeAsset();

    var _provider = new Provider(getNodeUrl());

    const paymaster = CONTRACTS.paymaster_v2;
    const gasPrice = await _provider.getGasPrice();

    if (fee.address == CONTRACTS.gas.address)
      return {
        gasPrice: gasPrice,
        gasLimit: BigInt(2500000),
      };

    const paymasterParams = utils.getPaymasterParams(paymaster, {
      type: "ApprovalBased",
      token: fee.address,
      minimalAllowance: BigInt(approve_amount),
      innerInput: new Uint8Array(),
    });

    return {
      gasPrice,
      maxFeePerGas: gasPrice,
      maxPriorityFeePerGas: gasPrice,
      gasLimit: BigInt(2500000),
      type: 0x71,
      from: ethers.getAddress(getSignerAddress() as string),
      customData: {
        gasPerPubdata: utils.DEFAULT_GAS_PER_PUBDATA_LIMIT,
        paymasterParams: paymasterParams,
      },
    };
  };

  const GetPinnedTokens = () => {
    return CONTRACTS.pinned
  };

  const getTokenInformation = async (contract) => {
    try {
      contract = ethers.getAddress(contract);
      const to = ethers.getAddress(getSignerAddress() as string);

      if (contract.toLowerCase() == CONTRACTS.gas.address.toLowerCase()) {
        return {
          success: true,
          address: contract,
          symbol: "ETH",
          decimals: 18,
          name: "Ethereum",
          //@ts-ignore
          balance: tokens[0].amount,
          active: true,
        };
      }

      if (store.getState().tokens[contract.toLowerCase()]) {
        return {
          success: true,
          ...store.getState().tokens[contract.toLowerCase()],
        };
      }

      const multicall = new Multicall({ethersProvider: provider, tryAggregate: true, network: {chainId, name: chain!.name}});
      const contractCallContext: ContractCallContext[] = [
        {
          reference: "decimals",
          contractAddress: contract,
          abi: ERC20,
          calls: [{reference: "decimals", methodName: "decimals", methodParameters: []}],
        },
        {
          reference: "symbol",
          contractAddress: contract,
          abi: ERC20,
          calls: [{reference: "symbol", methodName: "symbol", methodParameters: []}],
        },
        {
          reference: "name",
          contractAddress: contract,
          abi: ERC20,
          calls: [{reference: "name", methodName: "name", methodParameters: []}],
        },
        {
          reference: "balanceOf",
          contractAddress: contract,
          abi: ERC20,
          calls: [{reference: "balanceOf", methodName: "balanceOf", methodParameters: [to]}],
        },
      ];

      const multiRes: ContractCallResults = await multicall.call(contractCallContext);
      let symbol = multiRes.results["symbol"].callsReturnContext[0].returnValues[0];
      let decimals = new BigNumber(multiRes.results["decimals"].callsReturnContext[0].returnValues[0]).toFixed();
      let balance = new BigNumber(multiRes.results["balanceOf"].callsReturnContext[0].returnValues[0])
        .div(Math.pow(10, 18))
        .toFixed();
      let name = multiRes.results["name"].callsReturnContext[0].returnValues[0];

      dispatch(
        walletSlice.actions.updateToken({balance, symbol, address: contract, decimals, name, logo: UnknownToken, active: false}),
      );

      return {
        success: true,
        address: contract,
        symbol,
        decimals,
        name,
        balance,
        amount: balance,
        logo: UnknownToken,
        active: true,
        price: "0.00",
      };
    } catch (e) {
      return {
        success: false,
        address: contract,
        symbol: "",
        decimals: "",
        name: "",
        balance: "0.00",
        amount: "0.00",
        logo: UnknownToken,
        active: true,
        price: "0.00",
      };
    }
  };

  const ChangePoolFee = async (pair, val) => {
    try {
      if (new BigNumber(val).gt(1000) || new BigNumber(val).lt(1)) throw "out of fee range";

      const zksigner = await getZkSigner();

      const to = ethers.getAddress(getSignerAddress() as string);

      var pairContract = new Contract(pair, PAIR, zksigner!);

      var txHandle = await pairContract.changeFeeType(val.toFixed(), {});

      console.log(txHandle);

      var txResult = await txHandle.wait();

      return {success: true};
    } catch (e) {
      console.log(e);
      return {success: false};
    }
  };

  const ChangePoolDelegate = async (pair, del) => {
    try {
      const to = ethers.getAddress(getSignerAddress() as string);

      const zksigner = await getZkSigner();

      var pairContract = new Contract(pair, PAIR, zksigner!);

      var txHandle = await pairContract.delegate(del, {});

      console.log(txHandle);

      var txResult = await txHandle.wait();

      return {success: true};
    } catch (e) {
      console.log(e);
      return {success: false};
    }
  };

  const ChangeVEMuteDelegate = async (_id, del) => {
    try {
      const to = ethers.getAddress(getSignerAddress() as string);
      const zksigner = await getZkSigner();

      var veContract = new Contract(CONTRACTS.VEKOI, VEMUTE, zksigner!);
      var gasEst = await veContract.delegate.estimateGas(_id, del);

      var txHandle = await veContract.delegate(_id, del, {
        gasLimit: BigInt(gasEst.toString()),
      });

      var txResult = await txHandle.wait();

      await getDAOInfo();

      return {success: true};
    } catch (e) {
      console.log(e);
      return {success: false};
    }
  };

  const LockKoi = async (amount, lock_days, should_approve) => {
    try {
      if (new BigNumber(amount).lte(0)) throw "out of range";

      const zksigner = await getZkSigner();
      var veContract = new Contract(CONTRACTS.VEKOI, VEKOI, zksigner!);

      if (should_approve) await approveTokenAmount(CONTRACTS.KOI, CONTRACTS.VEKOI);

      var lock_time = new BigNumber(lock_days).times(60 * 60 * 24);

      dispatch(walletSlice.actions.updaetTransactionModalStatus("Confirm transaction in your wallet"));

      const receipts = await handleTransaction(veContract.Lock, zksigner, [
        new BigNumber(amount).times(Math.pow(10, 18)).toFixed(0),
        lock_time.toFixed(),
      ]);

      const txResults = await receipts.waitAndResult();

      await getDAOInfo();
      return {
        ...txResults,
        success: true,
        gasless: receipts.gasless,
      };
    } catch (e) {
      console.log(e);
      return {success: false};
    }
  };

  const RedeemVEKOI = async (index) => {
    try {
      const zksigner = await getZkSigner();
      var veContract = new Contract(CONTRACTS.VEKOI, VEKOI, zksigner!);

      const receipts = await handleTransaction(veContract.Redeem, zksigner, [[index]]);

      const txResults = await receipts.waitAndResult();

      await getDAOInfo();
      return {
        success: true,
        gasless: receipts.gasless,
      };
    } catch (e) {
      console.log(e);
      return {success: false};
    }
  };

  const getDelegatedVe = async () => {
    try {
      const to = ethers.getAddress(getSignerAddress() as string);
      var veContract = new Contract(CONTRACTS.VEKOI, VEKOI, await getZkSigner());

      const filter = veContract.filters.DelegateChanged(null, null, to);
      //emit DelegateChanged(oldDelegate, tokenId, delegatee);

      var logs = await provider.getLogs({
        fromBlock: 0,
        toBlock: "latest",
        address: CONTRACTS.VEKOI,
        topics: await filter.getTopicFilter(),
      });

      var ve_: string[] = [];
      for (let i in logs) {
        ve_.push(new BigNumber(logs[i].topics[2]).toString());
      }

      return ve_;
    } catch (e) {
      console.log(e);
    }

    return [];
  };

  const getDAOInfo = async () => {
    try {
      const multicall = new Multicall({ethersProvider: provider, tryAggregate: true, network: {chainId, name: chain!.name}});
      const to = ethers.getAddress(getSignerAddress() as string);
      var veContract = new Contract(CONTRACTS.VEKOI, VEKOI, await getZkSigner());

      const delegatedVes = await getDelegatedVe();

      const contractCallContext: ContractCallContext[] = [
        {
          reference: "vekoi_info",
          contractAddress: CONTRACTS.VEKOI,
          abi: VEKOI,
          calls: [
            {reference: "ve_votes", methodName: "GetVotingTokens", methodParameters: [to]},
            {
              reference: "ve_vote_supply",
              methodName: "getPastTotalSupply",
              methodParameters: [new BigNumber((await veContract.clock()).toString()).minus(1).toFixed()],
            },
            {reference: "ve_delegate", methodName: "delegates", methodParameters: [to]},
            {reference: "GetUnderlyingTokens", methodName: "GetUnderlyingTokens", methodParameters: [to]},
            {reference: "ve_nft_count", methodName: "balanceOf", methodParameters: [to]},
          ],
        },
        {
          reference: "koiallowance",
          contractAddress: CONTRACTS.KOI,
          abi: ERC20,
          calls: [
            {reference: "allowance_ve", methodName: "allowance", methodParameters: [to, CONTRACTS.VEKOI]},
            {reference: "koi_balance_ve", methodName: "balanceOf", methodParameters: [CONTRACTS.VEKOI]},
          ],
        },
        {
          reference: "verewards",
          contractAddress: CONTRACTS.VE_REWARDS_2,
          abi: VE_REWARDS,
          calls: [{reference: "claimFor", methodName: "claimFor", methodParameters: [to, getVeRewardsRaw()]}],
        },
      ];

      contractCallContext[0].calls = contractCallContext[0].calls.concat(
        delegatedVes
          .map((id) => [{reference: "ve_delegate_" + id, methodName: "delegates", methodParameters: [id]}])
          .flat(),
      );

      const multiRes: ContractCallResults = await multicall.call(contractCallContext);
      var bal_vekoi = multiRes.results["koiallowance"].callsReturnContext[1].returnValues[0];
      var bal_user_ve_koi = multiRes.results["vekoi_info"].callsReturnContext[0].returnValues[0];

      var ve_supply_koi = multiRes.results["vekoi_info"].callsReturnContext[1].returnValues[0];

      var ve_underlying_bal_koi = multiRes.results["vekoi_info"].callsReturnContext[3].returnValues[0];

      var ve_nft_count_koi = new BigNumber(
        multiRes.results["vekoi_info"].callsReturnContext[4].returnValues[0],
      ).toNumber();

      var verewards: any[] = multiRes.results["verewards"].callsReturnContext[0].returnValues;
      const _veRewards = verewards
        .map((id) => [
          {token: getTokenFromContractPure(id[0]), amount: new BigNumber(id[1]).div(Math.pow(10, 18)).toFixed()},
        ])
        .flat();

      var delegatedIndexes: any[] = [];
      for (let i = 5; i < multiRes.results["vekoi_info"].callsReturnContext.length; i++) {
        const _addy = multiRes.results["vekoi_info"].callsReturnContext[i].returnValues[0];
        if (_addy.toLowerCase() == to.toLowerCase()) {
          delegatedIndexes.push(multiRes.results["vekoi_info"].callsReturnContext[i].methodParameters[0]);
        }
      }

      var allowance_ve = multiRes.results["koiallowance"].callsReturnContext[0].returnValues[0];

      var contractCallContextLocks: ContractCallContext[] = [
        {
          reference: "vekoi_info",
          contractAddress: CONTRACTS.VEKOI,
          abi: VEKOI,
          calls: [],
        },
      ];

      for (let _index = 0; _index < ve_nft_count_koi; _index++) {
        contractCallContextLocks[0].calls.push({
          reference: String(_index),
          methodName: "tokenOfOwnerByIndex",
          methodParameters: [to, _index],
        });
      }

      const multiResLocks: ContractCallResults = await multicall.call(contractCallContextLocks);

      var user_locks: any = [];
      var ve_locks: any = [];
      var vekoi_locks: any = [];

      if (multiResLocks.results["vekoi_info"]) {
        var contractCallContextVe: ContractCallContext[] = [
          {
            reference: "ve_info",
            contractAddress: CONTRACTS.VEKOI,
            abi: VEKOI,
            calls: [],
          },
          {
            reference: "ve_del",
            contractAddress: CONTRACTS.VEKOI,
            abi: VEKOI,
            calls: [],
          },
          {
            reference: "ve_meta",
            contractAddress: CONTRACTS.VEKOI,
            abi: VEKOI,
            calls: [],
          },
        ];

        for (let i = 0; i < multiResLocks.results["vekoi_info"].callsReturnContext.length; i++) {
          let _id = new BigNumber(multiResLocks.results["vekoi_info"].callsReturnContext[i].returnValues[0]).toString();
          contractCallContextVe[0].calls.push({
            reference: String(_id),
            methodName: "GetLockInfo",
            methodParameters: [_id],
          });
          contractCallContextVe[1].calls.push({
            reference: String(_id),
            methodName: "delegates",
            methodParameters: [_id],
          });

          contractCallContextVe[2].calls.push({
            reference: String(_id),
            methodName: "tokenURI",
            methodParameters: [_id],
          });
        }

        const multiResVE: ContractCallResults = await multicall.call(contractCallContextVe);
        for (let i = 0; i < multiResVE.results["ve_info"].callsReturnContext.length; i++) {
          vekoi_locks.push({
            index: multiResVE.results["ve_info"].originalContractCallContext.calls[i].reference,
            amount: new BigNumber(multiResVE.results["ve_info"].callsReturnContext[i].returnValues[0])
              .div(Math.pow(10, 18))
              .toString(),
            time: new BigNumber(multiResVE.results["ve_info"].callsReturnContext[i].returnValues[1]).toString(),
            vote_weight: new BigNumber(multiResVE.results["ve_info"].callsReturnContext[i].returnValues[2])
              .div(Math.pow(10, 18))
              .toString(),
            legacy: multiResVE.results["ve_info"].callsReturnContext[i].returnValues[3],
            delegate: multiResVE.results["ve_del"].callsReturnContext[i].returnValues[0],
            meta: multiResVE.results["ve_meta"].callsReturnContext[i].returnValues[0],
          });
        }
      }

      var koiTVL = new BigNumber(bal_vekoi).div(Math.pow(10, 18)).times(getKoiTokenPrice()).toFixed();

      let daoInfo = {
        success: true,
        tvl: koiTVL,
        total_locked_koi: new BigNumber(new BigNumber(bal_vekoi).div(Math.pow(10, 18))).toFixed(),
        vekoi_balance_user: new BigNumber(bal_user_ve_koi).div(Math.pow(10, 18)).toFixed(),
        vekoi_address: CONTRACTS.VEKOI,
        koi_address: CONTRACTS.KOI,
        user_locks,
        ve_locks,
        vekoi_locks,
        vekoi_underlying_bal: new BigNumber(ve_underlying_bal_koi).div(Math.pow(10, 18)).toFixed(),
        ve_supply_koi: new BigNumber(ve_supply_koi).div(Math.pow(10, 18)).toFixed(),
        ve_supply_koi_solo: new BigNumber(ve_supply_koi).div(Math.pow(10, 18)).toFixed(),
        shouldApprove: new BigNumber(allowance_ve).div(Math.pow(10, 18)).toFixed(),
        share: new BigNumber(bal_user_ve_koi).div(new BigNumber(ve_supply_koi)).times(100).toFixed(2),
        delegatedIndexes: delegatedIndexes,
        veRewards: _veRewards,
      };

      dispatch(walletSlice.actions.updateDaoInfo(daoInfo));
    } catch (e) {
      console.log(e);
      let daoInfo = {
        success: true,
        dmute_mute_balance: "0",
        tvl: "0",
        vekoi_address: CONTRACTS.VEKOI,
        koi_address: CONTRACTS.KOI,
        user_locks: [],
        ve_locks: [],
        shouldApprove: "0",
        share: "0",
        legacy_upgraded: false,
        vekoi_locks: []
      };
      dispatch(walletSlice.actions.updateDaoInfo(daoInfo));
    }
  };

  const getAmplifierV2Pools = () => {
    const amp_pools: any = [];
    let _allPairs = Utils.deepClone(store.getState().allPairs);

    for (let i in CONTRACTS.AMPLIFIER_V2) {
      var lp_apy = "0";
      var tvl = "0";
      var lp_price = "0";
      let token0 = getTokenFromContract(CONTRACTS.AMPLIFIER_V2[i].token0);
      let token1 = getTokenFromContract(CONTRACTS.AMPLIFIER_V2[i].token1);

      for (let k in _allPairs) {
        let pair = _allPairs[k];
        if (CONTRACTS.AMPLIFIER_V2[i].pair.toLowerCase() == pair.id.toLowerCase()) {
          lp_apy = pair.apy;
          tvl = pair.reserveUSD;
          lp_price = new BigNumber(pair.totalSupply).div(pair.reserveUSD).toFixed();
        }
      }
      amp_pools.push({
        ...CONTRACTS.AMPLIFIER_V2[i],
        lp_apy,
        token0,
        token1,
        tvl,
        lp_price,
        v2: true,
      });
    }

    return amp_pools;
  };

  const getAmplifierV2Multiplier = async (_id, _balance) => {
    const multicall = new Multicall({ethersProvider: provider, tryAggregate: true, network: {chainId, name: chain!.name}});
    const contractCallContext: ContractCallContext[] = [];
    const to = ethers.getAddress(getSignerAddress() as string);

    const _address = getSignerAddress();
    var _veIds: any = [];

    //@ts-ignore
    const ve_locks = store.getState().daoInfo.delegatedIndexes;
    const ve_koi_locks = store.getState().daoInfo.vekoi_locks;

    for (let i in ve_locks) {
      _veIds.push(ve_locks[i]);
    }

    for (let i in ve_koi_locks) {
      if (ve_koi_locks[i].delegate.toLowerCase() == to.toLowerCase()) _veIds.push(ve_koi_locks[i].index);
    }

    _veIds = _veIds.filter((item, index) => _veIds.indexOf(item) == index);

    _veIds = await filterUsedVeIds(_id, _veIds);

    contractCallContext.push({
      reference: "amp",
      contractAddress: _id,
      abi: AMPLIFIER_REDUX,
      calls: [
        {
          reference: "calculateMultiplier",
          methodName: "calculateMultiplier",
          methodParameters: [_address, _balance, _veIds],
        },
        {reference: "payoutFor", methodName: "payoutFor", methodParameters: [_address, _balance, _veIds]},
      ],
    });

    const multiRes: ContractCallResults = await multicall.call(contractCallContext);

    return {
      multiplier: new BigNumber(multiRes.results["amp"].callsReturnContext[0].returnValues[0])
        .div(Math.pow(10, 18))
        .toFixed(2),
      payout: new BigNumber(multiRes.results["amp"].callsReturnContext[1].returnValues[0])
        .div(Math.pow(10, 18))
        .toFixed(2),
    };
  };

  const synchronizeFarmsV2 = () => {
    let farms = Utils.deepClone(store.getState().amplifiersv2);
    let _allPairs = Utils.deepClone(store.getState().allPairs);

    for (let i in farms) {
      for (let k in _allPairs) {
        let pair = _allPairs[k];
        if (CONTRACTS.AMPLIFIER_V2[i].pair.toLowerCase() == pair.id.toLowerCase()) {
          farms[i].pair_apy = pair.apy;
          farms[i].lpAPY = pair.apy;
          farms[i].lp_apy = pair.apy;
          farms[i].totalSupply = new BigNumber(pair.totalSupply).toFixed();
          farms[i].reserve0 = new BigNumber(pair.reserve0).toFixed();
          farms[i].reserve1 = new BigNumber(pair.reserve1).toFixed();
          farms[i].lp_price = new BigNumber(pair.reserveUSD).div(pair.totalSupply).toFixed();

          farms[i].underlyingAssetA = new BigNumber(farms[i].totalUserStake)
            .div(farms[i].totalSupply)
            .times(farms[i].reserve0)
            .toFixed();
          farms[i].underlyingAssetB = new BigNumber(farms[i].totalUserStake)
            .div(farms[i].totalSupply)
            .times(farms[i].reserve1)
            .toFixed();
          farms[i].lpPrice = farms[i].lp_price;
          farms[i].tvl = new BigNumber(farms[i].lp_price).times(farms[i].totalDebt).div(Math.pow(10, 18)).toFixed();
          break;
        }
      }
    }

    dispatch(walletSlice.actions.updateAmplifiersV2(farms));
  };

  const synchronizeMerkl = () => {
    try{
      let v3 = Utils.deepClone(store.getState().merkleCampaigns).v3['324'];
      let _allPairs = Utils.deepClone(store.getState().allPairs);
      let _ownedPairs = Utils.deepClone(store.getState().ownedPairs);
      let _ownedPairsV3 = Utils.deepClone(store.getState().ownedV3Liquidity);

      //reset
      for (let k in _allPairs) {
        _allPairs[k].rewardTokens = []
        delete _allPairs[k].ampAPY
      }

      for (let i in v3) {
        let merkObj = v3[i]
        for(let o in merkObj){
          let merkleCampaign = merkObj[o]
          let incentivizedPool: string = merkleCampaign['campaignParameters']['poolAddress']
          let rewardToken = getTokenFromContract(merkleCampaign['rewardToken'])
          let apr: string = merkleCampaign['apr']
          let curTimestamp = new BigNumber(Date.now()).div(1000)
          let startTimestamp: string = merkleCampaign['startTimestamp']
          let endTimestamp: string = merkleCampaign['endTimestamp']

          
          if(curTimestamp.gte(startTimestamp) && curTimestamp.lte(endTimestamp)){
            for (let k in _allPairs) {
              if (_allPairs[k].id.toLowerCase() == incentivizedPool.toLowerCase()){
                _allPairs[k].ampAPY = new BigNumber(_allPairs[k].ampAPY ? _allPairs[k].ampAPY : 0).plus(apr).toFixed();
                if(rewardToken.symbol){
                  if(_allPairs[k].rewardTokens)
                    _allPairs[k].rewardTokens.push(rewardToken)
                  else
                    _allPairs[k].rewardTokens = [rewardToken]
                } else
                  _allPairs[k].rewardTokens = [getKoiToken()]
                }

                const ids = _allPairs[k].rewardTokens.map(({ address }) => address);
                const filtered = _allPairs[k].rewardTokens.filter(({ address }, index) => !ids.includes(address, index + 1))
                _allPairs[k].rewardTokens = filtered
            }
          }

        }
      }
  
      for (let k in _ownedPairs) {
        for (let id in _allPairs) {
          if (_allPairs[id].id.toLowerCase() == _ownedPairs[k].address.toLowerCase()) {
            _ownedPairs[k].apy = _allPairs[id].apy;
            _ownedPairs[k] = {..._allPairs[id], ..._ownedPairs[k]}
          }
        }
      }

      for (let k in _ownedPairsV3) {
        for (let id in _allPairs) {
          let _pool = v3PoolAddress(
            CONTRACTS.v3CoreFactoryAddress,
            CONTRACTS.INIT_CODE_HASH_v3,
            _ownedPairsV3[k].token0,
            _ownedPairsV3[k].token1,
            _ownedPairsV3[k].fee,
          )
          if (_allPairs[id].id.toLowerCase() == _pool.toLowerCase()) {
            _ownedPairsV3[k].apy = _allPairs[id].apy;
            _ownedPairsV3[k].totalValueLockedUSD = _allPairs[id].totalValueLockedUSD;
            _ownedPairsV3[k].totalValueLockedToken0 = _allPairs[id].totalValueLockedToken0;
            _ownedPairsV3[k].totalValueLockedToken1 = _allPairs[id].totalValueLockedToken1;
            _ownedPairsV3[k].token1Price = _allPairs[id].token1Price;
            _ownedPairsV3[k].token0Price = _allPairs[id].token0Price;
            //_ownedPairsV3[k] = {..._allPairs[id], ..._ownedPairsV3[k]}
          }
        }
      }  
  
      dispatch(walletSlice.actions.updateAllPairs(_allPairs));
      dispatch(walletSlice.actions.updateOwnedPairs(_ownedPairs));
      //dispatch(walletSlice.actions.updateOwnedLiquidityV3(_ownedPairsV3));
    } catch(e){
      console.log(e)
    }
  };
  

  const getAmplifierV2Info = async () => {
    try {
      const to = getSignerAddress() ? getSignerAddress() : ethers.ZeroAddress
      const multicall = new Multicall({ethersProvider: provider, tryAggregate: true, network: {chainId, name: "zkSync Era"}});

      var contractCallContext: ContractCallContext[] = [];

      const _address = to;
      const _veIds: any = [];

      var amplifiers: any[] = [];
      let _allPairs = Utils.deepClone(store.getState().allPairs);

      for (let i in CONTRACTS.AMPLIFIER_V2) {
        contractCallContext.push({
          reference: "oracle" + String(i),
          contractAddress: CONTRACTS.AMPLIFIER_V2[i].oracle,
          abi: MUTE_ORACLE,
          calls: [
            {reference: "getCurrentMutePrice", methodName: "getCurrentMutePrice", methodParameters: []},
            {
              reference: "getPayoutTokenPrice",
              methodName: "getTokenPrice",
              methodParameters: [CONTRACTS.AMPLIFIER_V2[i].payoutToken, CONTRACTS.AMPLIFIER_V2[i].pair],
            },
          ],
        });

        contractCallContext.push({
          reference: "amp" + String(i),
          contractAddress: CONTRACTS.AMPLIFIER_V2[i].id,
          abi: AMPLIFIER_REDUX,
          calls: [
            {reference: "redeemRewardsView", methodName: "redeemRewardsView", methodParameters: [_address]},
            {reference: "GetUserStakes", methodName: "GetUserStakes", methodParameters: [_address]},
            {reference: "GetUsedVeInfo", methodName: "GetUsedVeInfo", methodParameters: [_veIds]},
            {reference: "management_fee", methodName: "management_fee", methodParameters: []},
            {reference: "percentageMulti", methodName: "percentageMulti", methodParameters: []},
            {reference: "totalDebt", methodName: "totalDebt", methodParameters: []},
            {reference: "totalPayoutGiven", methodName: "totalPayoutGiven", methodParameters: []},
            {reference: "maxDeposit", methodName: "maxDeposit", methodParameters: []},
          ],
        });

        contractCallContext.push({
          reference: "allowance" + String(i),
          contractAddress: CONTRACTS.AMPLIFIER_V2[i].pair,
          abi: PAIR,
          calls: [
            {reference: "myLP", methodName: "balanceOf", methodParameters: [to]},
            {reference: "allowance", methodName: "allowance", methodParameters: [to, CONTRACTS.AMPLIFIER_V2[i].id]},
            {reference: "index0", methodName: "index0", methodParameters: []},
            {reference: "index1", methodName: "index1", methodParameters: []},
            {reference: "balanceOf", methodName: "balanceOf", methodParameters: [CONTRACTS.AMPLIFIER_V2[i].id]},
          ],
        });

        contractCallContext.push({
          reference: "payout" + String(i),
          contractAddress: CONTRACTS.AMPLIFIER_V2[i].treasury,
          abi: AMPLIFIER_TREASURY,
          calls: [{reference: "payout", methodName: "payoutToken", methodParameters: []}],
        });
      }

      const multiResOracle: ContractCallResults = await multicall.call(contractCallContext);

      for (let i in CONTRACTS.AMPLIFIER_V2) {
        let amp_info = {
          ...Utils.deepClone(CONTRACTS.AMPLIFIER_V2[i]),
          pair_apy: "0.00",
          totalSupply: "0.00",
          reserve0: "0.00",
          reserve1: "0.00",
          lp_price: "0.00",
          tvl: "0",
        };

        /*
        for (let k in _allPairs) {
          let pair = _allPairs[k];
          if (CONTRACTS.AMPLIFIER_V2[i].pair.toLowerCase() == pair.id.toLowerCase()) {
            amp_info.pair_apy = pair.apy;
            amp_info.totalSupply = new BigNumber(pair.totalSupply).toFixed();
            amp_info.reserve0 = new BigNumber(pair.reserve0).toFixed();
            amp_info.reserve1 = new BigNumber(pair.reserve1).toFixed();
            amp_info.lp_price = new BigNumber(pair.reserveUSD).div(pair.totalSupply).toFixed();
            break;
          }
        }
        */

        /*
        var getCurrentEthPrice = multiResOracle.results['oracle'].callsReturnContext[0].returnValues[0]
        var getCurrentMutePrice = multiResOracle.results['oracle'].callsReturnContext[1].returnValues[0]
        var getTokenPriceA = multiResOracle.results['oracle'].callsReturnContext[2].returnValues[0]
        var getTokenPriceB = multiResOracle.results['oracle'].callsReturnContext[3].returnValues[0]
        var getLPValue = new BigNumber(multiResOracle.results['oracle'].callsReturnContext[4].returnValues[0]).div(Math.pow(10, 18)).toFixed(4)
        var veRatioToLPHoldings = multiResOracle.results['oracle'].callsReturnContext[5].returnValues[0]
        */

        var getCurrentMutePrice = multiResOracle.results["oracle" + String(i)].callsReturnContext[0].returnValues[0];
        var getPayoutTokenPrice = multiResOracle.results["oracle" + String(i)].callsReturnContext[1].returnValues[0];

        var redeemRewardsView = multiResOracle.results["amp" + String(i)].callsReturnContext[0].returnValues[0];
        var GetUserStakes = multiResOracle.results["amp" + String(i)].callsReturnContext[1].returnValues;
        var GetUsedVeInfo = multiResOracle.results["amp" + String(i)].callsReturnContext[2].returnValues[0];
        var management_fee = multiResOracle.results["amp" + String(i)].callsReturnContext[3].returnValues[0];
        var percentageMulti = multiResOracle.results["amp" + String(i)].callsReturnContext[4].returnValues[0];
        var totalDebt = multiResOracle.results["amp" + String(i)].callsReturnContext[5].returnValues[0];
        var totalPayoutGiven = multiResOracle.results["amp" + String(i)].callsReturnContext[6].returnValues[0];
        var maxDeposit = multiResOracle.results["amp" + String(i)].callsReturnContext[7].returnValues[0];

        var lp_bal = multiResOracle.results["allowance" + String(i)].callsReturnContext[0].returnValues[0];
        var allowance = multiResOracle.results["allowance" + String(i)].callsReturnContext[1].returnValues[0];
        var index0 = new BigNumber(
          multiResOracle.results["allowance" + String(i)].callsReturnContext[2].returnValues[0],
        );
        var index1 = new BigNumber(
          multiResOracle.results["allowance" + String(i)].callsReturnContext[3].returnValues[0],
        );

        var payoutToken = multiResOracle.results["payout" + String(i)].callsReturnContext[0].returnValues[0];

        amp_info.tvl = new BigNumber(amp_info.lp_price).times(totalDebt).div(Math.pow(10, 18)).toFixed();

        var totalUserStake = new BigNumber(0);

        let index = 0;
        let user_stakes: any[] = [];
        let fee0 = new BigNumber(0);
        let fee1 = new BigNumber(0);

        for (let i in GetUserStakes) {
          const stake = {
            stakeAmount: new BigNumber(GetUserStakes[i][0]).div(Math.pow(10, 18)).toFixed(),
            stakedTime: new BigNumber(GetUserStakes[i][1]).toFixed(),
            rewardAmount: new BigNumber(GetUserStakes[i][2]).div(Math.pow(10, 18)).toFixed(),
            rewardClaimed: new BigNumber(GetUserStakes[i][3]).div(Math.pow(10, 18)).toFixed(),
            epochDuration: new BigNumber(GetUserStakes[i][4]).toFixed(),
            lastRedeem: new BigNumber(GetUserStakes[i][5]).toFixed(),
            supplyIndex0: new BigNumber(GetUserStakes[i][6]).toFixed(),
            supplyIndex1: new BigNumber(GetUserStakes[i][7]).toFixed(),
          };

          if (index0.minus(stake.supplyIndex0).gt(0)) {
            fee0 = fee0.plus(new BigNumber(stake.stakeAmount).times(index0.minus(stake.supplyIndex0)));
          }

          if (index1.minus(stake.supplyIndex1).gt(0)) {
            fee1 = fee1.plus(new BigNumber(stake.stakeAmount).times(index1.minus(stake.supplyIndex1)));
          }

          user_stakes.push(stake);

          totalUserStake = totalUserStake.plus(stake.stakeAmount);

          index++;
        }
        var localPayout = getTokenFromContract(payoutToken);
        var amplifier = {
          ...amp_info,
          success: true,
          v2: true,
          id: amp_info.id,
          pair: amp_info.pair,
          token0: getTokenFromContract(amp_info.token0),
          token1: getTokenFromContract(amp_info.token1),
          stable: amp_info.stable,
          multiplier: new BigNumber(amp_info.ampBoost).toFixed(),
          user_stakes: user_stakes,
          // bps points additional on base APY
          // example min apy = 5, max apy = 10, multi = 0.06 -> 5.3%
          baseAPY: new BigNumber(amp_info.apy).div(100).toFixed(2),
          maxAPY: new BigNumber(amp_info.apy).div(100).times(amp_info.ampBoost).toFixed(2),
          ampBoost: amp_info.ampBoost,

          maxDeposit: new BigNumber(maxDeposit).div(Math.pow(10, 18)).toFixed(),
          tbv: new BigNumber(maxDeposit)
            .div(Math.pow(10, 18))
            .times(getPayoutTokenPrice)
            .div(Math.pow(10, 18))
            .toFixed(),
          maxTBV: new BigNumber(CONTRACTS.AMPLIFIER_V2[i].maxRewards)
            .times(getPayoutTokenPrice)
            .div(Math.pow(10, 18))
            .toFixed(),

          totalStaked: new BigNumber(totalDebt).div(Math.pow(10, 18)).toFixed(),
          totalClaimedRewards: new BigNumber(totalPayoutGiven).div(Math.pow(10, 18)).toFixed(),

          currentClaimable: new BigNumber(redeemRewardsView).div(Math.pow(10, 18)).toFixed(),
          currentClaimableFee0: new BigNumber(fee0).toFixed(),
          currentClaimableFee1: new BigNumber(fee1).toFixed(),

          totalUserStake: new BigNumber(totalUserStake).toFixed(),
          //requires lp info
          underlyingAssetA: new BigNumber(totalUserStake).div(amp_info.totalSupply).times(amp_info.reserve0).toFixed(),
          underlyingAssetB: new BigNumber(totalUserStake).div(amp_info.totalSupply).times(amp_info.reserve1).toFixed(),
          lpPrice: amp_info.lp_price,
          tvl: new BigNumber(amp_info.tvl).toFixed(),
          lpAPY: amp_info.pair_apy,
          lp_apy: amp_info.pair_apy,
          //

          managementFee: new BigNumber(amp_info.fee).toFixed(),

          approved: new BigNumber(allowance).div(Math.pow(10, 18)).toFixed(),
          balance: new BigNumber(lp_bal).div(Math.pow(10, 18)).toFixed(),
          divisor: new BigNumber(percentageMulti).div(Math.pow(10, 18)).toFixed(2),
          payoutToken: localPayout,
        };

        amplifiers.push(amplifier);
      }

      dispatch(walletSlice.actions.updateAmplifiersV2(amplifiers));
      return amplifiers;
    } catch (e) {
      console.log(e);
      return {
        success: false,
      };
    }
  };

  const getAmplifierPools = async () => {
    try {
      const amplifier = CONTRACTS.AMPLIFIER;

      const to = ethers.getAddress(getSignerAddress() as string);

      const multicall = new Multicall({ethersProvider: provider, tryAggregate: true, network: {chainId, name: chain!.name}});

      const contractCallContext: ContractCallContext[] = [];
      const amInfo: any = {};

      for (let i in amplifier) {
        amInfo[amplifier[i].id] = amplifier[i];

        contractCallContext.push({
          reference: amplifier[i].id,
          contractAddress: amplifier[i].id,
          abi: AMPLIFIER,
          calls: [
            {reference: "totalStakers", methodName: "totalStakers", methodParameters: []},
            {reference: "totalRewards", methodName: "totalRewards", methodParameters: []},
            {reference: "totalReclaimed", methodName: "totalReclaimed", methodParameters: []},
            {reference: "totalClaimedRewards", methodName: "totalClaimedRewards", methodParameters: []},
            {reference: "startTime", methodName: "startTime", methodParameters: []},
            {reference: "firstStakeTime", methodName: "firstStakeTime", methodParameters: []},
            {reference: "endTime", methodName: "endTime", methodParameters: []},
            {reference: "dripInfo", methodName: "dripInfo", methodParameters: [to]},
            {reference: "management_fee", methodName: "management_fee", methodParameters: []},

            {reference: "totalStake", methodName: "totalStake", methodParameters: []},
            {reference: "totalUserStake", methodName: "totalUserStake", methodParameters: [to]},
            {reference: "calculateMultiplier", methodName: "calculateMultiplier", methodParameters: [to, true]},
            {reference: "_percentageMin", methodName: "_percentageMin", methodParameters: []},
            {reference: "userStakedBlock", methodName: "userStakedBlock", methodParameters: [to]},
            {reference: "payout", methodName: "payout", methodParameters: []},
          ],
        });

        contractCallContext.push({
          reference: "token_" + amplifier[i].id,
          contractAddress: amplifier[i].pair,
          abi: ERC20,
          calls: [
            {reference: "myLP", methodName: "balanceOf", methodParameters: [to]},
            {reference: "allowance", methodName: "allowance", methodParameters: [to, amplifier[i].id]},
          ],
        });
      }

      const multiRes: ContractCallResults = await multicall.call(contractCallContext);
      var amplifiers: any[] = [];
      for (let i in multiRes.results) {
        if (multiRes.results[i].callsReturnContext[0].success == false || i.includes("token_")) continue;

        let amp_info = amInfo[i];
        var totalStakers = multiRes.results[i].callsReturnContext[0].returnValues[0];
        var totalRewards = multiRes.results[i].callsReturnContext[1].returnValues[0];
        var totalReclaimed = multiRes.results[i].callsReturnContext[2].returnValues[0];
        var totalClaimedRewards = multiRes.results[i].callsReturnContext[3].returnValues[0];
        var startTime = multiRes.results[i].callsReturnContext[4].returnValues[0];
        var firstStakeTime = multiRes.results[i].callsReturnContext[5].returnValues[0];
        var endTime = multiRes.results[i].callsReturnContext[6].returnValues[0];
        var dripInfo = multiRes.results[i].callsReturnContext[7];
        var management_fee = multiRes.results[i].callsReturnContext[8].returnValues[0];
        var totalStaked = multiRes.results[i].callsReturnContext[9].returnValues[0];
        var totalUserStake = multiRes.results[i].callsReturnContext[10].returnValues[0];
        var calculateMultiplier = multiRes.results[i].callsReturnContext[11].returnValues[0];
        var _stakeDivisor = new BigNumber(multiRes.results[i].callsReturnContext[12].returnValues[0]);

        var myLP = multiRes.results["token_" + String(i)].callsReturnContext[0].returnValues[0];
        var allowance = multiRes.results["token_" + String(i)].callsReturnContext[1].returnValues[0];

        //perSecondReward / totalLP / multi_current / multi_last / currentRewards
        var perSecondReward = new BigNumber(totalRewards).div(
          new BigNumber(endTime).minus(new BigNumber(firstStakeTime).eq(0) ? startTime : firstStakeTime),
        );

        let _allPairs = Utils.deepClone(store.getState().allPairs);

        let lp_apy = "0";
        let tvl = "0";
        let lp_totsup = "0";
        let reserve0 = "0";
        let reserve1 = "0";

        for (let k in _allPairs) {
          if (amp_info.pair.toLowerCase() == _allPairs[k].id.toLowerCase()) {
            lp_apy = _allPairs[k].apy;
            tvl = new BigNumber(_allPairs[k].reserveUSD)
              .times(new BigNumber(totalStaked).div(Math.pow(10, 18)).div(_allPairs[k].totalSupply))
              .toFixed();
            lp_totsup = _allPairs[k].totalSupply;
            reserve0 = _allPairs[k].reserve0;
            reserve1 = _allPairs[k].reserve1;
          }
        }

        var amp_apy = perSecondReward
          .times(60 * 60 * 24 * 365)
          .times(0)
          .div(Math.pow(10, 18))
          .div(tvl)
          .times(100)
          .toFixed(2);

        if (amp_apy == "Infinity") amp_apy = "10000";

        // amp is expired, show 0 apy
        if (new BigNumber(endTime).lt(Date.now() / 1000)) {
          amp_apy = "0.00";

          // show lp apy
          //lp_apy = '0.00'
        }

        amplifiers.push({
          success: true,
          id: amp_info.id,
          pair: amp_info.pair,
          token0: getTokenFromContract(amp_info.token0),
          token1: getTokenFromContract(amp_info.token1),
          totalStakers: new BigNumber(totalStakers).toFixed(),
          totalRewards: new BigNumber(totalRewards).div(Math.pow(10, 18)).toFixed(),
          totalStaked: new BigNumber(totalStaked).div(Math.pow(10, 18)).toFixed(),
          totalReclaimed: new BigNumber(totalReclaimed).div(Math.pow(10, 18)).toFixed(),
          totalClaimedRewards: new BigNumber(totalClaimedRewards).div(Math.pow(10, 18)).toFixed(),
          currentClaimable: new BigNumber(dripInfo.success == true ? dripInfo.returnValues[4] : 0)
            .div(Math.pow(10, 18))
            .toFixed(),
          currentClaimableFee0: new BigNumber(dripInfo.success == true ? dripInfo.returnValues[5] : 0).toFixed(),
          currentClaimableFee1: new BigNumber(dripInfo.success == true ? dripInfo.returnValues[6] : 0).toFixed(),
          // bps points additional on base APY
          // example min apy = 5, max apy = 10, multi = 0.06 -> 5.3%
          multiplier: new BigNumber(calculateMultiplier).div(_stakeDivisor).toFixed(),
          maxAPY: new BigNumber(amp_apy).toFixed(2),
          baseAPY: new BigNumber(amp_apy)
            .times(_stakeDivisor)
            .div(new BigNumber(Math.pow(10, 18)))
            .toFixed(2),
          lpAPY: lp_apy,
          lpPrice: new BigNumber(tvl).div(lp_totsup).toFixed(),
          totalUserStake: new BigNumber(totalUserStake).div(Math.pow(10, 18)).toFixed(),
          underlyingAssetA: new BigNumber(new BigNumber(totalUserStake).div(Math.pow(10, 18)))
            .div(lp_totsup)
            .times(reserve0)
            .toFixed(),
          underlyingAssetB: new BigNumber(new BigNumber(totalUserStake).div(Math.pow(10, 18)))
            .div(lp_totsup)
            .times(reserve1)
            .toFixed(),
          startTime: new BigNumber(startTime).toFixed(),
          firstStakeTime: new BigNumber(firstStakeTime).toFixed(),
          managementFee: new BigNumber(management_fee).toFixed(),
          endTime: new BigNumber(endTime).toFixed(),
          approved: new BigNumber(allowance).div(Math.pow(10, 18)).toFixed(),
          balance: new BigNumber(myLP).div(Math.pow(10, 18)).toFixed(),
          divisor: new BigNumber(Math.pow(10, 18)).div(_stakeDivisor).toFixed(2),
          tvl: new BigNumber(tvl).toFixed(),
          stable: amp_info.stable,
        });
      }

      dispatch(walletSlice.actions.updateAmplifiers(amplifiers));

      return amplifiers;
    } catch (e) {
      console.log(e);
      return {
        success: false,
      };
    }
  };

  const filterUsedVeIds = async (ampAddress, ids) => {
    const multicall = new Multicall({ethersProvider: provider, tryAggregate: true, network: {chainId, name: chain!.name}});

    const contractCallContext: ContractCallContext[] = [];
    const amInfo: any = {};

    if (ids.length == 0) return [];

    for (let i in ids) {
      contractCallContext.push({
        reference: ids[i],
        contractAddress: ampAddress,
        abi: AMPLIFIER_REDUX,
        calls: [{reference: "usedVeIds_" + String(ids[i]), methodName: "usedVeIds", methodParameters: [ids[i]]}],
      });
    }

    const multiRes: ContractCallResults = await multicall.call(contractCallContext);
    var validIds: any[] = [];
    for (let i in multiRes.results) {
      const expiry_time = new BigNumber(multiRes.results[i].callsReturnContext[0].returnValues[0]);
      if (new BigNumber(Date.now() / 1000).gt(expiry_time)) validIds.push(i.replace("usedVeIds_", ""));
    }

    return validIds;
  };

  const stakeInAmplifier = async (id, amount, pair, v2) => {
    try {
      const zksigner = await getZkSigner();
      var _provider = new Provider(getNodeUrl());
      const to = ethers.getAddress(getSignerAddress() as string);

      if ((await checkAllowance(pair, id, new BigNumber(amount).times(Math.pow(10, 18)).toFixed())) == false)
        await approveTokenAmount(pair, id);

      var amplifierContract = new Contract(id, v2 ? AMPLIFIER_REDUX : AMPLIFIER, zksigner!);

      let gasless: any;
      if (v2) {
        dispatch(walletSlice.actions.updaetTransactionModalStatus("Confirm deposit transaction"));

        var _veIds: any = [];

        //@ts-ignore
        const ve_locks = store.getState().daoInfo.delegatedIndexes;
        const ve_koi_locks = store.getState().daoInfo.vekoi_locks;

        for (let i in ve_locks) {
          _veIds.push(ve_locks[i]);
        }

        for (let i in ve_koi_locks) {
          if (ve_koi_locks[i].delegate.toLowerCase() == to.toLowerCase()) _veIds.push(ve_koi_locks[i].index);
        }

        _veIds = _veIds.filter((item, index) => _veIds.indexOf(item) == index);

        const filteredIds = (await filterUsedVeIds(id, _veIds)).slice(0, 14);

        const receipts = await handleTransaction(amplifierContract.deposit, zksigner, [
          new BigNumber(amount).times(Math.pow(10, 18)).toFixed(0),
          filteredIds,
        ]);

        gasless = receipts.gasless;
        const txResults = await receipts.waitAndResult();
        console.log(receipts, txResults);
      } else {
        const txHandle = await amplifierContract.stake(new BigNumber(amount).times(Math.pow(10, 18)).toFixed(0), {});

        await txHandle.wait();
      }

      await searchForLiquidityWithAddressAndUpdate(pair);

      await getAmplifierV2Info();
      synchronizeFarmsV2();
      synchronizeMerkl()

      return {
        success: true,
        gasless,
      };
    } catch (e) {
      console.log(e);
      return {
        success: false,
      };
    }
  };

  const withdrawFromAmplifier = async (id) => {
    try {
      const zksigner = await getZkSigner();
      var _provider = new Provider(getNodeUrl());

      var amplifierContract = new Contract(id, AMPLIFIER, zksigner!);

      const receipts = await handleTransaction(amplifierContract.withdraw, zksigner);

      const txResults = await receipts.waitAndResult();

      return {
        success: true,
        gasless: receipts.gasless,
      };
    } catch (e) {
      console.log(e);
      return {
        success: false,
      };
    }
  };

  const payoutFromAmplifier = async (id) => {
    try {
      const zksigner = await getZkSigner();

      var amplifierContract = new Contract(id, AMPLIFIER, zksigner!);
      const txHandle = await amplifierContract.payout({});

      await txHandle.wait();

      return {
        success: true,
      };
    } catch (e) {
      console.log(e);
      return {
        success: false,
      };
    }
  };

  const payoutFromAmplifierV2 = async (id) => {
    try {
      const zksigner = await getZkSigner();
      var _provider = new Provider(getNodeUrl());

      var amplifierContract = new Contract(id, AMPLIFIER_REDUX, zksigner!);

      const receipts = await handleTransaction(amplifierContract.redeemRewards, zksigner);

      const txResults = await receipts.waitAndResult();

      return {
        success: true,
        gasless: receipts.gasless,
      };
    } catch (e) {
      console.log(e);
      return {
        success: false,
      };
    }
  };

  const getTransactionResults = (paymasterParams: any = null, receipt: any = null, startTime: any = null) => {
    if (paymasterParams == null) return {gasSavedUSD: "", gasSpentUSD: "", time: "0.00", tx: ""};

    var endTime = Date.now();

    var gasUsed = receipt.gasUsed.toString();
    var gasEstimated = paymasterParams.gasLimit.toString();
    let gasEstimatedUSD = new BigNumber(gasEstimated)
      .times(paymasterParams.gasPrice.toString())
      .div(Math.pow(10, 18))
      .times(store.getState().ethPrice);
    let gasSpentUSD = new BigNumber(gasUsed)
      .times(paymasterParams.gasPrice.toString())
      .div(Math.pow(10, 18))
      .times(store.getState().ethPrice);

    return {
      gasSaved: gasEstimatedUSD.minus(gasSpentUSD).toFixed(2),
      gasSavedUSD: gasEstimatedUSD.minus(gasSpentUSD).toFixed(2),
      gasSpentUSD: gasSpentUSD.toFixed(2),
      time: new BigNumber(endTime).minus(startTime).div(1000).toFixed(2),
      tx: receipt.hash,
      logs: receipt.logs,
    };
  };

  const claimPoolFees = async (pair) => {
    try {
      const zksigner = await getZkSigner();
      var pool = new Contract(pair, PAIR, zksigner!);

      const receipts = await handleTransaction(pool.claimFees, zksigner);

      const txResults = await receipts.waitAndResult();

      return {success: true, error: "", ...txResults};
    } catch (e) {
      console.log(e);
      let txResults = getTransactionResults();

      return {
        success: false,
        error: JSON.stringify(e),
        ...txResults,
      };
    }
  };

  const claimPositionFees = async (id, token1, token2) => {
    try {
      const to = ethers.getAddress(getSignerAddress() as string);

      const zksigner = await getZkSigner();

      const NonFungContract = new Contract(
        CONTRACTS.nonfungibleTokenPositionManagerAddress,
        NonfungiblePositionManager.abi,
        zksigner,
      );

      const MAX_UINT128 = new BigNumber(2).pow(128).minus(1).toFixed();

      // we claim to the positon manager so we can sweep balances and unwrap weth
      const CollectParams = {
        tokenId: id,
        recipient: CONTRACTS.nonfungibleTokenPositionManagerAddress,
        amount0Max: MAX_UINT128,
        amount1Max: MAX_UINT128,
      };

      const collect_ABI = await NonFungContract.collect.populateTransaction(CollectParams);
      const unwrap_ETH_ABI = await NonFungContract.unwrapWETH9.populateTransaction(0, to);
      const sweep_token1_ABI = await NonFungContract.sweepToken.populateTransaction(wethOrToken(token1.address), 0, to);
      const sweep_token2_ABI = await NonFungContract.sweepToken.populateTransaction(wethOrToken(token2.address), 0, to);

      const calls = [collect_ABI.data, unwrap_ETH_ABI.data, sweep_token1_ABI.data, sweep_token2_ABI.data];

      if(store.getState().merkleRewards['tokens'].length > 0){
        dispatch(walletSlice.actions.updaetTransactionModalStatus("Confirm transaction in your wallet (1/2)"));
        await collectMerkleRewards()
      }

      dispatch(walletSlice.actions.updaetTransactionModalStatus("Confirm transaction in your wallet"));
      const receipts = await handleTransaction(NonFungContract.multicall, zksigner, [calls]);

      const txResults = await receipts.waitAndResult();
      return {
        ...txResults,
        success: true,
        gasless: receipts.gasless,
      };

    } catch (e) {
      console.log(e);
      let txResults = getTransactionResults();
      return {
        amountA: null,
        amountB: null,
        liquidity: null,
        share: null,
        success: false,
        error: JSON.stringify(e),
        ...txResults,
      };
    }
  };

  /*
  const getBondInfo = async () => {
    try {
      const to = ethers.getAddress(getSignerAddress() as string);

      const multicall = new Multicall({ethersProvider: provider, tryAggregate: true, network: {chainId, name: chain!.name}});
      const contractCallContext: ContractCallContext[] = [
        {
          reference: "bondInfo",
          contractAddress: CONTRACTS.BOND.bond,
          abi: BOND,
          calls: [{reference: "bondInfo", methodName: "bondInfo", methodParameters: []}],
        },
        {
          reference: "epochStart",
          contractAddress: CONTRACTS.BOND.bond,
          abi: BOND,
          calls: [{reference: "epochStart", methodName: "epochStart", methodParameters: []}],
        },
        {
          reference: "maxPayout",
          contractAddress: CONTRACTS.BOND.bond,
          abi: BOND,
          calls: [{reference: "maxPayout", methodName: "maxPayout", methodParameters: []}],
        },
        {
          reference: "startPrice",
          contractAddress: CONTRACTS.BOND.bond,
          abi: BOND,
          calls: [{reference: "startPrice", methodName: "startPrice", methodParameters: []}],
        },
        {
          reference: "maxPrice",
          contractAddress: CONTRACTS.BOND.bond,
          abi: BOND,
          calls: [{reference: "maxPrice", methodName: "maxPrice", methodParameters: []}],
        },
        {
          reference: "maxDeposit",
          contractAddress: CONTRACTS.BOND.bond,
          abi: BOND,
          calls: [{reference: "maxDeposit", methodName: "maxDeposit", methodParameters: []}],
        },
        {
          reference: "totalDebt",
          contractAddress: CONTRACTS.BOND.bond,
          abi: BOND,
          calls: [{reference: "totalDebt", methodName: "totalDebt", methodParameters: []}],
        },
        {
          reference: "totalPayoutGiven",
          contractAddress: CONTRACTS.BOND.bond,
          abi: BOND,
          calls: [{reference: "totalPayoutGiven", methodName: "totalPayoutGiven", methodParameters: []}],
        },
        {
          reference: "currentEpoch",
          contractAddress: CONTRACTS.BOND.bond,
          abi: BOND,
          calls: [{reference: "currentEpoch", methodName: "currentEpoch", methodParameters: []}],
        },
        {
          reference: "balanceOf",
          contractAddress: CONTRACTS.BOND.lp,
          abi: ERC20,
          calls: [{reference: "balanceOf", methodName: "balanceOf", methodParameters: [to]}],
        },
        {
          reference: "balanceOfTreasury",
          contractAddress: CONTRACTS.BOND.lp,
          abi: ERC20,
          calls: [
            {reference: "balanceOfTreasury", methodName: "balanceOf", methodParameters: [CONTRACTS.BOND.treasury]},
          ],
        },
        {
          reference: "balanceOfTreasuryMute",
          contractAddress: CONTRACTS.MUTE,
          abi: ERC20,
          calls: [
            {reference: "balanceOfTreasuryMute", methodName: "balanceOf", methodParameters: [CONTRACTS.BOND.treasury]},
          ],
        },
        {
          reference: "metadata",
          contractAddress: CONTRACTS.BOND.lp,
          abi: PAIR,
          calls: [{reference: "metadata", methodName: "metadata", methodParameters: []}],
        },
        {
          reference: "totalSupply",
          contractAddress: CONTRACTS.BOND.lp,
          abi: PAIR,
          calls: [{reference: "totalSupply", methodName: "totalSupply", methodParameters: []}],
        },
        {
          reference: "allowance",
          contractAddress: CONTRACTS.BOND.lp,
          abi: PAIR,
          calls: [{reference: "allowance", methodName: "allowance", methodParameters: [to, CONTRACTS.BOND.bond]}],
        },
      ];

      const multiRes: ContractCallResults = await multicall.call(contractCallContext);

      var balance = multiRes.results["balanceOf"].callsReturnContext[0].returnValues[0];
      var balance_t = multiRes.results["balanceOfTreasury"].callsReturnContext[0].returnValues[0];
      var balance_mute = multiRes.results["balanceOfTreasuryMute"].callsReturnContext[0].returnValues[0];

      var metadata = multiRes.results["metadata"].callsReturnContext[0].returnValues;
      var supply = multiRes.results["totalSupply"].callsReturnContext[0].returnValues[0];
      var allowance = multiRes.results["allowance"].callsReturnContext[0].returnValues[0];
      var epoch = multiRes.results["currentEpoch"].callsReturnContext[0].returnValues[0];

      var epochStart = multiRes.results["epochStart"].callsReturnContext[0].returnValues[0];
      var maxDeposit = multiRes.results["maxDeposit"].callsReturnContext[0].returnValues[0];
      var totalDebt = multiRes.results["totalDebt"].callsReturnContext[0].returnValues[0];
      var totalPayoutGiven = multiRes.results["totalPayoutGiven"].callsReturnContext[0].returnValues[0];
      var maxPayout = multiRes.results["maxPayout"].callsReturnContext[0].returnValues[0];
      var startPrice = multiRes.results["startPrice"].callsReturnContext[0].returnValues[0];
      var maxPrice = multiRes.results["maxPrice"].callsReturnContext[0].returnValues[0];

      var info = multiRes.results["bondInfo"].callsReturnContext[0].returnValues;

      //function metadata() external view returns (uint dec0, uint dec1, uint r0, uint r1, bool st, address t0, address t1) {
      var mute_reserves = new BigNumber(0);
      if (metadata[5] == CONTRACTS.gas.wrapped_address) {
        mute_reserves = new BigNumber(metadata[3]);
      } else {
        mute_reserves = new BigNumber(metadata[2]);
      }

      var lp_per_mute = new BigNumber(mute_reserves).div(supply).times(2);
      var max_purchase = new BigNumber(0);
      var price = new BigNumber(startPrice).div(Math.pow(10, 18)).toFixed();

      if (info.length != 0) {
        max_purchase = info[4];
        price = new BigNumber(info[2]).div(Math.pow(10, 18)).toFixed();
      }

      var apy = new BigNumber(price).div(lp_per_mute);
      var apy_text = "";
      if (apy.lt(1)) apy_text = "-" + new BigNumber(1).minus(apy).times(100).toFixed(2);
      else apy_text = new BigNumber(apy).minus(1).times(100).toFixed(2);

      let _bondInfo: any = [
        {
          token0: getTokenFromContract(CONTRACTS.MUTE),
          token1: getTokenFromContract(CONTRACTS.gas.address),
          id: CONTRACTS.BOND.bond,
          pool: CONTRACTS.BOND.lp,
          maxDeposit: new BigNumber(maxDeposit).div(Math.pow(10, 18)).toFixed(),
          maxPurchase: new BigNumber(max_purchase).div(Math.pow(10, 18)).toFixed(),
          maxPayout: new BigNumber(maxPayout).div(Math.pow(10, 18)).toFixed(),
          price: price,
          lp_per_mute: lp_per_mute.toFixed(2),
          epoch: new BigNumber(epoch).plus(1).toFixed(),
          started: new BigNumber(epochStart).lte(Date.now() / 1000),
          startTime: new BigNumber(epochStart).toNumber(),
          totalDebt: new BigNumber(totalDebt).div(Math.pow(10, 18)).plus(25000).toFixed(),
          totalPayout: new BigNumber(totalPayoutGiven).div(Math.pow(10, 18)).toFixed(),
          balance: new BigNumber(balance).div(Math.pow(10, 18)).toFixed(),
          apy: apy_text,
          approve: new BigNumber(allowance).lte(0),
          totalBonded: lp_per_mute.times(new BigNumber(totalDebt).div(Math.pow(10, 18)).plus(310)).toFixed(2),
          soldout: new BigNumber(balance_mute).lte(0),
        },
      ];

      dispatch(walletSlice.actions.updateBondInfo(_bondInfo));

      return {
        success: true,
      };
    } catch (e) {
      console.log(e);
      return {
        success: false,
      };
    }
  };


  const depositBond = async (poolId, amount, approve, max = false, epoch = 0, minAmount = 0) => {
    try {
      const to = ethers.getAddress(getSignerAddress() as string);
      const zksigner = await getZkSigner();

      var bondContract = new Contract(CONTRACTS.BOND.bond, BOND, zksigner!);

      if (approve == true) {
        await approveTokenAmount(poolId, CONTRACTS.BOND.bond);
      }

      var txHandle;
      if (max == true) {
        txHandle = await bondContract.depositMax(new BigNumber(amount).times(Math.pow(10, 18)).toFixed(), epoch - 1, 0);
      } else {
        txHandle = await bondContract.deposit(new BigNumber(amount).times(Math.pow(10, 18)).toFixed(), epoch - 1, 0);
      }

      await txHandle.wait();

      return {
        success: true,
      };
    } catch (e) {
      console.log(e);
      return {
        success: false,
      };
    }
  };

  */

  const isValidV3Pool = async (tokenA, tokenB, fee) => {
    const factory = new Contract(CONTRACTS.v3CoreFactoryAddress, IUniswapV3Factory.abi, provider);
    tokenA = isETH(tokenA) ? CONTRACTS.gas.wrapped_address : tokenA;
    tokenB = isETH(tokenB) ? CONTRACTS.gas.wrapped_address : tokenB;
    return (await factory.getPool(tokenA, tokenB, new BigNumber(fee).times(10000).toString())) == ethers.ZeroAddress;
  };

  const getFullPoolData = async (tokenA, tokenB, fee) => {
    var _tokenA = new Token(
      chainId,
      isETH(tokenA.address) ? CONTRACTS.gas.wrapped_address : tokenA.address,
      +tokenA.decimals,
      tokenA.symbol,
      tokenA.name,
    );
    var _tokenB = new Token(
      chainId,
      isETH(tokenB.address) ? CONTRACTS.gas.wrapped_address : tokenB.address,
      +tokenB.decimals,
      tokenB.symbol,
      tokenB.name,
    );

    [_tokenA, _tokenB] = _tokenA.sortsBefore(_tokenB) ? [_tokenA, _tokenB] : [_tokenB, _tokenA];

    try {
      var data = await getFullPool(
        CONTRACTS.v3CoreFactoryAddress,
        CONTRACTS.INIT_CODE_HASH_v3,
        provider,
        _tokenA,
        _tokenB,
        new BigNumber(fee).times(10000).toNumber(),
        clientV3,
      );
      return {
        success: true as const,
        data,
      };
    } catch (e) {
      console.log(e);
    }

    return {
      success: false as const,
      data: {
        pool: {token0: _tokenA, token1: _tokenB, fee: new BigNumber(fee).times(10000).toNumber(), liquidity: 0},
        ticks: [],
      },
    };
  };

  const getV3NftInfo = async (id) => {
    const to = ethers.getAddress(getSignerAddress() as string);
    const zksigner = await getZkSigner();

    const factory = new Contract(
      CONTRACTS.nonfungibleTokenPositionManagerAddress,
      NonfungiblePositionManager.abi,
      zksigner,
    );
    const [position, uri] = await Promise.all([factory.positions(id), factory.tokenURI(id)]);

    var token0 = getTokenFromContract(position.token0);
    var token1 = getTokenFromContract(position.token1);

    if (!(token0.symbol && token0.name && token0.decimals && token0.address))
      token0 = await getTokenInformation(position.token0);

    if (!(token1.symbol && token1.name && token1.decimals && token1.address))
      token1 = await getTokenInformation(position.token1);

    const data = await getFullPoolData(token0, token1, Number(position.fee.toString()) / 10000);

    const MAX_UINT128 = new BigNumber(2).pow(128).minus(1).toFixed();
    try{
      var results = await factory.collect.staticCall(
        {tokenId: id, recipient: to, amount0Max: MAX_UINT128, amount1Max: MAX_UINT128},
        {from: to},
      );
    } catch(e){
      results = {
        amount0: 0,
        amount1: 0
      }
    }


    var pair_info = await getPairDayData(data.data['poolAddress'], data.data.pool.fee / 100)

    return {
      ...data.data,
      ...pair_info,
      position,
      token0,
      token1,
      uri,
      fees: {
        token0: new BigNumber(results.amount0).div(Math.pow(10, token0.decimals)).toFixed(),
        token1: new BigNumber(results.amount1).div(Math.pow(10, token1.decimals)).toFixed(),
        claimable: new BigNumber(results.amount0).gt(0) || new BigNumber(results.amount1).gt(0),
      },
    };
  };

  const collectVeRewards = async () => {
    try {
      const zksigner = await getZkSigner();

      const veRewardContract = new Contract(CONTRACTS.VE_REWARDS_2, VE_REWARDS, zksigner);

      const receipts = await handleTransaction(veRewardContract.claim, zksigner, [getVeRewardsRaw()]);

      const txResults = await receipts.waitAndResult();

      await Promise.all([getDAOInfo(), onGetBalance()]);

      return {
        ...txResults,
        success: true,
        gasless: receipts.gasless,
      };
    } catch (e) {
      console.log(e);
      let txResults = getTransactionResults();
      return {
        amountA: null,
        amountB: null,
        liquidity: null,
        share: null,
        success: false,
        error: JSON.stringify(e),
        ...txResults,
      };
    }
  };

  const getCompletedActions = () => {
    const state = store.getState();

    const operations = state.history.map((e) => e.operation);
    const swap = state.history.empty ? undefined : operations.includes("Swap");
    const pool = state.history.empty ? undefined : operations.includes("Add LP") || operations.includes("Remove LP");

    const farms = selectFarms(state);

    return {
      swap,
      pool,
      farm: (farms as any).empty ? undefined : !!farms?.filter((_) => _.isUserFarm)?.length,
      dao: state?.daoInfo?.vekoi_locks.empty ? undefined : !!state?.daoInfo?.vekoi_locks?.length,
    };
  };

  const getMerkleCampaigns = async() => {
    const V3_API = `https://api.merkl.xyz/v3/campaigns?chainIds=${chainId}&types=2`
    const V2_API = `https://api.merkl.xyz/v3/campaigns?chainIds=${chainId}&types=1`

    const data = await Promise.all([fetch(V3_API).then((_) => _.json()), fetch(V2_API).then((_) => _.json())])
    // Filter the data
    
    type CampaignData = {
      [key: string]: {
        [key: string]: {
          [key: string]: {
            ammName: string;
            [key: string]: any;
          }
        }
      }
    };
    
    const filtered_v3: CampaignData = {};
    Object.entries(data[0] as CampaignData).forEach(([chainId, chainData]) => {
      Object.entries(chainData).forEach(([poolId, poolData]) => {
        Object.entries(poolData).forEach(([campaignId, campaignData]) => {
          if (campaignData.ammName === "KOI") {
            if (!filtered_v3[chainId]) filtered_v3[chainId] = {};
            if (!filtered_v3[chainId][poolId]) filtered_v3[chainId][poolId] = {};
            filtered_v3[chainId][poolId][campaignId] = campaignData;
          }
        });
      });
    });

    const filtered_v2: CampaignData = {};
    Object.entries(data[1] as CampaignData).forEach(([chainId, chainData]) => {
      Object.entries(chainData).forEach(([poolId, poolData]) => {
        Object.entries(poolData).forEach(([campaignId, campaignData]) => {
          if (campaignData.ammName === "KOI") {
            if (!filtered_v2[chainId]) filtered_v2[chainId] = {};
            if (!filtered_v2[chainId][poolId]) filtered_v2[chainId][poolId] = {};
            filtered_v2[chainId][poolId][campaignId] = campaignData;
          }
        });
      });
    });

    dispatch(walletSlice.actions.updateMerkleCampaigns({
      v3: filtered_v3,
      v2: filtered_v2
    }));

    return {
      v3: filtered_v3,
      v2: filtered_v2
    }
  }

  const getUserMerkleRewards = async() => {
    type MerklAPIData = {
      [token: string]: {
        claim: string;
        token: string;
        leaf: string;
        proof?: string[];
      };
    };
    try{
      let data: MerklAPIData;
      const to = getSignerAddress();
      try {
        data = await fetch(`https://api.merkl.xyz/v3/userRewards?chainId=${chainId}&user=${to}&proof=true`).then((_) => _.json());
      } catch {
        throw "Angle API not responding";
      }
  
      const tokens = Object.keys(data!).filter((k) => data![k].proof !== undefined || data![k].proof!.length != 0);
  
      //if (tokens.length === 0) throw "No tokens to claim";
      dispatch(walletSlice.actions.updateMerkleRewards({
        tokens: tokens,
        totalTokens: tokens.map((t) => data![t]['unclaimed']),
      }));
  
      return {
        tokens: tokens.map((t) => to),
        totalTokens: tokens,
        claims: tokens.map((t) => data![t]['accumulated']),
        proofs: tokens.map((t) => data![t]['proof'])
      }
    } catch (e){
      return {
        tokens: [],
        totalTokens: [],
        claims: [],
        proofs: []
      }
    }

  }

  const collectMerkleRewards = async () => {
    try {
      const zksigner = await getZkSigner();
      const contractAddress = '0xe117ed7Ef16d3c28fCBA7eC49AFAD77f451a6a21' //registry(chainId)?.Merkl?.Distributor;
      const distributor_abi = [
        {
          inputs: [
              {
                  internalType: "address[]",
                  name: "users",
                  type: "address[]",
              },
              {
                  internalType: "address[]",
                  name: "tokens",
                  type: "address[]",
              },
              {
                  internalType: "uint256[]",
                  name: "amounts",
                  type: "uint256[]",
              },
              {
                  internalType: "bytes32[][]",
                  name: "proofs",
                  type: "bytes32[][]",
              },
          ],
          name: "claim",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        }
      ]
      const merkleClaim = new Contract(contractAddress!, distributor_abi, zksigner);
      var rewards = await getUserMerkleRewards()
      const receipts = await handleTransaction(merkleClaim.claim, zksigner, [rewards.tokens, rewards.totalTokens, rewards.claims, rewards.proofs]);

      const txResults = await receipts.waitAndResult();

      await Promise.all([getUserMerkleRewards(), onGetBalance()]);

      return {
        ...txResults,
        success: true,
        gasless: receipts.gasless,
      };
    } catch (e) {
      console.log(e);
      let txResults = getTransactionResults();
      return {
        amountA: null,
        amountB: null,
        liquidity: null,
        share: null,
        success: false,
        error: JSON.stringify(e),
        ...txResults,
      };
    }
  };


  return {
    isETH,
    isStable,
    isUSDC,
    prepareTradeSmart,
    executeTradeSmart,
    createLiquidityPool,
    removeLiquidityPermit,
    removeLiquidityWithPermit,
    getDAOInfo,
    getTokenPair,
    getTokenFromContract,
    addTokenToStorage,
    getTokenInformation,
    stakeInAmplifier,
    withdrawFromAmplifier,
    payoutFromAmplifier,
    //depositBond,
    getTokenFromSymbol,
    RedeemVEKOI,
    LockKoi,
    getPairDayData,
    searchForLiquidity,
    searchForLiquidityAndUpdate,
    destroyCurrentTrade,
    claimPoolFees,
    currentSwapCost,
    getETH,
    getKoiToken,
    getKoiTokenPrice,
    getVeRewards,
    loadedInitialList,
    getPairTradeData,
    addTokenToWallet,
    ChangePoolFee,
    updateAmp,
    ChangeVEMuteDelegate,
    GetPinnedTokens,
    setFeeAsset,
    getFeeAsset,
    getFeeAssets,
    getAmplifierV2Pools,
    getAmplifierV2Info,
    getAmplifierV2Multiplier,
    payoutFromAmplifierV2,
    isValidV3Pool,
    addLiquidityV3,
    removeLiquidityV3,
    increaseLiquidityV3,
    claimPositionFees,
    getV3NftInfo,
    getFullPoolData,
    wethOrToken,
    getCompletedActions,
    collectVeRewards,

    // Just for typing purposes
    searchForAllLiquidityV3,
    getTransactionResults,
  };
};

export type WalletHookType = ReturnType<typeof useWalletHookFn>;

export const WalletHook = ({children}) => {
  const value = useWalletHookFn();

  return <walletHookContext.Provider value={value}>{children}</walletHookContext.Provider>;
};

export function useWalletHook<useTyping = false>() {
  const context = React.useContext(walletHookContext);
  if (!context) throw Error("useWalletHook can only be used within the Web3ReactProvider component");
  return context as useTyping extends true ? ReturnType<typeof useWalletHookFn> : any;
}
