import styled from "styled-components";

import {br, card, microPalette, palette, size, text, transition} from "../../styles";

export const Wrapper = styled.div<{full?: boolean; small?: boolean; micro?: boolean}>`
  ${card("s")}
  ${size.padding(1.5)}

  display: inline-flex;

  ${(_) =>
    _.full &&
    `
    width: 100%;
  `}

  ${(_) =>
    _.small &&
    `
    ${Tab} {
      ${text.bodySmall}
      ${size.padding(1, 3)}
    }
  `}

  ${(_) =>
    _.micro &&
    `
    ${size.padding(0.5)}
    
    ${Tab} {
      ${text.bodyMicro}
      ${size.padding(0.5, 2)}
    }
  `}
`;

export const Tab = styled.div<{active?: boolean}>`
  ${text.h7}
  ${br.s}
  ${transition.fast}
  ${size.padding(2, 6)}

  flex-grow: 1;
  flex-basis: 0;
  flex-wrap: no-wrap;
  min-width: max-content;
  text-align: center;
  line-height: ${38 - size.raw(4)}px;
  color: ${palette.gray400};
  cursor: pointer;

  &:hover {
    color: ${microPalette.c300};
  }

  ${(_) =>
    _.active &&
    `
    background: ${microPalette.c100};
    color: ${microPalette.c300};
    pointer-events: none;
  `}
`;
