import {
  TICK_SPACINGS,
} from "@uniswap/v3-sdk";
import {keccak256, AbiCoder} from "ethers";
import BigNumber from "bignumber.js";
import {ethers} from "ethers";
import {pack} from "@ethersproject/solidity";

// initialize tick spacings
TICK_SPACINGS[100] = 1;
TICK_SPACINGS[500] = 10;
TICK_SPACINGS[3000] = 60;
TICK_SPACINGS[5000] = 100;
TICK_SPACINGS[10000] = 200;

export function v3PoolAddress(factory: string, init_code_hash: string, token0: string, token1: string, fee: number) {
  const prefix = keccak256(ethers.toUtf8Bytes("zksyncCreate2"));
  const inputHash = keccak256(ethers.toUtf8Bytes(""));

  var data = AbiCoder.defaultAbiCoder().encode(["address", "address", "uint24"], [token0, token1, fee]);
  const addressBytes = ethers
    .keccak256(ethers.concat([prefix, ethers.zeroPadValue(factory, 32), keccak256(data), init_code_hash, inputHash]))
    .slice(26);
  const calculatedPool = ethers.getAddress(addressBytes);
  return calculatedPool;
}

export function getFeeToTickSpacing(_fee: number | string) {
  return TICK_SPACINGS[Number(_fee)];
}

export function priceToSqrt(amount0: string | number, amount1: string | number, decimalAdjustment: number) {
  return new BigNumber(amount1)
    .div(amount0)
    .div(Math.pow(10, decimalAdjustment))
    .sqrt()
    .times(Math.pow(2, 96))
    .toFixed(0);
}

/*
export function sqrtToPrice(sqrtPriceX96: string | number, token0: Token, token1: Token) {
    const ratio = new BigNumber(Math.pow(10,token1.decimals)).div(Math.pow(10, token0.decimals))
    const price = new BigNumber(sqrtPriceX96).div(Math.pow(2,96)).times(2)

    return {
        token0Price: price.div(ratio).toFixed(),
        token1Price: new BigNumber(1).div(price.div(ratio)).toFixed()
    }
}
*/

export function encodePath(path: {from: {contractAddress: String}; to: {contractAddress: String}; fee: Number}[]) {
  /*
    let encoded = '0x'
    for (let i = 0; i < path.length; i++) {
      // 20 byte encoding of the address
      encoded += String(path[i].from.contractAddress).slice(2)
      // 3 byte encoding of the fee
      encoded += path[i].fee.toString(16).padStart(6, '0')
    }
    // encode the final token
    encoded += path[path.length - 1].to.contractAddress.slice(2)
  
    return encoded.toLowerCase()
    */

  let calls: any[] = [];
  let types: string[] = [];

  for (let i = 0; i < path.length; i++) {
    if (i == 0) {
      types = ["address", "uint24", "address"];
      calls = [path[i].from.contractAddress, path[i].fee, path[i].to.contractAddress];
    } else {
      types.push("uint24");
      types.push("address");

      calls.push(path[i].fee);
      calls.push(path[i].to.contractAddress);
    }
  }

  return pack(types, calls);
}
