import {JsonFragment} from "@ethersproject/abi";

import QUOTER02 from "@uniswap/v3-periphery/artifacts/contracts/lens/QuoterV2.sol/QuoterV2.json";
import Factory from "@uniswap/v3-core/artifacts/contracts/UniswapV3Factory.sol/UniswapV3Factory.json";
import Pool from "@uniswap/v3-core/artifacts/contracts/UniswapV3Pool.sol/UniswapV3Pool.json";

export class KoiContractContext {
  public static quoterAddress = "0x6988C6bDC02E6AF5d99Fe45CE25D67dc781E2A2a";

  public static factoryAddress = "0x488A92576DA475f7429BC9dec9247045156144D3";

  public static permit2Address = "0x0000000000225e31D15943971F47aD3022F714Fa";

  public static INIT_CODE_HASH = "0x0100128b709068f187cf0a565f39cd8d90baf51a12a5717aebcdf69a503234b9";

  /**
   * Quoter Abi
   */
  public static quoterAbi: JsonFragment[] = QUOTER02.abi;

  /**
   * Factory Abi
   */
  public static factoryAbi: JsonFragment[] = Factory.abi;

  /**
   * Pool Abi
   */
  public static poolAbi: JsonFragment[] = Pool.abi;
}
