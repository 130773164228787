import {useQuery} from "@tanstack/react-query";
import {useMemo} from "react";
import {fetchGraphQL} from "../../../utils/gql-utils";
import {getGraphQLUrl} from "../../../web3/apollo/client";
import {AnalyticsGlobalData} from "./global";
import {transformTokens} from "./top-tokens.utils";

const tokenProps = `
  a: id
  s: symbol
  n: name
  l: totalLiquidity
  v: tradeVolumeUSD
  t: txCount
  p: derivedETH
`;

export const useTokenDataV2 = (globalData?: AnalyticsGlobalData, address?: string) => {
  const query = useQuery({
    queryKey: ["tokens-v2", address],
    queryFn: async () => {
      try {
        if (!globalData) throw "No global data";
        const url = getGraphQLUrl();
        const filter = JSON.stringify([address]);
        const {
          data: {tokens, lastDayTokens},
        } = await fetchGraphQL(
          url,
          `
          query {
            tokens (where: {id_in: ${filter}}) {
              ${tokenProps}
            }
            lastDayTokens: tokens (block: {number: ${globalData.blocks.day}}, where: {id_in: ${filter}}) {
              ${tokenProps}
            }
          }
        `,
        );
        const refs = {};
        tokens.forEach((_) => (refs[_.a] = _));
        lastDayTokens.forEach((_) => (refs[_.a].oneDayBefore = _));
        return tokens;
      } catch (e) {
        console.warn(e);
        throw e;
      }
    },
    enabled: !!globalData?.blocks?.day && !!address,
    refetchOnWindowFocus: false,
  });

  const data = useMemo(() => transformTokens(globalData, query.data) || [], [globalData, query.data]);

  return {
    ...query,
    ready: !!data.length,
    token: data?.[0] || {},
    tokens: data || [],
  };
};
