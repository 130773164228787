import { sophonsepolia } from "../../providers/Providers";
var SOPHON_SEPHOLIA_TOKEN: any = [
    {
      chainId: sophonsepolia.id,
      address: "0x0000000000000000000000000000000000000000",
      name: "Sophon",
      symbol: "SOPH",
      decimals: 18,
      logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    },
    {
      chainId: sophonsepolia.id,
      address: "0x9B7EA63EF5062661570afe548e3AD691D5652527",
      name: "Wrapped SOPH",
      symbol: "SOPH",
      decimals: 18,
      logoURI: "https://assets.coingecko.com/coins/images/2518/small/weth.png?1628852295",
    },
    {
      chainId: sophonsepolia.id,
      address: "0x155F5f62Ae114a46aF924FAe3a9b994849076403",
      name: "Bridged USDC",
      symbol: "USDC.e",
      decimals: 6,
      logoURI: "https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389",
    },
  ];
  
  export default SOPHON_SEPHOLIA_TOKEN;
