import React, {useState} from "react";
import styled from "styled-components";
import {shortDate} from "../../../utils/formatDate";
import {TransactionDetailsModal} from "./TransactionDetailsModal";
import {Transaction} from "../../../model/Transaction";
import {Card, CardContent, Empty, Flex, Icon, IconStatus, Text} from "../../../ui/components";
import {transactionToText} from "../../../utils/transactionToText";

export const HistoryList = ({history}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [selectedTransaction, setSelectedTransaction] = useState<Transaction | undefined>();

  const onTransactionClick = (transaction: Transaction) => {
    setSelectedTransaction(transaction);
    setIsModalVisible(true);
  };

  return (
    <>
      <CardContent extend>
        <CardContent.Title title="Transaction history" />
        <CardContent.Content>
          {!history.length ? (
            <Empty title="No history yet" />
          ) : (
            <Flex gap={1.5} column>
              {(history as Transaction[]).map((transaction, i) => (
                <Card key={transaction.hash + i} onClick={() => onTransactionClick(transaction)}>
                  <Flex gap={3}>
                    <IconWrapper>
                      <IconStatus transaction={transaction} />
                    </IconWrapper>
                    <Text h6>{transaction.operation}</Text>
                    <Flex.Grow />
                    <Text align="right">
                      <Text bodyMedium>{transactionToText(transaction)}</Text>
                      <Text bodyRegular color="gray400">
                        {shortDate(transaction.timestamp)}
                      </Text>
                    </Text>
                    <Icon icon="arrow" button size="s" rotate={3} color="c300" />
                  </Flex>
                </Card>
              ))}
            </Flex>
          )}
        </CardContent.Content>
      </CardContent>

      {/*
      <List isExpanded={isExpanded}>
        {history.map((item, index) => (
          (
              <>
              <LI key={index} onClick={() => {}}>
                <Button onClick={() => onTransactionClick(item)}>
                  <TextMedium style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginRight: '10px'}}><TxIcon src={succesful_tx}/>{item.operation}</TextMedium>
                  {item.status === 'Confirmed'
                    ? <Date>{formatDate(item.timestamp)}</Date>
                    : <Badge>{item.status}</Badge>
                  }
                  <Amount>${parseFloat(item.amountUSD).toFixed(4)} </Amount>
                </Button>
              </LI>
              <SeperatorLine/>
              </>
            )



        ))}
      </List>
      */}
      <TransactionDetailsModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        transaction={selectedTransaction}
      />
    </>
  );
};

const IconWrapper = styled.div`
  display: contents;

  & > svg {
    min-width: 32px;
    min-height: 32px;
  }
`;
