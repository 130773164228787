const GAS_ZKSYNC_ERA = {
    address: '0x0000000000000000000000000000000000000000',
    name: 'Ethereum',
    symbol: 'ETH',
    decimals: 18,
    wrapped_address: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91' ,
    wrapped_name: 'Wrapped Ether' ,
    wrapped_symbol: 'WETH',
    logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png'
};

const GAS_SOPHON_SEPOLIA = {
    address: '0x0000000000000000000000000000000000000000',
    name: 'Sophon',
    symbol: 'SOPH',
    decimals: 18,
    wrapped_address: '0x9B7EA63EF5062661570afe548e3AD691D5652527' ,
    wrapped_name: 'Wrapped Sophon' ,
    wrapped_symbol: 'WSOPH',
    logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png'
};

export {GAS_ZKSYNC_ERA, GAS_SOPHON_SEPOLIA}