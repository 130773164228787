import React, {ReactNode} from "react";
import styled from "styled-components";
import {BlockTitle} from "../text/BlockTitle";
import Toggle from "react-toggle";

interface WidgetProps {
  children: ReactNode;
  title?: string;
  toggle?: boolean;
  toggleVal?: boolean;
  setToggleVal?: (e: any) => void;
  className?: string;
}

export const Widget = ({children, title, toggle, toggleVal, setToggleVal, className}: WidgetProps) => {
  return (
    <WidgetBox className={className}>
      {title && (
        <WidgetHeader>
          {title && toggle ? (
            <WidgetTitle>
              {title}
              <ToggleDiv>
                <BalText>Hide 0 balance</BalText>
                <ToggleCustom defaultChecked={toggleVal} icons={false} onChange={setToggleVal} />
              </ToggleDiv>
            </WidgetTitle>
          ) : title ? (
            <WidgetTitle>{title}</WidgetTitle>
          ) : (
            <></>
          )}
        </WidgetHeader>
      )}
      {children}
    </WidgetBox>
  );
};

export const WidgetBox = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.05);
  padding: 20px 20px;
  background: var(--color-modal-blue);
  border-radius: 18px;
  font-size: 6px;
  box-shadow: rgba(50, 148, 248, 0.2) 0px 20px 100px;
  @media (max-width: 600px) {
    padding: 16px;
  }
`;

const WidgetHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const WidgetTitle = styled(BlockTitle)`
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const EmptyWidgetText = styled.p`
  margin: 20px 0 32px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
`;

const ToggleDiv = styled.div`
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const BalText = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #777f99;
  padding-right: 5px;
`;

const ToggleCustom = styled(Toggle)``;
