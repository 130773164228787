import React, {ReactNode, useEffect} from "react";
import {Loader} from "../Loader";
import {Modal} from "../../ui/components";
import {IconWrapper, ModalDescription} from "./ModalComponents";
import {TransactionDirection, TransactionDirectionProps} from "../TransactionDirection";
import {LiquidityTransactionDirection} from "../LiquidityTransactionDirection";
import {WalletState} from "./../../state";
import {useWalletSelector} from "../../state/hooks";

import styled from "styled-components";

export interface ConfirmTransactionModalProps extends TransactionDirectionProps {
  isVisible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  footer?: ReactNode;
  isLP?: boolean;
  deposit?: number;
  title?: string;
}

export const ConfirmTransactionModal = ({
  isVisible,
  onClose,
  onConfirm,
  leftValue,
  leftIcon,
  rightValue,
  rightIcon,
  footer,
  icon,
  isLP,
  deposit,
  title,
}: ConfirmTransactionModalProps) => {
  const status = useWalletSelector((state: WalletState) => state.transactionModalStatus);

  useEffect(() => {
    return () => {}; //clearTimeout(timer);
  }, [isVisible]);

  return (
    <Modal title={title ? title : "Confirm transaction"} isVisible={isVisible} onClose={onClose}>
      {isLP == true ? (
        <LiquidityTransactionDirection
          leftValue={leftValue}
          leftIcon={leftIcon}
          rightValue={rightValue}
          rightIcon={rightIcon}
          deposit={deposit ? deposit : 0}
          icon={icon}
        />
      ) : (
        <TransactionDirection
          leftValue={leftValue}
          leftIcon={leftIcon}
          rightValue={rightValue}
          rightIcon={rightIcon}
          icon={icon}
        />
      )}

      <></>
      <Footer>{footer}</Footer>
      <IconWrapper>
        <Loader />
        <ModalDescription>{status}</ModalDescription>
      </IconWrapper>
    </Modal>
  );
};

const Line = styled.div`
  width: 100%;
  height: 0px;
  margin-top: 25px;
  margin-bottom: 25px;
  border: 1px solid #262436;
`;
const Footer = styled.div`
  margin-top: 12px;
  padding-bottom: 25px;
`;
