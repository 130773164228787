
import { Button, Text, Chip, Flex, InputCoinAcross, InputCoin } from "../../../ui/components";
import { useAvailableRoutes } from "../../../providers/across_hooks/useAvailableRoutes";
import { useInputTokens } from "../../../providers/across_hooks/useInputTokens";
import { useOutputTokens } from "../../../providers/across_hooks/useOutputTokens";
import { useQuote } from "../../../providers/across_hooks/useQuote";
import { useSupportedAcrossChains } from "../../../providers/across_hooks/useSupportedAcrossChains";
import { getExplorerLink, isNativeToken } from "../../../providers/across_hooks/utils";
import { TokenInfo } from "@across-protocol/app-sdk";
import { useEffect, useState } from "react";
import { formatUnits, parseUnits } from "viem";
import { useAccount, useBalance, useChains } from "wagmi";
import { useDebounceValue } from "usehooks-ts";
import { useExecuteQuote } from "../../../providers/across_hooks/useExecuteQuote";
import { useAcrossChains } from "../../../providers/across_hooks/useAcrossChains";
import { ExecutionProgress } from "@across-protocol/app-sdk";
import { ChainSelect } from "./components/ChainSelect";
import { Divider } from "../../../ui/components";
import { ExternalLink } from "./components/ExternalLink";
import { TokenSelect } from "./components/TokenSelect";
import { TokenInput } from "./components/TokenInput";
import { useWalletSelector } from "../../../state/hooks";
import { WalletState } from "../../../state";
import { Asset } from "../../../model/Asset";
import { useTokensBalance } from "../../../providers/across_hooks/useTokensBalance";
import { AnimatePresence, motion } from "framer-motion";
import { reduceString } from "../../../utils";
import styled from "styled-components";
import { palette } from "../../../ui/styles";

export function BridgeSection() {
    const { address } = useAccount();
    const chains = useChains();
    // CHAINS
    //const { supportedChains } = useSupportedAcrossChains({});

    // use only token data for chains we support
    const acrossChains = useAcrossChains();

    // zkSync default input chain
    const defaultOriginChainId = chains.find((chain) => chain.id === 324)?.id;
    const [destinationChainId, setDestinationChainId] = useState<number | undefined>(defaultOriginChainId);
    const [originChainId, setOriginChainId] = useState<number | undefined>(chains.find((chain) => chain.id !== destinationChainId)?.id);
    const [fromTokenAmount, setFromTokenAmount] = useState("0")
    const [toTokenAmount, setToTokenAmount] = useState("0")

    // FROM TOKEN
    const { inputTokens } = useInputTokens(originChainId);
    const inputTokensBalance = useTokensBalance(originChainId, inputTokens)

    const [localInputToken, setLocalInputToken] = useState<Asset | undefined>(undefined);

    const setLocalInputTokenLocal = (_asset: Asset) => {
        setLocalInputToken(_asset)
        setFromToken(inputTokens?.find((_input) => _input.address.toLowerCase() == _asset.address?.toLowerCase()))
    }
    const [sendAmount, setSendAmount] = useState("0");

    const assets: any = useWalletSelector((state: WalletState) => state.tokens); //useWalletSelector(() => store.getState().tokens)

    function findMatchingTokensOptimized(arrayOne, arrayTwo) {
        if (!arrayOne || !arrayTwo)
            return []

        // Create a Set of lowercase addresses from arrayOne for O(1) lookup
        const addressSet = new Set(
            arrayOne.map(item => item.address.toLowerCase())
        );

        // Filter arrayTwo using the Set
        return arrayTwo.filter(item =>
            addressSet.has(item.address.toLowerCase())
        );
    }

    const sortedAssets = findMatchingTokensOptimized(inputTokens, Object.values(assets))

    const [fromToken, setFromToken] = useState<TokenInfo | undefined>(
        inputTokens?.[0],
    );

    const { data: fromTokenBalance } = useBalance({
        address,
        token: isNativeToken(fromToken, originChainId)
            ? undefined
            : fromToken?.address,
        chainId: originChainId,
    });

    const { availableRoutes } = useAvailableRoutes({
        originChainId,
        destinationChainId,
        originToken: fromToken?.address,
    });

    const outputTokensForRoute = availableRoutes?.map((route) =>
        route.outputToken.toLowerCase(),
    );

    const { outputTokens: outputTokensForChain } = useOutputTokens(destinationChainId);

    const [outputTokens, setOutputTokens] = useState<TokenInfo[] | undefined>();

    const outputTokensBalance = useTokensBalance(destinationChainId, outputTokens)

    useEffect(() => {
        const _outputTokens = outputTokensForChain?.filter((token) =>
            outputTokensForRoute?.includes(token.address.toLowerCase()),
        );
        setOutputTokens(_outputTokens);
    }, [availableRoutes]);

    const [toToken, setToToken] = useState<TokenInfo | undefined>(
        outputTokens?.[0],
    );

    useEffect(() => {
        if (outputTokens) {
            setToToken(
                outputTokens.find((token) => token.symbol === fromToken?.symbol) ??
                outputTokens?.[0],
            );
        }
    }, [outputTokens]);

    const [inputAmount, setInputAmount] = useState<string>("");
    const [debouncedInputAmount] = useDebounceValue(inputAmount, 300);
    const route = availableRoutes?.find((route) => {
        return (
            route.outputToken.toLocaleLowerCase() ===
            toToken?.address?.toLowerCase() &&
            route.outputTokenSymbol === toToken.symbol
        );
    });

    const quoteConfig =
        route && debouncedInputAmount && fromToken
            ? {
                route,
                recipient: address,
                inputAmount: parseUnits(debouncedInputAmount, fromToken?.decimals),
            }
            : undefined;

    const {
        quote,
        isLoading: quoteLoading,
        isRefetching,
    } = useQuote(quoteConfig);

    const {
        executeQuote,
        progress,
        error,
        isPending,
        depositReceipt,
        fillReceipt,
    } = useExecuteQuote(quote);
    const inputBalance = fromTokenBalance
        ? parseFloat(
            formatUnits(fromTokenBalance?.value, fromTokenBalance?.decimals),
        ).toFixed(4)
        : undefined;

    function onMax() {
        if (!fromTokenBalance?.value) return;
        setInputAmount(
            formatUnits(fromTokenBalance?.value, fromTokenBalance?.decimals),
        );
    }
    const originChain = chains.find((chain) => chain.id === originChainId);
    const destinationChain = chains.find(
        (chain) => chain.id === destinationChainId,
    );

    const depositTxLink = 
        depositReceipt &&
        originChain &&
        getExplorerLink({
            chain: originChain,
            type: "transaction",
            txHash: depositReceipt.transactionHash,
        });

    const fillTxLink =
        fillReceipt &&
        destinationChain &&
        getExplorerLink({
            chain: destinationChain,
            type: "transaction",
            txHash: fillReceipt.transactionHash,
        });

    const getStatus = () => {
        switch (progress.status) {
            case "txSuccess":
                return "Success"
            case "txPending":
                return "Pending..."
            default:
                return "Idle"
        }    
    }

    return (
        <div className="bg-foreground border border-border-secondary p-6 w-full rounded-[10px]">
            <>
                <div className="flex flex-col gap-4 w-full">
                    <Flex column gap={2}>
                        <Flex justify="space-between" gap={4}>
                            <Flex gap={2} column expandHorizontal>
                                <Text h6>From</Text>

                                <ChainSelect
                                    id="origin-chain"
                                    chains={acrossChains}
                                    chain={originChainId}
                                    onChainChange={(chainId) => {
                                        setDestinationChainId(undefined);
                                        setOriginChainId(chainId);
                                    }}
                                />
                            </Flex>
                            <Flex gap={2} column expandHorizontal>
                                <Text h6>To</Text>

                                <ChainSelect
                                    id="destination-chain"
                                    chains={acrossChains}
                                    chain={destinationChainId}
                                    onChainChange={setDestinationChainId}
                                />
                            </Flex>

                        </Flex>

                        <Divider size={2} />
                        <Text h6>Send</Text>
                        <InputCoin
                            value={inputAmount}
                            setValue={(r) => setInputAmount(String(r))}
                            price={localInputToken!?.price}
                            assets={inputTokensBalance}
                            selectedAsset={localInputToken}
                            onAssetSelect={setLocalInputTokenLocal}
                        />
                        {/*<InputCoinAcross
                value={fromTokenAmount}
                setValue={(r) => setFromTokenAmount(String(r))}
                price={undefined}
                assets={inputTokens}
                selectedAsset={fromToken}
                onAssetSelect={setFromToken}
            />*/}
                    </Flex>

                    <Divider size={2} />

                    <Flex gap={2} column>
                        <Text h6>Receive</Text>
                        <InputCoinAcross
                            disabled={outputTokens ? !(outputTokens?.length > 1) && (quoteLoading && !quote) : true}
                            value={quote && toToken ? formatUnits(quote.deposit.outputAmount, toToken.decimals) : '0.00'}
                            setValue={(r) => setToTokenAmount(String(r))}
                            price={undefined}
                            assets={outputTokens}
                            selectedAsset={toToken}
                            onAssetSelect={setToToken}
                            noBalance
                            disableInput
                        />
                    </Flex>

                    <Divider size={4} />
                </div>
            </>

            <div className="flex flex-col items-start gap-2 bg-foreground border border-border-secondary p-6 w-full rounded-[10px]">
                <Button
                    onClick={() => executeQuote()}
                    full
                    disabled={!(quote && toToken) || isRefetching || isPending}
                >
                    {isPending
                        ? "Executing..."
                        : isRefetching
                            ? "Updating quote..."
                            : "Bridge"}
                </Button>    

                {(progress || depositTxLink) && <DropdownContainer visible={true}>
                    <AnimatePresence>
                    
                            <DropdownContent
                                initial={{ height: 0 }}
                                animate={{ height: 'auto' }}
                                exit={{ height: 0 }}
                                transition={{ duration: 0.3 }}
                            >
                                <Flex gap={3} column padding="0 4">
                                    {[
                                        depositTxLink ? ["Deposit Tx", <Text style={{cursor: 'pointer'}} onClick={() => window.open(depositTxLink, "_blank")}>{reduceString(depositReceipt.transactionHash, 7, 4)}</Text>] : ["Status", getStatus()],
                                        ["Recipient", `${address ? reduceString(address as string, 7, 4) : "Not Connected"}`],
                                    ].map(([title, content]) => (
                                        <Flex justify="space-between">
                                            <Text bodyRegular>{title}</Text>
                                            <Text bodyMedium color={"gray400"}>{content}</Text>
                                        </Flex>
                                    ))}
                                </Flex>
                            </DropdownContent>
                        
                    </AnimatePresence>
                </DropdownContainer>}
            </div>
        </div>
    );
}

export type ProgressProps = {
    progress: ExecutionProgress;
    error?: Error | null;
    className?: string;
};

export const Status = {
    PENDING: "PENDING",
    SUCCESS: "SUCCESS",
    IDLE: "IDLE",
    ERROR: "ERROR",
} as const;

// TODO: make more fully featured
export function Progress({ progress, error, className }: ProgressProps) {
    if (progress.status === "idle") {
        return <></>;
    }

    const status = (() => {
        if (
            progress.status === "txError" ||
            progress.status === "simulationError" ||
            progress.status === "error"
        ) {
            return Status.ERROR;
        }
        if (progress.status === "txSuccess" && progress.step === "fill") {
            return Status.SUCCESS;
        }
        return Status.PENDING;
    })();

    const label = (() => {
        if (
            progress.status === "txError" ||
            progress.status === "simulationError" ||
            progress.status === "error"
        ) {
            return progress.error.name;
        }
        if (progress.step === "approve") {
            return "Approving ERC20 spend...";
        }
        if (progress.step === "deposit") {
            return "Depositing on origin chain...";
        }
        if (progress.step === "fill" && progress.status === "txSuccess") {
            return "Bridge complete!";
        }

        if (progress.step === "fill" && progress.status === "txPending") {
            return "Filling on destination chain...";
        }
    })();

    return (
        <div className={"px-2 w-full flex flex-col items-center gap-2"}>
            <p className="text-text/75 text-sm">{label}</p>
            <Text>{status}</Text>
        </div>
    );
}

const DropdownContainer = styled.div<{visible: boolean}>`
  width: 100%;
  border: 1px solid ${palette.gray100};
  border-radius: 14px;
  margin-top: 12px;
  padding: 12px 4px;
  display: ${({ visible }) => visible ? 'block': 'none'};
`;

const DropdownHeader = styled.div<{rotateDeg: number}>`
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  & > svg {
    transition: all 0.3s ease;
    transform: rotate(${({ rotateDeg }) => rotateDeg}deg);
  }
`;

const DropdownContent = styled(motion.div)`
  overflow: hidden;
`;