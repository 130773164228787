import React, {useState, useRef, ReactElement, JSXElementConstructor} from "react";
import {useLocation, useHistory} from "react-router-dom";
import {AddLiquidity} from "./LiquidityPoolModals/AddLiquidity";
import {LiquidityRewardsWidget} from "./LiquidityRewardsWidget";
import {YourLiquidityWidget} from "./YourLiquidityWidget";
import {AllPoolsWidget} from "./AllPoolsWidget";
import {useWalletHook} from "../../web3/walletHook";
import {WalletState} from "../../state";
import {useWalletSelector} from "../../state/hooks";
import {Box, Tabs, Scroll, useLayoutMq, Title, layoutMq, Flex, Text} from "../../ui/components";
import BigNumber from "bignumber.js";
import {Address, Asset} from "../../web3/types";
import {Pool, Position} from "@uniswap/v3-sdk";
import {Percent} from "@uniswap/sdk-core";
import { C } from "@tanstack/query-core/build/legacy/queryClient-aPcvMwE9";
import styled from "styled-components";
import { TrendingPoolV3 } from "./TrendingPoolV3";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import "./trendingstyles.css"
import { AiFillFire } from "react-icons/ai";

const queryString = require("query-string");

export const LiquidityPoolScreen = () => {
  const location = useLocation();
  const history = useHistory();
  const layoutBreak = useLayoutMq();


  const addLiqModal = useRef<any>(null);

  const [section, setSection] = useState(queryString.parse(location.search).id == '1' ? "b" : "a");

  const {createLiquidityPool, calculateLiquidityPool, getTokenFromContract} = useWalletHook();

  const tokens = useWalletSelector((state: WalletState) => state.tokens);
  const ownedPairs = useWalletSelector((state: WalletState) => state.ownedPairs);
  const ownedPairsV3 = useWalletSelector((state: WalletState) => state.ownedV3Liquidity);
  const allPairs = useWalletSelector((state: WalletState) => state.allPairs);
  const isLoading = useWalletSelector((state: WalletState) => state.ownedPairs == null);
  const isLoadingAll = useWalletSelector((state: WalletState) => state.allPairs == null || state.allPairs.length == 0);

  const searchParams = React.useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  const addLiquidity = async (assetA, assetB, amountA, amountB) => {
    return await createLiquidityPool(assetA, assetB, amountA, amountB);
  };

  const calculateLiquidity = async (assetA, assetB, amountA, amountB) => {
    return await calculateLiquidityPool(assetA, assetB, amountA, amountB);
  };

  const manageLiquidity = (assetAAddress, assetBAddress, type, feeAmount = 5000) => {
    if (type == 0) {
      history.push(`/pool/v3/new/${assetAAddress}/${assetBAddress}/${feeAmount}`);
    } else {
      history.push(`/pool/${assetAAddress}/${assetBAddress}/${type == 1 ? "true" : "false"}`);
    }
  };

  React.useEffect(() => {
    var url = queryString.parse(location.search);
    if (url.id != '0' && url.id != '1') {
      searchParams.set("id", "0");
      history.replace({search: searchParams.toString()});
    }

  },[])

  React.useEffect(() => {
    var url = queryString.parse(location.search);
    if (url.id == '0') {
      setSection('a')
    } else if (url.id == '1'){
      setSection('b')
    } else {
      searchParams.set("id", "0");
      history.replace({search: searchParams.toString()});
    }

  },[location])

  const setSectionLocal = (val: string) => {
    searchParams.set("id", val == 'a' ? '0' : '1');
    history.replace({search: searchParams.toString()});
    setSection(val)
  }

  const sortedAllLiq = React.useMemo(() => {
    return Object.values(allPairs)
      .sort((a: any, b: any) => {
        let _aPrice = 0;
        let _bPrice = 0;
        if (a.reserveUSD) {
          _aPrice = new BigNumber(a.reserveUSD).toNumber();
        } else if (a.totalValueLockedUSD) {
          _aPrice = new BigNumber(a.totalValueLockedUSD).toNumber();
        }

        if (b.reserveUSD) {
          _bPrice = new BigNumber(b.reserveUSD).toNumber();
        } else if (b.totalValueLockedUSD) {
          _bPrice = new BigNumber(b.totalValueLockedUSD).toNumber();
        }

        return _bPrice - _aPrice;
      });
  }, [allPairs]);


  const sortedLiq = React.useMemo(() => {
    var allVals: Record<Address, Asset> = {};

    for (let i in ownedPairs) {
      let _tokenA = getTokenFromContract(ownedPairs[i].assetA);
      let _tokenB = getTokenFromContract(ownedPairs[i].assetB);

      let assetAPrice = _tokenA && _tokenA.price ? _tokenA.price : "0";
      let assetBPrice = _tokenB && _tokenB.price ? _tokenB.price : "0";

      allVals["lp_" + String(i)] = {
        ...ownedPairs[i],
        assetAPrice,
        assetBPrice,
        hasLiq: true,
      };
    }

    for (let i in ownedPairsV3) {
      let _tokenA = ownedPairsV3[i].asset0;
      let _tokenB = ownedPairsV3[i].asset1;

      let _pool = ownedPairsV3[i].pool as Pool;
      if (_tokenA && _tokenA.price && _tokenB && _tokenB.price) {
        const position = new Position({
          pool: _pool,
          liquidity: Number(ownedPairsV3[i].liquidity.toString()),
          tickLower: Number(ownedPairsV3[i].tickLower.toString()),
          tickUpper: Number(ownedPairsV3[i].tickUpper.toString()),
        });

        const liqAmounts = position.burnAmountsWithSlippage(new Percent("0"));
        let totalValue = new BigNumber(0);
        totalValue = totalValue.plus(
          new BigNumber(liqAmounts.amount0.toString())
            .div(Math.pow(10, _tokenA.decimals))
            .times(_tokenA.price ? _tokenA.price : 0),
        );

        totalValue = totalValue.plus(
          new BigNumber(liqAmounts.amount1.toString())
            .div(Math.pow(10, _tokenB.decimals))
            .times(_tokenB.price ? _tokenB.price : 0),
        );

        allVals["lpv3_" + String(i)] = {
          ...ownedPairsV3[i],
          v3Price: totalValue.toFixed(),
          balance: totalValue.toFixed(),
          liqAmounts,
          v3: true,
        };

        for(let k in allPairs){
          if(allPairs[k].id.toLowerCase() ==  allVals["lpv3_" + String(i)].address.toLowerCase()){
            allVals["lpv3_" + String(i)] = {
              ...allPairs[k],
              ...allVals["lpv3_" + String(i)]
            }
          }
        }
      }
    }

    return Object.values(allVals)
      .filter((asset) => +asset.balance)
      .map((asset) => ({
        ...asset,
      }))
      .sort((a: any, b: any) => {
        let _aPrice = 0;
        let _bPrice = 0;
        if (a.hasLiq == true) {
          _aPrice = new BigNumber(a.assetAAmount)
            .times(a.assetAPrice)
            .plus(new BigNumber(a.assetBAmount).times(a.assetBPrice))
            .toNumber();
        } else if (a.v3) {
          _aPrice = new BigNumber(a.v3Price).toNumber();
        }

        if (b.hasLiq == true) {
          _bPrice = new BigNumber(b.assetAAmount)
            .times(b.assetAPrice)
            .plus(new BigNumber(b.assetBAmount).times(b.assetBPrice))
            .toNumber();
        } else if (b.v3) {
          _bPrice = new BigNumber(b.v3Price).toNumber();
        }

        return _bPrice - _aPrice;
      });
  }, [ownedPairs, ownedPairsV3]);


  const getTrendingPairs = React.useMemo(() => {
    var sortedTopPairs = Object.values(allPairs).sort((a: any, b: any) => {
        let _aPrice = typeof a.ampAPY == 'string' ? new BigNumber(a.apy).plus(a.ampAPY).toFixed(2) : (a.apy ? a.apy : 0)
        let _bPrice = typeof b.ampAPY == 'string' ? new BigNumber(b.apy).plus(b.ampAPY).toFixed(2) : (b.apy ? b.apy : 0)

        return _bPrice - _aPrice;
    });

    if(sortedTopPairs.length > 0){
        let _items = [0,1,2,3,4,5]
        return _items.map((i) => {
          var item = sortedTopPairs[i]
          const assetAInfo = getTokenFromContract(item.token0.id);
          const assetBInfo = getTokenFromContract(item.token1.id);
          return <TrendingPoolV3
            key={0}
            onManageClick={() => manageLiquidity(item.token0.id, item.token1.id, 0, item.feeTier)}
            icon0={assetAInfo.logo}
            icon1={assetBInfo.logo}
            token0={assetAInfo && assetAInfo.symbol ? assetAInfo.symbol : item.token0.symbol}
            token1={assetBInfo && assetBInfo.symbol ? assetBInfo.symbol : item.token1.symbol}
            reserveA={item.totalValueLockedToken0}
            reserveB={item.totalValueLockedToken1}
            fee={item.feeTier ? new BigNumber(item.feeTier).div(10000).toFixed(2) : new BigNumber(item.pairFee).div(100).toFixed(2) }
            apy={typeof item.ampAPY == 'string' ? item.apy + "-" + new BigNumber(item.apy).plus(item.ampAPY).toFixed(2) : item.apy}
            tvl={item.totalValueLockedUSD ? item.totalValueLockedUSD : item.reserveUSD}
            rewardTokens={item.rewardTokens ? item.rewardTokens : []}
            dark={true}
            v3={item.feeTier ? true : false}
            stable={item.stable ? true : false}
          />
        })
    } else {
      let _items = [0,1,2,3,4,5]
      return _items.map((i) => {
        return <TrendingPoolV3
          key={0}
          loading
          onManageClick={() => {}}
          icon0={""}
          icon1={""}
          token0=""
          token1={""}
          reserveA={""}
          reserveB={""}
          fee={""}
        />
      })
      return <></>
    }
  }, [allPairs])

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1080 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1080, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 1080, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  return (
    <Box vertical>
      <div>

      </div>
        <Flex  column gap={3}>
          <Text>Trending Pools <AiFillFire/></Text>
          <Carousel
              swipeable={true}
              draggable={false}
              showDots={true}
              responsive={responsive}
              ssr={false} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={5000}
              customTransition="all .5"
              transitionDuration={500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-0-px"
            >
              {getTrendingPairs}
            </Carousel>
          {/*<Flex gap={2}>
         getTrendingPools()
          </Flex>*/}
        </Flex>

        <FlexMod justify="flex-end">
            <Tabs
              onSelect={(val) => {setSectionLocal(val)}}
              selected={section}
              small
              tabs={[
                {value: "a", label: "Top Pools"},
                {value: "b", label: "My Liquidity"},
              ]}
            />
            <BreakPadding/>

            <AddLiquidity
              externalOpen={addLiqModal}
              assets={tokens}
              addLiquidity={addLiquidity}
              calculateLiquidity={calculateLiquidity}
              manageLiquidity={manageLiquidity}
            />
          </FlexMod>

        {section == "a" ? (
          <AllPoolsWidget ownedPairs={sortedAllLiq} manageLiquidity={manageLiquidity} isLoading={isLoadingAll} />
        ) : (
          <YourLiquidityWidget ownedPairs={sortedLiq} manageLiquidity={manageLiquidity} isLoading={isLoading} />
        )}

      <LiquidityRewardsWidget isVisible={false} onClose={() => {}} />
    </Box>
  );
};

const BreakPadding = styled.div`
  min-width: 32px;
  max-width: 64px;
  min-height: 12px;
  display: block;

  ${layoutMq(`
    display: block;
  `)}
`;

const FlexMod = styled(Flex)`
  ${layoutMq(`
    flex-direction: column;
    align-items: flex-end;
  `)}
`;