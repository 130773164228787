const base = 16 / 4;

export type PaddingString = `${number}${` ${number}` | ""}${` ${number}` | ""}${` ${number}` | ""}`;
function sizeFn(size: PaddingString);
function sizeFn(size: number);
function sizeFn(vertical: number, horizontal: number);
function sizeFn(top: number, horizontal: number, bottom: number);
function sizeFn(top: number, right: number, bottom: number, left: number);
function sizeFn(a: number | string, b?: number, c?: number, d?: number): string {
  if (typeof a === "string") {
    [a, b, c, d] = a.split(" ") as any as number[];
  }
  return [a, b, c, d]
    .map((_) => _ && _ * base + "px")
    .join(" ")
    .trim();
}

export const size = Object.assign(sizeFn, {
  raw: ((...args: Parameters<typeof sizeFn>) => +size(...args).replace("px", "")) as typeof sizeFn,
  gap: (gapSize: number) => `gap: ${sizeFn(gapSize)};`,
  padding: ((...args: Parameters<typeof sizeFn>) => `padding: ${size(...args)};`) as typeof sizeFn,
  margin: ((...args: Parameters<typeof sizeFn>) => `margin: ${size(...args)};`) as typeof sizeFn,
});
