import {toIcon} from "./icon.shared";

export const coinKoi = toIcon(
  <svg width="30" height="31" viewBox="5 6 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 11C7.76124 11 10 8.76123 10 6C10 8.76123 12.2388 11 15 11C15 13.7612 12.7613 16 10 16C7.23878 16 5 13.7612 5 11Z"
      fill="currentColor"
    ></path>
    <path
      d="M20 6C20 8.76123 22.2388 11 25 11C22.2388 11 20 13.2388 20 16C17.2388 16 15 13.7612 15 11C15 8.23877 17.2388 6 20 6Z"
      fill="currentColor"
    ></path>
    <path
      d="M25 21C22.2388 21 20 23.2388 20 26C20 23.2388 17.7612 21 15 21C15 18.2388 17.2388 16 20 16C22.7613 16 25 18.2388 25 21Z"
      fill="currentColor"
    ></path>
    <path
      d="M10 26C10 23.2388 7.76124 21 5 21C7.76124 21 10 18.7612 10 16C12.7613 16 15 18.2388 15 21C15 23.7612 12.7613 26 10 26Z"
      fill="currentColor"
    ></path>
  </svg>,
  true,
);
