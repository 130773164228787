import {PropsWithChildren} from "react";
import styled from "styled-components";

import {card, flex, getColor, mq, palette, PaletteColor, size, text, transition} from "../../styles";

export const Overlay = styled.div<{open?: boolean}>`
  ${transition.fast}

  background: ${palette.gray300.o(0.8)};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  transition-delay: 0s;

  ${(_) =>
    !_.open &&
    `
    pointer-events: none;
    opacity: 0;
    transition-delay: 0s;
  `}
`;

export const Card = styled.div<{maxWidth?: number; open?: boolean; maxHeight?: number}>`
  ${transition.fast}
  ${card("xl")}
  ${size.padding(8)}

  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: ${(_) => _.maxWidth || 480}px;
  max-height: ${(_) => _.maxHeight || 650}px;
  overflow: hidden;
  transform: translateX(-50%) translateY(-50%) scale(0.8);
  opacity: 0;

  ${(_) =>
    _.open &&
    `
    transform: translateX(-50%) translateY(-50%) scale(1);
    opacity: 1;
  `}

  ${(_) =>
    mq(
      600,
      `
    top: initial;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    transform: translateY(50%);

    ${
      _.open &&
      `
      transform:translateY(0);
    `
    }
  `,
    )}
`;

export const Close = styled.div`
  position: absolute;
  top: ${size(5)};
  right: ${size(5)};
  cursor: pointer;
`;

export const Content = styled.div<{scroll?: boolean}>`
  overflow: auto;
  overflow-y: hidden;

  max-height: min(600px, calc(100vh - ${size(16)}));

  ${(_) =>
    _.scroll &&
    `
    overflow-y: scroll;
  `}
`;

// Content elements
export const Rows = styled.div`
  ${size.margin(8, 0)}
  ${size.gap(3)}

  display: flex;
  flex-direction: column;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Row = styled(({title, children, ...rest}: PropsWithChildren<{title: string; color?: PaletteColor}>) => (
  <div {...rest}>
    <div>{title}</div>
    <span>{children}</span>
  </div>
))`
  ${flex("center", "space-between")}
  ${text.bodyRegular}

  & > div {
    color: ${palette.gray400};
  }
  & > span {
    color: ${(_) => (_.color ? getColor[_.color] : palette.black)};
  }
`;
