export const fetchGraphQL = (url: string, query: string, variables: Record<string, any> | null = null) =>
  fetch(url, {
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({
      query,
      variables,
    }),
    method: "POST",
  }).then((_) => _.json());
