import {useEffect, memo} from "react";
import {useLocation} from "react-router-dom";
import {useUi} from "../-provider";

export const PaletteRouterDetector = memo(() => {
  const {pathname} = useLocation();
  const ui = useUi();
  useEffect(() => {
    if (pathname.match(/^\/farm/)) {
      ui.setColor("purple");
    } else if (pathname.match(/^\/bonds1/)) {
      ui.setColor("aqua");
    } else {
      ui.setColor("green");
    }
  }, [pathname]);
  return null;
});
