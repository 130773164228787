import styled from "styled-components";
import {transition} from "../../styles";
import {Text} from "../text/Text";

export const Section = styled.div`
  ${transition.fast}

  padding: 16px 24px;
  background: rgb(var(--color-gray50));
  border-radius: 16px;

  border: 1px solid var(--color-blue-accent);

  :hover {
    border: 1px solid rgb(var(--color-gray100));
  }

  @media (max-width: 600px) {
    padding: 16px;
  }
`;

export const SectionWithBorder = styled(Section)`
  position: relative;
  border: 1px solid rgb(var(--color-gray200));
`;

export const SectionText = styled(Text)`
  font-size: 12px;
  color: var(--color-dark);
  cursor: pointer;
`;

export const SectionTextHeader = styled(Text)`
  font-size: 16px;
  color: white;
  font-weight: 500;
`;

export const SectionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin-top: 0px;
  }
`;
