import styled from "styled-components";
import {Loading} from "../../../../../ui/components";
import { card, flex, palette, size, text} from "../../../../../ui/styles";

export const Wrapper = styled.div`
  ${card("xl")}
  ${size.padding(5)}

  overflow: hidden;
  position: relative;

  ${Loading} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`;

export const Title = styled.h3`
  ${text.h7}

  margin-bottom: ${size(2)};
`;

export const TitleValue = styled.h3`
  ${text.h3}
`;

export const TitleValueSubtitle = styled.h3`
  ${text.bodySmall}

  color: ${palette.gray400};
`;

export const LegendRow = styled.div`
  ${flex("center")}
  ${size.gap(1)}
  ${text.bodySmall}

  span {
    color: ${palette.gray400};
  }
`;
export const LegendColor = styled.span<{alt?: boolean}>`
  height: 10px;
  width: 10px;
  border-radius: 3px;
  background: ${(_) => (_.alt ? palette.ultramoss : palette.lavender)};
`;

export const Chart = styled.div<{loading?: boolean}>`
  ${size.margin("6 0 3 5")};

  height: 180px;
  position: relative;

  ${(_) =>
    _.loading &&
    `
    ${flex("center", "center")}
  `}
`;

export const XAxis = styled.div`
  ${flex("center", "space-between")}
  ${text.bodyMicro}

  pointer-events: none;
  position: absolute;
  top: 100%;
  left: ${size(-2)};
  right: ${size(-2)};
  height: ${size(8)};
  color: ${palette.gray400};
  z-index: 3;

  span {
    ${flex("center", "center")}

    white-space: nowrap;
    text-align: center;
  }
`;

export const YAxis = styled.div`
  ${flex("center", "space-between", "column-reverse")}
  ${text.bodyMicro}

  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  width: ${size(10)};
  color: ${palette.gray400};
  z-index: 3;

  span {
    ${flex("center", "center")}

    width: 0;
    white-space: nowrap;
    text-align: center;
  }
`;
