import {RefObject, useEffect, useRef, useState} from "react";

const initial = {width: 0, height: 0};
export const useContainerSize = (containerRef: RefObject<HTMLElement | null | undefined>) => {
  const sizeRef = useRef(initial);
  const [{width, height}, setSizes] = useState(initial);

  useEffect(() => {
    if (!containerRef.current) return;
    const update = () => {
      const rect = containerRef.current!.getBoundingClientRect();
      const sizes = {width: rect.width, height: rect.height};
      setSizes(sizes);
      Object.assign(sizeRef.current, sizes);
    };
    update();
    const observer = new ResizeObserver(update);
    observer.observe(containerRef.current!);
    return () => {
      observer.disconnect();
    };
  }, [containerRef]);

  return {
    width,
    height,
    sizes: [width, height],
    sizeRef,
  };
};
