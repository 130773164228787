export enum ErrorCodes {
  noRoutesFound = 1,
  canNotFindChainId = 2,
  tokenChainIdContractDoesNotExist = 3,
  tradePathIsNotSupported = 4,
  generateApproveMaxAllowanceDataNotAllowed = 5,
  fromTokenContractAddressRequired = 6,
  fromTokenContractAddressNotValid = 7,
  toTokenContractAddressRequired = 8,
  toTokenContractAddressNotValid = 9,
  ethereumAddressRequired = 10,
  ethereumAddressNotValid = 11,
  invalidPairContext = 12,
  invalidFromOrToContractToken = 13,
  muteswitchVersionNotSupported = 14,
  muteswitchVersionsMustNotBeAnEmptyArray = 15,
  canNotFindProviderUrl = 16,
  wrongEthersProviderContext = 17,
  chainIdNotSupported = 18,
  chainIdCanNotBeFound = 19,
  muteswitchVersionsUnsupported = 20,
}
