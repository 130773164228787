import styled from "styled-components";

import {size} from "../../styles";

type DividerProps = {
  size: number | string;
  horizontal?: boolean;
};

export const Divider = styled.div<DividerProps>`
  ${(_) =>
    _.horizontal &&
    `
    display: inline-flex;
  `}

  ${(_) =>
    _.size &&
    `
    min-${!_.horizontal ? "height" : "width"}: ${size(+_.size)};
    max-${!_.horizontal ? "height" : "width"}: ${size(+_.size)};
  `}
`;
