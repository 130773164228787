import styled from "styled-components";

import {card, palette, size} from "../../styles";

export const Wrapper = styled.div<{active?: boolean}>`
  ${card("s", true)}
  ${size.padding(2)}

  padding-left: 30px;
  width: 100%;
  overflow: hidden;
  position: relative;
  min-height: 56px;
  display: flex;
  align-items: center;

  & > svg {
    color: ${palette.gray50};
    position: absolute;
    top: 50%;
    left: -16px;
    transform: translateY(-50%);
  }

  & > div {
    position: relative;
    z-index: 2;
    width: 100%;
  }
`;
