import React, {useState} from "react";
import styled from "styled-components";
import {useHistory, useParams} from "react-router-dom";
import BigNumber from "bignumber.js";
import {Grid} from "../../common/layout/Grid";
import AddIcon from "@mui/icons-material/Add";
import LaunchIcon from "@mui/icons-material/Launch";

import angleIcon from "../../assets/images/icons/angle-bright.svg";
import {ShadowWidget} from "../../common/ShadowWidget";

import {reduceString} from "../../utils/reduceString";
import {formatNumber, formatNumberSmallDecimal} from "../../utils/formatNumber";
import {LoadingBox} from "../../common/loading/LoadingBox";

import {
  Box,
  Modal as CustomModal,
  CardContent,
  InputCoin,
  Tabs,
  Button,
  LavaChip,
  IconAsset,
  Text,
  lavaEffectHoverClassName,
  Flex,
  Message,
  Divider,
  Loading,
  Empty,
  layoutMq,
  Title,
  TitleDash,
} from "../../ui/components";

import {Row} from "../../common/Row";

import {AmplifierV2StakeInfo} from "./AmplifierV2StakeInfo";

import {useWalletHook} from "../../web3/walletHook";
import {WalletState} from "../../state";
import {useWalletSelector} from "../../state/hooks";
import {br, palette, size, text} from "../../ui/styles";
import {sendTransactionGaEvent} from "../../utils";
import {LPOnFarmBanner} from "./FarmToLPBanner";
import {TxModal} from "../+common/TxModal";

const queryString = require("query-string");

interface ParamTypes {
  poolId: string;
}

enum Modal {
  Confirm,
  Deposit,
}

export const AmplifierV2SubScreen = ({poolInfo}) => {
  const history = useHistory();
  const {poolId} = useParams<ParamTypes>();

  const {
    stakeInAmplifier,
    withdrawFromAmplifier,
    payoutFromAmplifierV2,
    getAmplifierV2Info,
    getAmplifierV2Pools,
    getAmplifierV2Multiplier,
  } = useWalletHook<true>();

  const [assetA, setAssetA] = useState<any>(undefined);
  const [assetB, setAssetB] = useState<any>(undefined);
  const category = [assetA!?.symbol, assetB!?.symbol].join();

  const gasRefund = useWalletSelector((state: WalletState) => state.gasEstimateRefund);
  const status = useWalletSelector((state: WalletState) => state.transactionModalStatus);

  const [assetAAmount, setAssetAAmount] = useState("0");
  const [estimateAmount, setEstimateAmount] = useState("0");

  const [poolAPY, setPoolAPY] = useState("0");
  const [inputMultiplier, setInputMultiplier] = useState("1.00");
  const [inputPayout, setInputPayout] = useState("0.00");

  const [isDeposit, setIsDeposit] = useState(0);
  const [calculating, setCalculating] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const tokens = useWalletSelector((state: WalletState) => state.tokens);
  const ampInfo = useWalletSelector((state: WalletState) => state.amplifiersv2);
  const ownedLiq = useWalletSelector((state: WalletState) => state.ownedPairs);

  const [poolData, setPoolData] = useState<any | null>(null);

  const [modal, setModal] = useState<Modal | null>(null);

  const RetrieveInfo = () => {
    for (let i in ampInfo) {
      if (ampInfo[i].id?.toLowerCase() == poolId?.toLowerCase()) {
        if (ampInfo[i].success == false) break;

        let poolInfo = {
          ...ampInfo[i],
        };

        for (let k in ownedLiq) {
          if (ampInfo[i].pair?.toLowerCase() == ownedLiq[k].address?.toLowerCase())
            poolInfo.balance = ownedLiq[k].balance;
        }

        setPoolData(poolInfo);

        setIsLoading(false);
        setPoolAPY(new BigNumber(ampInfo[i].apy).times(2).toFixed(2) + "%");
        break;
      }
    }
  };

  React.useEffect(() => {
    RetrieveInfo();
  }, []);

  React.useEffect(() => {
    if (poolData) {
      setAssetA(tokens[poolData.token0.address?.toLowerCase()]);
      setAssetB(tokens[poolData.token1.address?.toLowerCase()]);
    }
    
    return () => {};
  }, [poolData]);

  React.useEffect(() => {
    if (poolData) {
      setAssetA(tokens[poolData.token0.address?.toLowerCase()]);
      setAssetB(tokens[poolData.token1.address?.toLowerCase()]);
    }

    RetrieveInfo();

    return () => {};
  }, [ampInfo, ownedLiq]);

  React.useEffect(() => {
    if (assetAAmount && poolData) {
      getAmplifierV2Multiplier(poolId, new BigNumber(assetAAmount).times(Math.pow(10, 18)).toFixed()).then((res) => {
        setInputMultiplier(res.multiplier);
        setInputPayout(res.payout);
      });
    }

    return () => {};
  }, [assetAAmount]);

  const getDepositButtonStatus = () => {
    if (poolData && poolData.approved) {
      if (new BigNumber(poolData.approved).lt(assetAAmount)) {
        return "Approve";
      }
    }

    if (poolData) {
      if (new BigNumber(poolData.maxDeposit).lt(inputPayout)) {
        return "Not enough rewards";
      }
    }

    if (new BigNumber(assetAAmount).lte(0)) return "Invalid amounts";

    if (assetA && assetB) {
      if (new BigNumber(assetAAmount).gt(poolData.balance)) return "Invalid amounts";
    }

    return "Stake LP";
  };

  const getWithdrawButtonStatus = () => {
    if (new BigNumber(poolData ? poolData.totalUserStake : 0).lte(0)) return "Unavailable";

    return "Withdraw LP";
  };

  const getCollectStatus = () => {
    if (poolData && new BigNumber(poolData.endTime).lt(Date.now() / 1000)) {
      return "Collect & Unstake";
    }
    return "Collect";
  };

  const shouldDisable = () => {
    if (getDepositButtonStatus() == "Stake LP" || getDepositButtonStatus() == "Approve") return false;

    return true;
  };

  const shouldDisableWithdraw = () => {
    if (getWithdrawButtonStatus() == "Withdraw LP") return false;

    return true;
  };

  const stakeInAmplifierLocal = async () => {
    setCalculating(true);
    setModal(Modal.Deposit);
    const res = await sendTransactionGaEvent("add_farm_transaction", category, () => {
      return stakeInAmplifier(poolId, assetAAmount, poolData.pair, true);
    });
    RetrieveInfo();
    setAssetAAmount("0");
    setCalculating(false);
    setModal(null);
  };

  const withdrawLiquidity = async () => {
    setCalculating(true);
    setModal(Modal.Confirm);
    const res = await sendTransactionGaEvent("remove_farm_transaction", category, () => {
      return withdrawFromAmplifier(poolId);
    });
    await getAmplifierV2Info();
    RetrieveInfo();
    setCalculating(false);
    setModal(null);
  };

  const payoutFromAmplifierLocal = async () => {
    setCalculating(true);
    setModal(Modal.Confirm);
    const res = await sendTransactionGaEvent("collect_farm_transaction", category, () => {
      return payoutFromAmplifierV2(poolId);
    });
    await getAmplifierV2Info();
    RetrieveInfo();
    setCalculating(false);
    setModal(null);
  };

  const changeAssetA = (val) => {
    setAssetAAmount(val);
  };

  const getROICalculator = () => {
    let apy = new BigNumber(poolData.multiplier).times(poolData.baseAPY).plus(poolData.lpAPY).div(100);
    return apy
      .times(estimateAmount)
      .div(365)
      .times(30)
      .minus(new BigNumber(estimateAmount).times(poolData.managementFee).div(10000))
      .toFixed(2);
  };

  const getROICalculatorLP = () => {
    let apy = new BigNumber(poolData.lpAPY).div(100);
    return apy.times(estimateAmount).div(365).times(30).toFixed(2);
  };

  const getROICalculatorAmp = () => {
    let apy = new BigNumber(poolData.multiplier).times(poolData.baseAPY).div(100);
    return apy.times(estimateAmount).div(365).times(30).toFixed(2);
  };

  const getMultiplier = async () => {
    return getAmplifierV2Multiplier(poolData.poolId, new BigNumber(assetAAmount).times(Math.pow(10, 18)).toFixed());
  };

  return (
    <Box noBreak>
      <Layout>
        <Title
          style={{gridArea: "titlecontainer"}}
          breakpoint="800"
          goBackLink="/farm"
          loading={isLoading || !assetA || !assetB}
          title={
            <>
              {assetA ? assetA.symbol : "---"} <TitleDash /> {assetB ? assetB.symbol : "---"}
            </>
          }
          chip={`${poolData && poolData.stable ? " sMLP" : " vMLP"} Liquidity`}
        />

        <div style={{gridArea: "exchange"}}>
          <LPOnFarmBanner
            hasLP={!!+poolData?.balance}
            tokenAddressA={assetA?.address}
            tokenAddressB={assetB?.address}
          />
          <CardContent>
            <DataContainerColumn>
              <Box>
                <Tabs
                  onSelect={(val) => setIsDeposit(val)}
                  standalone
                  full
                  tabs={[
                    {value: 0, label: "Stake"},
                    {value: 1, label: "Unstake"},
                    {value: 2, label: "Info"},
                  ]}
                />
              </Box>

              <Spacer />
              <Spacer />

              {isDeposit == 0 ? (
                <>
                  <ShadowWidget>
                    <InputCoin
                      value={assetAAmount}
                      setValue={setAssetAAmount}
                      disableEdit
                      selectedAsset={
                        {
                          assets: [assetA, assetB].filter((_) => !!_),
                        } as any
                      }
                      price={poolData ? poolData.lpPrice : 0}
                      balance={poolData ? poolData.balance : 0}
                    />
                  </ShadowWidget>
                  <Spacer />
                  <Spacer />
                  <Spacer />

                  <StatRow>
                    <StatText bodyRegular color="gray400">
                      Farm fee
                    </StatText>
                    <StatTextHighlight bodyMedium>
                      {poolData ? new BigNumber(poolData.managementFee).div(100).toFixed(2) : "0.00"}%
                    </StatTextHighlight>
                  </StatRow>
                  <StatRow>
                    <StatText bodyRegular color="gray400">
                      Applied veKOI multiplier
                    </StatText>
                    <StatTextHighlight bodyMedium>{poolData ? inputMultiplier : "1.00"}x</StatTextHighlight>
                  </StatRow>
                  <StatRow>
                    <StatText bodyRegular color="gray400">
                      {poolData ? poolData.payoutToken.symbol : "KOI"} APY
                    </StatText>
                    <StatTextHighlight bodyMedium>
                      {poolData ? new BigNumber(poolData.baseAPY).times(inputMultiplier).toFixed(0) + "%" : poolAPY}
                    </StatTextHighlight>
                  </StatRow>
                  <StatRow>
                    <StatText bodyRegular color="gray400">
                      Combined APY (Farm + LP)
                    </StatText>
                    <StatTextHighlight bodyMedium>
                      {poolData
                        ? new BigNumber(poolData.baseAPY).times(inputMultiplier).plus(poolData.lpAPY).toFixed(0) + "%"
                        : poolAPY}
                    </StatTextHighlight>
                  </StatRow>
                  <StatRow>
                    <StatText bodyRegular color="gray400">
                      {poolData ? poolData.payoutToken.symbol : "KOI"} Rewards
                    </StatText>
                    <StatTextHighlight bodyMedium>
                      {poolData ? inputPayout : "0.00"} {poolData ? " " + poolData.payoutToken.symbol : " KOI"}
                    </StatTextHighlight>
                  </StatRow>
                  <Button
                    checkNetwork
                    loading={calculating}
                    color="orchid"
                    onClick={() => stakeInAmplifierLocal()}
                    disabled={shouldDisable()}
                  >
                    {getDepositButtonStatus()}
                  </Button>
                </>
              ) : isDeposit == 1 ? (
                <>
                  <ShadowWidget>
                    <InputCoin
                      value={Number(poolData ? poolData.totalUserStake : 0)}
                      setValue={() => {}}
                      assets={[]}
                      disableEdit={true}
                      selectedAsset={
                        {
                          balance: new BigNumber(poolData ? poolData.balance : 0).toFixed(),
                          assets: [assetA, assetB].filter((_) => !!_),
                          decimals: 18,
                        } as any
                      }
                      onAssetSelect={() => {}}
                      price={poolData ? poolData.lpPrice : 0}
                      balance={poolData ? poolData.totalUserStake : 0}
                      disabled={true}
                    />
                  </ShadowWidget>
                  <Spacer />
                  <Spacer />
                  <Spacer />

                  <StatRow>
                    <Message error>
                      Withdrawing will forfeit any future yield for your deposits. If you want to claim rewards and
                      redeem expired deposits, use the 'collect' button.
                    </Message>
                  </StatRow>

                  <Button checkNetwork loading={calculating} onClick={() => withdrawLiquidity()} disabled={shouldDisableWithdraw()}>
                    {getWithdrawButtonStatus()}
                  </Button>
                </>
              ) : (
                <>
                  {poolData && poolData.user_stakes.length > 0 ? (
                    poolData.user_stakes.map((item, i) => (
                      <AmplifierV2StakeInfo
                        key={i}
                        info={item}
                        onRedeem={(res) => {}}
                        symbol={poolData.payoutToken.symbol ? poolData.payoutToken.symbol : ""}
                      />
                    ))
                  ) : (
                    <Flex grow expandVertical>
                      <Divider size={80} />
                      <Empty title="No deposits available" />
                      <Divider size={80} />
                    </Flex>
                  )}
                </>
              )}
            </DataContainerColumn>
          </CardContent>
          <Separator />
          <CardContent>
            <DataContainerColumn>
              <ContainerText h6>Rewards</ContainerText>

              <Flex>
                <RewardText>Farm Rewards</RewardText>
                {isLoading == true && !poolData ? (
                  <></>
                ) : (
                  <Rewards>
                    <RewardBody h6 color="black">
                      <IconAsset asset={poolData.payoutToken} button withBorder size={"xs"} />
                      {poolData
                        ? new BigNumber(poolData.currentClaimable).toFixed(2) + " " + poolData.payoutToken.symbol
                        : "0.00"}
                    </RewardBody>
                  </Rewards>
                )}
              </Flex>
              <Divider size={2} />

              <Flex>
                <RewardText>LP Reward</RewardText>
                {isLoading == true && !poolData ? (
                  <></>
                ) : (
                  <Rewards>
                    <RewardBody h6 color="black">
                      <IconAsset asset={assetA} button withBorder size={"xs"} />
                      {poolData && assetA
                        ? formatNumber(
                            new BigNumber(poolData.currentClaimableFee0).div(Math.pow(10, assetA.decimals)).toFixed(),
                            2,
                          )
                        : "0.00"}{" "}
                      {assetA ? assetA.symbol : ""}
                    </RewardBody>

                    <RewardBody h6 color="black">
                      <IconAsset asset={assetB} button withBorder size={"xs"} />
                      {poolData && assetB
                        ? formatNumber(
                            new BigNumber(poolData.currentClaimableFee1).div(Math.pow(10, assetB.decimals)).toFixed(),
                            2,
                          )
                        : "0.00"}{" "}
                      {assetB ? assetB.symbol : ""}
                    </RewardBody>
                  </Rewards>
                )}
              </Flex>
              <Divider size={6} />

              <Button
                checkNetwork
                alt
                loading={calculating}
                onClick={() => payoutFromAmplifierLocal()}
                disabled={poolData ? new BigNumber(poolData.currentClaimable).lte(0) : true}
              >
                {getCollectStatus()}
              </Button>
            </DataContainerColumn>
          </CardContent>
        </div>

        <Flex gap={3} style={{gridArea: "pool_stats"}} column className={lavaEffectHoverClassName}>
          <LavaChip
            heading="Total Staked"
            palette="purple"
            value={
              poolData ? "$" + formatNumber(new BigNumber(poolData.lpPrice).times(poolData.totalStaked), 2) : "$0.00"
            }
            loading={isLoading}
            alt={1}
            leader
          />

          <LavaChip
            heading="APY"
            palette="purple"
            value={
              poolData
                ? new BigNumber(poolData.baseAPY).plus(poolData.lpAPY).toFixed(0) +
                  "% to " +
                  new BigNumber(poolData.maxAPY).plus(poolData.lpAPY).toFixed(0) +
                  "%"
                : poolAPY
            }
            loading={isLoading}
            alt={2}
            follower
          />
        </Flex>

        <DataContainer area="my_stats">
          <DataContainerColumn>
            <ContainerText h6>My stats</ContainerText>
            <StatRow>
              <StatText bodyRegular color="gray400">
                Current LP balance
              </StatText>
              <StatTextHighlight bodyMedium>
                {isLoading == true ? (
                  <LoadingBox />
                ) : (
                  (poolData ? formatNumber(poolData.balance, 2) + " " : "0") +
                  (assetA ? assetA.symbol + "/" : "") +
                  (assetB ? assetB.symbol : "") +
                  (poolData && poolData.stable ? " sMLP" : " vMLP")
                )}
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText bodyRegular color="gray400">
                Total staked amount
              </StatText>
              <StatTextHighlight bodyMedium>
                {isLoading == true ? (
                  <LoadingBox />
                ) : (
                  (poolData ? formatNumber(poolData.totalStaked, 2) + " " : "0") +
                  (assetA ? assetA.symbol + "/" : "") +
                  (assetB ? assetB.symbol : "") +
                  (poolData && poolData.stable ? " sMLP" : " vMLP")
                )}
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText bodyRegular color="gray400">
                Underlying assets
              </StatText>
              <StatTextHighlight bodyMedium>
                {isLoading == true ? (
                  <LoadingBox />
                ) : (
                  (poolData
                    ? formatNumber(poolData.underlyingAssetA != "NaN" ? poolData.underlyingAssetA : "0", 2) + " "
                    : "0") +
                  (assetA ? assetA.symbol + " / " : "") +
                  (poolData
                    ? formatNumber(poolData.underlyingAssetB != "NaN" ? poolData.underlyingAssetB : "0", 2) + " "
                    : "0") +
                  (assetB ? assetB.symbol : "")
                )}
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText bodyRegular color="gray400">
                Pool share
              </StatText>
              <StatTextHighlight bodyMedium>
                {isLoading == true ? (
                  <LoadingBox />
                ) : poolData ? (
                  new BigNumber(poolData.totalUserStake)
                    .div(poolData.totalStaked == "0" ? 1 : poolData.totalStaked)
                    .times(100)
                    .toFixed(2) + "%"
                ) : (
                  "0%"
                )}
              </StatTextHighlight>
            </StatRow>
          </DataContainerColumn>
        </DataContainer>

        <DataContainer area="pool_details">
          <DataContainerColumn>
            <ContainerText h6>Farm details</ContainerText>
            <StatRow>
              <StatText bodyRegular color="gray400">
                Min - Max APY
              </StatText>
              <StatTextHighlight bodyMedium color="success">
                {isLoading == true ? (
                  <LoadingBox />
                ) : poolData ? (
                  new BigNumber(poolData.baseAPY).plus(poolData.lpAPY).toFixed(2) +
                  "% - " +
                  new BigNumber(poolData.maxAPY).plus(poolData.lpAPY).toFixed(2) +
                  "%"
                ) : (
                  "0.00%"
                )}
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText bodyRegular color="gray400">
                Farm veKOI multiplier
              </StatText>
              <StatTextHighlight bodyMedium>
                {isLoading == true ? (
                  <LoadingBox />
                ) : poolData ? (
                  new BigNumber(poolData.multiplier).toFixed(2) + "x"
                ) : (
                  "0.00x"
                )}
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText bodyRegular color="gray400">
                Vault address
              </StatText>
              <StatTextHighlight bodyMedium>
                {isLoading == true ? <LoadingBox /> : poolData ? reduceString(poolData.id, 7, 4) : ""}
                <LaunchIcon
                  style={{width: "15px", marginLeft: "5px", color: "rgb(var(--color-orchid))", cursor: "pointer"}}
                  onClick={() => {
                    window.open("https://era.zksync.network/address/" + poolData.id, "_blank");
                  }}
                />
              </StatTextHighlight>
            </StatRow>
          </DataContainerColumn>
        </DataContainer>
      </Layout>

      <CustomModal title="Collect Rewards" isVisible={modal === Modal.Confirm} onClose={() => setModal(null)}>
        <Footer>
          <div>
            <MainModalInfo>
              <StatTextHighlight
                style={{
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <AssetImage contrast asset={poolData ? poolData.payoutToken : null} />
                {formatNumberSmallDecimal(poolData ? poolData.currentClaimable : 0, 2)}
                <Divider size={1} />
                <Text color="gray400">{poolData ? poolData.payoutToken.symbol : "KOI"}</Text>
              </StatTextHighlight>
              <AddIcon style={{color: "black", marginLeft: "auto", marginRight: "auto"}} />
              <StatTextHighlight
                style={{
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <AssetImage contrast asset={assetA ? assetA : null} />
                {formatNumberSmallDecimal(
                  poolData && assetA
                    ? new BigNumber(poolData.currentClaimableFee0).div(Math.pow(10, assetA.decimals)).toFixed()
                    : 0,
                  2,
                )}
                <Divider size={1} />
                <Text color="gray400">{assetA ? (assetA.symbol == "ETH" ? "WETH" : assetA.symbol) : ""}</Text>
              </StatTextHighlight>
              <AddIcon style={{color: "black", marginLeft: "auto", marginRight: "auto"}} />
              <StatTextHighlight
                style={{
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <AssetImage contrast asset={assetB ? assetB : null} />
                {formatNumberSmallDecimal(
                  poolData && assetB
                    ? new BigNumber(poolData.currentClaimableFee1).div(Math.pow(10, assetB.decimals)).toFixed()
                    : 0,
                  2,
                )}
                <Divider size={1} />
                <Text color="gray400">{assetB ? (assetB.symbol == "ETH" ? "WETH" : assetB.symbol) : ""}</Text>
              </StatTextHighlight>
            </MainModalInfo>
            <TxModal>
              {(w) => (
                <>
                  <Row>
                    <Text bodyRegular color="gray400">
                      Gas Cost Estimate
                    </Text>
                    <Text bodyMedium>{w("$" + gasRefund.actual)}</Text>
                  </Row>
                  <Row>
                    <Text bodyRegular color="gray400">
                      Gas Refund Estimate
                    </Text>
                    <Text bodyMedium color="success">
                      {w("$" + gasRefund.refund)}
                    </Text>
                  </Row>
                </>
              )}
            </TxModal>
          </div>
        </Footer>
        <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Loading linear />
          &nbsp;
          <Text>{status}</Text>
        </div>
      </CustomModal>

      <CustomModal title={"Stake LP"} isVisible={modal === Modal.Deposit} onClose={() => setModal(null)}>
        <Footer>
          <>
            <MainModalInfo>
              <StatTextHighlight
                style={{
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "10px",
                  marginBottom: "10px",
                  width: "100%",
                }}
              >
                <div style={{justifyContent: "center", display: "flex", flexDirection: "row", alignItems: "center"}}>
                  <AssetImage button asset={assetA ? assetA : null} />
                  <AssetImage style={{marginLeft: "-8px"}} button asset={assetB ? assetB : null} />
                </div>

                {(poolData ? formatNumber(assetAAmount, 2) : 0) +
                  " " +
                  (assetA ? assetA.symbol + "/" : "") +
                  (assetB ? assetB.symbol : "") +
                  (poolData && poolData.stable ? " sMLP" : " vMLP")}
              </StatTextHighlight>
            </MainModalInfo>

            <TxModal>
              {(w) => (
                <>
                  <Row>
                    <Text bodyRegular color="gray400">
                      Gas Cost Estimate
                    </Text>
                    <Text bodyMedium>{w("$" + gasRefund.actual)}</Text>
                  </Row>
                  <Row>
                    <Text bodyRegular color="gray400">
                      Gas Refund Estimate
                    </Text>
                    <Text bodyMedium color="success">
                      {w("$" + gasRefund.refund)}
                    </Text>
                  </Row>
                </>
              )}
            </TxModal>
          </>
        </Footer>
        <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Loading linear />
          &nbsp;
          <Text>{status}</Text>
        </div>
      </CustomModal>
    </Box>
  );
};

const TitleContainerWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 30px;
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 52px;

  ${layoutMq(`
    font-size: 28px;
    padding: 10px;
  `)}
`;

const Sep = styled.div`
  background: ${palette.gray400};
  height: 2px;
  width: 30px;
  margin: 0px 16px;

  ${layoutMq(`
    height: 1px;
    width: 16px;
    margin: 0px 8px;
  `)}
`;

const List = styled.div`
  padding-right: 19px;
  min-height: 300px;
  max-height: 325px;
  overflow-y: auto;
`;

const Layout = styled(Grid)`
  grid-template-columns: 3fr 3fr 1fr;
  grid-template-areas:
    "titlecontainer titlecontainer titlecontainer"
    "pool_stats  exchange exchange"
    "my_stats exchange exchange"
    "pool_details exchange exchange"
    "pool_details exchange exchange"
    "pool_details exchange exchange"
    "pool_details exchange exchange";

  row-gap: ${size(3)};
  column-gap: ${size(3)};
  grid-auto-rows: minmax(min-content, max-content);

  & > *:first-child {
    margin-bottom: ${size(-3)};
  }

  @media (max-width: 800px) {
    max-width: 520px;
    margin: auto;
    column-gap: 0px;
    row-gap: 8px;
    grid-template-columns: 1fr;
    grid-template-areas:
      "titlecontainer"
      "pool_stats"
      "apy_stats"
      "exchange"
      "my_stats"
      "pool_details";
  }
`;

const Spacer = styled.div`
  width: 25px;
  height: 6px;
`;

const TitleContainer = styled.div`
  position: relative;
  overflow: hidden;
  grid-area: titlecontainer;
  margin-bottom: 25px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AssetImage = styled(IconAsset)`
  margin-bottom: 10px;
`;

const StatRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 12px;
`;

const StatText = styled(Text)`
  display: flex;
  align-items: center;
  text-align: right;
`;

const StatTextHighlight = styled(Text)`
  text-align: right;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Subtitle = styled.div`
  width: auto;
  padding: 0 10px;
  height: 27px;
  background: rgb(var(--color-white));
  border-radius: 8px;
  display: flex;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  text-align: center;
  justify-content: center;
  align-items: center;

  color: rgb(var(--color-black));
`;

const BackButton = styled.button`
  background: rgba(24, 29, 47, 0.7);
  border-radius: 14px;
  background-image: url(${angleIcon});
  background-size: cover;
  transform: rotate(90deg);

  width: 40px;
  height: 40px;
`;

const DataContainer = styled(CardContent)<{area: string}>`
  grid-area: ${({area}) => area};
  display: flex;
  flex-direction: row;
  height: fit-content;
  justify-content: space-between;
  width: 100%;
`;

const DataContainerAPY = styled.div`
  padding: 20px 20px;
  background: var(--color-modal-blue);
  border-radius: 18px;
  font-size: 6px;
  display: flex;
  flex-direction: row;
  height: fit-content;
  justify-content: space-between;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.05);

  @media (max-width: 600px) {
    padding: 16px;
    flex-direction: column;
  }
`;

const DataContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 80px;
  justify-content: space-between;
  width: 100%;
`;

const ContainerText = styled(Text)`
  padding-bottom: 18px;
`;

const CollectButton = styled(Button)`
  margin-top: 20px;
  width: 100%;
`;

const Separator = styled.div`
  height: 8px;
`;

const Footer = styled.div`
  margin-top: 12px;
  padding-bottom: 25px;
`;

const MainModalInfo = styled(CardContent)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;

const RewardBody = styled(Text)`
  ${size.padding(0, 2, 0, 1)}
  ${br.xs}
  ${size.gap(2)}

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${palette.gray50};
  width: fit-content;
  min-height: 32px;
`;

const Rewards = styled.div`
  ${size.gap(4)}

  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

const RewardText = styled.div`
  ${text.bodyRegular}

  align-self: flex-start;
  white-space: nowrap;
  color: ${palette.gray400};
  min-height: 32px;
  display: flex;
  align-items: center;
  padding-right: ${size(10)};
  min-width: 140px;
`;
