import {PropsWithChildren} from "react";
import styled from "styled-components";

import {br, flex, palette, size, text} from "../../styles";
import {Icon} from "../icon";

interface MessageProps {
  error?: boolean;
  info?: boolean;
}

export const Message = styled(({children, ...props}: PropsWithChildren<MessageProps>) => (
  <div {...props}>
    <Icon icon="errorBox" size="s" />
    <p>{children}</p>
  </div>
))<{error?: boolean}>`
  ${flex()}
  ${text.bodyMedium}
  ${size.gap(3)}
  ${size.padding(3)}
  ${br.s}

  ${(_) => {
    const color = _.error ? palette.error : _.info ? palette.azul : palette.white;
    return `
      color: ${color};
      background: ${_.error ? palette.errorLight : color.o(0.2)};

      svg {
        color: ${color};
      }
    `;
  }};

  ${(_) =>
    !_.error &&
    !_.info &&
    `
    backdrop-filter: blur(4px);
  `}
`;
