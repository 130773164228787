import { useMemo, useState } from "react";
import { Switch } from "../components";
import { microPaletteColors } from "../styles";
import * as SC from "./demo.styles";

export const useSettings = <T extends string>(name: string, options: T[], byDefault?: T, color = false) => {
  const [active, setActive] = useState(byDefault || options[0]);
  const settings = useMemo(
    () =>
      options.map((_) => (
        <SC.Setting active={_ === active} onClick={() => setActive(_)} color={color && (_ as any)}>
          {_}
        </SC.Setting>
      )),
    [options, active],
  );
  const component = useMemo(
    () => (
      <SC.Settings>
        {name}
        {settings}
      </SC.Settings>
    ),
    [name, settings],
  );

  return {
    active,
    component,
  };
};

export const useColor = (name: string) => {
  const {active, component} = useSettings(name, ["none", ...microPaletteColors], "none", true);
  return {component, active: active === "none" ? undefined : active};
};

export const useSwitch = (name: string, byDefault: boolean = false) => {
  const [active, setActive] = useState(byDefault);
  const component = useMemo(
    () => (
      <SC.Settings>
        {name}
        <Switch active={active} setActive={setActive} />
      </SC.Settings>
    ),
    [name, active, setActive],
  );

  return {
    active,
    component,
  };
};