import {memo, ReactNode, useCallback, useEffect, useMemo, useRef, useState} from "react";
import { Divider } from "../divider";
import { Flex } from "../flex";
import { IconSort } from "../icon-sort";
import { Loading } from "../loading";

import * as SC from "./index.styles";

type TableAlign = 'left' | 'center' | 'right';

export interface TableColumnConfig<T extends any> {
  index: string;
  key?: boolean;
  header: ReactNode;
  headerAlign?: TableAlign;
  align?: TableAlign;
  sort?: 'asc' | 'desc';
  value: (obj: T) => string | number;
  content: (obj: T) => ReactNode;
}

export interface TableProps<T extends any> {
  data?: T[];
  config: TableColumnConfig<T>[];
  height?: number | string;
  showLoading?: boolean;
  findKey?: (obj: T) => string;
}

export const toTableConfig = <T extends any>(data: T[], config: TableColumnConfig<T>[]) => config;

export const Table = <T extends any>(props: TableProps<T>) => {
  const {data = [], config = [], showLoading, findKey, ...rest} = props;

  const defaultSorting = useMemo(() => {
    const item = (config.find(_ => _.sort) || config[0]);
    return {
      by: item.index,
      dir: item.sort || 'asc',
    }
  }, [config])

  const [sorting, setSorting] = useState(defaultSorting);
  const scrollableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const elem = scrollableRef.current;
    const handleScroll = () => {
      const scroll = scrollableRef.current!?.scrollTop
      if (scrollableRef.current && scroll !== undefined) {
        scrollableRef.current.classList.toggle('scrolled', scroll > 0)
      }
    }
    handleScroll();
    elem?.addEventListener('scroll', handleScroll);
    return () => {
      elem?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const keyGetter = useMemo(() => findKey || config.find(_ => _.key)?.value, [config]);

  const preRendered = useMemo(() => {
    return data.map((_, i) => {
      const key = keyGetter?.(_) || i
      return {
        row: _,
        content: (
          <tr key={key}>
            {config.map((c, j) => (
              <td key={[key, j].join()} align={c.align}>
                {c.content(_)}
              </td>
            ))}
          </tr>
        )
      };
    })
  }, [data])

  const sorted = useMemo(() => {
    const g = config.find(_ => _.index === sorting.by)?.value!;
    return preRendered
      .slice(0)
      .sort(
        sorting.dir === "asc"
          ? (a, b) => g(a.row) > g(b.row) ? 1 : -1
          : (a, b) => g(a.row) > g(b.row) ? -1 : 1
      )
  }, [sorting, data])

  const changeSorting = useCallback((index: string) => {
    setSorting(current => {
      if (index === current.by) {
        return {
          ...current,
          dir: current.dir === 'asc' ? 'desc' : 'asc',
        };
      }
      return {
        ...current,
        by: index,
      }
    })
  }, [setSorting])

  return (
    <SC.Wrapper {...rest} ref={scrollableRef}>
      {showLoading && !sorted.length ? (
        <SC.Loading>
          <Loading size="xl"/>
        </SC.Loading>
      ) : (
        <table>
          <thead>
            <tr>
              {config.map((_, i) => (
                <th key={i} align={_.headerAlign} onClick={() => changeSorting(_.index)}>
                  <Flex inline>
                    {_.header}
                    <Divider horizontal size={1} />
                    <IconSort dir={_.index === sorting.by ? sorting.dir : undefined}/>
                  </Flex>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sorted.map(_ => _.content)}
          </tbody>
        </table>
      )}
    </SC.Wrapper>
  );
};
