import {CardContent, Button, Text, layoutMq, Bullet} from "../../ui/components";
import LP_CARD from "../../assets/images/lp/lp_card.png";
import bannerMobileImage from "../../assets/images/lava/green-bg-1-m.png";

import styled from "styled-components";
import {flex, palette, size, text} from "../../ui/styles";

export const LiquidityRewardsWidget = ({isVisible, onClose}) => {
  return (
    <>
      <Container>
        <Text h4>How does Liquidity work?</Text>

        <Content>
          <Title>
            <Bullet>1</Bullet>
            Deposit Liquidity
          </Title>

          <BodyText>
            Deposit two tokens to create liquidity in a pool, and receive a LP tokens to signify ownership in that pool.
            If you are the first person to create liquidity in a pool, you can designate the fee % the pool charges
            traders.
          </BodyText>

          <Title>
            <Bullet>2</Bullet>
            Earn Fees
          </Title>

          <BodyText>
            Fees for LPs are accumulated into a vault that allows you to claim fees seperately from your LP tokens. This
            is the most capital efficient way to capture trading fees and yield its value.
          </BodyText>
          
          {/*
          <Title>
            <Bullet>3</Bullet>
            Delegate & Change Pool Fees
          </Title>

          <BodyText>
            An account with at at least 50% of the LP vote weight delegated to it is able to change pool fees at any
            time. The fee for stable pools can be set from a range of 0.01% to 2%, whereas normal pools can be set from
            0.01% to 5%. Changing an LP fee taxes a 0.1% LP fee on the account to prevent sybil attacks.
          </BodyText>
          */}

          <ButtonMod secondary link="https://wiki.mute.io/mute/mute-switch/introduction" newTab>
            Read docs
          </ButtonMod>
        </Content>
      </Container>
    </>
  );
};

const Container = styled(CardContent)`
  ${size.padding(8)}

  background-image: url(${LP_CARD});
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: right center;

  ${layoutMq(`
    background-image: url("${bannerMobileImage}");
    background-size: 100% auto;
    background-position: 0 100%;
    padding-bottom: 54%;
  `)}
`;

const Content = styled.div`
  max-width: 570px;

  padding-top: ${size(6)};

  ${layoutMq(`
    padding-top: ${size(4)};
  `)}
`;

const Title = styled.div`
  ${size.gap(3)}
  ${flex("center")}
  ${text.h7}
`;

const BodyText = styled.div`
  ${text.bodyRegular}

  color: ${palette.gray400};

  margin-top: ${size(3)};
  margin-bottom: ${size(4)};

  ${layoutMq(`
    margin-top: ${size(2)};
  `)}
`;

const ButtonMod = styled(Button)`
  max-width: 200px;
  width: 100%;

  margin-top: ${size(2)};

  ${layoutMq(`
    margin-top: ${size(1)};
    max-width: 100%;
  `)}
`;
