import {toIcon} from "./icon.shared";

export const flake = toIcon(
  <svg width="16" height="16" viewBox="99 80 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M107 96C107 91.582 103.418 88 99 88C103.418 88 107 84.418 107 80C111.418 80 115 83.582 115 88C115 92.418 111.418 96 107 96Z"
      fill="currentColor"
    />
  </svg>,
);
