import { useParams } from "react-router-dom";
import { Box, Button, LavaChip, lavaEffectHoverClassName } from "../../../../ui/components";
import { numberWithCommas } from "../../../../utils";
import { useTokenData } from "../../data/token-data";
import { useAnalytics } from "../../provider";
import { AnalyticsPageHeading } from "./components/common-page";

import { AnalyticsOverviewPairs } from "./overview-pairs";
import { AnalyticsOverviewTxs } from "./overview-txs";
import { AnalyticsChartVolumeToken } from "./components/chart-volume/token";

import * as SC from "./index.styles";

interface AnalyticsTokenPageProps {}

export const AnalyticsTokenPage = ({}: AnalyticsTokenPageProps) => {
  const {address} = useParams<{address: string}>() ;
  const {data: {globalData}} = useAnalytics();
  const {ready, token, pairs, txs, chart} = useTokenData(globalData.data, address)
  return (
    <>
      <AnalyticsPageHeading
        loading={!ready}
        title={token.name}
        titleAlt={token.symbol}
        price={token.price}
        priceChange={token.priceChangePercentage}
        titleContent={<>
          <a href={`https://era.zksync.network/address/${address}`} target="_blank">
            <Button secondary size="s">View on Block Explorer</Button>
          </a>
        </>}
        goBackLink="/analytics/tokens"
      >
        <Box column>
          <Box>
            <Box className={lavaEffectHoverClassName} column>
              <LavaChip
                loading={!ready}
                heading="Liquidity"
                value={`$${numberWithCommas(token.liquidityUsd, 2)}`}
                alt={1}
                leader
              />
              <LavaChip
                loading={!ready}
                heading="Transactions 24h"
                value={`${numberWithCommas(token.txDayCount, 0)}`}
                alt={2}
                follower
              />
            </Box>
            <Box className={lavaEffectHoverClassName} column>
              <LavaChip
                loading={!ready}
                heading="Volume 24h"
                value={`$${numberWithCommas(token.volumeDayUsd, 2)}`}
                alt={1}
                palette="purple"
                leader
              />
              <LavaChip
                loading={!ready}
                heading="Fees 24h"
                value={`$${numberWithCommas(token.feesDayUsd, 2)}`}
                alt={2}
                palette="purple"
                follower
              />
            </Box>
          </Box>
          <Box extendOnMobile>
            <Box column extendOnMobile>
              <AnalyticsChartVolumeToken groups={chart} />
            </Box>
          </Box>
          <AnalyticsOverviewPairs data={pairs.pairs || []} />
          <AnalyticsOverviewTxs data={txs.txs || []} />
        </Box>
      </AnalyticsPageHeading>
    </>
  );
};
