import React from "react";
import styled from "styled-components";
import {Modal, Text, Button, ModalProps} from "../../../ui/components";
import {Transaction} from "../../../model/Transaction";
import {formatDate} from "../../../utils/formatDate";

interface TransactionDetailsModalProps extends ModalProps {
  transaction: Transaction | undefined;
}

export const TransactionDetailsModal = ({isVisible, onClose, transaction}: TransactionDetailsModalProps) => {
  if (transaction?.operation == "Swap") {
    return (
      <Modal title="Transaction details" isVisible={isVisible} onClose={onClose}>
        <List>
          <ListItem>
            <Text bodyRegular color="gray400">
              Operation
            </Text>
            <Text bodyMedium>{transaction?.operation}</Text>
          </ListItem>
          <ListItem>
            <Text bodyRegular color="gray400">
              Send
            </Text>
            <Text bodyMedium>{transaction?.amountIn + " " + transaction?.fromPair}</Text>
          </ListItem>
          <ListItem>
            <Text bodyRegular color="gray400">
              Receive
            </Text>
            <Text bodyMedium>{transaction?.amountOut + " " + transaction?.toPair}</Text>
          </ListItem>
          <ListItem>
            <Text bodyRegular color="gray400">
              Status
            </Text>
            <Text bodyMedium>{transaction?.status}</Text>
          </ListItem>
          <ListItem>
            <Text bodyRegular color="gray400">
              Time
            </Text>
            <Text bodyMedium>{transaction?.timestamp && formatDate(transaction.timestamp)}</Text>
          </ListItem>
        </List>
        <Button full onClick={() => window.open("https://era.zksync.network/tx/" + transaction?.hash, "_blank")}>
          View on Explorer
        </Button>
      </Modal>
    );
  } else if (transaction?.operation == "Add LP") {
    return (
      <Modal title="Transaction details" isVisible={isVisible} onClose={onClose}>
        <List>
          <ListItem>
            <Text bodyRegular color="gray400">
              Operation
            </Text>
            <Text bodyMedium>{transaction?.operation}</Text>
          </ListItem>
          <ListItem>
            <Text bodyRegular color="gray400">
              Amount
            </Text>
            <Text bodyMedium>{transaction?.amountIn + " " + transaction?.fromPair}</Text>
          </ListItem>
          <ListItem>
            <Text bodyRegular color="gray400">
              Amount
            </Text>
            <Text bodyMedium>{transaction?.amountOut + " " + transaction?.toPair}</Text>
          </ListItem>
          <ListItem>
            <Text bodyRegular color="gray400">
              Status
            </Text>
            <Text bodyMedium>{transaction?.status}</Text>
          </ListItem>
          <ListItem>
            <Text bodyRegular color="gray400">
              Time
            </Text>
            <Text bodyMedium>{transaction?.timestamp && formatDate(transaction.timestamp)}</Text>
          </ListItem>
        </List>
        <Button full onClick={() => window.open("https://era.zksync.network/tx/" + transaction?.hash, "_blank")}>
          View on Explorer
        </Button>
      </Modal>
    );
  } else {
    return (
      <Modal title="Transaction details" isVisible={isVisible} onClose={onClose}>
        <List>
          <ListItem>
            <Text bodyRegular color="gray400">
              Operation
            </Text>
            <Text bodyMedium>{transaction?.operation}</Text>
          </ListItem>
          <ListItem>
            <Text bodyRegular color="gray400">
              Amount
            </Text>
            <Text bodyMedium>{transaction?.amountIn + " " + transaction?.fromPair}</Text>
          </ListItem>
          <ListItem>
            <Text bodyRegular color="gray400">
              Amount
            </Text>
            <Text bodyMedium>{transaction?.amountOut + " " + transaction?.toPair}</Text>
          </ListItem>
          <ListItem>
            <Text bodyRegular color="gray400">
              Status
            </Text>
            <Text bodyMedium>{transaction?.status}</Text>
          </ListItem>
          <ListItem>
            <Text bodyRegular color="gray400">
              Time
            </Text>
            <Text bodyMedium>{transaction?.timestamp && formatDate(transaction.timestamp)}</Text>
          </ListItem>
        </List>
        <Button full onClick={() => window.open("https://era.zksync.network/tx/" + transaction?.hash, "_blank")}>
          View on Explorer
        </Button>
      </Modal>
    );
  }
};

const List = styled.ul`
  margin-bottom: 32px;
`;

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin-top: 8px;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
