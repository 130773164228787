import {Divider, Flex, Icon, Text} from "../../ui/components";
import * as SC from "./WalletBannerWidget.styles";

const points = `
Have total control over the assets through private keys.
Your assets are safe inside a verifiable zkRollup.
Withdraw assets to the Layer 1 wallet at any time.
`
  .trim()
  .split("\n");

export const WalletBannerWidget = () => {
  return (
    <SC.Wrapper>
      <SC.Content>
        <Text h4>How does your layer 2 wallet work?</Text>
        <Divider size="6" />
        <Text bodyRegular color="gray400">
          Koi Finance is a{" "}
          <Text inline color="c300">
            Layer 2 DeFi platform based on zk-Rollup technology.
          </Text>
          <br />
          You first need to deposit assets into the zkSync Era network to experience frictionless and low cost DeFi.
        </Text>
        <Divider size="6" />
        <Flex gap="2" column>
          {points.map((_, i) => (
            <Flex gap="2">
              <Icon icon="checkCircle" size="s" color="c300" />
              <span>{_}</span>
            </Flex>
          ))}
        </Flex>
      </SC.Content>
    </SC.Wrapper>
  );
};
