import {ComponentProps, PropsWithChildren} from "react";
import styled from "styled-components";

import {palette, transition} from "../../styles";

export const StyledCopy = styled.div<{margin?: boolean; keepShadow?: boolean; full?: boolean}>`
  display: contents;

  & > * {
    ${transition.fast}

    outline: 4px solid transparent;
    outline-offset: 8px;

    &:hover {
      outline-color: ${palette.ultramoss};
    }

    &:active {
      outline-color: ${palette.lavender};
    }

    ${(_) =>
      _.margin &&
      `
      margin-right: 16px;
      margin-bottom: 16px;
    `}
  }
`;

export const Copy = ({
  text,
  children,
  ...rest
}: PropsWithChildren<{text: string} & ComponentProps<typeof StyledCopy>>) => {
  return (
    <StyledCopy {...rest} title={text} onClick={() => navigator.clipboard.writeText(text)}>
      {children}
    </StyledCopy>
  );
};
