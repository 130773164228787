import {memo, PropsWithChildren, ReactNode} from "react";
import {withColor} from "../-with-color";

import * as SC from "./index.styles";

interface ChipProps extends SC.Types {
  label?: ReactNode;
  value?: ReactNode;
}

export const Chip = memo(
  withColor(function ChipComponent(props: PropsWithChildren<ChipProps>) {
    const {label, value, children} = props;
    return (
      <SC.Wrapper {...props}>
        {label && <SC.Title>{label}</SC.Title>}
        {value && <SC.Value>{value}</SC.Value>}
        {children}
      </SC.Wrapper>
    );
  }),
);
