import {memo, PropsWithChildren, useEffect, useId, useMemo, useRef, useState} from "react";

import * as SC from "./index.styles";
import {Asset} from "../../../model/Asset";
import {Icon, IconAsset} from "../icon";
import {Flex} from "../flex";
import {Text} from "../text";
import {withColor} from "../-with-color";
import {Point, svgPath} from "../-utils/path";
import {useContainerSize} from "../-utils/container-size";

interface ChartProps {
  data: number[];
  value?: number | string;
  assetA?: Asset;
  assetB?: Asset;
}

const paddingTop = 70;
const paddingBottom = 76;

export const Chart = memo(
  withColor(function ChartComponent(props: PropsWithChildren<ChartProps>) {
    const {data = [], value, children, assetA, assetB} = props;

    const containerRef = useRef<HTMLDivElement>(null);
    const {width, height} = useContainerSize(containerRef);
    const [markPosition, setMarkPosition] = useState({x: 0, y: 0});
    const [price, setPrice] = useState<string>();
    const [markVisible, setMarkVisible] = useState(false);

    const randomId = useId();
    const getId = (name: string) => `chart-${randomId}-${name}`;

    const {path, points} = useMemo(() => {
      const {min, max} = data.reduce(({min, max}, _) => ({min: Math.min(min, _), max: Math.max(max, _)}), {
        min: Infinity,
        max: -Infinity,
      });

      const reversedData = JSON.parse(JSON.stringify(data));

      const points: any[] = reversedData.map(
        (_, i) =>
          [
            (i * width) / (data.length - 1),
            (1 - (_ - min) / (max - min)) * (height - paddingTop - paddingBottom) + paddingTop,
          ] as Point,
      );

      return {
        points,
        path: svgPath(points, 0.15, 2),
      };
    }, [width, height, data]);

    useEffect(() => {
      const elem = containerRef.current;
      if (!elem || !points?.length) return;

      const onEnter = () => setMarkVisible(true);
      const onLeave = () => setMarkVisible(false);
      const onMove = (event: MouseEvent) => {
        const rect = containerRef.current!.getBoundingClientRect();
        const nPoints = points.length - 1;
        const point = Math.round(((event.clientX - rect.x) / rect.width) * nPoints);
        setPrice("$" + data[point]);
        setMarkPosition({x: point / nPoints, y: points[point][1] / height});
      };

      elem.addEventListener("mousemove", onMove, true);
      elem.addEventListener("mouseenter", onEnter, true);
      elem.addEventListener("mouseleave", onLeave, true);
      return () => {
        elem.removeEventListener("mousemove", onMove, true);
        elem.removeEventListener("mouseenter", onEnter, true);
        elem.removeEventListener("mouseleave", onLeave, true);
      };
    }, [containerRef, points]);

    return (
      <SC.Wrapper ref={containerRef}>
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
          <SC.AreaPath
            d={`
            ${path}
            l 0 0
            L ${width} ${height}
            L 0 ${height}
          `}
            mask={`url(#${getId("fade")})`}
          />
          <SC.MainLinePath d={path} />
          <linearGradient id={getId("fade-gradient")} y2="1" x2="0">
            <stop offset="0" stopColor="white" stopOpacity="1" />
            <stop offset="0.7" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <mask id={getId("fade")} maskContentUnits="objectBoundingBox">
            <rect width={1} height={1} fill={`url(#${getId("fade-gradient")})`} />
          </mask>
        </svg>
        {assetA && assetB && (
          <SC.Pair>
            <Flex>
              <IconAsset stack asset={assetA} button size="s" />
              <IconAsset stack asset={assetB} button size="s" />
            </Flex>
            {assetA.symbol}
            <Text color="gray400">―</Text>
            {assetB.symbol}
          </SC.Pair>
        )}
        <SC.Value>
          <span data-value="hover">{price}</span>
          <span data-value="base">{value}</span>
        </SC.Value>
        <SC.Mark left={markPosition.x} top={markPosition.y} visible={markVisible}>
          <Icon icon="flake" size="xs" />
        </SC.Mark>
      </SC.Wrapper>
    );
  }),
);
