import {Box, Divider, Flex, Tabs, Text} from "../../../../ui/components";

import {AnalyticsOverviewTokens} from "./overview-tokens";
import { AnalyticsOverviewPairs } from "./overview-pairs";

import {AnalyticsChartVolume} from "./components/chart-volume";
import {AnalyticsChartLiquidity} from "./components/chart-liquidity";
import { AnalyticsOverviewTxs } from "./overview-txs";
import { AnalyticsSearch } from "./components/search";


interface AnalyticsOverviewProps {}

export const AnalyticsOverview = ({}: AnalyticsOverviewProps) => {
  return (
    <>
      <Box noBreak vertical>
        <Box noBreak>
          <div>
            <Divider size={12}/>
            <Flex align="flex-end" justify="space-between" expandHorizontal wrap gap={4}>
              <Text h2>KOI Analytics</Text>
              <AnalyticsSearch/>
            </Flex>
            <Divider size={2}/>
          </div>
        </Box>
        <Box extendOnMobile>
          <Box column extendOnMobile>
            <AnalyticsChartLiquidity />
          </Box>
          <Box column extendOnMobile>
            <AnalyticsChartVolume />
          </Box>
        </Box>
        <AnalyticsOverviewTokens />
        <AnalyticsOverviewPairs />
        <AnalyticsOverviewTxs />
      </Box>
    </>
  );
};
