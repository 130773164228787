import styled from "styled-components";

export const ShadowWidget = styled.div`
  display: flex;
  flex-direction: column;
`;

/*

  box-shadow: 0px 16px 16px rgba(9, 12, 22, 0.5);
  border-radius: 16px;
  */
