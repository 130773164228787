import {memo, useCallback} from "react";
import {useHistory} from "react-router";
import styled from "styled-components";
import {transition} from "../../styles";
import {LavaChip} from "../../ui/components";
import {br, size} from "../../ui/styles";
import {sentGaEvent} from "../../utils";

interface LPOnFarmBannerProps {
  hasLP?: boolean;
  tokenAddressA: string;
  tokenAddressB: string;
}

export const LPOnFarmBanner = memo(function LPOnFarmBannerComponent(props: LPOnFarmBannerProps) {
  const {tokenAddressA, tokenAddressB, hasLP} = props;

  const history = useHistory();

  const visible = tokenAddressA && tokenAddressB;

  const goToLp = useCallback(() => {
    sentGaEvent("farm_to_lp_banner_clicked", [tokenAddressA, tokenAddressB].join());
    history.push(`/pool/${tokenAddressA}/${tokenAddressB}/false`);
  }, [tokenAddressA, tokenAddressB]);

  return (
    <Wrapper onClick={goToLp} open={!!visible}>
      {visible && (
        <LavaChip
          palette="green"
          arrow
          micro
          heading={
            !hasLP
              ? "Add liquidity to the pool to earn multiple yields"
              : "Add more liquidity to the pool to earn multiple yields"
          }
        />
      )}
    </Wrapper>
  );
});

const Wrapper = styled.div<{open: boolean}>`
  @media (min-width: 980px) or ((max-width: 800px) and (max-width: 550px)) {
    ${br.m}
    ${transition.slow}

    padding-bottom: 0;
    overflow: hidden;
    max-height: 0;

    ${(_) =>
      _.open &&
      `
      max-height: ${44 + size.raw(3)}px;
      padding-bottom: ${size(3)};
    `}
  }
`;
