import {Fragment, useMemo, useState} from "react";

import {
  Icon,
  InputCoin,
  Logo,
  Switch,
  Tabs,
  Button,
  CardScale,
  useUi,
  IconStatus,
  LavaChip,
  Chip,
  AssetSelector,
  Modal,
  Search,
  Chart,
  Message,
  Skeleton,
  Text,
  Title,
  TitleDash,
  ChartPriceRange,
  PriceRange,
} from "../components";
import * as icons from "../components/icon/icons";

import {br, palette, typo, text, microPalette, microPaletteColors} from "../styles";

import {Palette} from "./palette";
import {Copy} from "./copy";
import {BorderRadius} from "./border-radius";
import {Typography} from "./typo";
import {Text as TextDemo} from "./text";

import {generatePriceRangeData} from "./data-generation/price-range";
import { TableDemo } from "./table";
import { useColor, useSettings, useSwitch } from "./demo.hooks";

import * as SC from "./demo.styles";
import { IconSort } from "../components/icon-sort";

export const UiDemoPage = () => {
  const {color, setColor} = useUi();

  const iconSize = useSettings("Size", ["m", "s"], "m");
  const iconVariant = useSettings("Variant", ["none", "contrast", "success", "error", "button"]);
  const switchActive = useSwitch("Active");
  const buttonFull = useSwitch("Full");
  const buttonDisabled = useSwitch("Disabled");
  const tabsFull = useSwitch("Full");
  const tabsSmall = useSwitch("Small");
  const tabsMicro = useSwitch("Micro");
  const inputDisabled = useSwitch("Disabled");
  const inputNoData = useSwitch("No data");
  const inputState = useState<string>();
  const assetSelectorLarge = useSwitch("Large");
  const lavaChipColor = useColor("Overwrite");
  const chipColor = useColor("Overwrite");
  const modalOpen = useState(false);
  const skeletonActive = useSwitch("Active");
  const skeletonVariant = useSettings("Variant", ["none", "color"]);
  const titleLoading = useSwitch("Loading");
  const titleButton = useSwitch("Go back");
  const titleBp = useSettings("Breakpoint", ["default", "500", "10000"]);

  const priceRangeData = useMemo(() => generatePriceRangeData(1000), []);
  const [priceRangeFrom, setPriceRangeFrom] = useState(940);
  const [priceRangeTo, setPriceRangeTo] = useState(1070);

  return (
    <>
      <>
        <SC.Footer>
          <Tabs selected={color} onSelect={setColor} tabs={microPaletteColors.map((_) => ({value: _, label: _}))} />
        </SC.Footer>
        <SC.Wrapper>
          <SC.Card style={{paddingBottom: 4}}>
            <h2>Palette</h2>
            {Object.keys(palette).map((_, i) => (
              <Fragment key={i}>
                {[7, 12, 17, 22].includes(i) && <br />}
                <Copy text={`\$\{palette.${_}\}`}>
                  <Palette color={_ as any} />
                </Copy>
              </Fragment>
            ))}
            <br />
            {Object.keys(microPalette)
              .filter((_) => _.match(/^c/))
              .map((_, i) => (
                <Copy text={`\$\{microPalette.${_}\}`} key={i}>
                  <Palette color={_ as any} />
                </Copy>
              ))}
          </SC.Card>

          <SC.Card>
            <h2>Border Radius</h2>
            {Object.keys(br).map((_, i) => (
              <Copy text={`\$\{br.${_}\}`} key={i}>
                <BorderRadius size={_ as any} />
              </Copy>
            ))}
          </SC.Card>

          <SC.Card>
            <h2>Text</h2>
            {Object.keys(text).map((type) => (
              <SC.TypoWrapper key={type} subtitle>
                <Copy text={`\$\{text.${type}\}`}>
                  <TextDemo type={type as any}>KOI.finance</TextDemo>
                </Copy>
                <span>{type.match(/^H/) ? "Heading" : "Body"}</span>
                <span>{type}</span>
              </SC.TypoWrapper>
            ))}
          </SC.Card>

          {Object.entries(typo).map(([font, variants], i) => (
            <SC.Card key={font}>
              <h2>Typography ({font})</h2>
              {Object.keys(variants)
                .filter((_) => _ !== "raw")
                .reverse()
                .map((variant, i) => (
                  <SC.TypoWrapper key={font + i}>
                    <Copy text={`\$\{typo.${font}.${variant}\}`}>
                      <Typography font={font as any} type={variant}>
                        KOI.finance
                      </Typography>
                    </Copy>
                    <span>{typo[font][variant].match(/[\d]{3}/)}</span>
                    <span>{variant}</span>
                  </SC.TypoWrapper>
                ))}
            </SC.Card>
          ))}

          <SC.Card margin>
            <h2>Logo</h2>
            <Copy text={`<Logo/>`} margin>
              <Logo />
            </Copy>
            <Copy text={`<Logo icon/>`} margin>
              <Logo icon />
            </Copy>
          </SC.Card>

          <SC.Card margin>
            <h2>
              <>
                <span>Icons</span>
                {iconSize.component}
                {iconVariant.component}
              </>
            </h2>
            {Object.keys(icons).map((_, i) => (
              <Copy
                text={`<Icon icon="${_}" ${iconSize.active !== "m" ? `size="${iconSize.active}" ` : ""}${iconVariant.active !== "none" ? iconVariant.active + " " : ""}/>`}
                key={i}
                margin
              >
                <Icon
                  icon={_ as any}
                  size={iconSize.active}
                  contrast={iconVariant.active === "contrast"}
                  success={iconVariant.active === "success"}
                  error={iconVariant.active === "error"}
                  button={iconVariant.active === "button"}
                />
              </Copy>
            ))}
          </SC.Card>

          <SC.Card margin>
            <h2>Icon Status</h2>
            {"success,error,loading".split(",").map((_: any, i) => (
              <Copy text={`<IconStatus status="${_}"/>`} key={i} margin>
                <IconStatus status={_} />
              </Copy>
            ))}
          </SC.Card>

          <SC.Card margin>
            <h2>Icon Sort</h2>
            {([undefined, 'asc', 'desc'] as const).map((_, i) => (
              <Copy text={`<IconStatus status="${_}"/>`} key={i} margin>
                <IconSort dir={_} />
              </Copy>
            ))}
          </SC.Card>

          <SC.Card margin>
            <h2>
              <>
                <span>Button</span>
                {buttonDisabled.component}
                {buttonFull.component}
              </>
            </h2>
            <Copy
              text={`<Button ${buttonFull.active ? "full " : ""}${buttonDisabled.active ? "disabled " : ""}/>`}
              margin
              full={buttonFull.active}
            >
              <Button full={buttonFull.active} disabled={buttonDisabled.active}>
                Button
              </Button>
            </Copy>
            <Copy
              text={`<Button alt ${buttonFull.active ? "full " : ""}${buttonDisabled.active ? "disabled " : ""}/>`}
              margin
              full={buttonFull.active}
            >
              <Button alt full={buttonFull.active} disabled={buttonDisabled.active}>
                Button
              </Button>
            </Copy>
            <Copy
              text={`<Button secondary ${buttonFull.active ? "full " : ""}${buttonDisabled.active ? "disabled " : ""}/>`}
              margin
              full={buttonFull.active}
            >
              <Button secondary full={buttonFull.active} disabled={buttonDisabled.active}>
                Button
              </Button>
            </Copy>
          </SC.Card>

          <SC.Card>
            <h2>
              <>
                <span>Tabs</span>
                {tabsMicro.component}
                {tabsSmall.component}
                {tabsFull.component}
              </>
            </h2>
            <Copy
              text={`<Tabs tabs={[{value: 0, label: "Tab"}]} ${tabsFull.active ? "full " : ""}${tabsMicro.active ? "micro " : ""}${tabsSmall.active ? "small " : ""}/>`}
              full={tabsFull.active}
            >
              <Tabs
                standalone
                full={tabsFull.active}
                small={tabsSmall.active}
                micro={tabsMicro.active}
                tabs={[
                  {value: "a", label: "Tab A"},
                  {value: "b", label: "Tab B"},
                  {value: "c", label: "Tab C"},
                  {
                    value: "koi",
                    label: (
                      <>
                        <Logo icon height={16} width={16} /> Koi
                      </>
                    ),
                  },
                ]}
              />
            </Copy>
          </SC.Card>

          <SC.Card>
            <h2>
              <>
                <span>Switch</span>
                {switchActive.component}
              </>
            </h2>
            <Copy text={`<Switch active={${JSON.stringify(switchActive.active)}} setActive={() => {}}/>`}>
              <Switch active={switchActive.active} />
            </Copy>
          </SC.Card>

          <SC.Card>
            <h2>
              <>
                <span>Input</span>
                {inputDisabled.component}
                {inputNoData.component}
              </>
            </h2>
            <Copy
              text={`<InputCoin value={0} setValue={n => {}} price={0} balance={0} ${inputDisabled.active ? "disabled " : ""}/>`}
              full
            >
              <InputCoin
                value={inputState[0]}
                setValue={inputState[1]}
                price={inputNoData.active ? undefined : 1.2}
                balance={inputNoData.active ? undefined : 360.92}
                disabled={inputDisabled.active}
              />
            </Copy>
          </SC.Card>

          <SC.Card deprecated>
            <h2>Scale Card</h2>
            <Copy text={`<CardScale>...</CardScale>`} full>
              <CardScale>
                <Icon icon="coinEth" size="s" button />
              </CardScale>
            </Copy>
          </SC.Card>

          <SC.Card>
            <h2>
              <>
                <span>Lava Chip</span>
                {lavaChipColor.component}
              </>
            </h2>
            <Copy
              text={`<LavaChip heading="" value="" ${lavaChipColor.active ? `palette="${lavaChipColor.active}"` : ""}/>`}
              full
            >
              <LavaChip heading="Lava Chip Title" value="$10,000" palette={lavaChipColor.active} />
            </Copy>
          </SC.Card>

          <SC.Card margin>
            <h2>
              <>
                <span>Chip</span>
                {chipColor.component}
              </>
            </h2>
            <Copy text={`<Chip label="" value=""/>`} margin>
              <Chip label="Label" value="20%" />
            </Copy>
            <Copy text={`<Chip label="" value="" variant="white"/>`} margin>
              <Chip label="Label" value="20%" variant="white" />
            </Copy>
            <Copy text={`<Chip label="" value="" variant="dark"/>`} margin>
              <Chip label="Label" value="20%" variant="dark" />
            </Copy>
            <Copy text={`<Chip label="" value="" variant="color"/>`} margin>
              <Chip label="Label" value="20%" variant="color" palette={chipColor.active} />
            </Copy>
            <br />
            <Copy text={`<Chip label="" value="" valueColor={true} />`} margin>
              <Chip label="Label" value="20%" valueColor={true} />
            </Copy>
            <Copy text={`<Chip label="" value="" valueColor={false} />`} margin>
              <Chip label="Label" value="-20%" valueColor={false} />
            </Copy>
          </SC.Card>

          <SC.Card>
            <h2>
              <>
                <span>Assets Selector</span>
                {assetSelectorLarge.component}
              </>
            </h2>
            <Copy text={`<AssetSelector asset={{}} assets={[]} ${assetSelectorLarge.active ? "large " : ""}/>`}>
              <AssetSelector asset={{symbol: "ETH"} as any} large={assetSelectorLarge.active} />
            </Copy>
          </SC.Card>

          <SC.Card>
            <h2>Modal</h2>
            <Copy text={`<Modal isVisible={${modalOpen[0]}}>Content</Modal>`}>
              <Button onClick={() => modalOpen[1](true)}>Open modal</Button>
            </Copy>
            <Modal
              isVisible={modalOpen[0]}
              onClose={modalOpen[1]}
              title="Modal title"
              subtitle="Subtitle with some content with some words."
            >
              Content
            </Modal>
          </SC.Card>

          <SC.Card>
            <h2>Search</h2>
            <Copy text={`<Search placeholder="Search" />`}>
              <Search placeholder="Search" />
            </Copy>
          </SC.Card>

          <SC.Card>
            <h2>Chart</h2>
            <Copy text={`<Chart data={[]} />`}>
              <Chart
                value={"$0.999"}
                assetA={{symbol: "eth"} as any}
                assetB={{symbol: "usdc"} as any}
                data={[
                  1.00455663353969, 1.00104742840379, 1.00103485978641, 1.00639682330266, 1.00441168552599,
                  1.00639964360555, 1.0075306816657, 1.0032938516178, 1.00836658076549, 1.00237503331425,
                  1.0076486236001, 1.00731200580893, 1.00808651923198, 1.00621019858614, 1.00690491152665,
                  1.0072620952951, 1.00243485391399, 1.0072652504717, 1.01313119783674, 1.00506944370755,
                  1.00657675936778, 1.02065785046801, 0.999815108600812, 1.00507316515037,
                ]}
              />
            </Copy>
          </SC.Card>

          <SC.Card>
            <h2>Price Range Chart</h2>
            <Copy text={`<ChartPriceRange data={[]} />`}>
              <ChartPriceRange
                data={priceRangeData}
                rangeFrom={priceRangeFrom}
                onRangeFrom={setPriceRangeFrom}
                rangeTo={priceRangeTo}
                onRangeTo={setPriceRangeTo}
                price={997}
              />
            </Copy>
          </SC.Card>

          <SC.Card>
            <h2>Price Range</h2>
            <Copy text={`<PriceRange rangeFrom={} rangeTo={} price={} />`}>
              <PriceRange rangeFrom={800} rangeTo={1000} price={975} />
            </Copy>
            <Copy text={`<PriceRange rangeFrom={0} rangeTo={Infinity} price={} />`}>
              <PriceRange rangeFrom={0} rangeTo={Infinity} price={997} />
            </Copy>
          </SC.Card>

          <SC.Card>
            <h2>Message</h2>
            <Copy text={`<Message error>...</Message>`}>
              <Message error>Message content.</Message>
            </Copy>
          </SC.Card>

          <SC.Card>
            <h2>
              <>
                <span>Skeleton</span>
                {skeletonActive.component}
                {skeletonVariant.component}
              </>
            </h2>
            <Copy
              text={`<Skeleton active={${skeletonActive.active}} ${skeletonVariant.active !== "none" ? skeletonVariant.active + " " : ""}>...</Skeleton>`}
            >
              <Skeleton active={skeletonActive.active} variant={skeletonVariant.active as any} width={200}>
                <Text h2>Skeleton content.</Text>
              </Skeleton>
            </Copy>
          </SC.Card>

          <SC.Card>
            <h2>
              <>
                <span>Title</span>
                {titleButton.component}
                {titleLoading.component}
                {titleBp.component}
              </>
            </h2>
            <Copy
              text={`<Title${titleLoading.active ? " loading" : ""} title="Title content" chip="Chip content"${titleBp.active !== "default" ? ` breakpoint={${titleBp.active}}` : ""}${titleButton.active ? ' goBackLink="/path"' : ""}>...</Title>`}
            >
              <Title
                loading={titleLoading.active}
                title={
                  <>
                    Title <TitleDash /> Content
                  </>
                }
                chip="Chip content"
                goBackLink={titleButton.active ? "/dev/ui" : undefined}
                breakpoint={titleBp.active === "default" ? undefined : titleBp.active}
              />
            </Copy>
          </SC.Card>

          <TableDemo/>

        </SC.Wrapper>
      </>
    </>
  );
};
