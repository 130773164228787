import {CORE_ZKSYNC_ERA, CORE_SOPHON_SEPOLIA} from './core'
import { FARMS_ZKSYNC_ERA, FARMS_SOPHON_SEPOLIA } from './farms';
import { TOKENS_ZKSYNC_ERA, TOKENS_SOPHON_SEPOLIA } from './tokens';
import { GAS_ZKSYNC_ERA, GAS_SOPHON_SEPOLIA } from './gas';
export default function getContracts(id) {
  if(id == 324){
    return {
      ...CORE_ZKSYNC_ERA,
      ...TOKENS_ZKSYNC_ERA,
      ...FARMS_ZKSYNC_ERA,
      gas: GAS_ZKSYNC_ERA
    }
  }

  if(id == 531050104){
    return {
      ...CORE_SOPHON_SEPOLIA,
      ...FARMS_SOPHON_SEPOLIA,
      ...TOKENS_SOPHON_SEPOLIA,
      gas: GAS_SOPHON_SEPOLIA,
    }
  }

  return {
    ...CORE_ZKSYNC_ERA,
    ...TOKENS_ZKSYNC_ERA,
    ...FARMS_ZKSYNC_ERA,
    gas: GAS_ZKSYNC_ERA,
  };
}
