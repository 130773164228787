import {ethers} from "ethers";
import {Commands} from "./routercommands";
import {encodePath} from "./v3/liqPositions";

const SENDER_AS_RECIPIENT = "0x0000000000000000000000000000000000000001";
const ROUTER_AS_RECIPIENT = "0x0000000000000000000000000000000000000002";

interface SwapDetails {
  amountIn: string;
  amountOutMin: string;
  route: Route[];
  v3: boolean;
  routeV3: RouteV3[];
}

interface Route {
  from: string;
  to: string;
  stable: boolean;
}

interface RouteV3 {
  from: {contractAddress: string};
  to: {contractAddress: string};
  fee: Number;
}

export function getSwapData(
  swapData: SwapDetails[],
  ethInput: boolean,
  ethOutput: boolean,
  valueTotal: string,
  minReceived: string,
  getPermit: any,
) {
  const abi = ethers.AbiCoder.defaultAbiCoder();
  var commands = "0x";
  var inputs: string[] = [];

  //wrap eth
  if (ethInput) {
    const wrap_data = getWrapData(valueTotal);
    commands = commands + wrap_data.command.replace("0x", "");
    inputs.push(wrap_data.inputs[0]);
  }

  if (getPermit) {
    const permit_data = getPermitData(getPermit.permitSingle, getPermit.signature);
    commands = commands + permit_data.command.replace("0x", "");
    inputs.push(permit_data.inputs[0]);
  }

  //token swaps
  const swap_data = getSwapDataToTokens(swapData, ethInput, ethOutput);
  commands = commands + swap_data.commands.replace("0x", "");
  inputs = inputs.concat(swap_data.inputs);

  //unwrap eth
  if (ethOutput) {
    const unwrap_data = getUnwrapData(minReceived);
    commands = commands + unwrap_data.command.replace("0x", "");
    inputs.push(unwrap_data.inputs[0]);
  }

  return {commands, inputs};
}

export function getSwapDataToTokens(swapData: SwapDetails[], ethInput: boolean, ethOutput: boolean) {
  const abi = ethers.AbiCoder.defaultAbiCoder();

  var commands = "";
  var inputs: string[] = [];
  for (let i in swapData) {
    if (swapData[i].v3) {
      const swap_params = abi.encode(
        ["address", "uint256", "uint256", "bytes", "bool"], // recipient, amountIn, minOut, path, usePermit
        [
          ethOutput ? ROUTER_AS_RECIPIENT : SENDER_AS_RECIPIENT,
          swapData[i].amountIn,
          swapData[i].amountOutMin,
          encodePath(swapData[i].routeV3),
          !ethInput,
        ],
      );

      inputs.push(swap_params);
      commands = commands == "" ? Commands.V3_SWAP_EXACT_IN : commands + Commands.V3_SWAP_EXACT_IN.replace("0x", "");
    } else {
      var normalize: any = [];
      for (let j in swapData[i].route) {
        normalize.push([swapData[i].route[j].from, swapData[i].route[j].to, swapData[i].route[j].stable]);
      }

      const swap_params = abi.encode(
        ["address", "uint", "uint", "tuple(address, address, bool)[]", "bool"], // recipient, amountIn, minOut, path, usePermit
        [
          ethOutput ? ROUTER_AS_RECIPIENT : SENDER_AS_RECIPIENT,
          swapData[i].amountIn,
          swapData[i].amountOutMin,
          normalize,
          !ethInput,
        ],
      );

      inputs.push(swap_params);
      commands = commands == "" ? Commands.V2_SWAP_EXACT_IN : commands + Commands.V2_SWAP_EXACT_IN.replace("0x", "");
    }
  }

  return {commands, inputs};
}

export function getWrapData(amount) {
  const abi = ethers.AbiCoder.defaultAbiCoder();

  const wrap_params = abi.encode(
    ["address", "uint"], // encode as address array
    [ROUTER_AS_RECIPIENT, amount],
  );

  const command = Commands.WRAP_ETH;
  const inputs = [wrap_params];
  return {command, inputs};
}

export function getUnwrapData(amount) {
  const abi = ethers.AbiCoder.defaultAbiCoder();

  const wrap_params = abi.encode(
    ["address", "uint"], // encode as address array
    [SENDER_AS_RECIPIENT, amount],
  );

  const command = Commands.UNWRAP_WETH;
  const inputs = [wrap_params];
  return {command, inputs};
}

export function getPermitData(permitSingle, signature) {
  /*
    const PermitDetails = {
            // ERC20 token address
            address token;
            // the maximum amount allowed to spend
            uint160 amount;
            // timestamp at which a spender's token allowances become invalid
            uint48 expiration;
            // an incrementing value indexed per owner,token,and spender for each signature
            uint48 nonce;
    }
        struct PermitSingle {
            // the permit data for a single token alownce
            PermitDetails details;
            // address permissioned on the allowed tokens
            address spender;
            // deadline on the permit signature
            uint256 sigDeadline;
    }
    */
  const abi = ethers.AbiCoder.defaultAbiCoder();

  const permit_params = abi.encode(
    ["tuple(tuple(address, uint160, uint48, uint48), address, uint256)", "bytes"], // encode as address array
    [
      [
        [
          permitSingle.details.token,
          permitSingle.details.amount,
          permitSingle.details.expiration,
          permitSingle.details.nonce,
        ],
        permitSingle.spender,
        permitSingle.sigDeadline,
      ],
      signature,
    ],
  );

  const command = Commands.PERMIT2_PERMIT;
  const inputs = [permit_params];
  return {command, inputs};
}
