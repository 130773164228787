// SVG path generator from https://francoisromain.medium.com/smooth-a-svg-path-with-cubic-bezier-curves-e37b49d46c74
export type Point = [number, number];
export type Points = Point[];

export const svgPath = (points: Points, smoothing = 0.2, sharpEnd = 2) => {
  const side = (point: Point | undefined, current: Point) => {
    if (point) {
      const [px, py] = point;
      const [cx] = current;
      return [cx + (cx - px) * sharpEnd, py] as Point;
    }
  };

  const line = (pointA: Point, pointB: Point) => {
    const lengthX = pointB[0] - pointA[0];
    const lengthY = pointB[1] - pointA[1];
    return {
      length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
      angle: Math.atan2(lengthY, lengthX),
    };
  };

  const controlPoint = (current: Point, previous?: Point, next?: Point, reverse?: boolean) => {
    const p = previous || side(next, current) || current;
    const n = next || side(previous, current) || current;

    const o = line(p, n);

    const angle = o.angle + (reverse ? Math.PI : 0);
    const length = o.length * smoothing;

    const x = current[0] + Math.cos(angle) * length;
    const y = current[1] + Math.sin(angle) * length;
    return [x, y];
  };

  const bezier = (point: Point, i: number, list: Points) => {
    const cps = controlPoint(list[i - 1], list[i - 2], point);
    const cpe = controlPoint(point, list[i - 1], list[i + 1], true);
    return `C ${cps[0]},${cps[1]} ${cpe[0]},${cpe[1]} ${point[0]},${point[1]}`;
  };

  return points.reduce(
    (acc, point, i, list) => (i === 0 ? `M ${point[0]},${point[1]}` : `${acc} ${bezier(point, i, list)}`),
    "",
  );
};
