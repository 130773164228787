import {memo, useState, useMemo, useEffect, useCallback, useRef} from "react";
import {useHistory, useParams} from "react-router-dom";
import {Asset} from "../../../model/Asset";
import {useWalletSelector} from "../../../state/hooks";
import {selectAssets} from "../../../state/selectors";
import {
  AssetSelector,
  Box,
  Button,
  CardContent,
  Divider,
  Flex,
  InputCoin,
  Message,
  Tabs,
  Text,
  Title,
  ChartPriceRange,
  ChartPriceRangeHandle,
  useLayoutMq,
  Modal,
  AssetsBlock,
  Loading,
  LavaChip,
  lavaEffectHoverClassName,
  Tooltip,
  IconAsset,
} from "../../../ui/components";
import {formatPrice, numberWithCommas, reduceString, sendTransactionGaEvent} from "../../../utils";
import {useWalletHook} from "../../../web3/walletHook";
import {getTickBounds, getAmount0Liquidity, getAmount1Liquidity, getClosestPrice, getCurrentTick} from "./helper";
import {Pool} from "@uniswap/v3-sdk";
import BigNumber from "bignumber.js";
import {WalletState} from "../../../state";
import {TxModal} from "../../+common/TxModal";
import { MdOutlineBolt } from "react-icons/md";
import styled from "styled-components";
import unknownToken from "../../../assets/images/icons/unknown_token.svg";
import { Warning } from "../../../ui/components/price-range/index.styles";

interface ParamTypes {
  inputCurrency: string;
  outputCurrency: string;
  feeAmount?: string;
}

type Mutable<T> = {
  -readonly [K in keyof T]: Mutable<T[K]>;
};

interface AddLiquidityV3ViewProps {}

const tierTabs = [0.01, 0.05, 0.3, 0.5, 1].map((value) => ({value, label: value + "%"}));

const rangeSelectorConfig = {
  narrow: 0.005,
  common: 0.01,
  wide: 0.05,
};

export const AddLiquidityV3View = memo(function AddLiquidityV3ViewComponent(props: AddLiquidityV3ViewProps) {
  const {inputCurrency, outputCurrency, feeAmount} = useParams<ParamTypes>();
  const history = useHistory();

  const {isValidV3Pool, getFullPoolData, addLiquidityV3, wethOrToken, getPairDayData } = useWalletHook<true>();
  const layoutBreak = useLayoutMq();

  const gasRefund = useWalletSelector((state: WalletState) => state.gasEstimateRefund);
  const [txId, setTxId] = useState();
  const [nftId, setNftId] = useState();
  const [showModal, setShowModal] = useState(false);

  const assets = useWalletSelector(selectAssets);
  const assetsByAddress = useWalletSelector((state) => state.tokens);
  const allPairs = useWalletSelector((state) => state.allPairs);

  const [poolData, setPoolData] = useState<Awaited<ReturnType<typeof getFullPoolData>>>();
  const [pairInfo, setPairInfo] = useState<Awaited<ReturnType<typeof getPairDayData>>>();

  const pool = poolData?.data?.pool;
  const ticks = poolData?.data?.ticks;

  const rangeChartRef = useRef<ChartPriceRangeHandle>(null);

  const [selectedAssetA, setSelectedAssetA] = useState<Asset>();
  const [selectedAssetB, setSelectedAssetB] = useState<Asset>();
  const [assetAmountA, setAssetAmountA] = useState("");
  const [assetAmountB, setAssetAmountB] = useState("");
  const [priceFrom, setPriceFrom] = useState("");
  const [priceTo, setPriceTo] = useState("");
  const [initialPrice, setInitialPrice] = useState("");

  const [rangeType, setRangeType] = useState<RangeType>();
  const [rangeFull, setRangeFull] = useState(false);
  const [feeTier, setFeeTier] = useState(feeAmount ? Number(feeAmount) / 10000 : 0.5);
  const [isBase, setIsBase] = useState(true);

  const [priceRangeChanged, setPriceRangeChanged] = useState(false);

  const isNew = poolData?.success == false;

  const normalizedAssetA =
    pool && selectedAssetA
      ? pool.token0.address.toLowerCase() == wethOrToken(selectedAssetA?.address)?.toLowerCase()
        ? ({...selectedAssetA, address: wethOrToken(selectedAssetA?.address)} as Asset)
        : ({...selectedAssetB, address: wethOrToken(selectedAssetB?.address)} as Asset)
      : undefined;
  const normalizedAssetB =
    pool && selectedAssetA
      ? pool.token0.address.toLowerCase() == wethOrToken(selectedAssetA?.address)?.toLowerCase()
        ? ({...selectedAssetB, address: wethOrToken(selectedAssetB?.address)} as Asset)
        : ({...selectedAssetA, address: wethOrToken(selectedAssetA?.address)} as Asset)
      : undefined;

  (() => {
    if (normalizedAssetA && (!+normalizedAssetA?.balance! || !+normalizedAssetA?.price!)) {
      normalizedAssetA.balance =
        assetsByAddress[normalizedAssetA?.address!?.toLowerCase()]?.balance || normalizedAssetA.balance;

      normalizedAssetA.price =
        assetsByAddress[normalizedAssetA?.address!?.toLowerCase()]?.price || normalizedAssetA.price;
    }
    if (normalizedAssetB && (!+normalizedAssetB?.balance! || !+normalizedAssetB?.price!)) {
      normalizedAssetB.balance =
        assetsByAddress[normalizedAssetB?.address!?.toLowerCase()]?.balance || normalizedAssetB.balance;
      normalizedAssetB.price =
        assetsByAddress[normalizedAssetB?.address!?.toLowerCase()]?.price || normalizedAssetB.price;
    }
  })();

  const baseAsset = isBase ? normalizedAssetA! : normalizedAssetB!;
  const quoteAsset = isBase ? normalizedAssetB! : normalizedAssetA!;

  const valueFrom = rangeFull ? 0 : priceFrom === "" ? undefined : +priceFrom;
  const valueTo = rangeFull ? Infinity : priceTo === "" ? undefined : +priceTo;

  const createPosition = async () => {
    setTxId(undefined);
    setNftId(undefined);

    var minAm = valueFrom!;
    var maxAm = valueTo!;
    var _init = price!;

    if (isBase) {
      minAm = new BigNumber(1).div(valueTo!).toNumber();
      maxAm = new BigNumber(1).div(valueFrom!).toNumber();
      _init = new BigNumber(1).div(_init).toFixed();
    }

    const ratio = getTickBounds(pool! as Pool, rangeFull, minAm, maxAm);
    const _normalizedAssetA =
      pool!.token0.address.toLowerCase() == wethOrToken(selectedAssetA?.address)?.toLowerCase()
        ? selectedAssetA
        : selectedAssetB;
    const _normalizedAssetB =
      pool!.token0.address.toLowerCase() == wethOrToken(selectedAssetA?.address)?.toLowerCase()
        ? selectedAssetB
        : selectedAssetA;

    const category = [_normalizedAssetA!?.symbol, _normalizedAssetB!?.symbol].join();
    try {
      setShowModal(true);
      const recipt = await sendTransactionGaEvent("create_lp_v3_transaction", category, () => {
        return addLiquidityV3(
          _normalizedAssetA,
          _normalizedAssetB,
          assetAmountA,
          assetAmountB,
          ratio.tickLower,
          ratio.tickUpper,
          feeTier * 10000,
          1,
          getCurrentTick(pool! as Pool, _init, isNew),
        );
      });

      if (recipt.success) {
        setTxId(recipt.tx);
        const nftId = recipt.logs.find((_) => _.fragment?.name === "IncreaseLiquidity")?.args?.[0];
        setNftId(nftId);
      } else setShowModal(false);
    } catch (e) {
      setShowModal(false);
    }
  };

  const setDepositA = (val) => {
    if (Number(val)) {
      var minAm = valueFrom!;
      var maxAm = valueTo!;
      var _init = price!;
      if (isBase) {
        minAm = new BigNumber(1).div(valueTo!).toNumber();
        maxAm = new BigNumber(1).div(valueFrom!).toNumber();
        _init = new BigNumber(1).div(_init).toFixed();
      }

      const ratio = getTickBounds(pool! as Pool, rangeFull, minAm, maxAm);
      setAssetAmountB(getAmount1Liquidity(pool! as Pool, val, ratio, _init, isNew));
    }

    setAssetAmountA(val);
  };

  const setDepositB = (val) => {
    if (Number(val)) {
      var minAm = valueFrom!;
      var maxAm = valueTo!;
      var _init = price!;
      if (isBase) {
        minAm = new BigNumber(1).div(valueTo!).toNumber();
        maxAm = new BigNumber(1).div(valueFrom!).toNumber();
        _init = new BigNumber(1).div(_init).toFixed();
      }

      const ratio = getTickBounds(pool! as Pool, rangeFull, minAm, maxAm);

      setAssetAmountA(getAmount0Liquidity(pool! as Pool, val, ratio, _init, isNew));
    }

    setAssetAmountB(val);
  };

  useEffect(() => {
    if (inputCurrency && outputCurrency && assets && !selectedAssetA && !selectedAssetB) {
      var assetA_;
      var assetB_;

      for (let i in assets) {
        if (assets[i].address?.toLowerCase() == inputCurrency.toLowerCase()) {
          assetA_ = assets[i];
        }

        if (assets[i].address?.toLowerCase() == outputCurrency.toLowerCase()) {
          assetB_ = assets[i];
        }
      }

      setSelectedAssetA(assetA_);
      setSelectedAssetB(assetB_);
    }
  }, [inputCurrency, outputCurrency, assets]);

  // Get Pool data
  useEffect(() => {
    if (!selectedAssetA || !selectedAssetB) return;
    getFullPoolData(selectedAssetA, selectedAssetB, feeTier)
      .then((data) => {
        //console.log(data);
        setPoolData(data);
        getPairDayData(data.data['poolAddress'], data.data.pool.fee / 100).then(res_ => {
          //get boosted apy
          for(let i in allPairs){
            if(allPairs[i].id && allPairs[i].id.toLowerCase() == data.data['poolAddress'].toLowerCase()){
              if(allPairs[i].rewardTokens){
                res_['base'] = res_.apy
                res_.apy = new BigNumber(res_.apy).plus(allPairs[i].ampAPY ? allPairs[i].ampAPY : 0).toFixed(2)
                res_['boost'] = new BigNumber(allPairs[i].ampAPY ? allPairs[i].ampAPY : 0).toFixed(2)
                res_['rewardTokens'] = allPairs[i].rewardTokens
                res_['boosted'] = true
              }
            }
          }
          setPairInfo(res_)
        }).catch(e => {
          setPairInfo(undefined)
        })
        setAssetAmountA("");
        setAssetAmountB("");
        if (data.success) {
          setTimeout(() => newRangeType("common"), 10);
        } else {
          setPriceRangeChanged(false);
        }
      })
      .catch((e) => {
        console.log("[Get LP v3 error]", e);
        setPoolData(undefined);
      });

    return () => {};
  }, [selectedAssetA, selectedAssetB, feeTier]);

  useEffect(() => {
    if(pairInfo && poolData){
      var res_ = pairInfo
      for(let i in allPairs){
        if(allPairs[i].id && allPairs[i].id.toLowerCase() == poolData!.data['poolAddress'].toLowerCase()){
          if(allPairs[i].rewardTokens){
            res_['base'] = res_.apy
            res_.apy = new BigNumber(res_.apy).plus(allPairs[i].ampAPY ? allPairs[i].ampAPY : 0).toFixed(2)
            res_['boost'] = new BigNumber(allPairs[i].ampAPY ? allPairs[i].ampAPY : 0).toFixed(2)
            res_['rewardTokens'] = allPairs[i].rewardTokens
            res_['boosted'] = true
          }
        }
      }
      setPairInfo(res_)
    }
  }, [allPairs])
  useEffect(() => {
    if (!poolData || !selectedAssetA || !selectedAssetB || !pairInfo) return;

    let res_ = pairInfo
    if(!res_['boosted'] && poolData!.data['poolAddress'])
      for(let i in allPairs){
        if(allPairs[i].id && allPairs[i].id.toLowerCase() == poolData!.data['poolAddress'].toLowerCase()){
          if(allPairs[i].rewardTokens) {
            res_['base'] = res_.apy
            res_.apy = new BigNumber(res_.apy).plus(allPairs[i].ampAPY ? allPairs[i].ampAPY : 0).toFixed(2)
            res_['boost'] = new BigNumber(allPairs[i].ampAPY ? allPairs[i].ampAPY : 0).toFixed(2)
            res_['rewardTokens'] = allPairs[i].rewardTokens
            res_['boosted'] = true
          }
        }
      }

    setPairInfo(res_)
    return () => {};
  }, [allPairs]);
  // Update values after changing base/quote
  const setBase = useCallback(
    (isBase: boolean) => {
      setIsBase((prev) => {
        if (prev !== isBase) {
          const t = (_) => _ && String(1 / +_);
          setAssetAmountA(t);
          setAssetAmountB(t);
          setInitialPrice(t);
        }
        return isBase;
      });
    },
    [isBase],
  );

  // Reset range type when values changes
  useEffect(() => {
    setRangeType("custom");
  }, [priceFrom, priceTo]);

  // Memorized constants
  const isBaseTabs = useMemo(() => {
    return [
      {value: true, label: normalizedAssetB?.symbol},
      {value: false, label: normalizedAssetA?.symbol},
    ];
  }, [normalizedAssetA, normalizedAssetB]);

  const price = useMemo(() => {
    if (isNew || !poolData?.success) return +initialPrice;
    if (poolData?.success) {
      if (isBase) {
        return poolData.data.pool.token0Price.toSignificant();
      }
      return poolData.data.pool.token1Price.toSignificant();
    }
  }, [isNew, initialPrice, selectedAssetA, selectedAssetB, isBase, poolData]);

  // Set initial range when the price changes
  useEffect(() => {
    if (!priceRangeChanged) {
      setTimeout(() => newRangeType("common"));
    }
  }, [price, priceRangeChanged]);

  const getClosestPriceToInput = () => {
    var minAm = valueFrom!;
    var maxAm = valueTo!;

    if (isBase) {
      minAm = new BigNumber(1).div(valueTo!).toNumber();
      maxAm = new BigNumber(1).div(valueFrom!).toNumber();
    }

    const ratio = getTickBounds(pool! as Pool, rangeFull, minAm, maxAm);
    var _prices = getClosestPrice(
      pool! as Pool,
      isBase
        ? {...quoteAsset!, address: wethOrToken(quoteAsset!.address)}
        : {...baseAsset!, address: wethOrToken(baseAsset!.address)},
      ratio,
    );
    return _prices;
  };

  // Ranges tabs selector
  const newRangeType = useCallback(
    (type: RangeType) => {
      if (!price) return;
      setTimeout(() => setRangeType(type), 10);
      if (type === "custom") return;
      if (type === "full") {
        setRangeFull(true);
        return;
      }
      setRangeFull(false);
      const p = Number(price);
      const gap = p * rangeSelectorConfig[type];
      const pFrom = p - gap;
      const pTo = p + gap;
      rangeChartRef.current?.updateAndResetZoom({rangeFrom: pFrom, rangeTo: pTo});

      setPriceFrom(String(pFrom));
      setPriceTo(String(pTo));
    },
    [price, rangeChartRef],
  );

  const onSetPrice = useMemo(
    () => (type: "to" | "from") => (value: string | number) => {
      const [n, dec] = (value + ".0").split(".");
      value = (n + "." + dec.substr(0, quoteAsset?.decimals ?? 18).replace(/0*$/, "")).replace(/\.0*$/, "");
      setPriceRangeChanged(true);
      (type === "to" ? setPriceTo : setPriceFrom)(value);
    },
    [setPriceRangeChanged, quoteAsset],
  );

  // Preselects tabs
  const rangesTabsRaw = useMemo(
    () =>
      [
        {value: "narrow", label: "Narrow"},
        {value: "common", label: "Common"},
        {value: "wide", label: "Wide"},
        ...(!layoutBreak ? ([{value: "custom", label: "Custom"}] as const) : ([] as never)),
        {value: "full", label: "Full"},
      ] as const,
    [layoutBreak],
  );
  const rangesTabs = rangesTabsRaw as Mutable<typeof rangesTabsRaw>;
  type RangeType = (typeof rangesTabs)[number]["value"];

  const chartData = useMemo(() => {
    if (!ticks) return [];
    //return generatePriceRangeData(3000)
    let data: {to: number; amount: number; from: number}[] = [];

    var prev_price = 0;
    var _max = 0;

    for (let i = ticks!.length - 1; i >= 0; i--) {
      let tick = ticks![i];

      let _price = !isBase ? new BigNumber(tick.price1).toNumber() : new BigNumber(tick.price0).toNumber();
      let amount = !isBase
        ? new BigNumber(tick.liquidityLockedToken0).toNumber()
        : new BigNumber(tick.liquidityLockedToken1).toNumber();

      _max += amount;

      if (!prev_price) {
        data.push({amount: 0, from: 0, to: _price});
        prev_price = _price;
      }

      data.push({amount: amount, from: prev_price, to: _price});

      prev_price = _price;

      if (i == 0) {
        data.push({amount: 0, from: prev_price, to: prev_price * 872162});
      }
    }
    return data;
  }, [ticks, isBase]);

  const closeModal = useCallback(() => {
    if (nftId) {
      history.push(`/pool/v3/${nftId}`);
    } else {
      setShowModal(false);
    }
  }, [nftId]);

  return (
    <>
      <Box vertical onlyContain>
        <Title title="Add Liquidity" chip="v3" goBackLink="/pool" />
        <Box minLimit>
          <Box column>

          <Box className={lavaEffectHoverClassName} column>
            <LavaChip
                loading={!pairInfo}
                heading="Liquidity"
                value={`$${numberWithCommas(pairInfo?.tvl, 2)}`}
                alt={1}
                leader
              />
            </Box>
            <CardContent>
              <CardContent.Title title="Select Pair" />
              <CardContent.Content>
                <Flex gap={3}>
                  <AssetSelector asset={selectedAssetA} setAsset={setSelectedAssetA} assets={assets} large />
                  <AssetSelector asset={selectedAssetB} setAsset={setSelectedAssetB} assets={assets} large />
                </Flex>
                <Divider size={6} />
                <Text h7>Fee tier</Text>
                <Divider size={3} />
                <Tabs full tabs={tierTabs} onSelect={setFeeTier} selected={feeTier} />
              </CardContent.Content>
            </CardContent>

            <CardContent>
              <CardContent.Title title="Deposit Amounts" />
              <CardContent.Content>
                <InputCoin value={assetAmountA} setValue={setDepositA} selectedAsset={normalizedAssetA} fixedAsset />
                <Divider size={2} />
                <InputCoin value={assetAmountB} setValue={setDepositB} selectedAsset={normalizedAssetB} fixedAsset />
              </CardContent.Content>
            </CardContent>

            <Button full onClick={createPosition} checkNetwork>
              Add Liquidity
            </Button>
          </Box>

          <Box column>
          <Box className={lavaEffectHoverClassName} column>
               <LavaChip
                loading={!pairInfo}
                heading={
                    pairInfo && pairInfo['rewardTokens'] && pairInfo['rewardTokens'].length > 0 ? 
                    (
                      <Tooltip primary position="right" content={`This pool is boosted with additional rewards. \nBase: ${pairInfo['base']}% \nBoosted: ${pairInfo['boost']}%`}>
                      <Flex>
                        <Text>APY is Boosted</Text>
                        <MdOutlineBolt color="rgba(var(--color-mp-c200))" size={25}/>
                        <RewardToken>
                        {pairInfo && pairInfo['rewardTokens'] ? pairInfo!['rewardTokens'].map((item, index) => {
                          return (
                              <IconAsset style={{marginLeft: '5px'}} contrast asset={{logo: item.logo ? item.logo : unknownToken, symbol: item.symbol} as any} size="s"/>
                          )
                        }) : <></>}
                        </RewardToken>
                      
                      </Flex>

                    </Tooltip>
                    ) : <Text>APY</Text>
                }
                value={`${numberWithCommas(pairInfo?.apy, 2)}%`}
                alt={1}
                palette="purple"
                leader
              />
              {/*
              <LavaChip
                loading={isLoading}
                heading="APY"
                value={`${numberWithCommas(100, 2)}%`}
                alt={2}
                follower
              />
              */}

            </Box>
            <CardContent disabled={!selectedAssetA || !selectedAssetB}>
              <CardContent.Title title="Price Range">
                <Flex gap={3}>
                  <Text bodyMedium color="gray400">
                    {isNew ? "Initial Price" : "Current Price"}:{" "}
                    <Text inline color="black">
                      {formatPrice(price, true, 4, 2)}
                    </Text>
                  </Text>
                  {<Tabs tabs={isBaseTabs} micro selected={isBase} onSelect={setBase} />}
                </Flex>
              </CardContent.Title>
              <CardContent.Content>
                {isNew && (
                  <>
                    <InputCoin
                      subtitle="Initial price"
                      noBalance
                      value={initialPrice}
                      setValue={setInitialPrice}
                      selectedAsset={quoteAsset}
                      fixedAsset
                    />
                    <Divider size={3} />
                    <Message info>
                      You are going to create a new pool.
                      <br />
                      The first step is setting up the initial pool price.
                    </Message>
                    <Divider size={3} />
                  </>
                )}
                <InputCoin
                  subtitle="Low price"
                  noBalance
                  disabled={rangeFull}
                  max={valueTo}
                  value={valueFrom}
                  setValue={onSetPrice("from")}
                  selectedAsset={quoteAsset}
                  fixedAsset
                />
                <Divider size={2} />
                <InputCoin
                  subtitle="High price"
                  noBalance
                  disabled={rangeFull}
                  min={valueFrom}
                  value={valueTo}
                  setValue={onSetPrice("to")}
                  selectedAsset={quoteAsset}
                  fixedAsset
                />
                <Divider size={3} />
                <Tabs tabs={rangesTabs} full selected={rangeType} onSelect={newRangeType} />
                <Divider size={3} />
                <Message info>Selecting the Full option is similar to v2.</Message>
                <Divider size={3} />
                <ChartPriceRange
                  ref={rangeChartRef}
                  empty={
                    !selectedAssetA || !selectedAssetB || !price || valueFrom === undefined || valueTo === undefined
                  }
                  disabled={rangeFull}
                  price={+price!}
                  rangeFrom={valueFrom}
                  onRangeFrom={onSetPrice("from")}
                  rangeTo={valueTo}
                  onRangeTo={onSetPrice("to")}
                  data={chartData}
                />
              </CardContent.Content>
            </CardContent>
          </Box>
        </Box>
      </Box>

      <Modal
        title={`Add liquidity to ${normalizedAssetA?.symbol}/${normalizedAssetB?.symbol}`}
        isVisible={showModal}
        onClose={closeModal}
      >
        <AssetsBlock
          list={[
            {asset: normalizedAssetA!, amount: +assetAmountA},
            {asset: normalizedAssetB!, amount: +assetAmountB},
          ]}
        />

        <Modal.Rows>
          <TxModal>
            {(w) => (
              <>
                <Modal.Row title="Gas Cost Estimate">{w("$" + gasRefund.actual)}</Modal.Row>
                <Modal.Row title="Gas Refund Estimate" color="success">
                  {w("$" + gasRefund.actual)}
                </Modal.Row>
              </>
            )}
          </TxModal>
          <Modal.Row title="Transaction ID">
            <span
              style={{cursor: txId ? "pointer" : ""}}
              onClick={() => txId && window.open("https://era.zksync.network/tx/" + txId, "_blank")}
            >
              {txId ? reduceString(txId, 5, 3) : <Loading />}
            </span>
          </Modal.Row>
        </Modal.Rows>
        {!!txId && (
          <>
            <Message info>Transaction successful</Message>
            <Divider size={4} />
            <Button full onClick={closeModal}>
              View NFT #{Number(nftId)}
            </Button>
          </>
        )}
      </Modal>
    </>
  );
});

const RewardToken = styled.div`
  position: absolute;
  right: -75px;
  top: 0;
  display: flex;
  flex-direction: row;
`;
