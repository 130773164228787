import { useMemo } from "react";
import { formatPrice, numberWithCommas } from "../../../utils";
import { Flex, IconAsset, Skeleton, Table, Text, toTableConfig } from "../../components";
import { Copy } from "../copy";
import { useSwitch } from "../demo.hooks";
import * as SC from "../demo.styles";

export const TableDemo = () => {
  const overflow = useSwitch("Overflow");

  const data = useMemo(() =>
    'WETH,USDC,USDT,KOI,ZORRO,ZK,WBTC,wstETH,wrsETH,USDC,HOLD,USD+,MUTE,ZKDOGE,IDO,MAV,MVX,DAI,HNX,BOLT'
      .split(',')
      .map(coin => ({
        coin,
        volume: Math.random() * (10 ** Math.floor(3 + (Math.random() * 7))),
        price: Math.random() * (10 ** Math.floor(4 - (Math.random() * 10))),
      }))
  , []);

  const config = useMemo(() => {
    return toTableConfig(data, [
      {
        index: "token",
        key: true,
        header: "Token",
        headerAlign: "left",
        align: "left",
        value: d => d.coin,
        content: d => (
          <Flex gap={3}>
            <IconAsset symbol={d.coin} button size="s" />
            <div>
              <Text h6>{d.coin}</Text>
              <Text bodyRegular color="gray400">{d.coin}</Text>
            </div>
          </Flex>
        ),
      },
      ...(!overflow.active ? [] : [{
        index: 'overflow',
        header: <Skeleton active height={18} width={102}/>,
        value: () => 0,
        content: () => <Skeleton active height={18} width={120}/>,
      }]),
      {
        index: "volume",
        header: <>Volume <Text inline bodyMedium color="gray400">24h</Text></>,
        sort: 'desc',
        value: d => d.volume,
        content: d => (<>
          <Text inline bodyMedium color="gray400">$</Text>
          {' '}
          {numberWithCommas(d.volume, 2)}
        </>),
      },
      {
        index: "price",
        header: "Price",
        value: d => d.price,
        content: d => (<>
          <div>
            <Text inline bodyMedium color="gray400">$</Text>
            {' '}
            {formatPrice(d.price, true, 3)}
          </div>
          <Text color="error">
            -0.20%
          </Text>
        </>),
      },
    ]);
  }, [overflow.active]);

  return (
    <SC.Card>
      <h2>
        <>
          <span>Table</span>
          {overflow.component}
        </>
      </h2>
      <Copy text={`<Table>...</Table>`}>
        <Table height="30vh" data={data} config={config}/>
      </Copy>
    </SC.Card>
  );
}