const makeTransition = (trans: string) => {
  const fn = (property: string) =>
    new String(`transition: all ${trans}; ${property ? `transition-property: ${property};` : ""}`) as any as string;
  const attr = fn("all") as any;
  attr.prop = fn;
  return attr as string & {prop: typeof fn};
};

export const transition = {
  slow: makeTransition(".42s ease"),
  normal: makeTransition(".26s ease"),
  fast: makeTransition(".14s ease"),
};
