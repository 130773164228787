import styled, {css, keyframes} from "styled-components";

import {br, card, palette, size, transition} from "../../styles";
import {layoutMq} from "../box";

interface CardProps {
  border?: keyof typeof br;
  size?: "m" | "l";
  height?: string | number;
  breakMobile?: boolean;
  hoverable?: boolean;
  empty?: boolean;
  loading?: boolean;
}

const loadingPositionAnimation = keyframes`
  from {
    background-position-x: -400px;
  }
  to {
    background-position-x: calc(100% + 400px);
  }
`;

const loadingBackgroundAnimation = keyframes`
  0%, 100% {
    background-color: ${palette.white}
  }
  50% {
    background-color: ${palette.gray50};
  }
`;

export const Card = styled.div<CardProps>`
  ${transition.fast}
  ${(_) => card(_.border || (_.size === "l" ? "m" : "xs"))}
  ${(_) => !_.empty && (_.size === "l" ? size.padding(4, 6) : size.padding(2, 3))}

  ${(_) => {
    if (!_.height) return "";
    const css = `
      min-height: ${+_.height}px;
      max-height: ${+_.height}px;
      height: ${+_.height}px;
    `;
    return !_.breakMobile ? css : layoutMq.not(css);
  }}

  ${(_) =>
    _.hoverable &&
    `
    cursor: pointer;

    &:hover {
      ${card("xs", false, palette.gray300)}
    }
  `}

  ${(_) =>
    _.empty &&
    `
    overflow: hidden;
  `}

  ${(_) =>
    _.loading &&
    css`
      background-image: linear-gradient(90deg, transparent 0%, ${palette.gray100} 50%, transparent 100%);
      background-size: 400px 100%;
      background-repeat: no-repeat;
      animation-name: ${loadingPositionAnimation}, ${loadingBackgroundAnimation};
      animation-duration: 4s, 1s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;

      & > * {
        opacity: 0;
        pointer-events: none;
      }
    `}
`;
