import styled from "styled-components";

import {card, flex, microPalette, MicroPalette, palette, size, transition, typo} from "../styles";

export const Wrapper = styled.div`
  ${typo.primary.normal}

  margin: auto;
  max-width: 600px;
  padding-bottom: 64px;

  h2 {
    ${typo.primary.medium}
    font-size: 20px;
  }
`;

export const Card = styled.div<{margin?: boolean; deprecated?: boolean}>`
  ${typo.primary.normal}
  ${card("xl")}
  ${size.padding(6)}

  ${(_) =>
    _.margin &&
    `
    padding-bottom: ${size.raw(6) - 16}px;
  `}

  ${(_) =>
    _.deprecated &&
    `
    opacity: 0.5;
    pointer-events: none;

    h2:before {
      content: "DEPRECATED";
      color: ${palette.gray400};
      margin-right: ${size(2)};
    }
  `}

  h2 {
    ${typo.primary.medium}

    display: flex;
    padding-bottom: ${size(6)};
    font-size: 20px;

    & > span:first-child {
      margin-right: auto;
      font-size: 20px;
    }
    span {
      font-size: 14px;
    }
  }

  & + & {
    margin-top: ${size(8)};
  }
`;

export const TypoWrapper = styled.div<{subtitle?: boolean}>`
  ${flex("baseline")}
  ${size.gap(4)}

  ${(_) =>
    _.subtitle &&
    `
    padding-bottom: 8px;
  `}

  & + & {
    margin-top: ${size(4)};
  }
`;

export const Settings = styled.span`
  padding-left: ${size(6)};
  display: flex;
  align-items: center;

  & > span:first-of-type {
    margin-left: ${size(1)};
  }
  span {
    margin-left: ${size(1)};
  }

  div {
    position: relative;
    top: 1px;
    margin-left: ${size(2)};
  }
`;
export const Setting = styled.span<{active?: boolean; color?: MicroPalette}>`
  ${transition.fast}

  cursor: pointer;
  color: ${palette.gray300};
  box-shadow: 0 2px 0 -1px ${palette.gray300.o(0.33)};

  ${(_) =>
    _.active &&
    `
    pointer-events: none;
    color: ${palette.orchid};
    box-shadow: none;
  `}

  ${(_) =>
    _.color &&
    microPalette[_.color] &&
    `
    ${size.padding(0.5, 2, 1)}

    color: ${microPalette[_.color].c300};
    box-shadow: inset 0 0 0 1px ${microPalette[_.color].c200};
    background: ${microPalette[_.color].c100};
    border-radius: 4px;
    font-size: 0 !important;
    width: 16px;
    height: 16px;

    ${
      _.active &&
      `
      background: ${microPalette[_.color].c200};
      box-shadow: inset 0 0 0 1px ${microPalette[_.color].c300};
    `
    }
  `}
`;

export const Footer = styled.div`
  ${card(false, true)}
  ${flex("center", "center")}

  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 64px;
  z-index: 10;
  border-width: 1px 0 0 0;
`;
