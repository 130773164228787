import {memo, useMemo} from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Flex,
  IconAsset,
  Loading,
  Table,
  Text,
  Tooltip,
  toTableConfig,
} from "../../../../ui/components";
import {numberWithCommas, formatPriceZeros} from "../../../../utils";
import {AnalyticsData} from "../../data";
import {useAnalytics} from "../../provider";
import {AnalyticsCell, AnalyticsHeader} from "./components/cell";
import { AnalyticsSection } from "./components/common-section";

import * as SC from "./index.styles";

interface AnalyticsOverviewTokensProps {
  full?: boolean;
}

export const AnalyticsOverviewTokens = ({full}: AnalyticsOverviewTokensProps) => {
  const {
    data: {tokens},
    extended,
  } = useAnalytics(full, 'tokens');
  const data = full ? extended.tokens : tokens.data;

  const config = useMemo(() => {
    return toTableConfig(data, [
      {
        index: "token",
        key: true,
        header: "Token",
        headerAlign: "left",
        align: "left",
        value: d => d.name,
        content: d => (
          <Flex gap={3}>
            <IconAsset symbol={d.symbol} button size="s" />
            <div>
              <Text h6>{d.name}</Text>
              <Text bodyRegular color="gray400">
                {d.symbol}{" — "}
                <Text bodyRegular color="orchid" bodyMedium inline>
                  <Link to={`/analytics/token/${d.address}`}>
                    See details
                  </Link>
                </Text>
              </Text>
            </div>
          </Flex>
        ),
      },
      {
        index: "volume",
        header: <AnalyticsHeader name="Volume" alt="24h" />,
        value: d => d.volumeDayUsd,
        content: d => <AnalyticsCell data={d} prop="volumeDayUsd" type="coin"/>,
      },
      {
        index: "liquidity",
        sort: 'desc',
        header: <AnalyticsHeader name="Liquidity" />,
        value: d => d.liquidityUsd,
        content: d => <AnalyticsCell data={d} prop="liquidityUsd" type="coin"/>,
      },
      {
        index: "txs",
        header: <AnalyticsHeader name="Tx. Count" alt="24h" />,
        value: d => d.txCount,
        content: d => <AnalyticsCell data={d} prop="txDayCount"/>,
      },
      {
        index: "price",
        header: <AnalyticsHeader name="Price" alt="24h" />,
        value: d => d.price,
        content: d => <AnalyticsCell data={d} prop="price" change="priceChangePercentage" type="price" noTooltip/>,
      },
    ]);
  }, []);

  return (
    <>
      <AnalyticsSection title="Top Tokens" href="/analytics/tokens" isPage={full}>
        <Table showLoading height={SC.getTableSize(full)} data={data} config={config} findKey={d => d.address} />
      </AnalyticsSection>
    </>
  );
};
