import {
  Pool,
  nearestUsableTick,
  TickMath,
  Position,
  tickToPrice,
  priceToClosestTick,
} from "@uniswap/v3-sdk";
import {getFeeToTickSpacing, priceToSqrt} from "../../../web3/v3/liqPositions";
import BigNumber from "bignumber.js";
import JSBI from "jsbi";
import {Asset} from "../../../model/Asset";
import {Price} from "@uniswap/sdk-core";

export const getClosestPrice = (pool: Pool, quoteAsset: Asset, ratio: any) => {
  const _q1 = quoteAsset.address?.toLowerCase();

  const _quote = _q1 == pool.token0.address.toLowerCase() ? pool.token0 : pool.token1;
  const _base = _q1 == pool.token0.address.toLowerCase() ? pool.token1 : pool.token0;

  var res;
  if (pool.token0.address.toLowerCase() != _q1) {
    res = {
      upper: new BigNumber(1).div(tickToPrice(_base, _quote, ratio.tickUpper).toSignificant()).toFixed(_quote.decimals),
      lower: new BigNumber(1).div(tickToPrice(_base, _quote, ratio.tickLower).toSignificant()).toFixed(_quote.decimals),
    };
  } else {
    res = {
      upper: tickToPrice(_quote, _base, ratio.tickUpper).toSignificant(),
      lower: tickToPrice(_quote, _base, ratio.tickLower).toSignificant(),
    };
  }

  return {
    upper: new BigNumber(res.upper).gt(res.lower) ? res.upper : res.lower,
    lower: new BigNumber(res.upper).gt(res.lower) ? res.lower : res.upper,
  };
};

export const getTickBounds = (_pool: Pool, rangeFull: boolean, valueFrom: number, valueTo: number) => {
  if (rangeFull) {
    return {
      tickLower: nearestUsableTick(TickMath.MIN_TICK, getFeeToTickSpacing(_pool.fee)),
      tickUpper: nearestUsableTick(TickMath.MAX_TICK, getFeeToTickSpacing(_pool.fee)),
    };
  }

  var price_high = new BigNumber(valueFrom!).times(Math.pow(10, _pool.token0.decimals)).toFixed(0);
  var price_low = new BigNumber(valueTo!).times(Math.pow(10, _pool.token0.decimals)).toFixed(0);

  var _closest1 = priceToClosestTick(
    //@ts-ignore
    new Price(_pool!.token0, _pool!.token1, price_high, Math.pow(10, _pool.token1.decimals)),
  );
  var _closest2 = priceToClosestTick(
    //@ts-ignore
    new Price(_pool!.token0, _pool!.token1, price_low, Math.pow(10, _pool.token1.decimals)),
  );

  let tickUpper = nearestUsableTick(Number(_closest1.toFixed()), getFeeToTickSpacing(_pool.fee));
  let tickLower = nearestUsableTick(Number(_closest2.toFixed()), getFeeToTickSpacing(_pool.fee));

  return {
    tickUpper: tickLower < tickUpper ? tickUpper : tickLower,
    tickLower: tickLower < tickUpper ? tickLower : tickUpper,
  };
};

const startPriceSqrt = (_pool: Pool, initialPrice) => {
  var _price = new BigNumber(initialPrice).times(Math.pow(10, _pool.token0.decimals)).toFixed(0);
  return priceToSqrt(_price, Math.pow(10, _pool.token1.decimals), 0);
};

export const getAmount1Liquidity = (pool: Pool, val, ratio, initialPrice, newPool) => {
  if (pool) {
    var _pool = pool as Pool;
    if (newPool) {
      const startSqrt = startPriceSqrt(pool, initialPrice);
      const setTick = TickMath.getTickAtSqrtRatio(JSBI.BigInt(startSqrt));
      _pool = new Pool(pool.token0, pool.token1, pool.fee, startSqrt, 0, setTick);
    }

    const position = Position.fromAmount0({
      pool: _pool,
      tickLower: ratio.tickLower,
      tickUpper: ratio.tickUpper,
      amount0: new BigNumber(val).times(Math.pow(10, _pool.token0.decimals)).toFixed(0),
      useFullPrecision: true,
    });

    return new BigNumber(position.mintAmounts.amount1.toString()).div(Math.pow(10, _pool.token1.decimals)).toFixed();
  }

  return "0.00";
};

export const getAmount0Liquidity = (pool: Pool, val, ratio, initialPrice, newPool) => {
  if (pool) {
    var _pool = pool as Pool;
    if (newPool) {
      const startSqrt = startPriceSqrt(pool, initialPrice);
      const setTick = TickMath.getTickAtSqrtRatio(JSBI.BigInt(startSqrt));
      _pool = new Pool(pool.token0, pool.token1, pool.fee, startSqrt, 0, setTick);
    }

    const position = Position.fromAmount1({
      pool: _pool,
      tickLower: ratio.tickLower,
      tickUpper: ratio.tickUpper,
      amount1: new BigNumber(val).times(Math.pow(10, _pool.token1.decimals)).toFixed(0),
    });

    return new BigNumber(position.mintAmounts.amount0.toString()).div(Math.pow(10, _pool.token0.decimals)).toFixed();
  }

  return "0";
};

export const getCurrentTick = (pool: Pool, initialPrice, isNew) => {
  var _pool = (pool as Pool)
  if(isNew){
    const startSqrt = startPriceSqrt(pool, initialPrice);
    const setTick = TickMath.getTickAtSqrtRatio(JSBI.BigInt(startSqrt));
    var _pool = new Pool(pool.token0, pool.token1, pool.fee, startSqrt, 0, setTick)  
  }
  return _pool.tickCurrent;
};
