import { Link, useParams } from "react-router-dom";
import { Box, Button, Flex, IconAsset, LavaChip, lavaEffectHoverClassName, Table, Text, toTableConfig } from "../../../../ui/components";
import { numberWithCommas } from "../../../../utils";
import { useTokenData } from "../../data/token-data";
import { useAnalytics } from "../../provider";
import { AnalyticsPageHeading } from "./components/common-page";

import { AnalyticsOverviewPairs } from "./overview-pairs";
import { AnalyticsOverviewTxs } from "./overview-txs";

import * as SC from "./index.styles";
import { useGetPairsV2Data } from "../../data/pairs-v2";
import { usePairData } from "../../data/pair-data";
import { AnalyticsSection } from "./components/common-section";
import { useMemo } from "react";
import { AnalyticsCell } from "./components/cell";
import { AnalyticsChartVolumePair } from "./components/chart-volume/pair";
import { usePairDataV3 } from "../../data/pair-data-v3";

interface AnalyticsPairPageProps {}

export const AnalyticsPairPageV3 = ({}: AnalyticsPairPageProps) => {
  const {address} = useParams<{address: string}>() ;
  const {data: {globalData}} = useAnalytics();
  const {ready, pair, txs, chart} = usePairDataV3(globalData.data, address)
  const tokenData = useMemo(() =>
    [
      {...pair.token0Data, reserve: pair.rest?.totalValueLockedToken0},
      {...pair.token1Data, reserve: pair.rest?.totalValueLockedToken1},
    ]
      .filter(_ => !!_.symbol)
      .map(_ => ({..._, price: _.derivedETH * globalData?.data?.ethPrice!}))
      .map((_, i, list) => ({
        ..._,
        liquidity: _.reserve * _.price,
        conversion: _.price / list[1 - i].price,
        cSymbol: list[1 - i].symbol,
      }))
  , [globalData, pair]) 


  const config = useMemo(() => {
    return toTableConfig(tokenData, [
      {
        index: "token",
        header: "Token",
        headerAlign: "left",
        align: "left",
        value: d => d.name,
        content: d => (
          <Flex gap={3}>
            <IconAsset symbol={d.symbol} button size="s" />
            <div>
              <Text h6>{d.name}</Text>
              <Text bodyRegular color="gray400">
                {d.symbol}{" — "}
                <Text bodyRegular color="orchid" bodyMedium inline>
                  <Link to={`/analytics/token/${d.address}`}>
                    See details
                  </Link>
                </Text>
              </Text>
            </div>
          </Flex>
        ),
      },
      {
        index: "liquidity",
        header: "liquidity",
        value: d => d.reserve,
        content: d => <AnalyticsCell data={d} prop="liquidity" type="coin"/>,
      },
      {
        index: "amount",
        header: "Amount",
        value: d => d.reserve,
        content: d => <AnalyticsCell data={d} prop="reserve" type="coin" symbol="symbol"/>,
      },
      {
        index: "price",
        header: "Price",
        value: d => d.price,
        content: d => <AnalyticsCell data={d} prop="price" type="price"/>,
      },
      {
        index: "conversion",
        header: "Conversion",
        value: d => d.price,
        content: d => <>
          <div>
            <AnalyticsCell data={d} value={1} type="price" symbol="symbol"/>
            &nbsp;≈&nbsp;
            <AnalyticsCell data={d} prop="conversion" type="price" symbol="cSymbol"/>
          </div>
        </>,
      },
      {
        index: "address",
        key: true,
        header: "Address",
        value: d => d.address,
        content: d => <AnalyticsCell data={d} prop="address" type="hash" hashType="contract"/>,
      },
    ]);
  }, []);

  return (
    <>
      <AnalyticsPageHeading
        loading={!ready}
        title={pair.token0}
        titleAlt={pair.token1}
        titleContent={<>
          <a href={`https://era.zksync.network/address/${address}`} target="_blank">
            <Button secondary size="s">View on Block Explorer</Button>
          </a>
        </>}
        goBackLink="/analytics/pairs"
      >
        <Box column>
          <Box>
            <Box className={lavaEffectHoverClassName} column>
              <LavaChip
                loading={!ready}
                heading="Liquidity"
                value={`$${numberWithCommas(pair.liquidity, 2)}`}
                alt={1}
                leader
              />
              <LavaChip
                loading={!ready}
                heading="APY"
                value={`${numberWithCommas(pair.apy, 2)}%`}
                alt={2}
                follower
              />
            </Box>
            <Box className={lavaEffectHoverClassName} column>
              <LavaChip
                loading={!ready}
                heading="Volume 24h"
                value={`$${numberWithCommas(pair.volumeDayUsd, 2)}`}
                alt={1}
                palette="purple"
                leader
              />
              <LavaChip
                loading={!ready}
                heading="Fees 24h"
                value={`$${numberWithCommas(pair.feesDayUsd, 2)}`}
                alt={2}
                palette="purple"
                follower
              />
            </Box>
          </Box>

          <Box extendOnMobile>
            <Box column extendOnMobile>
              <AnalyticsChartVolumePair groups={chart} token0={pair.token0} token1={pair.token1} />
            </Box>
          </Box>

          <AnalyticsSection title="Pool Tokens" hideLink>
            <Table showLoading height={192} data={tokenData} config={config} />
          </AnalyticsSection>
          <AnalyticsOverviewTxs data={txs.txs || []} />
        </Box>
      </AnalyticsPageHeading>
    </>
  );
};
