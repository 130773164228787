import styled from "styled-components";

import {getColor, palette} from "../../styles";

export const Palette = styled.div<{color: keyof typeof palette}>`
  width: 40px;
  height: 40px;
  border: 2px solid black;
  position: relative;
  display: inline-block;
  border-radius: 4px;
  background: ${(_) => getColor(_.color)};
  margin-right: 30px;
  margin-bottom: 30px;

  &:before {
    content: "${(_) => _.color}";
    position: absolute;
    font-size: 10px;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 4px;
    color: ${palette.black};
    pointer-events: none;
  }
`;
