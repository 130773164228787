import styled from "styled-components";

export const HashBlock = styled.div`
  width: 96px;
`;

export const BlockiesImg = styled.img`
  height: 24px;
  width: 24px;
  min-width: 24px;
  border-radius: 4px;
  overflow: hidden;
`