import { memo, ReactNode } from "react";
import blockies from "blockies";

import { Flex, Text, Tooltip, TooltipProps } from "../../../../../../ui/components";
import { dateFormat, formatPriceZeros, numberWithCommas, timeFormat } from "../../../../../../utils";

import * as SC from './index.styles';

export const AnalyticsHeader = memo(function ({name, alt}: {name: string, alt?: string}) {
  return <>
    {name}
    {alt && <>&nbsp;<Text inline inherit color="gray400">{alt}</Text></>}
  </>;
})

type dType = number | string;

interface AnalyticsCellProps<T, P extends keyof T, R extends keyof T> {
  data: T & {[k in (P & R)]: dType} & {v2?: {[k in P]: dType}, v3?: {[k in P]: dType}};
  prop?: P;
  value?: number;
  change?: R;
  symbol?: R;
  type?: "coin" | "price" | "date" | "hash";
  hashType?: "account" | "tx" | "contract";
  tooltipPosition?: TooltipProps['position'];
  noTooltip?: boolean;
}

const coin = <Text inline inherit color="gray400">$&nbsp;</Text>;

export const AnalyticsCell = memo(function AnalyticsCellComp<T, P extends keyof T, R extends keyof T>({
  data = {} as any,
  prop,
  value: injectedValue,
  change,
  symbol,
  type,
  hashType,
  tooltipPosition,
  noTooltip,
}: AnalyticsCellProps<T, P, R>) {
  const value = (injectedValue || data[prop]) as number;
  let content: ReactNode;
  let decimals = 2;
  if (type === "coin" || type === "price") {
    content = <>
      {!symbol && coin}
      {type === "coin" ? numberWithCommas(value, 2) : formatPriceZeros(value, 3)}
      {symbol && <Text inline inherit color="gray400">&nbsp;{data[symbol]}</Text>}
    </>;
  } else if (type === "date") {
    content = <>
      <Text bodyMedium>{dateFormat.format(value)}</Text>
      <Text bodyRegular color="gray400">{timeFormat.format(value)}</Text>
    </>;
  } else if (type === "hash") {
    const hash = `${String(value).substr(0, 6)}...${String(value).substr(-4)}`;
    const img = blockies({seed: String(value)});
    content = <>
      <Flex inline align="center" justify="space-between" gap={2}>
        <SC.BlockiesImg src={img.toDataURL()} />
        <SC.HashBlock>
          <Text bodyMedium>{hash}</Text>
          <Text bodyRegular color="orchid">
            {hashType === "tx"
              ? <a target="_blank" href={`https://era.zksync.network/tx/${value}`}>View tx.</a>
              : <a target="_blank" href={`https://era.zksync.network/address/${value}`}>
                  View {hashType === "account" ? "account" : "contract"}
                </a>
            }
          </Text>
        </SC.HashBlock>
      </Flex>
    </>;
  } else {
    decimals = 0;
    content = numberWithCommas(value, 0);
  }

  if (change) {
    const value = +Number(data[change] || 0).toFixed(2);
    const color = value === 0 ? undefined : value > 0 ? "success" : "error";
    content = (
      <Flex column align="flex-end">
        <div>{content}</div>
        <div>
          <Text bodyRegular color={color}>{numberWithCommas(value, 2)}%</Text>
        </div>
      </Flex>
    );
  }

  if ((data?.v2?.[prop!] !== undefined || data?.v3?.[prop!] !== undefined) && !noTooltip) {
    const toValue = (n: string | number) => (
      <span>
        {type === "coin" && coin}
        {numberWithCommas(n, decimals)}
      </span>
    );
    return (
      <Tooltip
        position={tooltipPosition}
        content={
          <>
            <Flex justify="space-between" gap={3}>
              <Text bodyMedium>v2:</Text>
              {toValue(data.v2?.[prop as string])}
            </Flex>
            {data.v3 && (
              <Flex justify="space-between" gap={2}>
                <Text bodyMedium>v3:</Text>
                {toValue(data.v3?.[prop as string])}
              </Flex>
            )}
          </>
        }
      >
        <div>
          {content}
        </div>
      </Tooltip>
    );
  }

  return content;
});