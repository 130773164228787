import styled from "styled-components";

import {br, card, flex, palette, size} from "../../styles";
import {layoutMq} from "../box";

export const Wrapper = styled.div<{card?: boolean}>`
  ${br.m}
  ${flex("center", "center", "column")}
  ${size.gap(4)}
  ${size.padding(6, 0)}

  width: 100%;
  max-width: 320px;
  margin: auto;
  height: 100%;
  color: ${palette.gray400};
  text-align: center;

  ${(_) =>
    _.card &&
    `
    ${card("m")}
    ${size.padding(6)}
    ${size.gap(12)}

    max-width: initial;
    min-height: 120px;

    ${layoutMq.not(`
      flex-direction: row;
    `)}
  `}
`;
export const Content = styled.div<{card?: boolean}>`
  ${size.gap(3)}
  ${flex("center", "center", "column")}

  max-width: 300px;
`;
