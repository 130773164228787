import React, {memo, useMemo} from "react";
import {Link} from "react-router-dom";
import {WalletState} from "../../../state";
import {useWalletSelector} from "../../../state/hooks";
import {Card, CardContent, Chip, Flex, IconAsset, LavaChip, Text, useLayoutMq} from "../../../ui/components";
import {sentGaEvent} from "../../../utils";
import {Asset, Pair} from "../../../web3/types";
import {useWalletHook} from "../../../web3/walletHook";

export interface PairsListProps {
  pairs: Pair[];
  setToTrade: (tokenA: Asset, tokenB: Asset) => void;
  pairsLoading: boolean;
  assetA?: Asset;
  assetB?: Asset;
}

export const PairsListContent = memo(function PairsListContentComponent({
  pairs,
  setToTrade,
  pairsLoading,
  assetA,
  assetB,
}: PairsListProps) {
  const breakLayout = useLayoutMq();
  const {isETH} = useWalletHook<true>();
  const farms = useWalletSelector((state: WalletState) => state.allPairs);

  const farm = useMemo(() => {
    const g = (address: string = "") => (isETH(address) ? true : address || undefined);
    const addresses = [g(assetA?.address), g(assetB?.address)];
    if (addresses.includes(undefined)) return;
    return farms.find((_) => addresses.includes(g(_.token0.id)) && addresses.includes(g(_.token1.id)));
  }, [farms, assetA, assetB]);

  return (
    <>
      {farm && false && (
        <Link
          to={`/pool/${farm.token0.id}/${farm.token1.id}/${farm.stable}`}
          onClick={() => sentGaEvent("swap_banner_lp_clicked", [assetA?.symbol, assetB?.symbol].join())}
        >
          <LavaChip
            heading={`Earn up to ${(+farm.apy + +(farm.ampAPY || 0)).toFixed?.(2)}% adding liquidity on ${assetA?.symbol}―${assetB?.symbol}`}
            arrow
            micro
          />
        </Link>
      )}
      <CardContent mobileMaxHeight={278} forceContract>
        <CardContent.Title title="Trending pairs">{}</CardContent.Title>
        <CardContent.Content>
          {/* Carousel */}
          <Flex gap={1.5} column>
            {!pairs.length
              ? [...new Array(9)].map(() => <Card loading height="64" />)
              : pairs.map((pair) => (
                  <Card
                    hoverable
                    breakMobile
                    size={breakLayout ? "l" : "m"}
                    height="64"
                    key={pair.token1.address + pair.token2.address}
                    onClick={() => setToTrade(pair.token1, pair.token2)}
                  >
                    <Flex gap={2} expandVertical align="center" justify="flex-end" wrap>
                      <Flex>
                        <IconAsset stack asset={pair.token1} button size="s" />
                        <IconAsset stack asset={pair.token2} button size="s" />
                      </Flex>
                      <Text h6>
                        {pair.token1.symbol}
                        <Text inline color="gray300">
                          {" ― "}
                        </Text>
                        {pair.token2.symbol}
                      </Text>
                      <Flex.Grow />
                      <Flex.Break />
                      <Flex alignSelf="flex-end">
                        <Chip label="Price" value={pair.price} />
                      </Flex>
                      {pair.apy && (
                        <Flex alignSelf="flex-end">
                          <Chip label="APY" value={pair.apy + "%"} valueColor={true} />
                        </Flex>
                      )}
                    </Flex>
                  </Card>
                ))}
          </Flex>
        </CardContent.Content>
      </CardContent>
    </>
  );
});
