import {useQuery} from "@tanstack/react-query";
import {useMemo} from "react";
import {fetchGraphQL} from "../../../utils/gql-utils";
import {getGraphQLUrl} from "../../../web3/apollo/client";
import {AnalyticsGlobalData} from "./global";
import { transformTokens } from "./top-tokens.utils";
import { getBlocksTag } from "./utils";

const pairSnippet = `
  a: token0 {
    p: derivedETH
  }
  b: token1 {
    p: derivedETH
  }
  v: volumeUSD
  l: totalValueLockedUSD
`;

const starting_block = 32821671

export const usePairChartV3 = (globalData?: AnalyticsGlobalData, address?: string) => {
  const query = useQuery({
    queryKey: ["pair-chart-v3", address],
    queryFn: async () => {
      try {
        if (!globalData) throw "No global data";
        if (!globalData.blocks.chart) throw "No charts blocks";

        const _blocks = globalData.blocks.chart.filter(val => Number(val.block) > starting_block)

        const url = getGraphQLUrl(true);
        const filter = `where: {id: ${JSON.stringify(address)}}`;
        const now = Date.now();
        const p = globalData?.prices;
        const {data} = await fetchGraphQL(
          url,
          `
          query {
            ${_blocks.map(({block, tag, gap}) => `
              e_${tag}_${block}_${gap}: pools(block: {number: ${block}}, ${filter}) {
                ${pairSnippet}
              }
            `)}
          }
        `,
        );
        
        const groups: Record<'h' | 'd' | 'w' | 'm', Record<"timestamp" | "liquidity" | "volume" | "priceA" | "priceB" | "priceAB" | "priceAB", number>[]> = {} as any;
        Object.entries(data)
          .forEach(([key, value]: any) => {
            const [, group, block, gap] = key.split('_');
            if (!groups[group]) {
              groups[group] = [];
            }
            const v = value[0] || {};
            groups[group].push(({
              priceA: v.a?.p * p?.[group]?.[gap],
              priceB: v.b?.p * p?.[group]?.[gap],
              liquidity: +v.l,
              volume: v.v,
              timestamp: now - (+gap * 1000),
            }))
          })
        Object.entries(groups)
          .map(([key, group]) => {
            groups[key] = group
              .sort(({timestamp: a}, {timestamp: b}) => b - a)
              .map((_, i, list) => ({
                ..._,
                volume: _.volume - list[i + 1]?.volume || 0,
                priceAB: _.priceA / _.priceB,
                priceBA: _.priceB / _.priceA,
              }))
              .slice(0, -1)
              .reverse()
          });
        return groups;
      } catch (e) {
        console.warn(e);
        throw e;
      }
    },
    enabled: !!globalData && !!globalData?.blocks?.chart && !!address && !!globalData?.prices,
    refetchOnWindowFocus: false,
  });

  return {
    ...query,
  };
};
