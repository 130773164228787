
import { AcrossChain } from "@across-protocol/app-sdk";
import { Icon, Search, Text, Modal, ModalProps, Logo, Flex } from "../../../../ui/components";
import { ButtonPrimaryMedium } from "../../../../common/buttons/Button";
import BigNumber from "bignumber.js";
import { useWalletHook } from "../../../../web3/walletHook";
import { formatPrice } from "../../../../utils";
import {useState, useEffect, useMemo} from "react";
import styled, {css} from "styled-components";
import {ListItem} from "../../../../common/ListItem";
import { AssetSection } from "../../../../common/section/AssetSection";
import angleIcon from "../../../../assets/images/icons/angle-light.svg";
import { SectionRow, SectionWithBorder } from "../../../../common/section/Section";
import { SymbolWithLogo } from "../../../../common/SymbolWithLogo";

interface AssetsListModalProps {
  chains: AcrossChain[] | undefined;
  chain: AcrossChain["chainId"] | undefined;
  onChainChange: (_chainId: AcrossChain["chainId"]) => void;
  id?: string;
}

export const ChainSelect = ({chains, chain, onChainChange}: AssetsListModalProps) => {
  const [isAssetsModalVisible, setIsAssetsModalVisible] = useState(false);
  const currentChain = chains?.find((_chain) => _chain.chainId == chain)

  useEffect(() => {
    return () => {};
  }, []);
  
  const changeChain = (id) => {
    onChainChange(parseInt(id.toString()))
    setIsAssetsModalVisible(false)
  }

  return (
    <SectionWithBorder style={{padding: "0", width: '100%'}}>

    <SectionRow>
      {chain === undefined ? (
        <SelectTokenButton onClick={() => setIsAssetsModalVisible(true)}>Select chain</SelectTokenButton>
      ) : (
        <SelectedAssetButton onClick={() => setIsAssetsModalVisible(true)}>
          <Flex>
            <Icon imageFallback={currentChain ? currentChain.logoUrl.replace('zk_sync', 'zk-sync') : ""} style={{marginRight: "5px"}} />
            <Text bodyLarge>{currentChain ? currentChain.name : ""}</Text>
          </Flex>
        </SelectedAssetButton>
      )}
    </SectionRow>

    <StyledModal title="Select chain" isVisible={isAssetsModalVisible} onClose={() => setIsAssetsModalVisible(false)}>
      <AssetsList>
      {chains && chains.map((chain) => (
              <ListItem key={chain.chainId} onClick={() => changeChain(chain.chainId)}>
                <Flex>
                  <Icon imageFallback={chain.logoUrl.replace('zk_sync', 'zk-sync')} size="m" style={{marginRight: '5px'}}/>
                  <Text bodyLarge> {chain.name}</Text>
                </Flex>

              </ListItem>
        ))}
      </AssetsList>
    </StyledModal>
    </SectionWithBorder>
  );
};

const StyledModal = styled(Modal)`
  left: calc(50% - 200px);
  top: 15%;

  overflow-y: hidden;
  height: 70%;
  text-align: left;
  justify-content: flex-start;

  @media (max-width: 500px) {
    width: 100%;
    left: 0;
    top: initial;
  }
`;

const AssetsList = styled.ul`
  margin-top: 16px;
  max-height: 375px;
  min-height: 375px;

  overflow-y: scroll;
`;

const Divider = styled.div`
  position: relative;
  flex-direction: row;
  display: flex;
  justify-content: space-between;

  width: 100%;
`;

const RowNames = styled.div`
  font-size: 14px;
  color: var(--color-gray);
`;

const angleIconStyles = css`
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    width: 10px;
    height: 5px;
    transform: translateY(-50%);
  }
`;

const SelectTokenButton = styled.button`
  width: 100%;
  color: rgb(var(--color-black));
  height: 100%;
  text-align: left;
  padding: 16px 24px;

  ${angleIconStyles}

  &::after {
    right: 27.5px;
    background: url(${angleIcon}) center no-repeat;
  }
`;

const SelectedAssetButton = styled.button`
  position: relative;
  width: 100%;
  text-align: right;
  ${angleIconStyles}

  padding: 10px 20px;

  &::after {
    right: 27.5px;
    background: url(${angleIcon}) center no-repeat;
  }
`;
