import React, {useState, useMemo, useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";
import styled from "styled-components";
import arrowIcon from "../../../assets/images/icons/arrows.svg";
import {ConfirmTransactionModal} from "../../../common/modal/ConfirmTransactionModal";
import {SuccessfulSubmitModal} from "../../../common/modal/SuccessfulSubmitModal";
import {ErrorModal} from "../../../common/modal/ErrorModal";

import {SlipageToleranceModal} from "./SlipageToleranceModal";

import {Asset} from "../../../model/Asset";
import BigNumber from "bignumber.js";
import {reduceString, getCleanNumber, sendTransactionGaEvent, formatPrice} from "../../../utils";

import {WalletState} from "../../../state";
import {useWalletHook} from "../../../web3/walletHook";
import {useAccount} from "wagmi";
import {useWalletSelector} from "../../../state/hooks";
import { motion, AnimatePresence } from 'framer-motion';
import { formatNumber } from "../../../utils";

import {
  Button,
  CardContent,
  Divider,
  Flex,
  Icon,
  InputCoin,
  Text,
  layoutMq,
  Loading,
  Modal as CustomModal,
  Tabs
} from "../../../ui/components";

import { IoIosArrowUp } from "react-icons/io";

import * as SC from "./ExchangeWidget.styles";
import {ExchangeSmartRoute} from "./ExchangeSmartRoute";
import {TxModal} from "../../+common/TxModal";
import { palette } from "../../../ui/styles";
import { BridgeSection } from "./BridgeSection";

const queryString = require("query-string");

enum Modal {
  Pair,
  Confirm,
  Submitted,
  Switch,
  Connect,
  Slipage,
  Error,
}

enum Direction {
  Input = 1,
  Output = 2,
}

interface ReadOnlyURLSearchParams extends URLSearchParams {
  append: never;
  set: never;
  delete: never;
  sort: never;
}

export const ExchangeWidget = ({
  callBackSelect,
  externalSetTokens,
  setAdvancedChart,
  advancedChart,
  setShowTrending,
  showTrending,
}) => {
  let location = useLocation();
  const history = useHistory();

  const searchParams = useMemo(
    () => new URLSearchParams(location.search) as ReadOnlyURLSearchParams,
    [location.search],
  );

  const {isConnected, address} = useAccount();

  const {
    prepareTrade,
    prepareTradeSmart,
    executeTrade,
    executeTradeSmart,
    getTokenFromContract,
    getTokenInformation,
    destroyCurrentTrade,
    getFeeAsset,
  } = useWalletHook();

  const [modal, setModal] = useState<Modal | null>(null);
  const closeModal = () => setModal(null);
  const [modalError, setModalError] = useState("");

  const [sendAmount, setSendAmount] = useState("");
  const [getAmount, setGetAmount] = useState("");
  const [gasSaved, setGasSaved] = useState("");
  const [gasSpent, setGasSpent] = useState("");
  const [confirmTime, setConfirmTime] = useState("0.00");
  const [txHash, settxHash] = useState("");

  const [route, setRoute] = useState<any>({});
  const [routeText, setRouteText] = useState<any>(null);

  const [path, setPath] = useState<Array<String> | null>([]);

  const [token1, setToken1] = useState<Asset | undefined>(undefined);
  const [token2, setToken2] = useState<Asset | undefined>(undefined);

  const [openDialog, setOpenDialog] = useState(false)

  const [minReceived, setMinReceived] = useState("0");
  const [liquidityProviderFee, setLiquidityProviderFee] = useState(0);
  const [price, setPrice] = useState(0);
  const [hasEnoughAllowance, setHasEnoughAllowance] = useState(false);
  const [allowance, setAllowance] = useState("0");
  const [hasEnough, setHasEnough] = useState(true);
  const [priceImpact, setPriceImpact] = useState("0");
  const [stable, setStable] = useState([]);

  const [slippage, setSlippage] = useState(0.95);
  const [currentSwapCost, setCurrentSwapCost] = useState("0.00");

  const [calculating, setCalculating] = useState(false);
  const [success, setSuccess] = useState(false);
  const [hasEth, setHasEth] = useState(false);
  const [gettingTrade, setGettingTrade] = useState(false);

  const [widgetSection, setWidgetSection] = useState("a");

  //1 = input is exact, 2 = output is exact
  const [direction, setDirection] = useState(Direction.Input);

  const trade = useWalletSelector((state: WalletState) => state.cachedPairSmart);
  const assets: any = useWalletSelector((state: WalletState) => state.tokens); //useWalletSelector(() => store.getState().tokens)
  const gasRefund = useWalletSelector((state: WalletState) => state.gasEstimateRefund);

  const setTokensFunc = (tokenA, tokenB) => {
    clearWidget();
    setToken1(tokenA);
    setToken2(tokenB);

    // @ts-expect-error
    searchParams.set("inputCurrency", tokenA.address);
    // @ts-expect-error
    searchParams.set("outputCurrency", tokenB.address);
    history.replace({search: searchParams.toString()});
  };

  const setToken1Parent = async (val) => {
    if (token2) callBackSelect(val, token2);

    setToken1(val);

    // @ts-expect-error
    searchParams.set("inputCurrency", val.address);
    history.replace({search: searchParams.toString()});

    if (token2 && !new BigNumber(sendAmount).eq(0) && sendAmount != "") {
      setCalculating(true);
      setGetAmount("");
      var res = await prepareTradeSmart(val, token2, sendAmount, slippage);
    } else {
      destroyCurrentTrade();
    }
  };

  const setToken2Parent = async (val) => {
    if (token1) callBackSelect(token1, val);
    setToken2(val);

    // @ts-expect-error
    searchParams.set("outputCurrency", val.address);
    history.replace({search: searchParams.toString()});

    if (token1 && !new BigNumber(sendAmount).eq(0) && sendAmount != "") {
      setCalculating(true);
      setGetAmount("");
      var res = await prepareTradeSmart(token1, val, sendAmount, slippage);
    } else {
      destroyCurrentTrade();
    }
  };

  const onSubmit = () => {};

  const clearWidget = () => {
    destroyCurrentTrade();
    setModal(null);
    //setToken1(undefined)
    //setToken2(undefined)
    setSendAmount("");
    setGetAmount("");
  };

  const switchPairs = async () => {
    var tokenTemp = token1;

    setToken1(token2);
    setToken2(tokenTemp);
    setSendAmountFunction(getAmount, token2, tokenTemp);
    setGetAmount("");
  };

  const setSendAmountFunction = async (r, tokenA: undefined | Asset, tokenB: undefined | Asset) => {
    var match = ("" + r).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
    var dec = match ? Math.max(0, (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0)) : 0;
    if (dec > tokenA!?.decimals) setSendAmount(new BigNumber(r).toFixed(Number(tokenA!?.decimals)));
    else setSendAmount(r);

    setDirection(Direction.Input);

    if (new BigNumber(r).eq(0) || r == ".") setGetAmount("0");

    if (r.slice(-1) != ".") {
      await prepareTradeLocal(r, Direction.Input, tokenA, tokenB);
    }
  };

  const setGetAmountFunction = async (r) => {
    setGetAmount(r);
    //setDirection(Direction.Output);
    return;
    // if (isConnected) {
    //   await prepareTradeLocal(r, Direction.Output, undefined, undefined)
    // }
  };

  const setSlippageChange = async (r) => {
    setSlippage(r);
    setCalculating(true);
    await prepareTradeSmart(token1, token2, sendAmount, r);
    setCalculating(false);
  };

  const prepareTradeLocal = async (r, dir, tokenA: undefined | Asset, tokenB: undefined | Asset) => {
    setCalculating(true);
    try {
      var res = await prepareTradeSmart(tokenA ? tokenA : token1, tokenB ? tokenB : token2, r, slippage);
      setCalculating(false);
      return res;
    } catch (e) {}

    setCalculating(false);
  };

  const sendTx = async () => {
    try {
      const category = [token1!?.symbol, token2!?.symbol].join();
      const res = await sendTransactionGaEvent("swap_transaction", category, () => {
        return executeTradeSmart(token1, token2, sendAmount, minReceived, route, allowance, getAmount, slippage);
      });

      destroyCurrentTrade();

      if (res.success == true) {
        setGasSaved(res.gasSavedUSD!);
        setGasSpent(res.gasSpentUSD!);
        setConfirmTime(res.time);
        settxHash(res.tx);

        setModal(Modal.Submitted);
      } else {
        setModalError(res.error);
        setModal(null);
        clearWidget();
      }
    } catch (e) {
      destroyCurrentTrade();
      setModalError(JSON.stringify(e));
      setModal(null);
      clearWidget();
    }
  };

  const getSwapButtonStatus = () => {
    if (isConnected == false) {
      return "Connect Wallet";
    }

    if (calculating == true) return "";
    else if (sendAmount == "0" || sendAmount == "") return "Enter amount";
    else if (hasEnough == false) return "Insufficient balance";
    else if (success == false) return "Insufficient liquidity";
    else if (hasEth == false) {
      let feeAsset = getFeeAsset();
      return `Need ${feeAsset.symbol} for gas`;
    } else if (hasEnoughAllowance == false)
      return "Approve" + (new BigNumber(priceImpact).gte(5) ? " (high price impact)" : "");
    else if (
      (trade && trade.routeText && trade.routeText.includes("Wrap")) ||
      (trade && trade.routeText && trade.routeText.includes("Unwrap"))
    )
      return trade.routeText;
    else if (new BigNumber(priceImpact).gte(5)) return "Swap (high price impact)";
    else return "Swap";
  };

  useEffect(() => {
    if (trade && new BigNumber(trade.baseConvertRequest).eq(sendAmount)) {
      setCalculating(false);
      setSuccess(true);
      setGetAmount(new BigNumber(trade.expectedConvertQuote).toFixed());
      setMinReceived(new BigNumber(trade.minAmountConvertQuote).toFixed());
      //setSendAmount(new BigNumber(trade.baseConvertRequest).toFixed())

      /*
      setLiquidityProviderFee(
        Number(new BigNumber(trade.liquidityProviderFee[0]).times(trade.baseConvertRequest).div(10000).toPrecision(6)),
      );
      */
      setPrice(Number(new BigNumber(trade.baseConvertRequest).div(trade.expectedConvertQuote).toFixed(6)));
      //setStable(trade.stable);
      setHasEth(trade.hasETH);
      setPriceImpact(new BigNumber(trade.priceImpact).toFixed(2));
      setHasEnoughAllowance(trade.hasEnoughAllowance);
      setAllowance(trade.fromAllowance);
      setRoute(trade.routes);
      setRouteText(trade.routeText);
      setHasEnough(trade.fromBalance.hasEnough || new BigNumber(trade.fromBalance).gte(sendAmount));
    } else if (trade == null) {
      setSuccess(false);
      setPriceImpact("0");
      setRoute({});
      setRouteText(null);
    }

    return () => {};
  }, [trade]);

  useEffect(() => {
    externalSetTokens.current = setTokensFunc;
    return () => {
      destroyCurrentTrade();
    };
  }, []);

  useEffect(() => {
    var url = queryString.parse(location.search);
    if (url.inputCurrency) {
      let _token1: Asset | undefined = undefined;
      if (
        url.inputCurrency.toLowerCase() == "eth" ||
        url.inputCurrency.toLowerCase() == "0x5aea5775959fbc2557cc8789bc1bf90a239d9a91"
      )
        _token1 = getTokenFromContract("0x0000000000000000000000000000000000000000");
      else {
        _token1 = getTokenFromContract(url.inputCurrency);
      } 

      if (!token1 && _token1) setToken1(_token1);

            //load token into store if we do not have it
      if(_token1 && !_token1.active && _token1.address){
        getTokenInformation(_token1.address).then(res => {})
      }
    }
    if (url.outputCurrency) {
      let _token2: Asset | undefined = undefined;
      if (
        url.outputCurrency.toLowerCase() == "eth" ||
        url.outputCurrency.toLowerCase() == "0x5aea5775959fbc2557cc8789bc1bf90a239d9a91"
      )
        _token2 = getTokenFromContract("0x0000000000000000000000000000000000000000");
      else {
        _token2 = getTokenFromContract(url.outputCurrency);
      }

      if (!token2 && _token2) setToken2(_token2);

      //load token into store if we do not have it
      if(_token2 && !_token2.active && _token2.address){
        getTokenInformation(_token2.address).then(res => {})
      }
    }

    if (token1) {
      setToken1(assets[token1!.address!.toLowerCase()]);
    }
    if (token2) {
      setToken2(assets[token2!.address!.toLowerCase()]);
    }

    return () => {};
  }, [assets]);

  const showDetails =
    token1 && token2 && sendAmount != "0" && getAmount != "0" && sendAmount != "" && getAmount != "" && success;

  return (
    <Wrapper>
      <CardContent>
        <Flex justify="flex-end" padding="0 0 5 0">
        { false &&
        <Tabs
            onSelect={(val) => {setWidgetSection(val)}}
            selected={widgetSection}
            small
            tabs={[
              {value: "a", label: "Swap"},
              {value: "b", label: "Bridge"},
            ]}
          />
        }
          <Icon onClick={() => setModal(Modal.Slipage)} icon="settings" size="s" button color="c300"/>

        </Flex>

        
        {
          widgetSection == 'a' ? (
            <>

            <CardContent.Content>
                      <InputCoin
                        value={sendAmount}
                        setValue={(r) => setSendAmountFunction(String(r), token1, token2)}
                        price={token1!?.price}
                        assets={assets}
                        selectedAsset={token1}
                        onAssetSelect={setToken1Parent}
                      />
                      <SC.Switch>
                        <Icon icon="swap" size="s" button color="c300" onClick={switchPairs} />
                      </SC.Switch>
                      <InputCoin
                        value={getAmount}
                        setValue={setGetAmountFunction}
                        price={token2!?.price}
                        impact={Object.keys(route).length > 0 ? <Text color={new BigNumber(priceImpact).lt(2.5) ? "success" : "error"}>(-{priceImpact}%)</Text> : <></>}
                        assets={assets}
                        selectedAsset={token2}
                        onAssetSelect={setToken2Parent}
                        disableInput
                        noBalance
                      />
                    </CardContent.Content>
                    <Divider size={3} />
                    {/*
                      <Flex gap={3} column padding="0 4">
                      {[
                        [
                          "Price impact",
                          <Text color={new BigNumber(priceImpact).lt(2.5) ? "success" : "error"}>{priceImpact}%</Text>,
                        ],
                        ["Price", `1 ${token2 ? token2.symbol : ""} / ${price} ${token1 ? token1.symbol : ""}`],
                        ["Max. slippage", `${slippage}%`],
                        ["Recipient", `${address ? reduceString(address as string, 7, 4) : "Not Connected"}`],
                      ].map(([title, content]) => (
                        <Flex justify="space-between">
                          <Text bodyRegular>{title}</Text>
                          <Text bodyMedium color={showDetails ? undefined : "gray400"}>
                            {showDetails ? content : "—"}
                          </Text>
                        </Flex>
                      ))}
                      <ExchangeSmartRoute routes={route} routeText={routeText} />
                    </Flex>
                    */}

                      
                    <DropdownContainer visible={Object.keys(route).length > 0}> 
                        <DropdownHeader rotateDeg={openDialog ? 0 : 180} onClick={() => {setOpenDialog(!openDialog)}}>
                          <Text bodyMedium>{ `1 ${token2 ? token2.symbol : ""} = ${formatNumber(price, 2)} ${token1 ? token1.symbol : ""}  ($${formatPrice(new BigNumber(token1?.price ? token1?.price : 0).times(price).toFixed(),true, 2)})`}</Text>
                          <IoIosArrowUp/>
                        </DropdownHeader>
                        <AnimatePresence>
                          {openDialog && (
                            <DropdownContent
                              initial={{ height: 0 }}
                              animate={{ height: 'auto' }}
                              exit={{ height: 0 }}
                              transition={{ duration: 0.3 }}
                            >
                            <Flex gap={3} column padding="0 4">
                            {[
                              [
                                "Price impact",
                                <Text color={new BigNumber(priceImpact).lt(2.5) ? "success" : "error"}>{priceImpact}%</Text>,
                              ],
                              ["Max. slippage", `${slippage}%`],
                              ["Recipient", `${address ? reduceString(address as string, 7, 4) : "Not Connected"}`],
                            ].map(([title, content]) => (
                              <Flex justify="space-between">
                                <Text bodyRegular>{title}</Text>
                                <Text bodyMedium color={showDetails ? undefined : "gray400"}>
                                  {showDetails ? content : "—"}
                                </Text>
                              </Flex>
                            ))}
                            <ExchangeSmartRoute routes={route} routeText={routeText} />
                          </Flex>
                            </DropdownContent>
                          )}
                        </AnimatePresence>
                    </DropdownContainer>

                    <Divider size={6} />
                    <Button
                      checkNetwork
                      onClick={() => {
                        setModal(Modal.Confirm);
                        sendTx();
                      }}
                      loading={calculating}
                      disabled={!Number(sendAmount) || !Number(getAmount) || calculating || !hasEnough || !hasEth}
                    >
                      {getSwapButtonStatus()}
                    </Button>
            </>
            
          ) : (
            <BridgeSection/>
          )
        }

      </CardContent>

      <ConfirmTransactionModal
        isVisible={modal === Modal.Confirm}
        title={"Swap"}
        onClose={closeModal}
        onConfirm={() => sendTx()}
        leftValue={`${getCleanNumber(sendAmount)} ` + (token1 ? token1.symbol : "")}
        leftIcon={token1 ? token1.logo : ""}
        rightValue={`${getCleanNumber(getAmount)} ` + (token2 ? token2.symbol : "")}
        rightIcon={token2 ? token2.logo : ""}
        icon={arrowIcon}
        footer={
          <CustomModal.Rows>
            <TxModal>
              {(w) => (
                <>
                  <CustomModal.Row title="Gas Cost Estimate">{w("$" + gasRefund.actual)}</CustomModal.Row>
                  <CustomModal.Row title="Gas Refund Estimate" color="success">
                    {w("$" + gasRefund.refund)}
                  </CustomModal.Row>
                </>
              )}
            </TxModal>
            <CustomModal.Row title="Minimum received">
              {getCleanNumber(minReceived)} {token2 ? token2.symbol : ""}
            </CustomModal.Row>
            <CustomModal.Row title="Price impact">{priceImpact}%</CustomModal.Row>
          </CustomModal.Rows>
        }
      />
      <ErrorModal
        isVisible={modal === Modal.Error}
        onClose={clearWidget}
        title="Swap error!"
        description={modalError}
      />
      <SuccessfulSubmitModal
        isVisible={modal === Modal.Submitted}
        onClose={clearWidget}
        title="Swap confirmed!"
        description="Funds are avaliable in your wallet."
        savings={"$" + currentSwapCost}
        costs={"$0.00"}
        onSubmit={onSubmit}
        symbolIn={token1 ? token1.symbol : ""}
        iconIn={token1 ? token1.logo : ""}
        symbolOut={token2 ? token2.symbol : ""}
        iconOut={token2 ? token2.logo : ""}
        amountIn={getCleanNumber(sendAmount)}
        amountOut={getCleanNumber(getAmount)}
        price={new BigNumber(token2 ? token2.price : 0).times(getAmount).toFixed(2)}
        section={
          <CustomModal.Rows>
            <TxModal>
              {(w) => (
                <>
                  <CustomModal.Row title="Fee cost">{w("$" + gasSpent)}</CustomModal.Row>
                  <CustomModal.Row title="Refunded gas fees" color="success">
                    {w("$" + gasSaved)}
                  </CustomModal.Row>
                </>
              )}
            </TxModal>
            <CustomModal.Row title="Confirmation time" color="success">
              {confirmTime}s
            </CustomModal.Row>
            <CustomModal.Row title="Amount spent">
              {getCleanNumber(sendAmount)} {token1 ? token1.symbol : ""}
            </CustomModal.Row>
            <CustomModal.Row title="Tx hash">
              <TxButton onClick={() => window.open("https://era.zksync.network/tx/" + txHash, "_blank")}>
                {reduceString(txHash, 6, 4)}
              </TxButton>
            </CustomModal.Row>
          </CustomModal.Rows>
        }
      />
      <SlipageToleranceModal
        isVisible={modal === Modal.Slipage}
        onClose={closeModal}
        defaultLimit={slippage}
        onLimitChange={setSlippageChange}
        onAdvancedChart={setAdvancedChart}
        advancedChart={advancedChart}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${layoutMq(`
    max-height: initial !important;
  `)}
`;

const TxButton = styled.button`
  cursor: pointer;
  z-index: 1;
  position: relative;
`;

const DropdownContainer = styled.div<{visible: boolean}>`
  width: 100%;
  border: 1px solid ${palette.gray100};
  border-radius: 14px;
  display: ${({ visible }) => visible ? 'block': 'none'};
`;

const DropdownHeader = styled.div<{rotateDeg: number}>`
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  & > svg {
    transition: all 0.3s ease;
    transform: rotate(${({ rotateDeg }) => rotateDeg}deg);
  }
`;

const DropdownContent = styled(motion.div)`
  overflow: hidden;
`;