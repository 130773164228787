import {useEffect, useState} from "react";

export const useMediaQuery = (query: string | number) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    if (typeof query === "number") {
      query = `(max-width: ${query}px)`;
    }
    const matchQueryList = window.matchMedia(query);

    function handleChange(e: any) {
      setMatches(e.matches);
    }

    // Initial check after component mounts
    setMatches(matchQueryList.matches);

    matchQueryList.addEventListener("change", handleChange);

    return () => {
      matchQueryList.removeEventListener("change", handleChange);
    };
  }, [query]);

  return matches;
};
