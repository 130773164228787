import {useEffect} from "react";
import {useHistory} from "react-router-dom";

export function useAnalyticsTracking() {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      (window as any).dataLayer?.push({
        event: "virtualPageview",
        pageUrl: location.pathname,
        pageTitle: document.title,
      });
    });
    return unlisten;
  }, []);
}
