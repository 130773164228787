import {Fragment, memo} from "react";
import {Asset} from "../../../model/Asset";
import {numberWithCommas, formatPrice} from "../../../utils";
import {Divider} from "../divider";
import {Flex} from "../flex";
import {Icon, IconAsset} from "../icon";
import {Text} from "../text";
import * as SC from "./index.styles";

interface AssetsBlockProps {
  list: {
    asset: Asset;
    amount: number;
    amountUsd?: number;
  }[];
}

export const AssetsBlock = memo(function AssetsBlockComp(props: AssetsBlockProps) {
  const {list = []} = props;
  return (
    <SC.Wrapper>
      {list?.map(({asset, amount, amountUsd}, i) => (
        <Fragment key={i}>
          {!!i && <Icon icon="plus" color="c300" />}
          <div>
            <Flex column align="center">
              <IconAsset asset={asset} contrast size="s" />
              <Divider size={5} />
              <Text bodyRegular>
                {formatPrice(amount, true, 4)} {asset?.symbol}
              </Text>
              <Divider size={2} />
              <Text bodyRegular color="gray400">
                ${numberWithCommas(amountUsd ?? amount * Number(asset?.price), 2)}
              </Text>
            </Flex>
          </div>
        </Fragment>
      ))}
    </SC.Wrapper>
  );
});
