import React, {PropsWithChildren} from "react";
import styled from "styled-components";
import BigNumber from "bignumber.js";
import {formatNumber, abbrNum} from "../../utils/formatNumber";
import {Button, Card, Chip, Flex, IconAsset, layoutMq, Text, Tooltip, useLayoutMq} from "../../ui/components";
import { MdOutlineBolt } from "react-icons/md";
import { Asset } from "../../model/Asset";
import { FaArrowRight } from "react-icons/fa6";
import unknownToken from "../../assets/images/icons/unknown_token.svg";

interface DropdownCommonProps {
  onManageClick: () => any;
  icon0: string;
  icon1: string;
  token0: string;
  token1: string;
  reserveA: string;
  reserveB: string;
  fee: string;
  tvl?: string;
  apy?: string;
  dark?: boolean;
  loading?: boolean;
  rewardTokens?: Asset[];
  v3?: boolean;
  stable?: boolean;
}

type DropdownProps = DropdownCommonProps | ({loading: true} & Partial<DropdownCommonProps>);

export const TrendingPoolV3 = ({
  loading,
  onManageClick,
  icon0,
  icon1,
  token0,
  token1,
  reserveA,
  reserveB,
  fee,
  apy,
  tvl,
  dark,
  children,
  rewardTokens,
  v3,
  stable
}: PropsWithChildren<DropdownProps>) => {
  const layoutBreak = true

  const getPoolAPY = () => {
    if(apy?.split("-")[0] && apy?.split("-")[1] && !apy.includes('undefined'))
      return /*apy?.split("-")[0] + '-' + */ apy?.split("-")[1] + "%"
    else 
      return "0.01%"
  }

  const getPoolBaseAPY = () => {
    if(apy?.split("-")[0] && apy?.split("-")[1] && !apy.includes('undefined'))
      return apy?.split("-")[0] + "%"
    else 
      return "0.01%"
  }

  const getPoolBoostAPY = () => {
    if(apy?.split("-")[0] && apy?.split("-")[1] && !apy.includes('undefined'))
      return new BigNumber(apy?.split("-")[1]).minus(apy?.split("-")[0]).toFixed(2) + "%"
    else 
      return "0.01%"
  }

  const getTotalPoolAPY = () => {
    if(apy?.split("-")[0] && apy?.split("-")[1] && !apy.includes('undefined'))
      return apy?.split("-")[0] + '-' + apy?.split("-")[1] + "%"
    else 
      return "0.01%"
  }

  return (
    <Card size="l" style={{marginLeft: "5px", marginRight: "5px"}} loading={loading}>
      <Flex gap={2} justify="space-between" wrap>
        <Flex gap={4} order={1}>
          <Flex>
            <IconAsset stack asset={{logo: icon0 ? icon0 : unknownToken, symbol: token0} as any} size="s" button />
            <IconAsset stack asset={{logo: icon1 ? icon1 : unknownToken, symbol: token1} as any} size="s" button />
          </Flex>
          <div style={{position: "relative"}}>
            <Text h6 noWrap>
              {token0}
              <Text inline color="gray300">
                {" ― "}
              </Text>
              {token1}
              {" "}
              {v3 && <Flex inline style={{marginBottom: '0px', marginLeft: '8px'}}>
                <Chip palette="green" label="" value={"v3"} variant="color" />
                </Flex>
                }
            </Text>
            <Text bodySmall color="gray400" noWrap>
              {v3 ? `Concentrated ${fee}%` : `${stable ? 'Stable': 'Volatile'} ${fee}%`}
            </Text>
          </div>
        </Flex>
        <Break/>
        <Flex order={2}>
            {tvl && <Chip label="TVL" value={`$${formatNumber(new BigNumber(tvl).toFixed(0), 0)}`} />}
        </Flex>
        
        <Flex order={2} column align="flex-start">
          {apy ?
            (!apy.includes("-") ? (
              <Chip valueColor label="APY" value={apy + "%"} />
            ) : (
              <>
                <Tooltip content={`This pool is boosted with additional rewards. \nBase: ${getPoolBaseAPY()} \nBoosted: ${getPoolBoostAPY()}`}>
                  <Chip palette="purple" label="APY" value={getPoolAPY()} variant="color">
                    <MdOutlineBolt color="rgba(var(--color-mp-c200))" />
                  </Chip>
                </Tooltip>

              </>
            )) : <Chip palette="green" valueColor label="APY" value={"0.01%"} />}
        </Flex>
        <Break/>

        <Flex justify="flex-end" order={3} grow={1}>
          <Button
            secondary
            size="s"
            full={true}
            onClick={() => {
              onManageClick!();
            }}
          >
              <FaArrowRight/>
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
};

const Break = styled.div`
    display: none;

    display: block;
    min-width: 100%;
    max-width: 100%;
    order: 2;
`;

const BreakPadding = styled.div`
  min-width: 32px;
  max-width: 32px;
  display: none;

    display: block;
`;

const RewardToken = styled.div`
  position: absolute;
  bottom: 70%;
  left: 100%;
  display: flex;
  flex-direction: row;
`;
