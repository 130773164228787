import styled from "styled-components";
import {transition} from "../../../../../../styles";
import {br, flex, palette, size} from "../../../../../../ui/styles";

export * from "../chart.styles";

export const ChartBarWrapper = styled.div<{hover?: boolean}>`
  ${size.gap(1)};
  ${flex("flex-end")}

  overflow: hidden;
  height: 100%;

  ${(_) =>
    _.hover &&
    `
    & > ${ChartBar} {
      opacity: 0.4;
    }
  `}
`;

export const ChartBar = styled.div<{inner?: boolean; active?: boolean, transition?: boolean}>`
  ${flex("flex-end")}
  ${br.xxxs}
  ${transition.fast.prop("opacity")}

  position: relative;
  min-height: 4px;
  flex: 1;
  background: ${palette.ultramoss};
  background-image: linear-gradient(
    0deg,
    color-mix(in srgb, ${palette.ultramoss} 20%, white) 0%,
    ${palette.ultramoss} 100%
  );

  ${(_) =>
    _.inner &&
    `
    background: ${palette.lavender};
    background-image: linear-gradient(0deg, ${palette.chalk} 0%, ${palette.lavender} 100%);
  `}

  ${(_) =>
    _.transition &&
    `
    ${transition.slow}
  `}

  ${(_) =>
    _.active &&
    `
    opacity: 1 !important;
  `}

  ${(_) =>
    !_.inner &&
    `
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      height: 100vh;
      left: ${size(-0.5)};
      right: ${size(-0.5)};
    }
  `}
`;
