import {toIcon} from "./icon.shared";

export const coinUsdc = toIcon(
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 18C13.9875 18 18 13.9875 18 9C18 4.01247 13.9875 0 9 0C4.01247 0 0 4.01247 0 9C0 13.9875 4.01247 18 9 18Z"
      fill="#2775CA"
    />
    <path
      d="M11.475 10.425C11.475 9.11248 10.6875 8.66248 9.11254 8.47501C7.98754 8.32498 7.76254 8.02501 7.76254 7.49995C7.76254 6.97489 8.13757 6.63748 8.88754 6.63748C9.56254 6.63748 9.93757 6.86248 10.125 7.42498C10.1626 7.53748 10.2751 7.61245 10.3876 7.61245H10.9875C11.1375 7.61245 11.25 7.49995 11.25 7.35001V7.31248C11.1 6.48745 10.425 5.84998 9.56254 5.77501V4.87501C9.56254 4.72498 9.45004 4.61248 9.26257 4.57495H8.70007C8.55004 4.57495 8.43754 4.68745 8.40001 4.87501V5.73748C7.27501 5.88751 6.56257 6.63748 6.56257 7.57501C6.56257 8.81251 7.31254 9.29995 8.88754 9.48751C9.93757 9.67498 10.2751 9.89998 10.2751 10.5C10.2751 11.1 9.75001 11.5125 9.03757 11.5125C8.06251 11.5125 7.72501 11.1 7.61251 10.5375C7.57507 10.3875 7.46257 10.3125 7.35007 10.3125H6.71251C6.56257 10.3125 6.45007 10.425 6.45007 10.575V10.6125C6.60001 11.55 7.20004 12.225 8.43754 12.4125V13.3125C8.43754 13.4625 8.55004 13.575 8.73751 13.6125H9.30001C9.45004 13.6125 9.56254 13.5 9.60007 13.3125V12.4125C10.7251 12.225 11.475 11.4375 11.475 10.425V10.425Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.74997 7.16244C2.66241 10.05 4.16244 13.3125 7.08744 14.3624C7.19994 14.4375 7.31244 14.5874 7.31244 14.6999V15.225C7.31244 15.3 7.31244 15.3375 7.27491 15.3749C7.23747 15.525 7.08744 15.5999 6.93741 15.525C4.83744 14.85 3.22491 13.2374 2.54991 11.1375C1.42491 7.575 3.37494 3.78744 6.93741 2.66244C6.97494 2.625 7.04991 2.625 7.08744 2.625C7.23747 2.66244 7.31244 2.77494 7.31244 2.92497V3.44994C7.31244 3.6375 7.23747 3.75 7.08744 3.82497C5.54997 4.38747 4.31247 5.58744 3.74997 7.16244ZM10.725 2.81245C10.7624 2.66242 10.9124 2.58745 11.0625 2.66242C13.1249 3.33742 14.775 4.94995 15.45 7.08745C16.575 10.6499 14.6249 14.4375 11.0625 15.5625C11.0249 15.5999 10.95 15.5999 10.9124 15.5999C10.7624 15.5625 10.6874 15.45 10.6874 15.2999V14.775C10.6874 14.5874 10.7624 14.4749 10.9124 14.3999C12.4499 13.8374 13.6874 12.6375 14.2499 11.0625C15.3375 8.17492 13.8374 4.91242 10.9124 3.86248C10.7999 3.78742 10.6874 3.63748 10.6874 3.48745V2.96248C10.6874 2.88742 10.6874 2.84998 10.725 2.81245Z"
      fill="white"
    />
  </svg>,
  true,
);
