import {toIcon} from "./icon.shared";

export const arrow = toIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
    <path
      d="M10.5 10.9767L14.625 6.85168L15.8033 8.03002L10.5 13.3334L5.19666 8.03002L6.37499 6.85168L10.5 10.9767Z"
      fill="#216E05"
    />
  </svg>,
);
