import styled from "styled-components";

import {palette, text} from "../../styles";

export const Text = styled.div<{type: keyof typeof text}>`
  ${(_) => text[_.type]}

  color: ${palette.black};
  width: 310px;
  position: relative;

  &:before {
    content: "${(_) =>
      text[_.type]
        .replace(
          /.+font-family: var\(--font-(.+)\);.+font-size: (\d+px).+line-height: ([\d\.]+em).+$/s,
          "$1,    $2    $3",
        )
        .replace(/^(.)/, (_) => _.toUpperCase())}";
    white-space: pre;
    position: absolute;
    line-height: 10px;
    letter-spacing: 0;
    font-size: 10px;
    top: 100%;
    margin-top: -2px;
    left: 0;
    color: ${palette.black.o(0.55)};
    pointer-events: none;
  }
`;
