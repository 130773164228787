import {CSSProperties} from "react";
import {br} from "./border-radius";

import {palette} from "./palette";
import {transition} from "./transition";

export const flex = (
  alignItems?: CSSProperties["alignItems"],
  justifyContent?: CSSProperties["justifyContent"],
  direction?: CSSProperties["flexDirection"],
): string => `
  display: flex;
  ${!alignItems ? "" : `align-items: ${alignItems};`}
  ${!justifyContent ? "" : `justify-content: ${justifyContent};`}
  ${!direction ? "" : `flex-direction: ${direction};`}
`;

export const cardMixin = (
  borderRadius: keyof typeof br | false,
  useBorder = false,
  borderColor?: string,
  noBackground = false,
) => `
  ${borderRadius ? br[borderRadius] : ""}

  ${noBackground ? "" : `background: ${palette.white};`}
  ${
    useBorder
      ? `
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
    border: 1px solid ${borderColor || palette.gray100};
  `
      : `
    box-shadow:
      inset 0 0 0 1px ${borderColor || palette.gray100},
      0 4px 4px 0 rgba(0, 0, 0, 0.02);
  `
  }
`;

export const cardButton = (...args: Parameters<typeof cardMixin>) => `
  ${transition.fast}
  ${card(...args)}

  cursor: pointer;

  &:hover {
    ${card(args[0], args[1], palette.gray300)}
  }

  &:active {
    background: ${palette.gray50};
  }
`;

export const card = Object.assign(cardMixin, {button: cardButton});

export const ellipsis = `
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
