import {RefObject, useEffect, useRef} from "react";

interface UseDragRangeConfig<T> {
  ref?: RefObject<T | null | undefined>;
  container?: RefObject<T | null | undefined>;
  onChange?: (position: number | undefined, end?: boolean) => void;
}

export const useDragRange = <T extends HTMLElement>(config: UseDragRangeConfig<T>) => {
  const elementRef = useRef<T>(null);
  const element = config.ref?.current || elementRef.current;
  const container = config.container?.current || element?.parentElement!;
  const limitsRef = useRef({from: 0, to: 0});

  useEffect(() => {
    if (!element || !container || !document?.body) return;

    let dragging = false;
    let p = undefined as number | undefined;
    const onDown = () => {
      p = undefined;
      dragging = true;
    };
    const onUp = () => {
      if (dragging && p !== undefined) {
        config.onChange?.(p, true);
      }
      dragging = false;
    };
    const onMove = (event: MouseEvent) => {
      event.preventDefault();
      if (!dragging) return;
      const rect = container.getBoundingClientRect();
      const x = event.pageX;
      p = Math.min(1, limitsRef.current.to, Math.max(0, limitsRef.current.from, (x - rect.left) / rect.width));
      config.onChange?.(p);
    };

    element.addEventListener("mousedown", onDown, true);
    document.body.addEventListener("mouseup", onUp, true);
    document.body.addEventListener("mousemove", onMove, true);
    return () => {
      element.removeEventListener("mousedown", onDown, true);
      document.body.removeEventListener("mouseup", onUp, true);
      document.body.removeEventListener("mousemove", onMove, true);
    };
  }, [element, container, limitsRef, config.onChange]);

  return (from: number, to: number) => {
    Object.assign(limitsRef.current, {from, to});
  };
};
