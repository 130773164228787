import { memo, PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { Box, CardContent, Divider, Text, Title } from "../../../../../../ui/components";

import * as SC from "./index.styles";

interface AnalyticsSectionProps {
  title: string;
  href?: string;
  isPage?: boolean;
  hideLink?: boolean;
}

export const AnalyticsSection = memo(({title, href = "", isPage, hideLink, children}: PropsWithChildren<AnalyticsSectionProps>) => {  
  if (isPage) {
    return <div>
      <Divider size={4}/>
      <SC.TitleWrapper>
        <Title
          breakpoint={0}
          goBackLink="/analytics"
          title={title}
        />
      </SC.TitleWrapper>
      <Box noBreak>
        {children}
      </Box>
    </div>
  }
  return <>
    <Box noBreak>
      <CardContent extendHorizontal>
        <CardContent.Title title={title}>
          {!hideLink && (
            <Link to={isPage ? "/analytics" : href}>
              <Text color="c300" bodyMedium>
                {isPage ? "Go to Overview" : "See all"}
              </Text>
            </Link>
          )}
        </CardContent.Title>
        <CardContent.Content>
          {children}
        </CardContent.Content>
      </CardContent>
    </Box>
  </>
});
