import styled from "styled-components";
import {Modal, ModalProps} from "../../../ui/components";
import {AssetSection} from "../../../common/section/AssetSection";

import {Asset} from "../../../model/Asset";
import { Button, Text, Tabs, Message} from "../../../ui/components";
import {LoadingAnimation} from "../../../common/loading/loading";

enum ModalType {
  AssetsListA,
  AssetsListB,
}
interface AddLiquidityModalProps extends ModalProps {
  selectedAssetA: Asset | undefined;
  selectedAssetB: Asset | undefined;
  assetAmountA: string;
  assetAmountB: string;
  assets: Asset[];
  setAssetAmountA: (amount: string) => void;
  setAssetAmountB: (amount: string) => void;
  onSupplyButtonClick: () => void;
  setSelectedAssetA: (asset: Asset) => void;
  setSelectedAssetB: (asset: Asset) => void;
  disableButton: boolean;
  disableEdit: boolean;
  calculating: boolean;
  poolType: number;
  setPoolType: (amount: number) => void;
}

export const AddLiquidityModal = ({
  isVisible,
  onClose,
  assets,
  onSupplyButtonClick,
  setSelectedAssetA,
  setSelectedAssetB,
  selectedAssetA,
  selectedAssetB,
  calculating,
  poolType,
  setPoolType,
}: AddLiquidityModalProps) => {
  return (
    <>
      <ModalSecondary isVisible={isVisible} onClose={onClose} title="Add liquidity">
        <Text bodyRegular color="gray400">
          Add liquidity to a new or pre-existing pool. The ratio of tokens you add depends on the market value of the
          pool.
        </Text>
        <br />
        <br />

        <AssetSection selectedAsset={selectedAssetA} label="Input" assets={assets} onAssetSelect={setSelectedAssetA} />
        <CustomText h3 color="overgrown">
          {" "}
          +{" "}
        </CustomText>
        <AssetSection selectedAsset={selectedAssetB} label="Input" assets={assets} onAssetSelect={setSelectedAssetB} />
        <PoolTypeDiv>
          <Tabs
            onSelect={(val) => setPoolType(val)}
            standalone
            full
            tabs={[
              {value: 0, label: "Concentrated"},
              {value: 1, label: "Stable"},
              {value: 2, label: "Normal"},
            ]}
          />
        </PoolTypeDiv>

        {poolType == 1 ? (
          <>
            <Message error>
              Warning: Stable pools should strictly be used for assets that trade at a 1:1 value. For regular tokens,
              use normal pools instead. `{" "}
            </Message>
            `
            <br />
          </>
        ) : (
          <></>
        )}

        <Button full onClick={onSupplyButtonClick} disabled={!(selectedAssetA && selectedAssetB)}>
          <LoadingAnimation isLoading={calculating} />
          {!(selectedAssetA && selectedAssetB) ? "Invalid selection" : "Manage"}
        </Button>
      </ModalSecondary>
    </>
  );
};

const PoolTypeDiv = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;

  display: flex;
  width: 100%;
  justify-content: center;
`;

const PoolTypeButtonLeft = styled.button`
  display: flex;
  justify-content: space-between;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;

  align-items: center;
  flex-direction: row;
  width: 120px;
  color: rgb(var(--color-black));
  height: auto;
  background-color: rgb(var(--color-ultramoss));
  border: 1px solid rgb(var(--color-ultramoss));
  border-radius: 16px;

  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;

  &.active {
    color: rgb(var(--color-white));
    background-color: rgb(var(--color-overgrown));
    border: 1px solid rgb(var(--color-overgrown));
  }

  :hover:not(.active) {
    color: var(--color-blue);
    border: 1px solid var(--color-blue);
  }
`;

const PoolTypeButtonRight = styled(PoolTypeButtonLeft)`
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
`;

const ModalSecondary = styled(Modal)`
  background-color: var(--color-dark-blue);
`;

const ErrorText = styled(Text)`
  margin-top: 15px;
  color: rgb(255, 95, 21);
  margin-bottom: 15px;
`;

const CustomText = styled(Text)`
  margin: 10px 0px;

  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
`;
