import {toIcon} from "./icon.shared";

export const gas = toIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M9.3335 12.6667H10.0002V14H1.3335V12.6667H2.00016V2.66667C2.00016 2.29848 2.29864 2 2.66683 2H8.66683C9.03503 2 9.3335 2.29848 9.3335 2.66667V8H10.6668C11.4032 8 12.0002 8.59693 12.0002 9.33333V12C12.0002 12.3682 12.2986 12.6667 12.6668 12.6667C13.035 12.6667 13.3335 12.3682 13.3335 12V7.33333H12.0002C11.632 7.33333 11.3335 7.03487 11.3335 6.66667V4.27614L10.2289 3.17157L11.1718 2.22877L14.4716 5.52859C14.6018 5.65877 14.6668 5.82939 14.6668 6V12C14.6668 13.1046 13.7714 14 12.6668 14C11.5622 14 10.6668 13.1046 10.6668 12V9.33333H9.3335V12.6667ZM3.3335 12.6667H8.00016V8.66667H3.3335V12.6667ZM3.3335 3.33333V7.33333H8.00016V3.33333H3.3335Z"
      fill="#868686"
    />
  </svg>,
);
