import {typo} from "./typo";

const getText = (typo: string, fontSize: number, lineHeight: number, letterSpacing?: number) => `
  ${typo}
  font-size: ${fontSize}px;
  line-height: ${lineHeight}em;
  ${letterSpacing ? `letter-spacing: ${letterSpacing}px;` : ""}
`;

export const text = {
  h1: getText(typo.primary.medium, 56, 1.07, -0.56),
  h2: getText(typo.primary.medium, 40, 1.1, -1),
  h3: getText(typo.primary.medium, 28, 1.07, -0.28),
  h4: getText(typo.primary.medium, 24, 1, -0.24),
  h5: getText(typo.primary.medium, 20, 1, -0.2),
  h6: getText(typo.primary.medium, 18, 1),
  h7: getText(typo.primary.medium, 16, 1),
  bodyLarge: getText(typo.secondary.medium, 16, 1.25),
  bodyRegular: getText(typo.secondary.normal, 14, 1.29),
  bodyMedium: getText(typo.secondary.medium, 14, 1.29),
  bodySmall: getText(typo.secondary.normal, 12, 4 / 3),
  bodyMicro: getText(typo.secondary.normal, 10, 1), // Not part of design spec
};
