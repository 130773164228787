import styled from "styled-components";

import {mq, PaddingString, size} from "../../styles";

export const Scroll = styled.div<{
  parentPadding: PaddingString;
  padding?: PaddingString;
  height?: number;
  responsive?: boolean;
}>`
  ${(_) => {
    let [top, right, bottom, left] = _.parentPadding.split(" ").map((_) => +_ || 0);
    [top, right, bottom, left] = [top, right || top, bottom || top, left || right || top];

    let [pTop, pRight, pBottom, pLeft] = (_.padding || "0").split(" ").map((_) => +_ || 0);
    [pTop, pRight, pBottom, pLeft] = [pTop, pRight || pTop, pBottom || pTop, pLeft || pRight || pTop];

    const base = `
      ${
        _.height &&
        `
        flex: 1;
        height: max-content;
        max-height: ${+_.height + size.raw(bottom + pBottom) + size.raw(top + pTop)}px;
      `
      }
    `;

    if (!_.parentPadding) {
      console.warn('Scroll needs "parentPadding" property.');
      return base;
    }
    return `
      ${base}
      padding-right: ${size(right - 0.5 + pRight)};
      margin-right: ${size(-right - 0.5)};
      padding-bottom: ${size(bottom + pBottom)};
      margin-bottom: ${size(-bottom)};
      padding-top: ${size(top + pTop)};
      margin-top: ${size(-top)};
      padding-left: ${size(pRight)};
      overflow: auto;
      scrollbar-gutter: stable;

      -webkit-mask:
        linear-gradient(transparent ${size(pTop)}, #fff ${size(top + pTop)}, #fff calc(100% - ${size(bottom + pBottom)}), transparent calc(100% - ${size(pBottom)})),
        linear-gradient(270deg, #fff 0, #fff ${size(right)}, transparent ${size(2)});

      ::-webkit-scrollbar-track {
        margin: ${size(top + pTop)} ${size(right + pRight)} ${size(bottom + pBottom)} ${size(left + pLeft)};
      }

      ${
        !_.responsive
          ? ""
          : mq(
              570,
              `
        padding-right: ${size(1)};
        margin-right: ${size(-2)};
      `,
            )
      }
    `;
  }}
`;
