function getMqType(maxWidth: number | string): {not: (content: string) => string} & ((content: string) => string);
function getMqType(maxWidth: number | string, content: string): string;
function getMqType(maxWidth: number | string, content?: string) {
  return null as any;
}

function getMq(not: boolean, maxWidth: number | string, content?: string) {
  if (!content) {
    return Object.assign(getMq.bind(undefined, not, maxWidth), {
      not: getMq.bind(undefined, !not, maxWidth),
    });
  }
  return `
    @media ${not ? "screen and not" : ""} (max-width: ${maxWidth}px) {
      ${content}
    }
  `;
}

export const mq = Object.assign(getMq.bind(undefined, false) as typeof getMqType, {
  not: getMq.bind(undefined, true) as typeof getMqType,
});
