import { memo, PropsWithChildren, ReactNode } from "react";
import { Box, CardContent, Divider, layoutMqValue, Text, Title, TitleDash } from "../../../../../../ui/components";
import { formatPrice, numberWithCommas } from "../../../../../../utils";

import * as SC from "./index.styles";

interface AnalyticsPageHeadingProps {
  loading?: boolean;
  title: string;
  titleAlt?: string;
  titleContent?: ReactNode;
  goBackLink: string;
  price?: number;
  priceChange?: number;
  isPage?: boolean;
}

export const AnalyticsPageHeading = memo(({loading, title, titleAlt, titleContent, price, priceChange, goBackLink, children}: PropsWithChildren<AnalyticsPageHeadingProps>) => {  
  return (
    <Box>
      <div>
        <Divider size={4}/>
        <SC.TitleWrapper>
          <Title
            loading={loading}
            goBackLink={goBackLink}
            title={<>
              {title}
              {titleAlt && <>
                <TitleDash/>
                {titleAlt}
              </>}
            </>}
            chip={price ? "$" + formatPrice(price, true, 3, 2) : undefined}
            chipValue={priceChange}
            chipValueString={numberWithCommas(priceChange, 2) + '%'}
            chipType="price"
            breakpoint={layoutMqValue}
            breakContent
          >
            {titleContent}
          </Title>
        </SC.TitleWrapper>
        <Box noBreak>
          {children}
        </Box>
      </div>
    </Box>
  );
});
