import * as d3 from "d3-shape";

import {PropsWithChildren, useRef, useState, memo, useEffect, useId, useMemo} from "react";
import {createPortal} from "react-dom";
import {useUi} from "../-provider";

import {withColor} from "../-with-color";

import * as SC from "./index.styles";

export {lavaEffectHoverClassName} from "./index.styles";

const getShape = (data: number[]) =>
  d3
    .radialLine<number>()
    .angle((d, i) => (i / data.length) * 2 * Math.PI)
    .curve(d3.curveBasisClosed)
    .radius((d) => d)(data);

const generateShape = (base = 30, size = 80, steps = 12, round = 2) => {
  let points = [...new Array(steps)].map(() => base + Math.random() * size);
  const doubleList = points.concat(points);
  points = points.map((_, i) => doubleList.slice(i, i + round).reduce((a, b) => a + b, 0) / round).map(Math.round);
  return getShape(points)!;
};

const lavaColorConfig = {
  green: ["GLOBAL_SVG_LAVA_GREEN_FILTER", "#102A08"],
  purple: ["GLOBAL_SVG_LAVA_PURPLE_FILTER", "#0E0846"],
  aqua: ["GLOBAL_SVG_LAVA_AQUA_FILTER", "#06464A"],
};

interface LavaEffectProps {
  leader?: boolean;
  follower?: boolean;
}

export const LavaEffect = memo(
  withColor(function LavaEffectComponent(props: PropsWithChildren<LavaEffectProps>, color, {performance}) {
    const {leader, follower} = props;
    const {lavaFollowerId, setLavaFollowerId} = useUi();
    const containerRef = useRef<SVGSVGElement>(null);
    const [{width, height}, setSizes] = useState({width: 0, height: 0});
    const [active, setActive] = useState(true);
    const id = useId();
    const getId = (name: string, hash = false) => (hash ? "#" : "") + id + name;
    const getUrlId = (name: string) => `url(#${getId(name)})`;

    useEffect(() => {
      const elem = containerRef?.current?.parentElement;
      if (!elem) return;
      const update = () => {
        const rect = elem!.getBoundingClientRect();
        setSizes({width: rect.width, height: rect.height});
      };
      update();
      const observer = new ResizeObserver(update);
      observer.observe(elem!);
      return () => {
        observer.disconnect();
      };
    }, [containerRef]);

    useEffect(() => {
      const checkVisibility = () => {
        setActive(!document.hidden);
      };
      checkVisibility();
      document.addEventListener("visibilitychange", checkVisibility);
      return () => {
        document.removeEventListener("visibilitychange", checkVisibility);
      };
    }, []);

    const paths = useMemo(
      () =>
        [
          generateShape(40, 90, 15, 3),
          generateShape(30, 90, 8, 2),
          generateShape(6, 40, 8, 2),
          generateShape(10, 50, 12, 3),
          generateShape(0, 60, 12, 3),
          generateShape(20, 20, 12, 2),
          generateShape(20, 20, 12, 3),
          generateShape(6, 40, 16, 6),
        ].map((_) => [_, Math.random()]),
      [],
    );

    const {shapes, followerShapes} = useMemo(() => {
      const getShapes = (f?: boolean) => {
        const common = {width, height, performance, follower: f};
        return paths.map(([d, r]: any, i) => <SC.Path key={i} {...common} follower={f} n={i} r={r} d={d} />);
      };
      return {
        shapes: getShapes(leader ? false : undefined),
        followerShapes: getShapes(true),
      };
    }, [width, height, paths, performance, leader]);

    useEffect(() => {
      if (follower) {
        setLavaFollowerId(getId("follower"));
        return () => {
          setLavaFollowerId(undefined);
        };
      }
    }, [follower]);

    const [filter, background] = lavaColorConfig[color];

    return (
      <SC.SVGContainer
        active={active}
        ref={containerRef}
        width={width}
        height={height}
        performance={performance}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g style={{display: "none"}}>
          <g filter="url(#GLOBAL_SVG_LAVA_EFFECT)" id={getId("shapes")}>
            {!follower ? shapes : <g id={getId("follower")} />}
            {leader && lavaFollowerId && createPortal(followerShapes, document.getElementById(lavaFollowerId)!)}
          </g>
        </g>

        <g filter={`url(#${filter})`} mask={`url(#${getId("mask")})`}>
          <use xlinkHref={getId("shapes", true)} x="0" y="0" style={{color: background}}></use>
        </g>
        <defs>
          <mask id={getId("mask")}>
            <rect x={0} width={width} y={0} height={height} fill="black" />
            <use xlinkHref={getId("shapes", true)} x="0" y="0" style={{color: "white"}} />
          </mask>

          {/* Global Filters */}
          <filter id="GLOBAL_SVG_LAVA_EFFECT" filterUnits="userSpaceOnUse" x="0" y="0" width="100%" height="100%">
            <feGaussianBlur in="SourceGraphic" stdDeviation="6"></feGaussianBlur>
            <feComponentTransfer result="cutoff">
              <feFuncA type="linear" slope="14" intercept="-7"></feFuncA>
            </feComponentTransfer>
          </filter>

          <filter
            id="GLOBAL_SVG_LAVA_GREEN_FILTER"
            x="0.309753"
            y="-103.612"
            width="640.703"
            height="878.723"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="12.9663" />
            <feGaussianBlur stdDeviation="73.1299" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.541176 0 0 0 0 0.839216 0 0 0 0 0.462745 0 0 0 1 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_443_1229" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2.4639" />
            <feGaussianBlur stdDeviation="7.34757" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.505882 0 0 0 0 0.823529 0 0 0 0 0.443137 0 0 0 1 0" />
            <feBlend mode="normal" in2="effect1_innerShadow_443_1229" result="effect2_innerShadow_443_1229" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="3.45768" />
            <feGaussianBlur stdDeviation="66.3874" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.129412 0 0 0 0 0.431373 0 0 0 0 0.0196078 0 0 0 1 0" />
            <feBlend mode="normal" in2="effect2_innerShadow_443_1229" result="effect3_innerShadow_443_1229" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="21.6105" dy="37.17" />
            <feGaussianBlur stdDeviation="43.221" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.768627 0 0 0 0 0.933333 0 0 0 0 0.305882 0 0 0 1 0" />
            <feBlend mode="normal" in2="effect3_innerShadow_443_1229" result="effect4_innerShadow_443_1229" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="3.45768" />
            <feGaussianBlur stdDeviation="7.69333" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.244337 0 0 0 0 0.5975 0 0 0 0 0.0278832 0 0 0 1 0" />
            <feBlend mode="normal" in2="effect4_innerShadow_443_1229" result="effect5_innerShadow_443_1229" />
          </filter>

          <filter
            id="GLOBAL_SVG_LAVA_PURPLE_FILTER"
            x="-3.42981"
            y="-134.484"
            width="733.501"
            height="763.882"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="13.6591" />
            <feGaussianBlur stdDeviation="36.8622" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.203922 0 0 0 0 0.305882 0 0 0 0 0.847059 0 0 0 1 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_443_1241" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2.10141" />
            <feGaussianBlur stdDeviation="20" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.202623 0 0 0 0 0.305456 0 0 0 0 0.845329 0 0 0 1 0" />
            <feBlend mode="normal" in2="effect1_innerShadow_443_1241" result="effect2_innerShadow_443_1241" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="39.0739" dy="58.9795" />
            <feGaussianBlur stdDeviation="50" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.478101 0 0 0 0 0.433329 0 0 0 0 0.881055 0 0 0 1 0" />
            <feBlend mode="normal" in2="effect2_innerShadow_443_1241" result="effect3_innerShadow_443_1241" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="-3.68622" dy="12.0409" />
            <feGaussianBlur stdDeviation="8" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.609791 0 0 0 0 0.563883 0 0 0 0 1 0 0 0 1 0" />
            <feBlend mode="normal" in2="effect3_innerShadow_443_1241" result="effect4_innerShadow_443_1241" />
          </filter>

          <filter
            id="GLOBAL_SVG_LAVA_AQUA_FILTER"
            x="-2.94396"
            y="-424.523"
            width="674.356"
            height="931.697"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="13.696" />
            <feGaussianBlur stdDeviation="36.9616" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.211765 0 0 0 0 0.741176 0 0 0 0 0.615686 0 0 0 1 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_627_28298" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2.10708" />
            <feGaussianBlur stdDeviation="7.39232" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.211765 0 0 0 0 0.741176 0 0 0 0 0.615686 0 0 0 1 0" />
            <feBlend mode="normal" in2="effect1_innerShadow_627_28298" result="effect2_innerShadow_627_28298" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="39.1793" dy="59.1386" />
            <feGaussianBlur stdDeviation="40" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.487603 0 0 0 0 0.82878 0 0 0 0 0.847526 0 0 0 1 0" />
            <feBlend mode="normal" in2="effect2_innerShadow_627_28298" result="effect3_innerShadow_627_28298" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="-3.69616" dy="10.9755" />
            <feGaussianBlur stdDeviation="6.06171" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.258824 0 0 0 0 0.729412 0 0 0 0 0.756863 0 0 0 1 0" />
            <feBlend mode="normal" in2="effect3_innerShadow_627_28298" result="effect4_innerShadow_627_28298" />
          </filter>
        </defs>
      </SC.SVGContainer>
    );
  }),
);
