import {useQuery} from "@tanstack/react-query";
import { fetchGraphQL } from "../../../utils/gql-utils";
import { getGraphQLUrl } from "../../../web3/apollo/client";
import { BUNDLE_ID } from "./constants";
import {getBlocksTag} from "./utils";

export const useChartBlocks = (enabled?: boolean) => {
  const blocksQuery = useQuery({
    queryKey: ["blocks"],
    queryFn: async () => {
      const bh = await getBlocksTag(24 + 1, 60 * 60, "h");
      const bd = await getBlocksTag(30 + 1, 60 * 60 * 24, "d");
      const bw = await getBlocksTag(54 + 1, 60 * 60 * 24 * 7, "w");
      const bm = await getBlocksTag(12 + 1, 60 * 60 * 24 * 30, "m");
      return [bh, bd, bw, bm].flat();
    },
    enabled,
    refetchOnWindowFocus: false,
  });

  const pricesQuery = useQuery({
    queryKey: ["blocks-eth-prices"],
    queryFn: async () => {
      if (!blocksQuery.data?.length) throw "No blocks data";
      const url = getGraphQLUrl();
      return await fetchGraphQL(
        url,
        `
        query {
          ${blocksQuery.data.map(({block, tag, gap}) => `
            p_${tag}_${block}_${gap}: bundles(where: {id: ${BUNDLE_ID}}, block: {number: ${block}}) {
              p: ethPrice
            }
          `)}
        }
      `,
      ).then(({data}) => {
        const groups: Record<'h' | 'd' | 'w' | 'm', Record<number, number>> = {} as any;
        Object.entries(data)
          .forEach(([key, value]: any) => {
            const [, group, , gap] = key.split('_');
            if (!groups[group]) {
              groups[group] = {};
            }
            groups[group][gap] = +value[0].p;
          })
        return groups;
      });
    },
    enabled: enabled && !!blocksQuery.data?.length,
    refetchOnWindowFocus: false,
  });

  return {
    blocks: blocksQuery.data,
    prices: pricesQuery.data,
  };
};
