import {toIcon} from "./icon.shared";

export const gift = toIcon(
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.7561 2.50293C21.5175 2.50293 23.7561 4.7415 23.7561 7.50293C23.7561 8.41422 23.5123 9.26857 23.0864 10.0043L28.7561 10.0029V12.5029H26.2561V25.0029C26.2561 25.6933 25.6965 26.2529 25.0061 26.2529H5.0061C4.31575 26.2529 3.7561 25.6933 3.7561 25.0029V12.5029H1.2561V10.0029L6.92582 10.0043C6.4999 9.26857 6.2561 8.41422 6.2561 7.50293C6.2561 4.7415 8.49468 2.50293 11.2561 2.50293C12.7501 2.50293 14.0911 3.15819 15.0073 4.197C15.9211 3.15819 17.2621 2.50293 18.7561 2.50293ZM13.7561 12.5029H6.2561V23.7529H13.7561V12.5029ZM23.7561 12.5029H16.2561V23.7529H23.7561V12.5029ZM11.2561 5.00293C9.87539 5.00293 8.7561 6.12222 8.7561 7.50293C8.7561 8.82088 9.77595 9.90063 11.0695 9.99607L11.2561 10.0029H13.7561V7.50293C13.7561 6.25087 12.8358 5.21379 11.6346 5.03139L11.4427 5.00978L11.2561 5.00293ZM18.7561 5.00293C17.4381 5.00293 16.3584 6.02278 16.263 7.31635L16.2561 7.50293V10.0029H18.7561C20.074 10.0029 21.1538 8.98308 21.2493 7.6895L21.2561 7.50293C21.2561 6.12222 20.1369 5.00293 18.7561 5.00293Z"
      fill="currentColor"
    />
  </svg>,
);
