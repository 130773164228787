import React from "react";
import {CardContent, Icon, Text} from "../ui/components";
import styled from "styled-components";

export interface LiquidityTransactionDirectionProps {
  leftValue: string;
  rightValue: string;
  leftIcon?: string;
  rightIcon?: string;
  icon?: string;
  deposit: number;
}

export const LiquidityTransactionDirection = ({
  icon,
  leftValue,
  rightValue,
  leftIcon,
  rightIcon,
  deposit,
}: LiquidityTransactionDirectionProps) => (
  <CardContentCustom>
    {deposit == 0 || deposit == 1 ? (
      <>
        <MainWrapper>
          <TokenWrapper>
            <Icon imageFallback={leftIcon ? leftIcon : ""} size="m" contrast />
            <br />
            <Text h6>{leftValue}</Text>
          </TokenWrapper>
        </MainWrapper>
        <Icon icon="close" color="overgrown" rotate={1} size="m" />
        <MainWrapper>
          <TokenWrapper>
            <Icon imageFallback={rightIcon ? rightIcon : ""} size="m" contrast />
            <br />
            <Text h6>{rightValue}</Text>
          </TokenWrapper>
        </MainWrapper>
      </>
    ) : (
      <></>
    )}
  </CardContentCustom>
);

const CardContentCustom = styled(CardContent)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 200px;
  margin-bottom: 20px;
`;

const TokenWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
`;

const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  width: 50%;
`;
