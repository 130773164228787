import React, {useState} from "react";
import styled from "styled-components";
import {Asset} from "../../model/Asset";
import {ListItem} from "../ListItem";
import {SymbolWithLogoText} from "../SymbolWithLogoText";
import {Modal, ModalProps, Text} from "../../ui/components";
import BigNumber from "bignumber.js";

interface AssetsListModalProps extends ModalProps {
  assets: {};
  setSelectedAsset: (asset: Asset) => void;
}

export const PaymasterModal = ({isVisible, onClose, assets, setSelectedAsset}: AssetsListModalProps) => {
  const [addedAssets, setAddedAssets] = useState(JSON.parse(JSON.stringify(assets)));

  React.useEffect(() => {
    setAddedAssets(JSON.parse(JSON.stringify(assets)));
    return () => {};
  }, [assets]);

  const onAssetClick = (asset: Asset) => {
    //@ts-ignore
    setSelectedAsset(asset.asset.address);
    onClose(false);
  };

  return (
    <Modal
      title="Paymaster"
      subtitle={
        <>
          Pay for network fees with any asset from the list below. <br />
          Make sure you have at least $1 worth of the token.{" "}
          <a
            style={{color: "rgb(var(--color-overgrown))"}}
            href="https://twitter.com/mute_io/status/1707068878558224584"
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </a>
        </>
      }
      isVisible={isVisible}
      onClose={onClose}
    >
      <AssetsList key={Math.floor(Math.random() * 10000)}>
        {Object.entries(addedAssets).map(([key, asset], index) => {
          //@ts-ignore
          if (!asset || !asset.asset || !asset.asset.logo) return;
          //@ts-ignore
          var {logo, symbol, name, balance, address, active, price} = asset.asset!;
          //@ts-ignore
          var {discount} = asset!;

          var needMore = new BigNumber(balance).times(price).lt(1);

          return (
            <ListItem
              key={index}
              onClick={() => (needMore ? {} : onAssetClick(asset as Asset))}
              className={needMore ? "disabled" : ""}
            >
              <SymbolWithLogoText
                logo={logo}
                symbol={symbol}
                name={name}
                text={discount != 0 ? "- " + String(discount) : ""}
              />

              <AssetValues>
                <Text bodyMedium>{parseFloat(balance).toFixed(2)}</Text>
                <Text bodyRegular color="gray400">
                  {"$" + new BigNumber(balance).times(price).toFixed(2)}
                </Text>
              </AssetValues>
            </ListItem>
          );
        })}
      </AssetsList>
    </Modal>
  );
};

const SeperatorLine = styled.div`
  width: 100%;
  height: 1px;
`;

const AssetsList = styled.ul`
  max-height: 400px;
  overflow-y: scroll;
`;

export const AssetValues = styled.div`
  text-align: right;
`;
