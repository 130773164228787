export const generatePriceRangeData = (rangeMult = 1) => {
  const maxAmount = 1_000_000;
  const min = 0.85 * rangeMult;
  const max = 1.15 * rangeMult;
  const length = 40;
  const xFromVariance = 0.6;
  const amountVariance = 0.2;

  const data = Array.from({length}).map((_, i) => {
    const stepSize = 1 / (length - 1);
    const xFrom = i / (length - 1);
    const from = min + (max - min) * (xFrom + Math.random() * stepSize * xFromVariance);
    const amount = (1 - Math.abs((length / 2 - i) / (length / 2))) * maxAmount * (1 + Math.random() * amountVariance);

    return {
      amount,
      from,
    };
  });
  data.push({amount: 0, from: data[data.length - 1].from + 0.1});

  return data.map((_, i, list) => ({..._, to: list[i + 1]?.from || _.from + 0.1}));
};
