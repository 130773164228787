const CORE_ZKSYNC_ERA = {
    //v3 amm daa
    nftDescriptorLibraryAddress: "0xfd26c2511B0cfc4a486437aA772D1B79008F530F",
    v3CoreFactoryAddress: "0x488A92576DA475f7429BC9dec9247045156144D3",
    proxyAdminAddress: "0xFbDC94788b6168483A74e36F42dA78Ed4F2C12Ef",
    tickLensAddress: "0x6aD373772A5782448c0426347cDD915D51f8a8e1",
    nonfungibleTokenPositionDescriptorAddress: "0xB05CC86abf662af15E1d4bB9B06653fd1c5e7334",
    descriptorProxyAddress: "0x9b8e2425cEFEc0A65201714eAB6886874564968a",
    nonfungibleTokenPositionManagerAddress: "0xa459EbF3E6A6d5875345f725bA3F107340b67732",
    v3MigratorAddress: "0x0d5E71a7aC0B1a5344721024E725683eDD341CC4",
    quoterV2Address: "0x6988C6bDC02E6AF5d99Fe45CE25D67dc781E2A2a",
    swapRouter02: "0x7d2c007A36c722b6EB9B23e9720690bb5e938203",
    INIT_CODE_HASH_v3: "0x0100128b709068f187cf0a565f39cd8d90baf51a12a5717aebcdf69a503234b9",
  
    //base amm
    factory: "0x40be1cBa6C5B47cDF9da7f963B6F761F4C60627D",
    paircode: "0x95d5c05820d58f1c8cc736b47fe10a29ddcd2cf73a0d842e8537b9fe510fc618",
    weth: "0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91",
    router: "0x8B791913eB07C32779a16750e3868aA8495F5964",
  
    //helpers
    multicall: "0xb1F9b5FCD56122CdfD7086e017ec63E50dC075e7",
    paymaster_v2: "0x4ae2Ba9A5C653038C6d2F5D9F80B28011A454597",
  
    eth: "0x0000000000000000000000000000000000000000",
    //mute based
    MUTE: "0x0e97C7a0F8B2C9885C8ac9fC6136e829CbC21d42",
  
    //KOI
    KOI_MAINNET: "0x9d14bce1daddf408d77295bb1be9b343814f44de",
    KOI: "0xa995ad25ce5eb76972ab356168f5e1d9257e4d05",
    VEKOI: "0x98dB4e3Df6502369dAD7AC99f3aEE5D064721C4C",
    VE_REWARDS: "0xAF072Cb0aB8008f7a085F689cbC0C98389896d05",
    VE_REWARDS_2: "0x298A2CBB29C019585444Fa5431A99DfB4Fcc3D63",

    PERMIT2ADDRESS: "0x0000000000225e31D15943971F47aD3022F714Fa",
    UNSUPPORTEDPROTOCOL: "0xa7cd0afe5ee7af96835822e3bdc62ba67a6a36f6",
    UNIVERSALROUTER: "0x3388530fbaf0c916fa7c0390413dfb178cb33cbb",
  
    USDC_CONVERSION: "0xE14096cACB218001EfF677f8d1338cBD3C9c2930",
};
  



const CORE_SOPHON_SEPOLIA = {
    //v3 amm daa
    v3CoreFactoryAddress: "0x2E9892c97f6BE1376d0275c116C489135Bb16809",
    nonfungibleTokenPositionManagerAddress: "0x57c56212775eCCD4bEB80d56170Eb0f396E7E836",
    quoterV2Address: "0xD9B8D3b533627Fa672EE7B43a4F99eB371322737",
    swapRouter02: "0xD9B8D3b533627Fa672EE7B43a4F99eB371322737",
    INIT_CODE_HASH_v3: "0x0100128b709068f187cf0a565f39cd8d90baf51a12a5717aebcdf69a503234b9",
    PERMIT2ADDRESS: "0x37CcE35d6A19C3948e9557308E705Ee64404B3D0",
    UNSUPPORTEDPROTOCOL: "0xa7cd0afe5ee7af96835822e3bdc62ba67a6a36f6",
    UNIVERSALROUTER: "0x5567226975D2B346ef26Af99AF71f1D248B55Cb4",
  
    //base amm
    factory: "0x53c991B31B5E5A4169ad17C25D2C8c62AE96948F",
    paircode: "0x95d5c05820d58f1c8cc736b47fe10a29ddcd2cf73a0d842e8537b9fe510fc618",
    weth: "0x9B7EA63EF5062661570afe548e3AD691D5652527",
    router: "0xd05d806c31E52dbD7dF5e0586420bca895844EB0",
  
    //helpers
    multicall: "0x83c04d112adedA2C6D9037bb6ecb42E7f0b108Af",
    paymaster_v2: "0x0000000000000000000000000000000000000000",
  
    eth: "0x0000000000000000000000000000000000000000",
    //mute based
    MUTE: "0x0000000000000000000000000000000000000000",
    //KOI
    KOI_MAINNET: "0x0000000000000000000000000000000000000000",
    KOI: "0x0000000000000000000000000000000000000000",
    VEKOI: "0x0000000000000000000000000000000000000000",
    VE_REWARDS: "0x0000000000000000000000000000000000000000",
    VE_REWARDS_2: "0x0000000000000000000000000000000000000000",
  
    USDC_CONVERSION: "0x0000000000000000000000000000000000000000",
};

export {CORE_ZKSYNC_ERA, CORE_SOPHON_SEPOLIA}