import styled from "styled-components";

import {flex, mq, palette, size, text} from "../../styles";

export const Icon = styled.div`
  margin-right: ${size(6)};
`;

export const Title = styled.h1`
  ${flex("center")}
  ${text.h2}

  line-height: 40px;
`;

export const TitleMargin = styled.div`
  margin-right: ${size(6)};
`;

export const Dash = styled.span`
  display: inline-block;
  height: 2px;
  width: 24px;
  transform: scale(1, 0.5);
  margin: 0 ${size(4)};
  background: ${palette.gray400};
`;

export const Content = styled.div``;

export const TitleWrapper = styled.div`
  ${flex("center")}
`;

export const Wrapper = styled.div<{hasArrow?: boolean; bp: number; extend?: boolean; breakContent?: boolean}>`
  ${flex("center", "space-between")}
  ${size.gap(2)}

  padding-bottom: ${(_) => size(_.hasArrow ? 10 : 4)};
  padding-top: ${size(4)};

  ${(_) =>
    mq(
      _.bp,
      `
    padding-bottom: ${size(6)};

    ${Title} {
      ${text.h3}
    }

    ${TitleMargin} {
      margin-right: ${size(4)};
    }

    ${Dash} {
      margin: 0 ${size(2)};
      width: 16px;
    }
  `,
    )}

  ${(_) =>
    _.extend &&
    `
    width: 100%;
  `}

  ${(_) =>
    _.breakContent &&
    `
    flex-wrap: wrap;
  `}
`;
