import { memo, ReactNode, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { Flex, IconAsset, Loading, Search, Text, Tooltip, TooltipProps } from "../../../../../../ui/components";
import { dateFormat, formatPriceZeros, numberWithCommas, timeFormat } from "../../../../../../utils";
import { useAnalyticsSearch } from "../../../../data/search";

import * as SC from './index.styles';

interface AnalyticsSearchProps{}

export const AnalyticsSearch = memo(function AnalyticsSearchComp({}: AnalyticsSearchProps) {
  const [query, setQuery] = useState('');
  const search = useAnalyticsSearch(query);

  const items = useMemo(() =>
    (search.data || [])
      .map(_ => (
        <SC.Item key={_.address}>
          <Flex gap={3}>
            {(() => {
              if (_.type === "token") {
                return (
                  <>
                    <IconAsset symbol={_.symbol} button size="s" />
                    <SC.Ellipsis>
                      <Text h6 ellipsis>{_.name}</Text>
                      <Text bodyRegular color="gray400">
                        {_.symbol}{" — "}
                        <Text bodyRegular color="orchid" bodyMedium inline>
                          <Link to={`/analytics/token/${_.address}`}>
                            Token details
                          </Link>
                        </Text>
                      </Text>
                    </SC.Ellipsis>
                  </>
                );
              }
              if (_.type === "pair") {
                return (
                  <>
                    <Flex>
                      <IconAsset stack symbol={_.token0} button size="xs" />
                      <IconAsset stack symbol={_.token1} button size="xs" />
                    </Flex>
                    <div>
                      <Text h6>
                        {_.token0}
                        {' '}
                        <Text inherit inline color="gray300">—</Text>
                        {' '}
                        {_.token1}
                      </Text>

                      <Text bodyRegular color="orchid" bodyMedium>
                        <Link to={`/analytics/pair/${_.address}`}>
                          Pair details
                        </Link>
                      </Text>
                    </div>
                  </>
                );
              }
              return null;
            })()}
          </Flex>
        </SC.Item>
      ))
  , [search.data]);

  return (
    <>
      <SC.Wrapper empty={search.data?.empty}>
        <SC.InputWrapper>
          <Search placeholder="Search" value={query} onChange={e => setQuery(e.target.value)}/>

          <SC.Loader visible={search.isFetching && !!query}>
            <Loading/>
          </SC.Loader>
        </SC.InputWrapper>

        <SC.Popup>
          {items}
        </SC.Popup>
      </SC.Wrapper>
    </>
  );
});