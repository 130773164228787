import React, {PropsWithChildren} from "react";
import styled from "styled-components";
import BigNumber from "bignumber.js";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import {Button, Text, LavaChip, layoutMq, Chip, Flex, Bullet} from "../../ui/components";
import {Row} from "../../common/Row";
import {card, size} from "../../ui/styles";

interface DropdownProps {
  onManageClick: () => any;
  icon0: string;
  icon1: string;
  token0: string;
  token1: string;
  tvl?: string;
  apy?: string;
  stable?: boolean;
  dark?: boolean;
  disabled?: boolean;
  tbv?: string;
  percent?: number;
  soldout?: boolean;
  v2?: boolean;
  stakes?: number;
  payoutToken?: any;
}

export const Dropdown = ({
  onManageClick,
  icon0,
  icon1,
  token0,
  token1,
  apy,
  tvl,
  stable,
  dark,
  disabled,
  tbv,
  percent,
  soldout,
  v2,
  stakes,
  payoutToken = {},
}: PropsWithChildren<DropdownProps>) => {
  return (
    <StyledDropdown>
      <LavaChipWrapper>
        <LavaChip
          tokenA={{symbol: token0, logo: icon0} as any}
          tokenB={{symbol: token1, logo: icon1} as any}
          payout={payoutToken}
          subtitle={`${stable ? "sMLP" : "vMLP"} Liquidity`}
          chip={<Chip label="APY" value={apy ? apy + "%" : ""} />}
        />
      </LavaChipWrapper>

      <Flex column gap={2}>
        <Row>
          <Text bodyRegular color="gray400">
            Rewards
          </Text>
          <Text bodyMedium color="orchid">
            ${tbv?.includes("NaN") ? "—" : tbv}
          </Text>
          <Text bodyRegular color="gray400">
            {String(percent)?.includes("NaN") ? "—" : new BigNumber(soldout ? 0 : (percent as number)).toFixed(2)}%
          </Text>
        </Row>

        <Slider
          railStyle={{backgroundColor: "rgb(var(--color-gray100))"}}
          trackStyle={{backgroundColor: "rgb(var(--color-lavender))"}}
          handleStyle={{width: "0px", height: "0px", borderRadius: "0", border: "0", color: "rgba(0,0,0,0)"}}
          min={0}
          max={100}
          value={soldout ? 0 : percent}
          step={0.01}
        />

        <span />

        <Button
          full
          secondary
          disabled={disabled || (soldout && stakes == 0) || (payoutToken.symbol == "MUTE" && stakes == 0)}
          onClick={onManageClick}
        >
          <Flex noBasis expandHorizontal justify="center">
            {!!stakes && <Flex.Grow />}
            <Flex justify="center">
              {disabled || soldout || payoutToken.symbol == "MUTE" || !v2 ? (v2 ? "Sold out" : "Withdraw") : "Stake"}
            </Flex>
            {!!stakes && (
              <Flex justify="flex-end">
                <Bullet>{stakes}</Bullet>
              </Flex>
            )}
          </Flex>
        </Button>
      </Flex>
    </StyledDropdown>
  );
};

const StyledDropdown = styled.div`
  ${card("xl", true)}
  ${size.padding(4)}

  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: calc((100% / 3) - (12px * 2 / 3));

  ${layoutMq(`
    max-width: 100%;
  `)}
`;

const LavaChipWrapper = styled.div`
  ${size.margin(-4)}

  margin-bottom: ${size(4)};
`;
