import {memo, PropsWithChildren} from "react";

import * as SC from "./index.styles";

interface CardScaleProps {}

export const CardScale = memo(function CardScaleComponent({children, ...props}: PropsWithChildren<CardScaleProps>) {
  return (
    <SC.Wrapper {...props}>
      <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M40 0C40 22.0898 57.9102 40 80 40C57.9102 40 40 57.9102 40 80C17.9102 80 0 62.0898 0 40C0 17.9102 17.9102 0 40 0Z"
          fill="currentColor"
        />
      </svg>
      <div>{children}</div>
    </SC.Wrapper>
  );
});
