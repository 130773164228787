import styled from "styled-components";

import {flex, size} from "../../../ui/styles";
import {Flex} from "../../../ui/components";

export const Switch = styled.div`
  ${flex("center", "center")}

  height: ${size(2)};
  position: relative;
  z-index: 2;

  & > * {
    cursor: pointer;
  }
`;

export const FlexTextLine = styled(Flex)`
  height: 20px;
`;
