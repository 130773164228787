import {toIcon} from "./icon.shared";

export const coinEth = toIcon(
  <svg width="24" height="24" viewBox="4.474 1 15.052 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9984 3L11.8778 3.41022V15.312L11.9984 15.4324L17.5228 12.167L11.9984 3Z" fill="#343434"></path>
    <path d="M11.9984 3L6.474 12.167L11.9984 15.4326V3Z" fill="#8C8C8C"></path>
    <path
      d="M11.9984 16.4788L11.9304 16.5618V20.8021L11.9984 21.0001L17.526 13.2151L11.9984 16.4788Z"
      fill="#3C3C3B"
    ></path>
    <path d="M11.9984 21.0001V16.4787L6.474 13.2151L11.9984 21.0001Z" fill="#8C8C8C"></path>
    <path d="M11.9984 15.4326L17.5226 12.167L11.9984 9.65601V15.4326Z" fill="#141414"></path>
    <path d="M6.474 12.1671L11.9982 15.4326V9.65588" fill="#393939"></path>
  </svg>,
  true,
);
