import {PropsWithChildren, useEffect, useState} from "react";
import {OnboardSteps} from "../../../pages/+common/onboard/steps";

import {Header} from "../header";
import {Icon} from "../icon";
import {Text} from "../text";
import {useHistory} from "react-router-dom";

import * as SC from "./index.styles";
import {Flex} from "../flex";
import {Divider} from "../divider";

interface LayoutProps {}

export const Layout = ({children}: PropsWithChildren<LayoutProps>) => {
  const [rebrand, setRebrand] = useState(true);

  const history = useHistory();

  useEffect(() => {
    setTimeout(() => setRebrand(false), 30000);
  }, []);

  const triggerClick = () => {
    setRebrand(false);
    history.push("/dao");
  };

  return (
    <SC.Wrapper>
      <Header />
      {children}
      {rebrand && (
        <SC.Toast onClick={() => triggerClick()}>
          <Flex align="center">
            <div>
              <Icon size="s" icon="coinKoi" button />
            </div>
            <Divider size={4} horizontal />
            <Text h5 color="c100" style={{lineHeight: "125%"}}>
              veRewards are now live with an extra APY!
            </Text>
          </Flex>
          <Text bodyMedium color="c100" align="center" style={{lineHeight: "125%"}}>
            Visit the Governance page to lock your KOI and earn passive rewards.
          </Text>
        </SC.Toast>
      )}
      <OnboardSteps />
      <div id="overlay-wrapper"></div>
    </SC.Wrapper>
  );
};
