import {Multicall} from "../web3/multicall/multicall";

interface _network{
  chainId: number;
  name: string;
}
export class CustomMulticall extends Multicall {
  constructor(nodeUrl: string, network: _network, multicallCustomContractAddress?: string | undefined) {
    super({multicallCustomContractAddress, tryAggregate: true, nodeUrl, network: network});
  }
}
