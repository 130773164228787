import {AnalyticsGlobalData} from "./global";

export const cleanData = ({data}: any) =>
  (data.pairs as any[]).map(({id, a, b, v, l, pairFee, stable, ...rest}) => ({
    id: id as string,
    token0: a.s as string,
    token0Data: a as any,
    token1: b.s as string,
    token1Data: b as any,
    volume: +v,
    liquidity: +l,
    pairFee,
    stable,
    rest,
}))

export const cleanDataV3 = ({data}: any) =>
  (data.pools as any[]).map(({id, a, b, v, l, feeTier, ...rest}) => ({
    id: id as string,
    token0: a.s as string,
    token0Data: a as any,
    token1: b.s as string,
    token1Data: b as any,
    volume: +v,
    liquidity: +l,
    feeTier,
    rest,
}))

type CleanData = ReturnType<typeof cleanData>[number];
type CleanDataV3 = ReturnType<typeof cleanDataV3>[number];

const value = (_: number) => (isNaN(_) ? 0 : _);

export const transformPairs = ({ethPrice, ethDayPrice}: AnalyticsGlobalData = {} as any, pairs: any[] = []) =>
  pairs?.map?.(
    ({oneDayBefore, ...pair}: CleanData & {oneDayBefore: CleanData}) => {
      const volDay = value(+pair.volume - +oneDayBefore?.volume);
      return {
        ...pair,
        volumeDayUsd: volDay,
        feesDayUsd: volDay * Number(pair.pairFee) / 10000,
        apy: ((volDay * Number(pair.pairFee) / 10000) * 365) / pair.liquidity * 100,
      }
    }
);

export const transformPairsV3 = ({ethPrice, ethDayPrice}: AnalyticsGlobalData = {} as any, pairs: any[] = []) =>
  pairs?.map?.(
    ({oneDayBefore, ...pair}: CleanDataV3 & {oneDayBefore: CleanDataV3}) => {
      const volDay = value(+pair.volume - +oneDayBefore?.volume);
      return {
        ...pair,
        volumeDayUsd: volDay,
        feesDayUsd: volDay * Number(pair.feeTier) / 1000000,
        apy: ((volDay * Number(pair.feeTier) / 1000000) * 365) / pair.liquidity * 100,
      }
    }
);