import styled from "styled-components";

import {card, flex, microPalette, mq, palette, size, transition, typo} from "../../styles";
import {headerBreakpoint} from "../header/index.styles";

export const Wrapper = styled.div`
  ${typo.primary.normal}
  ${flex("center", "center", "column")}

  width: 100%;
  min-height: 100vh;
  font-size: 16px;
  color: ${palette.black};
  background: ${palette.gray50};
  position: relative;
  padding: ${size.raw(4 + 8) + 72}px ${size(10)} ${size(10)};

  ${mq(
    headerBreakpoint,
    `
    padding: ${size.raw(8) + 72}px ${size(6)} ${size(8)};
  `,
  )}

  ${mq(
    580,
    `
    padding: ${size.raw(8) + 72}px ${size(3)} ${size(8)};
  `,
  )}

  ${mq(
    400,
    `
    padding: ${size.raw(4) + 72}px ${size(2)} ${size(4)};
  `,
  )}

  * {
    ::-webkit-scrollbar {
      width: ${size(1)};
      height: ${size(1)};
    }

    ::-webkit-scrollbar-track {
      background: ${palette.gray100};
      border-radius: ${size(1)};
      margin: ${size(2)} ${size(6)} ${size(6)} ${size(6)};
    }

    ::-webkit-scrollbar-thumb {
      ${transition.fast}

      background: ${palette.gray300};
      border-radius: ${size(1)};
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${palette.gray400};
    }
  }
`;

export const Toast = styled.div`
  ${card("m", true)}
  ${size.padding(4, 6)}

  cursor: pointer;
  position: fixed;
  bottom: ${size(8)};
  left: ${size(8)};
  max-width: 100%;
  width: 300px;
  border: 0;
  line-height: 120%;

  background: ${microPalette.c500};
  color: ${microPalette.c200};

  & > *:first-child {
    border-bottom: 1px solid ${microPalette.c200};
    padding-bottom: ${size(3)};
    margin-bottom: ${size(3)};
  }
`;
