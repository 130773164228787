import BigNumber from "bignumber.js";

export function getAmountOut(amountIn, reserve0, reserve1, stable = true) {
  return new BigNumber(_getAmountOut(amountIn, reserve0, reserve1, stable));
}

export function getAmountOutFee(amountIn, reserve0, reserve1, stable = true, fee = 50) {
  return new BigNumber(
    _getAmountOut(new BigNumber(amountIn).times(10000 - fee).div(10000), reserve0, reserve1, stable),
  );
}

function _getAmountOut(amountIn, _reserve0, _reserve1, stable = true) {
  if (stable == false) {
    let reserveA = new BigNumber(_reserve0);
    let reserveB = new BigNumber(_reserve1);
    return new BigNumber(amountIn).times(reserveB).div(reserveA.plus(amountIn)).toFixed();
  } else {
    var xy = _k(_reserve0, _reserve1);
    _reserve0 = new BigNumber(_reserve0);
    _reserve1 = new BigNumber(_reserve1);

    let reserveA = _reserve0;
    let reserveB = _reserve1;

    var y = reserveB.minus(_get_y(new BigNumber(amountIn).plus(reserveA), xy, reserveB));
    return y.toFixed();
  }
}

export function _k(x, y) {
  var _x = new BigNumber(x);
  var _y = new BigNumber(y);
  var _a = _x.times(_y);
  var _b = _x.times(_x).plus(_y.times(_y));
  return _a.times(_b); // x3y+y3x >= k
}

function _f(x0, y) {
  let first = new BigNumber(y).times(y).times(y);
  let second = new BigNumber(x0).times(x0).times(x0).times(y);
  return x0.times(first).plus(second);
}

function _d(x0, y) {
  let first = new BigNumber(y).times(y);
  let second = new BigNumber(x0).times(x0).times(x0);
  return new BigNumber(3).times(x0).times(first).plus(second);
}

function _get_y(x0, xy, y) {
  for (var i = 0; i < 255; i++) {
    var y_prev = y;
    var k = _f(x0, y);
    if (k.lt(xy)) {
      var dy = xy.minus(k).div(_d(x0, y));
      y = y.plus(dy);
    } else {
      var dy = k.minus(xy).div(_d(x0, y));
      y = y.minus(dy);
    }
    if (y.gt(y_prev)) {
      if (y.minus(y_prev).lte(1)) {
        return y;
      }
    } else {
      if (y_prev.minus(y).lte(1)) {
        return y;
      }
    }
  }
  return y;
}
