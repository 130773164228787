import {Transaction} from "../../../model/Transaction";

interface IconStatusProps {
  status?: "success" | "error" | "loading";
  transaction?: Transaction;
}

export const IconStatus = ({status, transaction}: IconStatusProps) => {
  if (transaction?.status) {
    status =
      (
        {
          Confirmed: "success",
        } as const
      )[transaction.status] || status!;
  }

  if (status === "success") {
    return (
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="10" fill="#EEF9ED" />
        <path d="M13.75 19.75L23.5 10L25 11.5L13.75 22.75L7 16L8.50001 14.5L13.75 19.75Z" fill="#2BB215" />
      </svg>
    );
  }
  if (status === "error") {
    return (
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="10" fill="#FFECEF" />
        <path
          d="M16 14.4445L21.4445 9L23 10.5555L17.5555 16L23 21.4444L21.4445 22.9999L16 17.5555L10.5556 22.9999L9 21.4444L14.4444 16L9 10.5555L10.5556 9L16 14.4445Z"
          fill="#E80B40"
        />
      </svg>
    );
  }
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="10" fill="#F6F6F6" />
      <path
        d="M16 6C16.5652 6 17.0234 6.45819 17.0234 7.02338V10.0935C17.0234 10.6587 16.5652 11.1169 16 11.1169C15.4348 11.1169 14.9766 10.6587 14.9766 10.0935V7.02338C14.9766 6.45819 15.4348 6 16 6ZM16 21.3507C16.5652 21.3507 17.0234 21.8088 17.0234 22.3741V25.4442C17.0234 26.0094 16.5652 26.4676 16 26.4676C15.4348 26.4676 14.9766 26.0094 14.9766 25.4442V22.3741C14.9766 21.8088 15.4348 21.3507 16 21.3507ZM24.8627 11.1169C25.1453 11.6064 24.9776 12.2323 24.4881 12.5148L21.8293 14.0499C21.3398 14.3326 20.7139 14.1648 20.4314 13.6753C20.1488 13.1859 20.3164 12.56 20.8059 12.2774L23.4648 10.7423C23.9542 10.4597 24.5801 10.6274 24.8627 11.1169ZM11.5686 18.7922C11.8512 19.2817 11.6835 19.9076 11.1941 20.1902L8.53524 21.7252C8.04576 22.0079 7.41987 21.8402 7.13728 21.3507C6.85468 20.8612 7.02238 20.2353 7.51186 19.9527L10.1707 18.4177C10.6601 18.135 11.286 18.3028 11.5686 18.7922ZM24.8627 21.3507C24.5801 21.8402 23.9542 22.0079 23.4648 21.7252L20.8059 20.1902C20.3164 19.9076 20.1488 19.2817 20.4314 18.7922C20.7139 18.3028 21.3398 18.135 21.8293 18.4177L24.4881 19.9527C24.9776 20.2353 25.1453 20.8612 24.8627 21.3507ZM11.5686 13.6753C11.286 14.1648 10.6601 14.3326 10.1707 14.0499L7.51186 12.5148C7.02238 12.2323 6.85468 11.6064 7.13728 11.1169C7.41987 10.6274 8.04576 10.4597 8.53524 10.7423L11.1941 12.2774C11.6835 12.56 11.8512 13.1859 11.5686 13.6753Z"
        fill="#868686"
      />
    </svg>
  );
};
