import {memo, useEffect, useRef, useState} from "react";
import {size} from "../../../ui/styles";

import * as SC from "./ExchangeSmartRoute.styles";

interface ExchangeSmartRoutePath {
  paths: number;
  speeds?: number[];
  gap: number;
  row?: number;
  padding?: number;
  rounded?: number;
  type?: string[];
}

export const ExchangeSmartRoutePath = memo(function ExchangeSmartRoutePathC(props: ExchangeSmartRoutePath) {
  let {paths, gap, row = 32, padding = 2.5, rounded = 2, speeds = [], type = []} = props;
  padding = size.raw(padding);
  rounded = size.raw(rounded);

  const getHeight = (items: number = paths) => items * row + (items - 1) * size.raw(gap);

  const containerRef = useRef<SVGSVGElement>(null);
  const [width, setWidth] = useState(0);
  const height = getHeight();

  useEffect(() => {
    if (!containerRef.current) return;
    const update = () => {
      if (!containerRef?.current) return;
      const rect = containerRef.current!.getBoundingClientRect();
      setWidth(Math.floor(rect.width));
    };
    update();
    const observer = new ResizeObserver(update);
    observer.observe(containerRef.current!);
    return () => {
      observer.disconnect();
    };
  }, [containerRef]);

  const r = rounded;
  const p = padding;
  const getY = (n: number) => getHeight(n + 1) - row / 2;
  const getCurve = (dir: boolean, up: boolean, x = 0, y = 0) =>
    up
      ? dir
        ? `${r / 2} 0 ${r} ${r / 2} ${r} ${r}`
        : `0 ${r / 2} ${r / 2} ${r} ${r} ${r}`
      : dir
        ? `${r / 2} 0 ${r} -${r / 2} ${r} -${r}`
        : `0 -${r / 2} ${r / 2} -${r} ${r} -${r}`;

  return (
    <SC.Svg ref={containerRef} viewBox={`0 0 ${width} ${height}`}>
      {Array.from({length: paths})
        .map((_, i) => {
          if (paths % 2 === 1 && Math.floor(paths / 2) === i) {
            return `M 0 ${height / 2} l ${width} 0`;
          }
          const up = paths / 2 <= i;
          const y = getY(i);
          return `
            M 0 ${height / 2}
            l ${p} 0
            c ${getCurve(true, up)}
            L ${p + r} ${y + (up ? -r : r)}
            c ${getCurve(false, up)}
            L ${width - p * 2 - 2 * r} ${y}
            c ${getCurve(true, !up)}
            L ${width - p * 2 - r} ${height / 2 + (up ? r : -r)}
            c ${getCurve(false, !up)}
            L ${width} ${height / 2}
          `;
        })
        .map((d, i) => [<SC.Path key={i} d={d} />])
        .flat()}
    </SC.Svg>
  );
});
