import {memo, PropsWithChildren, useEffect, useId, useMemo, useRef, useState} from "react";

import {useContainerSize} from "../../../../../../../ui/components/-utils/container-size";
import { svgPath} from "../../../../../../../ui/components/-utils/path";
import {palette} from "../../../../../../../ui/styles";

import * as SC from "./index.styles";

interface AnalyticsChartLiquidityChartProps {
  data: {value: number; inner: number}[];
  max: number;
  min: number;
  setHover?: (index: number | undefined) => void;
}

const paddingTop = 2;
const paddingBottom = 2;

export const AnalyticsChartLiquidityChart = memo(function AnalyticsChartLiquidityChartComponent(
  props: PropsWithChildren<AnalyticsChartLiquidityChartProps>,
) {
  const {data = [], max, min, setHover} = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const {width, height} = useContainerSize(containerRef);
  const [markPosition, setMarkPosition] = useState({x: 0, y1: 0, y2: 0});
  const [markVisible, setMarkVisible] = useState(false);

  const randomId = useId();
  const getId = (name: string) => `chart-l-${randomId}-${name}`;

  const {pathValue, pathInner, points} = useMemo(() => {
    const reversedData = JSON.parse(JSON.stringify(data));

    const points: any[] = reversedData.map(
      (_, i) =>
        [
          (i * width) / (data.length - 1),
          (1 - _.value) * (height - paddingTop - paddingBottom) + paddingTop,
          (1 - _.inner) * (height - paddingTop - paddingBottom) + paddingTop,
        ] as const,
    );

    return {
      points,
      pathValue: svgPath(
        points.map((_) => [_[0], _[1]]),
        0.15,
        2,
      ),
      pathInner: svgPath(
        points.map((_) => [_[0], _[2]]),
        0.15,
        2,
      ),
    };
  }, [width, height, data, max, min]);

  useEffect(() => {
    const elem = containerRef.current;
    if (!elem || !points?.length) return;

    const onEnter = () => setMarkVisible(true);
    const onLeave = () => {
      setMarkVisible(false);
      setHover?.(undefined);
    };
    const onMove = (event: MouseEvent) => {
      const rect = containerRef.current!.getBoundingClientRect();
      const nPoints = points.length - 1;
      const point = Math.min(nPoints, Math.max(0, Math.round(((event.clientX - rect.x) / rect.width) * nPoints)));
      setMarkPosition({x: point / nPoints, y1: points[point]?.[1] / height, y2: points[point]?.[2] / height});
      setHover?.(point);
    };

    elem.addEventListener("mousemove", onMove, true);
    elem.addEventListener("mouseenter", onEnter, true);
    elem.addEventListener("mouseleave", onLeave, true);
    return () => {
      elem.removeEventListener("mousemove", onMove, true);
      elem.removeEventListener("mouseenter", onEnter, true);
      elem.removeEventListener("mouseleave", onLeave, true);
    };
  }, [containerRef, points]);

  return (
    <SC.Wrapper ref={containerRef}>
      <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
        <SC.AreaPath
          d={`
          ${pathValue}
          l 0 0
          L ${width} ${height}
          L 0 ${height}
        `}
          fill={`url(#${getId("fade-gradient")})`}
        />
        <SC.MainLinePath d={pathValue} />
        <SC.AreaPath
          alt
          d={`
          ${pathInner}
          l 0 0
          L ${width} ${height}
          L 0 ${height}
        `}
          fill={`url(#${getId("fade-gradient-alt")})`}
        />
        <SC.MainLinePath alt d={pathInner} />

        <defs>
          <linearGradient id={getId("fade-gradient")} y2="1" x2="0">
            <stop offset="0" stopColor={palette.goldenrod} />
            <stop offset="1" stopColor={palette.white} />
          </linearGradient>
          <linearGradient id={getId("fade-gradient-alt")} y2="1" x2="0">
            <stop offset="0" stopColor={palette.chalk} />
            <stop offset="1" stopColor={palette.white} />
          </linearGradient>
        </defs>
      </svg>
      <SC.Mark left={markPosition.x} visible={markVisible}>
        <SC.Flake icon="flake" size="xs" top={markPosition.y1} />
        <SC.Flake icon="flake" size="xs" top={markPosition.y2} alt />
      </SC.Mark>
    </SC.Wrapper>
  );
});
