import {memo, PropsWithChildren, ReactNode, useState} from "react";
import {withColor} from "../-with-color";

import * as SC from "./index.styles";

type TabValue = number | string | boolean;

interface TabsProps<T extends TabValue> {
  tabs: {value: T; label: ReactNode}[];
  selected?: T;
  onSelect?: (value: T) => void;
  full?: boolean;
  standalone?: boolean;
  small?: boolean;
  micro?: boolean;
}

export const Tabs = memo(
  withColor(function TabsComponent<T extends TabValue>(props: PropsWithChildren<TabsProps<T>>, color) {
    const {tabs, selected, onSelect, full, standalone, small, micro} = props;
    const [standaloneSelected, setStandaloneSelected] = useState(tabs[0]?.value);
    return (
      <SC.Wrapper {...{full, small, micro}}>
        {tabs.map((tab, i) => (
          <SC.Tab
            key={i}
            active={tab?.value === (standalone ? standaloneSelected : selected)}
            onClick={() => {
              setStandaloneSelected?.(tab?.value);
              onSelect?.(tab?.value);
            }}
          >
            {tab.label}
          </SC.Tab>
        ))}
      </SC.Wrapper>
    );
  }),
);
