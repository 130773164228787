import {memo} from "react";
import {Route, RouteComponentProps, Switch} from "react-router-dom";
import {AnalyticsOverview} from "./pages/overview";
import { AnalyticsPage } from "./pages/overview/page";
import { AnalyticsPairPage } from "./pages/overview/page-pair";
import { AnalyticsTokenPage } from "./pages/overview/page-token";

import {AnalyticsProvider} from "./provider";
import { AnalyticsPairPageV3 } from "./pages/overview/page-pair-v3";

export const AnalyticsMain = memo(function AnalyticsMainComp(props: RouteComponentProps) {
  return (
    <AnalyticsProvider>
      <Switch>
        <Route exact path="/analytics">
          <AnalyticsOverview />
        </Route>

        <Route exact path="/analytics/tokens">
          <AnalyticsPage page="tokens" />
        </Route>
        <Route exact path="/analytics/token/:address">
          <AnalyticsTokenPage />
        </Route>

        <Route exact path="/analytics/pairs">
          <AnalyticsPage page="pairs" />
        </Route>
        <Route exact path="/analytics/pair/:address">
          <AnalyticsPairPage />
        </Route>
        <Route exact path="/analytics/pairv3/:address">
          <AnalyticsPairPageV3 />
        </Route>

        <Route exact path="/analytics/transactions">
          <AnalyticsPage page="txs" />
        </Route>
      </Switch>
    </AnalyticsProvider>
  );
});
