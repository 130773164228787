import {AnalyticsGlobalData} from "./global";

export const tokenProps = `
  a: id
  s: symbol
  n: name
  l: totalLiquidity
  v: tradeVolumeUSD
  t: txCount
  p: derivedETH
`;

const value = (_: number) => (isNaN(_) ? 0 : _) || 0;

export const transformTokens = ({ethPrice, ethDayPrice}: AnalyticsGlobalData = {} as any, tokens: any[] = [], blockEthPrice?: number) =>
  tokens?.map?.(
    ({a, s, n, l, v, t, p, oneDayBefore = {}}: Record<string, string> & {oneDayBefore: Record<string, string>}) => {
      const price = +p * (blockEthPrice || ethPrice);
      const change = price - (+oneDayBefore?.p * ethDayPrice);
      return {
        address: a,
        symbol: s,
        name: n,
        liquidityUsd: value(+l * price),
        price: value(price),
        priceChange: value(change),
        priceChangePercentage: value((change / price) * 100),
        volumeUsd: value(+v),
        volumeDayUsd: value(+v - +oneDayBefore?.v),
        feesDayUsd: value(+v - +oneDayBefore?.v) * 0.005,
        txCount: value(+t),
        txDayCount: value(+t - +oneDayBefore?.t),
      }
    }
  );
