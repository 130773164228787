import {memo, useMemo, useState} from "react";

import {Flex, Tabs, Divider, Skeleton, Loading, useLayoutMq} from "../../../../../../ui/components";
import {abbrNum, formatPrice} from "../../../../../../utils";
import {AnalyticsData} from "../../../../data";
import { usePairData } from "../../../../data/pair-data";
import {useAnalytics} from "../../../../provider";

import * as SC from "./index.styles";

interface AnalyticsChartVolumePairProps {
  groups: ReturnType<typeof usePairData>['chart'];
  token0: string;
  token1: string
}

type ZoomType = "hour" | "day" | "week" | "month";

const {format: formatHour} = new Intl.DateTimeFormat("en-us", {month: "short", day: "numeric", hour: "numeric"});
const {format: formatDay} = new Intl.DateTimeFormat("en-us", {month: "short", day: "numeric"});
const {format: formatDayLong} = new Intl.DateTimeFormat("en-us", {month: "short", day: "numeric", year: "numeric"});
const {format: formatMonth} = new Intl.DateTimeFormat("en-us", {month: "short", year: "numeric"});

const config = {
  hour: {p: "h" as const, sub: "Last 24 hours", format: formatHour, date: (_) => formatHour(_.date)},
  day: {p: "d" as const, sub: "Last 30 days", format: formatDay, date: (_) => formatDayLong(_.date)},
  week: {
    p: "w" as const, 
    sub: "Last 53 weeks",
    format: formatDayLong,
    date: (_) => `${formatDayLong(_.date)} - ${formatDayLong(_.date + 7 * 24 * 60 * 60 * 1000)}`,
  },
  month: {p: "m" as const, sub: "Montly data", format: formatMonth, date: (_) => formatMonth(_.date)},
};

export const AnalyticsChartVolumePair = memo(function AnalyticsChartVolumePairComp({groups, token0, token1}: AnalyticsChartVolumePairProps) {
  const isMobile = useLayoutMq();
  const [zoom, setZoom] = useState<ZoomType>("week");
  const [param, setParam] = useState<keyof Exclude<ReturnType<typeof usePairData>['chart']['h'], undefined>[number]>("volume");
  const [hover, setHover] = useState<number>();

  const {data, min, max} = useMemo(() => {
    const values = (groups[config[zoom].p] || [])
      .map(_ => ({
        date: _.timestamp,
        raw: +_[param] || 0,
      }))

    let {min, max} = values.reduce(
      (acc, _) => ({
        max: Math.max(acc.max, _.raw),
        min: Math.min(acc.min, _.raw),
      }),
      {max: -Infinity, min: Infinity},
    );
    min = Math.max(0, min - (max - min) * 0.8);
    const gap = max - min;

    return {
      min,
      max,
      data: values
        .map(_ => ({
          ..._,
          value: _.raw / max,
        }))
    }
  }, [zoom, param, groups]);

  const {xAxis, yAxis} = useMemo(() => {
    const nX = 5;
    const nY = 3;
    const x = Array.from({length: nX})
      .map((_, i) => i / (nX - 1))
      .map((_) => data[Math.round((data.length - 1) * _)]?.date)
      .map(config[zoom].format);
    const y = Array.from({length: nY})
      .map((_, i) => i / (nY - 1))
      .map((_) => min + (max - min) * _)
      .map((_) => abbrNum(_, 3));
    return {
      xAxis: x,
      yAxis: y,
    };
  }, [zoom, data, min, max]);

  const agg = useMemo(() => {
    if (param !== 'volume') return data.slice(-1)[0];
    return data.reduce(
      (acc, _) => ({
        raw: acc.raw + _.raw,
      }),
      {raw: 0},
    );
  }, [data, param]);

  const isHover = hover !== undefined;
  const isLoading = groups.isLoading || !data.length;

  return (
    <>
      <SC.Wrapper>
        {isLoading && <Loading size="xl" />}
        <Flex justify="space-between" align="flex-start">
          <div>
            <SC.Title>
              {useMemo(() => ({
                volumeUsd: "Volume",
                liquidityUsd: "Liquidity",
                price: "Price",
              })[param], [param])}
            </SC.Title>
            <SC.TitleValue>
              <Skeleton hideChildren active={isLoading} width={140}>
                <span>
                  ${param.includes("price")
                    ? formatPrice(!isHover ? agg.raw : data[hover!]?.raw, true, 4)
                    : abbrNum(!isHover ? agg.raw : data[hover!]?.raw, 5)
                  }
                </span>
              </Skeleton>
            </SC.TitleValue>
            <Divider size={0.5} />
            <SC.TitleValueSubtitle>
              <Skeleton hideChildren active={isLoading} width={100}>
                <span>{isHover ? config[zoom].date(data[hover!]) : config[zoom].sub}</span>
              </Skeleton>
            </SC.TitleValueSubtitle>
          </div>
          <Flex column align="flex-end" gap={isMobile ? 1 : 2}>
            <Skeleton hideChildren active={isLoading}>
              <Tabs
                small
                micro={isMobile}
                selected={param}
                onSelect={setParam as any}
                tabs={[
                  {value: "volume", label: "Volume"},
                  {value: "liquidity", label: "Liquidity"},
                  {value: "priceAB", label: token0},
                  {value: "priceBA", label: token1},
                ]}
              />
            </Skeleton>
            <Skeleton hideChildren active={isLoading}>
              <Tabs
                micro
                selected={zoom}
                onSelect={setZoom}
                tabs={[
                  {value: "hour", label: "H"},
                  {value: "day", label: "D"},
                  {value: "week", label: "W"},
                  {value: "month", label: "M"},
                ]}
              />
            </Skeleton>
          </Flex>
        </Flex>
        <SC.Chart onMouseLeave={() => setHover(undefined)}>
          {!isLoading && (
            <>
              <SC.ChartBarWrapper hover={isHover}>
                {data.map((_, i) => (
                  <SC.ChartBar
                    key={[zoom, i].join()}
                    inner
                    transition
                    active={i === hover}
                    style={{height: _.value * 100 + "%"}}
                    onMouseEnter={() => setHover(i)}
                    onMouseLeave={() => setHover(undefined)}
                  />
                ))}
              </SC.ChartBarWrapper>
              <SC.XAxis>
                {xAxis.map((_) => (
                  <span>{_}</span>
                ))}
              </SC.XAxis>
              <SC.YAxis>
                {yAxis.map((_) => (
                  <span>{_}</span>
                ))}
              </SC.YAxis>
            </>
          )}
        </SC.Chart>
      </SC.Wrapper>
    </>
  );
});
