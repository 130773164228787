import {memo} from "react";

import {withColor} from "../-with-color";
import {formatPriceInfinity} from "../../../utils";
import {Icon} from "../icon";

import * as SC from "./index.styles";

interface PriceRangeProps {
  rangeFrom: number;
  rangeTo: number;
  price: number;
}

export const PriceRange = memo(
  withColor(function PriceRangeComponent(props: PriceRangeProps) {
    const {rangeFrom, rangeTo, price} = props;
    return (
      <SC.Container>
        <SC.Wrapper {...props}>
          <SC.LineBackground />
          <SC.Range>
            <SC.ValueChip>{formatPriceInfinity(rangeFrom)}</SC.ValueChip>
            <SC.ValueChip right>{formatPriceInfinity(rangeTo)}</SC.ValueChip>
          </SC.Range>
          <SC.PriceSelector>
            <Icon icon="flake" rotate={1} size="xxs" />
          </SC.PriceSelector>
          {price < rangeFrom || price > rangeTo ? <SC.Warning>Price is out of range</SC.Warning> : <></>}
        </SC.Wrapper>
      </SC.Container>
    );
  }),
);
