import styled from "styled-components";

import {br, palette} from "../../styles";

export const BorderRadius = styled.div<{size: keyof typeof br}>`
  ${(_) => br[_.size]}

  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  width: 40px;
  height: 40px;
  border: 1px solid ${palette.gray400.o(0.9)};
  border-top: 0;
  border-left: 0;
  position: relative;
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 14px;

  &:before {
    content: "${(_) => _.size}";
    position: absolute;
    font-size: 10px;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 4px;
    color: ${palette.black};
    pointer-events: none;
  }

  &:after {
    content: "${(_) => br[_.size].match(/\d+/)}";
    position: absolute;
    font-size: 12px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: ${palette.black};
    pointer-events: none;
  }
`;
