import {toIcon} from "./icon.shared";

export const trophy = toIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
    <path
      d="M11.3374 14.1171V15.8352H15.5041V17.5019H5.50407V15.8352H9.67075V14.1171C6.38214 13.707 3.8374 10.9017 3.8374 7.50195V2.50195H17.1708V7.50195C17.1708 10.9017 14.626 13.707 11.3374 14.1171ZM5.50407 4.16862V7.50195C5.50407 10.2634 7.74264 12.5019 10.5041 12.5019C13.2655 12.5019 15.5041 10.2634 15.5041 7.50195V4.16862H5.50407ZM1.3374 4.16862H3.00407V7.50195H1.3374V4.16862ZM18.0041 4.16862H19.6708V7.50195H18.0041V4.16862Z"
      fill="#194C07"
    />
  </svg>,
);
