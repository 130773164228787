import styled from "styled-components";

import {card, size, text} from "../../styles";

export const Wrapper = styled.div<{disabled?: boolean; large?: boolean}>`
  ${card.button("s")}
  ${size.padding(2)}
  ${size.gap(2)}
  ${text.h6}

  display: inline-flex;
  align-items: center;
  cursor: pointer;
  min-width: max-content;
  min-height: 40px;

  ${(_) =>
    _.disabled &&
    `
    pointer-events: none;
  `}

  ${(_) =>
    _.large &&
    `
    width: 100%;
    min-height: 50px;
  `}
`;
