import styled from "styled-components";
import {Icon} from "../../../../../../../ui/components";

import {br, flex, palette, size, text, transition} from "../../../../../../../ui/styles";

export const MainLinePath = styled.path<{alt?: boolean}>`
  stroke: ${(_) => (_.alt ? palette.lavender : palette.ultramoss)};
  stroke-width: 2px;
  fill: none;
`;

export const AreaPath = styled.path<{alt?: boolean}>`
  // fill: ${(_) => (_.alt ? palette.chalk : palette.goldenrod)};
`;

export const Value = styled.div`
  ${text.h3}

  z-index: 4;
  position: absolute;
  left: ${size(6)};
  bottom: ${size(4)};
`;

export const Pair = styled.div`
  ${text.h6}
  ${size.padding(1, 2)}
  ${size.gap(2)}
  ${flex("center")}
  ${br.xs}

  z-index: 4;
  position: absolute;
  left: ${size(6)};
  top: ${size(4)};
  background: ${palette.gray50};
  text-transform: uppercase;
`;

export const Mark = styled.div<{left?: number; visible?: boolean}>`
  ${transition.fast.prop("opacity")}

  height: 100%;
  border-right: 2px solid ${palette.gray300};
  position: absolute;
  top: 0;
  z-index: 2;
  opacity: 0;
  transform: translateX(-50%);
  background: linear-gradient(270deg, ${palette.white.o(0.8)} 0%, transparent 100%);
  width: 120px;
  transform: translateX(-100%);

  ${(_) =>
    _.visible &&
    `
    opacity: 1;
  `}

  ${(_) =>
    _.left !== undefined &&
    `
    left: min(max(2px, ${_.left * 100}%), calc(100% - 1px));
  `}
`;

export const Flake = styled(Icon)<{top?: number; alt?: boolean}>`
  color: ${(_) => (_.alt ? palette.orchid : palette.overgrown)};
  position: absolute;
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  right: 1px;
  transform: translateX(9px) translateY(-50%);

  ${(_) =>
    _.top !== undefined &&
    `
    top: ${_.top * 100}%;
  `}
`;

export const Wrapper = styled.div<{active?: boolean}>`
  ${size.margin("0 -5 0 -10")}

  height: 100%;
  position: relative;

  &:hover ${Value} span[data-value="base"],
  &:not(:hover) ${Value} span[data-value="hover"] {
    display: none;
  }
`;
