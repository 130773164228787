import React, {ChangeEvent, useState} from "react";
import styled, {css} from "styled-components";
import {ModalText} from "../../common/modal/ModalComponents";

import {Modal, Button, ModalProps} from "../../ui/components";


interface SlipageToleranceModalProps extends ModalProps {
  defaultLimit: number;
  execute: (val: number) => void;
}

export const LiquidityFeeModal = ({isVisible, onClose, defaultLimit, execute}: SlipageToleranceModalProps) => {
  const [limit, setLimit] = useState(defaultLimit);
  const [inputValue, setInputValue] = useState(defaultLimit.toFixed(2));
  const [calculating, setCalculating] = useState(false);

  const onChange = (value: string) => {
    setInputValue(value);
    setLimit(Number(value));
  };

  const minValue = 0.01;
  const maxValue = 10;
  const onBlur = (value: number | undefined) => {
    if (value === undefined) return;

    let slippage = value;
    if (value < minValue) slippage = minValue;
    else if (value > maxValue) slippage = maxValue;

    onChange(slippage.toString());
  };

  const executeChange = async () => {
    setCalculating(true);
    await execute(Number(inputValue));
  };

  return (
    <Modal title="Change pool fee" isVisible={isVisible} onClose={onClose}>
      <ModalText>Changing this pool's fee will incur a 0.1% tax on your LP holdings.</ModalText>
      <InputWrapper>
        <Input
          value={inputValue}
          onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
          onBlur={() => onBlur(limit)}
          type="number"
          placeholder={limit?.toString()}
        />
      </InputWrapper>
      <ButtonMod loading={calculating} onClick={() => executeChange()} disabled={inputValue == ""}>
        {"Change fee"}
      </ButtonMod>
    </Modal>
  );
};

const List = styled.ul`
  display: flex;
  justify-content: space-between;
  margin-top: 35px;

  @media (max-width: 500px) {
    max-width: 196px;
    flex-wrap: wrap;
    margin: 60px auto 0;
  }
`;

const Item = styled.li`
  width: 86px;
  height: 40px;

  &.active {
  }

  & + & {
    margin-left: 24px;

    @media (max-width: 500px) {
      margin-left: 0;
    }
  }

  @media (max-width: 500px) {
    margin-top: 16px;
  }
`;

const backgroundStyles = css`
  width: 100%;
  height: 100%;
  font-weight: 300;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: white;
  background: var(--color-dark-blue);
  border: 1px solid var(--color-gray);
  border-radius: 14px;
`;

const InputWrapper = styled.div`
  position: relative;
  padding: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-top: 10px;

  @media (max-width: 500px) {
  }
  @media (max-width: 500px) {
    &:nth-child(even) {
    }
  }
`;

const Input = styled.input`
  ::after {
    content: "%";
    color: black;
  }

  font-family: "Telegraf";
  font-weight: 600;
  height: 50px;
  width: 22%;
  padding: 20px;
  color: rgb(var(--color-black));
  border: 1px solid rgb(var(--color-overgrown));
  border-radius: 10px;
  text-align: center;
  outline: none;
  position: relative;

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

const ButtonMod = styled(Button)`
  margin-top: 40px;
  width: 100%;
  @media (max-width: 600px) {
    margin-top: 24px;
  }
`;
