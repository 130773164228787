import {
  memo,
  ForwardedRef,
  useCallback,
  useEffect,
  useId,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  ReactNode,
} from "react";

import {withColor} from "../-with-color";
import {Points, svgPath} from "../-utils/path";
import {useContainerSize} from "../-utils/container-size";
import {numberWithCommas} from "../../../utils";
import {Icon} from "../icon";

import * as SC from "./index.styles";
import {paddingBottom, paddingTop, getComputedValueChip} from "./index.styles";
import {useDragRange} from "./drag-range";

export interface ChartPriceRangeHandle {
  resetZoom: () => void;
  updateAndResetZoom: (state?: {rangeFrom?: number; rangeTo?: number}) => void;
}

interface ChartPriceRangeProps {
  data: Record<"amount" | "from" | "to", number>[];
  price?: number;
  rangeFrom?: number;
  onRangeFrom?: (n: number) => void;
  rangeTo?: number;
  onRangeTo?: (n: number) => void;
  disabled?: boolean;
  empty?: boolean;
}

const rangeInitialWidth = 0.3; // 0 to 0.5
const minRangeView = 0.05; // 0 to 0.5

const getMarkedNumber = (n: number, maxNumber: number) =>
  Object.assign(n !== Infinity ? n || 0 : maxNumber, {isInfinity: n === Infinity});
type MarkedNumber = ReturnType<typeof getMarkedNumber>;

export const ChartPriceRange = memo(
  withColor(function ChartPriceRangeComponent(
    props: ChartPriceRangeProps,
    palette,
    {ref}: {ref: ForwardedRef<ChartPriceRangeHandle>},
  ) {
    const {
      data = [],
      empty,
      rangeFrom: externalRangeFrom,
      rangeTo: externalRangeTo,
      onRangeFrom,
      onRangeTo,
      price: externalPrice,
    } = props;

    const randomId = useId();
    const getId = (name: string) => `range-${randomId}-${name}`;

    const containerRef = useRef<HTMLDivElement>(null);
    const chartPathRef = useRef<SVGPathElement>(null);
    const chartSvgRef = useRef<SVGSVGElement>(null);
    const chartPaddingsRef = useRef<SVGRectElement>(null);
    const fromDragRef = useRef<HTMLDivElement>(null);
    const toDragRef = useRef<HTMLDivElement>(null);
    const fromValueRef = useRef<HTMLDivElement>(null);
    const toValueRef = useRef<HTMLDivElement>(null);
    const rangeRef = useRef<HTMLDivElement>(null);
    const priceMarkRef = useRef<HTMLDivElement>(null);

    const {sizeRef, sizes} = useContainerSize(containerRef);

    const [XAxis, setXAxis] = useState<ReactNode>();

    // Values by reference
    const emptyN = undefined as number | undefined;
    const priceRef = useRef(externalPrice);
    const priceRangeRef = useRef({
      rangeFrom: externalRangeFrom,
      rangeTo: externalRangeTo,
      rangeToFree: externalRangeTo,
    });
    const viewRangeRef = useRef({viewFrom: emptyN, viewTo: emptyN, internaViewFrom: emptyN, internalViewTo: emptyN});
    const maxVisiblePriceRef = useRef(0);

    const getStatus = useCallback(() => {
      return {
        price: priceRef.current,
        ...priceRangeRef.current,
        ...viewRangeRef.current,
        ...sizeRef?.current,
      };
    }, [priceRef, priceRangeRef, viewRangeRef, sizeRef]);

    // Set view and price ranges
    const setViewRanges = useCallback(
      (ranges: Partial<Pick<(typeof viewRangeRef)["current"], "viewFrom" | "viewTo">> = {}) => {
        const {
          rangeFrom = 0,
          rangeTo = 0,
          price,
          internaViewFrom: currentViewFrom,
          internalViewTo: currentViewTo,
        } = getStatus();
        const {viewFrom: newViewFrom = currentViewFrom, viewTo: newViewTo = currentViewTo} = ranges;
        const minGapView = (rangeTo - rangeFrom) * minRangeView;
        const viewFrom =
          newViewFrom && Math.max(0, Math.min(newViewFrom, rangeFrom - minGapView, (price ?? Infinity) - minGapView));
        const viewTo = newViewTo && Math.max(newViewTo, rangeTo + minGapView, (price ?? -Infinity) + minGapView);
        Object.assign(viewRangeRef.current, {
          viewFrom,
          viewTo,
          internaViewFrom: newViewFrom,
          internalViewTo: newViewTo,
        });
      },
      [viewRangeRef, getStatus],
    );

    const setPriceRanges = useCallback(
      (ranges: Partial<Omit<(typeof priceRangeRef)["current"], "rangeToFree">> = {}, keepView = false) => {
        const {price, ...rest} = getStatus();
        const {rangeTo = rest.rangeTo!, rangeFrom = rest.rangeFrom!} = ranges;
        const maxVisibleNumber = Math.max(0, price! * 4, maxVisiblePriceRef.current * 1.2);
        const markedRangeTo = getMarkedNumber(rangeTo, maxVisibleNumber);
        Object.assign(priceRangeRef.current, {rangeFrom, rangeTo: markedRangeTo, rangeToFree: rangeTo});
        if (!keepView) setViewRanges();
      },
      [priceRangeRef, setViewRanges, getStatus],
    );

    // Position functions
    const getX = useCallback(
      (n: number | MarkedNumber) => {
        const {viewFrom, viewTo} = getStatus();
        if (viewFrom === undefined || viewTo === undefined) return undefined as never;
        if ((n as any).isInfinity) return 1;
        return (n - viewFrom) / (viewTo - viewFrom);
      },
      [getStatus],
    );

    const getValue = useCallback(
      (p: number) => {
        const {viewFrom, viewTo} = getStatus();
        if (viewFrom === undefined || viewTo === undefined) return undefined as never;
        return viewFrom + p * (viewTo - viewFrom);
      },
      [getStatus],
    );
    // Position functions

    // Drag and drop marks
    const setFromLimits = useDragRange<HTMLDivElement>({
      ref: fromDragRef,
      onChange: (position, end) => {
        setPriceRanges({rangeFrom: getValue(position!)}, true);
        updateAllPositions({end, skipPath: true});
      },
    });
    const setToLimits = useDragRange<HTMLDivElement>({
      ref: toDragRef,
      onChange: (position, end) => {
        setPriceRanges({rangeTo: getValue(position!)}, true);
        updateAllPositions({end, skipPath: true});
      },
    });

    // Chart Path
    const getPath = useCallback(() => {
      const {viewFrom, viewTo, width, height} = getStatus();
      if (viewFrom === undefined || viewTo === undefined) return {} as never;
      const max = data.reduce((acc, _) => Math.max(acc, _.amount), 0);
      const min = data.reduce((acc, _) => Math.min(acc, _.amount), 0);

      const padding = paddingTop + paddingBottom;
      const points = (JSON.parse(JSON.stringify(data)) as typeof data)
        .map((_) => {
          const y = paddingTop + (height - padding - ((_.amount - min) / (max - min)) * (height - padding));
          return [
            [Math.max(0, getX(_.from)) * width, y],
            [Math.min(1, getX(_.to)) * width, y],
          ] as Points;
        })
        .flat();

      const path = svgPath(points, 0);
      return `
        ${path}
        l 0 0
        L ${width} ${height}
        L 0 ${height}
      `;
    }, [...sizes, data, getStatus]);

    const updatePath = useCallback(() => {
      const {width, height} = getStatus();
      if (chartSvgRef.current) {
        chartSvgRef.current.setAttribute("viewBox", `0 0 ${width} ${height}`);
        chartSvgRef.current.setAttribute("width", String(width));
        chartSvgRef.current.setAttribute("height", String(height));
      }
      if (chartPathRef.current) {
        chartPathRef.current.setAttribute("d", getPath());
      }
      if (chartPaddingsRef.current) {
        chartPaddingsRef.current.setAttribute("width", String(width));
        chartPaddingsRef.current.setAttribute("height", String(height - paddingBottom));
      }
    }, [getPath]);

    useEffect(() => {
      updatePath();
      maxVisiblePriceRef.current = data.filter((_) => _.amount).reduce((acc, _) => Math.max(acc, _.to), 0);
    }, [data, getStatus]);

    // X Axis generation
    const updateXAxis = useCallback(() => {
      const {viewFrom, viewTo, width} = getStatus();
      if (viewFrom === undefined || viewTo === undefined) return;
      const maxAxis = Math.floor(width / 60);
      const gap = viewTo - viewFrom;
      const magnitude = Math.floor(Math.log10(+gap)) - 1;
      const scale = 10 ** magnitude;
      const scaleStep = [1, 2, 5, 10, 20, 50, 100].find((_) => gap / (scale * _) <= maxAxis) || 200;
      const step = scale * scaleStep;

      const initialValue = Math.floor(viewFrom / step) * step;
      const list = Array.from({length: maxAxis})
        .map((_, i) => initialValue + step * i)
        .filter((_) => !isNaN(_))
        .map((_, i) => (
          <SC.Xaxis key={i} style={{left: getX(+_) * 100 + "%"}}>
            {numberWithCommas(_, Math.max(0, -magnitude))}
          </SC.Xaxis>
        ));
      setXAxis(list);
    }, [...sizes, getStatus, setXAxis]);

    const getPriceGap = useCallback(() => {
      const {rangeFrom = 0, rangeTo = 0, rangeToFree, price} = getStatus();
      return {
        from: rangeFrom === 0 ? "0" : (-(1 - rangeFrom / price!) * 100).toFixed(2) + "%",
        to: rangeToFree === Infinity ? "∞" : (-(1 - rangeTo / price!) * 100).toFixed(2) + "%",
      };
    }, [getStatus]);

    const updateAllPositions = useCallback(
      (config: {end?: boolean; skipPath?: boolean} = {}) => {
        const {price, rangeFrom = price || 0, rangeTo = price || 0, viewFrom, viewTo} = getStatus();
        const {end, skipPath} = config;

        setFromLimits(getX(viewFrom!), getX(rangeTo!));
        setToLimits(getX(rangeFrom!), getX(viewTo!));

        if (!skipPath) {
          updatePath();
          updateXAxis();
        }

        if (end) {
          onRangeFrom?.(rangeFrom);
          if (rangeTo !== Infinity) {
            onRangeTo?.(rangeTo);
          }
        }
        if (fromDragRef.current) {
          fromDragRef.current.style.left = getX(rangeFrom) * 100 + "%";
        }
        if (priceMarkRef.current) {
          priceMarkRef.current.style.left = price === undefined ? "-100%" : getX(price) * 100 + "%";
        }
        if (toDragRef.current) {
          toDragRef.current.style.left = getX(rangeTo) * 100 + "%";
        }
        if (rangeRef.current) {
          rangeRef.current.style.left = getX(rangeFrom) * 100 + "%";
          rangeRef.current.style.right = (1 - getX(rangeTo)) * 100 + "%";
        }
        if (fromValueRef.current && toValueRef.current) {
          Object.assign(fromValueRef.current.style, getComputedValueChip({left: getX(rangeFrom)}));
          Object.assign(toValueRef.current.style, getComputedValueChip({left: getX(rangeTo), right: true}));
          const currentPriceGap = getPriceGap();
          fromValueRef.current.innerText = String(currentPriceGap.from);
          toValueRef.current.innerText = String(currentPriceGap.to);
        }
      },
      [
        getX,
        getStatus,
        updatePath,
        getPriceGap,
        priceRangeRef,
        fromDragRef,
        toDragRef,
        rangeRef,
        fromValueRef,
        toValueRef,
        priceMarkRef,
      ],
    );

    // Set initial view range
    const resetZoom = useCallback(() => {
      const {rangeFrom = 0, rangeTo = 0} = getStatus();

      const halfGap = (rangeTo - rangeFrom) / 2;
      const middle = rangeFrom + halfGap;
      const side = (halfGap / rangeInitialWidth) * 0.5;

      const newView = {
        viewFrom: Math.max(0, middle - side),
        viewTo: middle + side,
      };
      setViewRanges(newView);
      updatePath();
      return newView;
    }, [getStatus, updatePath]);

    useEffect(() => {
      const {rangeFrom, rangeTo} = getStatus();
      if (
        (viewRangeRef.current?.viewFrom === undefined || viewRangeRef.current?.viewTo === undefined) &&
        rangeFrom &&
        rangeTo
      ) {
        resetZoom();
      }
    }, [viewRangeRef, getStatus, resetZoom]);
    // End initial view range

    // Handlers to manage interaction from outside
    useImperativeHandle(ref, () => ({
      resetZoom() {
        resetZoom();
        updateAllPositions();
      },
      updateAndResetZoom(state) {
        setPriceRanges(state);
        resetZoom();
        updateAllPositions();
      },
    }));

    // Reset realtime styles after a external change
    useEffect(() => {
      setPriceRanges({rangeFrom: externalRangeFrom, rangeTo: externalRangeTo});
      priceRef.current = externalPrice;
      updateAllPositions();
    }, [externalRangeFrom, externalRangeTo, externalPrice, updateAllPositions]);

    // Zoom on click icons
    const zoom = useCallback(
      (out = false) => {
        const {viewFrom, viewTo, rangeFrom = 0, rangeTo = 0} = getStatus();
        if (viewFrom === undefined || viewTo === undefined) return;
        const step = 0.2;
        let ranges: {viewFrom: number; viewTo: number};
        if (out) {
          const apply = (viewTo - viewFrom) * (1 - 1 / (1 + step));
          ranges = {
            viewFrom: viewFrom + apply,
            viewTo: viewTo - apply,
          };
        } else {
          const apply = (viewTo - viewFrom) * step;
          ranges = {
            viewFrom: viewFrom - apply,
            viewTo: viewTo + apply,
          };
        }
        ranges = {
          viewFrom: Math.max(0, Math.min(ranges.viewFrom!, rangeFrom)),
          viewTo: Math.max(ranges.viewTo!, rangeTo),
        };
        setViewRanges(ranges);
        updateAllPositions();
      },
      [setViewRanges, getStatus, updateAllPositions],
    );

    const priceGap = useMemo(() => {
      return getPriceGap();
    }, [getPriceGap]);

    return (
      <SC.Container empty={empty}>
        <SC.Wrapper ref={containerRef}>
          <svg ref={chartSvgRef}>
            <SC.AreaPath ref={chartPathRef} mask={`url(#${getId("paddings")})`} />
            <mask id={getId("paddings")}>
              <rect ref={chartPaddingsRef} fill="white" />
            </mask>
          </svg>
          <SC.Overlay>
            <SC.Range ref={rangeRef} />
            <SC.Mark ref={priceMarkRef} price />
            <SC.Mark ref={fromDragRef}>
              <Icon icon="flake" size="xs" />
            </SC.Mark>
            <SC.Mark ref={toDragRef} rotate>
              <Icon icon="flake" size="xs" />
            </SC.Mark>
            <SC.ValueChip ref={fromValueRef}>{priceGap.from}</SC.ValueChip>
            <SC.ValueChip ref={toValueRef} right>
              {priceGap.to}
            </SC.ValueChip>
            <SC.Controls>
              <Icon button size="s" icon="plus" onClick={() => zoom(true)} />
              <Icon button size="s" icon="minus" onClick={() => zoom()} />
            </SC.Controls>
          </SC.Overlay>
          <SC.XaxisContainer>
            <SC.XaxisContainerSize>{XAxis}</SC.XaxisContainerSize>
          </SC.XaxisContainer>
        </SC.Wrapper>
      </SC.Container>
    );
  }),
);
