import {useQuery} from "@tanstack/react-query";
import { useMemo } from "react";
import {fetchGraphQL} from "../../../utils/gql-utils";
import {getGraphQLUrl} from "../../../web3/apollo/client";
import { AnalyticsGlobalData } from "./global";
import { cleanDataV3, transformPairsV3 } from "./pairs.utils";

const poolSnippet = `
  id,
  a: token0 {
    s: symbol
  }
  b: token1 {
    s: symbol
  }
  v: volumeUSD
  l: totalValueLockedUSD
  feeTier
`;

export const useGetPairsV3Data = (enabled?: boolean, globalData?: AnalyticsGlobalData, full?: boolean, filter?: string, extraFilter?: string, extraSnippet?: string) => {
  const query = useQuery({
    queryKey: ["pairs-v3", full, filter || '', extraFilter || ''],
    queryFn: async () => {
      const url = getGraphQLUrl(true);
      const data =  await fetchGraphQL(
        url,
        `
        query {
          pools (first: ${full ? 100 : 20}, orderBy: totalValueLockedUSD, orderDirection: desc${filter ? ', ' + filter : ''}) {
            ${poolSnippet}
            ${extraSnippet || ''}
          }
          extra: pools (first: ${full ? 100 : 20}, orderBy: totalValueLockedUSD, orderDirection: desc${extraFilter ? ', ' + extraFilter : ', where: {id: ""}'}) {
            ${poolSnippet}
            ${extraSnippet || ''}
          }
        }
      `,
      )
        .then(({data}) => ({data: {pools: data.pools.concat(data.extra)}}))
        .then(cleanDataV3);

      const dayBefore = await fetchGraphQL(
        url,
        `
          query {
            pools (where: {id_in: ${JSON.stringify(data.map(_ => _.id))}}, block: {number: ${globalData?.blocks.day}}) {
              ${poolSnippet}
            }
          }
      `,
      ).then(cleanDataV3);

      const refs = {};
      data.forEach((_) => (refs[_.id] = _));
      dayBefore.forEach((_) => (refs[_.id].oneDayBefore = _));
      return data;
    },
    enabled: !!globalData?.blocks?.day && enabled,
    refetchOnWindowFocus: false,
  });

  const data = useMemo(() => transformPairsV3(globalData, query.data) || [], [globalData, query.data]);

  return {
    ...query,
    pairs: data || [],
  };
};
