import {Link} from "react-router-dom";
import styled from "styled-components";
import {transition} from "../../../styles";
import {br, card, flex, microPalette, palette, size} from "../../../ui/styles";

export const Wrapper = styled.div`
  position: fixed;
  bottom: ${size(3)};
  right: ${size(4)};
  z-index: 11;
`;

export const Circle = styled.div<{open?: boolean}>`
  ${transition.fast}
  ${card("max")}
  ${flex("center", "center")}

  width: 60px;
  height: 60px;
  cursor: pointer;

  ${(_) =>
    !_.open &&
    `
    ${card("max", false, "transparent")}

    background: ${microPalette.c200};
    color: ${microPalette.c300};
  `}

  & > svg {
    position: absolute;
    top: -1px;
    left: -1px;
    min-width: 62px;
    min-height: 62px;
    max-width: 62px;
    max-height: 62px;
    transform: scaleX(-1) rotate(-90deg);

    circle {
      ${transition.fast}

      stroke: ${(_) => (_.open ? "transparent" : microPalette.c300)};
      stroke-dasharray: 189px;
    }
  }
`;

export const Card = styled.div<{open?: boolean}>`
  ${transition.fast}
  ${card("xl")}
  ${size.padding(6)};

  position: absolute;
  bottom: 100%;
  right: 0;
  margin-bottom: ${size(4)};
  width: max-content;
  overflow: hidden;

  ${(_) =>
    !_.open &&
    `
    opacity: 0;
    pointer-events: none;
  `}
`;

export const Line = styled.div`
  height: 1px;
  background: ${palette.gray100};
  margin: ${size(4)} ${size(-6)} 0 ${size(-6)};
`;

export const Content = styled.div<{open?: boolean}>`
  ${transition.slow}

  ${(_) =>
    !_.open &&
    `
    margin-right: -346px;
    margin-bottom: -388px;
  `}
`;

export const ItemContent = styled.div`
  ${size.gap(1)};

  display: flex;
  flex-direction: column;
`;

const itemHeight = 38;
export const BulletLine = styled.div`
  ${br.max}

  width: 2px;
  height: ${size(5)};
  background: ${palette.gray50};
  position: absolute;
  top: ${size.raw(5) + itemHeight}px;
  left: ${size.raw(6 + 3) - 1}px;
`;

export const Item = styled(Link)<{complete?: boolean; active?: boolean}>`
  ${transition.fast};
  ${size.gap(4)};
  ${size.padding(5, 6, 0, 6)};
  ${size.margin(0, -6)};
  ${flex("center")}

  cursor: pointer;
  position: relative;

  ${(_) =>
    _.complete &&
    `
    pointer-events: none;

    ${ItemContent} > *:first-child {
      color: ${microPalette.c300};
      text-decoration: line-through;
      text-decoration-thickness: 2px;
    }

    ${BulletLine} {
      background: ${microPalette.c200};
    }
  `}

  &:last-of-type ${BulletLine} {
    display: none;
  }
`;
