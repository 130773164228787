export const Logo = ({icon, brand, ...rest}: {icon?: boolean; brand?: boolean} & JSX.IntrinsicElements["svg"]) => {
  // Brand color: {color: "#102A08"};
  if (icon) {
    return (
      <svg width="30" height="31" viewBox="5 6 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path
          d="M5 11C7.76124 11 10 8.76123 10 6C10 8.76123 12.2388 11 15 11C15 13.7612 12.7613 16 10 16C7.23878 16 5 13.7612 5 11Z"
          fill="currentColor"
        ></path>
        <path
          d="M20 6C20 8.76123 22.2388 11 25 11C22.2388 11 20 13.2388 20 16C17.2388 16 15 13.7612 15 11C15 8.23877 17.2388 6 20 6Z"
          fill="currentColor"
        ></path>
        <path
          d="M25 21C22.2388 21 20 23.2388 20 26C20 23.2388 17.7612 21 15 21C15 18.2388 17.2388 16 20 16C22.7613 16 25 18.2388 25 21Z"
          fill="currentColor"
        ></path>
        <path
          d="M10 26C10 23.2388 7.76124 21 5 21C7.76124 21 10 18.7612 10 16C12.7613 16 15 18.2388 15 21C15 23.7612 12.7613 26 10 26Z"
          fill="currentColor"
        ></path>
      </svg>
    );
  }
  return (
    <svg width="100" height="34" viewBox="0 0 100 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M0 8.94084C4.44734 8.94084 8.05318 5.33195 8.05318 0.880859C8.05318 5.33195 11.659 8.94084 16.1064 8.94084C16.1064 13.3919 12.5005 17.0008 8.05318 17.0008C3.60584 17.0008 0 13.3919 0 8.94084Z"
        fill="currentColor"
      />
      <path
        d="M24.1596 0.880859C24.1596 5.33195 27.7655 8.94084 32.2128 8.94084C27.7655 8.94084 24.1596 12.5497 24.1596 17.0008C19.7123 17.0008 16.1064 13.3919 16.1064 8.94084C16.1064 4.48975 19.7123 0.880859 24.1596 0.880859Z"
        fill="currentColor"
      />
      <path
        d="M32.2128 25.06C27.7655 25.06 24.1596 28.6689 24.1596 33.12C24.1596 28.6689 20.5538 25.06 16.1064 25.06C16.1064 20.6089 19.7123 17 24.1596 17C28.607 17 32.2128 20.6089 32.2128 25.06Z"
        fill="currentColor"
      />
      <path
        d="M8.05318 33.12C8.05318 28.6689 4.44734 25.06 0 25.06C4.44734 25.06 8.05318 21.4511 8.05318 17C12.5005 17 16.1064 20.6089 16.1064 25.06C16.1064 29.5111 12.5005 33.12 8.05318 33.12Z"
        fill="currentColor"
      />
      <path
        d="M99.9995 9.17532H95.5767V24.8595H99.9995V28.3297H86.833V24.8595H91.2559V9.17532H86.833V5.70508H99.9995V9.17532Z"
        fill="currentColor"
      />
      <path
        d="M72.8618 28.9072C64.4924 28.9072 61.1582 23.4637 61.1582 16.9995C61.1582 10.6374 64.4924 5.0918 72.8618 5.0918C81.2312 5.0918 84.5654 10.6714 84.5654 16.9995C84.5654 23.4297 81.2312 28.9072 72.8618 28.9072ZM65.6831 16.9995C65.6831 22.443 67.5884 25.3349 72.8618 25.3349C78.1352 25.3349 80.0064 22.443 80.0064 16.9995C80.0064 11.556 78.1352 8.66411 72.8618 8.66411C67.5884 8.66411 65.6831 11.556 65.6831 16.9995Z"
        fill="currentColor"
      />
      <path
        d="M52.4444 15.1032L61.6303 27.9635V28.1676H56.3909L49.5185 18.2332L45.64 22.4179V28.1676H41.2852V5.54297H45.64V16.8723L55.6424 5.54297H61.2901L52.4444 15.1032Z"
        fill="currentColor"
      />
    </svg>
  );
};
