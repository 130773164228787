import {PropsWithChildren} from "react";
import styled from "styled-components";
import { useCore } from "../../../core";

import {br, card, flex, microPalette, palette, size, text, transition} from "../../styles";
import {Divider} from "../divider";
import {Loading} from "../loading";

type ButtonProps = {
  secondary?: boolean;
  alt?: boolean;
  transparent?: boolean;
  disabled?: boolean;
  full?: boolean;
  newTab?: boolean;
  size?: "m" | "s" | "xs";
  loading?: boolean;
  checkNetwork?: boolean;
} & (({link: string} & JSX.IntrinsicElements["a"]) | ({link?: undefined} & JSX.IntrinsicElements["button"]));

export const Button = styled(({...props}: PropsWithChildren<ButtonProps>) => {
  const {switchNetwork} = useCore();
  if (props.checkNetwork && !switchNetwork.correctNetwork) {
    props.children = (
      <>
        Switch Network
      </>
    );
    props.disabled = false;
    props.onClick = switchNetwork.switchNetwork;
    props.loading = switchNetwork.switching;
  }
  if (props.loading) {
    props.children = (
      <>
        <Loading currentColor />
        <Divider size={2} horizontal />
        {props.children}
      </>
    );
  }
  return props.link ? (
    <a {...(props as any)} href={props.link} target={props.newTab ? "_blank" : undefined} />
  ) : (
    <button {...(props as any)} />
  );
})<ButtonProps>`
  ${transition.fast}
  ${text.h6}
  ${flex("center", "center")}
  ${size.padding(2, 6)}
  ${br.s}

  display: inline-flex;
  font-size: 18px;
  line-height: 1em;
  background: ${microPalette.c500};
  color: ${microPalette.c200};
  cursor: pointer;
  min-height: 54px;

  &:hover {
    background: ${microPalette.c400};
  }
  &:active {
    background: ${microPalette.c300};
  }
  &:disabled {
    background: ${palette.gray300};
    pointer-events: none;
    color: ${palette.white};
  }

  ${(_) =>
    _.secondary &&
    `
    ${card("s")}

    color: ${palette.black};

    &:hover {
      ${card("s", false, palette.gray300)}

      color: ${microPalette.c400};
    }
    &:active {
      ${card("s", false, palette.gray200)}

      background: ${palette.gray50};
      color: ${microPalette.c300};
    }

    &:disabled {
      background: ${palette.white};
      color: ${palette.gray400};
    }
  `}

  ${(_) =>
    _.transparent &&
    `
    color: ${microPalette.c400};
    background: ${palette.white};
    border: 0;

    &:hover {
      background: ${palette.white.o(0.7)};
    }
    &:active {
      background: ${palette.white.o(0.7)};
      color: ${microPalette.c300};
    }

    &:disabled {
      background: ${palette.white};
      color: ${palette.gray300};
    }
  `}

  ${(_) =>
    _.alt &&
    `
    color: ${microPalette.c100};
    background: ${microPalette.c300};

    &:hover {
      background: ${microPalette.c400};
    }
    &:active {
      background: ${microPalette.c500};
    }
  `}

  ${(_) =>
    _.size === "s" &&
    `
    ${size.padding(3, 6)}
    ${text.h7}
    
    min-height: 40px;
  `}

  ${(_) =>
    _.size === "xs" &&
    `
    ${size.padding(0, 4)}
    ${text.h7}
    ${br.xs}

    min-height: 32px;
  `}

  ${(_) =>
    _.full &&
    `
    width: 100%;
  `}
`;
