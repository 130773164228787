import {useCallback, useMemo, useState} from "react";

export function useLocalStorage<T>(key: string, defaultValue?: T) {
  if (typeof localStorage === "undefined") {
    return [undefined, () => {}, () => {}] as never;
  }
  const getValue = () => {
    try {
      return JSON.parse(localStorage.getItem(key)!);
    } catch {
      return defaultValue;
    }
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [data, setData] = useState<T | typeof defaultValue>(getValue() || (defaultValue as T | undefined));

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const setSetting = useCallback(
    (value?: T) => {
      if (value !== undefined) {
        localStorage.setItem(key, JSON.stringify(value));
      } else {
        localStorage.removeItem(key);
      }
      setData(value || defaultValue);
    },
    [setData, defaultValue],
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(() => [data, setSetting, getValue] as const, [data, setSetting]);
}

export function useLocalStorageSettings<T>(setting: string, defaultValue?: T) {
  return useLocalStorage<T>(`koi-${setting}`, defaultValue);
}
