import {memo} from "react";

import * as SC from "./index.styles";
import {Text} from "../text";
import {Web3Connection} from "../+web3-connection";
import {useAccount} from "wagmi";

interface EmptyProps {
  title: string;
  subtitle?: string;
  card?: boolean;
  showWallet?: boolean;
}

export const Empty = memo(function EmptyComponent(props: EmptyProps) {
  const {title, subtitle, card, showWallet} = props;

  const account = useAccount();

  return (
    <SC.Wrapper card={card}>
      <SC.Content card={card}>
        <Text h6>{title}</Text>
        {subtitle && <Text bodyRegular>{subtitle}</Text>}
      </SC.Content>
      {showWallet && !account.isConnected && <Web3Connection onlyWallet />}
    </SC.Wrapper>
  );
});
