import {memo, PropsWithChildren, ReactNode, useEffect, useState} from "react";
import {createPortal} from "react-dom";
import {Divider} from "../divider";
import {Icon} from "../icon";
import {Text} from "../text";

import * as SC from "./index.styles";

export interface ModalProps {
  isVisible: boolean;
  onClose: (open: false) => void;
  className?: string;
  title?: string;
  subtitle?: ReactNode;
  maxWidth?: number;
  scroll?: boolean;
}

const ModalComp = memo(function ModalComponent(props: PropsWithChildren<ModalProps>) {
  const {isVisible: open, onClose, children, title, subtitle, maxWidth, scroll, ...rest} = props;
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (open) {
      const listenKeyboard = (event: KeyboardEvent) => {
        if (event.key === "Escape" || event.keyCode === 27) {
          onClose?.(false);
        }
      };
      window.addEventListener("keydown", listenKeyboard, true);
      return () => {
        window.removeEventListener("keydown", listenKeyboard, true);
      };
    }
  }, [open]);

  if (!isMounted) return null;

  return createPortal(
    <SC.Overlay onClick={() => onClose?.(false)} open={open}>
      <SC.Card onClick={(e) => e.stopPropagation()} maxWidth={maxWidth} open={open}>
        <SC.Close onClick={() => onClose?.(false)}>
          <Icon icon="close" size="s" />
        </SC.Close>
        <Text h4>{title}</Text>
        {subtitle && (
          <>
            <Divider size="2" />
            <Text bodyRegular color="gray400">
              {subtitle}
            </Text>
          </>
        )}
        <Divider size="8" />
        <SC.Content scroll={scroll}>{children}</SC.Content>
      </SC.Card>
    </SC.Overlay>,
    document.getElementById("overlay-wrapper")!,
  );
});

export const Modal = Object.assign(ModalComp, {Rows: SC.Rows, Row: SC.Row});
