import React, {useState} from "react";
import styled from "styled-components";
import {Dropdown} from "./Dropdown";
import BigNumber from "bignumber.js";
import {useAccount} from "wagmi";
import {formatNumber} from "../../utils/formatNumber";
import {Card, Empty} from "../../ui/components";
import {size} from "../../ui/styles";

enum Modal {
  Switch,
  Connect,
  Confirm,
  Submitted,
}

/*
type LiquidityPair = {
  value: string;
  assetAImage: string;
  assetBImage: string;
  assetA: any;
  assetB: any;
}

interface YourLiquidityWidgetProps {
  ownedPairs: LiquidityPair[];
  addLiquidity: (LiquidityPair) => any;
  removeLiquidity: (LiquidityPair) => any;
}
*/

export const YourLiquidityWidget = ({title, manageLiquidity, ownedPairs, isLoading}) => {
  const {address, isConnected} = useAccount();

  const [modal, setModal] = useState<Modal | null>(null);
  const closeModal = () => setModal(null);

  const sortedPairs: any = React.useMemo(() => {
    return Object.values(ownedPairs).sort((a: any, b: any) => {
      var aDis =
        a.payoutToken?.symbol == "MUTE" && (a.user_stakes ? a.user_stakes.length : 0)
          ? 0
          : new BigNumber(a.tbv).div(a.maxTBV).times(100).toNumber();
      var bDis =
        b.payoutToken?.symbol == "MUTE" && (b.user_stakes ? b.user_stakes.length : 0)
          ? 0
          : new BigNumber(b.tbv).div(b.maxTBV).times(100).toNumber();

      return bDis - aDis;
    });
  }, [ownedPairs]);

  const getWidget = () => {
    if (isConnected == false) {
      return <></>;
    }

    if (isLoading) {
      const loadingStyle = {
        width: "100%",
        borderRadius: 24,
        maxWidth: "calc((100% / 3) - (16px * 2 / 3))",
      };
      return (
        <>
          <Card loading height={322} style={loadingStyle} />
          <Card loading height={322} style={loadingStyle} />
          <Card loading height={322} style={loadingStyle} />
        </>
      );
    }

    return (
      <>
        {ownedPairs.map((item, index) => (
          <Dropdown
            onManageClick={() => manageLiquidity(item.id, item.v2)}
            icon0={item.token0 && item.token0.logo ? item.token0.logo : ""}
            icon1={item.token0 && item.token1.logo ? item.token1.logo : ""}
            token0={item.token0.symbol}
            token1={item.token1.symbol}
            apy={new BigNumber(item.apy ? item.apy : item.maxAPY)
              .div(100)
              .times(item.ampBoost ? item.ampBoost : 1)
              .plus(item.lp_apy ? item.lp_apy : item.lpAPY)
              .toFixed(2)}
            tvl={item.tvl}
            stable={item.stable}
            tbv={formatNumber(item.maxTBV, 2)}
            percent={new BigNumber(item.tbv).div(item.maxTBV).times(100).toNumber()}
            soldout={new BigNumber(item.tbv).div(item.maxTBV).times(100).lte(0.01)}
            v2={item.v2}
            stakes={item.user_stakes ? item.user_stakes.length : 0}
            payoutToken={item.payoutToken}
          />
        ))}

        {!ownedPairs.length && <Empty title="No farms yet" card />}
      </>
    );
  };

  return (
    <Container>
      {!isConnected && (
        <Empty
          title="Connect your wallet"
          subtitle="Connect your web3 wallet and check your active liquidity pool positions."
          card
          showWallet
        />
      )}

      <OverflowWidget>{getWidget()}</OverflowWidget>
    </Container>
  );
};

const Container = styled.div``;

const OverflowWidget = styled.div`
  display: flex;
  gap: ${size(3)};
  flex-wrap: wrap;
`;

const ConnectionWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 50%;
  font-size: 16px;
  justify-content: center;
  display: flex;
`;
