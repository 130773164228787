import {useQuery} from "@tanstack/react-query";
import {fetchGraphQL} from "../../../utils/gql-utils";
import {getGraphQLUrl} from "../../../web3/apollo/client";
import { mapTxType, txFragment, txFragmentV3 } from "./transactions.utils";

export const useGetTransactionsFilteredV3Data = (enabled?: boolean, pairs?: string[]) => {
  const query = useQuery({
    queryKey: ["transactions-filtered-v3", pairs?.join(',')],
    queryFn: async () => {
      const url = getGraphQLUrl(true);
      const common = "orderBy: timestamp, orderDirection: desc, first: 20"
      return await fetchGraphQL(
        url,
        `
        query ($allPairs: [Bytes]!) {
          mints(${common}, where: { pool_in: $allPairs }) {
            ${txFragmentV3('mint')}
          }
          burns(${common}, where: { pool_in: $allPairs }) {
            ${txFragmentV3('burn')}
          }
          swaps(${common}, where: { pool_in: $allPairs }) {
            ${txFragmentV3('swap')}
          }
        }
      `,
        {allPairs: pairs}
      ).then(({data}: {data: Record<string, any[]>}) => {
        const {mints, burns, swaps} = data;
        return [...mapTxType("mints", mints), ...mapTxType("burns", burns), ...mapTxType("swaps", swaps)]
          .map((_, i) => ({..._, index: i}))
          .flat()
      }).catch(e => {
        console.log(e)
      });
    },
    enabled,
    refetchOnWindowFocus: false,
  });

  return {
    ...query,
    txs: query.data || [],
  };
};
