import {Fragment, memo, PropsWithChildren, useState} from "react";
import {AssetsListModal} from "../../../common/modal/AssetsListModal";
import { TokenInfo } from "@across-protocol/app-sdk";
import { Asset } from "../../../model/Asset";
import {Flex} from "../flex";
import {Icon, IconAsset} from "../icon/index";
import {Text} from "../text";
import styled from "styled-components";
import { ListItem } from "../../../common/ListItem";

import * as SC from "./index.styles";
import { Modal } from "../modal";

interface AssetSelectorAcrossProps {
  asset?: TokenInfo | undefined;
  setAsset?: (asset: TokenInfo) => void;
  assets?: TokenInfo[];
  disabled?: boolean;
  large?: boolean;
}

export const AssetSelectorAcross = memo(function AssetSelectorComponent(props: PropsWithChildren<AssetSelectorAcrossProps>) {
  const {asset, assets = [], setAsset = () => {}, disabled, large} = props;
  const [open, setOpen] = useState(false);

  function handleTokenChange(token: TokenInfo) {
    if(setAsset)
      setAsset(token);
    setOpen(false)
  }

  return (
    <>
      <SC.Wrapper onClick={() => setOpen(true)} disabled={disabled} large={large}>
        {asset ? (
          
            <>
              <IconAsset size="xs" asset={{...asset, logo: asset.logoUrl, address: '', amount: "", value: '', balance: '', price: ''} as Asset} contrast />
              <span>{asset?.symbol}</span>
            </>
          
        ) : (
          <>
            <Text bodyRegular color="gray400">
              Select asset
            </Text>
          </>
        )}
        {!disabled ? (
          <>
            <Flex.Grow />
            <Icon icon="arrow" size="s" color="c300" />
          </>
        ) : null}
      </SC.Wrapper>

      <StyledModal title="Select token" isVisible={open} onClose={() => {setOpen(false)}}>
            <Divider>
              <RowNames>Asset</RowNames>
              <RowNames>Balance</RowNames>
            </Divider>

            <AssetsList>
            {assets && assets.map((token) => (
                    <ListItem key={token.symbol} onClick={() => handleTokenChange(token)}>
                      <Flex style={{width: "100%"}}>
                        <SymbolWithLogo logo={token.logoUrl} symbol={token.symbol} name={token.name} />
                      </Flex>
                    </ListItem>
              ))}
            </AssetsList>
        </StyledModal>
    </>
  );
});


const StyledModal = styled(Modal)`
  left: calc(50% - 200px);
  top: 15%;

  overflow-y: hidden;
  height: 70%;
  text-align: left;
  justify-content: flex-start;

  @media (max-width: 500px) {
    width: 100%;
    left: 0;
    top: initial;
  }
`;


const AssetsList = styled.ul`
  margin-top: 16px;
  max-height: 375px;
  min-height: 375px;

  overflow-y: scroll;
`;

const Divider = styled.div`
  position: relative;
  flex-direction: row;
  display: flex;
  justify-content: space-between;

  width: 100%;
`;

const RowNames = styled.div`
  font-size: 14px;
  color: var(--color-gray);
`;


interface SymbolWithLogoProps {
  logo: string;
  symbol: string;
  name?: string;
}

export const SymbolWithLogo = ({logo, symbol, name}: SymbolWithLogoProps) => {
  if (logo && logo != "") {
    return (
      <Row>
        <Icon button size="s" imageFallback={logo} />
        <Column>
          <Text h6>{symbol}</Text>
          <Text bodyRegular color="gray400">
            {name}
          </Text>
        </Column>
      </Row>
    );
  } else {
    return <Row />;
  }
};

const Row = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
`;

const Column = styled.div`
  flex-direction: column;
  display: flex;
  text-align: left;
  margin-left: 10px;
`;
