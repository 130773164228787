import BigNumber from "bignumber.js";

/**
 * format ether from wei
 * @param wei The value
 */
export function formatEther(
  // tslint:disable-next-line: no-any
  wei: any,
): BigNumber {
  return new BigNumber(wei);
}
