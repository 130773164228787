import styled from "styled-components";
import {Icon} from "../ui/components";
import {Text} from "../ui/components/text/index";

interface SymbolWithLogoProps {
  logo: string;
  symbol: string;
  name?: string;
  text?: string;
}

export const SymbolWithLogoText = ({logo, symbol, name, text}: SymbolWithLogoProps) => {
  if (logo && logo != "") {
    return (
      <Row>
        <Icon button size="s" imageFallback={logo} />
        <Column>
          <Text h6 style={{display: "flex"}}>
            {symbol} {text != "" ? <FeeDiscount>{text}%</FeeDiscount> : <></>}
          </Text>
          <Text bodyRegular color="gray400">
            {name}
          </Text>
        </Column>
      </Row>
    );
  } else {
    return <Row />;
  }
};

const FeeDiscount = styled(Text)`
  color: rgb(var(--color-green));
  border: 1px solid rgb(var(--color-gray50));
  background: rgb(var(--color-gray50));
  border-radius: 6px;
  padding: 7px;
  margin-left: 15px;
  height: 23px;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 60px;
  line-height: 55%;

  font-weight: 400;
  font-size: 14px;

  margin-top: -5px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  max-width: 40%;
`;

const Column = styled.div`
  flex-direction: column;
  display: flex;
  text-align: left;
  margin-left: 10px;
`;
