import {useState, useEffect, useMemo} from "react";
import styled from "styled-components";
import {Asset} from "../../model/Asset";
import {ListItem} from "../ListItem";
import {SymbolWithLogo} from "../SymbolWithLogo";
import UnknownToken from "../../assets/images/icons/unknown_token.png";
import {Icon, Search} from "../../ui/components";

import {Text} from "../../ui/components";

import {Modal, ModalProps} from "../../ui/components/modal";

import {ButtonPrimaryMedium} from "./../buttons/Button";
import BigNumber from "bignumber.js";

import {useWalletHook} from "../../web3/walletHook";
import {formatPrice} from "../../utils";

interface AssetsListModalProps extends ModalProps {
  assets: {};
  setSelectedAsset: (asset: Asset) => void;
}

export const AssetsListModal = ({isVisible, onClose, assets, setSelectedAsset}: AssetsListModalProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [addedAssets, setAddedAssets] = useState<Record<string, Asset>>(JSON.parse(JSON.stringify(assets)));
  const {addTokenToStorage, getTokenInformation, GetPinnedTokens} = useWalletHook();

  const showAmount = 999;

  useEffect(() => {
    const copy = JSON.parse(JSON.stringify(assets));
    if (Array.isArray(assets)) {
      setAddedAssets(assets.reduce((acc, _) => ({...acc, [_.address?.toLowerCase() as any]: _}), {}));
    } else {
      setAddedAssets(copy);
    }
  }, [assets]);

  useEffect(() => {
    if (searchValue != "")
      searchForAsset(searchValue)
        .then((res) => {
          if (res.success == true) {
            var asset = {
              logo: UnknownToken,
              symbol: res.symbol,
              name: res.name,
              amount: res.balance,
              address: res.address,
              active: false,
              price: "0.00",
              decimals: Number(res.decimals),
              value: "0",
            };

            let hasDuplicates = false;
            for (let i in addedAssets) {
              if (addedAssets[i].address!.toLowerCase() == asset.address.toLowerCase()) hasDuplicates = true;
            }

            let _addedAssets = JSON.parse(JSON.stringify(addedAssets));
            if (hasDuplicates == false) {
              _addedAssets[asset.address.toLowerCase()] = asset;
              setAddedAssets(_addedAssets);
            }
          }
        })
        .catch((e) => {});

    return () => {};
  }, [searchValue]);

  const onAssetClick = (asset: Asset) => {
    setSelectedAsset(asset);
    onClose(false);
  };

  const onAssetAdd = (asset: Asset) => {
    addTokenToStorage(asset);
    setSelectedAsset(asset);
    onClose(false);
  };

  const searchForAsset = async (contract) => {
    var info = await getTokenInformation(contract);
    return info;
  };

  const filterAssets = (address, symbol, name, searchValue) => {
    return (
      !address?.toLowerCase().includes(searchValue.toLowerCase()) &&
      !symbol.toLowerCase().includes(searchValue.toLowerCase()) &&
      !name.toLowerCase().includes(searchValue.toLowerCase())
    );
  };

  const getPinnedAssets = () => {
    if (addedAssets) {
      let pinned = GetPinnedTokens();

      return (
        <>
          {pinned
            .filter((pin) => addedAssets[pin.toLowerCase()])
            .map((pin) => (
              <PinnedAsset key={pin} onClick={() => onAssetClick(addedAssets[pin.toLowerCase()])}>
                <Icon size="s" contrast imageFallback={addedAssets[pin.toLowerCase()].logo} />
              </PinnedAsset>
            ))}
        </>
      );
    } else {
      return <></>;
    }
  };

  const list = useMemo(() => {
    return Object.entries(addedAssets)
      .map(([key, asset]) => ({...asset, balanceUsd: +new BigNumber(asset.balance).times(asset.price).toFixed(2)}))
      .filter((asset, i) => {
        var {logo, symbol, name, balance, address, active, price} = asset!;
        if (searchValue != "") {
          if (filterAssets(address, symbol, name, searchValue)) {
            return;
          }
        } else if (active == false && Number(balance) == 0 && showAmount < i) {
          return;
        }
        return true;
      })
      .sort(({balanceUsd: a}, {balanceUsd: b}) => b - a);
  }, [addedAssets, searchValue, filterAssets]);

  return (
    <StyledModal title="Select asset" isVisible={isVisible} onClose={onClose}>
      <Search value={searchValue} onChange={(event) => setSearchValue(event.target.value)} placeholder="Search" />
      <PinnedGrid>{getPinnedAssets()}</PinnedGrid>
      <Divider>
        <RowNames>Asset</RowNames>
        <RowNames>Balance</RowNames>
      </Divider>

      <AssetsList>
        {list.map((asset, i) => {
          const {logo, symbol, name, balance, address, active, price, balanceUsd} = asset!;
          return (
            <>
              <ListItem key={address} onClick={() => onAssetClick(asset)}>
                <SymbolWithLogo logo={logo} symbol={symbol} name={name} />
                {active ? (
                  <AssetValues>
                    <Text h6>{formatPrice(balance, true, 2, 2)}</Text>
                    {!new BigNumber(balanceUsd).isNaN() && <Text bodyRegular color="gray400">
                      {"$" + balanceUsd}
                    </Text>}
                  </AssetValues>
                ) : (
                  <SetActive onClick={() => onAssetAdd(asset as Asset)}>{"Import"}</SetActive>
                )}
              </ListItem>
            </>
          );
        })}
      </AssetsList>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  left: calc(50% - 200px);
  top: 15%;

  overflow-y: hidden;
  height: 70%;
  text-align: left;
  justify-content: flex-start;

  @media (max-width: 500px) {
    width: 100%;
    left: 0;
    top: initial;
  }
`;

const PinnedGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 10px;
`;

const PinnedAsset = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: rgb(var(--color-black));
  border: 1.5px solid rgb(var(--color-gray100));
  background-color: rgb(var(--color-gray50));
  font-weight: 500;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 10px;
  height: 35px;

  :hover {
    opacity: 0.5;
  }
`;

const AssetsList = styled.ul`
  margin-top: 16px;
  max-height: 375px;
  min-height: 375px;

  overflow-y: scroll;
`;

const Divider = styled.div`
  position: relative;
  flex-direction: row;
  display: flex;
  justify-content: space-between;

  width: 100%;
`;

const RowNames = styled.div`
  font-size: 14px;
  color: var(--color-gray);
`;
const SetActive = styled(ButtonPrimaryMedium)`
  height: 28px;
  width: 25%;
  padding-left: auto;
  padding-right: auto;
  font-size: 12px;
  border-radius: 12px;
`;

export const AssetValues = styled.div`
  text-align: right;
`;
