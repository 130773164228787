/* eslint react-hooks/rules-of-hooks: 0 */
import {createContext, useCallback, useContext, useState, type PropsWithChildren} from "react";
import {AnalyticsDataProps, useAnalyticsData} from "../data";

const analyticsContext = () => {
  const [load, setLoad] = useState<Record<string, boolean>>({});

  const data = useAnalyticsData();

  const tokens = useAnalyticsData({load: 'tokens', disabled: !load.tokens})?.tokens.data;
  const pairs = useAnalyticsData({load: 'pairs', disabled: !load.pairs})?.pairs.data;
  const txs = useAnalyticsData({load: 'txs', disabled: !load.txs})?.txs.data;

  const loadFullData = useCallback((loadData: AnalyticsDataProps['load']) => {
    if (loadData && !load[loadData]) {
      setTimeout(() => {
        setLoad(_ => ({..._, [loadData]: true}))
      })
    }
  }, [setLoad, load]);

  return {
    ready: true,
    data,
    extended: {
      tokens,
      pairs,
      txs,
    },
    load: loadFullData,
  };
};

type AnalyticsContextProps = ReturnType<typeof analyticsContext>;
const AnalyticsContext = createContext<AnalyticsContextProps | null>(null);
export const AnalyticsProvider = ({children}: PropsWithChildren<{}>) => {
  const analytics = analyticsContext();
  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
};

export const useAnalytics = (loadFullData?: boolean, load?: AnalyticsDataProps['load']) => {
  const context = useContext(AnalyticsContext);

  if (context === null) {
    throw new Error("useAnalytics must be used within a AnalyticsProvider");
  }

  if (loadFullData) {
    context.load(load);
  }
  
  return context;
};
