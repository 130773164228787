import React, {ChangeEvent, useState} from "react";
import styled from "styled-components";
import {Modal, Text, Button, Divider, Switch} from "../../../ui/components";
import Toggle from "react-toggle";
import {useWalletHook} from "../../../web3/walletHook";

import {size} from "../../../ui/styles";

export const SlipageToleranceModal = ({
  isVisible,
  onClose,
  defaultLimit,
  onLimitChange,
  onAdvancedChart,
  advancedChart,
}) => {
  const [limit, setLimit] = useState(defaultLimit);
  const [inputValue, setInputValue] = useState("");
  const {setFeeAsset, getFeeAsset, getFeeAssets} = useWalletHook();

  const onChange = (value: string) => {
    setInputValue(value);
    setLimit(Number(value));
  };

  const minValue = 0.01;
  const maxValue = 100;
  const onBlur = (value: number | undefined) => {
    if (value === undefined) return;

    let slippage = value;
    if (value < minValue) slippage = minValue;
    else if (value > maxValue) slippage = maxValue;

    onChange(slippage.toString());
    onLimitChange(slippage);
  };

  return (
    <Modal title="Settings" isVisible={isVisible} onClose={onClose}>
      <Text h4>Slippage Tolerance</Text>
      <Divider size={3} />
      <Text bodyRegular color="gray400">
        By setting slippage tolerance, you set a limit on how many tokens you will accept, whether higher or lower than
        the rate shown in the interface. Slippage tolerance is set as a percentage of the total swap value.
      </Text>
      <InputWrapper>
        <Button
          secondary
          onClick={() => {
            onChange("0.1");
          }}
        >
          0.1%
        </Button>
        <Button
          secondary
          onClick={() => {
            onChange("0.5");
          }}
        >
          0.5%
        </Button>
        <Button
          secondary
          onClick={() => {
            onChange("1");
          }}
        >
          1.0%
        </Button>
        <Input
          value={inputValue}
          onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
          onBlur={() => onBlur(limit)}
          type="number"
          placeholder={limit?.toString()}
        />
      </InputWrapper>
      <Divider size={6} />
      <Text h4>Advanced Chart</Text>
      <Divider size={3} />
      <Text bodyRegular color="gray400">
        Toggle GeckoTerminal charting versus the simple koi chart.
      </Text>
      <br />
      <Switch
        active={advancedChart}
        setActive={(_) => {
          onAdvancedChart(_);
        }}
      />
    </Modal>
  );
};

const InputWrapper = styled.div`
  position: relative;
  padding: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-top: 12px;

  @media (max-width: 500px) {
    & > * {
      ${size.padding(0, 4)}
    }
  }
`;

const Input = styled.input`
  ::after {
    content: "%";
    color: black;
  }

  font-family: "Telegraf";
  font-weight: 600;
  height: 50px;
  width: 22%;
  padding: 20px;
  color: rgb(var(--color-black));
  border: 1px solid rgb(var(--color-overgrown));
  border-radius: 10px;
  text-align: center;
  outline: none;
  position: relative;

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  @media (max-width: 500px) {
    & {
      padding-right: 0;
      padding-left: 0;
    }
  }
`;

const ToggleDiv = styled.div`
  text-align: left;
  position: relative;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: auto;
`;

const ToggleCustom = styled(Toggle)``;
