import {BrowserRouter as Router, Route, Redirect} from "react-router-dom";

import {Layer2WalletScreen} from "./pages/Layer2Wallet/Layer2WalletScreen";
import {LiquidityPoolScreen} from "./pages/LiquidityPool/LiquidityPoolScreen";
import {LiquidityPoolSubScreen} from "./pages/LiquidityPool/LiquidityPoolSubScreen";
import {LiquidityPoolV3SubScreen} from "./pages/LiquidityPool/LiquidityPoolV3SubScreen";

import {AmplifierScreen} from "./pages/Amplifier/AmplifierScreen";
import {AmplifierSubScreen} from "./pages/Amplifier/AmplifierSubScreen";
import {AmplifierV2SubScreen} from "./pages/Amplifier/AmplifierV2SubScreen";
import {SwapScreen} from "./pages/Swap/SwapScreen";
import {SwapWidget} from "./pages/Swap/SwapWidget";
import {DaoScreen} from "./pages/Dao/DaoScreen";
import {AddLiquidityV3View} from "./pages/LiquidityPool/AddLiquidityV3";

import {UiDemoPage} from "./ui/page/demo";
import {UiProvider} from "./ui/components/-provider";

import {Providers} from "./providers";
import {Layout, PaletteRouterDetector} from "./ui/components";
import { RewardsScreen } from "./pages/NewRewards";

import "./styles/reset.css";
// import "./styles/style.css";
import "./ui/styles/globals.css";
import {AnalyticsMain} from "./pages/Analytics/AnalyticsMain";

export function App() {
  return (
    <Router>
      <Providers>
        <UiProvider>
          <Layout>
            <PaletteRouterDetector />

            <Route exact path="/dev/ui" component={UiDemoPage} />

            <Route exact path="/wallet" component={Layer2WalletScreen} />
            <Route exact path="/swap" component={SwapScreen} />

            <Route exact path="/pool" component={LiquidityPoolScreen} />
            <Route exact path="/pool/v3/new" component={AddLiquidityV3View} />
            <Route exact path="/pool/v3/new/:inputCurrency/:outputCurrency/:feeAmount" component={AddLiquidityV3View} />
            <Route exact path="/pool/v3/new/:inputCurrency/:outputCurrency" component={AddLiquidityV3View} />

            <Route exact path="/pool/v3/:nftId" component={LiquidityPoolV3SubScreen} />

            <Route exact path="/pool/:inputCurrency/:outputCurrency/:stable" component={LiquidityPoolSubScreen} />

            <Route exact path="/farm" component={AmplifierScreen} />
            <Route exact path="/farm/:poolId" component={AmplifierSubScreen} />
            <Route exact path="/farmv2/:poolId" component={AmplifierV2SubScreen} />

            <Route
              exact
              path="/"
              render={() => {
                return <Redirect to="/swap" />;
              }}
            />

            <Route exact path="/dao" component={DaoScreen} />

            <Route exact path="/widget" component={SwapWidget} />

            <Route path="/analytics" component={AnalyticsMain} />

            <Route exact path="/rewards" component={RewardsScreen} />
            {/*<Route exact path="/rewards" component={RewardsScreen} />*/}
          </Layout>
        </UiProvider>
      </Providers>
    </Router>
  );
}

//

//            <Route exact path="/bonds" component={BondsScreen} />
//<Route exact path="/bonds/:poolId" component={BondSubScreen} />
//            <Route exact path="/rewards" component={RewardsScreen} />
