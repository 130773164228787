import {Dropdown} from "./Dropdown";
import BigNumber from "bignumber.js";
import {useAccount} from "wagmi";
import {useWalletHook} from "../../web3/walletHook";
import UnknownToken from "../../assets/images/icons/unknown_token.png";
import {Card, Empty, Flex, Scroll, useLayoutMq} from "../../ui/components";
import {PositionV3} from "./positionV3";
import { PositionV2 } from "./positionV2";
import React from "react";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";

/*
type LiquidityPair = {
  value: string;
  assetAImage: string;
  assetBImage: string;
  assetA: any;
  assetB: any;
}

interface YourLiquidityWidgetProps {
  ownedPairs: LiquidityPair[];
  addLiquidity: (LiquidityPair) => any;
  removeLiquidity: (LiquidityPair) => any;
}
*/

enum Sort {
  TVL,
  APY,
}

export const YourLiquidityWidget = ({manageLiquidity, ownedPairs, isLoading}) => {
  const {isConnected} = useAccount();

  const {getTokenFromContract} = useWalletHook();


  const layoutBreak = useLayoutMq();
  
  const [sorted, setSorted] = React.useState(Sort.TVL)
  const [_ascending, setAcending] = React.useState(true)

  const sortByAPY = () => {
    if(sorted == Sort.APY)
      setAcending(!_ascending)
    else
      setAcending(true)
    setSorted(Sort.APY)
  }

  const sortByTVL = () => {
    if(sorted == Sort.TVL)
      setAcending(!_ascending)
    else
      setAcending(true)

    setSorted(Sort.TVL)
  }

  const sortedPairs = React.useMemo(() => {
    /*
    var vals: any = Object.values(ownedPairs)
      .sort((a: any, b: any) => {
        if(sorted == Sort.TVL){
          var _resA = a.totalValueLockedUSD ? a.totalValueLockedUSD : (a.reserveUSD ? a.reserveUSD : 0)
          var _resB = b.totalValueLockedUSD ? b.totalValueLockedUSD : (b.reserveUSD ? b.reserveUSD : 0)
          return Number(_resB) - Number(_resA)
        } else {
          var _apyA = typeof a.ampAPY == 'number' ? new BigNumber(a.apy).plus(a.ampAPY).toFixed(2) : (a.apy ? a.apy : 0)
          var _apyB = typeof b.ampAPY == 'number' ? new BigNumber(b.apy).plus(b.ampAPY).toFixed(2) : (b.apy ? b.apy : 0)
          
          return Number(_apyB) - Number(_apyA)
        }
      });

      if(_ascending == false){
        return vals.reverse()
      }
      */

      return ownedPairs

  }, [ownedPairs, sorted, _ascending]);
  
  const getAssetLogo = (id) => {
    let _asset = getTokenFromContract(id);
    if (_asset) return _asset.logo;

    return UnknownToken;
  };



  const getWidget = () => {
    if (isConnected == false) {
      return <></>;
    }

    if (isLoading) {
      return [...new Array(6)].map(() => <Card size="l" loading height={90} />);
    }

    return (
      <>
        {sortedPairs.map((item, index) =>
          item.v3 ? (
            <PositionV3 position={item} />
          ) : (
            <PositionV2
              onManageClick={() => manageLiquidity(item.assetA, item.assetB, item.stable ? 1 : 2)}
              item={item}
              icon0={getAssetLogo(item.assetA)}
              icon1={getAssetLogo(item.assetB)}
              token0={item.assetASymbol}
              token1={item.assetBSymbol}
              stable={item.stable}
              fee={item.pairFeePercent}
              reserveA={item.assetATotal}
              reserveB={item.assetBTotal}
              tvl={item.reserveUSD}
              apy={
                item.ampAPY && item.apy
                  ? item.apy + "-" + new BigNumber(item.apy).plus(item.ampAPY).toFixed(2)
                  : item.apy
              }
            />
          ),
        )}
        {sortedPairs.length == 0 ? <Empty title="No liquidity pool positions" card /> : <></>}
      </>
    );
  };

  return (
    <Flex column gap={2}>
      {!isConnected && (
        <Empty
          title="Connect your wallet"
          subtitle="Connect your web3 wallet and check your active liquidity pool positions."
          card
          showWallet
        />
      )}

    <Card size="l" height={50}>
          <Flex gap={2} justify="space-between" noBasis={!layoutBreak} expandVertical wrap={layoutBreak}>
            <Flex gap={4} grow={layoutBreak ? 2 : 16} order={0}>
            Pool
            </Flex>

            <Flex grow={layoutBreak ? 1 : 10} order={layoutBreak && 1}>
              Reserves
            </Flex>

            <Flex gap={3} grow={layoutBreak ? 2 : 10} order={layoutBreak && 2} onClick={sortByTVL}>
              TVL {false && sorted == Sort.TVL && _ascending && <FaArrowUp/>} {false && sorted == Sort.TVL && !_ascending && <FaArrowDown/>}
            </Flex>

            <Flex order={layoutBreak && 2} gap={2} grow={layoutBreak ? 1 : 10} onClick={sortByAPY}>
              APY {false && sorted == Sort.APY && _ascending && <FaArrowUp/>} {false && sorted == Sort.APY && !_ascending && <FaArrowDown/>}
            </Flex>


            <Flex justify="flex-end" order={layoutBreak && 10} grow={layoutBreak ? 1 : 10}>

            </Flex>
          </Flex>
        </Card>
        <Scroll padding="0 0 2" parentPadding="3 3" responsive height={90 * 6 + 12 * 5}>
          <Flex column gap={2}>
            {getWidget()}
          </Flex>
          </Scroll>
    </Flex>
  );
};
