import {ChainId} from "../../enums/chain-id";
import {Token} from "../../factories/token/models/token";
import {ErrorCodes} from "../errors/error-codes";
import {MuteSwitchError} from "../errors/muteswitch-error";

/**
 * ZKSYNC token context CHANGE CONTRACT ADDRESS INFO ETC
 */
export class ZKSYNC {
  public static ZKSYNC_ERA(): Token {
    return {
      chainId: ChainId.ZKSYNC_ERA,
      contractAddress: "0x5a7d6b2f92c77fad6ccabd7ee0624e64907eaf3e",
      decimals: 18,
      symbol: "ZK",
      name: "ZKsync",
    };
  }

  /**
   * Get KOI token info by chain id
   * @param chainId The chain id
   */
  public static token(chainId: ChainId | number): Token {
    switch (chainId) {
      case ChainId.ZKSYNC_ERA:
        return this.ZKSYNC_ERA();
      default:
        throw new MuteSwitchError(`${chainId} is not allowed`, ErrorCodes.tokenChainIdContractDoesNotExist);
    }
  }
}
