import styled, {css, keyframes} from "styled-components";


export const lavaEffectHoverClassName = "LAVA_EFFECT_HOVER";

const fps = 8;

const pathConfigs = [
  [0.94, 0.8, 1.2, 30],
  [0.7, 0.2, 0.76, -20],
  [0.65, 0.4, 0.5, -10],
  [0.56, 0.1, 0.6, 50],
  [0.7, 0.6, 1.5, 30, true],
  [0.78, 0.9, 0.6, 10, true],
  [0.52, 0.2, 0.82, 20, true],
  [0.96, 0.8, 0.7, 30, true],
] as const;

const lavaAnimation = (
  width: number,
  height: number,
  [x, y, scale, rotation]: (typeof pathConfigs)[number],
  followOffset?: number,
) => {
  const f = 3.5;
  const getH = (p: number) =>
    height * y + (f / 2 - (p / 100) * f) * height + (followOffset === undefined ? 0 : followOffset);
  const getW = (p: number) => x * width;
  return keyframes`
    0% {
      transform: translate3d(${getW(0)}px, ${getH(0)}px, 0) rotate(0deg) scale(${scale});
    }
    25% {
      transform: translate3d(${getW(25)}px, ${getH(25)}px, 0) rotate(${rotation * 0.5}deg) scale(${scale * 0.8});
    }
    50% {
      transform: translate3d(${getW(50)}px, ${getH(50)}px, 0) rotate(${rotation}deg) scale(${scale * 0.6}, ${scale * 1.2});
    }
    75% {
      transform: translate3d(${getW(75)}px, ${getH(75)}px, 0) rotate(${rotation * 2}deg) scale(${scale * 1}, ${scale * 0.8});
    }
    100% {
      transform: translate3d(${getW(100)}px, ${getH(100)}px, 0) rotate(${rotation * 3}deg) scale(${scale * 0.7}, ${scale * 0.6});
    }
  `;
};

export const Path = styled(
  (
    props: JSX.IntrinsicElements["path"] & {
      n: number;
      r: number;
      width: number;
      height: number;
      performance?: number;
      follower?: boolean;
    },
  ) => <path {...props} data-extra={pathConfigs[props.n!]?.[4]} />,
)`
  fill: currentColor;
  ${(_) => {
    const [x, y, scale, rotation, onlyAnimation] = pathConfigs[_.n!] || [];
    if (!x) return "";
    let {width, height} = _;
    if (_.follower !== undefined) {
      height = height * 2 + 16;
    }
    const offset = (_.height + 12) / 2;
    const followerOffset = _.follower === undefined ? undefined : _.follower ? -offset : offset;
    const anim = lavaAnimation(width, height, pathConfigs[_.n!], followerOffset);
    const time = scale ** -1 * 30 + (_.r * 10 - 2);
    return css`
      animation: ${anim} ${time}s steps(${Math.round(time * fps)}) infinite;
      animation-delay: ${_.performance !== 1 ? time * -0.4 : onlyAnimation ? 0 : time * -0.5}s;
      transform: translate3d(${width * x}px, ${height * y + (followerOffset || 0)}px, 0) rotate(${rotation * 0.5}deg)
        scale(${scale});
    `;
  }}

  .${lavaEffectHoverClassName}:hover & {
    animation-play-state: initial;
  }
`;

export const SVGContainer = styled.svg<{active?: boolean; performance?: number}>`
  transition: opacity 2s ease;
  opacity: 1;

  ${(_) =>
    !_.active &&
    `
    ${Path} {
      animation-play-state: paused;
    }
  `}

  ${(_) =>
    _.performance === undefined &&
    `
    opacity: 0;

    ${Path} {
      animation: none;
    }
  `}

  ${(_) =>
    _.performance === 0 &&
    `
    ${Path} {
      animation: none;
    }
    ${Path}[data-extra="true"] {
      display: none;
    }
  `}

  ${(_) =>
    _.performance === 1 &&
    `
    ${Path} {
      animation-play-state: paused;
    }
    ${Path}[data-extra="true"] {
      animation-delay: 0s;
    }
    &:hover ${Path} {
      animation-play-state: initial;
    }
  `}
`;
