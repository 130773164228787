import styled from "styled-components";

import {br, flex, microPalette, palette, size, text} from "../../styles";

const lightGreen = `color-mix(in srgb, ${microPalette.c200} 50%, ${microPalette.c300})`;

export const Wrapper = styled.div<{small?: boolean}>`
  ${flex("center")}
  ${text.bodyRegular}
  ${br.s}
  ${size.padding("0 3 0 2")}
  ${size.gap(1)}

  height: ${(_) => (_.small ? 32 : 40)}px;

  background-clip: padding-box, border-box;
  background-image: linear-gradient(0deg, ${palette.white} 0%, ${palette.white} 100%),
    linear-gradient(45deg, ${microPalette.c200} 30%, ${microPalette.c300} 50%, ${microPalette.c200} 70%);
  background-image: linear-gradient(0deg, ${palette.white} 0%, ${palette.white} 100%),
    linear-gradient(45deg, ${lightGreen} 30%, ${microPalette.c300} 50%, ${lightGreen} 70%);
  background-origin: border-box;
  border: 1px solid transparent;
`;

export const Icon = styled.div`
  max-height: 24px;
`;

export const Content = styled.div`
  ${text.bodyLarge}

  color: ${microPalette.c300};
`;
