import {Transaction} from "../../../model/Transaction";
import { Icon } from "../";

import * as SC from "./index.styles";

interface IconSortProps {
  dir?: 'desc' | 'asc' | null | undefined;
}

export const IconSort = ({dir, ...rest}: IconSortProps & Omit<JSX.IntrinsicElements['div'], 'ref'>) => {
  return (
    <SC.Wrapper {...rest}>
      <SC.Dir active={dir === "asc"} top><Icon icon="arrowSquare" size="xxs"/></SC.Dir>
      <SC.Dir active={dir === "desc"}><Icon icon="arrowSquare" size="xxs"/></SC.Dir>
    </SC.Wrapper>
  );
};
