import {createSelector} from "reselect";
import {WalletState} from ".";
import {Asset} from "../model/Asset";

export const selectWallet = (state: WalletState) => state;

export const selectAssets = createSelector(selectWallet, (state) => Object.values(state.tokens) as Asset[]);

export const selectAmplifiers = createSelector(selectWallet, (state) => state.amplifiers);
export const selectAmplifiersV2 = createSelector(selectWallet, (state) => state.amplifiersv2);

export const selectFarms = createSelector(selectAmplifiers, selectAmplifiersV2, (amp, ampv2) => {
  if (amp.empty && ampv2.empty) return Object.assign([] as any[], {empty: true});
  return [
    ...amp.filter((_) => +_.endTime > Date.now() || +_.totalUserStake !== 0).map((_) => ({..._, isUserFarm: true})),
    ...ampv2.map((_) => ({..._, isUserFarm: _.user_stakes.length > 0})),
  ].filter((_) => {
    const expired = false //(_.tbv / _.maxTBV) * 100 <= 0;
    return _.token0 && _.token1 && (!expired || (expired && _.isUserFarm));
  });
});

export const selectIsOnFarm = createSelector(
  selectFarms,
  (state, tokenAddressA: string, tokenAddressB: string) => tokenAddressA,
  (state, tokenAddressA: string, tokenAddressB: string) => tokenAddressB,
  (farms, tokenAddressA: string, tokenAddressB: string) => {
    const serialize = (_: any[]) => _.sort().join().toLowerCase();
    return farms.find(
      (_) => serialize([_.token0.address, _.token1.address]) === serialize([tokenAddressA, tokenAddressB]),
    );
  },
);

// User usage status
export const selectUserUsage = createSelector(selectWallet, (state) => {
  const operations = state?.history.map((e) => e.operation);
  const swap = state?.history.empty ? undefined : operations.includes("Swap");
  const pool = state?.history.empty ? undefined : operations.includes("Add LP") || operations.includes("Remove LP");

  const farms = selectFarms(state);

  return {
    swap,
    pool,
    farm: (farms as any).empty ? undefined : !!farms?.filter((_) => _.isUserFarm)?.length,
    dao: state?.daoInfo?.vekoi_locks?.empty ? undefined : !!state?.daoInfo?.vekoi_locks?.length,
  };
});
