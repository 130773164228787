const getRawTypo = (name: string) => `var(--font-${name})`;

const getTypo = (name: string, weight: number) => `
  font-family: var(--font-${name});
  font-weight: ${weight};
`;

export const typo = {
  primary: {
    normal: getTypo("telegraf", 400),
    medium: getTypo("telegraf", 500),
    bold: getTypo("telegraf", 600),
    raw: getRawTypo("telegraf"),
  },
  secondary: {
    normal: getTypo("dm-sans", 400),
    medium: getTypo("dm-sans", 500),
    raw: getRawTypo("dm-sans"),
  },
};
