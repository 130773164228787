import {OnboardSteps} from "../+common/onboard/steps";
import {Box, Button, Card, CardContent, Divider, Flex, Icon, LavaChip, Text, useLayoutMq} from "../../ui/components";
import {numberWithCommas} from "../../utils";
import bg from "../../assets/images/rewards-bg.jpg";

import * as SC from "./index.styles";

export const RewardsScreen = () => {
  const layoutBreak = useLayoutMq();

  return (
    <>
      <SC.Bg src={bg} />
      <Text h1 align="center">
        Join ZK Nation
        <br />
        with{" "}
        <Text inline color="orchid">
          ZKSync
        </Text>{" "}
        x{" "}
        <Text inline color="overgrown">
          KOI
        </Text>
        <br />
        challenges
      </Text>
      <Divider size={16} />
      <Box vertical>
        <Box>
          <LavaChip
            palette="purple"
            heading="My Rewards"
            value={
              <>
                <Flex column gap={4}>
                  <Icon icon="coinZk" contrast />
                  {"—" || numberWithCommas(1234.56, 2)} ZK
                </Flex>
              </>
            }
            leader={layoutBreak}
          />
          <LavaChip
            heading="Active Participants"
            value={
              <>
                <Flex column gap={4}>
                  <Divider size={10} />
                  {numberWithCommas(1234)}
                </Flex>
              </>
            }
            follower={layoutBreak}
          />
        </Box>
        <Box>
          <Box vertical>
            <CardContent palette="purple">
              <CardContent.Title title="Join ZK Nation by ZK x KOI" />
              <CardContent.Content>
                <OnboardSteps
                  onlySteps
                  stepChildren={[
                    <>
                      <Icon icon="coinZk" contrast size="xs" />
                      <Text bodyLarge>{numberWithCommas(123.12, 2)} ZK</Text>
                    </>,
                    <>
                      <Icon icon="coinZk" contrast size="xs" />
                      <Text bodyLarge>{numberWithCommas(92.177, 2)} ZK</Text>
                    </>,
                  ]}
                />
                <Divider size={6} />
                <Button full alt disabled>
                  Coming soon!
                </Button>
              </CardContent.Content>
            </CardContent>
          </Box>
          <Box relative>
            <Box.Fit column>
              <CardContent>
                <CardContent.Title title="Top rewarded" />
                <CardContent.Content>
                  <Flex gap={3} justify="stretch" noBasis>
                    {Array.from({length: 3}).map((_, i) => (
                      <Card key={i} border="xl" empty>
                        <Flex padding="3" column align="center">
                          <Icon button icon="trophy" color={`c${4 - i}00` as any} />
                          <Divider size={4} />
                          <Text bodyLarge>0x17af...982</Text>
                          <Text color="gray400">{numberWithCommas(3921 - 1200 * i, 2)} ZK</Text>
                        </Flex>
                      </Card>
                    ))}
                  </Flex>
                </CardContent.Content>
              </CardContent>
              <CardContent forceContract>
                <CardContent.Title title="Claim history" />
                <CardContent.Content>
                  <Flex gap={3} column>
                    {Array.from({length: 10}).map((_, i) => (
                      <Flex key={i} gap={3}>
                        <Icon icon="coinZk" contrast size="xs" />
                        <Text bodyLarge>{numberWithCommas(300 - i * 23.45, 2)} ZK</Text>
                        <Flex.Grow />
                        <Text color="gray400">14 Sep 2024</Text>
                        <span />
                        <Text bodyMedium>0x17af...982</Text>
                        <Icon icon="external" color="c300" size="xs" />
                      </Flex>
                    ))}
                  </Flex>
                </CardContent.Content>
              </CardContent>
            </Box.Fit>
          </Box>
        </Box>
      </Box>
    </>
  );
};
