/* eslint react-hooks/rules-of-hooks: 0 */
import { createContext, Fragment, useContext, useMemo, type PropsWithChildren } from "react";

import { useSwitchNetwork } from "./switch-network.hooks";

const coreContext = () => {
  const children: any[] = [];
  const clearChildren = <T,>(hook: T & { children?: any[] }): Omit<T, "children"> => {
    children.push(...(hook.children || []));
    return { ...hook, children: undefined };
  };

  const switchNetwork = useSwitchNetwork();

  return {
    switchNetwork,
    children,
  };
};

type CoreContextProps = Omit<ReturnType<typeof coreContext>, "children">;
const CoreContext = createContext<CoreContextProps | null>(null);
export const CoreProvider = ({ children }: PropsWithChildren<{}>) => {
  const core = coreContext();
  return (
    <CoreContext.Provider value={{ ...core, children: undefined } as any}>
      <>
        {children}
        {(core.children as any[]).map((_, i) => (
          <Fragment key={i}>{_}</Fragment>
        ))}
      </>
    </CoreContext.Provider>
  );
};

export const useCore = () => {
  const context = useContext(CoreContext);

  if (context === null) {
    throw new Error("useCore must be used within a CoreProvider");
  }

  return context;
};
