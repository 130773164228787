import React from "react";
import styled from "styled-components";
import {ButtonPrimaryLarge} from "../buttons/Button";
import externalLinkIcon from "../../assets/images/icons/external-link.svg";

export const ModalButton = styled(ButtonPrimaryLarge)`
  margin-top: 32px;
  width: 100%;
`;

export const ModalText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #7f7d97;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
`;

export const ModalDescription = styled(ModalText)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-left: 10px;
  text-align: center;
`;

export const ModalSection = styled.div`
  min-height: 75px;
  height: 75px;

  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

interface EtherscanLinkProps {
  href: string;
}

export const EtherscanLink = ({href}: EtherscanLinkProps) => (
  <Link href={href} target="_blank" rel="noopener noreferrer">
    View on zkSync
    <Icon src={externalLinkIcon} alt="external link" />
  </Link>
);

const Link = styled.a`
  display: inline-flex;
  align-items: center;
  margin-top: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #00c0ff;
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 8px;
`;
