import {Multicall} from "../multicall/multicall";
import {ContractCallContext, ContractCallReturnContext} from "../multicall/models";

import {ERC20} from "../ABI/index";

export type MulticallExecutorResult = Record<string, string>;

// Executor
export class MulticallExecutor {
  private calls: ContractCallContext[] = [];
  private listeners: Record<string, (data: MulticallExecutorResult) => void> = {};

  constructor(private multicall: Multicall) {}

  add(...args: ContractCallContext[] | ContractCallContext[][]) {
    this.calls.push(...args.flat());
    return this;
  }

  build<T extends keyof typeof multicallsBuilders>(
    type: T,
    id?: string,
    ...args: Parameters<(typeof multicallsBuilders)[T]>
  ) {
    const call = this.getContext(type, args);
    if (id) {
      call.reference = id;
    }
    this.add(call);
    return this;
  }

  listen<T extends keyof typeof multicallsBuilders>(
    type: T,
    args: Parameters<(typeof multicallsBuilders)[T]>,
    callback: (data: MulticallExecutorResult) => void,
  ) {
    const id = `MCE-${type}-${this.calls.length}-${this.getReference(type, ...args)}`;
    this.listeners[id] = callback;
    this.build(type, id, ...args);
    return this;
  }

  clear() {
    this.calls = [];
    this.listeners = {};
    return this;
  }

  async execute(...args: Parameters<(typeof this)["add"]>) {
    if (args.length) {
      this.add(...args);
    }
    const {results} = await this.multicall.call(this.calls);
    const cleanResults = {};
    Object.keys(results).forEach((id) => (cleanResults[id] = multicallResultToObject(results[id])));
    Object.keys(results).forEach((id) => this.listeners[id]?.(cleanResults[id]));
    this.clear();
    return results;
  }

  private getReference<T extends keyof typeof multicallsBuilders>(
    type: T,
    ...args: Parameters<(typeof multicallsBuilders)[T]>
  ) {
    return this.getContext(type, args).reference;
  }

  private getContext<T extends keyof typeof multicallsBuilders>(
    type: T,
    args: Parameters<(typeof multicallsBuilders)[T]>,
  ) {
    return (multicallsBuilders[type] as any)?.(...args) as ContractCallContext;
  }
}

const multicallResultToObject = (result: ContractCallReturnContext): MulticallExecutorResult => {
  return result.callsReturnContext.reduce(
    (acc, {methodName, returnValues}) => ({
      ...acc,
      [methodName]: returnValues[0],
    }),
    {} as MulticallExecutorResult,
  );
};

// Set of multicall builders
const erc20 = (address: string, allowanceOwner?: string, allowanceSpender?: string, balanceOf?: string): ContractCallContext => ({
  reference: "erc20-" + address?.toLowerCase(),
  contractAddress: address,
  abi: ERC20,
  calls: [
    ...(allowanceOwner && allowanceSpender
      ? [{reference: "approve", methodName: "allowance", methodParameters: [allowanceOwner, allowanceSpender]}]
      : []),
    {reference: "decimals", methodName: "decimals", methodParameters: []},
    {reference: "symbol", methodName: "symbol", methodParameters: []},
    ...(balanceOf
      ? [{reference: "balanceOf", methodName: "balanceOf", methodParameters: [balanceOf]}]
      : []),
  ],
});

const multicallsBuilders = {erc20};
