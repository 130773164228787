import {Fragment, memo, PropsWithChildren, useState} from "react";
import {AssetsListModal} from "../../../common/modal/AssetsListModal";
import {Asset, LPAsset} from "../../../model/Asset";

import {Flex} from "../flex";
import {Icon, IconAsset} from "../icon/index";
import {Text} from "../text";

import * as SC from "./index.styles";

interface AssetSelectorProps {
  asset?: Asset | LPAsset | undefined;
  setAsset?: (asset: Asset) => void;
  assets?: Asset[];
  disabled?: boolean;
  large?: boolean;
}

export const AssetSelector = memo(function AssetSelectorComponent(props: PropsWithChildren<AssetSelectorProps>) {
  const {asset, assets = [], setAsset = () => {}, disabled, large} = props;
  const [open, setOpen] = useState(false);
  return (
    <>
      <SC.Wrapper onClick={() => setOpen(true)} disabled={disabled} large={large}>
        {asset ? (
          "assets" in asset ? (
            <>
              <Flex>
                {asset.assets.map((_) => (
                  <Icon key={_.symbol} stack size="xs" imageFallback={_.logo} button />
                ))}
              </Flex>
              <span>
                {asset.assets.map((_, i) => (
                  <Fragment key={_.symbol}>
                    {!!i && (
                      <>
                        {" "}
                        <Text color="gray400" inline>
                          –
                        </Text>{" "}
                      </>
                    )}
                    {_.symbol}
                  </Fragment>
                ))}{" "}
                <Text color="gray400" inline>
                  LP
                </Text>
              </span>
            </>
          ) : (
            <>
              <IconAsset size="xs" asset={asset} contrast />
              <span>{asset?.symbol}</span>
            </>
          )
        ) : (
          <>
            <Text bodyRegular color="gray400">
              Select asset
            </Text>
          </>
        )}
        {!disabled ? (
          <>
            <Flex.Grow />
            <Icon icon="arrow" size="s" color="c300" />
          </>
        ) : null}
      </SC.Wrapper>
      <AssetsListModal
        isVisible={open}
        onClose={() => setOpen(false)}
        assets={assets}
        setSelectedAsset={setAsset as any}
      />
    </>
  );
});
