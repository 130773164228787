import {memo, useMemo} from "react";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Flex,
  Icon,
  IconAsset,
  Loading,
  Table,
  Text,
  Tooltip,
  toTableConfig,
} from "../../../../ui/components";
import { formatPrice, formatPriceZeros } from "../../../../utils";
import { AnalyticsData } from "../../data";
import {useAnalytics} from "../../provider";
import {AnalyticsCell, AnalyticsHeader} from "./components/cell";
import { AnalyticsSection } from "./components/common-section";

import * as SC from "./index.styles";

interface AnalyticsOverviewTxsProps {
  full?: boolean;
  data?: AnalyticsData['txs']['data'];
}

export const AnalyticsOverviewTxs = ({full, data: injectedData}: AnalyticsOverviewTxsProps) => {
  const {
    data: {txs},
    extended,
  } = useAnalytics(full, 'txs');
  const data = injectedData || (full ? extended.txs : txs.data);

  const config = useMemo(() => {
    return toTableConfig(data, [
      {
        index: "action",
        key: true,
        header: "Action",
        headerAlign: "left",
        align: "left",
        value: d => [d.token0, d.token1, d.type, d.hash, d.index].join().toLowerCase(),
        content: d => (
          <Flex gap={3}>
            <Flex>
              <IconAsset stack symbol={d.token0} button size="xs" />
              <IconAsset stack symbol={d.token1} button size="xs" />
            </Flex>
            <div>
              {(() => {
                switch (d.type) {
                  case "swaps": return <>
                    <Text h6>
                      Swap {d.token0} for {d.token1}
                    </Text>
                    <Text bodyRegular color="gray400">
                      <Flex align="center">
                        <span>{formatPriceZeros(d.amount0, 4, 3)}</span>
                        &nbsp;
                        {d.token0}
                        <Icon icon="arrow" size="xxs" rotate={3} color="orchid" />
                        <span>{formatPriceZeros(d.amount1, 4, 3)}</span>
                        &nbsp;
                        {d.token1}
                      </Flex>
                    </Text>
                  </>;
                  case "mints": return <>
                    <Text h6>
                      Add {d.token0} and {d.token1} to LP
                    </Text>
                    <Text bodyRegular color="gray400">
                      <Flex align="center">
                        <span>{formatPriceZeros(d.amount0, 4, 3)}</span>
                        &nbsp;
                        {d.token0} &
                        &nbsp;
                        <span>{formatPriceZeros(d.amount1, 4, 3)}</span>
                        &nbsp;
                        {d.token1}
                        <Icon icon="arrow" size="xxs" rotate={3} color="orchid" />
                        LP 
                      </Flex>
                    </Text>
                  </>;
                  case "burns": return <>
                    <Text h6>
                      Remove {d.token0} and {d.token1} from LP
                    </Text>
                    <Text bodyRegular color="gray400">
                      <Flex align="center">
                        LP 
                        <Icon icon="arrow" size="xxs" rotate={3} color="orchid" />
                        <span>{formatPriceZeros(d.amount0, 4, 3)}</span>
                        &nbsp;
                        {d.token0} &
                        &nbsp;
                        <span>{formatPriceZeros(d.amount1, 4, 3)}</span>
                        &nbsp;
                        {d.token1}
                      </Flex>
                    </Text>
                  </>;
                }
              })()}
            </div>
          </Flex>
        ),
      },
      {
        index: "total",
        header: <AnalyticsHeader name="Amount" />,
        value: d => d.amountUsd,
        content: d => <AnalyticsCell data={d} prop="amountUsd" type="coin"/>,
      },
      {
        index: "from",
        header: <AnalyticsHeader name="Account" />,
        value: d => d.from,
        content: d => <AnalyticsCell data={d} prop="from" type="hash" hashType="account"/>,
      },
      {
        index: "hash",
        header: <AnalyticsHeader name="Tx. Hash" />,
        value: d => d.hash,
        content: d => <AnalyticsCell data={d} prop="hash" type="hash" hashType="tx"/>,
      },
      {
        index: "date",
        header: <AnalyticsHeader name="Date" />,
        sort: 'desc',
        value: d => d.date,
        content: d => <AnalyticsCell data={d} prop="date" type="date"/>,
      },
    ]);
  }, []);

  return (
    <>
      <AnalyticsSection title="Transactions" href="/analytics/transactions" isPage={full} hideLink={!!injectedData}>
        <Table showLoading height={SC.getTableSize(full)} data={data} config={config} />
      </AnalyticsSection>
    </>
  );
};
