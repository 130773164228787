import {memo, useCallback, useEffect, useState} from "react";
import {useWeb3Modal} from "@web3modal/wagmi/react";
import {useAccount, useChainId} from "wagmi";

import {formatPrice, gaHash, reduceAddress, sentGaEvent, setGaUserProperties} from "../../../utils";
import {useWalletHook} from "../../../web3/walletHook";
import {Icon, IconAsset} from "../icon";
import {PaymasterModal} from "../../../common/modal/PaymasterModal";

import greenCircle from "../../../assets/images/green-circle.png";
import {Button} from "../+button";

import * as SC from "./index.styles";
import {isSponsoredPaymaster} from "../../../web3/sponsored-paymaster";
import {Loading} from "../loading";
import {GasLessChip} from "../chip-fancy";
import { useCore } from "../../../core";
import { Text } from "../text";

interface Web3ConnectionProps {
  onlyBalance?: boolean;
  onlyWallet?: boolean;
}

export const Web3Connection = memo(function Web3ConnectionComponent({onlyBalance, onlyWallet}: Web3ConnectionProps) {
  const {getFeeAssets, setFeeAsset, getFeeAsset} = useWalletHook<true>();
  const {open} = useWeb3Modal();
  const account = useAccount();
  const chainId = useChainId()
  const {switchNetwork} = useCore();
  const [showPaymaster, setShowPaymaster] = useState(false);
  const [firstCheck, setFirstCheck] = useState(true);
  const [sponsoredPaymaster, setSponsoredPaymaster] = useState<boolean>();

  const feeAsset = getFeeAsset();
  const loadingBalance = !!(feeAsset?.balance as any)?.unknown;
  const loading = (loadingBalance || sponsoredPaymaster === undefined) && sponsoredPaymaster !== true;

  const openModal = useCallback(() => {
    open();
    if (account.isConnected) {
      sentGaEvent("open_web3_account", {value: account.address}, true);
    } else {
      sentGaEvent("click_web3_connect");
    }
  }, [account]);

  useEffect(() => {
    if (firstCheck) {
      setFirstCheck(false);
      return;
    }
    if (account.isConnected) {
      setGaUserProperties({
        web3_provider: account?.connector?.name,
        hash: gaHash(account?.address!),
      });
      sentGaEvent("web3_connected", {value: account.address, category: account?.connector?.name}, true);
    } else {
      sentGaEvent("web3_disconnected");
    }
  }, [!!account.isConnected]);

  useEffect(() => {
    if (onlyWallet) return;
    isSponsoredPaymaster(chainId).then((enabled) => {
      setSponsoredPaymaster(enabled);
    });
  }, [onlyWallet, chainId]);

  return (
    <>
      <PaymasterModal
        isVisible={showPaymaster}
        onClose={() => setShowPaymaster(false)}
        assets={getFeeAssets()}
        setSelectedAsset={setFeeAsset}
      />
      <SC.Wrapper>
        {!onlyWallet && account.address && (
          <>
            {sponsoredPaymaster || !switchNetwork.correctNetwork ? (
              <SC.GasLessWrapper forceVisible={!onlyBalance}>
                {!switchNetwork.correctNetwork ? (
                  <SC.WrongNetworkButton onClick={switchNetwork.switchNetwork}>
                    <Icon icon="close" color="error" contrast background="errorLight" size="xs" />
                    <Text h7 color="error">
                      Wrong network
                    </Text>
                  </SC.WrongNetworkButton>
                ) : (
                  <GasLessChip />
                )}
              </SC.GasLessWrapper>
            ) : (
              <SC.Button forceVisible={onlyBalance} gap={1} balance onClick={() => setShowPaymaster(true)}>
                <Icon icon="gas" size="xs" contrast color="gray400" />
                {!loading ? (
                  <>
                    <IconAsset asset={feeAsset} button size="xs" />
                    {formatPrice(feeAsset?.balance, true, 3)} {feeAsset?.symbol}
                  </>
                ) : (
                  <Loading />
                )}
              </SC.Button>
            )}
          </>
        )}
        {!onlyBalance && (
          <>
            {account.isConnected && (
              <SC.Button wallet onClick={openModal}>
                <img src={greenCircle} />
                {reduceAddress(account.address)}
                <Icon icon="arrow" />
              </SC.Button>
            )}
            {!account.isConnected && (
              <Button size="s" onClick={openModal}>
                Connect Wallet
              </Button>
            )}
          </>
        )}
      </SC.Wrapper>
    </>
  );
});
