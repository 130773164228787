import React from "react";
import styled, {keyframes} from "styled-components";

export const LoadingBox = () => {
  return <LoadingBoxDiv />;
};

const Shimmer = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

export const LoadingBoxDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  animation: ${Shimmer} 3s infinite;
  background: linear-gradient(
    90deg,
    rgba(var(--color-goldenrod)) 0%,
    rgba(var(--color-ultramoss)) 50%,
    rgba(var(--color-green)) 100%
  );
  background-size: 500% 500%;
  border-radius: 16px;
  width: 100%;
  height: 100%;
`;

export const LoadingText = styled.div`
  animation: ${Shimmer} 3s infinite;
  background: linear-gradient(
    90deg,
    rgba(var(--color-gray400)) 0%,
    rgba(var(--color-goldenrod)) 50%,
    rgba(var(--color-gray200)) 100%
  );
  background-size: 500% 500%;
  border-radius: 16px;
  width: 50%;
  height: 40px;
`;
