import {memo, useEffect, useMemo, useState, ReactNode} from "react";
import { useLocation} from "react-router-dom";
import {useAccount} from "wagmi";

import {useWalletSelector} from "../../../state/hooks";
import {Bullet, Divider, Flex, Icon, Loading, Text, Web3Connection} from "../../../ui/components";
import {selectUserUsage} from "../../../state/selectors";

import * as SC from "./steps.styles";

export const OnboardSteps = memo(function OnboardStepsComp({
  onlySteps,
  stepChildren = [],
}: {
  onlySteps?: boolean;
  stepChildren?: ReactNode[];
}) {
  const path = useLocation().pathname;
  const account = useAccount();

  const [open, setOpen] = useState(false);
  const stepCompleted = useWalletSelector(selectUserUsage);

  useEffect(() => {
    setOpen(false);
  }, [path.includes("rewards")]);

  const {steps, allCompleted, progress} = useMemo(() => {
    const list = [
      {
        heading: "Get started with KOI",
        subtitle: "Swap any amount of any tokens",
        complete: stepCompleted.swap,
        path: "/swap",
      },
      {
        heading: "Provide liquidity on KOI",
        subtitle: "Provide 2 assets to one of the pools",
        complete: stepCompleted.pool,
        path: "/pool",
      },
      {
        heading: "Stake on our farms",
        subtitle: "Use the farms to earn more APY",
        complete: stepCompleted.farm,
        path: "/farm",
      },
      {
        heading: "Participate in Governance",
        subtitle: "Lock any amount of KOI to DAO",
        complete: stepCompleted.dao,
        path: "/dao",
      },
    ].map((_, i) => ({..._, children: stepChildren[i]}));
    return {
      steps: list,
      progress: list.filter((_) => _.complete).length / list.length,
      allCompleted: !!list.find((_) => _.complete !== true),
    };
  }, [stepCompleted, path, stepChildren]);

  const stepsComp = useMemo(
    () =>
      steps
        .map((step) => ({...step, complete: account.isConnected ? step.complete : false}))
        .map((step, i) => (
          <SC.Item {...step} to={step.path} key={i} active={path.includes(step.path)}>
            <SC.BulletLine />
            <Bullet discrete={step.complete === false}>
              {step.complete === undefined ? (
                <Loading size="s" />
              ) : !step.complete ? (
                i + 1
              ) : (
                <Icon icon="gift" size="xxs" />
              )}
            </Bullet>
            <SC.ItemContent>
              <Text h6>{step.heading}</Text>
              <Text color="gray400">{step.complete === undefined ? "Verifying..." : step.subtitle}</Text>
            </SC.ItemContent>
            <Flex.Grow />
            {step.children}
          </SC.Item>
        )),
    [steps, account.isConnected],
  );

  if (onlySteps) return stepsComp;

  if (path.includes("rewards")) return null;

  return (
    <SC.Wrapper>
      <SC.Circle onClick={() => setOpen((_) => !_)} open={open}>
        <Icon icon={!open ? "gift" : "close"} size="l" />
        <svg width="62" height="62" xmlns="http://www.w3.org/2000/svg">
          <circle
            cx="31"
            cy="31"
            r="30"
            strokeWidth="2"
            fill="none"
            strokeDashoffset={account.isConnected ? (1 - progress) * 189 : 189}
          />
        </svg>
      </SC.Circle>
      <SC.Card open={open}>
        <SC.Content open={open}>
          <Text h6>Get started with KOI</Text>
          <SC.Line />

          {!account.isConnected && (
            <>
              <Flex column align="center">
                <Divider size={4} />
                <Web3Connection onlyWallet />
              </Flex>
            </>
          )}

          {stepsComp}
          {/*
          <Divider size={4}/>
          <div>
            <Link to="/rewards">
              <Button full alt>Rewards</Button>
            </Link>
          </div>
*/}
        </SC.Content>
      </SC.Card>
    </SC.Wrapper>
  );
});
