import {memo, ReactNode, useCallback} from "react";
import {useWalletSelector} from "../../../state/hooks";
import { GasLessChip, Loading, Modal} from "../../../ui/components";

export interface TxModalProps {
  children: (valueWrapper: (value: ReactNode) => ReactNode) => ReactNode;
}

export const TxModal = memo(({children}: TxModalProps) => {
  const gasFree = useWalletSelector((state) => state.gasEstimateFree);

  const wrapper = useCallback(
    (value: ReactNode) => {
      if (gasFree === undefined) return <Loading size="s" />;
      else return value;
    },
    [gasFree],
  );

  if (gasFree) {
    return (
      <Modal.Row title="Gas Cost" color="orchid">
        <GasLessChip small />
      </Modal.Row>
    );
  }

  return children?.(wrapper) || null;
});
