import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAccount, useSwitchChain } from "wagmi";
import {zkSync} from "wagmi/chains";
import { sophonsepolia } from "../providers/Providers";

import { useEthersProvider } from "../providers/ethers";
import { useWalletDispatch } from "../state/hooks";
import { walletSlice } from "../state/reducer";


const isSupportedNetwork = (id) => {
  return (id == sophonsepolia.id || id ==  zkSync.id)
}

export const useSwitchNetwork = () => {
  const dispatch = useWalletDispatch();

  const {address: account, isConnected, chain} = useAccount();
  const {switchChainAsync, error} = useSwitchChain();
  const provider = useEthersProvider({chainId: 324});

  const [switching, setSwitching] = useState(false);

  const chainId = chain?.id;

  const isCorrectNetwork = useCallback(() => isSupportedNetwork(chain?.id), [chain]);

  const switchNetwork = useCallback(async () => {
    setSwitching(true);
    try {
      await switchChainAsync({chainId: zkSync.id})
    } catch {}
    setSwitching(false);
  }, [switchChainAsync]);
  
  useEffect(() => {
    if (provider && account) {
      dispatch(walletSlice.actions.updateWrongChain(!isCorrectNetwork()));
      if (!isCorrectNetwork()) {
        // Disable auto prompt
        // switchNetwork();
      }
    }
  }, [isConnected, provider, account, chainId, isCorrectNetwork]);

  const correctNetwork = isCorrectNetwork();

  return {
    ready: isConnected && chainId,
    expectedNetwork: {
      chainId: zkSync.id,
      connected: correctNetwork,
    },
    correctNetwork,
    switchNetwork,
    switching,
  };
};
