import {memo, PropsWithChildren} from "react";

import * as SC from "./index.styles";

interface SwitchProps {
  active?: boolean;
  setActive?: (active: boolean) => unknown;
}

export const Switch = memo(function SwitchComponent({active, setActive}: PropsWithChildren<SwitchProps>) {
  return <SC.Wrapper active={active} onClick={() => setActive?.(!active)} />;
});
