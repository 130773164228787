import styled from "styled-components";

import { card, microPalette, palette, size, text} from "../../styles";

const rangeHeight = 3;
const bgHeight = 1;
const circleSize = 11;
const circleBorder = 3;
const centralRange = 0.6;

const maxSide = (1 - centralRange) / 2;

interface CommonTyping {
  rangeFrom: number;
  rangeTo: number;
  price: number;
}

export const Container = styled.div`
  ${size.padding(9, 4, 4)}

  width: 100%;
`;

export const Wrapper = styled.div<CommonTyping>`
  position: relative;
  height: ${rangeHeight}px;
  width: 100%;

  ${({rangeFrom, rangeTo, price}) => {
    const inRange = rangeFrom <= price && price <= rangeTo;
    const left = Math.min(maxSide, (rangeFrom / rangeTo) * centralRange);
    const right = rangeTo === Infinity ? 0 : maxSide;
    const pricePosition = ((price - rangeFrom) / (rangeTo - rangeFrom)) * (1 - left - right) + left;
    const priceLeft = Math.max(0, Math.min(1, rangeTo === Infinity ? 0.2 : pricePosition));
    return `
      ${Range} {
        left: ${left * 100}%;
        right: ${right * 100}%;
      }
      ${PriceSelector} {
        left: ${priceLeft * 100}%;
        color: ${inRange ? microPalette.c300 : palette.gray300};
      }
    `;
  }}
`;

export const LineBackground = styled.div`
  position: absolute;
  top: ${(rangeHeight - bgHeight) / 2}px;
  left: 0;
  width: 100%;
  height: ${bgHeight}px;
  background: ${palette.gray300};
`;

export const PriceSelector = styled.div`
  position: absolute;
  z-index: 3;
  top: 50%;
  margin-top: ${rangeHeight / 2}px;
  transform: translateX(-50%);
`;

export const Range = styled.div`
  position: absolute;
  z-index: 2;
  background: ${microPalette.c400};
  height: ${rangeHeight}px;
  top: 0;

  &:before,
  &:after {
    content: "";
    display: block;
    height: ${circleSize}px;
    width: ${circleSize}px;
    border-radius: 50%;
    border: ${circleBorder}px solid ${microPalette.c400};
    background: ${palette.white};
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateX(-50%) translateY(-50%);
  }
  &:after {
    left: initial;
    right: 0;
    transform: translateX(50%) translateY(-50%);
  }
`;

export const ValueChip = styled.div<{right?: boolean}>`
  ${card("xs")}
  ${size.padding(1.5)}

  position: absolute;
  user-select: none;
  z-index: 4;
  bottom: 50%;
  margin-bottom: ${size.raw(1) + circleSize / 2}px;
  left: 0;
  transform: translateX(-50%);

  ${(_) =>
    _.right &&
    `
    left: initial;
    right: 0;
    transform: translateX(50%);
  `}
`;

export const Warning = styled.div`
  ${size.padding(1)}
  ${text.bodyRegular}

  position: absolute;
  width: 100%;
  top: 100%;
  left: 50%;
  transform: translateX(-25%);
`;
