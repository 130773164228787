export const Commands = {
  // Masks to extract certain bits of commands
  FLAG_ALLOW_REVERT: "0x80",
  COMMAND_TYPE_MASK: "0x3f",

  // Command Types. Maximum supported command at this moment is 0x3f.

  // Command Types where value<0x08", executed in the first nested-if block
  V3_SWAP_EXACT_IN: "0x00",
  V3_SWAP_EXACT_OUT: "0x01",
  PERMIT2_TRANSFER_FROM: "0x02",
  PERMIT2_PERMIT_BATCH: "0x03",
  SWEEP: "0x04",
  TRANSFER: "0x05",
  PAY_PORTION: "0x06",
  // COMMAND_PLACEHOLDER: "0x07",

  // The commands are executed in nested if blocks to minimise gas consumption
  // The following constant defines one of the boundaries where the if blocks split commands
  FIRST_IF_BOUNDARY: "0x08",

  // Command Types where 0x08<=value<=0x0f", executed in the second nested-if block
  V2_SWAP_EXACT_IN: "0x08",
  V2_SWAP_EXACT_OUT: "0x09",
  PERMIT2_PERMIT: "0x0a",
  WRAP_ETH: "0x0b",
  UNWRAP_WETH: "0x0c",
  PERMIT2_TRANSFER_FROM_BATCH: "0x0d",
  BALANCE_CHECK_ERC20: "0x0e",
  // COMMAND_PLACEHOLDER: "0x0f",

  // The commands are executed in nested if blocks to minimise gas consumption
  // The following constant defines one of the boundaries where the if blocks split commands
  SECOND_IF_BOUNDARY: "0x10",

  // Command Types where 0x10<=value<0x18", executed in the third nested-if block
  SEAPORT_V1_5: "0x10",
  LOOKS_RARE_V2: "0x11",
  NFTX: "0x12",
  CRYPTOPUNKS: "0x13",
  // 0x14",
  OWNER_CHECK_721: "0x15",
  OWNER_CHECK_1155: "0x16",
  SWEEP_ERC721: "0x17",

  // The commands are executed in nested if blocks to minimise gas consumption
  // The following constant defines one of the boundaries where the if blocks split commands
  THIRD_IF_BOUNDARY: "0x18",

  // Command Types where 0x18<=value<=0x1f", executed in the final nested-if block
  X2Y2_721: "0x18",
  SUDOSWAP: "0x19",
  NFT20: "0x1a",
  X2Y2_1155: "0x1b",
  FOUNDATION: "0x1c",
  SWEEP_ERC1155: "0x1d",
  ELEMENT_MARKET: "0x1e",
  // COMMAND_PLACEHOLDER: "0x1f",

  // The commands are executed in nested if blocks to minimise gas consumption
  // The following constant defines one of the boundaries where the if blocks split commands
  FOURTH_IF_BOUNDARY: "0x20",

  // Command Types where 0x20<=value
  SEAPORT_V1_4: "0x20",
  EXECUTE_SUB_PLAN: "0x21",
  APPROVE_ERC20: "0x22",
};
