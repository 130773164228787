import React, {ChangeEvent, useState} from "react";
import styled, {css} from "styled-components";
import {Modal, ModalProps, Text} from "../../ui/components";

interface SlipageToleranceModalProps extends ModalProps {
  defaultLimit: number;
  defaultLimitSlippage: number;
  disableFee: boolean;
  onLimitChange: (limit: number) => void;
  onLimitChangeSlippage: (limit: number) => void;
}

export const LiquiditySettingsModal = ({
  isVisible,
  onClose,
  defaultLimit,
  defaultLimitSlippage,
  disableFee,
  onLimitChange,
  onLimitChangeSlippage,
}: SlipageToleranceModalProps) => {
  const [limit, setLimit] = useState(defaultLimit);
  const [limitSlippage, setLimitSlippage] = useState(defaultLimitSlippage);

  const [inputValue, setInputValue] = useState("");
  const [inputValueSlippage, setInputValueSlippage] = useState("");

  const onChange = (value: string) => {
    setInputValue(value);
    setLimit(Number(value));
  };

  const onChangeSlippage = (value: string) => {
    setInputValueSlippage(value);
    setLimitSlippage(Number(value));
  };

  const minValue = 0.01;
  const maxValue = 10;
  const onBlur = (value: number | undefined) => {
    if (value === undefined) return;

    let slippage = value;
    if (value < minValue) slippage = minValue;
    else if (value > maxValue) slippage = maxValue;

    onChange(slippage.toString());
    onLimitChange(slippage);
  };

  const minValue_S = 0.05;
  const maxValue_S = 20;
  const onBlurSlippage = (value: number | undefined) => {
    if (value === undefined) return;

    let slippage = value;
    if (value < minValue_S) slippage = minValue_S;
    else if (value > maxValue_S) slippage = maxValue_S;

    onChangeSlippage(slippage.toString());
    onLimitChangeSlippage(slippage);
  };

  return (
    <Modal title="Settings" isVisible={isVisible} onClose={onClose}>
      <List>
        <Item>
          <Text h4>Pool fee</Text>
          <br />
          <Text bodyRegular color="gray400">
            Pool fee is the spread that the AMM pool operates on. If you are an LP, this is the % of rewards you
            generate off each trade. Can only be set on pool creation.
          </Text>
          <Item>
            <InputWrapper>
              <Input
                value={inputValue}
                onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
                onBlur={() => onBlur(limit)}
                type="number"
                placeholder={limit?.toString()}
                disabled={disableFee}
              />
            </InputWrapper>
          </Item>
        </Item>

        <Item>
          <Text h4>Slippage Tolernace</Text>
          <br />
          <Text bodyRegular color="gray400">
            By setting slippage tolerance, you set a limit on your LP deposit ratios, whether higher or lower than the
            rate shown in the interface. Slippage tolerance is set as a percentage of the total liquidity ratios.
          </Text>
          <InputWrapper>
            <Input
              value={inputValueSlippage}
              onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeSlippage(event.target.value)}
              onBlur={() => onBlurSlippage(limitSlippage)}
              type="number"
              placeholder={limitSlippage?.toString()}
            />
          </InputWrapper>
        </Item>
      </List>
    </Modal>
  );
};
//For every trade an account makes through this pool, this percent is what is taken and given to LPs. You can only set and edit this when launching a new pool.
const List = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 0px;

  @media (max-width: 500px) {
    max-width: 196px;
    flex-wrap: wrap;
    margin: 60px auto 0;
  }
`;

const Item = styled.li`
  width: 100%;
  height: 100%;
  margin-bottom: 20px;

  @media (max-width: 500px) {
    margin-top: 16px;
  }
`;

const backgroundStyles = css`
  width: 100%;
  height: 100%;
  font-weight: 300;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: white;
  background: var(--color-dark-blue);
  border: 1px solid var(--color-gray);
  border-radius: 14px;
`;

const ItemButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  ${backgroundStyles}

  &.active {
    color: #ffffff;
    background: transparent;
  }

  :hover {
    color: #ffffff;
  }
`;

const InputWrapper = styled.div`
  font-family: "Telegraf";
  font-weight: 600;
  position: relative;
  padding: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-top: 10px;

  @media (max-width: 500px) {
  }

  &::after {
    content: "%";
    position: absolute;
    top: 50%;
    left: 65px;
    transform: translateY(-50%);
  }
  @media (max-width: 500px) {
    &:nth-child(even) {
    }
  }
`;

const Input = styled.input`
  font-family: "Telegraf";
  font-weight: 600;
  height: 50px;
  width: 22%;
  padding: 20px;
  color: rgb(var(--color-black));
  border: 1px solid rgb(var(--color-overgrown));
  border-radius: 10px;
  text-align: center;
  outline: none;
  position: relative;

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &:disabled {
    opacity: 0.5;
  }
`;
