import React, {useState, useMemo} from "react";
import styled, {keyframes} from "styled-components";
import {useParams} from "react-router-dom";
import BigNumber from "bignumber.js";
import {useAccount} from "wagmi";

import {Grid} from "../../common/layout/Grid";
import {LiquiditySettingsModal} from "./LiquiditySettingsModal";

import LaunchIcon from "@mui/icons-material/Launch";

import unknownToken from "../../assets/images/icons/unknown_token.svg";

import {
  reduceString,
  formatNumber,
  formatPrice,
  extractNumber,
  sendTransactionGaEvent,
  numberWithCommas,
} from "../../utils";
import {LoadingAnimation} from "../../common/loading/loading";
import {ConfirmTransactionModal} from "../../common/modal/ConfirmTransactionModal";
import crossLight from "../../assets/images/icons/cross.svg";
import {SuccessfulSubmitModal} from "../../common/modal/SuccessfulSubmitModal";
import {ErrorModal} from "../../common/modal/ErrorModal";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Flex,
  IconAsset,
  Loading,
  PriceRange,
  Skeleton,
  Title,
  InputCoin,
  TitleDash,
  Modal as CustomModal,
  Message,
  Scroll,
  LavaChip,
  lavaEffectHoverClassName,
  Tooltip,
} from "../../ui/components";
import {Tabs, Button, Text} from "../../ui/components";
import {useWalletHook} from "../../web3/walletHook";
import {WalletState} from "../../state";

import {useWalletSelector} from "../../state/hooks";

import {Pool, Position, TickMath} from "@uniswap/v3-sdk";
import {getFeeToTickSpacing} from "../../web3/v3/liqPositions";
import {Percent} from "@uniswap/sdk-core";
import {TxModal} from "../+common/TxModal";
import {useMediaQuery} from "../../hooks/useMediaQuery";
import { MdOutlineBolt } from "react-icons/md";

interface ParamTypes {
  nftId: string;
}

enum Modal {
  Settings,
  ChangeFee,
  Confirm,
  Switch,
  Connect,
  Slipage,
  Error,
  Deposit,
  Withdraw,
  Success,
  Rewards,
  SuccessRewards,
  Add,
  Remove,
  SuccessIncrease,
  SuccessRemove,
}

export const LiquidityPoolV3SubScreen = () => {
  const {nftId} = useParams<ParamTypes>();
  const mobile = useMediaQuery(940);

  const [assetA, setAssetA] = useState<any>(undefined);
  const [assetB, setAssetB] = useState<any>(undefined);

  const [depositA, setDepositA] = useState("0");
  const [depositB, setDepositB] = useState("0");

  const [withdrawalA, setWithdrawalA] = useState("0");
  const [withdrawalB, setWithdrawalB] = useState("0");
  const [withdrawalLiquidity, setWithdrawalLiquidity] = useState("0");

  const [settingPoolFee, setSettingPoolFee] = useState(0.3);

  const [poolAPY, setPoolAPY] = useState("0");
  const [poolTVL, setPoolTVL] = useState("0");

  const [poolData, setPoolData] = useState<any>(null);
  const [pairInfo, setPairInfo] = useState<Awaited<ReturnType<typeof getPairDayData>>>();
  const allPairs = useWalletSelector((state) => state.allPairs);

  const [isDeposit, setIsDeposit] = useState(0);
  const [calculating, setCalculating] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [modal, setModal] = useState<Modal | null>(null);

  const category = [assetA!?.symbol, assetB!?.symbol].join();

  const closeModal = () => {
    setModal(null);
    setGasSaved("");
    setConfirmTime("0.00");
    settxHash("");
    setCalculating(false);
    setDepositA("0");
    setDepositB("0");
    setWithdrawalA("0");
    setWithdrawalB("0");
    setWithdrawalLiquidity("0");
  };

  const [error, setError] = useState("");
  const [slippage, setSlippage] = useState(0.5);
  const [gasSaved, setGasSaved] = useState("");
  const [confirmTime, setConfirmTime] = useState("0.00");
  const [txHash, settxHash] = useState("");

  const gasRefund = useWalletSelector((state: WalletState) => state.gasEstimateRefund);
  const merkleRewards = useWalletSelector((state: WalletState) => state.merkleRewards);

  const [useToken0, setUseToken0] = useState(true);

  const {
    getTokenFromContract,
    getTokenInformation,
    searchForLiquidity,
    getPairDayData,
    increaseLiquidityV3,
    removeLiquidityV3,
    claimPositionFees,
    currentSwapCost,
    loadedInitialList,
    getV3NftInfo,
  } = useWalletHook<true>();

  const {isConnected} = useAccount();

  React.useEffect(() => {
    if (isConnected && loadedInitialList == true) {
      getV3NftInfo(nftId).then((res) => {
        setPoolData(res);
        console.log(res);
        getPairDayData(res['poolAddress'], res.pool.fee / 100).then(res_ => {
          //get boosted apy
          for(let i in allPairs){
            if(allPairs[i].id && allPairs[i].id.toLowerCase() == res['poolAddress'].toLowerCase()){
              if(allPairs[i].rewardTokens){
                res_['base'] = res_.apy
                res_.apy = new BigNumber(res_.apy).plus(allPairs[i].ampAPY ? allPairs[i].ampAPY : 0).toFixed(2)
                res_['boost'] = new BigNumber(allPairs[i].ampAPY ? allPairs[i].ampAPY : 0).toFixed(2)
                res_['rewardTokens'] = allPairs[i].rewardTokens
                res_['boosted'] = true
              }
            }
          }
          setPairInfo(res_)
        })
        setAssetA(res.token0);
        setAssetB(res.token1);
        setIsLoading(false);
      });
    }

    return () => {};
  }, [isConnected, loadedInitialList]);

  React.useEffect(() => {
    if (!poolData || !pairInfo) return;

    let res_ = pairInfo
    if(!res_['boosted'])
      for(let i in allPairs){
        if(allPairs[i].id && allPairs[i].id.toLowerCase() == poolData['poolAddress'].toLowerCase()){
          if(allPairs[i].rewardTokens){
            res_['base'] = res_.apy
            res_.apy = new BigNumber(res_.apy).plus(allPairs[i].ampAPY ? allPairs[i].ampAPY : 0).toFixed(2)
            res_['boost'] = new BigNumber(allPairs[i].ampAPY ? allPairs[i].ampAPY : 0).toFixed(2)
            res_['rewardTokens'] = allPairs[i].rewardTokens
            res_['boosted'] = true
          }
        }
      }

    setPairInfo(res_)
    return () => {};
  }, [allPairs]);

  React.useEffect(() => {
    if (poolData == null) setIsLoading(true);
    else setIsLoading(false);

    return () => {};
  }, [poolData, poolAPY]);

  const increaseLiquidity = async () => {
    setCalculating(true);

    try {
      var txRes = await sendTransactionGaEvent("add_lp_v3_transaction", category, () => {
        return increaseLiquidityV3(nftId, assetA, assetB, depositA, depositB, 1);
      });

      var res = await getV3NftInfo(nftId);
      setPoolData(res);
      setAssetA(res.token0);
      setAssetB(res.token1);

      if (txRes.success == true) {
        setGasSaved(txRes.gasSavedUSD!);
        setConfirmTime(txRes.time);
        settxHash(txRes.tx);
        setModal(Modal.SuccessIncrease);
      } else {
        closeModal();
      }
    } catch (e) {
      console.log(e);
      closeModal();
    }

    setCalculating(false);
  };

  const setWithdrawalAmountA = (val) => {
    setWithdrawalA(val);
    var burnPercentage = new BigNumber(poolData.position.liquidity.toString())
      .times(new BigNumber(val).div(getLiquidityPosition().token0))
      .toFixed(0);
    const position = new Position({
      pool: poolData.pool as Pool,
      liquidity: Number(burnPercentage),
      tickLower: Number(poolData.position.tickLower.toString()),
      tickUpper: Number(poolData.position.tickUpper.toString()),
    });

    const liqAmounts = position.burnAmountsWithSlippage(new Percent("0"));

    setWithdrawalB(
      new BigNumber(liqAmounts.amount1.toString()).div(Math.pow(10, poolData.pool.token1.decimals)).toFixed(),
    );
    setWithdrawalLiquidity(burnPercentage);
  };

  const setWithdrawalAmountB = (val) => {
    setWithdrawalB(val);
    var burnPercentage = new BigNumber(poolData.position.liquidity.toString())
      .times(new BigNumber(val).div(getLiquidityPosition().token1))
      .toFixed(0);
    const position = new Position({
      pool: poolData.pool as Pool,
      liquidity: Number(burnPercentage),
      tickLower: Number(poolData.position.tickLower.toString()),
      tickUpper: Number(poolData.position.tickUpper.toString()),
    });

    const liqAmounts = position.burnAmountsWithSlippage(new Percent("0"));

    setWithdrawalA(
      new BigNumber(liqAmounts.amount0.toString()).div(Math.pow(10, poolData.pool.token0.decimals)).toFixed(),
    );
    setWithdrawalLiquidity(burnPercentage);
  };

  const removeLiquidity = async () => {
    setCalculating(true);
    try {
      var txRes = await sendTransactionGaEvent("remove_lp_v3_transaction", category, () => {
        return removeLiquidityV3(nftId, assetA, assetB, withdrawalLiquidity, withdrawalA, withdrawalB, 1);
      });


      if (txRes.success == true) {
        var res = await getV3NftInfo(nftId);
        setPoolData(res);
        setAssetA(res.token0);
        setAssetB(res.token1);

        setGasSaved(txRes.gasSavedUSD!);
        setConfirmTime(txRes.time);
        settxHash(txRes.tx);
        setModal(Modal.SuccessRemove);
      } else {
        closeModal();
      }
    } catch (e) {
      console.log(e);
      closeModal();
    }

    setCalculating(false);
  };

  const setSlippageChange = async (r) => {
    setSlippage(r);
  };

  const payoutFees = async () => {
    setCalculating(true);
    setModal(Modal.Rewards);

    try {
      var res = await sendTransactionGaEvent("claim_lp_v3_transaction", category, () => {
        return claimPositionFees(nftId, assetA, assetB);
      });

      if (res.success == false) {
        setError(res.error);
        //setModal(Modal.Error)
        closeModal();
      } else {
        var nftRes = await getV3NftInfo(nftId);
        setPoolData(nftRes);
        setAssetA(nftRes.token0);
        setAssetB(nftRes.token1);

        setGasSaved(res.gasSavedUSD!);
        setConfirmTime(res.time);
        settxHash(res.tx);
        setModal(Modal.SuccessRewards);
      }
    } catch (e) {
      console.log(e);
      closeModal();
    }

    setCalculating(false);
  };

  const poolSVG = useMemo(() => {
    if (poolData) {
      var base64 = poolData.uri.replace("data:application/json;base64,", "");
      var decodedStringAtoB = atob(base64);

      var svgText = atob(JSON.parse(decodedStringAtoB).image.replace("data:image/svg+xml;base64,", ""));
      return `data:image/svg+xml;utf8,${encodeURIComponent(svgText)}`;
    }
    return "";
  }, [poolData]);

  const getLiquidityPosition = () => {
    if (poolData) {
      const position = new Position({
        pool: poolData.pool as Pool,
        liquidity: poolData.position.liquidity.toString(),
        tickLower: Number(poolData.position.tickLower.toString()),
        tickUpper: Number(poolData.position.tickUpper.toString()),
      });

      //const liqAmounts = position.burnAmountsWithSlippage(new Percent("0"))
      const liqAmounts = position.mintAmounts;

      return {
        token0: new BigNumber(liqAmounts.amount0.toString()).div(Math.pow(10, poolData.pool.token0.decimals)).toFixed(),
        token1: new BigNumber(liqAmounts.amount1.toString()).div(Math.pow(10, poolData.pool.token1.decimals)).toFixed(),
      };
    }

    return {
      token0: 0,
      token1: 0,
    };
  };

  const getPriceRange = () => {
    if (poolData) {
      const tick_spacing = getFeeToTickSpacing(Number(poolData.pool.fee.toString()));

      var tokenText;

      if (useToken0) tokenText = ` ${poolData.token1.symbol} per ${poolData.token0.symbol}`;
      else tokenText = ` ${poolData.token0.symbol} per ${poolData.token1.symbol}`;

      if (
        new BigNumber(TickMath.MAX_TICK).minus(tick_spacing).lt(poolData.position.tickUpper.toString()) &&
        new BigNumber(TickMath.MIN_TICK).plus(tick_spacing).gt(poolData.position.tickLower.toString())
      ) {
        return {
          min: formatNumber("0", 2) + tokenText,
          max: "∞" + tokenText,
        };
      }

      const position = new Position({
        pool: poolData.pool as Pool,
        liquidity: Number(poolData.position.liquidity.toString()),
        tickLower: Number(poolData.position.tickLower.toString()),
        tickUpper: Number(poolData.position.tickUpper.toString()),
      });

      if (useToken0)
        return {
          min: position.token0PriceLower.toSignificant() + tokenText,
          max: position.token0PriceUpper.toSignificant() + tokenText,
        };
      else
        return {
          min: formatNumber(new BigNumber(1).div(position.token0PriceUpper.toFixed(12)), 2) + tokenText,
          max: formatNumber(new BigNumber(1).div(position.token0PriceLower.toFixed(12)), 2) + tokenText,
        };
    }

    return {
      min: "0",
      max: "0",
    };
  };

  const getCurrentPrice = () => {
    if (poolData) {
      const _pool = poolData.pool as Pool;

      var tokenText = "";

      if (useToken0) tokenText = ` ${poolData.token1.symbol} per ${poolData.token0.symbol}`;
      else tokenText = ` ${poolData.token0.symbol} per ${poolData.token1.symbol}`;

      if (useToken0) return _pool.token0Price.toSignificant() + tokenText;
      else return _pool.token1Price.toSignificant() + tokenText;
    }

    return "0";
  };

  const getTVL = () => {
    if (poolTVL != "0") return poolTVL;

    return 0;

    /*
    (poolData.pool as Pool).
    if (new BigNumber(assetA.price).gt(0))
      return new BigNumber(poolData.assetATotal).times(assetA.price).times(2).toFixed();

    if (new BigNumber(assetB.price).gt(0))
      return new BigNumber(poolData.assetBTotal).times(assetB.price).times(2).toFixed();

    */
    return 0;
  };

  const getMerkleRewards = () => {

  }

  const getRewards = () => {
    var _merkl: any = []

    for(let i in merkleRewards['tokens']){
      var _token = getTokenFromContract(merkleRewards['tokens'][i])
      var _amount =  new BigNumber(merkleRewards['totalTokens'][i]).div(Math.pow(10, _token.decimals)).toFixed()
      _merkl.push([_token, _amount])
    } 

    return [

      [assetA, formatNumber(poolData ? poolData.fees.token0 : 0, 2)],
      [assetB, formatNumber(poolData ? poolData.fees.token1 : 0, 2)],
      ..._merkl
    ]
  }

  const modifyDepositA = (val) => {
    setDepositA(val);

    const _pool = poolData.pool as Pool;

    const position = Position.fromAmount0({
      pool: _pool,
      tickLower: Number(poolData.position.tickLower.toString()),
      tickUpper: Number(poolData.position.tickUpper.toString()),
      amount0: new BigNumber(val).times(Math.pow(10, _pool.token0.decimals)).toFixed(0),
      useFullPrecision: true,
    });

    setDepositB(
      new BigNumber(position.mintAmounts.amount1.toString()).div(Math.pow(10, _pool.token1.decimals)).toFixed(),
    );
  };

  const modifyDepositB = (val) => {
    setDepositB(val);

    const _pool = poolData.pool as Pool;

    const position = Position.fromAmount1({
      pool: _pool,
      tickLower: Number(poolData.position.tickLower.toString()),
      tickUpper: Number(poolData.position.tickUpper.toString()),
      amount1: new BigNumber(val).times(Math.pow(10, _pool.token1.decimals)).toFixed(0),
    });

    setDepositA(
      new BigNumber(position.mintAmounts.amount0.toString()).div(Math.pow(10, _pool.token0.decimals)).toFixed(),
    );
  };

  return (
    <Box noBreak column>
      <Layout>
        <Box style={{gridArea: "header"}}>
            <Box className={lavaEffectHoverClassName} column>
              <LavaChip
                loading={!pairInfo}
                heading="Liquidity"
                value={`$${numberWithCommas(pairInfo ? pairInfo.tvl : 0, 2)}`}
                alt={1}
                leader
              />
              {/*
              <LavaChip
                loading={isLoading}
                heading="APY"
                value={`${numberWithCommas(100, 2)}%`}
                alt={2}
                follower
              />
              */}

            </Box>
            <Box className={lavaEffectHoverClassName} column>
              <LavaChip
                loading={!pairInfo}
                heading={
                  pairInfo && pairInfo['rewardTokens'] && pairInfo['rewardTokens'].length > 0 ? 
                  (
                    <Tooltip position="right" content={`This pool is boosted with additional rewards. \nBase: ${pairInfo['base']}% \nBoosted: ${pairInfo['boost']}%`}>
                    <Flex>
                      <Text>APY is Boosted</Text>
                      <MdOutlineBolt color="rgba(var(--color-mp-c200))" size={20}/>
                      <RewardToken>
                      {pairInfo && pairInfo['rewardTokens'] ? pairInfo!['rewardTokens'].map((item, index) => {
                        return (
                            <IconAsset style={{marginLeft: '5px'}} contrast asset={{logo: item.logo ? item.logo : unknownToken, symbol: item.symbol} as any} size="s"/>
                        )
                      }) : <></>}
                      </RewardToken>
                    
                    </Flex>

                  </Tooltip>
                  ) : <Text>APY</Text>
                }
                value={`${numberWithCommas(pairInfo ? pairInfo.apy : 0, 2)}%`}
                alt={1}
                palette="purple"
                leader
              />
              {/*
              <LavaChip
                loading={isLoading}
                heading="Fees 24h"
                value={`$${numberWithCommas(1000, 2)}`}
                alt={2}
                palette="purple"
                follower
              />
              */}
            </Box>
        </Box>
        <Flex style={{gridArea: "titlecontainer"}} justify="space-between">
          <Title
            style={{gridArea: "titlecontainer"}}
            breakpoint={940}
            goBackLink="/pool?id=1"
            extend
            breakContent
            loading={isLoading || !assetA || !assetB}
            title={
              <>
                {assetA ? assetA.symbol : "---"} <TitleDash /> {assetB ? assetB.symbol : "---"}
              </>
            }
            chip={`${poolData ? Number(poolData.pool.fee.toString() / 10000).toFixed(2) + "%" : ""}`}
          >
            <Flex gap={2} align="center">
              <Button size="s" onClick={() => setModal(Modal.Add)}>
                Add
              </Button>
              <Button size="s" onClick={() => setModal(Modal.Remove)}>
                Remove
              </Button>
            </Flex>
          </Title>
        </Flex>

        <CustomModal
          maxWidth={520}
          isVisible={Modal.Add == modal}
          onClose={closeModal}
          title="Increase liquidity"
          subtitle="Add to your liquidity position based on your price range"
        >
          <Flex gap={4} column>
            <InputCoin value={depositA} setValue={modifyDepositA} selectedAsset={assetA} fixedAsset />
            <InputCoin value={depositB} setValue={modifyDepositB} selectedAsset={assetB} fixedAsset />
            <Button onClick={increaseLiquidity}>
              Increase
              {calculating && (
                <>
                  <Divider horizontal size={2} />
                  <Loading currentColor />
                </>
              )}
            </Button>
          </Flex>
        </CustomModal>

        <CustomModal
          maxWidth={520}
          isVisible={Modal.Remove == modal}
          onClose={closeModal}
          title="Remove liquidity"
          subtitle="Remove your liquidity amounts"
        >
          <Flex gap={4} column>
            <InputCoin
              value={withdrawalA}
              setValue={setWithdrawalAmountA}
              selectedAsset={{...assetA, balance: getLiquidityPosition().token0}}
              fixedAsset
            />
            <InputCoin
              value={withdrawalB}
              setValue={setWithdrawalAmountB}
              selectedAsset={{...assetB, balance: getLiquidityPosition().token1}}
              fixedAsset
            />
            <Button onClick={removeLiquidity}>
              Remove
              {calculating && (
                <>
                  <Divider horizontal size={2} />
                  <Loading currentColor />
                </>
              )}
            </Button>
          </Flex>
        </CustomModal>

        <LiquiditySettingsModal
          isVisible={modal === Modal.Settings}
          onClose={() => setModal(null)}
          defaultLimit={settingPoolFee}
          defaultLimitSlippage={slippage}
          onLimitChange={setSettingPoolFee}
          onLimitChangeSlippage={setSlippage}
          disableFee={poolData ? poolData.available == true : false}
        />

        <Flex style={{gridArea: "exchange"}} gap={2} grow>
          {/* 
                <LPOnFarmBanner hasLP={!!+poolData?.balance} tokenAddressA={assetA?.address} tokenAddressB={assetB?.address}/>
            */}
          <DataContainer extend>
            <CardContent.Title title="Rewards"/>
            <Scroll padding="0 0 4" parentPadding="3 3" responsive height={115}>

            <Flex column>
              {getRewards().map(([asset, amount]) => (
                <>
                  <Card loading={isLoading} size="m" border="s">
                    <Flex align="center" gap={2}>
                      <IconAsset button size="s" asset={asset} />
                      <Text h6>{asset?.symbol}</Text>
                      <Flex.Grow />
                      <Text bodyMedium>
                        {poolData ? amount : "0.00"}{" "}
                        <Text inline color="gray400">
                          {asset?.symbol}
                        </Text>
                      </Text>
                    </Flex>
                  </Card>
                  <Divider size={2} />
                </>
              ))}
            </Flex>

            </Scroll>

            <Message info>
            Boosted rewards can be delayed by up to 24 hours. 
            </Message>
            <Divider size={4} />

            <Button alt full onClick={() => payoutFees()} disabled={poolData ? !poolData.fees.claimable : true}>
              <LoadingAnimation isLoading={calculating} />
              Collect
            </Button>
          </DataContainer>
        </Flex>

        {!mobile && (
          <Flex style={{gridArea: "nft_image"}} grow>
            <Skeleton variant="contrast" br="xl" width={261} height={450} active={!poolSVG}>
              <NftImage src={poolSVG} />
            </Skeleton>
          </Flex>
        )}

        <Flex style={{gridArea: "total_assets"}} gap={3} column>
          <DataContainer extend>
            <CardContent.Title title="My liquidity" />
            <CardContent.Content>
              <Flex gap={2} column>
                {[
                  [assetA, getLiquidityPosition().token0],
                  [assetB, getLiquidityPosition().token1]
                ].map(([asset, amount]) => (
                  <Card loading={isLoading} size="m" border="s">
                    <Flex align="center" gap={2}>
                      <IconAsset button size="s" asset={asset} />
                      <Text h6>{asset?.symbol}</Text>
                      <Flex.Grow />
                      <Text bodyMedium>
                        {poolData ? formatPrice(amount, true, 5, 2) : "0.00"}{" "}
                        <Text inline color="gray400">
                          {asset?.symbol}
                        </Text>
                      </Text>
                    </Flex>
                  </Card>
                ))}
              </Flex>
            </CardContent.Content>
          </DataContainer>
        </Flex>

        <DataContainer area="pool_details">
          <CardContent.Title title="Price range">
            {isLoading || (
              <Tabs
                selected={useToken0}
                onSelect={(val) => setUseToken0(val)}
                micro
                tabs={[
                  {value: false, label: poolData ? String(poolData.pool.token0.symbol) : ""},
                  {value: true, label: poolData ? String(poolData.pool.token1.symbol) : ""},
                ]}
              />
            )}
          </CardContent.Title>
          <CardContent.Content>
            {isLoading || (
              <>
                <Divider size={2} />
                <PriceRange
                  rangeFrom={extractNumber(getPriceRange().min)}
                  rangeTo={extractNumber(getPriceRange().max)}
                  price={extractNumber(getCurrentPrice())}
                />
                <Divider size={6} />
              </>
            )}

            <StatRow>
              <StatText bodyRegular color="gray400">
                Min price
              </StatText>
              <StatTextHighlight bodyMedium>
                {isLoading == true ? <Loading /> : poolData ? getPriceRange().min : "0"}
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText bodyRegular color="gray400">
                Max price
              </StatText>
              <StatTextHighlight bodyMedium>
                {isLoading == true ? <Loading /> : poolData ? getPriceRange().max : "0"}
              </StatTextHighlight>
            </StatRow>

            <StatRow>
              <StatText bodyRegular color="gray400">
                Current price
              </StatText>
              <StatTextHighlight bodyMedium>
                {isLoading == true ? <Loading /> : poolData ? getCurrentPrice() : "0"}
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText bodyRegular color="gray400">
                Pool address
              </StatText>
              <StatTextHighlight bodyMedium>
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    {poolData ? reduceString(poolData.poolAddress, 7, 4) : ""}
                    <LaunchIcon
                      style={{
                        width: "15px",
                        marginLeft: "5px",
                        color: "rgb(var(--color-overgrown))",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.open("https://era.zksync.network/address/" + poolData.poolAddress, "_blank");
                      }}
                    />
                  </>
                )}
              </StatTextHighlight>
            </StatRow>
          </CardContent.Content>
        </DataContainer>
      </Layout>

      {assetA && assetB ? (
        <>
          <ConfirmTransactionModal
            isVisible={modal === Modal.Rewards}
            title={"Claim Rewards"}
            onClose={closeModal}
            onConfirm={() => {}}
            leftValue={
              formatNumber(poolData ? poolData.fees.token0 : 0, 2) +
              " " +
              (assetA ? (assetA.symbol == "ETH" ? "WETH" : assetA.symbol) : "")
            }
            rightValue={
              formatNumber(poolData ? poolData.fees.token1 : 0, 2) +
              " " +
              (assetA ? (assetB.symbol == "ETH" ? "WETH" : assetB.symbol) : "")
            }
            leftIcon={assetA ? assetA.logo : unknownToken}
            rightIcon={assetB ? assetB.logo : unknownToken}
            icon={crossLight}
            isLP={true}
            deposit={isDeposit}
            footer={
              <CustomModal.Rows>
                <TxModal>
                  {(w) => (
                    <>
                      <CustomModal.Row title="Gas Cost Estimate">{w("$" + gasRefund.actual)}</CustomModal.Row>
                      <CustomModal.Row title="Gas Refund Estimate">{w("$" + gasRefund.refund)}</CustomModal.Row>
                    </>
                  )}
                </TxModal>
              </CustomModal.Rows>
            }
          />

          <SuccessfulSubmitModal
            isVisible={modal === Modal.SuccessRewards}
            onClose={closeModal}
            title={"Claim Rewards Successful!"}
            description="Funds are avaliable in your wallet."
            savings={"$" + currentSwapCost()}
            costs={"$0.00"}
            onSubmit={() => {}}
            symbolIn={""}
            iconIn={""}
            symbolOut={""}
            iconOut={""}
            amountIn={""}
            amountOut={""}
            section={
              <CustomModal.Rows>
                <TxModal>
                  {(w) => (
                    <>
                      <CustomModal.Row title="Refunded gas fees" color="success">
                        {w("$" + gasSaved)}
                      </CustomModal.Row>
                    </>
                  )}
                </TxModal>
                <CustomModal.Row title="Confirmation time">{confirmTime}s</CustomModal.Row>
                <CustomModal.Row title="Tx hash">
                  <TxButton onClick={() => window.open("https://era.zksync.network/tx/" + txHash, "_blank")}>
                    {reduceString(txHash, 6, 4)}
                  </TxButton>
                </CustomModal.Row>
              </CustomModal.Rows>
            }
          />

          <SuccessfulSubmitModal
            isVisible={modal === Modal.SuccessIncrease}
            onClose={closeModal}
            title={"Increase Liquidity Successful!"}
            description="Your position has increased."
            savings={"$" + currentSwapCost()}
            costs={"$0.00"}
            onSubmit={() => {}}
            symbolIn={""}
            iconIn={""}
            symbolOut={""}
            iconOut={""}
            amountIn={""}
            amountOut={""}
            section={
              <CustomModal.Rows>
                <TxModal>
                  {(w) => (
                    <>
                      <CustomModal.Row title="Refunded gas fees" color="success">
                        {w("$" + gasSaved)}
                      </CustomModal.Row>
                    </>
                  )}
                </TxModal>
                <CustomModal.Row title={`${assetA?.symbol} deposited`}>
                  {formatNumber(new BigNumber(depositA), 2)} {assetA ? assetA.symbol : ""}
                </CustomModal.Row>
                <CustomModal.Row title={`${assetB?.symbol} deposited`}>
                  {formatNumber(new BigNumber(depositB), 2)} {assetB ? assetB.symbol : ""}
                </CustomModal.Row>
                <CustomModal.Row title="Confirmation time">{confirmTime}s</CustomModal.Row>
                <CustomModal.Row title="Tx hash">
                  <TxButton onClick={() => window.open("https://era.zksync.network/tx/" + txHash, "_blank")}>
                    {reduceString(txHash, 6, 4)}
                  </TxButton>
                </CustomModal.Row>
              </CustomModal.Rows>
            }
          />

          <SuccessfulSubmitModal
            isVisible={modal === Modal.SuccessRemove}
            onClose={closeModal}
            title={"Remove Liquidity Successful!"}
            description="Your position has decreased."
            savings={"$" + currentSwapCost()}
            costs={"$0.00"}
            onSubmit={() => {}}
            symbolIn={""}
            iconIn={""}
            symbolOut={""}
            iconOut={""}
            amountIn={""}
            amountOut={""}
            section={
              <CustomModal.Rows>
                <TxModal>
                  {(w) => (
                    <>
                      <CustomModal.Row title="Refunded gas fees" color="success">
                        {w("$" + gasSaved)}
                      </CustomModal.Row>
                    </>
                  )}
                </TxModal>
                <CustomModal.Row title={`${assetA?.symbol} removed`}>
                  {formatNumber(new BigNumber(withdrawalA), 2)} {assetA ? assetA.symbol : ""}
                </CustomModal.Row>
                <CustomModal.Row title={`${assetB?.symbol} removed`}>
                  {formatNumber(new BigNumber(withdrawalB), 2)} {assetB ? assetB.symbol : ""}
                </CustomModal.Row>
                <CustomModal.Row title="Confirmation time">{confirmTime}s</CustomModal.Row>
                <CustomModal.Row title="Tx hash">
                  <TxButton onClick={() => window.open("https://era.zksync.network/tx/" + txHash, "_blank")}>
                    {reduceString(txHash, 6, 4)}
                  </TxButton>
                </CustomModal.Row>
              </CustomModal.Rows>
            }
          />
        </>
      ) : (
        <></>
      )}

      <ErrorModal isVisible={modal === Modal.Error} onClose={closeModal} title="LP error!" description={error} />
    </Box>
  );
};

const Layout = styled(Grid)`
  grid-template-columns: 261px 1fr;
  grid-template-areas:
    "titlecontainer titlecontainer"
    "header header"
    "nft_image total_assets"
    "nft_image  exchange"
    "pool_details pool_details";

  row-gap: 12px;
  column-gap: 12px;
  max-width: 860px;
  width: 860px;
  margin: auto;

  @media (max-width: 940px) {
    max-width: 520px;
    width: 100%;
    margin: auto;
    grid-template-columns: 1fr;
    grid-template-areas:
      "titlecontainer"
      "header"
      "exchange"
      "total_assets"
      "pool_details";
  }
`;

const StatRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
`;

const StatText = styled(Text)`
  display: flex;
  align-items: center;
  text-align: right;
`;

const StatTextHighlight = styled(Text)`
  text-align: right;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const DataContainer = styled(CardContent)<{area?: string; extend?: boolean}>`
  grid-area: ${({area}) => area};

  ${(_) => _.extend && `flex: 1;`}
`;

const DataContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 80px;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const SettingAnim = keyframes`
 0% { transform: rotate(0deg); }
 100% { transform: rotate(60deg); }
`;

const TxButton = styled.button`
  cursor: pointer;
  color: black;
  font-weight: 500;
  z-index: 5;
  position: relative;
`;

const NftImage = styled.img`
  max-height: 1000px;
`;


const RewardToken = styled.div`
  position: absolute;
  right: -75px;
  top: -10px;
  display: flex;
  flex-direction: row;
`;
