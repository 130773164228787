import styled from "styled-components";

import {card, palette, size, text, transition, typo} from "../../styles";

export const Tooltip = styled.div<{primary?: boolean; align?: AlignSetting}>`
  ${size.padding(2, 3)}
  ${text.bodyRegular}
  ${(_) => _.primary && typo.primary.normal}
  ${card("xs", false, "transparent", false)}
  ${transition.normal}

  text-align: ${(_) => _.align || "left"};
  background: ${palette.gray50};
  color: ${palette.black};
  position: absolute;
  max-width: 220px;
  width: max-content;
  top: 100%;
  margin-top: 0;
  transform: translateX(-50%) scale(0.8);
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  white-space: pre-line;
  // backdrop-filter: blur(2px);
`;

export const Wrapper = styled.span<{position?: "right" | "left"}>`
  position: relative;
  display: inline-block;

  ${_ => {
    const x = _.position === "right" ? "0" : _.position === "left" ? "0" : "-50%";
    return `
      ${Tooltip} {
        transform: translateX(${x}) scale(0.8);
        transform-origin: ${_.position === "right" ? "0%" : _.position === "left" ? "100%" : "50%"} 0%;
        ${_.position === "right"
            ? "left: 0;"
            : _.position === "left"
              ? "right: 0;"
              : "left: 50%;"
        };
      }
      &:hover ${Tooltip} {
        transform: translateX(${x}) scale(1);
        opacity: 1;
        margin-top: ${size(1)};
      }
    `;
  }}
`;
