import {memo} from "react";
import {withColor} from "../-with-color";
import {Icon, Icons} from "../icon";

import * as SC from "./index.styles";

interface ChipFancyProps {
  icon: Icons;
  content: string;
  small?: boolean;
}

export const ChipFancy = memo(
  withColor(function ChipFancyComponent(props: ChipFancyProps) {
    const {icon, content, ...rest} = props;
    return (
      <SC.Wrapper {...rest}>
        <SC.Icon>
          <Icon icon={icon} color="c300" contrast background="c100" size="xs" />
        </SC.Icon>
        <SC.Content>{content}</SC.Content>
      </SC.Wrapper>
    );
  }),
);

export const GasLessChip = memo((props: Pick<ChipFancyProps, "small">) => {
  return <ChipFancy icon="gas" content="Gas-less" {...props} />;
});
