import React, {useState, useRef} from "react";
import styled, {keyframes} from "styled-components";
import {useLocation, useHistory, useParams} from "react-router-dom";
import BigNumber from "bignumber.js";
import {useAccount} from "wagmi";

import {Grid} from "../../common/layout/Grid";
import {LiquiditySettingsModal} from "./LiquiditySettingsModal";
import {LiquidityFeeModal} from "./LiquidityFeeModal";
import {LPOnFarmBanner} from "./LPOnFarmBanner";

import {getAmountOut} from "../../web3/helper";

import LaunchIcon from "@mui/icons-material/Launch";

import unknownToken from "../../assets/images/icons/unknown_token.svg";
import {ShadowWidget} from "../../common/ShadowWidget";

import {reduceString, formatNumber, getCleanNumber, sendTransactionGaEvent} from "../../utils";
import {LoadingBox} from "../../common/loading/LoadingBox";
import {ConfirmTransactionModal} from "../../common/modal/ConfirmTransactionModal";
import crossLight from "../../assets/images/icons/cross.svg";
import {SuccessfulSubmitModal} from "../../common/modal/SuccessfulSubmitModal";
import {ErrorModal} from "../../common/modal/ErrorModal";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Flex,
  IconAsset,
  lavaEffectHoverClassName,
  layoutMq,
  Loading,
  Title,
  TitleDash,
  Modal as CustomModal,
} from "../../ui/components";
import {Icon, InputCoin, Tabs, Button, LavaChip, Text} from "../../ui/components";
import {useWalletHook} from "../../web3/walletHook";
import {WalletState} from "../../state";

import {useWalletSelector} from "../../state/hooks";
import {br, palette, size, text} from "../../ui/styles";
import {TxModal} from "../+common/TxModal";

interface ParamTypes {
  inputCurrency: string;
  outputCurrency: string;
  stable: string;
}

enum Modal {
  Settings,
  ChangeFee,
  Confirm,
  Switch,
  Connect,
  Slipage,
  Error,
  Deposit,
  Withdraw,
  Success,
  Rewards,
  SuccessRewards,
}

export const LiquidityPoolSubScreen = ({poolInfo}) => {
  let location = useLocation();
  const history = useHistory();
  const {inputCurrency, outputCurrency, stable} = useParams<ParamTypes>();

  const [assetA, setAssetA] = useState<any>(undefined);
  const [assetB, setAssetB] = useState<any>(undefined);

  const [assetAAmount, setAssetAAmount] = useState("0");
  const [assetBAmount, setAssetBAmount] = useState("0");
  const [assetLPAmountWithdraw, setAssetLPAmountWithdraw] = useState("0");

  const [settingPoolFee, setSettingPoolFee] = useState(stable == "true" ? 0.05 : 0.3);

  const [poolAPY, setPoolAPY] = useState("0");
  const [poolTVL, setPoolTVL] = useState("0");

  const [poolData, setPoolData] = useState<any>(null);

  const [isDeposit, setIsDeposit] = useState(0);
  const [calculating, setCalculating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const tokens = useWalletSelector((state: WalletState) => state.tokens);

  const [modal, setModal] = useState<Modal | null>(null);
  const closeModal = () => setModal(null);
  const [error, setError] = useState("");
  const [slippage, setSlippage] = useState(0.5);
  const [gasSaved, setGasSaved] = useState("");
  const [confirmTime, setConfirmTime] = useState("0.00");
  const [txHash, settxHash] = useState("");

  const gasRefund = useWalletSelector((state: WalletState) => state.gasEstimateRefund);

  const createLiqModal = useRef<any>(null);
  const addLiqModal = useRef<any>(null);
  const removeLiqModal = useRef<any>(null);

  const {
    getTokenFromContract,
    getTokenInformation,
    searchForLiquidity,
    getPairDayData,
    createLiquidityPool,
    removeLiquidityPermit,
    removeLiquidityWithPermit,
    ChangePoolFee,
    searchForLiquidityAndUpdate,
    claimPoolFees,
    currentSwapCost,
    loadedInitialList,
  } = useWalletHook();
  const category = [assetA!?.symbol, assetB!?.symbol].join();

  const {isConnected} = useAccount();

  React.useEffect(() => {
    if (isConnected && loadedInitialList == true) {
      let inputCurrencyTemp = inputCurrency;
      let outputCurrencyTemp = outputCurrency;

      if (inputCurrencyTemp.toLowerCase() == "eth") inputCurrencyTemp = "0x000000000000000000000000000000000000800a";

      if (outputCurrencyTemp.toLowerCase() == "eth") outputCurrencyTemp = "0x000000000000000000000000000000000000800a";

      var _assetA = getTokenFromContract(inputCurrencyTemp);
      var _assetB = getTokenFromContract(outputCurrencyTemp);

      if (_assetA.symbol != null && JSON.stringify(_assetA) != JSON.stringify(assetA)) setAssetA(_assetA);
      else if (!_assetA.symbol)
        getTokenInformation(inputCurrencyTemp).then((res) => {
          setAssetA(res);
        });

      if (_assetB.symbol != null && JSON.stringify(_assetB) != JSON.stringify(assetB)) setAssetB(_assetB);
      else if (!_assetB.symbol)
        getTokenInformation(outputCurrencyTemp).then((res) => {
          setAssetB(res);
        });
    }

    return () => {};
  }, [isConnected, loadedInitialList]);

  React.useEffect(() => {
    if (assetB && assetA) {
      if (poolData == null) {
        searchForLiquidity(assetA, assetB, 0, stable == "true").then((_poolData) => {
          setPoolData(_poolData);
          setIsLoading(false);
          if (_poolData.available == true) {
            setSettingPoolFee(new BigNumber(_poolData.pairFeePercent).toNumber());
            getPairDayData(_poolData.address, new BigNumber(_poolData.pairFeePercent).times(100).toNumber()).then(
              (dayData) => {
                try {
                  setPoolAPY(dayData.apy);
                  setPoolTVL(dayData.tvl);
                } catch (e) {
                  console.log(e);
                }
              },
            );
          }
        });
      }
    }

    return () => {};
  }, [assetA, assetB]);

  React.useEffect(() => {
    if (poolData == null) setIsLoading(true);
    else setIsLoading(false);

    return () => {};
  }, [poolData, poolAPY]);

  const getDepositButtonStatus = () => {
    if (new BigNumber(assetAAmount).lte(0) || new BigNumber(assetBAmount).lte(0)) return "Invalid amounts";

    if (assetA && assetB) {
      if (new BigNumber(assetAAmount).gt(assetA.balance) || new BigNumber(assetBAmount).gt(assetB.balance))
        return "Invalid amounts";

      if (poolData) {
        if (poolData.approveAssetA || poolData.approveAssetB) {
          return "Approve";
        }
      }

      return "Add liquidity";
    }

    return "Invalid amounts";
  };

  const getWithdrawButtonStatus = () => {
    if (new BigNumber(assetLPAmountWithdraw).lte(0)) return "Invalid amounts";

    if (poolData) {
      if (new BigNumber(assetLPAmountWithdraw).gt(poolData.balance)) return "Invalid amounts";

      if (poolData.approveAssetLP || new BigNumber(poolData.approveLPValue).lt(assetLPAmountWithdraw)) {
        return "Approve";
      }
    }

    return "Withdraw liquidity";
  };

  const shouldDisable = () => {
    if (getDepositButtonStatus() == "Add liquidity" || getDepositButtonStatus() == "Approve") return false;

    return true;
  };

  const shouldDisableWithdraw = () => {
    if (getWithdrawButtonStatus() == "Withdraw liquidity" || getWithdrawButtonStatus() == "Approve") return false;

    return true;
  };

  const provideLiquidity = async () => {
    setModal(Modal.Deposit);
    setCalculating(true);

    const res = await sendTransactionGaEvent("add_lp_transaction", category, () => {
      return createLiquidityPool(
        assetA,
        assetB,
        assetAAmount,
        assetBAmount,
        new BigNumber(settingPoolFee).times(100).toNumber(),
        stable == "true",
        slippage,
        poolData.address,
      );
    });

    if (res.success == false) {
      setError(res.error);
      //setModal(Modal.Error)
      setModal(null);
    } else {
      setGasSaved(res.gasSavedUSD!);
      setConfirmTime(res.time);
      settxHash(res.tx);
      setPoolData(await searchForLiquidityAndUpdate(assetA, assetB, 0, stable == "true"));
      setModal(Modal.Success);
    }
    setCalculating(false);
  };

  const withdrawLiquidity = async () => {
    setCalculating(true);
    setModal(Modal.Deposit);
    /*
    if(poolData.approveAssetLP){
      await Wallet.removeLiquidityPermit(assetA.address, assetB.address, poolData.address)
    }
    */

    const res = await sendTransactionGaEvent("remove_lp_transaction", category, async () => {
      if (new BigNumber(poolData.approveLPValue).lt(assetLPAmountWithdraw)) {
        await removeLiquidityPermit(assetA.address, assetB.address, poolData.address);
      }

      let minAmountA = new BigNumber(100)
        .minus(slippage)
        .times(new BigNumber(poolData.assetAAmount).times(assetLPAmountWithdraw).div(poolData.balance))
        .div(100)
        .toNumber();
      let minAmountB = new BigNumber(100)
        .minus(slippage)
        .times(new BigNumber(poolData.assetBAmount).times(assetLPAmountWithdraw).div(poolData.balance))
        .div(100)
        .toNumber();

      return removeLiquidityWithPermit(
        assetA,
        assetB,
        assetLPAmountWithdraw,
        "",
        stable == "true",
        minAmountA,
        minAmountB,
      );
    });

    if (res.success == false) {
      setError(res.error);
      //setModal(Modal.Error)
      setModal(null);
    } else {
      setGasSaved(res.gasSavedUSD!);
      setConfirmTime(res.time);
      settxHash(res.tx);
      setPoolData(await searchForLiquidityAndUpdate(assetA, assetB, 0, stable == "true"));
      setModal(Modal.Success);
    }

    setCalculating(false);
  };

  const minReceivedA = () => {
    if (poolData) {
      let minAmountA = new BigNumber(100)
        .minus(slippage)
        .times(
          new BigNumber(poolData.assetAAmount)
            .times(assetLPAmountWithdraw)
            .div(poolData && Number(poolData.balance) != 0 ? poolData.balance : 1),
        )
        .div(100)
        .toNumber();
      return getCleanNumber(minAmountA);
    }

    return "0.00";
  };

  const minReceivedB = () => {
    if (poolData) {
      let minAmountB = new BigNumber(100)
        .minus(slippage)
        .times(
          new BigNumber(poolData.assetBAmount)
            .times(assetLPAmountWithdraw)
            .div(poolData && Number(poolData.balance) != 0 ? poolData.balance : 1),
        )
        .div(100)
        .toNumber();
      return getCleanNumber(minAmountB);
    }

    return "0.00";
  };

  const setSlippageChange = async (r) => {
    setSlippage(r);
  };

  const changeAssetA = (val) => {
    setAssetAAmount(val);
    console.log(poolData);
    if (poolData.available == true)
      setAssetBAmount(new BigNumber(val).times(poolData.assetBTotal).div(poolData.assetATotal).toFixed());
  };

  const changeAssetB = (val) => {
    setAssetBAmount(val);
    if (poolData.available == true)
      setAssetAAmount(new BigNumber(val).times(poolData.assetATotal).div(poolData.assetBTotal).toFixed());
  };

  const changeLPWithdraw = (val) => {
    setAssetLPAmountWithdraw(String(val));
  };

  const changePoolFeeInternal = async (val) => {
    await ChangePoolFee(poolData.address, new BigNumber(val).times(100).toNumber());
    var _poolData = await searchForLiquidityAndUpdate(assetA, assetB, 0, stable == "true");
    setPoolData(_poolData);
    if (_poolData.available == true) {
      setSettingPoolFee(new BigNumber(_poolData.pairFeePercent).toNumber());
    }

    setModal(null);
  };

  const payoutFees = async () => {
    setCalculating(true);
    setModal(Modal.Rewards);

    const res = await sendTransactionGaEvent("claim_lp_transaction", category, () => {
      return claimPoolFees(poolData.address);
    });

    if (res.success == false) {
      setError(res.error);
      //setModal(Modal.Error)
      setModal(null);
    } else {
      setGasSaved(res.gasSavedUSD!);
      setConfirmTime(res.time);
      settxHash(res.tx);
      setModal(Modal.SuccessRewards);
      setPoolData(await searchForLiquidity(assetA, assetB, 0, stable == "true"));
    }

    setCalculating(false);
  };

  const getPoolPrice = () => {
    if (assetAAmount == "0" || assetBAmount == "0") return "0";
    if (stable == "true" && assetA && assetB) {
      var amOut = getAmountOut(new BigNumber(assetAAmount).div(100000).toNumber(), assetAAmount, assetBAmount);
      return amOut.times(new BigNumber(1).div(new BigNumber(assetAAmount).div(100000))).toFixed(4);
    } else {
      return new BigNumber(assetBAmount).div(assetAAmount).isNaN() == false
        ? new BigNumber(assetBAmount).div(assetAAmount).toFixed()
        : "0";
    }
  };

  const getTVL = () => {
    if (poolTVL != "0") return poolTVL;

    if (new BigNumber(assetA.price).gt(0))
      return new BigNumber(poolData.assetATotal).times(assetA.price).times(2).toFixed();

    if (new BigNumber(assetB.price).gt(0))
      return new BigNumber(poolData.assetBTotal).times(assetB.price).times(2).toFixed();

    return 0;
  };

  return (
    <Box noBreak column>
      <Layout>
        <Title
          style={{gridArea: "titlecontainer"}}
          breakpoint="800"
          goBackLink={`/pool?id=${new BigNumber(poolData && poolData.balance).gt(0) ? '1' : '0'}`}
          loading={isLoading || !assetA || !assetB}
          title={
            <>
              {assetA ? assetA.symbol : "---"} <TitleDash /> {assetB ? assetB.symbol : "---"}
            </>
          }
          chip={`${poolData ? (poolData.available ? (poolData.stable ? "Stable" : "Normal") : "NEW") : ""} Liquidity Pool`}
        />

        <LiquiditySettingsModal
          isVisible={modal === Modal.Settings}
          onClose={() => setModal(null)}
          defaultLimit={settingPoolFee}
          defaultLimitSlippage={slippage}
          onLimitChange={setSettingPoolFee}
          onLimitChangeSlippage={setSlippage}
          disableFee={poolData ? poolData.available == true : false}
        />

        <LiquidityFeeModal
          isVisible={modal === Modal.ChangeFee}
          onClose={() => setModal(null)}
          defaultLimit={settingPoolFee}
          execute={changePoolFeeInternal}
        />

        <Flex style={{gridArea: "exchange"}} gap={3} column>
          {/*<LPOnFarmBanner
            hasLP={!!+poolData?.balance}
            tokenAddressA={assetA?.address}
            tokenAddressB={assetB?.address}
          />*/}

          <CardContent>
            <CardContent.Content>
              <Divider size={1} />
              <Tabs
                onSelect={(val) => setIsDeposit(val)}
                standalone
                full
                tabs={[
                  {value: 0, label: "Deposit"},
                  {value: 1, label: "Withdraw"},
                ]}
              />

              <Divider size={4} />

              {isDeposit == 0 ? (
                <>
                  <InputCoin
                    value={Number(assetAAmount)}
                    setValue={changeAssetA}
                    assets={[]}
                    disableEdit={true}
                    selectedAsset={assetA}
                    onAssetSelect={() => {}}
                    price={assetA ? assetA.price : 0}
                    balance={assetA ? assetA.balance : 0}
                    disabled={false}
                  />
                  <Spacer />
                  <InputCoin
                    value={Number(assetBAmount)}
                    setValue={changeAssetB}
                    assets={[]}
                    disableEdit={true}
                    selectedAsset={assetB}
                    onAssetSelect={() => {}}
                    price={assetB ? assetB.price : 0}
                    balance={assetB ? assetB.balance : 0}
                    disabled={false}
                  />
                  <Divider size={3} />
                  <StatRow>
                    <StatText bodyRegular color="gray400">
                      Price
                    </StatText>
                    <StatTextHighlight bodyMedium>
                      {getCleanNumber(getPoolPrice())} {assetB ? assetB.symbol : ""} per {assetA ? assetA.symbol : ""}
                    </StatTextHighlight>
                  </StatRow>
                  <StatRow>
                    <StatText bodyRegular color="gray400">
                      Slippage
                    </StatText>
                    <StatTextHighlight bodyMedium>{new BigNumber(slippage).toFixed(2)}%</StatTextHighlight>
                  </StatRow>
                  <StatRow>
                    <StatText bodyRegular color="gray400">
                      Pool fee
                    </StatText>
                    <StatTextHighlight bodyMedium>{new BigNumber(settingPoolFee).toFixed(2)}%</StatTextHighlight>
                  </StatRow>
                  <Divider size={4} />
                  <Flex>
                    <Button
                      checkNetwork
                      full
                      loading={calculating}
                      onClick={() => {
                        provideLiquidity();
                      }}
                      disabled={shouldDisable()}
                    >
                      {getDepositButtonStatus()}
                    </Button>
                    <Divider horizontal size={3} />
                    <Flex justify="flex-end">
                      <Icon icon="settings" color="c300" size="m" button onClick={() => setModal(Modal.Settings)} />
                    </Flex>
                  </Flex>
                </>
              ) : (
                <></>
              )}
              {isDeposit == 1 ? (
                <>
                  <ShadowWidget>
                    <InputCoin
                      value={Number(assetLPAmountWithdraw)}
                      setValue={changeLPWithdraw}
                      disableEdit
                      selectedAsset={{
                        assets: [assetA, assetB],
                        amount: "0",
                        balance: new BigNumber(poolData ? poolData.balance : 0).toFixed(),
                        logo: assetA?.logo,
                        symbol: assetA?.symbol + "/" + assetB?.symbol + " LP",
                        name: "",
                        value: "",
                        decimals: 18,
                        price: "",
                      }}
                      price={poolData ? poolData.lpPrice : 0}
                      balance={new BigNumber(poolData ? poolData.balance : 0).toNumber()}
                    />
                  </ShadowWidget>

                  <Divider size={4} />
                  <Flex justify="flex-end" gap={4}>
                    <Icon icon="settings" color="c300" size="s" button onClick={() => setModal(Modal.Settings)} />
                  </Flex>
                  <Divider size={6} />

                  <StatRow>
                    <StatText bodyRegular color="gray400">
                      {assetA.symbol} received
                    </StatText>
                    <StatTextHighlight bodyMedium>
                      {getCleanNumber(
                        new BigNumber(poolData ? poolData.assetAAmount : 0)
                          .times(assetLPAmountWithdraw)
                          .div(poolData && Number(poolData.balance) != 0 ? poolData.balance : 1),
                      )}{" "}
                      {assetA.symbol}
                    </StatTextHighlight>
                  </StatRow>

                  <StatRow>
                    <StatText bodyRegular color="gray400">
                      {assetB.symbol} received
                    </StatText>
                    <StatTextHighlight bodyMedium>
                      {getCleanNumber(
                        new BigNumber(poolData ? poolData.assetBAmount : 0)
                          .times(assetLPAmountWithdraw)
                          .div(poolData && Number(poolData.balance) != 0 ? poolData.balance : 1),
                      )}{" "}
                      {assetB.symbol}
                    </StatTextHighlight>
                  </StatRow>

                  <StatRow>
                    <StatText bodyRegular color="gray400">
                      {assetA.symbol} min received
                    </StatText>
                    <StatTextHighlight bodyMedium>
                      {minReceivedA()} {assetA.symbol}
                    </StatTextHighlight>
                  </StatRow>

                  <StatRow>
                    <StatText bodyRegular color="gray400">
                      {assetB.symbol} min received
                    </StatText>
                    <StatTextHighlight bodyMedium>
                      {minReceivedB()} {assetB.symbol}
                    </StatTextHighlight>
                  </StatRow>

                  <Flex.Grow />

                  <Button
                    loading={calculating}
                    full
                    onClick={() => withdrawLiquidity()}
                    disabled={shouldDisableWithdraw()}
                  >
                    {getWithdrawButtonStatus()}
                  </Button>
                </>
              ) : (
                <></>
              )}
            </CardContent.Content>
          </CardContent>

          <CardContent>
            <CardContent.Title title="Rewards" />
            <CardContent.Content>
              <Flex>
                <RewardText>LP Reward</RewardText>
                {isLoading == true && !poolData ? (
                  <></>
                ) : (
                  <Rewards>
                    <RewardBody h6 color="black">
                      <IconAsset asset={assetA} button withBorder size={"xs"} />
                      {new BigNumber(poolData ? poolData.claimable0 : 0).toFixed(4)}
                      {" " + (assetA ? (assetA.symbol == "ETH" ? "WETH" : assetA.symbol) : "")}
                    </RewardBody>

                    <RewardBody h6 color="black">
                      <IconAsset asset={assetB} button withBorder size={"xs"} />
                      {new BigNumber(poolData ? poolData.claimable1 : 0).toFixed(4)}
                      {" " + (assetB ? (assetB.symbol == "ETH" ? "WETH" : assetB.symbol) : "")}
                    </RewardBody>
                  </Rewards>
                )}
              </Flex>
              <Divider size={6} />

              <Button
                checkNetwork
                alt
                full
                loading={calculating}
                onClick={() => payoutFees()}
                disabled={
                  poolData
                    ? new BigNumber(poolData.claimable0).lte(0) && new BigNumber(poolData.claimable1).lte(0)
                    : true
                }
              >
                Collect
              </Button>
            </CardContent.Content>
          </CardContent>
        </Flex>

        <Flex style={{gridArea: "pool_stats"}} gap={3} column>
          <Flex gap={3} column className={lavaEffectHoverClassName}>
            <LavaChip
              heading="Total Value Locked"
              value={poolData ? "$" + formatNumber(getTVL(), 2) : "$0.00"}
              loading={isLoading}
              leader
              alt={1}
            />

            <LavaChip heading="APY" value={poolAPY + "%"} loading={isLoading} follower alt={2} />
          </Flex>

          <DataContainer extend>
            <CardContent.Title title="My Pool Stats" />
            <CardContent.Content>
              <StatRow>
                <StatText bodyRegular color="gray400">
                  Amount LP
                </StatText>
                <StatTextHighlight bodyMedium color="black">
                  {isLoading == true ? (
                    <LoadingBox />
                  ) : (
                    (poolData ? formatNumber(new BigNumber(poolData.balance), 2) + " " : "0") +
                    (assetA ? assetA.symbol + "/" : "") +
                    (assetB ? assetB.symbol : "") +
                    " LP"
                  )}
                </StatTextHighlight>
              </StatRow>
              <StatRow>
                <StatText bodyRegular color="gray400">
                  Amount {assetA ? assetA.symbol : ""}
                </StatText>
                <StatTextHighlight bodyMedium color="black">
                  {isLoading == true ? (
                    <LoadingBox />
                  ) : (
                    (poolData ? formatNumber(new BigNumber(poolData.assetAAmount), 2) + " " : "0") +
                    (assetA ? assetA.symbol : "")
                  )}
                </StatTextHighlight>
              </StatRow>
              <StatRow>
                <StatText bodyRegular color="gray400">
                  Amount {assetB ? assetB.symbol : ""}
                </StatText>
                <StatTextHighlight bodyMedium color="black">
                  {isLoading == true ? (
                    <LoadingBox />
                  ) : (
                    (poolData ? formatNumber(new BigNumber(poolData.assetBAmount), 2) + " " : "0") +
                    (assetB ? assetB.symbol : "")
                  )}
                </StatTextHighlight>
              </StatRow>
              <StatRow>
                <StatText bodyRegular color="gray400">
                  Pool share
                </StatText>
                <StatTextHighlight bodyMedium color="black">
                  {isLoading == true ? <LoadingBox /> : poolData ? poolData.share + "%" : "0%"}
                </StatTextHighlight>
              </StatRow>
              <StatRow>
                <StatText bodyRegular color="gray400">
                  Vote share
                </StatText>
                <StatTextHighlight bodyMedium color="black">
                  {isLoading == true ? <LoadingBox /> : poolData ? poolData.vote_share + "%" : "0%"}
                </StatTextHighlight>
              </StatRow>
            </CardContent.Content>
          </DataContainer>
        </Flex>

        <DataContainer area="total_assets">
          <CardContent.Title title="Total Assets In Pool" />
          <CardContent.Content>
            <Flex gap={2} column>
              {[
                [assetA, poolData?.assetATotal],
                [assetB, poolData?.assetBTotal],
              ].map(([asset, amount], i) => (
                <Card key={i} loading={isLoading} size="l" border="s">
                  <Flex align="center" gap={2}>
                    {asset && <IconAsset button size="s" asset={asset} />}
                    <Text h6>{asset?.symbol}</Text>
                    <Flex.Grow />
                    <Text bodyMedium>
                      {poolData ? formatNumber(new BigNumber(amount).toFixed(2), 0) : "0.00"}{" "}
                      <Text inline color="gray400">
                        {asset?.symbol}
                      </Text>
                    </Text>
                  </Flex>
                </Card>
              ))}
            </Flex>
          </CardContent.Content>
        </DataContainer>

        <DataContainer area="pool_details">
          <DataContainerColumn>
            <ContainerText h5>Pool Details</ContainerText>
            <StatRow>
              <StatText bodyRegular color="gray400">
                Pool fee
              </StatText>
              <StatTextHighlight bodyMedium>
                {isLoading == true ? <Loading /> : poolData ? poolData.pairFeePercent + "%" : "0%"}
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText bodyRegular color="gray400">
                Pool address
              </StatText>
              <StatTextHighlight bodyMedium>
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    {poolData ? reduceString(poolData.address, 7, 4) : ""}
                    <LaunchIcon
                      style={{
                        width: "15px",
                        marginLeft: "5px",
                        color: "rgb(var(--color-overgrown))",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.open("https://era.zksync.network/address/" + poolData.address, "_blank");
                      }}
                    />
                  </>
                )}
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText bodyRegular color="gray400">
                LP vote delegate
              </StatText>
              <StatTextHighlight bodyMedium>
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    {poolData ? reduceString(poolData.delegate, 7, 4) : ""}
                    <LaunchIcon
                      style={{
                        width: "15px",
                        marginLeft: "5px",
                        color: "rgb(var(--color-overgrown))",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.open("https://era.zksync.network/address/" + poolData.delegate, "_blank");
                      }}
                    />
                  </>
                )}
              </StatTextHighlight>
            </StatRow>
            <StatRow style={{padding: "0", margin: "0"}}>
              <Button
                full
                secondary
                onClick={() => setModal(Modal.ChangeFee)}
                disabled={poolData ? new BigNumber(poolData.vote_share).lt(50) : true}
              >
                Change fee
              </Button>
              <Divider size={2} horizontal />
              <Button full secondary disabled={true}>
                Delegate
              </Button>
            </StatRow>
          </DataContainerColumn>
        </DataContainer>
      </Layout>

      {assetA && assetB ? (
        <>
          <ConfirmTransactionModal
            isVisible={modal === Modal.Deposit}
            title={
              isDeposit == 0
                ? "Deposit " + assetA.symbol + "/" + assetB.symbol + " LP"
                : isDeposit == 1
                  ? "Withdraw " + assetA.symbol + "/" + assetB.symbol + " LP"
                  : "Claim Rewards"
            }
            onClose={closeModal}
            onConfirm={() => {}}
            leftValue={
              `${isDeposit == 0 ? getCleanNumber(new BigNumber(assetAAmount)) : getCleanNumber(new BigNumber(poolData ? poolData.assetAAmount : 0).times(assetLPAmountWithdraw).div(poolData ? poolData.balance : 0))} ` +
              (assetA ? assetA.symbol : "")
            }
            leftIcon={assetA ? assetA?.logo : unknownToken}
            rightValue={
              `${isDeposit == 0 ? getCleanNumber(new BigNumber(assetBAmount)) : getCleanNumber(new BigNumber(poolData ? poolData.assetBAmount : 0).times(assetLPAmountWithdraw).div(poolData ? poolData.balance : 0))} ` +
              (assetB ? assetB.symbol : "")
            }
            rightIcon={assetB ? assetB?.logo : unknownToken}
            icon={crossLight}
            isLP={true}
            deposit={isDeposit}
            footer={
              <CustomModal.Rows>
                <CustomModal.Row title="Slippage tolerance">{slippage + "%"}</CustomModal.Row>
                <TxModal>
                  {(w) => (
                    <>
                      <CustomModal.Row title="Gas Cost Estimate">{w("$" + gasRefund.actual)}</CustomModal.Row>
                      <CustomModal.Row title="Gas Refund Estimate" color="success">
                        {w("$" + gasRefund.refund)}
                      </CustomModal.Row>
                    </>
                  )}
                </TxModal>
              </CustomModal.Rows>
            }
          />

          <ConfirmTransactionModal
            isVisible={modal === Modal.Rewards}
            title={"Claim Rewards"}
            onClose={closeModal}
            onConfirm={() => {}}
            leftValue={
              getCleanNumber(new BigNumber(poolData ? poolData.claimable0 : 0)) +
              " " +
              (assetA ? (assetA.symbol == "ETH" ? "WETH" : assetA.symbol) : "")
            }
            leftIcon={assetA ? assetA?.logo : unknownToken}
            rightValue={
              getCleanNumber(new BigNumber(poolData ? poolData.claimable1 : 0)) +
              " " +
              (assetA ? (assetB.symbol == "ETH" ? "WETH" : assetB.symbol) : "")
            }
            rightIcon={assetB ? assetB?.logo : unknownToken}
            icon={crossLight}
            isLP={true}
            deposit={isDeposit}
            footer={
              <CustomModal.Rows>
                <TxModal>
                  {(w) => (
                    <>
                      <CustomModal.Row title="Gas Cost Estimate">{w("$" + gasRefund.actual)}</CustomModal.Row>
                      <CustomModal.Row title="Gas Refund Estimate" color="success">
                        {w("$" + gasRefund.refund)}
                      </CustomModal.Row>
                    </>
                  )}
                </TxModal>
              </CustomModal.Rows>
            }
          />

          <SuccessfulSubmitModal
            isVisible={modal === Modal.SuccessRewards}
            onClose={closeModal}
            title={"Claim Rewards Successful!"}
            description="Funds are avaliable in your wallet."
            savings={"$" + currentSwapCost()}
            costs={"$0.00"}
            onSubmit={() => {}}
            symbolIn={""}
            iconIn={""}
            symbolOut={""}
            iconOut={""}
            amountIn={""}
            amountOut={""}
            section={
              <CustomModal.Rows>
                <TxModal>
                  {(w) => (
                    <>
                      <CustomModal.Row title="Refunded gas fees" color="success">
                        {w("$" + gasSaved)}
                      </CustomModal.Row>
                    </>
                  )}
                </TxModal>
                <CustomModal.Row title="Confirmation time" color="success">
                  {confirmTime}s
                </CustomModal.Row>
                <CustomModal.Row title="Tx hash">
                  <TxButton onClick={() => window.open("https://era.zksync.network/tx/" + txHash, "_blank")}>
                    {reduceString(txHash, 6, 4)}
                  </TxButton>
                </CustomModal.Row>
              </CustomModal.Rows>
            }
          />

          <SuccessfulSubmitModal
            isVisible={modal === Modal.Success}
            onClose={closeModal}
            title={
              isDeposit == 0
                ? "Deposit " + assetA.symbol + "/" + assetB.symbol + " LP Successful!"
                : "Withdraw " + assetA.symbol + "/" + assetB.symbol + " LP Successful!"
            }
            description="Funds are avaliable in your wallet."
            savings={"$" + currentSwapCost()}
            costs={"$0.00"}
            onSubmit={() => {}}
            symbolIn={""}
            iconIn={""}
            symbolOut={""}
            iconOut={""}
            amountIn={""}
            amountOut={""}
            section={
              isDeposit == 0 ? (
                <CustomModal.Rows>
                  <TxModal>
                    {(w) => (
                      <>
                        <CustomModal.Row title="Refunded gas fees" color="success">
                          {w("$" + gasSaved)}
                        </CustomModal.Row>
                      </>
                    )}
                  </TxModal>
                  <CustomModal.Row title="Confirmation time" color="success">
                    {confirmTime}s
                  </CustomModal.Row>
                  <CustomModal.Row title={`${assetA ? assetA.symbol : ""} deposited`}>
                    {parseFloat(assetAAmount).toPrecision(2)} {assetA ? assetA.symbol : ""}
                  </CustomModal.Row>
                  <CustomModal.Row title={`${assetB ? assetB.symbol : ""} deposited`}>
                    {new BigNumber(assetBAmount).toPrecision(2)} {assetB ? assetB.symbol : ""}
                  </CustomModal.Row>
                  <CustomModal.Row title="Tx hash">
                    <TxButton onClick={() => window.open("https://era.zksync.network/tx/" + txHash, "_blank")}>
                      {reduceString(txHash, 6, 4)}
                    </TxButton>
                  </CustomModal.Row>
                </CustomModal.Rows>
              ) : (
                <CustomModal.Rows>
                  <TxModal>
                    {(w) => (
                      <>
                        <CustomModal.Row title="Refunded gas fees" color="success">
                          {w("$" + gasSaved)}
                        </CustomModal.Row>
                      </>
                    )}
                  </TxModal>
                  <CustomModal.Row title="Confirmation time" color="success">
                    {confirmTime}s
                  </CustomModal.Row>
                  <CustomModal.Row title={`Minimum ${assetA ? assetA.symbol : ""} received`}>
                    {parseFloat(minReceivedA()).toPrecision(2)} {assetA ? assetA.symbol : ""}
                  </CustomModal.Row>
                  <CustomModal.Row title={`Minimum ${assetB ? assetB.symbol : ""} received`}>
                    {new BigNumber(minReceivedB()).toPrecision(2)} {assetB ? assetB.symbol : ""}
                  </CustomModal.Row>
                  <CustomModal.Row title="Tx hash">
                    <TxButton onClick={() => window.open("https://era.zksync.network/tx/" + txHash, "_blank")}>
                      {reduceString(txHash, 6, 4)}
                    </TxButton>
                  </CustomModal.Row>
                </CustomModal.Rows>
              )
            }
          />
        </>
      ) : (
        <></>
      )}

      <ErrorModal isVisible={modal === Modal.Error} onClose={closeModal} title="LP error!" description={error} />
    </Box>
  );
};

const Layout = styled(Grid)`
  grid-template-columns: 3fr 4fr;
  grid-template-areas:
    "titlecontainer titlecontainer"
    "pool_stats  exchange"
    "total_assets exchange"
    "pool_details exchange";

  row-gap: 12px;
  column-gap: 12px;

  @media (max-width: 800px) {
    max-width: 520px;
    margin: auto;
    grid-template-columns: 1fr;
    grid-template-areas:
      "titlecontainer"
      "pool_stats"
      "apy_stats"
      "exchange"
      "rewards"
      "my_stats"
      "total_assets"
      "pool_details";
  }
`;

const WithdrawPercentageRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 20px 10px;
`;

const Spacer = styled.div`
  width: 25px;
  height: 6px;
`;

const TitleContainer = styled.div`
  position: relative;
  overflow: hidden;
  grid-area: titlecontainer;
  margin-bottom: 25px;
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TitleContainerWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 30px;
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 52px;

  ${layoutMq(`
    font-size: 28px;
    padding: 10px;
  `)}
`;

const AssetImage = styled.img`
  height: 25px;
  width: 25px;
  margin-right: 5px;
  border: 1px solid transparent;
  border-radius: 10px;
`;

const StatRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
`;

const AssetRow = styled(StatRow)`
  margin-bottom: 0;
`;

const StatText = styled(Text)`
  display: flex;
  align-items: center;
  text-align: right;
`;

const StatTextHighlight = styled(Text)`
  text-align: right;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Subtitle = styled.div`
  width: auto;
  padding: 6px 8px;
  height: fit-content;
  background: rgb(var(--color-white));
  border-radius: 8px;
  display: flex;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  text-align: center;
  justify-content: center;
  align-items: center;

  color: rgb(var(--color-black));

  @media (max-width: 600px) {
    display: none;
  }
`;

const DataContainer = styled(CardContent)<{area?: string; extend?: boolean}>`
  grid-area: ${({area}) => area};

  ${(_) => _.extend && `flex: 1;`}
`;

const SDivider = styled.div`
  height: 5px;
  @media (max-width: 600px) {
    height: 5px;
  }
`;

const DataContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 80px;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const ContainerText = styled(Text)`
  padding-bottom: 18px;
`;

const ExchangeHeaderButton = styled.button`
  font-family: "Aeonik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #7f7d97;
  width: 100%;
  height: 55px;
  border-bottom: 2px solid #1d2336;

  &.active {
    color: var(--color-blue-accent);
    border-bottom: 2px solid var(--color-blue-accent);
  }
`;

const SettingAnim = keyframes`
 0% { transform: rotate(0deg); }
 100% { transform: rotate(60deg); }
`;

const SettingButton = styled.button`
  width: 24px;
  height: 35px;
  margin-left: auto;
  padding: 0;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  :hover {
    opacity: 0.5;
    animation: ${SettingAnim} 0.5s forwards;
  }
`;

const GearIcon = styled.img`
  max-width: 100%;
`;

const TxButton = styled.button`
  cursor: pointer;
  color: black;
  font-weight: 500;
  z-index: 5;
  position: relative;
`;

const Sep = styled.div`
  background: ${palette.gray400};
  height: 2px;
  width: 30px;
  margin: 0px 16px;

  ${layoutMq(`
    height: 1px;
    width: 16px;
    margin: 0px 8px;
  `)}
`;

const RewardBody = styled(Text)`
  ${size.padding(0, 2, 0, 1)}
  ${br.xs}
  ${size.gap(2)}

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${palette.gray50};
  width: fit-content;
  min-height: 32px;
`;

const Rewards = styled.div`
  ${size.gap(4)}

  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

const RewardText = styled.div`
  ${text.bodyRegular}

  align-self: flex-start;
  white-space: nowrap;
  color: ${palette.gray400};
  min-height: 32px;
  display: flex;
  align-items: center;
  padding-right: ${size(10)};
  min-width: 140px;
`;
