import styled from "styled-components";

import {flex, microPalette, palette, text} from "../../styles";

interface BulletProps {
  discrete?: boolean;
}

export const Bullet = styled.div<BulletProps>`
  ${flex("center", "center")}
  ${text.bodyMedium}

  min-height: 24px;
  max-height: 24px;
  min-width: 24px;
  max-width: 24px;
  border-radius: 50%;
  background: ${microPalette.c200.o(0.5)};
  color: ${microPalette.c300};

  ${(_) =>
    _.discrete &&
    `
    background: ${palette.gray50};
  `}
`;
