import React from "react";
import styled from "styled-components";

import {SectionRow, SectionText, SectionWithBorder} from "./Section";

interface AddressSectionProps {
  address: string;
  label: string;
  className?: string;
  onChange?: (value: string) => void;
}

export const AddressSection = ({address, label, className, onChange}: AddressSectionProps) => (
  <StyledSection className={className}>
    <SectionRow>
      <SectionText>{label}</SectionText>
    </SectionRow>
    <StyledAddress value={address} onChange={(event) => (onChange ? onChange(event.target.value) : null)} />
  </StyledSection>
);

//      <Badge>Connected Wallet</Badge>

const StyledSection = styled(SectionWithBorder)`
  margin: 32px 0;
`;

const StyledAddress = styled.input`
  position: relative;
  width: 100%;
  height: 30px;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: rgb(var(--color-black));
  background: none;
  border: none;
  outline: none;

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &::-webkit-input-placeholder {
    color: #ffffff;
  }
  &::-moz-placeholder {
    color: #ffffff;
  }
  &:-ms-input-placeholder {
    color: #ffffff;
  }
  &:-moz-placeholder {
    color: #ffffff;
  }

  &[disabled]::-webkit-input-placeholder {
    color: #777f99;
  }
  &[disabled]::-moz-placeholder {
    color: #777f99;
  }
  &[disabled]:-ms-input-placeholder {
    color: #777f99;
  }
  &[disabled]:-moz-placeholder {
    color: #777f99;
  }

  @media (max-width: 900px) {
    max-width: 100px;
  }
`;
