import React, {useState} from "react";
import styled from "styled-components";
import BigNumber from "bignumber.js";
import {Text, CardContent, Card} from "../../ui/components";

import {formatDate} from "../../utils/formatDate";
import LockClockIcon from "@mui/icons-material/LockClock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import {formatNumber} from "../../utils/formatNumber";

export const AmplifierV2StakeInfo = ({info, onRedeem, symbol}) => {
  const [calculating, setCalculating] = useState(false);

  React.useEffect(() => {
    return () => {};
  }, []);

  const redeem = async () => {
    if (shouldDisableRedeem()) return;
    setCalculating(true);
    await onRedeem(info.index);
    setCalculating(false);
  };

  const shouldDisableRedeem = () => {
    var curDate = new BigNumber(Date.now()).div(1000);
    var expDate = new BigNumber(info.stakedTime).plus(info.epochDuration)
    if (curDate.lt(expDate)) {
      return true;
    }

    return false;
  };

  const getRedeemStatus = () => {
    return;
    var curDate = new BigNumber(Date.now()).div(1000);

    if (curDate.lt(info.unlock_time)) {
      return "Unmature";
    }

    return "Mature";
  };

  const getRedeemImage = () => {
    var curDate = new BigNumber(Date.now()).div(1000);
    var expDate = new BigNumber(info.stakedTime).plus(info.epochDuration)
    if (curDate.lt(expDate)) {
      return <LockClockIcon/>; //loading_tx
    }
    return <LockOpenIcon />;
    //return succesful_tx
  };

  return (
    <StyledDropdown>
      <DateText bodyRegular color="gray400">
        Rewards: &nbsp;{" "}
        <Amount bodyRegular color="black">
          {" "}
          {formatNumber(info.rewardAmount, 2)} {symbol}{" "}
        </Amount>
      </DateText>
      <DateText bodyRegular color="gray400">
        Claimed: &nbsp;{" "}
        <Amount bodyRegular color="black">
          {formatNumber(info.rewardClaimed, 2)} {symbol}{" "}
        </Amount>
      </DateText>

      <DateText bodyRegular color="gray400">
        Expiration date: &nbsp;{" "}
        <Amount bodyRegular color="black">
          {" "}
          {formatDate(
            new BigNumber(info.stakedTime)
              .times(1000)
              .plus(24 * 60 * 60 * 30 * 1000)
              .toNumber(),
          )}{" "}
        </Amount>
      </DateText>

      <LI>
        <DateText bodyRegular style={{marginRight: "auto"}} color="gray400">
          LP Amount: &nbsp;{" "}
          <Amount bodyRegular color="black">
            {" "}
            {formatNumber(info.stakeAmount, 2) + " LP"}
          </Amount>
        </DateText>
        {getRedeemImage()}
      </LI>
    </StyledDropdown>
  );
};

const StyledDropdown = styled(Card)`
  position: relative;
  overflow: hidden;
  flex-direction: row;

  & + & {
    margin-top: 6px;
  }
`;

const LI = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DateText = styled(Text)`
  text-align: left;
  align-items: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

const Amount = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 900px) {
    font-size: 12px;
  }
`;

const StatRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 12px;
`;

const StatText = styled(Text)`
  display: flex;
  align-items: center;
  text-align: right;
`;

const StatTextHighlightLink = styled.a`
  width: 50%;
  text-align: right;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  :hover {
    color: var(--color-overgrown);
  }
`;

const StatTextHighlight = styled(Text)`
  width: 50%;
  text-align: right;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Content = styled(CardContent)`
  align-items: center;
  justify-content: space-between;
  height: 150px;
  margin-bottom: 50px;
`;
