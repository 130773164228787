import {useQuery} from "@tanstack/react-query";
import {useMemo} from "react";
import {fetchGraphQL} from "../../../utils/gql-utils";
import {getGraphQLUrl} from "../../../web3/apollo/client";
import {AnalyticsGlobalData} from "./global";
import { transformTokens } from "./top-tokens.utils";
import { getBlocksTag } from "./utils";

const tokenProps = `
  l: totalLiquidity
  v: tradeVolumeUSD
  p: derivedETH
`;

export const useTokenChartV2 = (globalData?: AnalyticsGlobalData, address?: string) => {
  const query = useQuery({
    queryKey: ["token-chart-v2", address],
    queryFn: async () => {
      try {
        if (!globalData) throw "No global data";
        if (!globalData.blocks.chart) throw "No charts blocks";
        const url = getGraphQLUrl();
        const filter = `where: {id: ${JSON.stringify(address)}}`;
        const now = Date.now();
        const p = globalData?.prices;
        const {data} = await fetchGraphQL(
          url,
          `
          query {
            ${globalData.blocks.chart.map(({block, tag, gap}) => `
              t_${tag}_${block}_${gap}: tokens (block: {number: ${block}}, ${filter}) {
                ${tokenProps}
              }
            `)}
          }
        `,
        );
        const groups: Record<'h' | 'd' | 'w' | 'm', (ReturnType<typeof transformTokens>[number] & {timestamp: number})[]> = {} as any;
        Object.entries(data)
          .forEach(([key, value]) => {
            const [, group, block, gap] = key.split('_');
            if (!groups[group]) {
              groups[group] = [];
            }
            groups[group].push(({
              ...transformTokens(globalData, value as any[], p?.[group]?.[gap])[0],
              timestamp: now - (+gap * 1000),
            }))
          })
        Object.entries(groups)
          .map(([key, group]) => {
            groups[key] = group
              .sort(({timestamp: a}, {timestamp: b}) => b - a)
              .map((_, i, list) => ({
                ..._,
                volumeUsd: _.volumeUsd - list[i + 1]?.volumeUsd || 0,
              }))
              .slice(0, -1)
              .reverse()
          });
        return groups;
      } catch (e) {
        console.warn(e);
        throw e;
      }
    },
    enabled: !!globalData && !!globalData?.blocks?.chart && !!address && !!globalData?.prices,
    refetchOnWindowFocus: false,
  });

  return {
    ...query,
  };
};
