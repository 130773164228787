import styled from "styled-components";

import {NavLink} from "react-router-dom";

import {mq, palette, transition, typo, size, br, flex, card, microPalette} from "../../styles";
import {noBalanceMq} from "../+web3-connection/index.styles";
import { motion } from "framer-motion";

export const headerBreakpoint = 1140;
const mobileMq = mq(headerBreakpoint);

export const Wrapper = styled.div`
  ${size.padding(4)}

  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding-bottom: 0;

  ${mobileMq(`
    ${size.padding(0)}
  `)}
`;

export const Header = styled.nav`
  ${card("m")}
  ${size.padding(4, 6)}
  ${flex("center")}

  ${mobileMq(`
    ${br.none}
  `)}
`;

export const Menu = styled.div`
  ${flex("center")}
  ${size.gap(6)}

  padding-left: ${size(12)};
  margin-right: auto;

  ${mq(
    1280,
    `
    ${size.gap(2)}
    padding-left: ${size(6)};
  `,
  )}

  ${mobileMq(`
    display: none;
  `)}
`;

export const MenuItem = styled(NavLink)`
  ${typo.primary.medium}
  ${transition.fast}
  ${size.padding(3)}

  color: ${palette.gray400};
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: ${size(-4)};
    left: ${size(3)};
    right: ${size(3)};
    border-top: 1px solid transparent;
  }

  :hover {
    color: ${microPalette.c300};
  }

  &[aria-current] {
    color: ${microPalette.c300};

    &:before {
      border-color: currentColor;
    }
  }
`;

export const DropdownContent = styled(motion.div)`
  position: absolute;
  top: 150%;
  left: 0%;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 200px;
  z-index: 10;
  padding: 6px 4px;
  cursor: pointer;
`;

export const DropdownContainer = styled.div<{active: boolean}>`
  position: relative;
  pointer: cursor;

  color: ${(props) => props.active ? microPalette.c300 : palette.gray400};

  :hover {
    color: ${microPalette.c300};
  }

  &[aria-current] {
    color: ${microPalette.c300};

    &:before {
      border-color: ${microPalette.c300};
    }
  }
`;

export const DropdownButton = styled.button<{active: boolean}>`
  ${typo.primary.medium}
  ${transition.fast}
  color: ${palette.gray400};
  position: relative;
  cursor: pointer;

  :hover {
    color: ${microPalette.c300};
  }
`;


export const MenuButton = styled(NavLink)`
  ${typo.primary.medium}
  ${transition.fast}
  ${size.padding(3)}

  color: ${palette.gray400};
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: ${size(-3.5)};
    left: ${size(3)};
    right: ${size(3)};
    border-top: 1px solid transparent;
  }

  :hover {
    color: ${microPalette.c300};
  }

  &[aria-current] {
    color: ${microPalette.c300};

    &:before {
      border-color: currentColor;
    }
  }
`;

export const MobileMenuItem = styled(NavLink)`
  ${typo.primary.medium}
  ${transition.fast}
  ${size.padding(3)}

  color: ${palette.gray400};
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: ${size(-4)};
    left: ${size(3)};
    right: ${size(3)};
    border-top: 1px solid transparent;
  }

  :hover {
    color: ${microPalette.c300};
  }

  &[aria-current] {
    color: ${microPalette.c300};

    &:before {
      border-color: currentColor;
    }
  }
`;


export const SubMenuItem = styled(NavLink)<{active: boolean}>`
  ${typo.primary.medium}
  ${transition.fast}
  ${size.padding(3)}

  color: ${palette.gray400};
  position: relative;
  background-color: ${palette.gray200};
  border: 1px solid transparent; 
  border-radius: 10px;

  width: 100%;
  padding: 12px 16px;
  background-color: ${(props) => props.active ? palette.gray200 : "white"};;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.2s;
  color:  ${(props) => props.active ? microPalette.c300 : palette.gray400};
  font-size: 14px;

  :hover {
    color: ${microPalette.c300};
    background-color: ${palette.gray100};
  }

`;

export const MenuWrapper = styled.div`
  margin-left: ${size(4)};
  display: none;
  cursor: pointer;

  ${mobileMq(`
    display: block;
  `)}
`;

export const MobileMenu = styled.div<{open?: boolean}>`
  ${size.padding(6)}
  ${transition.fast}

  position: fixed;
  z-index: 9;
  top: 72px;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${palette.white};
  display: flex;
  flex-direction: column;

  ${(_) =>
    !_.open &&
    `
    pointer-events: none;
    opacity: 0;
  `}

  ${MenuItem} {
    ${flex("center", "center")}

    text-align: center;
    max-height: 112px;
    flex: 1;
    color: ${palette.black};
    border-top: 1px solid ${palette.gray300.o(0.2)};

    &:before {
      display: none;
    }
    &:first-of-type {
      border-top: 0;
    }
  }
`;

export const MobileMenuWraper = styled.div`
  ${flex("center", "center")}

  max-width: 240px;
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: stretch;
  flex-direction: column;
`;

export const Balance = styled.div`
  ${flex("center", "center")}

  padding-bottom: ${size(8)}px;

  ${noBalanceMq.not(`
    display: none;
  `)}
`;
