import styled from "styled-components";
import {card, mq, palette, size, transition} from "../../../../../../ui/styles";

export const Wrapper = styled.div<{empty: boolean}>`
  position: relative;

  input {
    height: 44px;
  }

  ${_ => !_.empty && `
    &:focus-within {
      ${Popup} {
        opacity: 1;
      }
    }
  `}

  ${() => mq(640, `
    width: 100%;

    input {
      max-width: 250px;
    }
    ${Popup} {
      right: initial;
      left: 0;
    }
  `)}
`;

export const Popup = styled.div`
  ${transition.fast}
  ${card("s")}
  ${size.padding(2, 4)}

  position: absolute;
  top: 100%;
  margin-top: ${size(2)};
  right: 0;
  width: 300px;
  max-height: min(400px, 60vh);
  max-width: calc(100vw - ${size(6)});
  opacity: 0;
  z-index: 4;
  overflow: auto;
`

export const InputWrapper = styled.div`
  position: relative;
  width: fit-content;
`

export const Loader = styled.div<{visible: boolean}>`
  ${transition.fast}

  position: absolute;
  top: 50%;
  right: ${size(3)};
  transform: translateY(-50%);
  opacity: ${_ => +_.visible};
`
export const Item = styled.div`
  ${size.padding(2, 0)}

  border-bottom: 1px solid ${palette.gray50};

  &:last-of-type {
    border-bottom: 0;
  }
`

export const Ellipsis = styled.div`
  overflow: hidden;
  flex: 1;
`