import gql from 'graphql-tag'

export const POOL_TICKS = gql`
  query ticks($pool: Bytes!, $skip: Int!) {
    ticks(where: {poolAddress: $pool} first: 1000, orderBy: tickIdx orderDirection: asc, skip: $skip) {
        tickIdx
        liquidityGross
        liquidityNet
      }
  }
`

export const GLOBAL_INFO_V3 = gql`
  query pooldata($user: Bytes!) {
    pools (orderBy: totalValueLockedETH, orderDirection: desc, where: {totalValueLockedETH_gt: 0.05}) {
      id
      token1Price
      token0Price
      feeTier
      totalValueLockedETH
      totalValueLockedUSD
      totalValueLockedToken0
      totalValueLockedToken1
      token0{
        id
        symbol
        decimals
        derivedETH
      }
      token1{
        id
        symbol
        decimals
        derivedETH
      }
    }
    bundle(id: 1){
      ethPriceUSD
    }
  }
`


export const GLOBAL_POOLS_V3 = gql`
  query pooldata($token0: ID!, $token1: ID!) {
    pools (orderBy: totalValueLockedETH, orderDirection: desc, where: {totalValueLockedUSD_gt: 1000}) {
      id
      feeTier
      totalValueLockedETH
      totalValueLockedUSD
      token0{
        id
        symbol
        decimals
      }
      token1{
        id
        symbol
        decimals
      }
    }
    token0:token(id: $token0){
      id
      symbol
      name
      decimals
      derivedETH
      volumeUSD
    }
    token1:token(id: $token1){
      id
      symbol
      name
      decimals
      derivedETH
      volumeUSD
    }
    bundle(id: 1){
      ethPriceUSD
    }
  }
`