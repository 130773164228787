import {Box} from "../../../../ui/components";

import {AnalyticsOverviewTokens} from "./overview-tokens";
import { AnalyticsOverviewPairs } from "./overview-pairs";

import {AnalyticsChartVolume} from "./components/chart-volume";
import {AnalyticsChartLiquidity} from "./components/chart-liquidity";
import { AnalyticsOverviewTxs } from "./overview-txs";


interface AnalyticsPageProps {
  page: 'tokens' | 'pairs' | 'txs';
}

export const AnalyticsPage = (props: AnalyticsPageProps) => {
  return (
    <>
      <Box noBreak vertical>
        {(() => {
          switch (props.page) {
            case "tokens": return <AnalyticsOverviewTokens full />;
            case "pairs": return <AnalyticsOverviewPairs full />;
            case "txs": return <AnalyticsOverviewTxs full />;
          }
        })()}
      </Box>
    </>
  );
};
