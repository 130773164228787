import React, {ReactNode} from "react";
import styled from "styled-components";
import {transition} from "../styles";

interface ListItemProps {
  children: ReactNode;
  onClick: () => void;
  className?: string;
}

export const ListItem = ({children, onClick, className}: ListItemProps) => {
  return (
    <Item className={className}>
      <Button onClick={onClick} className={className}>
        {children}
      </Button>
    </Item>
  );
};

const Item = styled.div`
  ${transition.fast}

  border-radius: 10px;
  border: 1px solid rgb(var(--color-gray100));
  :hover {
    opacity: 0.5;
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  & + & {
    margin-top: 5px;
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  width: 100%;
  &.disabled {
    cursor: not-allowed;
  }
`;
