import styled from "styled-components";

import {palette, size, transition} from "../../styles";

const width = 28;
export const Wrapper = styled.div<{active?: boolean}>`
  ${transition.fast}
  ${size.padding(0.5)}

  width: ${width}px;
  min-width: ${width}px;
  max-width: ${width}px;
  background: ${palette.gray300};
  display: inline-flex;
  border-radius: 100px;
  cursor: pointer;

  &:before {
    ${transition.fast}

    content: "";
    display: block;
    min-width: 12px;
    min-height: 12px;
    border-radius: 50%;
    background: ${palette.white};
  }

  ${(_) =>
    _.active &&
    `
    background: ${palette.success};

    &:before {
      transform: translateX(${width - (size.raw(1) + 12)}px);
    }
  `}
`;
