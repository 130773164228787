import styled from "styled-components";
import {layoutMq} from "../../ui/components";
import {size} from "../../ui/styles";

export const PriceContainer = styled.div`
  white-space: nowrap;
`;

export const PriceRow = styled.div`
  ${size.padding(0, 10)}
  ${size.gap(6)}

  flex: 1;
  display: flex;
  align-items: center;

  ${layoutMq(`
    display: contents;
  `)}
`;

export const Break = styled.div`
  display: none;

  ${layoutMq(`
    display: block;
    min-width: 100%;
    max-width: 100%;
  `)}
`;

export const PriceRange = styled.div`
  flex: 1;
  max-width: 300px;
  margin-left: auto;

  ${layoutMq(`
    ${size.padding(4, 0)}
    min-width: 100%;
  `)}
`;
