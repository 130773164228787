import {useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {LiquidityRewardsWidget} from "./LiquidityRewardsWidget";
import {YourLiquidityWidget} from "./YourLiquidityWidget";
import {useWalletSelector} from "../../state/hooks";
import {Box, Tabs, Scroll, Title, Divider} from "../../ui/components";
import {selectFarms} from "../../state/selectors";
import React from "react";
const queryString = require("query-string");

export const AmplifierScreen = () => {
  let history = useHistory();
  const location = useLocation();

  const searchParams = React.useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  const [section, setSection] = useState(queryString.parse(location.search).id == '1' ? "b" : "a");

  const activePairs = useWalletSelector(selectFarms);

  const manageLiquidity = (poolId, v2) => {
    if (v2) history.push(`/farmv2/${poolId}`);
    else history.push(`/farm/${poolId}`);
  };

  React.useEffect(() => {
    var url = queryString.parse(location.search);
    if (url.id != '0' && url.id != '1') {
      searchParams.set("id", "0");
      history.replace({search: searchParams.toString()});
    }

  },[])

  React.useEffect(() => {
    var url = queryString.parse(location.search);
    if (url.id == '0') {
      setSection('a')
    } else if (url.id == '1'){
      setSection('b')
    } else {
      searchParams.set("id", "0");
      history.replace({search: searchParams.toString()});
    }

  },[location])

  const setSectionLocal = (val: string) => {
    searchParams.set("id", val == 'a' ? '0' : '1');
    history.replace({search: searchParams.toString()});
    setSection(val)
  }

  return (
    <Box vertical onlyContain>
      <Title title="Farms" />

      <Tabs
        onSelect={(val) => setSectionLocal(val)}
        selected={section}
        full
        tabs={[
          {value: "a", label: "All Farms"},
          {value: "b", label: "My Farms"},
        ]}
      />

      <Divider size={3} />

      <Scroll padding="0 0 3" parentPadding="2" responsive height={322 * 2 + 16 * 1}>
        {section == "a" ? (
          <YourLiquidityWidget
            title={"Active Farms"}
            ownedPairs={activePairs.filter((_) => _.payoutToken?.symbol == "ZK" || _.payoutToken?.symbol == "NODL")}
            manageLiquidity={manageLiquidity}
            isLoading={!activePairs.length}
          />
        ) : (
          <YourLiquidityWidget
            title={"My Farms"}
            ownedPairs={activePairs.filter((_) => _.isUserFarm)}
            manageLiquidity={manageLiquidity}
            isLoading={!activePairs.length}
          />
        )}
      </Scroll>

      <LiquidityRewardsWidget isVisible={false} onClose={() => {}} />
    </Box>
  );
};
