import {toIcon} from "./icon.shared";

export const errorBox = toIcon(
  <svg viewBox="0 0 20 20" width="20" height="20">
    <path
      d="M 9.172 12.144 L 8.818 4.375 L 10.929 4.375 L 10.574 12.144 Z M 8.75 15.606 L 8.75 13.681 L 10.996 13.681 L 10.996 15.606 Z M 0 6.25 L 0 13.75 C 0 17.202 2.798 20 6.25 20 L 13.75 20 C 17.202 20 20 17.202 20 13.75 L 20 6.25 C 20 2.798 17.202 0 13.75 0 L 6.25 0 C 2.798 0 0 2.798 0 6.25 Z"
      fill="#FFECEF"
    />
  </svg>,
);
