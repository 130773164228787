const v = (varName: string) => {
  const fn = (opacity: number) =>
    new String(
      opacity === 1 ? `rgb(var(--color-${varName}))` : `rgba(var(--color-${varName}), ${opacity})`,
    ) as any as string;
  const attr = fn(1) as any;
  attr.o = fn;
  attr.var = `var(--color-${varName})`;
  return attr as string & {o: typeof fn; var: string};
};

export const palette = {
  white: v("white"),
  gray50: v("gray50"),
  gray100: v("gray100"),
  gray200: v("gray200"),
  gray300: v("gray300"),
  gray400: v("gray400"),
  black: v("black"),

  success: v("green"),
  successLight: v("green-light"),
  error: v("red"),
  errorMedium: v("red-medium"),
  errorLight: v("red-light"),

  goldenrod: v("goldenrod"),
  ultramoss: v("ultramoss"),
  overgrown: v("overgrown"),
  forest: v("forest"),
  lindworm: v("lindworm"),

  chalk: v("chalk"),
  lavender: v("lavender"),
  orchid: v("orchid"),
  azul: v("azul"),
  depths: v("depths"),

  breeze: v("breeze"),
  pond: v("pond"),
  sea: v("sea"),
  meadow: v("meadow"),
  glaze: v("glaze"),
};

type color = keyof typeof palette;

const toMicroPalette = (colors: [color, color, color, color, color] | false) => {
  return {
    c100: colors ? palette[colors[0]] : v("mp-c100"),
    c200: colors ? palette[colors[1]] : v("mp-c200"),
    c300: colors ? palette[colors[2]] : v("mp-c300"),
    c400: colors ? palette[colors[3]] : v("mp-c400"),
    c500: colors ? palette[colors[4]] : v("mp-c500"),
  };
};

const coloredMicroPalettes = {
  green: toMicroPalette(["goldenrod", "ultramoss", "overgrown", "forest", "lindworm"]),
  purple: toMicroPalette(["chalk", "lavender", "orchid", "azul", "depths"]),
  aqua: toMicroPalette(["breeze", "pond", "sea", "meadow", "glaze"]),
};
const themedMicroPalette = toMicroPalette(false);
export type MicroPalette = keyof typeof coloredMicroPalettes;

export const microPaletteColors = Object.keys(coloredMicroPalettes) as MicroPalette[];
export const microPalette = Object.assign(coloredMicroPalettes, themedMicroPalette);

export type PaletteColor = color | keyof typeof themedMicroPalette;

export const getColor = (color?: PaletteColor): ReturnType<typeof v> =>
  palette[color!] || themedMicroPalette[color!] || v("placeholder");
