import {keepPreviousData, useQuery} from "@tanstack/react-query";
import {fetchGraphQL} from "../../../utils/gql-utils";
import {getGraphQLUrl} from "../../../web3/apollo/client";

const empty = Object.assign([], {empty: true})

export const useAnalyticsSearch = (search: string) => {
  const query = useQuery({
    queryKey: ["search", search],
    queryFn: async () => {
      try {
        if (!search) return empty;
        const url = getGraphQLUrl();
        const {data} = await fetchGraphQL(
          url,
          `
          query search($search: String, $idSearch: ID) {
            tokens(
              orderBy: tradeVolumeUSD
              orderDirection: desc
              where: {
                or: [
                  { name_contains_nocase: $search }
                  { symbol_contains_nocase: $search }
                  { id: $idSearch }
                ]
              }
            ) {
              id
              s: symbol
              n: name
              v: tradeVolumeUSD
            }
            pairs(
              orderBy: volumeUSD
              orderDirection: desc
              where: {
                or: [
                  { token0_: { symbol_contains_nocase: $search } }
                  { token1_: { symbol_contains_nocase: $search } }
                  { id: $idSearch }
                ]
              }
            ) {
              id
              v: volumeUSD
              a: token0 {
                s: symbol
              }
              b: token1 {
                s: symbol
              }
            }
          }
          `,
          {
            search,
            idSearch: search,
          }
        );
        const result = [
          ...(data.tokens as any[]).map(({id, s, n, v}: Record<string, string>) => ({
            type: "token" as const,
            address: id,
            symbol: s,
            name: n,
            volume: +v,
          })),
          ...(data.pairs as any[]).map(({id, a, b, v}: Record<string, string & {s: string}>) => ({
            type: "pair" as const,
            address: id as string,
            token0: a.s,
            token1: b.s,
            volume: +v,
          })),
        ]
          .sort(({volume: a}, {volume: b}) => b - a)
          .filter((_, i, list) => list.findIndex(({address}) => address === _.address) === i)
          .slice(0, 100);
        return Object.assign(result, {empty: !result.length})
      } catch (e) {
        console.warn(e);
        throw e;
      }
    },
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  });

  return {
    ...query,
    data: query.data || empty,
  };
};
