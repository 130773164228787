import styled, {keyframes} from "styled-components";

import {palette} from "../../styles";
import {Logo} from "../logo";

const loadingSizes = {
  xl: 24,
  m: 18,
  s: 14,
};

interface LoadingProps {
  size?: keyof typeof loadingSizes;
  linear?: boolean;
  currentColor?: boolean;
}

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(${-360 * 10}deg);
  }
`;

export const Loading = styled((props: LoadingProps) => {
  const size = loadingSizes[props.size || "m"];
  return <Logo {...props} width={size} height={size} icon />;
})<{currentColor?: boolean}>`
  color: ${(_) => (_.currentColor ? "currentColor" : palette.black)};

  animation: ${spinAnimation} 5s cubic-bezier(0.8, 0.15, 0.15, 0.8) infinite;

  ${(_) =>
    _.linear &&
    `
    animation-timing-function: linear;
    animation-duration: ${10 * 2}s;
  `}
`;
