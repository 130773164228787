import styled from "styled-components";

import {card, palette, size} from "../../styles";

export const Wrapper = styled.div`
  height: 18px;
  width: 14px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
`;

export const Dir = styled.div<{top?: boolean, active?: boolean}>`
  position: absolute;
  right: 0;
  left: 0;
  height: 9px;
  overflow: hidden;

  ${_ => _.top ? `
    top: -2px;
  ` : `
    bottom: -2px;
    transform: rotate(180deg);
  `}

  color: ${_ => _.active ? palette.gray400 : palette.gray300};
`
