import {ChainId} from "../../enums/chain-id";
import {Token} from "../../factories/token/models/token";
import {ErrorCodes} from "../errors/error-codes";
import {MuteSwitchError} from "../errors/muteswitch-error";

/**
 * USDT token context CHANGE CONTRACT ADDRESS INFO ETC
 */
export class USDT {
  public static ZKSYNC_ERA(): Token {
    return {
      chainId: ChainId.ZKSYNC_ERA,
      contractAddress: "0x493257fD37EDB34451f62EDf8D2a0C418852bA4C",
      decimals: 6,
      symbol: "USDT",
      name: "Tether USD",
    };
  }

  /**
   * Get USDT token info by chain id
   * @param chainId The chain id
   */
  public static token(chainId: ChainId | number): Token {
    switch (chainId) {
      case ChainId.ZKSYNC_ERA:
        return this.ZKSYNC_ERA();
      default:
        throw new MuteSwitchError(`${chainId} is not allowed`, ErrorCodes.tokenChainIdContractDoesNotExist);
    }
  }
}
