import {css} from "styled-components";

import {transition} from "./ui";

export const iconTransition = css`
  ${transition.fast}

  transform: scale(1);

  &:hover {
    opacity: 0.9;
    transform: scale(1.1);
  }
`;
