/* eslint react-hooks/rules-of-hooks: 0 */
import {cloneElement, forwardRef, ForwardedRef, RefAttributes} from "react";
import {getWrapperStyle, useUi} from "../-provider";

import {MicroPalette} from "../../styles";

export function withColor<P, H, B extends React.ReactElement | null>(
  Component: (props: P, color: MicroPalette, config: {ref: ForwardedRef<H>; performance?: number}) => B,
): (props: P & {palette?: MicroPalette; performance?: number} & RefAttributes<H>) => B {
  return forwardRef((props: P & {palette?: MicroPalette; performance?: number}, ref) => {
    const {color, performance} = useUi();
    return cloneElement(
      (Component as any)(props, props.palette || color, {ref, performance: props.performance ?? performance}),
      {
        style: !props?.palette ? undefined : getWrapperStyle(props.palette, true),
      },
    );
  }) as any;
}
