import {Children, ComponentProps, ReactElement, useCallback, useEffect, useMemo, useState} from "react";
import {NavLink, useLocation} from "react-router-dom";

import {Web3Connection} from "../+web3-connection";
import {useMediaQuery} from "../../../hooks/useMediaQuery";
import {Flex} from "../flex";
import {Icon} from "../icon";
import {Logo} from "../logo";

import * as SC from "./index.styles";
import styled from "styled-components";
import { palette } from "../../styles";
import { AnimatePresence, motion } from "framer-motion";
import { Text } from "../text";
import { MdOutlineAnalytics } from "react-icons/md";
import { MdOutlineHowToVote } from "react-icons/md";
import { PiWallet } from "react-icons/pi";
import { useWalletSelector } from "../../../state/hooks";
import { WalletState } from "../../../state";
import { selectFarms } from "../../../state/selectors";

interface HeaderProps {}

export const Header = (props: HeaderProps) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const isMobile = useMediaQuery(SC.headerBreakpoint);
  const activePairs = useWalletSelector(selectFarms);

  const showFarms = () => {
    return activePairs.filter((_) => _.isUserFarm).length > 0
  }

  const toggleMenu = useCallback(() => {
    setOpen((_) => !_);
  }, [setOpen]);

  useEffect(() => {
    setOpen(false);
  }, [isMobile]);

  const menu = useMemo(() => {
    const Item = (props: ComponentProps<typeof SC.MenuButton>) => (
      <SC.MenuButton {...props} onClick={() => setOpen(false)} />
    );

    const MenuItem = (props: ComponentProps<typeof SC.MenuButton>) => (
      <SC.MenuButton {...props} onClick={() => setOpen(false)} />
    );

    const MobileMenuItem = (props: ComponentProps<typeof SC.MenuItem>) => (
      <SC.MenuItem {...props} onClick={() => setOpen(false)} />
    );

    const SubItem = (props: ComponentProps<typeof SC.MenuItem>) => (
      <SC.SubMenuItem {...props} onClick={() => setOpen(false)} />
    );

    return (
      <>
        {isMobile ?
        <>
        <MobileMenuItem to="/wallet">Wallet</MobileMenuItem>
        <MobileMenuItem to="/swap">Trade</MobileMenuItem>
        <MobileMenuItem to="/pool">Pools</MobileMenuItem>
        {showFarms() && <MobileMenuItem to="/farm">Farms</MobileMenuItem>}
        <MobileMenuItem to="/dao">Governance</MobileMenuItem>
        <MobileMenuItem to="/analytics">Analytics</MobileMenuItem>
        </>
        : 
        <>
        <MenuItem to="/swap">Trade</MenuItem>
        <Dropdown title={<Item to={"/pool"}>Pools</Item>} active={location.pathname.includes('pool')}>
          <Flex gap={3} column padding="0 0">
              <SubItem to="/pool?id=0" active={location.pathname.includes('pool') && !location.search.includes('id=1')}>View Pools</SubItem>
              <SubItem to="/pool?id=1" active={location.pathname.includes('pool') && location.search.includes('id=1')}>My Liquidity</SubItem>
            </Flex>
        </Dropdown>
        {showFarms() && 
          (<Dropdown title={<Item to={"/farm"}>Farms</Item>} active={location.pathname.includes('farm')}>
          <Flex gap={3} column padding="0 0">
              <SubItem to="/farm?id=0" active={location.pathname.includes('farm') && !location.search.includes('id=1')}>All Farms</SubItem>
              <SubItem to="/farm?id=1" active={location.pathname.includes('farm') && location.search.includes('id=1')} >My Farms</SubItem>
            </Flex>
          </Dropdown>)
        }


  
        <Dropdown title={<Item to={"/wallet"}>Explore</Item>} active={location.pathname.includes('wallet') || location.pathname.includes('dao') || location.pathname.includes('analytics')}>
          <Flex gap={3} column padding="0 0">
              <SubItem to="/wallet" active={location.pathname.includes('wallet')}><PiWallet/>&nbsp;Wallet</SubItem>
              <SubItem to="/dao" active={location.pathname.includes('dao')}><MdOutlineHowToVote/>&nbsp;Governance</SubItem>
              <SubItem to="/analytics" active={location.pathname.includes('analytics')}><MdOutlineAnalytics/>&nbsp;Analytics</SubItem>
            </Flex>
        </Dropdown>
        </>
        }
        {/*<Item to="/rewards">Rewards</Item>*/}
      </>
    );
  }, [isMobile, location]);

  return (
    <>
      <SC.Wrapper>
        <SC.Header>
          <NavLink to="/">
            <Logo />
          </NavLink>
          <SC.Menu>{menu}</SC.Menu>
          <Flex.Grow />
          <Web3Connection />
          <SC.MenuWrapper onClick={toggleMenu}>
            <Icon icon={!open ? "menu" : "close"} />
          </SC.MenuWrapper>
        </SC.Header>
      </SC.Wrapper>
      <SC.MobileMenu open={open}>
        <SC.Balance>
          <Web3Connection onlyBalance />
        </SC.Balance>
        <SC.MobileMenuWraper>{menu}</SC.MobileMenuWraper>
      </SC.MobileMenu>
    </>
  );
};

interface DropdownProps {
  children: ReactElement;
  active: boolean;
  title: ReactElement;
}

const Dropdown = ({title, children, active}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <SC.DropdownContainer
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      onMouseUp={() => setIsOpen(false)}
      active={active}
    >
      {title}
      <AnimatePresence>
        {isOpen && (
          <SC.DropdownContent
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            {children}
          </SC.DropdownContent>
        )}
      </AnimatePresence>
    </SC.DropdownContainer>
  );
};