import styled from "styled-components";

import {br, microPalette, palette, size, text} from "../../styles";

export interface Types {
  variant?: "white" | "dark" | "color";
  valueColor?: boolean | number;
}
export const Title = styled.div`
  ${text.bodyRegular}

  color: ${palette.gray400};
`;

export const Value = styled.div`
  ${text.bodyMedium}
`;

export const Wrapper = styled.div<Types>`
  ${br.xxs}
  ${size.padding(0.5, 2)}
  ${size.gap(2)}

  position: relative;
  display: inline-flex;
  background: ${palette.gray50};

  ${(_) =>
    _.variant === "dark" &&
    `
    background: ${palette.gray400};
    color: ${palette.white};

    ${Title} {
      color: ${palette.gray200};
    }
  `}

  ${(_) =>
    _.variant === "white" &&
    `
    background: ${palette.white};

    ${Title} {
      color: ${palette.black};
    }
  `}

  ${(_) =>
    _.variant === "color" &&
    `
    background: ${microPalette.c500};

    ${Title} {
      color: ${palette.gray200};
    }
    ${Value} {
      color: ${microPalette.c200};
    }
  `}


  ${(_) =>
    _.valueColor !== undefined &&
    `
    ${Value} {
      color: ${typeof _.valueColor === "number" ? (_.valueColor >= 0 ? palette.success : palette.error) : _.valueColor ? palette.success : palette.error};
    }
  `}
`;
