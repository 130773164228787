import {memo, useCallback, useMemo, useState} from "react";
import { useHistory} from "react-router-dom";
import { tickToPrice} from "@uniswap/v3-sdk";

import {Button, Card, Chip, Divider, Flex, IconAsset, layoutMq, PriceRange, Text, Tooltip, useLayoutMq} from "../../ui/components";
import { abbrNum, formatNumber, formatPrice, formatPriceInfinity, formatPriceZeros, numberWithCommas} from "../../utils";
import {PositionDataV3} from "../../model/WalletHookTypes";

import * as SC from "./positionV3.styles";
import { AnimatePresence, motion } from "framer-motion";
import BigNumber from "bignumber.js";
import { FaArrowRight } from "react-icons/fa6";
import styled from "styled-components";
import { palette } from "../../ui/styles";
import unknownToken from "../../assets/images/icons/unknown_token.svg";
import { MdOutlineBolt } from "react-icons/md";
import { positions } from "@mui/system";

interface PositionV3Props {
  position: PositionDataV3;
}

export const PositionV3 = memo(function PositionV3Comp(props: PositionV3Props) {
  const {position} = props;
  const history = useHistory();
  const layoutBreak = useLayoutMq();
  const {pool} = position || ({} as never);
  const [isOpen, setIsOpen] = useState(false)

  const currentPrice = useMemo(() => {
    if (!pool) return;
    return +pool.token1Price.toSignificant();
  }, [pool?.token1Price]);

  const priceA = useMemo(() => {
    if (!pool) return;
    if (Number(position.tickUpper) > 887000) return Infinity;
    return +tickToPrice(pool.token1, pool.token0, Number(position.tickUpper)).toSignificant();
  }, [pool, position.tickUpper]);

  const priceB = useMemo(() => {
    if (!pool) return;
    if (Number(position.tickLower) < -887000) return 0;
    return +tickToPrice(pool.token1, pool.token0, Number(position.tickLower)).toSignificant();
  }, [pool, position.tickLower]);

  const {lowerPrice, upperPrice} = useMemo(() => {
    return {
      lowerPrice: Math.min(priceA!, priceB!),
      upperPrice: Math.max(priceA!, priceB!),
    };
  }, [priceA, priceB]);

  const goToPosition = useCallback(() => {
    history.push(`/pool/v3/${position.id}`);
  }, [position.id]);

  const getPoolAPY = () => {
    if(position['ampAPY'])
      return new BigNumber(position["apy"]).plus(position['ampAPY']).toFixed(2) + '%'

    return new BigNumber(position["apy"]).toFixed(2) + '%'
  }

  const getPoolBaseAPY = () => {
    return new BigNumber(position["apy"]).toFixed(2) + '%'
  }

  const getPoolBoostAPY = () => {
    return new BigNumber(position['ampAPY']).toFixed(2) + '%'
  }


  const token0balance = () => {
    return new BigNumber(position['liqAmounts'].amount0.toString()).div(Math.pow(10, position.asset0.decimals)).toFixed()
  }

  const token1balance = () => {
    return new BigNumber(position['liqAmounts'].amount1.toString()).div(Math.pow(10, position.asset1.decimals)).toFixed()
  }

  return (
    <AnimatePresence>
      
    <Card size="l" height={layoutBreak ? undefined : 90} onClick={() => {setIsOpen(!isOpen)}} style={isOpen ? {borderBottomLeftRadius: "0px", borderBottomRightRadius: "0", cursor: 'pointer', zIndex: 2}: {cursor: 'pointer', zIndex: 2}}>
    <Flex gap={2} justify="space-between" noBasis={!layoutBreak} expandVertical wrap={layoutBreak}>
        <Flex gap={4} grow={layoutBreak ? 2 : 16} order={0}>
          <Flex>
            <IconAsset stack asset={{logo: position.asset0.logo ? position.asset0.logo : unknownToken, symbol: position.asset0.symbol} as any} size="s" button />
            <IconAsset stack asset={{logo: position.asset1.logo ? position.asset1.logo : unknownToken, symbol: position.asset1.symbol} as any} size="s" button />
          </Flex>

          <div style={{position: "relative"}}>
            <Text h6 noWrap>
              {position.asset0.symbol}
              <Text inline color="gray300">
                {" ― "}
              </Text>
              {position.asset1.symbol}
              {" "}
              <Flex inline style={{marginBottom: '0px', marginLeft: '5px'}}>
                <Chip palette="green" label="" value={"v3"} variant="color" />
              </Flex>
            </Text>
            <Text bodySmall color="gray400" noWrap>
              {`CL ${new BigNumber(position.fee).div(10000).toFixed(2)}% - NFT #${position.id}`}
            </Text>
          </div>
        </Flex>

        <Break />

        <Flex gap={3} grow={layoutBreak ? 2 : 10} order={layoutBreak && 2}>
          <BreakPadding />
          <Flex column>
            <Text bodyRegular color="gray400">
              {position.asset0.symbol}
            </Text>
            <Text bodyRegular color="gray400">
              {position.asset1.symbol}
            </Text>
          </Flex>
          <Flex column>
            <Text bodyMedium>{abbrNum(position["balance0"], 4)}</Text>
            <Text bodyMedium>{abbrNum(position["balance1"], 4)}</Text>
          </Flex>
        </Flex>

        <Flex grow={layoutBreak ? 1 : 10} order={layoutBreak && 1}>
          {!layoutBreak ? (
            <div>
              {position["totalValueLockedUSD"] && (
                <>
                  <Text bodyRegular color="gray400">
                    TVL
                  </Text>
                  <Text bodyMedium>{`$${formatNumber(new BigNumber(position["totalValueLockedUSD"]).toFixed(0), 0)}`}</Text>
                </>
              )}
            </div>
          ) : (
            position["totalValueLockedUSD"] && <Chip label="TVL" value={`$${formatNumber(new BigNumber(position["totalValueLockedUSD"]).toFixed(0), 0)}`} />
          )}
        </Flex>


        <Flex order={layoutBreak && 2} column align="flex-start" gap={2} grow={layoutBreak ? 1 : 10}>
          {position["apy"] ?
            (!position["ampAPY"] ? (
              <Chip valueColor label="APY" value={position["apy"] + "%"} />
            ) : (
              <>
                <Tooltip content={`This pool is boosted with additional rewards. \nBase: ${getPoolBaseAPY()} \nBoosted: ${getPoolBoostAPY()}`}>
                  <Chip palette="purple" label="APY" value={getPoolAPY()} variant="color">
                    <MdOutlineBolt color="rgba(var(--color-mp-c200))" />
                    <RewardToken>
                    {position["rewardTokens"] ? position["rewardTokens"].map((item, index) => {
                      return (
                          <IconAsset contrast asset={{logo: item.logo ? item.logo : unknownToken, symbol: item.symbol} as any} size="xs"/>
                      )
                    }) : <></>}
                    </RewardToken>
                  </Chip>
                </Tooltip>

              </>
            )) : <Chip valueColor label="APY" value={"0.01%"} />}
        </Flex>

        <Break />

        <Flex justify="flex-end" order={layoutBreak && 5} grow={layoutBreak ? 1 : 10}>
          <Button
            secondary
            size="s"
            full={layoutBreak}
            onClick={goToPosition}
          >
              <FaArrowRight/>
          </Button>
        </Flex>
      </Flex>
    </Card>
    
    {isOpen && (
      <DropdownContent
        key="dropModal"
        initial={{ height: 0, opacity: 1, translateY: -100, zIndex: 0 }}
        animate={{ height: 'auto', opacity: 1, translateY: 0, zIndex: 1  }}
        exit={{ height: 0, opacity: 1, translateY: -100, zIndex: 0 }}
        transition={{ duration: 0.15 }}
      >
      <CardDropDown size="l" height={layoutBreak ? undefined : 90} onClick={() => {setIsOpen(!isOpen)}}>
        <Flex gap={2} justify="space-between" expandVertical expandHorizontal wrap={layoutBreak}>

            <Flex>
              <div>
                <Text bodyRegular color="gray400">Position</Text>
                <Text bodyMedium>{abbrNum(token0balance())} {position.asset0.symbol} {" / "} {abbrNum(token1balance())} {position.asset1.symbol}</Text>
              </div>
            </Flex>
            <SC.PriceRow>
              <SC.PriceContainer>
                <Text bodyRegular color="gray400">
                  Current price:
                </Text>
                <Text bodyMedium inline>
                  {formatPriceInfinity(currentPrice, (n) => formatPriceZeros(n, 5, 10))}
                </Text>
                <Divider size={1} horizontal />
                <Text bodyMicro inline color="gray400">
                  {position.asset0.symbol} per {position.asset1.symbol}
                </Text>
              </SC.PriceContainer>
              <SC.PriceRange>
                <PriceRange rangeFrom={lowerPrice} rangeTo={upperPrice} price={currentPrice!} />
              </SC.PriceRange>
            </SC.PriceRow>
            <SC.Break />
            <Button secondary full={layoutBreak} onClick={goToPosition} size="s">
            Manage
            </Button>
        </Flex>
        
      </CardDropDown>
      </DropdownContent>
    )}
    </AnimatePresence>
  );
});


const DropdownContent = styled(motion.div)`
  cursor: pointer;
`;

const CardDropDown = styled(Card)`
    margin-top: -10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 0px;
    padding-left: 45px;
    background: ${palette.gray50};    
`


const Break = styled.div`
  display: none;

  ${layoutMq(`
    display: block;
    min-width: 100%;
    max-width: 100%;
    order: 2;
  `)}
`;

const BreakPadding = styled.div`
  min-width: 32px;
  max-width: 64px;
  display: none;

  ${layoutMq(`
    display: block;
  `)}
`;

const RewardToken = styled.div`
  position: absolute;
  bottom: 70%;
  left: 100%;
  display: flex;
  flex-direction: row;
`;
