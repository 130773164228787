import {ChainId} from "../../enums/chain-id";
import {Token} from "../../factories/token/models/token";
import {ErrorCodes} from "../errors/error-codes";
import {MuteSwitchError} from "../errors/muteswitch-error";

/**
 * KOI token context CHANGE CONTRACT ADDRESS INFO ETC
 */
export class KOI {
  public static ZKSYNC_ERA(): Token {
    return {
      chainId: ChainId.ZKSYNC_ERA,
      contractAddress: "0xa995ad25ce5eb76972ab356168f5e1d9257e4d05",
      decimals: 18,
      symbol: "KOI",
      name: "Koi",
    };
  }

  /**
   * Get KOI token info by chain id
   * @param chainId The chain id
   */
  public static token(chainId: ChainId | number): Token {
    switch (chainId) {
      case ChainId.ZKSYNC_ERA:
        return this.ZKSYNC_ERA();
      default:
        throw new MuteSwitchError(`${chainId} is not allowed`, ErrorCodes.tokenChainIdContractDoesNotExist);
    }
  }
}

/**
 * KOI token context CHANGE CONTRACT ADDRESS INFO ETC
 */
export class MUTE {
  public static ZKSYNC_ERA(): Token {
    return {
      chainId: ChainId.ZKSYNC_ERA,
      contractAddress: "0x0e97c7a0f8b2c9885c8ac9fc6136e829cbc21d42",
      decimals: 18,
      symbol: "MUTE",
      name: "Mute.io",
    };
  }

  /**
   * Get KOI token info by chain id
   * @param chainId The chain id
   */
  public static token(chainId: ChainId | number): Token {
    switch (chainId) {
      case ChainId.ZKSYNC_ERA:
        return this.ZKSYNC_ERA();
      default:
        throw new MuteSwitchError(`${chainId} is not allowed`, ErrorCodes.tokenChainIdContractDoesNotExist);
    }
  }
}
