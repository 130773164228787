import styled, {css} from "styled-components";

import {br, card, flex, microPalette, palette, size, text, transition} from "../../styles";

export const paddingTop = size.raw(8);
export const paddingBottom = size.raw(8);

export const Container = styled.div<{empty?: boolean}>`
  ${br.xl}
  ${size.padding(0, 6)}

  background: ${palette.gray50};
  overflow: hidden;

  ${(_) =>
    _.empty &&
    `
    pointer-events: none;

    & > * {
      visibility: hidden;
    }
  `}
`;

export const Wrapper = styled.div`
  min-height: 226px;
  max-height: 226px;
  position: relative;
`;

export const Overlay = styled.div`
  position: absolute;
  top: ${paddingTop}px;
  bottom: ${paddingBottom}px;
  right: 0;
  left: 0;
`;

export const Mark = styled.div<{left?: number; rotate?: boolean; price?: boolean}>`
  height: 100%;
  width: 2px;
  margin-left: -1px;
  background: ${microPalette.c200};
  color: ${microPalette.c200};
  position: absolute;
  top: 0;
  z-index: 4;
  transform: ${(_) => !_.rotate && "rotate(180deg)"};
  cursor: pointer;

  ${(_) =>
    _.left !== undefined &&
    `
    left: min(max(0%, ${_.left * 100}%), 100%);
  `}

  ${(_) =>
    _.price &&
    `
    background: ${microPalette.c300.o(0.6)};
    z-index: 3;
    pointer-events: none;
  `}

  &:before {
    content: "";
    display: block;
    width: 16px;
    margin-left: -8px;
    left: 50%;
    height: 100%;
    position: absolute;
  }

  svg {
    position: absolute;
    min-width: 16px;
    min-height: 16px;
    max-width: 16px;
    max-height: 16px;
    right: 1px;
    transform: translateX(7px) translateY(-50%);
    top: 50%;
  }
`;

export const Range = styled.div<{from?: number; to?: number}>`
  background: ${microPalette.c300.o(0.2)};
  left: ${(_) => Math.max(_.from!, 0) * 100}%;
  right: ${(_) => Math.max(0, 1 - _.to!) * 100}%;
  height: 100%;
  top: 0;
  position: absolute;
  z-index: 2;
`;

export const AreaPath = styled.path`
  fill: ${microPalette.c500};
`;

type ValueChipProps = {left?: number; right?: boolean};
export const getComputedValueChip = (props: ValueChipProps) => {
  return {
    left: Math.max(0, Math.min(1, props.left!)) * 100 + "%",
    transform:
      (props.right && props.left! < 0.81) || (!props.right && props.left! < 0.19)
        ? `translateX(${size(3)}) translateY(-50%)`
        : `translateX(calc(-100% - ${size(3)})) translateY(-50%)`,
  };
};
export const ValueChip = styled.div<ValueChipProps>`
  ${card("xs")}
  ${size.padding(1.5)}
  ${transition.fast.prop("transform")}

  position: absolute;
  user-select: none;
  z-index: 5;
  top: 50%;
  ${(_) => {
    const calculated = getComputedValueChip(_);
    return css`
      left: ${calculated.left};
      transform: ${calculated.transform};
    `;
  }}
`;

export const XaxisContainer = styled.div`
  ${flex("center", "space-between")}
  ${size.margin(0, -3)}
  ${text.bodyMicro}

  height: ${paddingTop}px;
  color: ${palette.gray400};
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-mask: linear-gradient(
    90deg,
    transparent 0,
    white ${size(3)},
    white calc(100% - ${size(3)}),
    transparent 100%
  );
`;
export const XaxisContainerSize = styled.div`
  ${size.margin(0, 3)}

  position: relative;
  width: 100%;
  height: 100%;
`;
export const Xaxis = styled.div`
  ${flex("center", "center")}
  ${transition.fast}

  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
`;

export const Controls = styled.div`
  ${size.gap(1)}
  ${size.padding(1)}

  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;

  & > * {
    ${size.padding(0, 1.5)}

    max-height: 24px;
    min-height: 24px;
    overflow: hidden;
    color: ${microPalette.c300};
  }
`;
