import styled from "styled-components";

import {card, flex, size} from "../../styles";

export const Wrapper = styled.div`
  ${card("s")}
  ${flex("center", "center")}
  ${size.padding(5, 3)}
  ${size.gap(8)}

  text-align: center;
`;
