import styled from "styled-components";

import {palette, typo} from "../../styles";

export const Typography = styled.div<{font: keyof typeof typo; type: string}>`
  ${(_) => typo[_.font][_.type]}

  font-size: 40px;
  color: ${palette.black};
  width: 220px;
`;
