import {CardContent, Button, Text, layoutMq} from "../../ui/components";
import LP_CARD from "../../assets/images/lp/farm_card.png";

import styled from "styled-components";

export const LiquidityRewardsWidget = ({isVisible, onClose}) => {
  return (
    <>
      <Container>
        <HeaderRow>
          <Text h4>How does Farming work?</Text>
        </HeaderRow>

        <Column>
          <Row>
            <NumberBg bodyRegular>1</NumberBg>
            <Text h5>Deposit Liquidity Tokens</Text>
          </Row>

          <BodyText>
            After providing liquidity for a given pair, stake your LP tokens in a designated farming pool.
          </BodyText>

          <Row>
            <NumberBg bodyRegular>2</NumberBg>
            <Text h5>Earn Multiple Yields</Text>
          </Row>

          <BodyText>
            After depositing, start earning additional token rewards on top of your LP yield. Each pool has a regular
            and boosted APY determined by your veKOI balance ratio compared to your LP value.
          </BodyText>

          <Row>
            <NumberBg bodyRegular>3</NumberBg>
            <Text h5>Collect Rewards</Text>
          </Row>

          <BodyText>Yield farm your rewards without needing to withdraw your stake.</BodyText>

          <ButtonMod
            secondary
            onClick={() => {
              window.open("https://wiki.mute.io/mute/mute-switch/amplifier", "_blank");
            }}
          >
            Read docs
          </ButtonMod>
        </Column>
      </Container>
    </>
  );
};

const ButtonMod = styled(Button)`
  max-width: 200px;
`;
const Container = styled(CardContent)`
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  width: 100%;
  padding: 34px 48px;
  background-image: url(${LP_CARD});
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: right center;

  grid-area: description;
  @media (max-width: 767px) {
    flex-direction: column;
    height: auto;
  }

  ${layoutMq(`
    background-image: none;
  `)}
`;

const HeaderRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const HeaderText = styled.p`
  font-family: "Aeonik";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
`;

const DescriptionText = styled.p`
  font-family: "Aeonik";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
`;

const BodyText = styled.p`
  font-family: "Aeonik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 10px;
  margin-bottom: 15px;

  color: #777f99;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 70%;
  height: 100%;
  margin-top: 20px;

  @media (max-width: 767px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  @media (max-width: 767px) {
    width: 100%;
    padding-top: 20px;
  }
`;

const NumberBg = styled(Text)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(var(--color-chalk));
  width: 25px;
  height: 25px;
  border-radius: 100px;
  margin-right: 10px;
  color: rgb(var(--color-orchid));
`;
