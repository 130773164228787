import gql from 'graphql-tag'

export const GLOBAL_INFO = gql`
  query transactions($user: Bytes!, $hourStartUnix: Int!) {
    mints(first: 5, orderBy: timestamp, orderDirection: desc, where: { to: $user }) {
      id
      timestamp
      pair {
        id
        token0 {
          symbol
        }
        token1 {
          symbol
        }
      }
      to
      liquidity
      amount0
      amount1
      amountUSD
    }
    burns(first: 5, orderBy: timestamp, orderDirection: desc, where: { sender: $user }) {
      id
      timestamp
      pair {
        id
        token0 {
          symbol
        }
        token1 {
          symbol
        }
      }
      sender
      to
      liquidity
      amount0
      amount1
      amountUSD
    }
    swaps(first: 5, orderBy: timestamp, orderDirection: desc, where: { from: $user }) {
      id
      timestamp
      pair {
        token0 {
          symbol
        }
        token1 {
          symbol
        }
      }
      amount0In
      amount0Out
      amount1In
      amount1Out
      amountUSD
      to
    }
    bundle(id: 1){
      ethPrice
    }
    user(id:$user){
      liquidityPositions(where: {liquidityTokenBalance_gt: 0}){
        id
      }
    }
    pairDayDatas(first: 10, orderBy:dailyVolumeUSD, orderDirection: desc, where: {date: $hourStartUnix}) {
      id
      dailyVolumeUSD
      date
      pairAddress
      token0{
        id
        symbol
        decimals
        name
        derivedETH
      }
      token1{
        id
        symbol
        decimals
        name
        derivedETH
      }
    }
    tokens(first: 550, orderBy: tradeVolumeUSD, orderDirection:desc){
      id
      symbol
      name
      decimals
      derivedETH
      tradeVolumeUSD
    }
    pairs (first: 15, orderBy: reserveETH, orderDirection: desc) {
      stable
      id
      token1Price
      token0Price
      pairFee
      token0{
        id
        symbol
        derivedETH
      }
      token1{
        id
        symbol
        derivedETH
      }
      reserve0
      reserve1

      token0Fees
      token0FeesP

      token1Fees
      token1FeesP
      volumeUSD
      reserveUSD
      totalSupply
    }
  }
`

/*
    extra:pairs (first: 1, where: {id: $extra}) {
      stable
      id
      token1Price
      token0Price
      pairFee
      token0{
        id
        symbol
        derivedETH
      }
      token1{
        id
        symbol
        derivedETH
      }
      reserve0
      reserve1

      token0Fees
      token0FeesP

      token1Fees
      token1FeesP
      volumeUSD
      reserveUSD
      totalSupply
    }
      */

export const LIQ_POSITIONS = gql`
  query transactions($user: Bytes!) {
    user(id:$user){
      liquidityPositions(first:1000){
        id
        liquidityTokenBalance
      }
    }
  }
`

export const TOP_PAIRS = gql`
  query transactions($user: Bytes!, $extra: Bytes!) {
    pairs (first: 20, orderBy: reserveETH, orderDirection: desc) {
      stable
      id
      token1Price
      token0Price
      pairFee
      token0{
        id
        symbol
      }
      token1{
        id
        symbol
      }
      reserve0
      reserve1

      token0Fees
      token0FeesP

      token1Fees
      token1FeesP
      volumeUSD
      reserveUSD
      totalSupply
    }
    extra:pairs (first: 1, where: {id: $extra}) {
      stable
      id
      token1Price
      token0Price
      pairFee
      token0{
        id
        symbol
      }
      token1{
        id
        symbol
      }
      reserve0
      reserve1

      token0Fees
      token0FeesP

      token1Fees
      token1FeesP
      volumeUSD
      reserveUSD
      totalSupply
    }
  }
`

export const TOKEN_PRICES = gql`
  query tokens($user: Bytes!) {
    bundle(id: 1){
      ethPrice
    }
    tokens(first: 1000, orderBy: tradeVolumeUSD, orderDirection:desc){
      id
      symbol
      name
      decimals
      derivedETH
      tradeVolumeUSD
    }
  }
`

export const SWAP_HISTORY = gql`
  query transactions($user: Bytes!, $timestampStartUnix: Int!, $id: Bytes!) {
    user:muteSwitchUserWeekDatas(first:1, where:{id: $id}, orderBy:feesPaidUSD, orderDirection:desc){
      id
      date
      feesPaidUSD
      totalVolumeUSD
    }
    global:muteSwitchUserWeekDatas(first:1000, where:{date_gte:$timestampStartUnix}, orderBy:feesPaidUSD, orderDirection:desc){
      id
      date
      feesPaidUSD
      totalVolumeUSD
    }
  }
`


export const USER_LIQUIDITY = gql`
  query user($id: ID!) {
    liquidityPositions (where: {user: $id}) {
      id
      pair{
        id
        stable
        reserveUSD
      }
    }
  }
`

export const TRENDING_PAIRS = gql`
  query pairDayDatas($hourStartUnix: Int!){
    pairDayDatas(orderBy:hourlyVolumeUSD, orderDirection: desc, where: {hourStartUnix: $hourStartUnix}) {
      id
      hourlyVolumeUSD
      hourStartUnix
      pair {
        id
        stable
        token0Price
        token1Price
        reserveUSD
        token0{
          id
          symbol
          decimals
          name
        }
        token1{
          id
          symbol
          decimals
          name
        }
      }
    }
  }
`

export const GLOBAL_POOLS = gql`
  query pooldata($token0: ID!, $token1: ID!) {
    pairs (first: 1000, orderBy: reserveUSD, orderDirection: desc) {
      stable
      id
      pairFee
      reserveUSD
      token0{
        id
        symbol
        decimals
      }
      token1{
        id
        symbol
        decimals
      }
      reserve0
      reserve1
    }
    token0:token(id: $token0){
      id
      symbol
      name
      decimals
      derivedETH
      tradeVolumeUSD
    }
    token1:token(id: $token1){
      id
      symbol
      name
      decimals
      derivedETH
      tradeVolumeUSD
    }
    bundle(id: 1){
      ethPrice
    }
  }
`


export const USER_ACTIVITY = gql`
  query transactions($user: Bytes!) {
    mints(first: 1, orderBy: timestamp, orderDirection: desc, where: { to: $user }) {
      id
      to
      liquidity
      amount0
      amount1
      amountUSD
    }
    burns(first: 1, orderBy: timestamp, orderDirection: desc, where: { sender: $user }) {
      id
      sender
      to
      liquidity
      amount0
      amount1
      amountUSD
    }
    swaps(first: 5, orderBy: timestamp, orderDirection: desc, where: { from: $user }) {
      id
      amount0In
      amount0Out
      amount1In
      amount1Out
      amountUSD
      to
    }
    user(id:$user){
      liquidityPositions(where: {liquidityTokenBalance_gt: 0}){
        id
      }
    }
  }
`
// NOTE: this is not a gql template
export const USER_INTERACTION = `
  query userInteraction($user: Bytes!) {
    mints(first: 1, where: {to: $user}) {
      id
    }
    swaps(first: 1, where: {from: $user}) {
      id
    }
  }
`