import styled from "styled-components";
import {mq} from "../../ui/styles";

export const Bg = styled.img`
  position: fixed;
  top: 0;
  max-width: min(max(1200px, 100%), 1708px);

  ${mq(
    800,
    `
    max-width: 140%;
  `,
  )}

  & ~ * {
    position: relative;
    z-index: 2;
  }
`;
