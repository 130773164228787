import {useMemo} from "react";
import { sumObjs } from ".";
import { AnalyticsGlobalData } from "./global";
import { useGetPairsV2Data } from "./pairs-v2";
import { useTokenChartV2 } from "./token-chart-v2";

import { useTokenDataV2 } from "./token-data-v2";
import { useTokenDataV3 } from "./token-data-v3";
import { useGetTransactionsFilteredV2Data } from "./transactions-filtered-v2";

export const useTokenData = (globalData?: AnalyticsGlobalData, address?: string) => {
  const tTokensV2 = useTokenDataV2(globalData, address);
  const tTokensV3 = useTokenDataV3(globalData, address);

  const tokenPairs = useGetPairsV2Data(
    !!address,
    globalData,
    false,
    `where: {token0: "${address}"}`,
    `where: {token1: "${address}"}`,
  )

  const tokenTxs = useGetTransactionsFilteredV2Data(!!address, tokenPairs.pairs.map(_ => _.id))

  const tokenChart = useTokenChartV2(globalData, address);

  // Tokens
  const tokensData = useMemo(() => {
    if (!tTokensV2?.tokens?.length) return [];
    const v3Refs = {};
    tTokensV3?.tokens.forEach((_) => (v3Refs[_.symbol] = _));
    return tTokensV2.tokens
      .map((v2) => ({
        ...v2,
        ...sumObjs(["liquidityUsd", "volumeDayUsd", "txDayCount"], v2, v3Refs[v2.symbol] as typeof v2),
        v2,
        v3: v3Refs[v2.symbol] as typeof v2,
      }))
      .sort(({volumeDayUsd: a}, {volumeDayUsd: b}) => b - a);
  }, [tTokensV2, tTokensV3]);

  return {
    ...tTokensV2,
    ready: !!tokensData.length,
    token: tokensData[0] || {},
    tokens: tokensData,
    pairs: {
      isLoading: tokenPairs.isLoading,
      pairs: tokenPairs.pairs,
    },
    txs: {
      isLoading: tokenTxs.isLoading,
      txs: tokenTxs.txs,
    },
    chart: {
      isLoading: tokenChart.isLoading,
      ...tokenChart.data,
    }
  };
};
