import styled from "styled-components";

import {br, card, flex, microPalette, palette, size, text, transition} from "../../styles";

export const Wrapper = styled.div<{height?: number | string, scrolled?: boolean}>`
  ${card('m', true)}
  ${size.padding(0, 6, 6)}

  overflow: auto;

  ${_ => _.height && `height: ${isNaN(+_.height) ? _.height : _.height + 'px'};`}

  ::-webkit-scrollbar-track {
    margin: ${size(4)};
  }

  &.scrolled table thead tr th:before {
    background: ${palette.gray100};
  }
  table {
    min-width: 100%;

    thead tr th {
      ${size.padding(6, 4, 4)}

      position: sticky;
      top: ${size(-2)};
      background: ${palette.white};
      cursor: pointer;
      z-index: 3;

      &:before {
        ${transition.fast}

        content: "";
        position: absolute;
        bottom: -1px;
        left: ${size(-6)};
        right: ${size(-6)};
        height: 1px;
        display: block;
      }
    }
    tbody tr td {
      ${size.padding(2, 4)}

      border-bottom: 1px solid ${palette.gray50};
    }
    tbody tr:last-child td {
      border-bottom: 0;
    }

    thead tr th,
    tbody tr td {
      ${text.bodyLarge}

      vertical-align: middle;
      width: max-content;
      white-space: nowrap;

      &:not([align]) {
        text-align: right;
      }

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
`;

export const Loading = styled.div`
  ${flex('center', 'center')}

  padding-top: ${size(6)};
  height: 100%;
  width: 100%;
`