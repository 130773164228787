import {useQuery} from "@tanstack/react-query";
import {fetchGraphQL} from "../../../utils/gql-utils";
import {getGraphQLUrl} from "../../../web3/apollo/client";
import { mapTxType, txFragment } from "./transactions.utils";

export const useGetTransactionsV2Data = (enabled?: boolean, full?: boolean) => {
  const query = useQuery({
    queryKey: ["transactions-v2", full],
    queryFn: async () => {
      const url = getGraphQLUrl();
      return await fetchGraphQL(
        url,
        `
        query {
          transactions(first: ${full ? 100 : 20}, orderBy: timestamp, orderDirection: desc) {
            id
            mints(orderBy: timestamp, orderDirection: desc) {
              ${txFragment('mint')}
            }
            burns(orderBy: timestamp, orderDirection: desc) {
              ${txFragment('burn')}
            }
            swaps(orderBy: timestamp, orderDirection: desc) {
              ${txFragment('swap')}
            }
          }
        }
      `,
      ).then(({data}: {data: {transactions: (Record<string, any[]> & {id: string})[]}}) =>
        data.transactions
          .map((t) => {
            const {mints, burns, swaps, id} = t;
            return [...mapTxType("mints", mints), ...mapTxType("burns", burns), ...mapTxType("swaps", swaps)]
              .map((_, i) => ({..._, hash: id, index: i}));
          })
          .flat(),
      );
    },
    enabled,
    refetchOnWindowFocus: false,
  });

  return {
    ...query,
    txs: query.data || [],
  };
};
