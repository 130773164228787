import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  row-gap: 7px;
  column-gap: 7px;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 900px) {
    row-gap: 24px;
    column-gap: 16px;
  }
  @media (max-width: 767px) {
    grid-template-areas: none;
    grid-template-columns: initial;
    row-gap: 16px;
  }
`;
