import {toIcon} from "./icon.shared";

export const checkCircle = toIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      d="M9.00008 17.3337C4.39758 17.3337 0.666748 13.6028 0.666748 9.00033C0.666748 4.39783 4.39758 0.666992 9.00008 0.666992C13.6026 0.666992 17.3334 4.39783 17.3334 9.00033C17.3334 13.6028 13.6026 17.3337 9.00008 17.3337ZM8.16925 12.3337L14.0609 6.44116L12.8826 5.26283L8.16925 9.97699L5.81175 7.61949L4.63341 8.79782L8.16925 12.3337Z"
      fill="#216E05"
    />
  </svg>,
);
