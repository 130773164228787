const getRadius = (size) => `
  border-radius: ${size}px;
`;

export const br = {
  max: getRadius(999),
  /** 24 - xl */
  xl: getRadius(24),
  /** 16 - m */
  m: getRadius(16),
  /** 12 - s */
  s: getRadius(12),
  /** 8 - xs */
  xs: getRadius(8),
  /** 6 - xxs */
  xxs: getRadius(6),
  xxxs: getRadius(4),
  /** 0 - none */
  none: getRadius(0),
};
