import React, {PropsWithChildren} from "react";
import styled from "styled-components";
import unknownToken from "../../assets/images/icons/unknown_token.svg";
import BigNumber from "bignumber.js";
import {formatNumber, abbrNum} from "../../utils/formatNumber";
import {Button, Card, Chip, Flex, IconAsset, layoutMq, Text, Tooltip, useLayoutMq} from "../../ui/components";
import { MdOutlineBolt } from "react-icons/md";
import { Asset } from "../../model/Asset";
import { AnimatePresence, motion } from "framer-motion";
import { FaArrowRight } from "react-icons/fa6";

interface DropdownCommonProps {
  onManageClick: () => any;
  icon0: string;
  icon1: string;
  token0: string;
  token1: string;
  stable: boolean;
  fee: string;
  reserveA: string;
  reserveB: string;
  tvl?: string;
  apy?: string;
  dark?: boolean;
  loading?: boolean;
  rewardTokens?: Asset[];
}

type DropdownProps = DropdownCommonProps | ({loading: true} & Partial<DropdownCommonProps>);

export const Dropdown = ({
  loading,
  onManageClick,
  icon0,
  icon1,
  token0,
  token1,
  stable,
  reserveA,
  reserveB,
  fee,
  apy,
  tvl,
  dark,
  children,
  rewardTokens
}: PropsWithChildren<DropdownProps>) => {

  const layoutBreak = useLayoutMq();
  if (loading) {
    return null;
  }
  const getPoolAPY = () => {
    if(apy?.split("-")[0] && apy?.split("-")[1] && !apy.includes('undefined'))
      return /*apy?.split("-")[0] + '-' + */ apy?.split("-")[1] + "%"
    else 
      return "0.01%"
  }

  const getTotalPoolAPY = () => {
    if(apy?.split("-")[0] && apy?.split("-")[1] && !apy.includes('undefined'))
      return apy?.split("-")[0] + '-' + apy?.split("-")[1] + "%"
    else 
      return "0.01%"
  }

  return (
    <Card size="l" height={layoutBreak ? undefined : 90}>
      <Flex gap={2} justify="space-between" noBasis={!layoutBreak} expandVertical wrap={layoutBreak}>
        <Flex gap={4} grow={layoutBreak ? 2 : 16} order={0}>
          <Flex>
            <IconAsset stack asset={{logo: icon0 ? icon0 : unknownToken, symbol: token0} as any} size="s" button />
            <IconAsset stack asset={{logo: icon1 ? icon1 : unknownToken, symbol: token1} as any} size="s" button />
          </Flex>

          <div>
            <Text h6 noWrap>
              {token0}
              <Text inline color="gray300">
                {" ― "}
              </Text>
              {token1}
              {" "}
            </Text>
            <Text bodySmall color="gray400" noWrap>
              {stable ? "Stable" : "Volatile"} {` ${fee}%`}
            </Text>
          </div>
        </Flex>

        <Break />

        <Flex gap={3} grow={layoutBreak ? 2 : 10} order={layoutBreak && 2}>
          <BreakPadding />
          <Flex column>
            <Text bodyRegular color="gray400">
              {token0}
            </Text>
            <Text bodyRegular color="gray400">
              {token1}
            </Text>
          </Flex>
          <Flex column>
            <Text bodyMedium>{abbrNum(reserveA, 4)}</Text>
            <Text bodyMedium>{abbrNum(reserveB, 4)}</Text>
          </Flex>
        </Flex>

        <Flex grow={layoutBreak ? 1 : 10} order={layoutBreak && 1}>
          {!layoutBreak ? (
            <div>
              {tvl && (
                <>
                  <Text bodyRegular color="gray400">
                    TVL
                  </Text>
                  <Text bodyMedium>{`$${formatNumber(new BigNumber(tvl).toFixed(0), 0)}`}</Text>
                </>
              )}
            </div>
          ) : (
            tvl && <Chip label="TVL" value={`$${formatNumber(new BigNumber(tvl).toFixed(0), 0)}`} />
          )}
        </Flex>

        <Flex order={layoutBreak && 2} column align="flex-start" gap={2} grow={layoutBreak ? 1 : 10}>
          {apy ?
            (!apy.includes("-") ? (
              <Chip valueColor label="APY" value={apy + "%"} />
            ) : (
              <>
                <Tooltip content="This pool is boosted with additional rewards.">
                  <Chip palette="purple" label="APY" value={getPoolAPY()} variant="color">
                    <MdOutlineBolt color="rgba(var(--color-mp-c200))" />
                  </Chip>
                </Tooltip>

              </>
            )) : <Chip valueColor label="APY" value={"0.01%"} />}
        </Flex>

        <Break />

        <Flex justify="flex-end" order={layoutBreak && 10} grow={layoutBreak ? 1 : 10}>
          <Button
                    secondary
                    size="s"
                    full={layoutBreak}
                    onClick={() => {
                    onManageClick();
                    }}
                >
                    <FaArrowRight/>
                </Button>
        </Flex>
      </Flex>
    </Card>
  );
};



const Break = styled.div`
  display: none;

  ${layoutMq(`
    display: block;
    min-width: 100%;
    max-width: 100%;
    order: 2;
  `)}
`;

const BreakPadding = styled.div`
  min-width: 32px;
  max-width: 32px;
  display: none;

  ${layoutMq(`
    display: block;
  `)}
`;


const DropdownContent = styled(motion.div)`
  cursor: pointer;
`;

const CardDropDown = styled(Card)`
    margin-top: -10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
`

