import {JsonRpcProvider} from "ethers";

/**
 * @param diff in seconds
 */
export const getBlockByDate = async (diff: number) => {
  const targetDate = Math.floor((Date.now() + new Date().getTimezoneOffset() * 60 * 1000 - diff * 1000) / 1000);
  const web3 = new JsonRpcProvider("https://mainnet.era.zksync.io");

  const averageBlockTime = 1;

  const currentBlockNumber = await web3.getBlockNumber();
  let block = await web3.getBlock(currentBlockNumber);

  let blockNumber = currentBlockNumber;

  while (block!?.timestamp > targetDate) {
    const decreaseBlocks = Math.floor((block!.timestamp - targetDate) / averageBlockTime);

    if (decreaseBlocks < 1) {
      break;
    }

    blockNumber -= decreaseBlocks;

    block = await web3.getBlock(blockNumber);
  }

  return block?.number;
};


export const getBlocksByDates = (diffs: number[]) =>
  Promise.all(diffs.map(_ => getBlockByDate(_)));

export const getBlocks = (length: number, gap: number) =>
  getBlocksByDates(Array.from({length}).map((_, i) => i * gap));

export const getBlocksTag = (length: number, gap: number, tag: string) =>
  getBlocksByDates(Array.from({length}).map((_, i) => i * gap))
    .then(b => b.map((_, i) => ({block: _, tag, gap: i * gap})));
